import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { getFamilyListAPI } from '../../apis';
import { PageContainer, PageTitle } from '../../components/styled/page';
import FamilyList from './FamilyList';
import FamilyModal from './FamilyModal';

const Tools = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

class FamilyManagement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.FamilyModalRef = React.createRef();

    this.state = {
      families: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchFamilyList();
  }

  fetchFamilyList = () => {
    this.setState({ isLoading: true });
    const iPromise = getFamilyListAPI().toPromise();
    iPromise.then((res) => {
      const families = _.get(res, 'data', []);
      this.setState({ families });
    });
    iPromise.finally(() => {
      this.setState({ isLoading: false });
    });
  };

  showFamilyModal = (action, data) => {
    const modal = this.FamilyModalRef.current;
    modal.show(action, data);
  };

  onCreateClick = () => {
    this.showFamilyModal('create');
  };

  onEditClick = (rowData) => {
    this.showFamilyModal('edit', rowData);
  };

  onRefresh = () => {
    this.fetchFamilyList();
  };

  render() {
    const { families, isLoading } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'經營管理 > 家族管理'}</PageTitle>
        <Tools>
          <Button
            type="primary"
            onClick={this.onCreateClick}
          >
            <span>新增</span>
          </Button>
          <Button
            type="primary"
            loading={isLoading}
            onClick={this.onRefresh}
          >
            <span>刷新</span>
          </Button>
        </Tools>
        <FamilyList
          families={families}
          onEdit={this.onEditClick}
        />
        <FamilyModal
          ref={this.FamilyModalRef}
          onComplete={this.fetchFamilyList}
        />
      </PageContainer>
    );
  }
}

export default FamilyManagement;
