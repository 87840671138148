import _ from 'lodash';
import { Modal } from 'antd';
import { liveInterruptionAPI } from '../apis';

function liveRoomRecall(roomId, onSuccess) {
  const { confirm } = Modal;
  const hasOnSuccess = _.isFunction(onSuccess);

  confirm({
    title: '立即下架',
    content: '下架後，直播將立即中斷',
    okType: 'danger',
    centered: true,
    maskClosable: true,
    onOk() {
      const observable = liveInterruptionAPI(roomId);
      observable.subscribe(res => {
        const isSuccess = res.Status === 'Ok';
        if (isSuccess && hasOnSuccess) onSuccess();
      });
    },
  });
}

export default liveRoomRecall;
