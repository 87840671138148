import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { PageContainer, PageTitle } from '../../components/styled/page';
import UserSearchBar from '../../components/UserSearchBar';
import {
  getRemoveReasonsAPI,
  getAccountDeletionListAPI,
} from '../../apis';
import brandName from '../../constants/brandName';
import { evolve } from 'ramda';
const encodeKeyword = value =>
  evolve({ keyword: keyword => encodeURIComponent(keyword) }, value);

const { Column } = Table;

const UserInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    line-height: 2;
  }
`;

const Reason = styled.article`
  p {
    margin-bottom: 0.5rem;
  }
`;

const Filters = styled.div`
  margin-bottom: 1rem;
`;

class AccountDeletionRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deletionList: [],
      reasonTypes: [],
      filters: {
        user: null,
      },
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
        onChange: this.onPageChange,
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    await this.fetchReasonType();
    this.fetchDeletionList();
  }

  fetchReasonType = () => {
    const type = 'removeAccount';
    const iPromise = getRemoveReasonsAPI(type).toPromise();
    iPromise.then((res) => {
      const { data } = res;
      this.setState({ reasonTypes: data });
    });
    return iPromise;
  }

  fetchDeletionList = () => {
    const { filters, pagination } = this.state;
    const userFilter = filters.user;
    const payload = {
      page: pagination.current,
      item: pagination.pageSize,
    };
    if (userFilter) {
      Object.assign(payload, userFilter);
    }
    const iPromise = getAccountDeletionListAPI(payload).toPromise();
    iPromise.then((res) => {
      const { data } = res;
      this.setState({ deletionList: data });
    });
    return iPromise;
  }

  onUserSelectChange = (value) => {
    const { filters } = this.props;
    const { userFilter } = value;
    const newFilters = {
      ...filters,
      user: encodeKeyword(userFilter),
    };
    this.setState({
      filters: newFilters,
    }, this.fetchDeletionList);
  }

  onPageChange = (pagination) => {
    this.setState({
      pagination,
    }, this.fetchDeletionList);
  }

  renderUserInfo = (rowData) => {
    const {
      email, loginId, nickname,
      trueLoveId, verifiedPhone,
    } = rowData;
    return (
      <UserInfo>
        <li>
          <label>暱稱：</label>
          <span>{nickname}</span>
        </li>
        <li>
          <label>Login ID：</label>
          <span>{loginId}</span>
        </li>
        <li>
          <label>{brandName} ID：</label>
          <span>{trueLoveId}</span>
        </li>
        <li>
          <label>Email：</label>
          <span>{email}</span>
        </li>
        <li>
          <label>手機號碼：</label>
          <span>{verifiedPhone}</span>
        </li>
      </UserInfo>
    );
  }

  renderReason = (rowData) => {
    const { reasonTypes } = this.state;
    const { reasonId, reasonDesc } = rowData;
    const reasonType = _.find(reasonTypes, { id: reasonId });
    const isOther = reasonType.id === 16;
    let reason = reasonType.description;
    if (isOther) reason = `${reason}：`;
    return (
      <Reason>
        <p>{reason}</p>
        {isOther && (
          <p>{reasonDesc}</p>
        )}
      </Reason>
    );
  }

  renderTime = (value) => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    return value && moment.unix(value).format(format);
  }

  render() {
    const { deletionList, pagination } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'帳號管理 > 帳號刪除申請'}</PageTitle>
        <Filters>
          <UserSearchBar
            initialValue={{ userFilter: { keyword: '', type: 'nick_name' }}}
            filters={['userFilter']}
            onSubmit={this.onUserSelectChange}
          />
        </Filters>
        <Table
          rowKey="id"
          dataSource={deletionList}
          pagination={pagination}
        >
          <Column
            title="用戶"
            render={this.renderUserInfo}
          />
          <Column
            title="刪除原因"
            render={this.renderReason}
          />
          <Column
            title="申請時間"
            dataIndex="updatedAt"
            width="12rem"
            align="center"
            render={this.renderTime}
          />
          <Column
            title="取消時間"
            dataIndex="deletedAt"
            width="12rem"
            align="center"
            render={this.renderTime}
          />
          <Column
            title="刪除時間"
            dataIndex="removedAt"
            width="12rem"
            align="center"
            render={this.renderTime}
          />
        </Table>
      </PageContainer>
    );
  }
}

export default AccountDeletionRequest;
