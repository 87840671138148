import _ from 'lodash';
import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  Button, Form, Input,
  Modal, Popconfirm, Tag,
} from 'antd';
import UploadImg from '../../components/UploadImg';
import ColorPicker from '../../components/ColorPicker';

const FormItem = Form.Item;

const ColorPickerField = styled.div`
  display: flex;
  align-items: center;
`;

const ColorText = styled.span`
  margin-left: 0.5rem;
  cursor: pointer;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};

const AppConfigFormModal = ({
  editName,
  editType,
  editData,
  editKey,
  onCancel,
  onOk,
  visible,
  loading
}) => {
  const colorPickerRef = useRef();
  const formRef = useRef();

  const onConfirm = () => {
    const form = formRef.current;
    const iPromise = form.validateFields();
    iPromise.then(onOk);
  };

  const renderEditor = (type) => {
    const defaultValue = _.get(editData, editKey);

    const onColorSelected = (value, instance) => {
      const form = formRef.current;
      form.setFieldsValue({ [editKey]: value.toHEXA().toString() });
      instance.hide();
    };

    if (type === 'image') {
      return (
        <FormItem
          name={String(editKey)}
          initialValue={defaultValue}
          rules={[
            { required: true, message: '請上傳圖片' },
          ]}
        >
          <UploadImg
            type="card"
            uploadUrl="/cdn/backend/photo"
            enableGridBackground={true}
          >
            <span>上傳圖片</span>
          </UploadImg>
        </FormItem>
      );
    } else if (type === 'color') {
      return (
        <ColorPickerField>
          <ColorPicker
            ref={colorPickerRef}
            defaultValue={defaultValue}
            onSave={onColorSelected}
          />
          <FormItem shouldUpdate noStyle>
            {() => {
              const form = formRef.current;
              const color = form?.getFieldValue(editKey);
              return (
                <ColorText
                  onClick={() => colorPickerRef.current.show()}
                >
                  {color}
                </ColorText>
              );
            }}
          </FormItem>
          <FormItem
            name={editKey}
            initialValue={defaultValue}
            rules={[
              { required: true, message: '請選擇標籤顏色' },
            ]}
            noStyle
            hidden
          >
            <Input type="hidden" />
          </FormItem>
        </ColorPickerField>
      );
    } else {
      return false;
    }
  };

  const renderFooter = () => {
    return (
      <React.Fragment>
        <Popconfirm
          key="confirm"
          title="是否確定儲存"
          placement="topLeft"
          onConfirm={onConfirm}
        >
          <Button
            key={'add'}
            loading={loading}
            type="primary"
          >
            <span>確定</span>
          </Button>
        </Popconfirm>
        <Button
          key="cancle"
          style={{ marginLeft: 8 }}
          onClick={onCancel}
        >
          <span>取消</span>
        </Button>
      </React.Fragment>
    );
  };

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      title="編輯 AppConfig 內容"
      footer={renderFooter()}
      onCancel={onCancel}
    >
      <Form
        ref={formRef}
        {...formItemLayout}
      >
        <FormItem label="控制項目">
          <span>{editName}</span>
        </FormItem>
        <FormItem label="欄位 ID">
          <Tag>{editKey}</Tag>
        </FormItem>
        <FormItem label={`${editType === 'image' ? '檔案上傳' : '顏色'}`}>
          {renderEditor(editType)}
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AppConfigFormModal;
