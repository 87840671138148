import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Card, Button, Upload, Input, message } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';

const StyledModal = styled(Modal)`
  .modal-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .custom-image {
    margin-bottom: 12px;
    width: 200px;
    height: 200px;
    overflow: hidden;
  }

  .custom-card {
    .editable-icon-pen {
      cursor: pointer;
    }
  }
`;

const BtnGroup = styled.div``;

const UploadButton = styled(Upload).attrs({ showUploadList: false })`
  .ant-upload {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 200px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    background-color: #fbfbfb;
    font-size: 32px;
    cursor: pointer;
    text-align: center;
    transition: border-color .5s, opacity .5s;

    &:hover {
      opacity: 0.8;
      border: 1px dashed blue;
    }
  }

  span.ant-upload {
    padding-top: 70px;
  }

  .upload-button-text {
    display: block;
    font-size: 16px;
  }
`;

class LiveProfitIdAvatarModal extends Component {
  static propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.initialState = {
      personalId: {
        imgUrl: '',
        fileList: [],
        caption: '',
        isEditing: false,
      },
      personalDocA: {
        imgUrl: '',
        fileList: [],
        caption: '',
        isEditing: false,
      },
      personalDocB: {
        imgUrl: '',
        fileList: [],
        caption: '',
        isEditing: false,
      },
    };
    this.state = this.initialState;
  }

  handleUploadChangeByTag = tag => info => {
    switch (info.file.status) {
      case 'uploading': {
        const reader = new FileReader();
        reader.onload = evt => {
          const imgUrl = evt.target.result;
          this.setState(prevState => ({
            [tag]: {
              ...prevState[tag],
              imgUrl,
              fileList: info.fileList,
            },
          }));
        };
        reader.readAsDataURL(info.file.originFileObj);
        break;
      }
      // case 'removed': {
      //   this.setState({
      //     imgUrl: this.props.info.imgUrl,
      //     fileList: info.fileList,
      //   });
      //   break;
      // }
      default:
        break;
    }
  };

  isFileValid = file => {
    const validType = ['image/jpeg', 'image/jpg', 'image/png'];
    const isImage = validType.indexOf(file.type) > -1;
    if (!isImage) message.error('只能允許上傳圖片如 jpeg, jpg 或 png 類型的檔案。');
    return isImage;
  };

  handleUploadBtnClick = () => {
    message.success('你已成功新增文件。');
    this.setState(this.initialState);
    this.props.onClose();
  };

  handleModalCancel = () => {
    this.setState(this.initialState);
    this.props.onClose();
  };

  render() {
    const { isModalVisible } = this.props;
    const { personalId, personalDocA, personalDocB } = this.state;
    const footerActions = (
      <BtnGroup>
        <Button onClick={this.handleModalCancel}>取消</Button>
        <Button onClick={this.handleUploadBtnClick} type="primary">
          確定上傳
        </Button>
      </BtnGroup>
    );
    return (
      <StyledModal
        title="新增文件"
        visible={isModalVisible}
        onCancel={this.handleModalCancel}
        footer={footerActions}
        width={800}
      >
        <div className="modal-content">
          <Card
            className="avatar-card"
            style={{ width: 200 }}
            bodyStyle={{ padding: 0 }}
          >
            <div className="custom-image">
              {personalId.fileList.length ? (
                <img
                  src={personalId.imgUrl}
                  alt="persona-id-avatar"
                  width="100%"
                />
              ) : (
                <UploadButton
                  onChange={this.handleUploadChangeByTag('personalId')}
                  beforeUpload={this.isFileValid}
                >
                  <LegacyIcon type="upload" />
                  <span className="upload-button-text">新增身分證照片</span>
                </UploadButton>
              )}
            </div>
            <div className="custom-card">
              <h3>文件說明：</h3>
              <Input
                value={personalId.caption}
                placeholder="請輸入身分證文件說明文字"
                onChange={evt =>
                  this.setState({
                    personalId: {
                      ...this.state.personalId,
                      caption: evt.target.value,
                    },
                  })}
              />
            </div>
          </Card>
          <Card
            className="avatar-card"
            style={{ width: 200 }}
            bodyStyle={{ padding: 0 }}
          >
            <div className="custom-image">
              {personalDocA.fileList.length ? (
                <img
                  src={personalDocA.imgUrl}
                  alt="persona-id-avatar"
                  width="100%"
                />
              ) : (
                <UploadButton
                  onChange={this.handleUploadChangeByTag('personalDocA')}
                  beforeUpload={this.isFileValid}
                >
                  <LegacyIcon type="plus" />
                  <span className="upload-button-text">新增文件照片一</span>
                </UploadButton>
              )}
            </div>
            <div className="custom-card">
              <h3>文件說明：</h3>
              <Input
                placeholder="請輸入文件說明文字"
                value={personalDocA.caption}
                onChange={evt =>
                  this.setState({
                    personalDocA: {
                      ...this.state.personalDocA,
                      caption: evt.target.value,
                    },
                  })}
              />
            </div>
          </Card>
          <Card
            className="avatar-card"
            style={{ width: 200 }}
            bodyStyle={{ padding: 0 }}
          >
            <div className="custom-image">
              {personalDocB.fileList.length ? (
                <img
                  src={personalDocB.imgUrl}
                  alt="persona-id-avatar"
                  width="100%"
                />
              ) : (
                <UploadButton
                  onChange={this.handleUploadChangeByTag('personalDocB')}
                  beforeUpload={this.isFileValid}
                >
                  <LegacyIcon type="plus" />
                  <span className="upload-button-text">新增文件照片二</span>
                </UploadButton>
              )}
            </div>
            <div className="custom-card">
              <h3>文件說明：</h3>
              <Input
                placeholder="請輸入文件說明文字"
                value={personalDocB.caption}
                onChange={evt =>
                  this.setState({
                    personalDocB: {
                      ...this.state.personalDocB,
                      caption: evt.target.value,
                    },
                  })}
              />
            </div>
          </Card>
        </div>
      </StyledModal>
    );
  }
}

export default LiveProfitIdAvatarModal;
