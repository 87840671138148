import { Button, Form, Input, Modal, Popconfirm, Space } from 'antd';
import React, { useEffect } from 'react';
import FileUpload from '../../components/FileUpload';
import { useHasErrors } from '../../hooks/useHasErrors';
import { isReallyEmpty } from '../../utils/mixin';

const { Item: FormItem } = Form;
const { TextArea } = Input;
/**
 * 驗證檔案或備註需擇其一有資料
 */
const customValidator = ({ getFieldsValue, resetFields }) => {
  return {
    validator: rule => {
      const { remark, fileUrl } = getFieldsValue(['remark', 'fileUrl']);
      if (isReallyEmpty(remark) && isReallyEmpty(fileUrl)) {
        return Promise.reject(new Error('上傳檔案或備註，擇一必填'));
      } else if (rule.field === 'remark' && isReallyEmpty(fileUrl)) {
        resetFields(['fileUrl'], true);
      } else if (rule.field === 'fileUrl' && isReallyEmpty(remark)) {
        resetFields(['remark'], true);
      }
      return Promise.resolve();
    }
  };
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

const AgencyMasterTerminateModal = ({ onOk, onCancel, visible, editData }) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();

  useEffect(() => {
    if (visible && !isReallyEmpty(editData)) {
      form.setFieldsValue({
        agencyMasterContractId: editData.agencyMasterContractId
      });
    }
  }, [form, editData, visible]);

  const onFinish = values => {
    onOk(values);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };

  return (
    <Modal
      title={`終止合約 - ${editData?.nickname}`}
      onCancel={onCancel}
      visible={visible}
      afterClose={onAfterClose}
      footer={
        <Space>
          <Popconfirm
            key="confirm"
            title="是否確定終止"
            placement="topLeft"
            onConfirm={() => form.submit()}
          >
            <Button key={'add'} type="primary" disabled={hasErrors}>
              確定
            </Button>
          </Popconfirm>
          <Button key="cancle" onClick={onCancel}>
            取消
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={validateStatus}
        {...formItemLayout}
      >
        <p>
          終止合約後，直播主目前收益點數將被立刻結算，並變更角色為一般用戶，是否確定要終止合約？
        </p>
        <p>終止合約需有相關證明，以下欄位必須擇一填寫：</p>
        <FormItem label="終止合約憑證" name="fileUrl" rules={[customValidator]}>
          <FileUpload uploadUrl="/cdn/agencymaster/contract" />
        </FormItem>
        <FormItem label="備註" name="remark" rules={[customValidator]}>
          <TextArea placeholder="請輸入備註" rows={2} />
        </FormItem>
        <FormItem hidden={true} name="agencyMasterContractId">
          <Input key="agencyMasterContractId" type="hidden" />
        </FormItem>
      </Form>
    </Modal>
  );
};
export default AgencyMasterTerminateModal;
