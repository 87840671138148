import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Select, DatePicker, Button, Input } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { getLiveViewerList } from '../reducers/liveViewerStatistics.js';
import { dateOptions } from '../constants/moments';
import downloadCSV from '../utils/downloadCSV';

const { RangePicker } = DatePicker;
const Option = Select.Option;
const Search = Input.Search;

const Tools = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

class LiveViewerStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: ''
    };
  }

  componentDidMount() {
    const { keyword } = this.state;
    const { getLiveViewerList, startDate, endDate } = this.props;
    getLiveViewerList(startDate, endDate, keyword);
  }

  changeKeyword = value => {
    this.setState({ keyword: value.target.value });
  };

  searchKeyword = value => {
    const { getLiveViewerList, startDate, endDate } = this.props;
    getLiveViewerList(startDate, endDate, value);
  };

  onChange = value => {
    const { keyword } = this.state;
    const { getLiveViewerList, startDate, endDate } = this.props;
    if (value !== 'customize') {
      getLiveViewerList(+value, endDate, keyword);
    } else {
      getLiveViewerList(startDate, endDate, keyword, true);
    }
  };

  onDatePickChange = date => {
    const { keyword } = this.state;
    const { getLiveViewerList } = this.props;
    getLiveViewerList(date[0].valueOf(), date[1].valueOf(), keyword, true);
  };

  downloadCSVFromJson = () => {
    const { liveViewerlist } = this.props;
    downloadCSV(liveViewerlist, ['dateStr', 'totalCounts'], ['時間', '登入數']);
  };

  render() {
    const { liveViewerlist, isCustomize, startDate, endDate } = this.props;

    return (
      <div>
        <h1>{'經營管理 > 直播人流統計'}</h1>
        <Tools>
          <Search
            placeholder="搜尋特定直播主"
            style={{ width: 200 }}
            onChange={this.changeKeyword}
            onSearch={this.searchKeyword}
          />
          <Select
            defaultValue={dateOptions.week}
            value={isCustomize ? 'customize' : String(startDate)}
            style={{ width: '8rem' }}
            onChange={this.onChange}>
            <Option value={dateOptions.week}>過去一週</Option>
            <Option value={dateOptions.month}>過去一個月</Option>
            <Option value={dateOptions.quart}>過去一季</Option>
            <Option value={dateOptions.half}>過去六個月</Option>
            <Option value={dateOptions.year}>過去一年</Option>
            <Option value="customize">自訂</Option>
          </Select>
          {isCustomize ? (
            <RangePicker
              style={{ width: '22rem' }}
              format="YYYY/MM/DD HH:mm:ss"
              showTime={true}
              value={[moment(+startDate), moment(+endDate)]}
              onChange={this.onDatePickChange}
              allowClear={false}
            />
          ) : null}
        </Tools>
        <Button
          style={{ marginBottom: '1rem' }}
          type="primary"
          onClick={this.downloadCSVFromJson}>
          匯出報表
        </Button>
        {liveViewerlist.length && (
          <LineChart
            width={1000}
            height={500}
            data={liveViewerlist}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dateStr" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line name="登入數" dataKey="totalCounts" />
          </LineChart>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    liveViewerlist: state.liveViewerStatistics.liveViewerlist,
    startDate: state.liveViewerStatistics.startDate,
    endDate: state.liveViewerStatistics.endDate,
    isCustomize: state.liveViewerStatistics.isCustomize
  }),
  { getLiveViewerList }
)(LiveViewerStatistics);
