import { Menu } from 'antd';
import { Link, Route, Switch } from 'react-router-dom';
import { PageContainer, PageTitle } from '../../components/styled/page';
import { SIGNIN_EVENT_MANAGEMENT } from '../../constants/routes';
import ContinuityEventManagement from './ContinuityEventManagement';
import CreateEventManagement from './CreateEventManagement';

const items = [
  {
    label: <Link to={SIGNIN_EVENT_MANAGEMENT}>活動簽到</Link>,
    key: SIGNIN_EVENT_MANAGEMENT
  },
  {
    label: <Link to={`${SIGNIN_EVENT_MANAGEMENT}/continuity`}>預設簽到</Link>,
    key: `${SIGNIN_EVENT_MANAGEMENT}/continuity`
  }
];
const SigninEventManagement = props => {
  return (
    <PageContainer>
      <PageTitle>{'首頁管理 > 簽到任務'}</PageTitle>
      <Menu
        selectedKeys={[props.location.pathname]}
        mode="horizontal"
        items={items}
        style={{ marginBottom: '16px' }}
      />
      <Switch>
        <Route
          path={[`${SIGNIN_EVENT_MANAGEMENT}/continuity`]}
          render={() => <ContinuityEventManagement {...props} />}
        />
        <Route
          path={`${SIGNIN_EVENT_MANAGEMENT}`}
          render={() => <CreateEventManagement {...props} />}
        />
      </Switch>
    </PageContainer>
  );
};
export default SigninEventManagement;
