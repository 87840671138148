import React from 'react';
import styled from 'styled-components';
import { Button, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TagGroupManageModal from './TagGroupManageModal';
import removeTagGroup from './removeTagGroup';
import { getBackendTagGroupListAPI } from '../../apis';
import TableContainer from '../../components/TableContainer';

const { Column } = Table;

const StyledSection = styled.section`
  .tag-group-tools {
    margin-bottom: 1rem;
  }

  .tag-group-row-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      border: 0;
      background: none;
      font-size: 1rem;
      box-shadow: none;
      overflow: hidden;

      &.delete {
        &:hover,
        &:focus {
          color: #ff4d4f;
        }
      }
    }
  }
`;

class TagGroupManagement extends React.Component {
  constructor(props) {
    super(props);

    this.TagGroupManageModalRef = React.createRef();

    this.state = {
      isLoading: false,
      tagGroups: [],
      pagination: {
        showSizeChanger: false,
        pageSize: 20,
        total: 0,
        current: 1
      }
    };
  }

  componentDidMount() {
    this.fetchTagGroups();
  }

  showTagGroupManageModal = (action, data) => {
    const modal = this.TagGroupManageModalRef.current;
    modal.show(action, data);
  };

  fetchTagGroups = () => {
    this.setState({ isLoading: true });

    const { pagination } = this.state;
    const { current, pageSize } = pagination;

    const observable = getBackendTagGroupListAPI({
      page: current,
      item: pageSize
    });

    observable.subscribe(res => {
      const { data, totalCount } = res;
      const newPagination = Object.assign({}, pagination, {
        total: totalCount
      });

      this.setState({
        tagGroups: data,
        pagination: newPagination,
        isLoading: false
      });
    });
  };

  onCreateBtnClick = () => {
    this.showTagGroupManageModal('create');
  };

  onEditBtnClick = rowData => {
    this.showTagGroupManageModal('edit', rowData);
  };

  onRemoveBtnClick = id => {
    removeTagGroup(id, this.fetchTagGroups);
  };

  onTableChange = iPagination => {
    const { pagination } = this.state;
    const newPagination = Object.assign({}, pagination, {
      current: iPagination.current
    });

    this.setState({ pagination: newPagination }, () => {
      this.fetchTagGroups();
    });
  };

  renderTagGroupsTabelActions = (text, record) => {
    const { id } = record;

    return (
      <div className="tag-group-row-actions">
        <Button className="edit" onClick={() => this.onEditBtnClick(record)}>
          <EditOutlined />
        </Button>
        <Button className="delete" onClick={() => this.onRemoveBtnClick(id)}>
          <DeleteOutlined />
        </Button>
      </div>
    );
  };

  render() {
    const { isLoading, tagGroups, pagination } = this.state;

    return (
      <React.Fragment>
        <StyledSection>
          <h1>{'APP 管理 > 標籤群組(TagGroup)管理'}</h1>
          <div className="tag-group-tools">
            <Button type="primary" onClick={this.onCreateBtnClick}>
              <span>新增</span>
            </Button>
          </div>
          <TableContainer>
            <Table
              rowKey="id"
              dataSource={tagGroups}
              pagination={pagination}
              loading={isLoading}
              onChange={this.onTableChange}
            >
              <Column title="標籤名稱" dataIndex="label" key="label" />
              <Column title="類型" dataIndex="type" key="type" />
              <Column
                key="_action"
                width="10rem"
                render={this.renderTagGroupsTabelActions}
              />
            </Table>
          </TableContainer>
        </StyledSection>
        <TagGroupManageModal
          ref={this.TagGroupManageModalRef}
          onCompleted={this.fetchTagGroups}
        ></TagGroupManageModal>
      </React.Fragment>
    );
  }
}

export default TagGroupManagement;
