import _ from 'lodash';
import Observer from './observer';
import { getVoiceChatRoom, getPrivateVoiceChatRoom } from '../../apis';

function debugMessage(type, message) {
  console[type](`VoiceChatService: ${message}`);
}

function VoiceChatService() {
  const observer = new Observer();
  const data = {
    seats: [],
    seatsMute: [],
  };

  let intervalRef = null;
  let roomID = null;
  let update = null;

  function init(id, isPrivate) {
    dispose();

    const idType = typeof id;
    if (idType !== 'string') {
      debugMessage('error', `Invalid argument 'id' of type '${idType}', expected 'string'`);
      return;
    }

    roomID = id;
    update = isPrivate ? getPrivateVoiceChatRoom : getVoiceChatRoom;

    fetchVoiceChatRoom();
    intervalRef = setInterval(fetchVoiceChatRoom, 5000);
  }

  function dispose() {
    clearInterval(intervalRef);
    observer.clear();
    roomID = null;
    update = null;
  }

  function fetchVoiceChatRoom() {
    if (!roomID) return;

    const iPromise = update(roomID).toPromise();
    iPromise.then((response) => {
      const { data: room } = response;
      const seats = _.get(room, 'inRoomBcst.seats', []);
      const seatsMute = _.get(room, 'inRoomBcst.seatsMute', []);
      Object.assign(data, { seats, seatsMute });
      observer.dispatch('update', data);
    });
    return iPromise;
  }

  Object.defineProperty(this, 'init', {
    enumerable: true,
    value: init,
  });

  Object.defineProperty(this, 'dispose', {
    enumerable: true,
    value: dispose,
  });

  Object.defineProperty(this, 'on', {
    enumerable: true,
    value: observer.on.bind(observer),
  });

  Object.defineProperty(this, 'off', {
    enumerable: true,
    value: observer.off.bind(observer),
  });
}

export default new VoiceChatService();
