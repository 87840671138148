import {
  compose, dissoc, head,
  identity, ifElse, is,
  prop,
} from 'ramda';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Popconfirm, Space, Table } from 'antd';
import {
  getPrivateVoiceChatSpotlightList,
  getPrivateVoiceChatSpotlight,
  addPrivateVoiceChatSpotlight,
  setPrivateVoiceChatSpotlight,
  deletePrivateVoiceChatSpotlight,
  showModal,
} from '../../reducers/privateVoiceChatSpotlightManagement';
import { renderPreviewer } from '../../components/UploadImg';
import SpotLightFormModal from '../../components/SpotlightFormModal';
import { PageContainer, PageTitle } from '../../components/styled/page';

const { Column } = Table;

const Actions = styled(Space)`
  margin-bottom: 1rem;
`;

const Reference = styled.a`
  word-wrap: break-word;
  word-break: break-word;
`;

const getSubmitData = compose(
  dissoc('dateRange'),
  value => ({
    ...value,
    startTime: value.dateRange ? value.dateRange[0].unix() : undefined,
    endTime: value.dateRange ? value.dateRange[1].unix() : undefined
  }),
  ifElse(
    compose(is(Object), prop('imageUrl')),
    value => ({
      ...value,
      imageUrl: value?.imageUrl?.videoUrl
    }),
    identity
  )
);

const PrivateVoiceChatSpotlightManagement = ({ showHeadline = true }) => {
  const dispatch = useDispatch();
  const { data, loading, currentSpotlight, isShowModal, totalCount } =
    useSelector(state => state.privateVoiceChatSpotlightManagement);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(getPrivateVoiceChatSpotlightList());
  }, [dispatch]);

  const pagination = {
    pageSize: 20,
    total: totalCount
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onAddClick = () => {
    setIsCreateMode(true);
    dispatch(showModal());
  };

  const onEditClick = () => {
    dispatch(getPrivateVoiceChatSpotlight(head(selectedRowKeys)));
    setIsCreateMode(false);
    dispatch(showModal());
  };

  const onDeleteClick = () => {
    dispatch(deletePrivateVoiceChatSpotlight(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onFormSubmit = form => {
    form.validateFields().then(values => {
      if (isCreateMode) {
        dispatch(addPrivateVoiceChatSpotlight(getSubmitData(values)));
      } else {
        dispatch(setPrivateVoiceChatSpotlight(getSubmitData(values)));
      }
    });
  };

  const onModalCancel = () => {
    dispatch(showModal(false));
  };

  const renderMedia = (rowData) => {
    const { imageUrl } = rowData;
    return renderPreviewer(imageUrl, {
      autoPlay: true,
      loop: true,
      muted: true,
    });
  };

  const renderReference = (refer) => {
    return (
      <Reference
        href={refer}
        rel="noreferrer"
        target="_blank"
      >
        <span>{refer}</span>
      </Reference>
    );
  };

  const renderShelfTime = (rowData) => {
    const { startTime, endTime } = rowData;
    const format = 'YYYY-MM-DD HH:mm:ss';
    const start = moment.unix(startTime).format(format);
    const end = moment.unix(endTime).format(format);
    return (
      <React.Fragment>
        <div>{`${start} ~`}</div>
        <div>{` ${end}`}</div>
      </React.Fragment>
    );
  };

  return (
    <PageContainer>
      {showHeadline && (
        <PageTitle>{'聲聊管理 > 私密聲聊 Spotlight'}</PageTitle>
      )}
      <Actions>
        <Button
          onClick={onAddClick}
        >
          <span>新增</span>
        </Button>
        <Button
          disabled={selectedRowKeys.length !== 1}
          onClick={onEditClick}
        >
          <span>編輯</span>
        </Button>
        <Popconfirm
          title="是否確定刪除?"
          placement="right"
          disabled={selectedRowKeys.length === 0}
          onConfirm={onDeleteClick}
        >
          <Button
            type="danger"
            disabled={selectedRowKeys.length === 0}
          >
            <span>刪除</span>
          </Button>
        </Popconfirm>
      </Actions>
      <Table
        rowKey="id"
        dataSource={data}
        loading={loading}
        pagination={pagination}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange
        }}
      >
        <Column
          title="標題"
          dataIndex="title"
        />
        <Column
          title="副標"
          dataIndex="description"
        />
        <Column
          width="17rem"
          title="行銷圖片/影片"
          render={renderMedia}
        />
        <Column
          title="連結位置"
          dataIndex="linkUrl"
          render={renderReference}
        />
        <Column
          title="上下架時間"
          render={renderShelfTime}
        />
      </Table>
      <SpotLightFormModal
        isCreateMode={isCreateMode}
        visible={isShowModal}
        editData={currentSpotlight}
        onCancel={onModalCancel}
        onOk={onFormSubmit}
      />
    </PageContainer>
  );
};

export default PrivateVoiceChatSpotlightManagement;
