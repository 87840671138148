import React, { Component } from 'react';
import { Tabs } from 'antd';
import ExportReport from './ExportReport';
import RevenueWithdraw from './RevenueWithdraw';
import { PageContainer, PageTitle } from '../../components/styled/page';

const { TabPane } = Tabs;

const TabKeyEnum = Object.freeze({
  EXPORT_REPORT: 'ExportReport',
  REVENUE_WITHDRAW: 'RevenueWithdraw',
});

const TabTitleEnum = Object.freeze({
  [TabKeyEnum.EXPORT_REPORT]: '匯出報表',
  [TabKeyEnum.REVENUE_WITHDRAW]: '結算業績',
});

class AccountSheetExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: TabKeyEnum.EXPORT_REPORT,
    };
  }

  onTabChange = (tab) => {
    this.setState({ activeTab: tab });
  }

  render() {
    const { activeTab } = this.state;
    const subTitle = TabTitleEnum[activeTab];
    return (
      <PageContainer>
        <PageTitle>{`帳務管理 > 直播主收益管理 > ${subTitle}`}</PageTitle>
        <Tabs
          defaultActiveKey="1"
          onChange={this.onTabChange}
        >
          <TabPane
            tab={TabTitleEnum[TabKeyEnum.EXPORT_REPORT]}
            key={TabKeyEnum.EXPORT_REPORT}
          >
            <ExportReport />
          </TabPane>
          <TabPane
            tab={TabTitleEnum[TabKeyEnum.REVENUE_WITHDRAW]}
            key={TabKeyEnum.REVENUE_WITHDRAW}
          >
            <RevenueWithdraw />
          </TabPane>
        </Tabs>
      </PageContainer>
    );
  }
}

export default AccountSheetExport;
