import { message } from 'antd';
import produce from 'immer';
import { groupBy, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, pluck, switchMap, map } from 'rxjs/operators';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { createRequestTypes } from '../actions/Types';
import {
  addVideoAPI,
  deleteVideoAPI,
  getVideoListAPI,
  setVideoAPI
} from '../apis';

/**Type */
const GET_VIDEO_LIST = createRequestTypes('GET_VIDEO_LIST');
const ADD_VIDEO = createRequestTypes('ADD_VIDEO');
const SET_VIDEO = createRequestTypes('SET_VIDEO');
const DELETE_VIDEO = createRequestTypes('DELETE_VIDEO');
const SHOW_VIDEO_MODAL = 'SHOW_VIDEO_MODAL';

/**
 * Action Creator
 */
export const getVideoList = createAction(GET_VIDEO_LIST.REQUEST);
export const getVideoListSuccess = createAction(GET_VIDEO_LIST.SUCCESS);
export const getVideoListFailure = createAction(GET_VIDEO_LIST.FAILURE);
export const showModal = createAction(
  SHOW_VIDEO_MODAL,
  (isShowModal = true) => isShowModal
);
export const addVideo = createAction(ADD_VIDEO.REQUEST);
export const addVideoSuccess = createAction(ADD_VIDEO.SUCCESS);
export const addVideoFailure = createAction(ADD_VIDEO.FAILURE);
export const setVideo = createAction(SET_VIDEO.REQUEST);
export const setVideoSuccess = createAction(SET_VIDEO.SUCCESS);
export const setVideoFailure = createAction(SET_VIDEO.FAILURE);
export const deleteVideo = createAction(
  DELETE_VIDEO.REQUEST,
  (ids = []) => ids
);
export const deleteVideoSuccess = createAction(DELETE_VIDEO.SUCCESS);
export const deleteVideoFailure = createAction(DELETE_VIDEO.FAILURE);

/**
 * Epics
 */
export const getVideoListEpic = action$ =>
  action$.pipe(
    ofType(GET_VIDEO_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getVideoListAPI().pipe(
        map(getVideoListSuccess),
        catchRequestError(getVideoListFailure)
      )
    )
  );

export const addVideoEpic = action$ =>
  action$.pipe(
    ofType(ADD_VIDEO.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      addVideoAPI(payload).pipe(
        mergeMap(res => [addVideoSuccess(res), getVideoList()]),
        catchRequestError(addVideoFailure)
      )
    )
  );

export const setVideoEpic = action$ =>
  action$.pipe(
    ofType(SET_VIDEO.REQUEST),
    pluck('payload'),
    mergeMap(payload =>
      setVideoAPI(payload).pipe(
        mergeMap(res => [setVideoSuccess(res), getVideoList()]),
        catchRequestError(setVideoFailure)
      )
    )
  );

export const deleteVideoEpic = action$ =>
  action$.pipe(
    ofType(DELETE_VIDEO.REQUEST),
    pluck('payload'),
    switchMap(ids => {
      return forkJoin(
        ids.map(id =>
          deleteVideoAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      );
    }),
    mergeMap(res => {
      const errors = groupBy(v => v.Status)(res);
      if (errors.Error?.length > 0) {
        message.error(
          `刪除失敗: ${errors.Error.map(
            e => `id:${e.id} message: ${e.Message}`
          ).join(', ')}`
        );
        return [deleteVideoFailure(), getVideoList()];
      } else {
        return [deleteVideoSuccess(), getVideoList()];
      }
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false,
  isShowModal: false,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_VIDEO_LIST.REQUEST,
      ADD_VIDEO.REQUEST,
      SET_VIDEO.REQUEST,
      DELETE_VIDEO.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(ADD_VIDEO.SUCCESS, SET_VIDEO.SUCCESS)]: produce(
      (draft, { payload }) => {
        draft.loading = false;
        draft.isShowModal = false;
      }
    ),
    [GET_VIDEO_LIST.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;
      draft.data = data;
      draft.totalCount = data.length;
    }),
    [GET_VIDEO_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_VIDEO.FAILURE,
      SET_VIDEO.FAILURE,
      DELETE_VIDEO.SUCCESS,
      DELETE_VIDEO.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_VIDEO_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
