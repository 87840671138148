import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { map, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import { getVoiceChatRoomRankingAPI } from '../apis';
import downloadCSV from '../utils/downloadCSV';
import { catchRequestError, ofType } from '../utils/extendOperators';
/**Type */
const GET_VOICE_CHAT_ROOM_RANKING = createRequestTypes(
  'GET_VOICE_CHAT_ROOM_RANKING'
);

/**
 * Action Creator
 */
export const getVoiceChatRoomRanking = createAction(
  GET_VOICE_CHAT_ROOM_RANKING.REQUEST
);
export const getVoiceChatRoomRankingSuccess = createAction(
  GET_VOICE_CHAT_ROOM_RANKING.SUCCESS
);
export const getVoiceChatRoomRankingFailure = createAction(
  GET_VOICE_CHAT_ROOM_RANKING.FAILURE
);

export const getVoiceChatRoomRankingCsv = sheet => {
  return downloadCSV(
    sheet,
    ['ranking', 'user.nickname', 'points'],
    ['排名', '廳名', '業績']
  );
};
/**
 * Epics
 */
export const getVoiceChatRoomRankingEpic = action$ =>
  action$.pipe(
    ofType(GET_VOICE_CHAT_ROOM_RANKING.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getVoiceChatRoomRankingAPI(payload).pipe(
        map(getVoiceChatRoomRankingSuccess),
        catchRequestError(getVoiceChatRoomRankingFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false
};

export default handleActions(
  {
    [GET_VOICE_CHAT_ROOM_RANKING.REQUEST]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [GET_VOICE_CHAT_ROOM_RANKING.SUCCESS]: produce(
      (draft, { payload: { data } }) => {
        draft.loading = false;
        draft.data = data;
      }
    ),
    [GET_VOICE_CHAT_ROOM_RANKING.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
    })
  },
  initialState
);
