import React from 'react';
import { message, Button, Form, Input } from 'antd';
import { resetSMSAPI } from '../../apis';

const FormItem = Form.Item;

class SMSForm extends React.Component {

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      isLoading: false,
    };
  }

  onFinish = (values) => {
    this.setState({ isLoading: true });

    const code = values.code.trim();
    const phonenumber = values.phonenumber.trim();
    const phonenumbers = `${code}${phonenumber}`;
    const iPromise = resetSMSAPI({ phonenumbers }).toPromise();
    iPromise.then(() => {
      const form = this.formRef.current;
      form.resetFields();
      message.success('簡訊重置成功');
    });
    iPromise.catch((error) => {
      const { Message } = error.response;
      message.error(`簡訊重置失敗 - ${Message}`);
    });
    iPromise.finally(() => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <Form
        ref={this.formRef}
        layout="inline"
        onFinish={this.onFinish}
      >
        <FormItem
          name="code"
        >
          <Input
            type="tel"
            style={{ width: '6rem' }}
            placeholder="國碼"
            allowClear
          />
        </FormItem>
        <FormItem
          name="phonenumber"
          rules={[
            { required: true, message: '請輸入電話號碼' },
          ]}
        >
          <Input
            type="tel"
            placeholder="電話號碼"
            style={{ width: '12rem' }}
          />
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            <span>確定</span>
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default SMSForm;
