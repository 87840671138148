import { map, switchMap, mergeMap, tap, catchError, withLatestFrom } from 'rxjs/operators';
import { handleActions, createAction } from 'redux-actions';
import { pipe } from 'ramda';
import { catchRequestError, getPayloadByType } from '../utils/extendOperators';
import { getManualSettlementListAPI, sendSettlementAPI } from '../apis';

const defaultPageSize = 10;

/**
 * Action Types
 */

const GET_MANUAL_SETTLEMENT_LIST = 'GET_MANUAL_SETTLEMENT_LIST';
const GET_MANUAL_SETTLEMENT_LIST_SUCCESS = 'GET_MANUAL_SETTLEMENT_LIST_SUCCESS';
const GET_MANUAL_SETTLEMENT_LIST_FAILURE = 'GET_MANUAL_SETTLEMENT_LIST_FAILURE';

const SEND_SETTLEMENT_LIST = 'SEND_SETTLEMENT_LIST';

const SELECT_ROW_KEYS = 'SELECT_ROW_KEYS';

const OPEN_SEARCH_MODAL = 'OPEN_SEARCH_MODAL';
const CLOSE_SEARCH_MODAL = 'CLOSE_SEARCH_MODAL';

/**
 * Action Creators
 */

export const getManualSettlementList = (currentPage, agencyId, identityId) =>
  createAction(GET_MANUAL_SETTLEMENT_LIST)({
    currentPage,
    agencyId,
    identityId,
  });
const getManualSettlementListSuccess = createAction(
  GET_MANUAL_SETTLEMENT_LIST_SUCCESS
);
const getManualSettlementListFailure = createAction(
  GET_MANUAL_SETTLEMENT_LIST_FAILURE
);

export const sendSettlementList = createAction(SEND_SETTLEMENT_LIST);

export const selectRowKeys = (
  selectedRowKeys,
  identityId,
  agencyId,
  size = defaultPageSize
) =>
  createAction(SELECT_ROW_KEYS)({
    selectedRowKeys,
    selectedRows: { identityId, agencyId },
    size,
  });

export const openSearchModal = createAction(OPEN_SEARCH_MODAL);
export const closeSearchModal = createAction(CLOSE_SEARCH_MODAL);

/**
 * Epics
 */

export const getManualSettlementListEpic = pipe(
  getPayloadByType(GET_MANUAL_SETTLEMENT_LIST),
  switchMap(({ currentPage, agencyId, identityId, size }) =>
    getManualSettlementListAPI(
      currentPage,
      {
        agencyId,
        identityId,
      },
      size
    ).pipe(
      mergeMap(payload => [
        getManualSettlementListSuccess({ ...payload, currentPage }),
        closeSearchModal(),
      ]),
      catchRequestError(getManualSettlementListFailure)
    )
  )
);

export const sendSettlementListEpic = (actions, state$) =>
  actions.pipe(
    getPayloadByType(SEND_SETTLEMENT_LIST),
    switchMap(payload =>
      sendSettlementAPI(payload).pipe(
        tap(res => {
          window.alert('結算成功');
        }),
        withLatestFrom(state$),
        map(([res,state]) => {
          const { manualSettlement } = state;
          return getManualSettlementList(
            manualSettlement.currentPage,
            manualSettlement.agencyId,
            manualSettlement.identityId
          );
        }),
        catchError(error => {
          window.alert('結算失敗');
        })
      )
    )
  );

/**
 * Reducer
 */

const initialState = {
  loading: false,
  showModal: false,
  list: [],
  currentPage: 0,
  totalCount: 0,
  size: defaultPageSize,
  identityListOptions: [],
  agencyListOptions: [],
  selectedRowKeys: [],
  selectedRows: {
    identityId: [],
    agencyId: [],
  },
};

export default handleActions(
  {
    [GET_MANUAL_SETTLEMENT_LIST]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: true,
    }),
    [GET_MANUAL_SETTLEMENT_LIST_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      currentPage: action.payload.currentPage,
      list: action.payload.data,
      totalCount: action.payload.totalCount,
      selectedRowKeys: [],
      selectedRows: {
        identityId: [],
        agencyId: [],
      },
    }),
    [SELECT_ROW_KEYS]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [OPEN_SEARCH_MODAL]: (state, action) => ({
      ...state,
      showModal: true,
    }),
    [CLOSE_SEARCH_MODAL]: (state, action) => ({
      ...state,
      showModal: false,
    }),
  },
  initialState
);
