import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'antd';

const StyledUploadModal = styled(Modal)`
  width: 40rem !important;
`;

const Preview = styled.div`
  width: 100%;
  position: relative;

  &::after {
    padding-top: 75%;
    display: block;
    content: '';
  }
`;

const PreviewImage = styled(Preview)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${props => props.blob});
`;

const PreviewVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

class UploadModal extends React.Component {

  static propTypes = {
    onMediaUpload: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      file: {},
      fileType: null,
      fileObjectUrl: null,
      visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  show = (file) => {
    const fileType = _.split(file.type, '/', 2);
    const fileObjectUrl = URL.createObjectURL(file);
    this.setState({
      file,
      fileType,
      fileObjectUrl,
      visible: true,
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  onSubmit = () => {
    const { onMediaUpload } = this.props;
    const { file, fileType } = this.state;
    if (onMediaUpload) onMediaUpload({ file, fileType });
    this.hide();
  }

  renderImage = () => {
    const { fileObjectUrl } = this.state;
    return (
      <PreviewImage blob={fileObjectUrl}></PreviewImage>
    );
  }

  renderVideo = () => {
    const { fileObjectUrl } = this.state;
    return (
      <Preview>
        <PreviewVideo
          src={fileObjectUrl}
          controls
          disablePictureInPicture
        />
      </Preview>
    );
  }

  renderPreview = () => {
    const { fileType } = this.state;
    const category = fileType[0];
    if (category === 'image') {
      return this.renderImage();
    } else if (category === 'video') {
      return this.renderVideo();
    }
    return null;
  }

  render() {
    const { visible } = this.state;
    return visible ? (
      <StyledUploadModal
        className="upload-media-modal"
        title="傳送多媒體訊息"
        width="auto"
        visible={visible}
        onOk={this.onSubmit}
        onCancel={this.hide}
        centered
        maskClosable
      >
        {this.renderPreview()}
      </StyledUploadModal>
    ) : null;
  }
}

export default UploadModal;
