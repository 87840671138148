import { prop } from 'ramda';
import { Socket } from 'phoenix';
import Channel from './channel';
import store from '../../store';

function ChatService() {
  let socket;

  function init() {
    if (socket) {
      connect();
      return true;
    }

    const auth = prop('auth', store.getState());
    const { token, nonce } = auth;
    const endPoint = process.env.REACT_APP_CHAT_SOCKET;

    socket = new Socket(endPoint, {
      params: { token, nonce },
      vsn: '2.0.0',
    });

    socket.onError(onSocketError);
    connect();
  }

  function connect() {
    if (!socket) {
      init();
      return;
    };

    if (isConnected()) return;

    socket.connect();
  }

  function disconnect() {
    if (socket) socket.disconnect();
  }

  function isConnected() {
    if (!socket) return false;
    return socket.isConnected();
  }

  function joinRoom(roomId) {
    const topic = `live_room:${roomId}`;
    return new Channel(socket, topic);
  }

  function onSocketError(error) {
    console.error('Socket Error', error);
    disconnect();
  }

  Object.defineProperty(this, 'init', {
    enumerable: true,
    value: init,
  });

  Object.defineProperty(this, 'connect', {
    enumerable: true,
    value: connect,
  });

  Object.defineProperty(this, 'disconnect', {
    enumerable: true,
    value: disconnect,
  });

  Object.defineProperty(this, 'joinRoom', {
    enumerable: true,
    value: joinRoom,
  });
}

export default new ChatService();
