import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import AudiobufferToWav from 'audiobuffer-to-wav';
import AudioBufferUtils from 'audio-buffer-utils';
import WaveSurfer from 'wavesurfer.js';
import styled from 'styled-components';
import { Slider } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';

const Wrapper = styled.div`
  position: relative;
`;

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FAFAFA;
`;

const Wave = styled.div`
  min-height: 128px;
  cursor: pointer;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  background: rgba(33, 33, 54, 0.75);

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #FAFAFA;
    cursor: pointer;

    &:hover {
      color: #69C0FF;
    }
  }

  .ant-slider {
    margin-left: 0.5rem;
  }
`;

const Timer = styled.span`
  margin-left: 1rem;
  color: #BBB;
  user-select: none;
`;

const WaveContext = new (
  window.AudioContext ||
  window.webkitAudioContext
)();

class VoiceChatHistoryPlayer extends React.Component {

  static propTypes = {
    session: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.WaveSurferRef = React.createRef();

    this.timer = {
      current: '00:00:00',
      duration: '00:00:00',
    };

    this.state = {
      duration: 0,
      currentTime: 0,
      wavesurfer: null,
      isReady: false,
      isPlaying: false,
    };
  }

  componentDidMount() {
    requestAnimationFrame(this.init);
  }

  componentWillUnmount() {
    this.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    const { session: prevSession } = prevProps;
    const { session } = this.props;
    if (!_.isEqual(prevSession, session)) {
      this.init();
    }
  }

  init = async () => {
    this.destroy();

    const { session } = this.props;
    const { recordings } = session;

    if (_.isEmpty(session) || recordings.length <= 0) return;

    const dataset = await this.processSources();
    const sources = dataset.filter(data => data.buffer);
    const duration = this.calcDuration(sources);
    if (sources.length <= 0 || duration <= 0) return;

    this.setState({ duration, isReady: true }, async () => {
      const audiobuffer = await this.genAudioBuffer(sources);
      const iBlob = this.converAudioBuffertoBlob(audiobuffer);
      this.genAudioWave(iBlob);
    });
  }

  destroy = () => {
    const { wavesurfer } = this.state;
    if (wavesurfer) wavesurfer.destroy();

    this.setState({
      duration: 0,
      currentTime: 0,
      wavesurfer: null,
      isReady: false,
      isPlaying: false,
    });
  }

  formatTime = (sec) => {
    return new Date(sec * 1000).toISOString().substr(11, 8);
  }

  calcDuration = (dataset) => {
    const { session } = this.props;
    const { info } = session;
    const endTimeArray = _.map(dataset, 'endTime');
    const endTime = Math.max(info.endTime, ...endTimeArray);
    const calc = Math.ceil(endTime - info.startTime) + 1;
    return calc > 0 ? calc : 0;
  }

  processSources = async () => {
    const { session } = this.props;
    const { recordings, info } = session;
    const downloads = [];
    const result = recordings.map((source) => {
      const { endTime, replayUrl } = source;
      const item = { endTime };
      const download = this.downloadToAudioBuffer(replayUrl);
      download.then((buffer) => {
        if (!buffer) return;
        const duration = _.get(buffer, 'duration', 0);
        const beginTime = Math.ceil(endTime - duration);
        const startAt = beginTime - info.startTime;
        Object.assign(item, { buffer, beginTime, startAt });
      });
      downloads.push(download);
      return item;
    });
    await Promise.all(downloads);
    return result;
  }

  downloadToAudioBuffer = (url) => {
    return new Promise(async (resolve) => {
      if (!url) resolve(null);
      const response = await fetch(url, { cache: 'no-cache' });
      if (!response.ok) resolve(null);
      const arrBuffer = await response.arrayBuffer();
      const audBuffer = await WaveContext.decodeAudioData(arrBuffer);
      resolve(audBuffer);
    });
  }

  genAudioBuffer = (sources) => {
    const { duration } = this.state;
    const target = _.minBy(sources, 'buffer.sampleRate');
    const sampleRate = _.get(target, 'buffer.sampleRate');
    const length = duration * sampleRate;
    let result = AudioBufferUtils.create(length, 1, sampleRate);
    for(let i = 0; i < sources.length; i++) {
      const { buffer, startAt } = sources[i];
      const isFirst = i === 0;
      const a = isFirst ? result : AudioBufferUtils.normalize(result);
      const b = AudioBufferUtils.normalize(buffer);
      const offset = startAt * sampleRate;
      result = AudioBufferUtils.mix(a, b, null, offset);
    }
    return AudioBufferUtils.normalize(result);
  } 

  converAudioBuffertoBlob = (buffer) => {
    const wav = AudiobufferToWav(buffer);
    return new Blob([ wav ]);
  }

  genAudioWave = (blob) => {
    const container = this.WaveSurferRef.current;
    const wavesurfer = WaveSurfer.create({
      container,
      scrollParent: true,
      hideScrollbar: true,
      progressColor: '#3C3C50',
      waveColor: '#8F8F9A',
    });

    wavesurfer.on('play', this.onPlayerPlay);
    wavesurfer.on('pause', this.onPlayerPause);
    wavesurfer.on('audioprocess', this.onAudioProcess);
    wavesurfer.on('seek', this.onSeek);
    wavesurfer.on('finish', this.onPlayEnd);

    wavesurfer.loadBlob(blob);
    this.setState({ wavesurfer });
  }

  playPause = () => {
    const { wavesurfer } = this.state;
    wavesurfer && wavesurfer.playPause();
  }

  skipBackward = () => {
    const { wavesurfer } = this.state;
    wavesurfer && wavesurfer.skipBackward();
  }

  skipForward = () => {
    const { wavesurfer } = this.state;
    wavesurfer && wavesurfer.skipForward();
  }

  onVolumeSlide = (value) => {
    const { wavesurfer } = this.state;
    const volume = value / 100;
    wavesurfer && wavesurfer.setVolume(volume);
  }

  onPlayerPlay = () => {
    this.setState({ isPlaying: true });
  }

  onPlayerPause = () => {
    this.setState({ isPlaying: false });
  }

  onPlayEnd = () => {
    const { wavesurfer } = this.state;
    wavesurfer && wavesurfer.stop();
  }

  onAudioProcess = (timing) => {
    const currentTime = parseInt(timing, 10);
    this.setState({ currentTime });
  }

  onSeek = () => {
    const { wavesurfer, isPlaying } = this.state;
    if (!wavesurfer || isPlaying) return;

    const timing = wavesurfer.getCurrentTime();
    const currentTime = parseInt(timing, 10);
    this.setState({ currentTime });
  }

  renderTimer = () => {
    const { currentTime, duration } = this.state;
    const dCurrentTime = this.formatTime(currentTime);
    const dDuration = this.formatTime(duration);
    return (
      <Timer>{`${dCurrentTime} / ${dDuration}`}</Timer>
    );
  }

  renderControls = () => {
    const { isPlaying } = this.state;
    const iconPlayPause = isPlaying ? 'pause' : 'caret-right';
    return (
      <Controls>
        <button onClick={this.playPause}>
          <LegacyIcon type={iconPlayPause} />
        </button>
        <button onClick={this.skipBackward}>
          <LegacyIcon type="backward" />
        </button>
        <button onClick={this.skipForward}>
          <LegacyIcon type="forward" />
        </button>
        <Slider
          style={{ width: '5rem' }}
          defaultValue={100}
          onChange={this.onVolumeSlide}
        />
        {this.renderTimer()}
      </Controls>
    );
  }

  renderOverlay = () => {
    const { session } = this.props;
    const { isReady } = this.state;

    const isEmptySession = _.isEmpty(session);

    let message;
    if (isEmptySession) {
      message = '請選擇場次紀錄';
    } else if (!isReady) {
      message = '音訊處理中...';
    }

    return isEmptySession || !isReady ? (
      <Overlay>
        <span>{message}</span>
      </Overlay>
    ) : null;
  }

  render() {
    return (
      <Wrapper>
        <Wave ref={this.WaveSurferRef} />
        {this.renderControls()}
        {this.renderOverlay()}
      </Wrapper>
    );
  }
}

export default VoiceChatHistoryPlayer;
