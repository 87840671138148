import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, DatePicker, Form, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import LiveMasterSelect from '../../../components/LiveMasterSelect';
import {
  getAccountSheetTWD,
  getAccountSheetUSD
} from '../../../reducers/accountSheetExport';
import { getAgencyList } from '../../../reducers/agencyManagement';
import { getUserList } from '../../../reducers/userManagement';
import { isNil } from 'ramda';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

const defaultRange = [
  moment().subtract(1, 'months').startOf('month'),
  moment().subtract(1, 'months').endOf('month')
];

const FieldHeader = styled.div`
  margin-bottom: 0.5rem;

  label {
    width: 7rem;
    margin-right: 0.5rem;
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
  }
`;

const Actions = styled.div`
  margin-top: 0.5rem;
  display: flex;

  button:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const DatesLabel = styled.div`
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
  background: #f5f5f5;
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  padding: 4px 14px 4px;
`;

class AccountSheetExport extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {};
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.props.getAgencyList({
      page: 1,
      agencyStatusFilter: 'progress',
      item: 9999
    });
    this.props.getUserList({ roleFilter: 'ROLE_MASTER', item: '9999' });
  };

  enableSubmitButton = () => {
    const form = this.formRef.current;
    if (!form) return false;

    const { loading } = this.props;
    const formData = form.getFieldsValue();
    return (
      !loading &&
      formData.currentRange &&
      formData.currentRange.length === 2 &&
      ((formData.agencyIds && formData.agencyIds.length > 0) ||
        (formData.liveMasterId && formData.liveMasterId.length > 0))
    );
  };

  exportAccountSheet = action => {
    const form = this.formRef.current;
    const iPromise = form.validateFields();
    iPromise.then(values => {
      const { currentRange, liveMasterId, agencyIds } = values;
      const startTime = currentRange[0].startOf('day').unix();
      const endTime = currentRange[1].endOf('day').unix();
      action({
        startTime,
        endTime,
        liveMasterId,
        agencyIds,
        page: 1,
        item: 99999
      });
    });
  };

  onSearchUserChanged = value => {
    this.onSearch$.next(value);
  };

  onLiveMasterSelectAll = () => {
    const { userList } = this.props;
    const form = this.formRef.current;
    const liveMasterId = userList.map(user => `${user.id}`);
    form.setFieldsValue({ liveMasterId });
  };

  onLiveMasterSelectClear = () => {
    const form = this.formRef.current;
    form.setFieldsValue({ liveMasterId: [] });
  };

  onAgencySelectAll = () => {
    const { agencyList } = this.props;
    const form = this.formRef.current;
    const agencyIds = agencyList.map(agency => `${agency.agencyId}`);
    form.setFieldsValue({ agencyIds });
  };

  onAgencySelectClear = () => {
    const form = this.formRef.current;
    form.setFieldsValue({ agencyIds: [] });
  };

  onExportAccountSheetTWD = () => {
    const { getAccountSheetTWD } = this.props;
    this.exportAccountSheet(getAccountSheetTWD);
  };

  onExportAccountSheetUSD = () => {
    const { getAccountSheetUSD } = this.props;
    this.exportAccountSheet(getAccountSheetUSD);
  };

  renderLiveMasterIdField = () => {
    const fieldId = 'liveMasterId';
    const { userList } = this.props;
    return (
      <FormItem noStyle>
        <FieldHeader>
          <label>匯出直播主</label>
          <Button type="link" onClick={this.onLiveMasterSelectAll}>
            <span>全選</span>
          </Button>
          <Button type="link" onClick={this.onLiveMasterSelectClear}>
            <span>清除</span>
          </Button>
        </FieldHeader>
        <FormItem name={fieldId}>
          <LiveMasterSelect
            placeholder="請選擇匯出帳號"
            mode="multiple"
            style={{ width: '100%' }}
            userListCatche={userList}
          />
        </FormItem>
      </FormItem>
    );
  };

  renderAgencyIdField = () => {
    const { agencyList } = this.props;
    return (
      <FormItem noStyle>
        <FieldHeader>
          <label>匯出經紀公司</label>
          <Button type="link" onClick={this.onAgencySelectAll}>
            <span>全選</span>
          </Button>
          <Button type="link" onClick={this.onAgencySelectClear}>
            <span>清除</span>
          </Button>
        </FieldHeader>
        <FormItem name="agencyIds">
          <Select
            mode="multiple"
            placeholder="請選擇匯出經紀公司"
            filterOption={(inputValue, option) =>
              option.title.indexOf(inputValue) > -1
            }
          >
            {agencyList.map(item => (
              <Option key={item.agencyId} title={item.agencyName}>
                {item.agencyName}
              </Option>
            ))}
          </Select>
        </FormItem>
      </FormItem>
    );
  };

  renderRangeLabel = currentRange => {
    if (isNil(currentRange)) {
      return null;
    }
    return (
      <DatesLabel>
        {`${currentRange[0].format('YYYY-MM-DD HH:mm:ss')} ~
          ${currentRange[1].format('YYYY-MM-DD HH:mm:ss')}`}
      </DatesLabel>
    );
  };

  renderRangePicker = () => {
    return (
      <>
        <FormItem
          name="currentRange"
          label="日期區間"
          initialValue={defaultRange}
          rules={[{ required: true, message: '請輸入匯出期間' }]}
        >
          <RangePicker
            style={{ width: '16rem' }}
            onChange={dates => {
              if (dates) {
                dates?.[0]?.startOf('day');
                dates?.[1]?.endOf('day');
              }
            }}
            disabledDate={current =>
              current > moment().endOf('day').subtract(1, 'day')
            }
          />
        </FormItem>
        <FormItem shouldUpdate>
          {({ getFieldValue }) =>
            this.renderRangeLabel(getFieldValue('currentRange'))
          }
        </FormItem>
      </>
    );
  };

  renderActions = () => {
    const disabled = !this.enableSubmitButton();
    return (
      <Actions>
        <Button
          type="primary"
          disabled={disabled}
          onClick={this.onExportAccountSheetUSD}
        >
          <DownloadOutlined />
          <span>匯出帳號資料</span>
        </Button>
        <Button
          type="primary"
          disabled={disabled}
          onClick={this.onExportAccountSheetTWD}
        >
          <DownloadOutlined />
          <span>匯出帳號資料(TWD)</span>
        </Button>
      </Actions>
    );
  };

  render() {
    return (
      <Form ref={this.formRef}>
        {this.renderLiveMasterIdField()}
        {this.renderAgencyIdField()}
        {this.renderRangePicker()}
        <FormItem shouldUpdate>{this.renderActions}</FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  userList: state.userManagement.userList,
  agencyList: state.agencyManagement.data,
  loading: state.accountSheetExport.loading
});

const mapDispatchToProps = {
  getUserList,
  getAccountSheetTWD,
  getAccountSheetUSD,
  getAgencyList
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSheetExport);
