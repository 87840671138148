import { map, switchMap, debounce, take } from 'rxjs/operators';
import { interval } from 'rxjs';
import { handleActions, createAction } from 'redux-actions';
import { pipe } from 'ramda';
import { catchRequestError, getPayloadByType } from '../utils/extendOperators';
import { searchLiveMasterListAPI } from '../apis';

/**
 * Action Types
 */

const SEARCH_LIVE_MASTER_BY_KEYWORD = 'SEARCH_LIVE_MASTER_BY_KEYWORD';
const SEARCH_LIVE_MASTER_BY_KEYWORD_SUCCESS =
  'SEARCH_LIVE_MASTER_BY_KEYWORD_SUCCESS';
const SEARCH_LIVE_MASTER_BY_KEYWORD_FAILURE =
  'SEARCH_LIVE_MASTER_BY_KEYWORD_FAILUREs';

/**
 * Action Creators
 */

export const searchLiveMasterByKeyword = (input, page = 0, size = 10) =>
  createAction(SEARCH_LIVE_MASTER_BY_KEYWORD)({ input, page, size });
const searchLiveMasterByKeywordSuccess = ({ identityList: data, totalCount }) =>
  createAction(SEARCH_LIVE_MASTER_BY_KEYWORD_SUCCESS)({ data, totalCount });
const searchLiveMasterByKeywordFailure = createAction(
  SEARCH_LIVE_MASTER_BY_KEYWORD_FAILURE
);

/**
 * Epics
 */

export const searchLiveMasterByKeywordEpic = pipe(
  getPayloadByType(SEARCH_LIVE_MASTER_BY_KEYWORD),
  debounce(payload => take(1)(interval(payload.debounce || 0))),
  switchMap(payload =>
    searchLiveMasterListAPI(payload).pipe(
      map(searchLiveMasterByKeywordSuccess),
      catchRequestError(searchLiveMasterByKeywordFailure)
    )
  )
);

/**
 * Reducer
 */
const initailState = {
  data: [],
  totalCount: 0,
  page: 0,
  size: 0,
  loading: false,
};

export default handleActions(
  {
    [SEARCH_LIVE_MASTER_BY_KEYWORD]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: true,
    }),
    [SEARCH_LIVE_MASTER_BY_KEYWORD_SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false,
    }),
  },
  initailState
);
