import { evolve, pick } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import {
  Form, Input, InputNumber,
  Select, Upload,
} from 'antd';
import {
  CloudUploadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import uploadCDN from '../../../utils/uploadCDN';
import { voiceChatTemplateConfig } from '../../../constants/voiceChat';
import validations from './validations';

const { Dragger } = Upload;
const { Option } = Select;

const TypeForm = styled(Form)`
  display: flex;
  align-items: stretch;
`;

const LeftPanel = styled.div`
  min-width: 23rem;
  flex-grow: 0;
  flex-shrink: 0;
`;

const RightPanel = styled.div`
  min-width: 20rem;
  margin-left: 1rem;
  flex-grow: 1;
`;

const ImageDragger = styled(Dragger)`
  .ant-upload {
    padding: 0 !important;
  }

  .ant-upload-text {
    white-space: nowrap;
  }

  &.has-error {
    border-color: red !important;
  }
`;

const FormItem = styled(Form.Item)`
  display: flex;
  align-items: flex-start;

  .ant-form-item-label {
    width: 8rem;
    flex-shrink: 0;
  }

  .ant-form-item-row {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
  }
`;

const BackgroundPreview = styled.div`
  width: 100%;
  padding-top: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const BackgroundHint = styled.div`
  position: relative;
  padding-top: 100%;
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .anticon {
    font-size: 1.5em;
  }

  .file-name {
    word-break: break-all;
  }
`;

class VoiceChatTypeForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      backgroundPreview: null,
      isBackgroundUploading: false,
    };
  }

  componentDidMount() {
    this.reset();
  }

  isEditMode = () => {
    const { mode } = this.props;
    return mode === 'edit';
  };

  getFormData = () => {
    const form = this.formRef.current;
    return evolve({
      maxSeats: Number,
      maxVipSeats: Number,
    }, form.getFieldsValue());
  };

  getFieldValue = (field) => {
    const form = this.formRef.current;
    return form.getFieldValue(field);
  };

  setFieldsValue = (field, value) => {
    const form = this.formRef.current;
    form.setFieldsValue({ [field]: value });
  }

  setFormData = (data) => {
    const form = this.formRef.current;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = pick(fields, data);
    form.setFieldsValue(values);

    const { backgroundUrl } = data;
    this.setState({ backgroundPreview: backgroundUrl });
  };

  validateFields = () => {
    const form = this.formRef.current;
    return form.validateFields();
  };

  reset = () => {
    const form = this.formRef.current;
    form.resetFields();

    this.setState({ backgroundPreview: null });
  };

  onFileSelect = (defaultRequest) => {
    // preset
    const { file } = defaultRequest;
    this.setState({
      isBackgroundUploading: true,
      backgroundName: file.name,
    });
    this.setFieldsValue('backgroundUrl', null);

    // do file upload
    const promise = uploadCDN(file, 'voiceChatBackground');
    promise.then((response) => {
      // set form data
      const { url } = response;
      this.setFieldsValue('backgroundUrl', url);

      // upload success callback
      defaultRequest.onSuccess();

      // set preview
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const backgroundPreview = reader.result;
        this.setState({
          backgroundPreview,
          isBackgroundUploading: false,
        });
      };
    });
  }

  shouldBackgroundFieldUpdate = (prevValues, curValues) => {
    const { backgroundUrl } = curValues;
    const { backgroundUrl: prevBackgroundUrl } = prevValues;
    const isDirty = backgroundUrl !== prevBackgroundUrl;
    return !backgroundUrl || isDirty;
  }

  onUiTemplateChanged = (_, option) => {
    const form = this.formRef.current;
    form.setFieldsValue({
      maxSeats: option.maxSeats,
      maxVipSeats: option.maxVipSeats
    });
  };

  renderBackgroundUploadHint = () => {
    return (
      <BackgroundHint>
        <div>
          <CloudUploadOutlined />
          <p>點擊/拖曳至此上傳背景底圖</p>
        </div>
      </BackgroundHint>
    );
  }

  renderBackgroundPreview = (src) => {
    return (
      <BackgroundPreview
        style={{ backgroundImage: `url(${src})` }}
      ></BackgroundPreview>
    );
  }

  renderBackgroundUploading = () => {
    const loadingStyle = {
      fontSize: '3rem',
      color: '#1890FF',
    };

    return (
      <BackgroundHint>
        <div>
          <LoadingOutlined style={loadingStyle} />
        </div>
      </BackgroundHint>
    );
  }

  renderBackgroundUpload = () => {
    const form = this.formRef.current;
    const { backgroundPreview, isBackgroundUploading } = this.state;

    const fieldErrors = form?.getFieldError('backgroundUrl');
    const hasError = fieldErrors?.length > 0;
    const cssClass = hasError ? 'has-error' : null;

    let content = null;
    if (isBackgroundUploading) {
      content = this.renderBackgroundUploading();
    } else if (backgroundPreview) {
      content = this.renderBackgroundPreview(backgroundPreview);
    } else {
      content = this.renderBackgroundUploadHint();
    }

    return (
      <ImageDragger
        name="file"
        multiple={false}
        customRequest={this.onFileSelect}
        showUploadList={false}
        className={cssClass}
      >
        {content}
      </ImageDragger>
    );
  };

  render() {
    const isEditMode = this.isEditMode();
    return (
      <TypeForm ref={this.formRef}>
        <LeftPanel>
          <FormItem
            label="類型名稱"
            name="name"
            rules={validations.name}
          >
            <Input
              id="name"
              placeholder="類型名稱"
            />
          </FormItem>
          <FormItem
            label="表情貼群組ID"
            name="stickerGroupId"
            initialValue={1}
            rules={validations.stickerGroupId}
          >
            <Input
              id="stickerGroupId"
              placeholder="表情貼群組ID"
            />
          </FormItem>
          <FormItem
            label="UI 版型"
            name="uiTemplate"
            rules={validations.uiTemplate}
          >
            <Select
              id="uiTemplate"
              placeholder="UI 版型"
              onChange={this.onUiTemplateChanged}
            >
              {voiceChatTemplateConfig.map(template => (
                <Option
                  key={template.id}
                  maxSeats={template.maxSeats}
                  value={template.id}
                  maxVipSeats={template.maxVipSeats}
                >{template.id} ({template.info})</Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label="麥位數量"
            name="maxSeats"
            rules={validations.maxSeats}
          >
            <InputNumber
              id="maxSeats"
              type="number"
              placeholder="麥位數量"
              disabled={true}
            />
          </FormItem>
          <FormItem
            label="VIP麥位數量"
            name="maxVipSeats"
            rules={validations.maxVipSeats}
          >
            <InputNumber
              id="maxVipSeats"
              type="number"
              placeholder="VIP麥位數量"
              disabled={true}
            />
          </FormItem>
          <FormItem
            name="backgroundUrl"
            rules={validations.backgroundUrl}
          >
            <Input type="hidden" />
          </FormItem>
          {isEditMode && (
            <FormItem name="id" hidden>
              <Input type="hidden" />
            </FormItem>
          )}
        </LeftPanel>
        <RightPanel>
          <FormItem
            shouldUpdate={this.shouldBackgroundFieldUpdate}
            noStyle
          >
            {this.renderBackgroundUpload}
          </FormItem>
        </RightPanel>
      </TypeForm>
    );
  }
}

export default VoiceChatTypeForm;
