import produce from 'immer';
import { assocPath, is } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  addBottomTabBarAPI,
  deleteBottomTabBarAPI,
  getBottomTabBarAPI,
  getBottomTabBarListAPI,
  setBottomTabBarAPI
} from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';

/**Type */
const GET_BOTTOMTABBAR_LIST = createRequestTypes('GET_BOTTOMTABBAR_LIST');
const ADD_BOTTOMTABBAR = createRequestTypes('ADD_BOTTOMTABBAR');
const GET_BOTTOMTABBAR = createRequestTypes('GET_BOTTOMTABBAR');
const SET_BOTTOMTABBAR = createRequestTypes('SET_BOTTOMTABBAR');
const DELETE_BOTTOMTABBAR = createRequestTypes('DELETE_BOTTOMTABBAR');
const SHOW_BOTTOMTABBAR_MODAL = 'SHOW_BOTTOMTABBAR_MODAL';

/**
 * Action Creator
 */
export const getBottomTabBarList = createAction(GET_BOTTOMTABBAR_LIST.REQUEST);
export const getBottomTabBarListSuccess = createAction(
  GET_BOTTOMTABBAR_LIST.SUCCESS
);
export const getBottomTabBarListFailure = createAction(
  GET_BOTTOMTABBAR_LIST.FAILURE
);
export const showModal = createAction(
  SHOW_BOTTOMTABBAR_MODAL,
  (isShowModal = true) => isShowModal
);
export const addBottomTabBar = createAction(
  ADD_BOTTOMTABBAR.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback
  })
);
export const addBottomTabBarSuccess = createAction(ADD_BOTTOMTABBAR.SUCCESS);
export const addBottomTabBarFailure = createAction(ADD_BOTTOMTABBAR.FAILURE);
export const getBottomTabBar = createAction(GET_BOTTOMTABBAR.REQUEST);
export const getBottomTabBarSuccess = createAction(GET_BOTTOMTABBAR.SUCCESS);
export const getBottomTabBarFailure = createAction(GET_BOTTOMTABBAR.FAILURE);
export const setBottomTabBar = createAction(
  SET_BOTTOMTABBAR.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const setBottomTabBarSuccess = createAction(SET_BOTTOMTABBAR.SUCCESS);
export const setBottomTabBarFailure = createAction(SET_BOTTOMTABBAR.FAILURE);
export const deleteBottomTabBar = createAction(
  DELETE_BOTTOMTABBAR.REQUEST,
  (id, callbackAction) => ({ id, callbackAction })
);
export const deleteBottomTabBarSuccess = createAction(
  DELETE_BOTTOMTABBAR.SUCCESS
);
export const deleteBottomTabBarFailure = createAction(
  DELETE_BOTTOMTABBAR.FAILURE
);

/**
 * Epics
 */
export const getBottomTabBarListEpic = action$ =>
  action$.pipe(
    ofType(GET_BOTTOMTABBAR_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getBottomTabBarListAPI(payload).pipe(
        map(getBottomTabBarListSuccess),
        catchRequestError(getBottomTabBarListFailure)
      )
    )
  );

export const addBottomTabBarEpic = action$ =>
  action$.pipe(
    ofType(ADD_BOTTOMTABBAR.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      addBottomTabBarAPI(payload).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [addBottomTabBarSuccess(res), callbackAction]
            : [addBottomTabBarSuccess(res)];
        }),
        catchRequestError(addBottomTabBarFailure)
      )
    )
  );

export const getBottomTabBarEpic = action$ =>
  action$.pipe(
    ofType(GET_BOTTOMTABBAR.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getBottomTabBarAPI(payload).pipe(
        map(res =>
          getBottomTabBarSuccess(assocPath(['data', 'id'], payload, res))
        ),
        catchRequestError(getBottomTabBarFailure)
      )
    )
  );

export const setBottomTabBarEpic = action$ =>
  action$.pipe(
    ofType(SET_BOTTOMTABBAR.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction }) =>
      setBottomTabBarAPI(payload).pipe(
        mergeMap(res =>
          callbackAction
            ? [setBottomTabBarSuccess(res), callbackAction]
            : [setBottomTabBarSuccess(res)]
        ),
        catchRequestError(setBottomTabBarFailure)
      )
    )
  );

export const deleteBottomTabBarEpic = action$ =>
  action$.pipe(
    ofType(DELETE_BOTTOMTABBAR.REQUEST),
    pluck('payload'),
    switchMap(({ id, callbackAction }) =>
      deleteBottomTabBarAPI(id).pipe(
        mergeMap(res =>
          callbackAction
            ? [deleteBottomTabBarSuccess(res), callbackAction]
            : [deleteBottomTabBarSuccess(res)]
        ),
        catchRequestError(deleteBottomTabBarFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  currentTab: null,
  loading: false,
  isShowModal: false
};

export default handleActions(
  {
    [combineActions(
      GET_BOTTOMTABBAR_LIST.REQUEST,
      ADD_BOTTOMTABBAR.REQUEST,
      SET_BOTTOMTABBAR.REQUEST,
      DELETE_BOTTOMTABBAR.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [GET_BOTTOMTABBAR.REQUEST]: produce(draft => {
      draft.loading = true;
      draft.currentTab = null;
    }),
    [GET_BOTTOMTABBAR.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentTab = payload.data;
    }),
    [GET_BOTTOMTABBAR.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.currentTab = null;
      draft.isShowModal = false;
    }),
    [combineActions(ADD_BOTTOMTABBAR.SUCCESS, SET_BOTTOMTABBAR.SUCCESS)]:
      produce((draft, { payload }) => {
        draft.loading = false;
        draft.isShowModal = false;
      }),
    [GET_BOTTOMTABBAR_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
    }),
    [GET_BOTTOMTABBAR_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
    }),
    [combineActions(
      ADD_BOTTOMTABBAR.FAILURE,
      SET_BOTTOMTABBAR.FAILURE,
      DELETE_BOTTOMTABBAR.SUCCESS,
      DELETE_BOTTOMTABBAR.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_BOTTOMTABBAR_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
