import { combineActions, createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { switchMap, pluck, map, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getSeriesListAPI, setSeriesListAPI } from '../apis';
import { createRequestTypes } from '../actions/Types';
import { catchRequestError } from '../utils/extendOperators';
import { message } from 'antd';

/**
 * Action Types
 */

const GET_SERIES_LIST = createRequestTypes('GET_SERIES_LIST');
const SET_SERIES_LIST = createRequestTypes('SET_SERIES_LIST');

/**
 * Action Creators
 */

export const getSeriesList = createAction(GET_SERIES_LIST.REQUEST);
export const getSeriesListSuccess = createAction(GET_SERIES_LIST.SUCCESS);
export const getSeriesListFailure = createAction(GET_SERIES_LIST.FAILURE);
export const setSeriesList = createAction(SET_SERIES_LIST.REQUEST);
export const setSeriesListSuccess = createAction(SET_SERIES_LIST.SUCCESS);
export const setSeriesListFailure = createAction(SET_SERIES_LIST.FAILURE);
/**
 * Epics
 */

export const getSeriesListEpic = action$ =>
  action$.pipe(
    ofType(GET_SERIES_LIST.REQUEST),
    pluck('payload'),
    switchMap(() =>
      getSeriesListAPI().pipe(
        map(getSeriesListSuccess),
        catchRequestError(getSeriesListFailure)
      )
    )
  );

export const setSeriesListEpic = action$ =>
  action$.pipe(
    ofType(SET_SERIES_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      setSeriesListAPI(payload).pipe(
        mergeMap(res => {
          message.success('儲存成功');
          return [setSeriesListSuccess(res), getSeriesList()];
        }),
        catchRequestError(setSeriesListFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  loading: false,
  data: []
};

export default handleActions(
  {
    [combineActions(GET_SERIES_LIST.REQUEST, SET_SERIES_LIST.REQUEST)]: produce(
      draft => {
        draft.loading = true;
      }
    ),
    [GET_SERIES_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
    }),
    [GET_SERIES_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
    }),
    [combineActions(SET_SERIES_LIST.SUCCESS, SET_SERIES_LIST.FAILURE)]: produce(
      draft => {
        draft.loading = false;
      }
    )
  },
  initialState
);
