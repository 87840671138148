import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { groupBy } from 'ramda';
import {
  mergeMap,
  switchMap,
  pluck,
  catchError,
  concatAll,
  reduce
} from 'rxjs/operators';
import { ofType } from '../utils/extendOperators';
import { addMessageAPI } from '../apis';
import { of } from 'rxjs';
import { createRequestTypes } from '../actions/Types';
import { message } from 'antd';

/**
 * Action Types
 */
const SHOW_MODAL = 'MESSAGEMANAGEMENT.SHOW_MODAL';
const ADD_MESSAGE = createRequestTypes('ADD_MESSAGE');

/**
 * Action Creators
 */
export const showModal = createAction(SHOW_MODAL);

export const addMessage = createAction(ADD_MESSAGE.REQUEST);
export const addMessageSuccess = createAction(ADD_MESSAGE.SUCCESS);
export const addMessageFailure = createAction(ADD_MESSAGE.FAILURE);

/**
 * Epics
 */
export const addMessageEpic = action$ =>
  action$.pipe(
    ofType(ADD_MESSAGE.REQUEST),
    pluck('payload'),
    switchMap(payload => {
      return of(
        ...payload.map(data =>
          addMessageAPI(data).pipe(
            catchError(e => {
              if (e)
                return of({
                  Status: 'error',
                  msgType: data.msgType,
                  message: e.response.Message || e.message
                });
            })
          )
        )
      ).pipe(
        concatAll(),
        reduce((acc, val) => [...acc, val], [])
      );
    }),
    mergeMap(e => {
      const errors = groupBy(v => v.Status)(e);
      if (errors['error'] && errors['error'].length > 0) {
        errors['error'].map(e =>
          message.error(`${e.msgType} 發生錯誤，訊息：${e.message}。`)
        );
        return [addMessageFailure()];
      } else {
        return [addMessageSuccess(e), showModal(false)];
      }
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  isShowModal: false,
  loading: false
};

export default handleActions(
  {
    [ADD_MESSAGE.REQUEST]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [ADD_MESSAGE.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [ADD_MESSAGE.FAILURE]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
