import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const { Column } = Table;

class LiveMasterNetworkSpeedTable extends React.Component {
  static propTypes = {
    users: PropTypes.array,
    dataSource: PropTypes.array,
  }

  static defaultProps = {
    users: [],
    dataSource: [],
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderNickname = (rowData) => {
    const { users } = this.props;
    const { loginId } = rowData;
    const user = _.find(users, { loginId });
    return user ? user.nickname : '';
  }

  renderTime = (time) => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    return moment(time).format(format);
  }

  render() {
    const { dataSource } = this.props;
    return (
      <Table
        rowKey="id"
        dataSource={dataSource}
      >
        <Column
          title="nickname"
          render={this.renderNickname}
        />
        <Column
          title="createTime"
          dataIndex="createTime"
          render={this.renderTime}
        />
        <Column
          title="platform"
          dataIndex="platform"
        />
        <Column
          title="roomId"
          dataIndex="roomId"
        />
        <Column
          title="speed"
          dataIndex="speed"
        />
        <Column
          title="appVersion"
          dataIndex="appVersion"
        />
      </Table>
    );
  }
}

export default LiveMasterNetworkSpeedTable;
