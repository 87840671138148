import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from 'antd';
import ColorPicker from '../../../components/ColorPicker';

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;

  .pickr {
    line-height: 1;
  }

  label {
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

class ColorSelector extends React.Component {
  static propTypes = {
    value: PropTypes.string,
  };

  static defaultProps = {
    value: '#000000',
  };

  constructor(props) {
    super(props);

    this.ColorPickerRef = React.createRef();

    this.state = {};
  }

  triggerChange = (value) => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  }

  onPickerClick = () => {
    const picker = this.ColorPickerRef.current;
    picker.show();
  };

  onPickerSelected = (value, instance) => {
    const color = value.toHEXA().toString();
    this.triggerChange(color);
    instance.hide();
  };

  render() {
    const { value } = this.props;
    return (
      <Wrapper>
        <ColorPicker
          ref={this.ColorPickerRef}
          defaultValue={value}
          onSave={this.onPickerSelected}
        />
        <label onClick={this.onPickerClick}>
          <span>{value}</span>
        </label>
        <Input
          type="hidden"
          placeholder="#000000"
          defaultValue={value}
        />
      </Wrapper>
    );
  }
}

export default ColorSelector;
