export default function columns(comp) {
  return Object.freeze([{
    key: 'ranking',
    title: '排名',
    width: '8rem',
    align: 'center',
    render: comp.renderRanking,
  }, {
    key: 'friendshipRank',
    title: '親密度等級',
    width: '8rem',
    align: 'center',
    dataIndex: 'friendshipRank',
  }, {
    key: 'avatar',
    title: '頭像',
    width: '8rem',
    align: 'center',
    render: comp.renderAvatar,
  }, {
    key: 'name',
    title: '暱稱',
    dataIndex: 'name',
  }, {
    key: 'oopsvipId',
    title: 'OOPSVIP ID',
    dataIndex: 'oopsvipId',
  }]);
};
