import React, { Component } from 'react';
import styled from 'styled-components';
import { Select, Input, Button, Form } from 'antd';
import { reported as fakeData } from '../fake/liveStream';
import {
  LiveCard,
  ReportedStreamsModal,
} from '../components';

const Option = Select.Option;
const FormItem = Form.Item;
const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  min-width: 850px;

  > .row {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }

  > .align-right {
    margin-left: auto;
  }
`;

const LiveCardCollection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

class ReportedLive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modalInfo: {
        account: '',
        imgUrl: '',
        tableData: [],
      },
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    console.log(`你搜尋了 ${e.target[0].value}`);
  };

  onImageClickWithInfo = d => () => {
    this.setState({
      isModalVisible: true,
      modalInfo: {
        account: d.account,
        imgUrl: d.picture,
        tableData: [
          {
            index: d.index,
            reporterAccount: d.reporterAccount,
            timeStamp: d.timeStamp,
            reason: d.reason,
          },
        ],
      },
    });
  };

  render() {
    return (
      <div>
        <ReportedStreamsModal
          visible={this.state.isModalVisible}
          onCancel={() => this.setState({ isModalVisible: false })}
          info={this.state.modalInfo}
        />
        <StyledHeader>
          <div className="row">
            <h1>直播管理 > 被檢舉直播</h1>
          </div>
          <div className="row">
            <h3 className="title">顯示：</h3>
            <Select defaultValue="reported" style={{ width: 120 }}>
              <Option value="live">直播中</Option>
              <Option value="off">已下架</Option>
              <Option value="reported">被檢舉直播</Option>
            </Select>
          </div>
          <div className="row align-right">
            <Form layout="inline" onSubmit={this.handleSubmit}>
              <FormItem>
                <Input placeholder="搜尋" style={{ width: 200 }} />
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  搜尋
                </Button>
              </FormItem>
            </Form>
          </div>
        </StyledHeader>
        <LiveCardCollection>
          {fakeData.map(d => (
            <LiveCard
              key={d.index}
              imgUrl={d.picture}
              onImageClick={this.onImageClickWithInfo(d)}
            >
              <h3>{d.account}</h3>
            </LiveCard>
          ))}
        </LiveCardCollection>
      </div>
    );
  }
}

export default ReportedLive;
