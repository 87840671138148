import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Button, Table, Tag } from 'antd';
import { DeleteOutlined ,EditOutlined } from '@ant-design/icons';
import TagManageModal from './TagManageModal';
import removeTag from './removeTag';
import { getBackendTagListAPI } from '../../apis';

const { Column } = Table;

const Wrapper = styled.section`
  min-width: 850px;
  margin-right: 1rem;
  padding-bottom: 1rem;
`;

const Tools = styled.div`
  margin-bottom: 1rem;
`;

const TagTable = styled(Table)`
  .tag-groups {
    span {
      margin: 0.25rem;
    }

    .ant-tag {
      font-size: inherit;
    }
  }
`;

const TagRowColor = styled.div`
  display: flex;
  align-items: center;

  i {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
  }

  span {
    margin-left: 0.5rem;
    text-transform: uppercase;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    border: 0;
    background: none;
    font-size: 1rem;
    box-shadow: none;
    overflow: hidden;

    &.delete {
      &:hover,
      &:focus {
        color: #ff4d4f;
      }
    }
  }
`;

class TagManagement extends React.Component {
  constructor(props) {
    super(props);

    this.TagManageModalRef = React.createRef();

    this.state = {
      tags: [],
      pagination: {
        showSizeChanger: false,
        pageSize: 20,
        total: 0,
        current: 1
      }
    };
  }

  componentDidMount() {
    this.fetchTags();
  }

  showTagManageModal = (action, data) => {
    const modal = this.TagManageModalRef.current;
    modal.show(action, data);
  };

  fetchTags = () => {
    const { pagination } = this.state;
    const { current, pageSize } = pagination;

    const observable = getBackendTagListAPI({
      page: current,
      item: pageSize
    });

    observable.subscribe(res => {
      const { data, totalCount } = res;
      this.setState(state => ({
        tags: data,
        pagination: { ...state.pagination, total: totalCount }
      }));
    });
  };

  onCreateBtnClick = () => {
    this.showTagManageModal('create');
  };

  onEditBtnClick = rowData => {
    const groups = _.get(rowData, 'groups');
    const iGroups = _.map(groups, group => group.id);
    const data = Object.assign({}, rowData, { groups: iGroups });
    this.showTagManageModal('edit', data);
  };

  onRemoveBtnClick = id => {
    removeTag(id, this.fetchTags);
  };

  onTableChange = iPagination => {
    this.setState({ pagination: iPagination }, () => {
      this.fetchTags();
    });
  };

  renderTagTableGroup = (text, record) => {
    const groups = _.get(record, 'groups');

    if (_.isEmpty(groups)) return;

    return (
      <React.Fragment>
        {groups.map(group => (
          <Tag key={group.id}>{group.label}</Tag>
        ))}
      </React.Fragment>
    );
  };

  renderTagTableColor = (text, record) => {
    const { color } = record;
    const style = { backgroundColor: color };
    return (
      <TagRowColor>
        <i style={style}></i>
        <span>{color}</span>
      </TagRowColor>
    );
  };

  renderTagTabelActions = (text, record) => {
    const { id } = record;
    return (
      <Actions>
        <Button
          className="edit"
          onClick={() => this.onEditBtnClick(record)}
        >
          <EditOutlined />
        </Button>
        <Button
          className="delete"
          onClick={() => this.onRemoveBtnClick(id)}
        >
          <DeleteOutlined />
        </Button>
      </Actions>
    );
  };

  render() {
    const { tags, pagination } = this.state;

    return (
      <Wrapper>
        <h1>{'APP 管理 > 標籤(Tag)管理'}</h1>
        <Tools>
          <Button
            type="primary"
            onClick={this.onCreateBtnClick}
          >
            <span>新增</span>
          </Button>
        </Tools>
        <TagTable
          dataSource={tags}
          rowKey="id"
          pagination={pagination}
          onChange={this.onTableChange}
        >
          <Column
            title="標籤名稱"
            dataIndex="label"
            key="label"
            width="30%"
          />
          <Column
            title="所屬群組"
            width="30%"
            key="group"
            className="tag-groups"
            render={this.renderTagTableGroup}
          />
          <Column
            title="色碼"
            key="color"
            render={this.renderTagTableColor}
          />
          <Column title="類型" dataIndex="type" key="type" />
          <Column
            key="_action"
            width="10rem"
            render={this.renderTagTabelActions}
          />
        </TagTable>
        <TagManageModal
          ref={this.TagManageModalRef}
          onCompleted={this.fetchTags}
        ></TagManageModal>
      </Wrapper>
    );
  }
}

export default TagManagement;
