import {
  compose, find, groupBy,
  indexBy, keys, map,
  prepend, prop, propEq,
  sortBy, toPairs,
} from 'ramda';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, DatePicker, Form } from 'antd';
import { LineChartOutlined, SearchOutlined } from '@ant-design/icons';
import { getLiveMasterNetworkSpeedSheet } from '../../reducers/accountSheetExport';
import { PageContainer, PageTitle } from '../../components/styled/page';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import LiveMasterNetworkSpeedChart from './LiveMasterNetworkSpeedChart';
import LiveMasterNetworkSpeedTable from './LiveMasterNetworkSpeedTable';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;

// 取得所有的loginId
const getLoginIds = compose(keys, indexBy(prop('loginId')));

// 格式化為 chart Data
const formatChartData = (data, columns) => {
  return compose(
    prepend([{ type: 'string' }, ...columns]),
    map(value => [
      moment(Number(value[0])).format('MM/DD HH:mm:ss'),
      ...map(col => {
        return prop('speed', find(propEq('loginId', col))(value[1]));
      }, columns)
    ]),
    sortBy(prop(0)),
    toPairs,
    groupBy(prop('createTime'))
  )(data);
};

class LiveMasterNetworkSpeedSheetExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartVisible: false,
      chartData: [],
      selectedUser: [],
      confirmedUser: []
    };
  }

  onUserSelectChange = userList => {
    this.setState({ selectedUser: userList });
  };

  onShowChartClick = () => {
    const { networkSpeedSheet } = this.props;
    const chartData = formatChartData(
      networkSpeedSheet,
      getLoginIds(networkSpeedSheet)
    );
    this.setState({
      chartData,
      chartVisible: true,
    });
  };

  onSubmit = (values) => {
    this.setState({
      chartVisible: false,
      chartData: [],
      confirmedUser: [...this.state.selectedUser],
    });
    this.props.getLiveMasterNetworkSpeedSheet(
      values.userIds,
      values.currentRange,
    );
  };

  render() {
    const { loading, networkSpeedSheet } = this.props;
    const { chartData, chartVisible, confirmedUser } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'直播管理 > 直播主網速查詢'}</PageTitle>
        <Form onFinish={this.onSubmit}>
          <FormItem
            name="userIds"
            label="匯出帳號"
            rules={[
              { required: true, message: '請輸入匯出帳號' },
            ]}
          >
            <LiveMasterSelect
              style={{ width: '100%' }}
              mode="multiple"
              onSelectChange={this.onUserSelectChange}
            />
          </FormItem>
          <FormItem
            name="currentRange"
            label="匯出期間"
            rules={[
              { required: true, message: '請輸入匯出期間' },
            ]}
          >
            <RangePicker
              style={{ width: 368 }}
              showTime={{
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('23:59:59', 'HH:mm:ss')
                ]
              }}
              format="YYYY-MM-DD HH:mm:ss"
              disabledDate={current => current > moment().endOf('day')}
            />
          </FormItem>
          <FormItem style={{ marginBottom: '16px' }}>
            <Button
              htmlType="submit"
              loading={loading}
            >
              <SearchOutlined />
              <span>查詢帳號資料</span>
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              onClick={this.onShowChartClick}
              disabled={!networkSpeedSheet.length}
            >
              <LineChartOutlined />
              <span>顯示圖表</span>
            </Button>
          </FormItem>
        </Form>
        {chartData.length > 0 && (
          <LiveMasterNetworkSpeedChart
            style={{ marginBottom: '1.5rem' }}
            dataset={chartData}
            visible={chartVisible}
          />
        )}
        {networkSpeedSheet.length > 0 && (
          <LiveMasterNetworkSpeedTable
            users={confirmedUser}
            dataSource={networkSpeedSheet}
          />
        )}
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { accountSheetExport } = state;
  return {
    loading: accountSheetExport.loading,
    networkSpeedSheet: accountSheetExport.networkSpeedSheet,
  };
};

const mapDispatchToProps = {
  getLiveMasterNetworkSpeedSheet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveMasterNetworkSpeedSheetExport);
