import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import EnquiryForm from './EnquiryForm';

const defaultState = {
  visible: false,
};

class EnquiryModal extends React.Component {

  static propTypes = {
    onConfirm: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = { ...defaultState };
  }

  show = (dataset) => {
    this.setState({ dataset, visible: true }, () => {
      requestAnimationFrame(() => {
        const { form } = this;
        form.reset();
        if (dataset) form.setFormData(dataset);
      });
    });
  };

  hide = () => {
    this.setState({ ...defaultState });
  }

  detectTitle = () => {
    const { dataset } = this.state;
    const nickname = _.get(dataset, 'user.nickname');
    return `用戶：${nickname}`;
  }

  onConfirm = () => {
    const { form } = this;
    const { onConfirm } = this.props;

    form.validateFields((errors, values) => {
      if (errors) return;
      if (onConfirm) onConfirm(values);
      this.hide();
    });
  };

  render() {
    const { visible } = this.state;
    return (
      <Modal
        title={this.detectTitle()}
        visible={visible}
        centered
        maskClosable
        onOk={this.onConfirm}
        onCancel={this.hide}
      >
        <EnquiryForm
          wrappedComponentRef={form => (this.form = form)}
        />
      </Modal>
    );
  }
}

export default EnquiryModal;
