import {
  Button, Form, Input,
  InputNumber, Modal, Popconfirm,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import UploadImg from '../../components/UploadImg';
import { addPointTransfer, showModal } from '../../reducers/pointTransfer';
import { isReallyEmpty } from '../../utils/mixin';
import hasErrors from '../../utils/hasErrors';

const FormItem = Form.Item;

const AddPointTransferModal = ({
  addPointTransfer,
  isShowModal,
  loading,
  showModal,
  uuid,
}) => {
  const [form] = Form.useForm();

  const imageValidator = (rule, value) => {
    const remark = form.getFieldValue('remark');
    if (isReallyEmpty(remark) && isReallyEmpty(value)) {
      const error = new Error('需填寫「備註」或「上傳截圖」作為證明方可轉移點數');
      return Promise.reject(error);
    }
    return Promise.resolve();
  };

  const onRemarkChange = () => {
    form.validateFields(['image']);
  };

  const onSubmit = () => {
    const validatePromise = form.validateFields();
    validatePromise.then((values) => {
      addPointTransfer(values);
    });
  };

  return (
    <Modal
      title="新增站內轉點"
      visible={isShowModal}
      destroyOnClose={true}
      onCancel={() => showModal(false)}
      confirmLoading={loading}
      footer={[
        <Button key="cancle" onClick={() => showModal(false)}>
          <span>取消</span>
        </Button>,
        <Popconfirm
          key="confirm"
          title={'是否確定新增'}
          placement="topLeft"
          onConfirm={onSubmit}
        >
          <Button
            key={'add'}
            loading={loading}
            type="primary"
            style={{ marginLeft: 8 }}
            disabled={hasErrors(form.getFieldsError())}
          >
            <span>確定</span>
          </Button>
        </Popconfirm>
      ]}
    >
      <Form form={form} layout="vertical">
        <FormItem
          label="轉出對象"
          name="receiverId"
          rules={[{ required: true, message: '請輸入對象' }]}
        >
          <LiveMasterSelect roleFilter="" />
        </FormItem>
        <FormItem
          label="點數"
          name="point"
          rules={[
            { required: true, message: '請輸入點數' },
            { type: 'number', message: '請輸入正確的數字' },
          ]}
        >
          <InputNumber placeholder="請輸入點數" style={{ width: '100%' }} />
        </FormItem>
        <FormItem
          label="備註"
          name="remark"
        >
          <Input
            placeholder="請輸入備註"
            onChange={onRemarkChange}
          />
        </FormItem>
        <FormItem
          label="上傳截圖（最多三張）"
          name="image"
          rules={[
            { validator: imageValidator },
          ]}
        >
          <UploadImg uploadUrl="/cdn/backend/photo" mode="multiple" />
        </FormItem>
        <FormItem
          name="senderId"
          initialValue={uuid}
          rules={[{ required: true }]}
          hidden
        >
          <Input type="hidden" />
        </FormItem>
      </Form>
    </Modal>
  );
};

const mapState = (state) => {
  const { pointTransfer } = state;
  const { isShowModal, loading } = pointTransfer;
  return { isShowModal, loading };
};

const mapDispatch = {
  showModal,
  addPointTransfer,
};

export default connect(
  mapState,
  mapDispatch,
)(AddPointTransferModal);
