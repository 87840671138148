import { Modal } from 'antd';
import { deleteBackendTagGroupAPI } from '../../apis';

const { confirm } = Modal;

function removeTagGroup(id, onCompleted) {
  confirm({
    title: '刪除標籤群組',
    content: '標籤群組刪除後，將無法復原，確定要刪除？',
    okType: 'danger',
    centered: true,
    maskClosable: true,
    onOk() {
      const observable = deleteBackendTagGroupAPI(id);
      observable.subscribe(onCompleted);
    }
  });
}

export default removeTagGroup;
