import React from 'react';
import AdManagement from './AdManagement';
import { PageContainer, PageTitle } from '../../components/styled/page';

const EventBanner = () => (
  <PageContainer>
    <PageTitle>{'首頁管理 > Event Banner'}</PageTitle>
    <AdManagement zone="EVENT" type="0" />
  </PageContainer>
);
export default EventBanner;
