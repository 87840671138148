import _ from 'lodash';
import React from 'react';
import { Select } from 'antd';
import { getVoiceChatList } from '../../apis';

const { Option } = Select;

class VoiceChatRoomSelector extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      roomList: [],
    };
  }

  componentDidMount() {
    this.fetchVoiceChatRoomList();
  }

  filterOption = (input, options) => {
    const title = _.get(options, 'label', '').toLowerCase();
    const text = input.toLowerCase();
    return title && title.indexOf(text) >= 0;
  }

  fetchVoiceChatRoomList = () => {
    const obserable = getVoiceChatList();
    obserable.subscribe((response) => {
      const { data: roomList } = response;
      this.setState({ roomList });
    });
  }

  onSearch = _.debounce(this.fetchVoiceChatRoomList, 300);

  renderOptions = () => {
    const { roomList } = this.state;
    const hasOption = roomList.length >= 0;
    return hasOption && roomList.map((room) => {
      const { roomId, roomTitle } = room;
      return (
        <Option
          key={roomId}
          value={roomId}
          label={roomTitle}
        >
          <span>{roomTitle}</span>
        </Option>
      );
    });
  }

  render() {
    return (
      <Select
        style={this.props.style}
        value={this.props.value}
        filterOption={this.filterOption}
        placeholder="請輸入聊天室標題"
        onSearch={this.onSearch}
        onChange={this.props.onChange}
        showSearch
      >
        {this.renderOptions()}
      </Select>
    );
  }
}

export default VoiceChatRoomSelector;
