import {
  __, evolve, identity,
  includes, useWith as ramdaUseWith,
} from 'ramda';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Button, DatePicker, Form,
  Input, Modal, Popconfirm,
  Table,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  addGiftListCategory,
  deleteGiftListCategory,
  showModal,
} from '../../reducers/giftListCategories';
import antdTablePagination from '../../utils/antdTablePagination';
import { giftType } from '../../constants/gifts';
import LightBox from '../../components/LightBox';
import UploadImg from '../../components/UploadImg';

const { Column } = Table;
const FormItem = Form.Item;

const filterFieldByType = ramdaUseWith(
  (isInclude, render) => (isInclude ? render() : null),
  [includes(__, [giftType.liveRoom.value, giftType.liveShow.value]), identity]
);

const MainModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
  }
`;

class ItemCategoriesManagement extends React.Component {
  constructor(props) {
    super(props);

    this.createFromRef = React.createRef();

    this.state = {
      defaultPagination: new antdTablePagination(),
    };
  }

  detectStartTimeDisabledDate = current => {
    const yesterday = moment().subtract(1, 'day');
    const limit = yesterday.endOf('day');
    return current < limit;
  };

  detectEndTimeDisabledDate = current => {
    const form = this.createFromRef.current;
    const oStartTime = form.getFieldValue('startTime');
    const startTime = oStartTime ? oStartTime.clone() : moment();
    const limit = startTime.subtract(1, 'day').endOf('day');
    return current < limit;
  };

  onStartTimeChange = () => {
    const form = this.createFromRef.current;
    form.resetFields(['endTime']);
  };

  onSubmit = () => {
    const form = this.createFromRef.current;
    const iPromise = form.validateFields();
    iPromise.then(values => {
      const { addGiftListCategory, showModal } = this.props;
      const payload = evolve({
        startTime: t => (t && moment(t).format('X')) || null,
        endTime: t => (t && moment(t).format('X')) || null
      })(values);
      addGiftListCategory(payload);
      showModal(false);
    });
  };

  renderMainModalTitle = () => {
    const { showModal, type, giftListCategories } = this.props;
    // 在背包禮物下和隨機禮物下，只能有一筆分類
    const isDisabled =
      (type === 7 || type === 8) && giftListCategories.length >= 1;
    return (
      <MainModalHeader>
        <h4>禮物分類列表</h4>
        <Button
          type="primary"
          shape="round"
          disabled={isDisabled}
          onClick={() => showModal(true)}
        >
          <PlusOutlined />
          <span>新增禮物分類</span>
        </Button>
      </MainModalHeader>
    );
  };

  renderMainTable = () => {
    const {
      giftListCategories, item, loading,
      type, totalCount,
    } = this.props;
    const { defaultPagination } = this.state;
    return (
      <Table
        loading={loading}
        style={{ borderRadius: '4px', overflow: 'hidden' }}
        scroll={{ y: 455 }}
        rowKey="id"
        dataSource={giftListCategories}
        pagination={{
          ...defaultPagination,
          total: totalCount,
          pageSize: item
        }}
      >
        <Column key="categoryName" title="分類名稱" dataIndex="categoryName" />
        <Column
          key="startTime"
          title="上架時間"
          dataIndex="startTime"
          render={this.renderTimeField}
        />
        <Column
          key="endTime"
          title="下架時間"
          dataIndex="endTime"
          render={this.renderTimeField}
        />
        {filterFieldByType(type, () => (
          <Column key="banner" title="圖片" render={this.renderBannerField} />
        ))}
        {filterFieldByType(type, () => (
          <Column key="url" title="URL" dataIndex="url" />
        ))}
        <Column key="id" title="封存分類" render={this.renderArchiveField} />
      </Table>
    );
  };

  renderTimeField = value => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    const display = moment.unix(value).format(format);
    return <span>{display}</span>;
  };

  renderBannerField = rowData => {
    const { banner } = rowData;
    return <LightBox src={banner} />;
  };

  renderArchiveField = rowData => {
    const { deleteGiftListCategory } = this.props;
    const { id } = rowData;
    return (
      <Popconfirm
        title="是否確定封存"
        onConfirm={() => deleteGiftListCategory(id)}
        placement="right"
      >
        <Button>封存</Button>
      </Popconfirm>
    );
  };

  renderCreateModalFooter = () => {
    const { loading } = this.props;
    return (
      <Button
        key="submit"
        type="primary"
        htmlType="submit"
        loading={loading}
        onClick={this.onSubmit}
      >
        <span>新增</span>
      </Button>
    );
  };

  renderCreateForm = () => {
    const { type } = this.props;
    return (
      <Form ref={this.createFromRef} layout="vertical" onSubmit={this.onSubmit}>
        <FormItem
          name="categoryName"
          label="禮物分類名稱"
          rules={[{ required: true, message: '請輸入禮物分類名稱' }]}
        >
          <Input />
        </FormItem>
        <FormItem name="startTime" label="上架時間">
          <DatePicker
            style={{ width: '100%' }}
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            disabledDate={this.detectStartTimeDisabledDate}
            onChange={this.onStartTimeChange}
          />
        </FormItem>
        <FormItem name="endTime" label="下架時間">
          <DatePicker
            style={{ width: '100%' }}
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
            disabledDate={this.detectEndTimeDisabledDate}
          />
        </FormItem>
        {filterFieldByType(type, () => (
          <FormItem name="banner" label="圖片">
            <UploadImg uploadUrl="/cdn/category/banner" />
          </FormItem>
        ))}
        {filterFieldByType(type, () => (
          <FormItem name="url" label="URL">
            <Input />
          </FormItem>
        ))}
        <FormItem name="type" initialValue={type} hidden>
          <Input type="hidden" />
        </FormItem>
      </Form>
    );
  };

  render() {
    const { isShowModal, showModal, visible, onClose } = this.props;
    return (
      <React.Fragment>
        <Modal
          title={this.renderMainModalTitle()}
          centered
          width={928}
          bodyStyle={{ paddingBottom: 8 }}
          visible={visible}
          footer={null}
          closable={false}
          onCancel={onClose}
          destroyOnClose={true}
        >
          {this.renderMainTable()}
        </Modal>
        <Modal
          visible={isShowModal}
          footer={this.renderCreateModalFooter()}
          closable={false}
          destroyOnClose={true}
          onCancel={() => showModal(false)}
        >
          {this.renderCreateForm()}
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.giftListCategories.loading,
  type: state.giftList.typeFilter,
  isShowModal: state.giftListCategories.isShowModal,
  giftListCategories: state.giftListCategories.data,
  totalCount: state.giftListCategories.totalCount,
  item: state.giftListCategories.item
});

const mapDispatchToProps = {
  addGiftListCategory,
  deleteGiftListCategory,
  showModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCategoriesManagement);
