import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import {
  switchMap,
  map,
  pluck,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators';
import {
  getPayloadByType,
  catchRequestError,
  ofType
} from '../utils/extendOperators';
import {
  getGiftCategoryListAPI,
  deleteGiftCategoryAPI,
  addGiftCategoryAPI
} from '../apis';
import { giftType } from '../constants/gifts';
import { createRequestTypes } from '../actions/Types';
import { forkJoin } from 'rxjs';
import { getGiftList } from './giftList';
import { message } from 'antd';
export const defaultQuery = {
  typeFilter: giftType.liveRoom.value,
  statusFilter: 'all',
  item: 9999,
  page: 1
};
/**
 * Type
 */
const GET_GIFT_LIST_CATEGORY = createRequestTypes('GET_GIFT_LIST_CATEGORY');
const ADD_GIFT_LIST_CATEGORY = createRequestTypes('ADD_GIFT_LIST_CATEGORY');
const DELETE_GIFT_LIST_CATEGORY = createRequestTypes(
  'DELETE_GIFT_LIST_CATEGORY'
);
const SHOW_MODAL = 'SHOW_MODAL';

/**
 * Creator
 */

export const getGiftListCategory = createAction(
  GET_GIFT_LIST_CATEGORY.REQUEST,
  ({ typeFilter, statusFilter, item, page } = defaultQuery) => ({
    typeFilter,
    statusFilter,
    item,
    page
  })
);

export const getGiftListCategorySuccess = createAction(
  GET_GIFT_LIST_CATEGORY.SUCCESS
);
export const getGiftListCategoryFailure = createAction(
  GET_GIFT_LIST_CATEGORY.FAILURE
);
export const addGiftListCategory = createAction(ADD_GIFT_LIST_CATEGORY.REQUEST);
export const addGiftListCategorySuccess = createAction(
  ADD_GIFT_LIST_CATEGORY.SUCCESS
);
export const addGiftListCategoryFailure = createAction(
  ADD_GIFT_LIST_CATEGORY.FAILURE
);
export const deleteGiftListCategory = createAction(
  DELETE_GIFT_LIST_CATEGORY.REQUEST
);
export const deleteGiftListCategorySuccess = createAction(
  DELETE_GIFT_LIST_CATEGORY.SUCCESS
);
export const deleteGiftListCategoryFailure = createAction(
  DELETE_GIFT_LIST_CATEGORY.FAILURE
);

export const showModal = createAction(SHOW_MODAL);

export const getGiftListCategoryEpic = action$ =>
  action$.pipe(
    ofType(GET_GIFT_LIST_CATEGORY.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      forkJoin([
        getGiftCategoryListAPI(payload),
        getGiftCategoryListAPI({ ...payload, statusFilter: 'archive' })
      ]).pipe(
        map(getGiftListCategorySuccess),
        catchRequestError(getGiftListCategoryFailure)
      )
    )
  );

export const addGiftListCategoryEpic = (action$, state$) =>
  action$.pipe(
    getPayloadByType(ADD_GIFT_LIST_CATEGORY.REQUEST),
    withLatestFrom(state$),
    switchMap(([payload, { giftList: { typeFilter } }]) =>
      addGiftCategoryAPI(payload).pipe(
        mergeMap(e => [
          addGiftListCategorySuccess(e),
          getGiftListCategory({ ...defaultQuery, typeFilter })
        ]),
        catchRequestError(err => {
          message.error('發生錯誤');
          return addGiftListCategoryFailure(err);
        })
      )
    )
  );

export const deleteGiftListCategoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_GIFT_LIST_CATEGORY.REQUEST),
    pluck('payload'),
    withLatestFrom(state$),
    switchMap(([payload, { giftList }]) =>
      deleteGiftCategoryAPI(payload).pipe(
        mergeMap(e => [
          deleteGiftListCategorySuccess(e),
          getGiftListCategory({
            ...defaultQuery,
            typeFilter: giftList.typeFilter
          }),
          getGiftList({ ...giftList })
        ]),
        catchRequestError(err => {
          message.error('發生錯誤');
          return deleteGiftListCategoryFailure(err);
        })
      )
    )
  );

const initialState = {
  loading: false,
  isShowModal: false,
  data: [],
  allData: [],
  archiveData: [],
  item: 20,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_GIFT_LIST_CATEGORY.REQUEST,
      ADD_GIFT_LIST_CATEGORY.REQUEST,
      DELETE_GIFT_LIST_CATEGORY.REQUEST
    )]: produce(draft => {
      draft.loading = true;
    }),
    [combineActions(GET_GIFT_LIST_CATEGORY.SUCCESS)]: produce(
      (draft, { payload: [data, archiveData] }) => {
        draft.data = data.data;
        draft.loading = false;
        draft.isShowModal = false;
        draft.totalCount = data.totalCount;
        draft.archiveData = archiveData.data;
        draft.allData = [...data.data, ...archiveData.data];
      }
    ),
    [combineActions(GET_GIFT_LIST_CATEGORY.FAILURE)]: produce(
      (draft, { payload }) => {
        draft.loading = false;
        draft.data = [];
        draft.archiveData = [];
        draft.allData = [];
        draft.totalCount = 0;
      }
    ),
    [combineActions(
      ADD_GIFT_LIST_CATEGORY.SUCCESS,
      GET_GIFT_LIST_CATEGORY.SUCCESS,
      GET_GIFT_LIST_CATEGORY.FAILURE,
      ADD_GIFT_LIST_CATEGORY.FAILURE,
      DELETE_GIFT_LIST_CATEGORY.SUCCESS,
      DELETE_GIFT_LIST_CATEGORY.FAILURE
    )]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
