import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { switchMap, pluck, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getDialogAPI } from '../apis';
import { createRequestTypes } from '../actions/Types';
import { catchRequestError } from '../utils/extendOperators';

/**
 * Action Types
 */

const GET_DIALOG = createRequestTypes('GET_DIALOG');

/**
 * Action Creators
 */

export const getDialog = createAction(GET_DIALOG.REQUEST);
export const getDialogSuccess = createAction(GET_DIALOG.SUCCESS);
export const getDialogFailure = createAction(GET_DIALOG.FAILURE);

/**
 * Epics
 */

export const getDialogEpic = action$ =>
  action$.pipe(
    ofType(GET_DIALOG.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getDialogAPI(payload).pipe(
        map(getDialogSuccess),
        catchRequestError(getDialogFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  loading: false,
  data: [],
  item: 50,
  totalCount: 0
};

export default handleActions(
  {
    [GET_DIALOG.REQUEST]: produce(draft => {
      draft.loading = true;
    }),
    [GET_DIALOG.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
    }),
    [GET_DIALOG.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    })
  },
  initialState
);
