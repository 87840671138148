import _ from 'lodash';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { HomeOutlined, TeamOutlined } from '@ant-design/icons';
import { getFamilyAPI } from '../../apis';
import { DetailTabsEnum, DetailTabsComponentEnum } from  './constants';
import { FAMILY_MANAGEMENT } from '../../constants/routes';
import { PageTitle, PageContainer } from '../../components/styled/page';

const GoToFamilyList = styled(Link)`
  text-decoration: underline !important;
  color: inherit !important;
`;

const FamilyInfoBlock = styled.div`
  display: flex;
  align-items: center;
`;

const FamillyInfo = styled.div`
  margin-left: 1rem;
  font-size: 1rem;

  & > *:not(:first-child) {
    margin-top: 0.25rem;
  }
`;

const FamilyDesc = styled.div`
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #CCC;
  white-space: pre-line;
`;

const FamilyCrest = styled.div`
  width: 8rem;
  height: 8rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const FamilyCrestDefault = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DDD;
  font-size: 1.5rem;
  color: #999;
`;

const FamilyName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const FamilyExp = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 0.5rem;
    padding: 0.2rem;
    border: 1px solid #999;
    border-radius: 5px;
    line-height: 1;
    font-size: 0.75rem;
  }
`;

const FamillyMembers = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 0.5rem;
    font-size: 1.1em;
  }
`;

const DetailTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 0 0.5rem 0.5rem !important;
  }
`;

const DetailTabContent = styled.div`
  padding: 0 1rem;
`;

const DetailTabsItems = Object.freeze([{
  label: '爵位',
  key: DetailTabsEnum.NOBILITIES,
}, {
  label: '成員',
  key: DetailTabsEnum.MEMBERS,
}, {
  label: '佈告欄',
  key: DetailTabsEnum.BULLETIN,
}]);

class FamilyDetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      family: {},
      activeTab: DetailTabsEnum.NOBILITIES,
    };
  }

  componentDidMount() {
    this.fetchFamilyDetail();
  }

  fetchFamilyDetail = () => {
    const { match } = this.props;
    const { id } = match.params;
    const obserable = getFamilyAPI(id);
    obserable.subscribe((res) => {
      const { data } = res;
      this.setState({ family: data });
    });
  };

  onTabChange = (key) => {
    this.setState({ activeTab: key });
  };

  renderFamilyCrest = () => {
    const { family } = this.state;
    const url = _.get(family, 'thumbUrl');
    const style = { backgroundImage: `url(${url})` };
    return (
      <FamilyCrest style={style}>
        {!url && (
          <FamilyCrestDefault>
            <HomeOutlined />
          </FamilyCrestDefault>
        )}
      </FamilyCrest>
    );
  };

  renderFamilyInfo = () => {
    const { family } = this.state;
    const familyName = _.get(family, 'name');
    const familyExp = _.get(family, 'totalExp', 0);
    const familyMembersCurrent = _.get(family, 'memberQtyProps.current', 0);
    const familyMembersLimit = _.get(family, 'memberQtyProps.limit', 0);
    return (
      <FamillyInfo>
        <FamilyName>{familyName}</FamilyName>
        <FamilyExp>
          <label>EXP</label>
          <span>{familyExp}</span>
        </FamilyExp>
        <FamillyMembers>
          <label>
            <TeamOutlined />
          </label>
          <span>{familyMembersCurrent}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{familyMembersLimit}</span>
        </FamillyMembers>
      </FamillyInfo>
    );
  };

  renderFamilyDesc = () => {
    const { family } = this.state;
    const desc = _.get(family, 'desc');
    return (
      <FamilyDesc>{desc}</FamilyDesc>
    );
  };

  renderTabContent = () => {
    const { family, activeTab } = this.state;
    const comp = DetailTabsComponentEnum[activeTab];
    return React.createElement(comp, { family });
  };

  render() {
    const { family, activeTab } = this.state;
    const falmilyName = _.get(family, 'name');
    return (
      <PageContainer>
        <PageTitle>
          <span>{'經營管理 > '}</span>
          <GoToFamilyList to={`${FAMILY_MANAGEMENT}`}>
            <span>{'家族管理'}</span>
          </GoToFamilyList>
          <span>{` > ${falmilyName}`}</span>
        </PageTitle>
        <FamilyInfoBlock>
          {this.renderFamilyCrest()}
          {this.renderFamilyInfo()}
        </FamilyInfoBlock>
        {this.renderFamilyDesc()}
        <DetailTabs
          items={DetailTabsItems}
          activeKey={activeTab}
          size="large"
          onChange={this.onTabChange}
        />
        <DetailTabContent>
          {this.renderTabContent()}
        </DetailTabContent>
      </PageContainer>
    );
  }
}

export default withRouter(FamilyDetail);
