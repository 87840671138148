export default {
  chartArea: {
    width: '100%',
    height: '100%',
    left: 104,
    top: 64,
    right: 56,
    bottom: 112
  },
  interpolateNulls: true,
  legend: {
    position: 'top',
    textStyle: {
      fontSize: 16
    }
  },
  animation: {
    duration: 500,
    easing: 'out',
    startup: true
  },
  hAxis: {
    title: '日期',
    titleTextStyle: {
      italic: false
    },
    textStyle: {
      fontSize: 14
    }
  },
  vAxis: {
    title: '速度',
    titleTextStyle: {
      italic: false
    },
    textStyle: {
      fontSize: 14
    }
  }
};
