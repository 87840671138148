import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';
import FamilyForm from './FamilyForm';
import { addFamilyAPI, updateFamilyAPI } from '../../../apis';
import { ModeEnum } from './constants';

const ActionMap = Object.freeze({
  [ModeEnum.CREATE]: {
    title: '新增家族',
    handler: 'createFamily',
  },
  [ModeEnum.EDIT]: {
    title: '編輯家族',
    handler: 'updateFamily',
  },
});

class FamilyModal extends React.PureComponent {
  static propTypes = {
    onComplete: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      action: null,
      visible: false
    };
  }

  show = (action, data) => {
    if (!ActionMap[action]) {
      console.error(`TagManageModal: Could not support action "${action}"`);
      return;
    }

    this.setState({ action, visible: true }, () => {
      const { form } = this;
      form.reset();
      if (data) form.setFormData(data);
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  processErrorMessage = (msg) => {
    let result = '家族新增失敗';
    switch (msg) {
      case 'FAMILY_ADMIN_HAS_EXIST_IN_OTHER_FAMILY':
        result = '家族新增失敗，家族長已經掌管其他家族';
        break;
      default:
    }
    return result;
  };

  createFamily = (values) => {
    const payload = { ...values, isActive: true };
    const iPromise = addFamilyAPI(payload).toPromise();
    iPromise.then(() => {
      message.success('家族新增成功');
      this.onComplete();
    });
    iPromise.catch((error) => {
      const msg = _.get(error, 'response.Message');
      const feedback = this.processErrorMessage(msg);
      message.error(feedback);
    });
  };

  updateFamily = (values) => {
    const iPromise = updateFamilyAPI(values).toPromise();
    iPromise.then(() => {
      message.success('家族更新成功');
      this.onComplete();
    });
    iPromise.catch(() => {
      message.error('家族更新失敗');
    });
  };

  onConfirm = () => {
    const { form } = this;
    const { action } = this.state;
    const iPromise = form.validateFields();
    iPromise.then((values) => {
      const handler = ActionMap[action].handler;
      this[handler](values);
    });
  };

  onComplete = () => {
    this.hide();
    const { onComplete } = this.props;
    if (onComplete) onComplete();
  };

  render() {
    const { action, visible } = this.state;
    const meta = ActionMap[action];
    const title = meta && meta.title;
    return (
      <Modal
        title={title}
        open={visible}
        centered
        maskClosable
        onOk={this.onConfirm}
        onCancel={this.hide}
      >
        <FamilyForm
          ref={form => this.form = form}
          mode={action}
        />
      </Modal>
    );
  }
}

export default FamilyModal;
