import _ from 'lodash';
import {
  compose, converge, evolve,
  merge, path, paths,
  pathOr, pipe,
  map as Rmap,
} from 'ramda';
import produce from 'immer';
import moment from 'moment';
import { createAction, handleActions, combineActions } from 'redux-actions';
import { ajax } from 'rxjs/ajax';
import { map, switchMap, tap, pluck } from 'rxjs/operators';
import { API_KIBANA, API_KIBANA_KEY } from '../constants/endpoint';
import {
  getUserConsumeAPI,
  getLiveMasterHoursAPI,
  getLiveMasterRevenueAPI,
} from '../apis';
import { ofType, catchRequestError } from '../utils/extendOperators';
import downloadCSV from '../utils/downloadCSV';
import downloadFile from '../utils/downloadFile';

//kibana api setting
const getKibanaNetworkSpeedAPI = body =>
  ajax({
    url:
      API_KIBANA +
      '/fluentd-*/_search?rest_total_hits_as_int=true&ignore_unavailable=true&ignore_throttled=true&timeout=30000ms',
    method: 'POST',
    headers: {
      Authorization: 'ApiKey ' + API_KIBANA_KEY,
      'Content-Type': 'application/json;charset=UTF-8',
      'kbn-xsrf': true
    },
    crossDomain: true,
    body
  }).pipe(pluck('response'));

const createKibanaQueryObj = (userIds, dateRanges) => {
  return {
    size: 9999,
    sort: [{ '@timestamp': { order: 'asc', unmapped_type: 'boolean' } }],
    _source: { excludes: [] },
    version: true,
    query: {
      bool: {
        filter: [
          {
            bool: {
              should: userIds.map(user => ({
                bool: {
                  should: [
                    { match_phrase: { 'PlayEventLogApi.identityId': user } }
                  ]
                }
              })),
              minimum_should_match: 1
            }
          },
          {
            range: {
              '@timestamp': {
                format: 'strict_date_optional_time',
                gte: moment.utc(dateRanges[0]).format(),
                lte: moment.utc(dateRanges[1]).format()
              }
            }
          }
        ]
      }
    }
  };
};

const formatKibanaSpeedData = compose(
  Rmap(
    converge(merge, [
      compose(
        ([id, date]) => ({
          id,
          createTime: parseInt(moment.utc(date).format('x'))
        }),
        paths([['_id'], ['_source', '@timestamp']])
      ),
      path(['_source', 'PlayEventLogApi'])
    ])
  ),
  pathOr([], ['hits', 'hits'])
);

const genAccountSheetRowFromTWD = (rowData) => {
  const { createAt, liveTimeSum } = rowData;
  return {
    ...rowData,
    liveTimeSum: moment
      .duration(liveTimeSum, 'seconds')
      .asHours()
      .toFixed(3),
    createAt: moment.unix(createAt).format('YYYY-MM-DD HH:mm:ss'),
  };
};

const genAccountSheetRowFromUSD = (rowData) => {
  const {
    createAt, liveTimeSum, originRatio,
    actualRatio, fixRevenue, originRevenue,
    actualRevenue, realRevenue, points,
  } = rowData;
  const data = {
    ...rowData,
    liveTimeSum: moment
      .duration(liveTimeSum, 'seconds')
      .asHours()
      .toFixed(3),
    originRatio: 0,
    actualRatio: 0,
    createAt: moment.unix(createAt).format('YYYY-MM-DD HH:mm:ss'),
  };
  if (points > 0) {
    Object.assign(data, {
      originRatio: _.round(originRatio / 33, 3),
      actualRatio: _.round(actualRatio / 33, 3),
      fixRevenue: _.round(fixRevenue * 33, 3),
      originRevenue: _.round(originRevenue * 33, 3),
      actualRevenue: _.round(actualRevenue * 33, 3),
      realRevenue: _.round(realRevenue * 33, 3),
    });
  }
  return data;
};

const getAccountSheetUSDCsv = (sheet) => {
  const keys = [
    'createAt',
    'liveMasterLoginId',
    'liveMasterNickname',
    'points',
    'originRatio',
    'actualRatio',
    'fixRevenue',
    'originRevenue',
    'actualRevenue',
    'realRevenue',
    'liveTimeSum',
  ];
  const columns = [
    '日期',
    '直播主Login ID',
    '直播主暱稱',
    '送禮總點數',
    '原始比值',
    '實際比值',
    '收益金額(固定比值 ÷ 760)',
    '實際收益金額(原始比值)',
    '使用收益金額(實際比值)',
    '實際收益金額',
    '開播時間'
  ];
  const data = sheet.map((rowData) => genAccountSheetRowFromTWD(rowData));
  return downloadCSV(data, keys, columns);
};

const getAccountSheetTWDCsv = (sheet) => {
  const keys = [
    'createAt',
    'liveMasterLoginId',
    'liveMasterNickname',
    'points',
    'originRatio',
    'actualRatio',
    'fixRevenue',
    'originRevenue',
    'actualRevenue',
    'realRevenue',
    'liveTimeSum',
  ];
  const columns = [
    '日期',
    '直播主Login ID',
    '直播主暱稱',
    '送禮總點數',
    '原始比值',
    '實際比值',
    '收益金額(固定比值 ÷ 23)',
    '實際收益金額(原始比值)',
    '使用收益金額(實際比值)',
    '實際收益金額',
    '開播時間'
  ];
  const data = sheet.map((rowData) => {
    const { createAt } = rowData;
    const year = moment.unix(createAt).year();
    if (year <= 2022) {
      return genAccountSheetRowFromTWD(rowData);
    }
    return genAccountSheetRowFromUSD(rowData);
  });
  return downloadCSV(data, keys, columns);
};

const getUserConsumeSheetCsv = sheet => {
  const formatFunc = Rmap(
    evolve({
      createAt: t => moment(t).format('YYYY-MM-DD HH:mm:ss'),
      updateAt: t => moment(t).format('YYYY-MM-DD HH:mm:ss')
    })
  );

  return downloadCSV(
    formatFunc(sheet),
    [
      'nickname',
      'loginId',
      'createAt',
      'updateAt',
      'receiptDonate',
      'receiptAddr',
      'receiptName',
      'receiptTel',
      'orderId',
      'id',
      'purchasePaymentType',
      'price'
    ],
    [
      '粉絲暱稱',
      '粉絲 ID',
      '交易開始時間',
      '交易完成時間',
      '是否捐贈發票',
      '發票地址',
      '發票收件人',
      '發票收件人電話',
      '商店訂單編號',
      '交易序號',
      '支付方式',
      '消費金額'
    ]
  );
};

const getLiveMasterHoursSheetCsv = sheet => {
  const csvBlob = new Blob(
    [new Uint8Array([0xef, 0xbb, 0xbf]), sheet.join('\n')],
    { type: 'text/csv' }
  );
  const filename = window.URL.createObjectURL(csvBlob) + '.csv';
  return downloadFile(csvBlob, filename);
};

/**
 * Action Types
 */
const GET_ACCOUNT_SHEET_TWD = 'GET_ACCOUNT_SHEET_TWD';
const GET_ACCOUNT_SHEET_TWD_SUCCESS = 'GET_ACCOUNT_SHEET_TWD_SUCCESS';
const GET_ACCOUNT_SHEET_TWD_FAILURE = 'GET_ACCOUNT_SHEET_TWD_FAILURE';

const GET_ACCOUNT_SHEET_USD = 'GET_ACCOUNT_SHEET_USD';
const GET_ACCOUNT_SHEET_USD_SUCCESS = 'GET_ACCOUNT_SHEET_USD_SUCCESS';
const GET_ACCOUNT_SHEET_USD_FAILURE = 'GET_ACCOUNT_SHEET_USD_FAILURE';

const GET_USER_CONSUME_SHEET = 'GET_USER_CONSUME_SHEET';
const GET_USER_CONSUME_SHEET_SUCCESS = 'GET_USER_CONSUME_SHEET_SUCCESS';
const GET_USER_CONSUME_SHEET_FAILURE = 'GET_USER_CONSUME_SHEET_FAILURE';

const GET_LIVE_MASTER_HOURS_SHEET = 'GET_LIVE_MASTER_HOURS_SHEET';
const GET_LIVE_MASTER_HOURS_SHEET_SUCCESS =
  'GET_LIVE_MASTER_HOURS_SHEET_SUCCESS';
const GET_LIVE_MASTER_HOURS_SHEET_FAILURE =
  'GET_LIVE_MASTER_HOURS_SHEET_FAILURE';

const GET_LIVE_MASTER_NETWORK_SPEEDSHEET = 'GET_LIVE_MASTER_NETWORK_SPEEDSHEET';
const GET_LIVE_MASTER_NETWORK_SPEEDSHEET_SUCCESS =
  'GET_LIVE_MASTER_NETWORK_SPEEDSHEET_SUCCESS';
const GET_LIVE_MASTER_NETWORK_SPEEDSHEET_FAILURE =
  'GET_LIVE_MASTER_NETWORK_SPEEDSHEET_FAILURE';

/**
 * Action Creators
 */
export const getAccountSheetTWD = createAction(GET_ACCOUNT_SHEET_TWD);
const getAccountSheetTWDSuccess = createAction(GET_ACCOUNT_SHEET_TWD_SUCCESS);
const getAccountSheetTWDFailure = createAction(GET_ACCOUNT_SHEET_TWD_FAILURE);

export const getAccountSheetUSD = createAction(GET_ACCOUNT_SHEET_USD);
const getAccountSheetUSDSuccess = createAction(GET_ACCOUNT_SHEET_USD_SUCCESS);
const getAccountSheetUSDFailure = createAction(GET_ACCOUNT_SHEET_USD_FAILURE);

export const getUserConsumeSheet = createAction(GET_USER_CONSUME_SHEET);
const getUserConsumeSheetSuccess = createAction(GET_USER_CONSUME_SHEET_SUCCESS);
const getUserConsumeSheetFailure = createAction(GET_USER_CONSUME_SHEET_FAILURE);

export const getLiveMasterHoursSheet = createAction(
  GET_LIVE_MASTER_HOURS_SHEET
);
const getLiveMasterHoursSheetSuccess = createAction(
  GET_LIVE_MASTER_HOURS_SHEET_SUCCESS
);
const getLiveMasterHoursSheetFailure = createAction(
  GET_LIVE_MASTER_HOURS_SHEET_FAILURE
);

export const getLiveMasterNetworkSpeedSheet = createAction(
  GET_LIVE_MASTER_NETWORK_SPEEDSHEET,
  createKibanaQueryObj
);
const getLiveMasterNetworkSpeedSheetSuccess = createAction(
  GET_LIVE_MASTER_NETWORK_SPEEDSHEET_SUCCESS
);
const getLiveMasterNetworkSpeedSheetFailure = createAction(
  GET_LIVE_MASTER_NETWORK_SPEEDSHEET_FAILURE
);

/**
 * Epics
 */
export const getAccountSheetUSDEpic = pipe(
  ofType(GET_ACCOUNT_SHEET_USD),
  pluck('payload'),
  switchMap(payload =>
    getLiveMasterRevenueAPI(payload).pipe(
      pluck('data'),
      tap(getAccountSheetUSDCsv),
      map(getAccountSheetUSDSuccess),
      catchRequestError(getAccountSheetUSDFailure)
    )
  )
);

export const getAccountSheetTWDEpic = pipe(
  ofType(GET_ACCOUNT_SHEET_TWD),
  pluck('payload'),
  switchMap(payload =>
    getLiveMasterRevenueAPI(payload).pipe(
      pluck('data'),
      tap(getAccountSheetTWDCsv),
      map(getAccountSheetTWDSuccess),
      catchRequestError(getAccountSheetTWDFailure)
    )
  )
);

export const getUserConsumeSheetEpic = pipe(
  ofType(GET_USER_CONSUME_SHEET),
  pluck('payload'),
  switchMap(payload =>
    getUserConsumeAPI(payload).pipe(
      pluck('purchaseOrderList'),
      tap(getUserConsumeSheetCsv),
      map(getUserConsumeSheetSuccess),
      catchRequestError(getUserConsumeSheetFailure)
    )
  )
);

export const getLiveMasterHoursSheetEpic = pipe(
  ofType(GET_LIVE_MASTER_HOURS_SHEET),
  pluck('payload'),
  switchMap(payload =>
    getLiveMasterHoursAPI(payload).pipe(
      tap(getLiveMasterHoursSheetCsv),
      map(getLiveMasterHoursSheetSuccess),
      catchRequestError(getLiveMasterHoursSheetFailure)
    )
  )
);

export const getLiveMasterNetworkSpeedSheetEpic = pipe(
  ofType(GET_LIVE_MASTER_NETWORK_SPEEDSHEET),
  pluck('payload'),
  switchMap(payload =>
    getKibanaNetworkSpeedAPI(payload).pipe(
      map(getLiveMasterNetworkSpeedSheetSuccess),
      catchRequestError(getLiveMasterNetworkSpeedSheetFailure)
    )
  )
);

/**
 * Reducer
 */
const initialState = {
  loading: false,
  networkSpeedSheet: []
};

export default handleActions(
  {
    [combineActions(
      GET_ACCOUNT_SHEET_TWD,
      GET_ACCOUNT_SHEET_USD,
      GET_USER_CONSUME_SHEET,
      GET_LIVE_MASTER_HOURS_SHEET,
      GET_LIVE_MASTER_NETWORK_SPEEDSHEET
    )]: produce(draft => {
      draft.loading = true;
    }),
    [combineActions(
      GET_ACCOUNT_SHEET_TWD_SUCCESS,
      GET_ACCOUNT_SHEET_USD_SUCCESS,
      GET_USER_CONSUME_SHEET_SUCCESS,
      GET_LIVE_MASTER_HOURS_SHEET_SUCCESS,
      GET_LIVE_MASTER_NETWORK_SPEEDSHEET_SUCCESS
    )]: produce(draft => {
      draft.loading = false;
    }),
    [GET_LIVE_MASTER_NETWORK_SPEEDSHEET_SUCCESS]: produce(
      (draft, { payload }) => {
        draft.networkSpeedSheet = formatKibanaSpeedData(payload);
      }
    )
  },
  initialState
);
