import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Card, Button } from 'antd';

const StyledModal = styled(Modal)`
  .modal-content {
    display: flex;
  }

  .modal-footer {
    padding-top: 16px;
  }
`;

class OnlineStreamsModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
  };

  onDelete = () => {
    const { onDelete, info: { roomId } } = this.props;
    onDelete(roomId);
  }

  render() {
    const { loginId, liveHour, currentCount, profilePicture } = this.props.info;

    return (
      <StyledModal
        title="在線直播中"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={null}
      >
        <div className="modal-content">
          <Card
            style={{ width: 300, marginRight: '16px' }}
            bodyStyle={{ padding: '4px' }}
          >
            <img src={profilePicture} alt="avatar" width="100%" />
          </Card>
          <div className="info">
            <h3>帳號：{loginId}</h3>
            <h3>直播時間：{`${liveHour}:00:00`}</h3>
            <h3>線上人數：{currentCount}</h3>
            {/*<h3>禮物數：{giftCount}</h3>*/}
          </div>
        </div>
        <div className="modal-footer">
          <Button type="danger" size="large" style={{ width: '100%' }} onClick={this.onDelete}>
            立即下架
          </Button>
        </div>
      </StyledModal>
    );
  }
}

export default OnlineStreamsModal;
