import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import VoiceChatService from '../../../services/voiceChat';
import SeatManager from './SeatManager';
import VoiceChatRoomContext from '../VoiceChatRoomContext';
import VoiceChatLiveContext from './VoiceChatLiveContext';

const RoomControls = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RoomControlsBtns = styled.div`
  button {
    & + button {
      margin-left: 0.5rem;
    }
  }
`;

class VoiceChatRoomLive extends React.Component {

  static contextType = VoiceChatRoomContext;

  constructor(props) {
    super(props);

    this.state = {
      seats: [],
      seatsMute: [],
      couldPlay: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    VoiceChatService.dispose();
  }

  init = () => {
    const { roomId, isPrivate } = this.context;
    VoiceChatService.init(roomId, isPrivate);
    VoiceChatService.on('update', this.onVoiceChatUpdate);
  }

  getContextValue = () => {
    const { couldPlay, seats, seatsMute } = this.state;
    return { couldPlay, seats, seatsMute };
  }

  onStartListen = () => {
    this.setState({ couldPlay: true });
  }

  onVoiceChatUpdate = (data) => {
    this.setState(data);
  }

  renderControls = () => {
    const { couldPlay } = this.state;
    
    return (
      <RoomControls>
        <RoomControlsBtns>
          <Button
            type="primary"
            onClick={this.onStartListen}
            disabled={couldPlay}
          >
            <span>開始監聽</span>
          </Button>
          <Button type="danger" ghost>
            <span>立即下架</span>
          </Button>
        </RoomControlsBtns>
      </RoomControls>
    );
  }

  render() {
    const ContextValue = this.getContextValue();
    return (
      <VoiceChatLiveContext.Provider value={ContextValue}>
        {this.renderControls()}
        <SeatManager />
      </VoiceChatLiveContext.Provider>
    );
  }
}

export default VoiceChatRoomLive;
