import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, DatePicker, Table } from 'antd';
import { Form } from '@ant-design/compatible';
import TableContainer from '../../components/TableContainer';
import { getRoomList } from '../../reducers/messageRoomList';
import { isReallyEmpty } from '../../utils/mixin';
import {
  isEmpty,
  equals,
  cond,
  T,
  identity,
  not,
  compose,
  pathOr,
  mapObjIndexed,
  flatten
} from 'ramda';
import moment from 'moment';
import hasErrors from '../../utils/hasErrors';
import { userLevel } from '../../constants/roles';
import { Link } from 'react-router-dom';
import { DIALOG } from '../../constants/routes';
import { createQs } from '../../utils/mixin';
import LiveMasterSelect from '../../components/LiveMasterSelect';
const { RangePicker } = DatePicker;

/**
 * set margin
 */
const ControlsContainer = styled.div`
  margin: 10px 0;
  padding-right: 16px;

  .mr8:not(:last-child) {
    margin-right: 8px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
`;
const mapStateToProps = ({
  userManagement,
  messageRoomList: { data, item, loading, totalCount }
}) => ({
  userList: userManagement.userList,
  userListLoading: userManagement.loading,
  data,
  item,
  loading,
  totalCount
});

const mapDispatchToProps = {
  getRoomList
};
const FormItem = Form.Item;

@Form.create({
  mapPropsToFields: compose(
    mapObjIndexed(value => Form.createFormField({ value: value })),
    cond([
      [
        compose(not, isEmpty),
        state => ({
          userId: state.currentUser.id,
          dates: [moment.unix(state.startTime), moment.unix(state.endTime)]
        })
      ],
      [T, identity]
    ]),
    pathOr({}, ['history', 'location', 'state'])
  )
})
@connect(mapStateToProps, mapDispatchToProps)
class MessageRoomList extends Component {
  constructor(props) {
    super(props);
    const locationState = this.props.history.location.state || {};
    this.forceFeatch = false;
    this.state = {
      searchForm: {
        currentUser: null,
        startTime: null,
        endTime: null,
        currentPage: 1
      },
      userListByURL: compose(
        flatten,
        value => [value],
        pathOr([], ['currentUser'])
      )(locationState),
      selectedUser: pathOr(null, ['currentUser'], locationState),
      searchKeyword: pathOr('', ['currentUser', 'id'], locationState)
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { state }
      }
    } = this.props;

    this.setState({ searchForm: { ...state } });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { searchForm } = this.state;
    const { item } = this.props;

    if (
      (!equals(prevState.searchForm, searchForm) &&
        !isReallyEmpty(searchForm.currentUser)) ||
      this.forceFeatch
    ) {
      this.forceFeatch = false;
      this.searchRoomList(
        searchForm.currentUser.id,
        searchForm.startTime,
        searchForm.endTime,
        item,
        searchForm.currentPage
      );
    }
  }

  generateFormItem = () => {
    const {
      loading,
      form: { getFieldsError }
    } = this.props;

    return [
      {
        key: 'userId',
        style: { display: 'block' },
        options: {
          rules: [{ required: true, message: '請輸入使用者暱稱' }]
        },
        render: () => (
          <LiveMasterSelect
            style={{ width: 400 }}
            roleFilter={null}
            onSelectChange={this.handleUserSelected}
          />
        )
      },
      {
        key: 'dates',
        options: {
          initialValue: [moment().subtract(7, 'days').startOf('day'), moment()],
          rules: [{ required: true, message: '請輸入查詢期間' }]
        },
        render: () => (
          <RangePicker
            style={{ width: 360 }}
            className="mr8"
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss')
              ]
            }}
            disabledDate={current =>
              current >= moment().startOf('day').add(1, 'day')
            }
          />
        )
      },
      {
        key: 'submit',
        render: () => (
          <Button
            type="primary"
            key={'ok'}
            htmlType="submit"
            loading={loading}
            disabled={hasErrors(getFieldsError())}
          >
            查詢
          </Button>
        )
      }
    ];
  };

  generateTableColumn = () => {
    const {
      searchForm: { currentUser, startTime, endTime }
    } = this.state;

    const tableColumns = [
      {
        title: '發送對象 ID',
        dataIndex: 'loginId',
        key: 'loginId'
      },
      {
        title: '發送對象暱稱',
        dataIndex: 'nickname',
        key: 'nickname',
        render: (value, record) => (
          <Link
            to={{
              pathname: `${DIALOG}/${record.dialogId}`,
              search: createQs({
                currentUser: pathOr('', ['id'], currentUser),
                startTime,
                endTime
              })
            }}
          >
            {value}
          </Link>
        )
      },
      {
        title: '發送對象等級',
        dataIndex: 'userLevel',
        render: value => `${userLevel[value.levelId]} ${value.levelNum}`
      },
      {
        title: '發送時間',
        dataIndex: 'latestMsgTime',
        render: value =>
          value && moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
      }
    ];

    return tableColumns;
  };

  /**
   * 搜尋用戶 ID
   */
  handleUserSearchChange = value => {
    this.onSearch$.next(value);
  };

  /**
   * 選取後存在State
   */
  handleUserSelected = value => {
    this.setState({
      selectedUser: value.length > 0 ? value[0] : null
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields }
    } = this.props;

    validateFields((errors, values) => {
      const { selectedUser } = this.state;
      if (!errors) {
        this.forceFeatch = true;
        const { dates } = values;
        const startTime = dates[0].unix();
        const endTime = dates[1].unix();
        this.setState({
          searchForm: {
            currentUser: { ...selectedUser },
            startTime,
            endTime,
            currentPage: 1
          }
        });
      }
    });
  };

  /**
   * 更新 History
   */
  updateHistoryState = () => {
    const { searchForm } = this.state;
    this.props.history.replace({
      state: {
        ...searchForm
      }
    });
  };

  /**
   * 點擊頁數
   */
  handleTableChange = pagination => {
    const { searchForm } = this.state;
    this.setState({
      searchForm: { ...searchForm, currentPage: pagination.current }
    });
  };

  /**
   * 取得 RoomList
   */
  searchRoomList = (userId, startTime, endTime, item, currentPage) => {
    const { getRoomList } = this.props;
    getRoomList({ userId, startTime, endTime, item, page: currentPage });
    this.updateHistoryState();
  };

  render() {
    const {
      searchForm: { currentUser, currentPage }
    } = this.state;
    const {
      item,
      totalCount,
      data,
      loading,
      form: { getFieldDecorator }
    } = this.props;
    const pagination = {
      current: currentPage,
      pageSize: item,
      total: totalCount
    };

    return (
      <div>
        <h1>{'經營管理 > 私訊紀錄查詢'}</h1>
        <ControlsContainer>
          <Form layout="inline" onSubmit={this.handleSubmit}>
            <div className="mb16">
              {this.generateFormItem().map(item => (
                <FormItem key={item.key} style={item.style}>
                  {getFieldDecorator(item.key, item.options)(item.render())}
                </FormItem>
              ))}
            </div>
          </Form>
          <h3>私訊紀錄</h3>
          <h4>
            查詢帳號：
            {currentUser
              ? `${currentUser.loginId} (${currentUser.nickname})`
              : ''}
          </h4>
        </ControlsContainer>
        <TableContainer>
          <Table
            loading={loading}
            dataSource={currentUser ? data : []}
            rowKey={record => record.dialogId}
            columns={this.generateTableColumn()}
            onChange={this.handleTableChange}
            pagination={currentUser ? pagination : {}}
          />
        </TableContainer>
      </div>
    );
  }
}

export default MessageRoomList;
