import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { DatePicker, Table, Button } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { map, pluck } from 'rxjs/operators';
import downloadCSV from '../utils/downloadCSV';
import TableContainer from '../components/TableContainer';
import {
  getGiftReport,
  changeLiveMasterName,
  changeRangeTime
} from '../reducers/giftReport';
import { getReceiveGiftListAPI } from '../apis';
import LiveMasterSelect from '../components/LiveMasterSelect';
import { has } from 'ramda';
import { parseQs } from '../utils/mixin';

const { RangePicker } = DatePicker;
const Toolbar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const columns = [
  {
    title: '直播主Login ID',
    dataIndex: 'liveMasterLoginId'
  },
  {
    title: '日期',
    dataIndex: 'createAt',
    render: text => moment.unix(text).format('YYYY-MM-DD HH:mm:ss')
  },
  {
    title: '送禮者Login ID',
    dataIndex: 'giverLoginId'
  },
  {
    title: '送禮者暱稱',
    dataIndex: 'giverNickname'
  },
  {
    title: '禮物名稱',
    dataIndex: 'giftName'
  },
  {
    title: '送禮總點數',
    dataIndex: 'points'
  },
  {
    title: '新台幣價格',
    dataIndex: 'ntd'
  }
];
@connect(
  state => ({
    ...state.giftReport
  }),
  {
    getGiftReport,
    changeLiveMasterName,
    changeRangeTime
    // searchLiveMasterList,
  }
)
class GiftReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFieldsTouched: false,
      downloading: false
    };
  }

  componentDidMount() {
    const { getGiftReport, startTime, endTime } = this.props;
    const search = parseQs(this.props.history.location.search);
    if (has('liveMasterId', search)) {
      getGiftReport({
        startTime,
        endTime,
        liveMasterId: [search.liveMasterId]
      });
    } else {
      this.onSearch();
    }
  }

  handleRangeTimeChange = p => {
    const { changeRangeTime } = this.props;
    this.setState({ isFieldsTouched: true });
    changeRangeTime({ startTime: p[0].unix(), endTime: p[1].unix() });
  };

  onPageChange = pagination => {
    const { getGiftReport, liveMasterId, startTime, endTime } = this.props;

    getGiftReport({
      startTime,
      endTime,
      liveMasterId,
      page: pagination.current
    });
  };

  handleSelectChange = value => {
    const { changeLiveMasterName } = this.props;
    this.setState({ isFieldsTouched: true });
    changeLiveMasterName(value || []);
  };

  onSearch = () => {
    const { liveMasterId, startTime, endTime, getGiftReport } = this.props;
    this.setState({ isFieldsTouched: false });
    getGiftReport({ liveMasterId, page: 1, startTime, endTime });
  };

  onDownloadClick = () => {
    const { liveMasterId, totalCount, startTime, endTime } = this.props;
    this.setState({
      downloading: true
    });
    getReceiveGiftListAPI({
      liveMasterId,
      page: 1,
      item: totalCount,
      startTime,
      endTime
    })
      .pipe(
        pluck('data'),
        map(data =>
          data.map(x => ({
            ...x,
            createAt: moment.unix(x.createAt).format('YYYY-MM-DD HH:mm:ss')
          }))
        )
      )
      .subscribe(data => {
        this.setState({
          downloading: false
        });
        downloadCSV(
          data,
          [
            'liveMasterLoginId',
            'createAt',
            'giverLoginId',
            'giverNickname',
            'giftName',
            'points',
            'ntd'
          ],
          [
            '直播主Login ID',
            '日期',
            '送禮者Login ID',
            '送禮者暱稱',
            '禮物名稱',
            '送禮總點數',
            '新台幣價格'
          ]
        );
      });
  };

  render() {
    const {
      data,
      page,
      totalCount,
      isLoading,
      startTime,
      endTime,
      liveMasterId
    } = this.props;
    const { isFieldsTouched } = this.state;
    return (
      <TableContainer>
        <h1>{'經營管理 > 送禮報表'}</h1>
        <Toolbar>
          <div>
            <LiveMasterSelect
              style={{ width: 400, marginBottom: 8 }}
              value={liveMasterId}
              typeFilter="login_id"
              mode="multiple"
              onChange={this.handleSelectChange}
            />
            <RangePicker
              allowClear={false}
              value={[moment.unix(startTime), moment.unix(endTime)]}
              style={{ width: 400, marginRight: 8, marginBottom: 8 }}
              format="YYYY/MM/DD HH:mm:ss"
              showTime={true}
              onChange={this.handleRangeTimeChange}
            />
            <Button type="primary" onClick={this.onSearch}>
              <LegacyIcon type="search" />
              <span>搜尋</span>
            </Button>
          </div>
          <Button
            type="primary"
            loading={this.state.downloading}
            onClick={this.onDownloadClick}
            disabled={isFieldsTouched}
          >
            <LegacyIcon type="download" />
            <span>匯出報表</span>
          </Button>
        </Toolbar>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data}
          loading={isLoading}
          style={{ marginTop: 8 }}
          pagination={{
            showSizeChanger: false,
            current: page,
            total: totalCount
          }}
          onChange={this.onPageChange}
        />
      </TableContainer>
    );
  }
}

export default GiftReport;
