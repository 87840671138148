import React, { Component } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { BankAccountSettingsForm } from '../components';

const StyledCard = styled(Card)`width: 800px;`;

class ManagerCompanyBankAccontSettings extends Component {
  render() {
    return (
      <div>
        <h1>經紀公司 > 銀行帳戶設定</h1>
        <StyledCard>
          <BankAccountSettingsForm />
        </StyledCard>
      </div>
    );
  }
}

export default ManagerCompanyBankAccontSettings;
