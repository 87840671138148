import { createAction, handleActions, combineActions } from 'redux-actions';
import produce from 'immer';
import {
  pipe,
  prop,
  groupBy,
  identity,
  omit,
  propEq,
  ifElse,
  filter,
  compose,
  contains,
  values,
  __
} from 'ramda';
import { useWith as ramdaUseWith } from 'ramda';
import {
  map,
  mergeMap,
  switchMap,
  pluck,
  withLatestFrom
} from 'rxjs/operators';
import { ofType, catchRequestError } from '../utils/extendOperators';
import {
  getSystemAnnouncementListAPI,
  pushNotificationAPI,
  getAnnouncementListAPI,
  addAnnouncementAPI,
  setAnnouncementAPI,
  deleteAnnouncementAPI,
  getAnnouncementAPI,
  getCoverageAPI,
  getTagListAPI
} from '../apis';
import { forkJoin } from 'rxjs';
import { tagType } from '../constants/roles';
export const filterTagByLabel = ramdaUseWith(filter, [
  v => compose(contains(__, v), prop('label')),
  identity
]);
/**
 * Action Types
 */
const SHOW_MODAL = 'ANNOUNCEMENTPUSH.SHOW_MODAL';
const SET_DEPARTMENT = 'SET_DEPARTMENT';
const GET_SYSTEM_ANNOUNCEMENT_LIST = 'GET_SYSTEM_ANNOUNCEMENT_LIST';
const GET_SYSTEM_ANNOUNCEMENT_LIST_SUCCESS =
  'GET_SYSTEM_ANNOUNCEMENT_LIST_SUCCESS';
const GET_SYSTEM_ANNOUNCEMENT_LIST_FAILURE =
  'GET_SYSTEM_ANNOUNCEMENT_LIST_FAILURE';
const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
const PUSH_NOTIFICATION_SUCCESS = 'PUSH_NOTIFICATION_SUCCESS';
const PUSH_NOTIFICATION_FAILURE = 'PUSH_NOTIFICATION_FAILURE';
const GET_ANNOUNCEMENT_LIST = 'GET_ANNOUNCEMENT_LIST';
const GET_ANNOUNCEMENT_LIST_SUCCESS = 'GET_ANNOUNCEMENT_LIST_SUCCESS';
const GET_ANNOUNCEMENT_LIST_FAILURE = 'GET_ANNOUNCEMENT_LIST_FAILURE';
const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT';
const ADD_ANNOUNCEMENT_SUCCESS = 'ADD_ANNOUNCEMENT_SUCCESS';
const ADD_ANNOUNCEMENT_FAILURE = 'ADD_ANNOUNCEMENT_FAILURE';
const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
const GET_ANNOUNCEMENT_SUCCESS = 'GET_ANNOUNCEMENT_SUCCESS';
const GET_ANNOUNCEMENT_FAILURE = 'GET_ANNOUNCEMENT_FAILURE';
const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';
const DELETE_ANNOUNCEMENT_FAILURE = 'DELETE_ANNOUNCEMENT_FAILURE';
const MODIFY_ANNOUNCEMENT = 'MODIFY_ANNOUNCEMENT';
const MODIFY_ANNOUNCEMENT_SUCCESS = 'MODIFY_ANNOUNCEMENT_SUCCESS';
const MODIFY_ANNOUNCEMENT_FAILURE = 'MODIFY_ANNOUNCEMENT_FAILURE';
const GET_COVERAGE = 'GET_COVERAGE';
const GET_COVERAGE_SUCCESS = 'GET_COVERAGE_SUCCESS';
const GET_COVERAGE_FAILURE = 'GET_COVERAGE_FAILURE';
const GET_TAG_LIST = 'GET_TAG_LIST';
const GET_TAG_LIST_SUCCESS = 'GET_TAG_LIST_SUCCESS';
const GET_TAG_LIST_FAILURE = 'GET_TAG_LIST_FAILURE';
/**
 * Action Creators
 */
export const showModal = createAction(SHOW_MODAL);
export const setDepartment = createAction(SET_DEPARTMENT);
export const getSystemAnnouncementList = (pageSize, currentPage) =>
  createAction(GET_SYSTEM_ANNOUNCEMENT_LIST)({ pageSize, currentPage });
const getSystemAnnouncementListSuccess = createAction(
  GET_SYSTEM_ANNOUNCEMENT_LIST_SUCCESS
);
const getSystemAnnouncementListFailure = createAction(
  GET_SYSTEM_ANNOUNCEMENT_LIST_FAILURE
);
export const pushNotification = (info, pageSize, currentPage) =>
  createAction(PUSH_NOTIFICATION)({ info, pageSize, currentPage });
const pushNotificationSuccess = createAction(PUSH_NOTIFICATION_SUCCESS);
const pushNotificationFailure = createAction(PUSH_NOTIFICATION_FAILURE);

export const getAnnouncementList = (dept, pageSize, currentPage) =>
  createAction(GET_ANNOUNCEMENT_LIST)({ dept, pageSize, currentPage });
export const getAnnouncementListSuccess = createAction(
  GET_ANNOUNCEMENT_LIST_SUCCESS
);
export const getAnnouncementListFailure = createAction(
  GET_ANNOUNCEMENT_LIST_FAILURE
);

export const addAnnouncement = createAction(ADD_ANNOUNCEMENT);
export const addAnnouncementSuccess = createAction(ADD_ANNOUNCEMENT_SUCCESS);
export const addAnnouncementFailure = createAction(ADD_ANNOUNCEMENT_FAILURE);

export const getAnnouncement = createAction(GET_ANNOUNCEMENT);
export const getAnnouncementSuccess = createAction(GET_ANNOUNCEMENT_SUCCESS);
export const getAnnouncementFailure = createAction(GET_ANNOUNCEMENT_FAILURE);

export const modifyAnnouncement = createAction(MODIFY_ANNOUNCEMENT);
export const modifyAnnouncementSuccess = createAction(
  MODIFY_ANNOUNCEMENT_SUCCESS
);
export const modifyAnnouncementFailure = createAction(
  MODIFY_ANNOUNCEMENT_FAILURE
);
export const deleteAnnouncement = createAction(DELETE_ANNOUNCEMENT);
export const deleteAnnouncementSuccess = createAction(
  DELETE_ANNOUNCEMENT_SUCCESS
);
export const deleteAnnouncementFailure = createAction(
  DELETE_ANNOUNCEMENT_FAILURE
);

export const getCoverage = createAction(GET_COVERAGE);
export const getCoverageSuccess = createAction(GET_COVERAGE_SUCCESS);
export const getCoverageFailure = createAction(GET_COVERAGE_FAILURE);

export const getTagList = createAction(GET_TAG_LIST);
export const getTagListSuccess = createAction(GET_TAG_LIST_SUCCESS);
export const getTagListFailure = createAction(GET_TAG_LIST_FAILURE);
/**
 * Epics
 */
export const getSystemAnnouncementListEpic = pipe(
  ofType(GET_SYSTEM_ANNOUNCEMENT_LIST),
  map(action => action.payload),
  switchMap(payload =>
    getSystemAnnouncementListAPI(payload).pipe(
      map(getSystemAnnouncementListSuccess),
      catchRequestError(getSystemAnnouncementListFailure)
    )
  )
);

export const pushNotificationEpic = pipe(
  ofType(PUSH_NOTIFICATION),
  map(action => action.payload),
  switchMap(payload =>
    pushNotificationAPI(payload.info).pipe(
      mergeMap(res => [
        pushNotificationSuccess(res),
        getSystemAnnouncementList(payload.pageSize, payload.currentPage)
      ]),
      catchRequestError(pushNotificationFailure)
    )
  )
);

/* v2 */
export const getAnnouncementListEpic = action$ =>
  action$.pipe(
    ofType(GET_ANNOUNCEMENT_LIST),
    pluck('payload'),
    map(ifElse(propEq('dept', 'xs_product'), omit(['dept']), identity)),
    switchMap(payload =>
      getAnnouncementListAPI(payload).pipe(
        map(getAnnouncementListSuccess),
        catchRequestError(getAnnouncementListFailure)
      )
    )
  );

export const addAnnouncementEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_ANNOUNCEMENT),
    pluck('payload'),
    withLatestFrom(state$),
    switchMap(
      ([
        payload,
        {
          systemAnnouncementPush: { department, pageSize }
        }
      ]) => {
        return addAnnouncementAPI(payload).pipe(
          mergeMap(e => [
            addAnnouncementSuccess(e),
            getAnnouncementList(department, pageSize, initialState.currentPage)
          ]),
          catchRequestError(addAnnouncementFailure)
        );
      }
    )
  );
export const getAnnouncementEpic = action$ =>
  action$.pipe(
    ofType(GET_ANNOUNCEMENT),
    pluck('payload'),
    switchMap(payload =>
      getAnnouncementAPI(payload).pipe(
        map(getAnnouncementSuccess),
        catchRequestError(getAnnouncementFailure)
      )
    )
  );

export const modifyAnnouncementEpic = (action$, state$) =>
  action$.pipe(
    ofType(MODIFY_ANNOUNCEMENT),
    pluck('payload'),
    withLatestFrom(state$),
    switchMap(
      ([
        payload,
        {
          systemAnnouncementPush: { department, pageSize, currentPage }
        }
      ]) =>
        setAnnouncementAPI(payload).pipe(
          mergeMap(e => [
            modifyAnnouncementSuccess(e),
            getAnnouncementList(department, pageSize, currentPage)
          ])
        )
    )
  );

export const delectAnnouncementEpic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ANNOUNCEMENT),
    pluck('payload'),
    mergeMap(ids => {
      return forkJoin(
        ids.map(id => {
          return deleteAnnouncementAPI(id).pipe(
            catchRequestError(e => {
              return { Status: 'error', id: id, message: e.message };
            })
          );
        })
      );
    }),
    withLatestFrom(state$),
    mergeMap(
      ([
        e,
        {
          systemAnnouncementPush: { department, pageSize }
        }
      ]) => {
        const errors = groupBy(v => v.Status)(e);
        if (errors['error'] && errors['error'].length > 0) {
          return [deleteAnnouncementFailure()];
        } else {
          return [
            deleteAnnouncementSuccess(),
            getAnnouncementList(department, pageSize, initialState.currentPage)
          ];
        }
      }
    )
  );

export const getCoverageEpic = action$ =>
  action$.pipe(
    ofType(GET_COVERAGE),
    pluck('payload'),
    switchMap(payload =>
      getCoverageAPI(payload).pipe(
        map(getCoverageSuccess),
        catchRequestError(getCoverageFailure)
      )
    )
  );

export const getTagListEpic = action$ =>
  action$.pipe(
    ofType(GET_TAG_LIST),
    pluck('payload'),
    switchMap(() =>
      getTagListAPI().pipe(
        map(getTagListSuccess),
        catchRequestError(getTagListFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  isShowModal: false,
  loading: false,
  coverageLoading: false,
  coverageCount: null,
  pageSize: 20,
  currentPage: 1,
  totalCount: 0,
  tagList: [],
  department: null,
  currentAnnouncement: null
};

export default handleActions(
  {
    [combineActions(
      ADD_ANNOUNCEMENT,
      MODIFY_ANNOUNCEMENT,
      DELETE_ANNOUNCEMENT
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [GET_ANNOUNCEMENT_LIST]: produce((draft, { payload }) => {
      draft.loading = true;
      draft.currentPage = payload.currentPage;
    }),
    [GET_COVERAGE]: produce((draft, { payload }) => {
      draft.coverageLoading = true;
    }),
    [GET_COVERAGE_SUCCESS]: produce((draft, { payload }) => {
      draft.coverageCount = payload.data.totalCount;
      draft.coverageLoading = false;
    }),
    [GET_COVERAGE_FAILURE]: produce((draft, { payload }) => {
      draft.coverageLoading = false;
    }),
    [GET_ANNOUNCEMENT_LIST_SUCCESS]: produce((draft, { payload }) => {
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
      draft.loading = false;
    }),
    [GET_TAG_LIST_SUCCESS]: produce((draft, { payload }) => {
      draft.tagList = filterTagByLabel(values(tagType), payload.data);
    }),
    [combineActions(
      ADD_ANNOUNCEMENT_SUCCESS,
      MODIFY_ANNOUNCEMENT_SUCCESS,
      DELETE_ANNOUNCEMENT_SUCCESS
    )]: produce((draft, { payload }) => {
      draft.isShowModal = false;
      draft.loading = false;
      draft.coverageCount = null;
      draft.currentAnnouncement = null;
    }),
    [SET_DEPARTMENT]: produce((draft, { payload }) => {
      draft.department = payload;
    }),
    [combineActions(
      GET_ANNOUNCEMENT_LIST_FAILURE,
      ADD_ANNOUNCEMENT_FAILURE,
      MODIFY_ANNOUNCEMENT_FAILURE,
      GET_ANNOUNCEMENT,
      DELETE_ANNOUNCEMENT_FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.currentAnnouncement = null;
    }),
    [combineActions(GET_ANNOUNCEMENT)]: produce(draft => {
      draft.loading = true;
      draft.currentAnnouncement = null;
    }),
    [combineActions(GET_ANNOUNCEMENT_SUCCESS)]: produce(
      (draft, { payload }) => {
        draft.loading = false;
        draft.currentAnnouncement = payload.data;
      }
    ),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
      if (!payload) {
        draft.coverageCount = null;
      }
    })
  },
  initialState
);
