import {
  always, any, complement,
  compose, curry, equals,
  ifElse, isNil, map,
  mergeLeft, move, or,
  paths, project, propEq,
  reduce, reject, when,
} from 'ramda';
import Sortable from 'sortablejs';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Button, Form, Input,
  Select, Table,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getBackendTagGroupListAPI } from '../../apis';
import { getSeriesList, setSeriesList } from '../../reducers/seriesManagement';
import { isReallyEmpty } from '../../utils/mixin';
import { PageContainer, PageTitle } from '../../components/styled/page';

const { Option } = Select;

const Tools = styled.div`
  margin: 10px 0;

  .mr8:not(:last-child) {
    margin-right: 8px;
  }
`;

const CreateButton = styled(Button)`
  width: 100%;
  margin-top: 0.5rem;
`;

const pickColumns = project(['order', 'title', 'description', 'tagGroup']);

const updateById = curry((values, id, items) =>
  map(when(propEq('id', id), mergeLeft(values)), items)
);

const removeById = (id, list) => reject(propEq('id', id), list);

const notEqual = complement(equals);

const validateInput = ifElse(
  isReallyEmpty,
  always({ validateStatus: 'error', help: '不可為空值' }),
  always({})
);

const createRowData = (base = {}) => ({
  title: '',
  tagGroup: undefined,
  description: '',
  id: uuidv4(),
  validate: { title: {}, description: {}, tagGroup: {} },
  ...base
});

const hasErrors = any(
  compose(
    reduce(or, false),
    map(complement(isNil)),
    paths([
      ['validate', 'title', 'validateStatus'],
      ['validate', 'description', 'validateStatus'],
      ['validate', 'tagGroup', 'validateStatus']
    ])
  )
);

class SeriesManagement extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      prevData: [],
      list: [],
      tagGroupLoading: false,
      tagGroups: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (notEqual(pickColumns(props.data), pickColumns(state.prevData))) {
      return {
        prevData: [...props.data],
        list: props.data.map(serie => ({
          ...createRowData(),
          ...serie
        }))
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.getSeriesList();
    this.fetchGroup();
    requestAnimationFrame(this.initSortable);
  }

  componentWillUnmount = () => {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };

  initSortable = () => {
    const tableEl = this.tableRef.current;
    const tbody = tableEl.querySelector('tbody');
    new Sortable(tbody, {
      onUpdate: this.handleDragList,
    });
  }

  /**
   * 取得 tagGroup
   */
  fetchGroup = () => {
    this.setState({ tagGroupLoading: true });
    const observable = getBackendTagGroupListAPI({
      page: 1,
      item: 999,
      typeFilter: 'user'
    });
    this.subscription = observable.subscribe(res => {
      const tagGroups = res.data;
      this.setState({ tagGroups, tagGroupLoading: false });
    });
  };

  generateTableColumn = () => {
    const { tagGroups, list } = this.state;
    return [
      {
        width: 64,
        dataIndex: 'order',
        render: value => `#${value}`
      },
      {
        title: '顯示名稱',
        dataIndex: 'title',
        render: (value, row) => (
          <Form.Item {...row.validate.title} style={{ marginBottom: 0 }}>
            <Input
              placeholder="請輸入名稱"
              className="preventDrag"
              value={value}
              onChange={({ target }) => {
                this.setStateListWithOrder(
                  updateById(
                    {
                      title: target.value,
                      validate: {
                        ...row.validate,
                        title: validateInput(target.value)
                      }
                    },
                    row.id,
                    list
                  )
                );
              }}
            />
          </Form.Item>
        )
      },
      {
        title: '標籤群組',
        dataIndex: 'tagGroup',
        render: (value, row) => (
          <Form.Item {...row.validate.tagGroup} style={{ marginBottom: 0 }}>
            <Select
              placeholder="請選擇標籤群組"
              style={{ width: '100%' }}
              value={value}
              className="preventDrag"
              onChange={value => {
                this.setStateListWithOrder(
                  updateById(
                    {
                      tagGroup: value,
                      validate: {
                        ...row.validate,
                        tagGroup: validateInput(value)
                      }
                    },
                    row.id,
                    list
                  )
                );
              }}
            >
              {tagGroups.map(template => (
                <Option key={template.id} value={template.id}>
                  <span>{template.label}</span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )
      },
      {
        title: '備註',
        dataIndex: 'description',
        render: (value, row) => (
          <Form.Item {...row.validate.description} style={{ marginBottom: 0 }}>
            <Input
              placeholder="請輸入備註"
              className="preventDrag"
              value={value}
              onChange={({ target }) => {
                this.setStateListWithOrder(
                  updateById(
                    {
                      description: target.value,
                      validate: {
                        ...row.validate,
                        description: validateInput(target.value)
                      }
                    },
                    row.id,
                    list
                  )
                );
              }}
            />
          </Form.Item>
        )
      },
      {
        width: 64,
        dataIndex: '',
        key: 'delete',
        render: (__, row) => (
          <Button
            className="preventDrag"
            shape="circle"
            onClick={() => this.setStateListWithOrder(removeById(row.id, list))}
          >
            <DeleteOutlined />
          </Button>
        )
      }
    ];
  };

  /**
   * 儲存送出
   */
  handleSaveClick = e => {
    this.setState(
      ({ list }) => ({
        list: list.map(item => ({
          ...item,
          validate: {
            title: validateInput(item.title),
            tagGroup: validateInput(item.tagGroup),
            description: validateInput(item.description)
          }
        }))
      }),
      () => {
        const { list } = this.state;
        const { setSeriesList } = this.props;
        if (!hasErrors(list)) {
          setSeriesList({ data: list });
        }
      }
    );
  };

  /**
   * 新增
   */
  handleCreateClick = () => {
    const { list } = this.state;
    this.setStateListWithOrder([...list, createRowData()]);
  };

  /**
   * 加 order
   */
  setStateListWithOrder = list => {
    this.setState({
      list: list.map((item, index) => ({
        ...item,
        order: index + 1
      }))
    });
  };

  /**
   * 處理拖曳
   */
  handleDragList = evt => {
    const { list } = this.state;
    const { oldIndex, newIndex } = evt;
    const newList = move(oldIndex, newIndex, list);
    this.setStateListWithOrder(newList);
  };

  render() {
    const { list, tagGroupLoading } = this.state;
    const { loading } = this.props;
    return (
      <PageContainer>
        <PageTitle>{'首頁管理 > 專題直播主'}</PageTitle>
        <Tools>
          <Button
            disabled={hasErrors(list)}
            className="mr8"
            onClick={this.handleSaveClick}
            type="primary"
          >
            <span>儲存</span>
          </Button>
        </Tools>
        <Table
          ref={this.tableRef}
          rowKey="id"
          dataSource={list}
          columns={this.generateTableColumn()}
          loading={loading || tagGroupLoading}
          pagination={false}
          onRow={() => ({ style: { cursor: 'move' } })}
        />
        <CreateButton
          shape="round"
          type="dashed"
          size="large"
          onClick={this.handleCreateClick}
        >
          <PlusOutlined />
        </CreateButton>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => state.seriesManagement;

const mapDispatchToProps = {
  getSeriesList,
  setSeriesList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeriesManagement);
