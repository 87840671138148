import _ from 'lodash';
import { move, remove } from 'ramda';
import Sortable from 'sortablejs';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import templates from '../../constants/tabTemplate';
import {
  getTabListAPI,
  getBackendTagGroupListAPI,
  updateBackendTabListAPI,
} from '../../apis';
import TabRow from './TabRow';
import { PageContainer, PageTitle } from '../../components/styled/page';

const Tools = styled.div`
  margin-bottom: 1rem;
`;

const TabsContainer = styled.div`
  margin-bottom: 1.75rem;

  & > div {
    margin-bottom: 0.5rem;
  }
`;

const CreateButton = styled(Button)`
  width: 100%;
  height: auto;
  padding: 0.6rem;
  background: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  border-style: dashed;
`;

class TabManagement extends React.Component {
  constructor(props) {
    super(props);

    this.tabsContainerRef = React.createRef();

    this.state = {
      tabsSortable: null,
      tabs: [],
      tagGroups: []
    };
  }

  componentDidMount() {
    this.fetchGroup();
    this.fetchTabs();

    // init sortable
    const tabsSortable = new Sortable(this.tabsContainerRef.current, {
      onUpdate: this.onTabSort
    });
    this.setState(() => ({ tabsSortable }));
  }

  fetchTabs = () => {
    const observable = getTabListAPI();
    observable.subscribe(res => {
      const tabs = res.data;

      _.forEach(tabs, tab => {
        // set groupId
        Object.assign(tab, { groupId: tab.tagGroupId });
        // generate tab id
        const id = this.generateTabId(tab);
        Object.assign(tab, { id });
      });

      this.setState(() => ({ tabs }));
    });
  };

  fetchGroup = () => {
    const observable = getBackendTagGroupListAPI({
      page: 1,
      item: 99
    });
    observable.subscribe(res => {
      const tagGroups = res.data;
      this.setState({ tagGroups });
    });
  };

  generateTabId = tab => {
    const { order, template } = tab;
    const groupId = tab.groupId || 'n';
    return `${order}-${groupId}-${template}`;
  };

  onTabSort = evt => {
    const { tabs } = this.state;
    const { oldIndex, newIndex } = evt;
    const newTabs = move(oldIndex, newIndex, tabs);

    this.setState(() => ({ tabs: newTabs }));
  };

  onTabDelete = index => {
    const { tabs } = this.state;
    const newTabs = remove(index, 1, tabs);
    this.setState(() => ({ tabs: newTabs }));
  };

  onSave = () => {
    const { tabs } = this.state;
    const data = [...tabs];

    data.forEach((tab, index) => {
      // remove useless params
      delete tab.id;

      // set order
      tab.order = index + 1;

      // set groupId to 1 when template is HOME or VOICE_CHAT
      if (tab.template === 'HOME' || tab.template === 'VOICE_CHAT') {
        tab.groupId = 1;
      }
    });

    const observable = updateBackendTabListAPI({ data });
    observable.subscribe(res => {
      this.fetchTabs();
    });
  };

  onCreateBtnClick = () => {
    const { tabs } = this.state;

    const tab = {
      id: Date.now(),
      groupId: null,
      order: tabs.length + 1,
      template: templates[0].value,
      i18nContents: { names: {} }
    };

    this.setState({ tabs: [...tabs, tab] });
  };

  render() {
    const { tabsContainerRef } = this;
    const { tabs, tagGroups } = this.state;

    return (
      <PageContainer>
        <PageTitle>{'首頁管理 > Main Menu 分類'}</PageTitle>
        <Tools>
          <Button
            type="primary"
            onClick={this.onSave}
          >
            <span>儲存</span>
          </Button>
        </Tools>
        <TabsContainer ref={tabsContainerRef}>
          {tabs.map((tab, index) => (
            <TabRow
              key={tab.id}
              index={index}
              rowData={tab}
              dataset={{ tagGroups, templates }}
              onDelete={this.onTabDelete}
            ></TabRow>
          ))}
          <CreateButton
            size="large"
            onClick={this.onCreateBtnClick}
          >
            <PlusOutlined />
          </CreateButton>
        </TabsContainer>
      </PageContainer>
    );
  }
}

export default TabManagement;
