import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { GIFT_LIST } from '../../constants/routes';
import { giftType } from '../../constants/gifts';
import { updateQuery, defaultQuery } from '../../reducers/giftList';

const items = [
  {
    label: '直播間',
    key: 'liveRoom'
  },
  {
    label: 'Live Show',
    key: 'liveShow'
  },
  {
    label: '動態',
    key: 'post'
  },
  {
    label: 'voiceChat 聲聊',
    key: 'voiceChat'
  },
  {
    label: '動效禮物',
    key: 'sfxGift'
  },
  {
    label: '背包禮物',
    key: 'backpackGift'
  },
  {
    label: '隨機禮物',
    key: 'giftRandom'
  },
  {
    label: '攻擊型動效禮物',
    key: 'offensiveSfxGift'
  }
];

const GiftSubMenu = ({ selectedKeys, className }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  function onClick(event) {
    history.push(`${GIFT_LIST}/${event.key}`);
    dispatch(
      updateQuery({
        ...defaultQuery,
        typeFilter: giftType[event.key]?.value
      })
    );
  }

  return (
    <Menu
      mode="horizontal"
      className={className}
      selectedKeys={selectedKeys}
      onClick={onClick}
      items={items}
    />
  );
};
export default GiftSubMenu;
