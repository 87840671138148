import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import WithdrawForm from './WithdrawForm';

class WithdrawModal extends React.Component {

  static propTypes = {
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  show = () => {
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  }

  onConfirm = () => {
    const { form } = this;
    const { onSubmit } = this.props;
    form.validateFields((errors, values) => {
      if (errors) return;
      if (onSubmit) onSubmit(values);
      this.hide();
    });
  };

  render() {
    const { visible } = this.state;
    return (
      <Modal
        title="個人業績結算"
        visible={visible}
        centered
        maskClosable
        onOk={this.onConfirm}
        onCancel={this.hide}
      >
        <WithdrawForm
          wrappedComponentRef={form => (this.form = form)}
        ></WithdrawForm>
      </Modal>
    );
  }
}

export default WithdrawModal;
