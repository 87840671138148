import Bulletin from './Bulletin';
import Mambers from './Mambers';
import Nobilities from './Nobilities';

export const DetailTabsEnum = Object.freeze({
  BULLETIN: 'BULLETIN',
  MEMBERS: 'MEMBERS',
  NOBILITIES: 'NOBILITIES',
});

export const DetailTabsComponentEnum = Object.freeze({
  BULLETIN: Bulletin,
  MEMBERS: Mambers,
  NOBILITIES: Nobilities,
});
