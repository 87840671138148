import { pick } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Form, Input, Select } from 'antd';
import tagTypes from '../../../constants/tagTypes';

const Option = Select.Option;

const StyledFormItem = styled(Form.Item)`
  display: flex;
  align-items: flex-start;

  .ant-form-item-label {
    width: 5rem;
  }

  .ant-form-item-control-wrapper {
    flex-grow: 1;
  }
`;

class TagGroupManageForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {};
  }

  getFormData = () => {
    const form = this.formRef.current;
    return form.getFieldsValue();
  };

  setFormData = data => {
    const form = this.formRef.current;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = pick(fields, data);
    form.setFieldsValue(values);
  };

  validateFields = () => {
    const form = this.formRef.current;
    return form.validateFields();
  };

  reset = () => {
    const form = this.formRef.current;
    form.resetFields();
  };

  render() {
    return (
      <Form
        ref={this.formRef}
        labelAlign="left"
      >
        <StyledFormItem
          label="群組名稱"
          name="label"
          htmlFor="label"
          rules={[
            { required: true, message: '請輸入群組名稱' },
          ]}
        >
          <Input id="label" placeholder="群組名稱" />
        </StyledFormItem>
        <StyledFormItem
          label="類型"
          name="type"
          rules={[
            { required: true, message: '請選擇類型' },
          ]}
        >
          <Select placeholder="請選擇類型">
            {tagTypes.map(type => (
              <Option key={type.value} value={type.value}>
                <span>{type.label}</span>
              </Option>
            ))}
          </Select>
        </StyledFormItem>
        <StyledFormItem name="id" hidden>
          <Input type="hidden" />
        </StyledFormItem>
      </Form>
    );
  }
}

export default TagGroupManageForm;
