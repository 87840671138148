import { TagsOutlined } from '@ant-design/icons';
import { Modal, Select, Spin, Tabs, Tag } from 'antd';
import {
  compose,
  concat,
  curry,
  filter as Rfilter,
  flatten,
  groupBy,
  includes,
  map as Rmap,
  omit,
  prop,
  reject,
  uniqBy,
  whereEq
} from 'ramda';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { of } from 'rxjs';
import { filter, pluck, switchMap } from 'rxjs/operators';
import { getBackendTagListAPI } from '../../apis';
import tagTypes from '../../constants/tagTypes';
const { CheckableTag } = Tag;
const GroupHeader = ({ label }) => (
  <div
    style={{
      borderBottom: '1px solid #E9E9E9',
      padding: '4px 0'
    }}
  >
    {
      <div>
        <TagsOutlined style={{ paddingRight: '8px' }} />
        {label}
      </div>
    }
  </div>
);
const pickGroup = compose(uniqBy(prop('id')), flatten, Rmap(prop('groups')));
const assignTag = curry((tagList, groupList) =>
  Rmap(
    g => ({
      ...g,
      tags: compose(
        Rmap(compose(v => ({ ...v, key: v.id }), omit(['groups']))),
        Rfilter(t => includes(g, t.groups))
      )(tagList)
    }),
    groupList
  )
);
const groupByType = groupBy(prop('type'));
const defaultValue = [];
const TagPicker = forwardRef((props, ref) => {
  const getTagList$ = useRef(
    getBackendTagListAPI({
      page: 1,
      item: 9999
    }).pipe(pluck('data'))
  );
  const [isShowModal, setIsShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupListByType, setGroupListByType] = useState([]);
  const [value, setValue] = useMergedState(props.defaultValue || defaultValue, {
    value: props.value
  });
  const [selectedTags, setSelectedTags] = useState(value);

  useEffect(() => {
    if (isShowModal) {
      setSelectedTags(value);
    }
  }, [value, isShowModal]);

  useEffect(() => {
    const subscription = of(isShowModal)
      .pipe(
        filter(isShowModal => isShowModal),
        switchMap(() => {
          setLoading(true);
          return getTagList$.current;
        })
      )
      .subscribe({
        next: data => {
          setLoading(false);
          setGroupListByType(groupByType(assignTag(data, pickGroup(data))));
        },
        error: () => {
          setLoading(false);
        },
        complete: () => {
          setLoading(false);
        }
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [isShowModal]);

  const onConfirm = () => {
    triggerChange(selectedTags);
    setIsShowModal(false);
  };

  const onModalCancel = () => {
    setIsShowModal(false);
  };

  const onAfterClose = () => {
    setSelectedTags([]);
    setLoading(false);
  };

  const onTagClick = (checked, tag) => {
    if (checked) {
      setSelectedTags(tags => compose(uniqBy(prop('id')), concat)(tags, [tag]));
    } else {
      setSelectedTags(tags => reject(whereEq(tag), tags));
    }
  };

  const triggerChange = value => {
    if (props.value === undefined) {
      setValue(value);
    }
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const reanderGroup = group => {
    return (
      <div
        key={group.id}
        style={{
          marginBottom: '8px'
        }}
      >
        <GroupHeader label={group.label}></GroupHeader>
        <div style={{ padding: '8px 16px' }}>
          {group.tags.map(tag => randerTag(group, tag))}
        </div>
      </div>
    );
  };

  const randerTag = (group, tag) => {
    return (
      <CheckableTag
        style={{
          borderColor: '#d9d9d9',
          cursor: 'pointer'
        }}
        key={`${group.id}_${tag.id}`}
        checked={includes(tag, selectedTags)}
        onChange={checked => onTagClick(checked, tag)}
      >
        {tag.label}
      </CheckableTag>
    );
  };

  return (
    <div>
      <Select
        mode="tags"
        value={value}
        open={false}
        onDropdownVisibleChange={visible => {
          setIsShowModal(visible);
        }}
        tagRender={props => <Tag closable={false}>{props.label}</Tag>}
        options={value.map(({ id, label }) => ({
          id,
          value: label
        }))}
      />
      <Modal
        open={isShowModal}
        onCancel={onModalCancel}
        onOk={onConfirm}
        afterClose={onAfterClose}
      >
        <Spin spinning={loading}>
          <Tabs
            defaultActiveKey="user"
            items={tagTypes.map(type => ({
              ...type,
              key: type.value,
              children: (groupListByType[type.value] || []).map(reanderGroup)
            }))}
          />
        </Spin>
      </Modal>
    </div>
  );
});
export default TagPicker;
