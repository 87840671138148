import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import { switchMap, map, pluck } from 'rxjs/operators';
import { getPayloadByType, catchRequestError } from '../utils/extendOperators';

import { getAvatarsAPI, addAvatarAPI, deleteAvatarAPI } from '../apis';

const GET_AVATAR = 'GET_AVATAR';
const GET_AVATAR_SUCCESS = 'GET_AVATAR_SUCCESS';
const GET_AVATAR_FAILURE = 'GET_AVATAR_FAILURE';

const ADD_AVATAR = 'ADD_AVATAR';
const ADD_AVATAR_SUCCESS = 'ADD_AVATAR_SUCCESS';
const ADD_AVATAR_FAILURE = 'ADD_AVATAR_FAILURE';

const DELETE_AVATAR = 'DELETE_AVATAR';
const DELETE_AVATAR_SUCCESS = 'DELETE_AVATAR_SUCCESS';
const DELETE_AVATAR_FAILURE = 'DELETE_AVATAR_FAILURE';

const SHOW_MODAL = 'AVATAR.SHOW_MODAL';

/**
 * Creator
 */

export const getAvatar = createAction(GET_AVATAR);
const getAvatarSuccess = createAction(GET_AVATAR_SUCCESS);
const getAvatarFailure = createAction(GET_AVATAR_FAILURE);

export const addAvatar = createAction(ADD_AVATAR);
const addAvatarSuccess = createAction(ADD_AVATAR_SUCCESS);
const addAvatarFailure = createAction(ADD_AVATAR_FAILURE);

export const deleteAvatar = createAction(DELETE_AVATAR);
const deleteAvatarSuccess = createAction(DELETE_AVATAR_SUCCESS);
const deleteAvatarFailure = createAction(DELETE_AVATAR_FAILURE);

export const showModal = createAction(SHOW_MODAL);

export const getAvatarEpic = action$ =>
  action$.pipe(
    getPayloadByType(GET_AVATAR),
    switchMap(() =>
      getAvatarsAPI().pipe(
        pluck('avatars'),
        map(getAvatarSuccess),
        catchRequestError(getAvatarFailure)
      )
    )
  );

export const addAvatarEpic = action$ =>
  action$.pipe(
    getPayloadByType(ADD_AVATAR),
    switchMap(payload =>
      addAvatarAPI(payload).pipe(
        map(addAvatarSuccess),
        catchRequestError(addAvatarFailure)
      )
    )
  );

export const deleteAvatarEpic = action$ =>
  action$.pipe(
    getPayloadByType(DELETE_AVATAR),
    switchMap(payload =>
      deleteAvatarAPI(payload).pipe(
        map(() => deleteAvatarSuccess(payload)),
        catchRequestError(deleteAvatarFailure)
      )
    )
  );

const initialState = {
  loading: false,
  isShowModal: false,
  avatars: []
};

export default handleActions(
  {
    [combineActions(GET_AVATAR, ADD_AVATAR, DELETE_AVATAR)]: produce(draft => {
      draft.loading = true;
    }),
    [GET_AVATAR_SUCCESS]: produce((draft, { payload }) => {
      draft.avatars = payload;
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [ADD_AVATAR_SUCCESS]: produce((draft, { payload }) => {
      draft.avatars.push(payload);
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [DELETE_AVATAR_SUCCESS]: produce((draft, { payload }) => {
      draft.avatars = draft.avatars.filter(el => el.id !== payload);
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
