import _ from 'lodash';
import { message } from 'antd';
import produce from 'immer';
import { groupBy, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  addDispatchAPI,
  deleteDispatchAPI,
  getDispatchAPI,
  getDispatchListAPI,
  setDispatchAPI,
  getBackendTagListAPI,
  getDispatchUsersAPI,
  withdrawDispatchUserAPI
} from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';

export const defaultQuery = { page: 1, item: 20, giftFilter: 'NON-GIFT' };
/**Type */
const GET_DISPATCH_LIST = createRequestTypes('GET_DISPATCH_LIST');
const ADD_DISPATCH = createRequestTypes('ADD_DISPATCH');
const GET_DISPATCH = createRequestTypes('GET_DISPATCH');
const SET_DISPATCH = createRequestTypes('SET_DISPATCH');
const DELETE_DISPATCH = createRequestTypes('DELETE_DISPATCH');
const SHOW_DISPATCH_MODAL = 'SHOW_DISPATCH_MODAL';
const RESET_DISPATCH = 'RESET_DISPATCH';
const GET_TAGS_LIST = createRequestTypes('GET_TAGS_LIST');
const GET_DISPATCH_USERS = createRequestTypes('GET_DISPATCH_USERS');
const WITHDRAW_DISPATCH_USER = createRequestTypes('WITHDRAW_DISPATCH_USER');
/**
 * Action Creator
 */
export const getDispatchList = createAction(
  GET_DISPATCH_LIST.REQUEST,
  (payload = {}) => ({ ...defaultQuery, ...payload })
);
export const getDispatchListSuccess = createAction(GET_DISPATCH_LIST.SUCCESS);
export const getDispatchListFailure = createAction(GET_DISPATCH_LIST.FAILURE);
export const showModal = createAction(
  SHOW_DISPATCH_MODAL,
  (isShowModal = true) => isShowModal
);
export const addDispatch = createAction(
  ADD_DISPATCH.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const addDispatchSuccess = createAction(ADD_DISPATCH.SUCCESS);
export const addDispatchFailure = createAction(ADD_DISPATCH.FAILURE);
export const getDispatch = createAction(GET_DISPATCH.REQUEST);
export const getDispatchSuccess = createAction(GET_DISPATCH.SUCCESS);
export const getDispatchFailure = createAction(GET_DISPATCH.FAILURE);
export const setDispatch = createAction(
  SET_DISPATCH.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const setDispatchSuccess = createAction(SET_DISPATCH.SUCCESS);
export const setDispatchFailure = createAction(SET_DISPATCH.FAILURE);
export const deleteDispatch = createAction(
  DELETE_DISPATCH.REQUEST,
  (ids = [], callbackAction, callback) => ({
    ids,
    callbackAction,
    callback,
  })
);
export const deleteDispatchSuccess = createAction(DELETE_DISPATCH.SUCCESS);
export const deleteDispatchFailure = createAction(DELETE_DISPATCH.FAILURE);
export const resetDispatch = createAction(RESET_DISPATCH);
export const getTagsList = createAction(GET_TAGS_LIST.REQUEST);
export const getTagsListSuccess = createAction(GET_TAGS_LIST.SUCCESS);
export const getTagsListFailure = createAction(GET_TAGS_LIST.FAILURE);
export const getDispatchUsers = createAction(GET_DISPATCH_USERS.REQUEST);
export const getDispatchUsersSuccess = createAction(GET_DISPATCH_USERS.SUCCESS);
export const getDispatchUsersFailure = createAction(GET_DISPATCH_USERS.FAILURE);
export const withdrawDispatchUser = createAction(
  WITHDRAW_DISPATCH_USER.REQUEST,
  (payload, callbackAction) => ({
    dispatchId: payload.dispatchId,
    uuids: payload.uuids,
    callbackAction
  })
);
export const withdrawDispatchUserSuccess = createAction(
  WITHDRAW_DISPATCH_USER.SUCCESS
);
export const withdrawDispatchUserFailure = createAction(
  WITHDRAW_DISPATCH_USER.FAILURE
);
/**
 * Epics
 */
export const getTagsListEpic = action$ =>
  action$.pipe(
    ofType(GET_TAGS_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getBackendTagListAPI(payload).pipe(
        map(getTagsListSuccess),
        catchRequestError(getTagsListFailure)
      )
    )
  );
export const getDispatchListEpic = action$ =>
  action$.pipe(
    ofType(GET_DISPATCH_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getDispatchListAPI(payload).pipe(
        map(getDispatchListSuccess),
        catchRequestError(getDispatchListFailure)
      )
    )
  );

export const addDispatchEpic = action$ =>
  action$.pipe(
    ofType(ADD_DISPATCH.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction }) =>
      addDispatchAPI(payload).pipe(
        mergeMap(res => {
          const actions = [addDispatchSuccess(res)];
          if (callbackAction) actions.push(callbackAction);
          return actions;
        }),
        catchRequestError(addDispatchFailure)
      )
    )
  );

export const getDispatchEpic = action$ =>
  action$.pipe(
    ofType(GET_DISPATCH.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getDispatchAPI(payload).pipe(
        map(getDispatchSuccess),
        catchRequestError(addDispatchFailure)
      )
    )
  );
export const getDispatchUserEpic = action$ =>
  action$.pipe(
    ofType(GET_DISPATCH_USERS.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getDispatchUsersAPI(payload).pipe(
        map(getDispatchUsersSuccess),
        catchRequestError(getDispatchUsersFailure)
      )
    )
  );

export const setDispatchEpic = action$ =>
  action$.pipe(
    ofType(SET_DISPATCH.REQUEST),
    pluck('payload'),
    mergeMap(({ payload, callbackAction }) =>
      setDispatchAPI(payload).pipe(
        mergeMap(res => {
          const actions = [setDispatchSuccess(res)];
          if (callbackAction) actions.push(callbackAction);
          return actions;
        }),
        catchRequestError(setDispatchFailure)
      )
    )
  );

export const deleteDispatchEpic = action$ =>
  action$.pipe(
    ofType(DELETE_DISPATCH.REQUEST),
    pluck('payload'),
    switchMap(({ ids, callbackAction, callback }) => {
      return forkJoin(
        ids.map(id =>
          deleteDispatchAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      ).pipe(
        mergeMap(res => {
          const errors = groupBy(v => v.Status)(res);
          const actions = [];
          if (errors.Error?.length > 0) {
            message.error(
              `刪除失敗: ${errors.Error.map(
                e => `id:${e.id} message: ${e.Message}`
              ).join(', ')}`
            );
            actions.push(deleteDispatchFailure());
          } else {
            actions.push(deleteDispatchSuccess());
          }
          if (callbackAction) {
            actions.push(callbackAction);
          } else if (_.isFunction(callback)) {
            callback();
          }
          return actions;
        })
      );
    })
  );
export const withdrawDispatchUserEpic = action$ =>
  action$.pipe(
    ofType(WITHDRAW_DISPATCH_USER.REQUEST),
    pluck('payload'),
    switchMap(({ dispatchId, uuids, callbackAction }) => {
      return forkJoin(
        uuids.map(id =>
          withdrawDispatchUserAPI(dispatchId, id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      ).pipe(
        mergeMap(res => {
          const errors = groupBy(v => v.Status)(res);
          const actions = [];
          if (errors.Error?.length > 0) {
            message.error(
              `刪除失敗: ${errors.Error.map(
                e => `id:${e.id} message: ${e.Message}`
              ).join(', ')}`
            );
            actions.push(withdrawDispatchUserFailure());
          } else {
            actions.push(withdrawDispatchUserSuccess());
          }
          if (callbackAction) actions.push(callbackAction);
          return actions;
        })
      );
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  tagList: [],
  dispatchUsers: [],
  loadingDispatchUsers: false,
  loading: false,
  isShowModal: false,
  currentDispatch: undefined,
  totalCount: 0
};

export default handleActions(
  {
    [GET_DISPATCH_USERS.REQUEST]: produce(draft => {
      draft.loadingDispatchUsers = true;
      draft.dispatchUsers = [];
    }),
    [GET_DISPATCH_USERS.SUCCESS]: produce((draft, { payload }) => {
      draft.loadingDispatchUsers = false;
      draft.dispatchUsers = payload.data;
    }),
    [GET_DISPATCH_USERS.FAILURE]: produce(draft => {
      draft.loadingDispatchUsers = false;
      draft.dispatchUsers = [];
    }),
    [combineActions(
      GET_DISPATCH_LIST.REQUEST,
      ADD_DISPATCH.REQUEST,
      DELETE_DISPATCH.REQUEST
    )]: produce(draft => {
      draft.loading = true;
    }),
    [combineActions(GET_DISPATCH.REQUEST, SET_DISPATCH.REQUEST)]: produce(
      draft => {
        draft.loading = true;
        draft.currentDispatch = undefined;
      }
    ),
    [GET_DISPATCH.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentDispatch = payload.data;
    }),
    [combineActions(GET_DISPATCH.FAILURE, SET_DISPATCH.FAILURE)]: produce(
      draft => {
        draft.loading = false;
        draft.currentDispatch = undefined;
        draft.isShowModal = false;
      }
    ),
    [combineActions(ADD_DISPATCH.SUCCESS, SET_DISPATCH.SUCCESS)]: produce(
      draft => {
        draft.loading = false;
        draft.currentDispatch = undefined;
        draft.isShowModal = false;
      }
    ),
    [GET_DISPATCH_LIST.SUCCESS]: produce(
      (draft, { payload: { data, totalCount } }) => {
        draft.loading = false;
        draft.data = data;
        draft.totalCount = totalCount;
      }
    ),
    [GET_DISPATCH_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_DISPATCH.FAILURE,
      DELETE_DISPATCH.SUCCESS,
      DELETE_DISPATCH.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [GET_TAGS_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.tagList = payload.data;
    }),
    [combineActions(GET_TAGS_LIST.REQUEST, GET_TAGS_LIST.FAILURE)]: produce(
      draft => {
        draft.tagList = [];
      }
    ),
    [RESET_DISPATCH]: produce((draft, { payload }) => {
      draft.currentDispatch = undefined;
    }),
    [SHOW_DISPATCH_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
