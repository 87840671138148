import { Button, Drawer, Form, Input, Modal, Space } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import React, { forwardRef, useState, useEffect } from 'react';
import { useHasErrors } from '../../hooks/useHasErrors';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import { omit, pipe, values, reject, length, gt, not } from 'ramda';
import { isReallyEmpty } from '../../utils/mixin';
const { Item: FormItem } = Form;
const { TextArea } = Input;
const isEmptyValues = pipe(values, reject(isReallyEmpty), length, gt(1));
const hasOtherLengValues = pipe(omit(['en']), isEmptyValues, not);
const formItemLayout = {
  modal: {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 16
    }
  },
  drawer: {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  }
};
export const langList = [
  {
    id: 'zh_TW',
    value: 'zh_TW',
    label: '中文(zh_TW)',
    placeholder: '請輸入中文'
  },
  {
    id: 'en',
    value: 'en',
    label: '英文(en)',
    placeholder: '請輸入英文',
    rules: [
      {
        required: true,
        message: '請輸入英文'
      }
    ]
  },
  { id: 'vi', value: 'vi', label: '越南文(vi)', placeholder: '請輸入越南文' }
];

const Display = ({ type = 'modal', ...props }) => {
  // prettier-ignore
  switch (type) {
    case 'modal':
      return <Modal {...props} />;
    case 'drawer':
      return <Drawer
        title={props.title}
        visible={props.visible}
        onClose={props.onCancel}
        afterVisibleChange={visible => {
          if (!visible) {
            props.afterClose();
          }
        }}
        extra={
          <Space>
            <Button onClick={props.onCancel}>取消</Button>
            <Button
              onClick={props.onOk}
              type="primary"
              {...props.okButtonProps}
            >
              確定
            </Button>
          </Space>
        }
      >
        {props.children}
      </Drawer>;
    default:
      console.error('請輸入正確的 type');
      return null;
  }
};
const InputComponent = ({ textArea, disabledSuffix = false, ...props }) => {
  return textArea ? (
    <Input.Group>
      <TextArea {...props} />
      {disabledSuffix ? null : (
        <GlobalOutlined
          style={{
            position: 'absolute',
            top: '10px',
            right: '12px',
            zIndex: 1,
            pointerEvents: 'none',
            cursor: 'text',
            fontSize: 12,
            color: 'rgba(0,0,0,.25)'
          }}
        />
      )}
    </Input.Group>
  ) : (
    <Input {...props} />
  );
};
const InputI18 = forwardRef(({ textArea, rows, ...props }, ref) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isRequiredTW, setIsRequiredTW] = useState(false);
  const [value, setValue] = useMergedState(props.defaultValue, {
    value: props.value
  });

  useEffect(() => {
    if (isShowModal) {
      form.setFieldsValue(value);
      setIsRequiredTW(hasOtherLengValues(value));
    }
  }, [form, value, isShowModal]);

  const onClick = () => {
    if (props.disabled !== true) {
      setIsShowModal(true);
    }
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    setIsShowModal(false);
  };

  const onFinish = values => {
    setIsShowModal(false);
    if (isEmptyValues(values)) {
      triggerChange(undefined);
    } else {
      triggerChange(values);
    }
  };

  const triggerChange = value => {
    if (props.value === undefined) {
      setValue(value);
    }
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const onValuesChange = (_, allValues) => {
    setIsRequiredTW(hasOtherLengValues(allValues));
    form.validateFields();
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };

  return (
    <>
      <InputComponent
        disabled={props.disabled}
        style={props.style}
        value={value?.en || value?.zh_TW || value?.vi || ''}
        onClick={onClick}
        placeholder={props.placeholder}
        textArea={textArea}
        rows={rows}
        suffix={
          <GlobalOutlined
            onClick={onClick}
            style={{
              cursor: 'text',
              fontSize: 12,
              color: 'rgba(0,0,0,.25)'
            }}
          />
        }
      />
      <Display
        type={props.type}
        title={props.label}
        visible={isShowModal}
        onOk={onOk}
        onCancel={onCancel}
        afterClose={onAfterClose}
        okButtonProps={{ disabled: hasErrors }}
      >
        <Form
          {...formItemLayout[props.type || 'modal']}
          form={form}
          onFinish={onFinish}
          onFieldsChange={validateStatus}
          onValuesChange={onValuesChange}
        >
          {langList.map(item => (
            <FormItem
              label={item.label}
              key={item.id}
              name={item.id}
              rules={isRequiredTW ? item?.rules : []}
            >
              <InputComponent
                placeholder={item.placeholder}
                rows={rows}
                textArea={textArea}
                disabledSuffix={true}
              />
            </FormItem>
          ))}
        </Form>
      </Display>
    </>
  );
});
export default InputI18;
