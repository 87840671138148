import {
  complement, compose, converge,
  find, head, identity, isEmpty,
  isNil, map, mergeRight,
  pick, prop, propEq,
  when,
} from 'ramda';
import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Avatar, Button, Popconfirm, Table } from 'antd';
import {
  addPastEvent,
  deletePastEvent,
  getPastEventList,
  setPastEvent,
  showModal,
} from '../../reducers/pastEventManagement';
import { getAdBannerList } from '../../reducers/adManagement';
import { findById } from '../../utils/mixin';
import antdTablePagination from '../../utils/antdTablePagination';
import LiveMasterInfo from '../../components/LiveMasterInfo';
import { PageContainer, PageTitle } from '../../components/styled/page';
import PastEventFormModal from './PastEventFormModal';

const { Column } = Table;

const Tools = styled.header`
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
  }
`;

const Reference = styled.a`
  word-wrap: break-word;
  word-break: break-word;
`;

const MasterInfo = styled.div`
  display: flex;
  align-items: center;
  word-wrap: break-word;
  word-break: break-word;

  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const mergeEvents = memoizeOne((pastEventList, adEventList) => {
  if (isEmpty(pastEventList) || isEmpty(adEventList)) {
    return [];
  }
  return map(
    converge(mergeRight, [
      identity,
      compose(
        when(complement(isNil), pick(['bannerUrl', 'linkUrl'])),
        eventId => find(propEq('id', eventId), adEventList),
        prop('eventId')
      )
    ]),
    pastEventList
  );
});

class PastEventManagement extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isCreateMode: false,
      editData: undefined,
      selectedRowKeys: [],
      defaultPagination: new antdTablePagination(),
    };
  }

  componentDidMount() {
    this.props.getAdBannerList({ zone: 'EVENT', type: '', item: 50, page: 1 });
    this.props.getPastEventList();
  }

  onSelectRowChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    });
  };

  onAddClick = () => {
    this.setState({
      editData: null,
      isCreateMode: true,
    }, this.props.showModal);
  };

  onEditClick = () => {
    this.setState(({ selectedRowKeys }) => ({
      editData: findById(head(selectedRowKeys), this.props.data),
      isCreateMode: false,
    }), this.props.showModal);
  };

  onDeleteClick = () => {
    this.props.deletePastEvent(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onFormSubmit = (values) => {
    const { addPastEvent, setPastEvent } = this.props;
    const { isCreateMode } = this.state;
    if (isCreateMode) {
      addPastEvent(values);
    } else {
      setPastEvent(values);
    }
  };

  onFormCancel = () => {
    this.setState({ editData: null });
    this.props.showModal(false);
  };

  renderImage = (src) => {
    return src && (
      <img
        src={src}
        style={{ width: '100%' }}
        alt="Preview"
      />
    );
  }

  renderLink = (refer) => {
    return (
      <Reference
        href={refer}
        target="_blank"
        rel="noreferrer"
      >
        <span>{refer}</span>
      </Reference>
    );
  }

  renderMasterInfo = (rowData) => {
    const { liveMasterId } = rowData;
    return liveMasterId.map((masterID, index) => (
      <LiveMasterInfo
        key={index}
        value={masterID}
        render={(data) => (
          <MasterInfo>
            <Avatar
              size="small"
              src={data.profilePicture}
              alt="頭像"
              style={{ marginRight: '8px' }}
            />
            <span>{data.nickname}</span>
          </MasterInfo>
        )}
      />
    ));
  }

  render() {
    const { data, loading, isShowModal, adEvents, totalCount } = this.props;
    const { editData, selectedRowKeys, isCreateMode, defaultPagination } = this.state;
    const tablePagination = { ...defaultPagination, total: totalCount };
    const pastEvents = mergeEvents(data, adEvents);
    return (
      <PageContainer>
        <PageTitle>{'首頁管理 > 上月活動'}</PageTitle>
        <Tools>
          <Button
            type="primary"
            onClick={this.onAddClick}
          >
            <span>新增</span>
          </Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onEditClick}
          >
            <span>編輯</span>
          </Button>
          <Popconfirm
            title="是否確定刪除?"
            placement="right"
            disabled={selectedRowKeys.length === 0}
            onConfirm={this.onDeleteClick}
          >
            <Button
              type="danger"
              disabled={selectedRowKeys.length === 0}
            >
              <span>刪除</span>
            </Button>
          </Popconfirm>
        </Tools>
        <Table
          rowKey="id"
          scroll={{ x: true }}
          loading={loading}
          rowSelection={{
            selectedRowKeys,
            onChange: this.onSelectRowChange
          }}
          pagination={tablePagination}
          dataSource={pastEvents}
        >
          <Column
            title="id"
            dataIndex="id"
          />
          <Column
            title="活動圖片"
            dataIndex="bannerUrl"
            width="18rem"
            render={this.renderImage}
          />
          <Column
            title="活動連結"
            dataIndex="linkUrl"
            render={this.renderLink}
          />
          <Column
            title="指定直播主"
            render={this.renderMasterInfo}
          />
        </Table>
        <PastEventFormModal
          loading={loading}
          isCreateMode={isCreateMode}
          visible={isShowModal}
          editData={editData}
          dataSource={adEvents}
          onCancel={this.onFormCancel}
          onOk={this.onFormSubmit}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = ({ pastEventManagement, adManagement }) => ({
  ...pastEventManagement,
  adEvents: adManagement.data,
  loadingAd: adManagement.loading,
});

const mapDispatchToProps = {
  getAdBannerList,
  getPastEventList,
  addPastEvent,
  setPastEvent,
  deletePastEvent,
  showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PastEventManagement);
