import React from 'react';
import styled from 'styled-components';
import { Button, Table, Tag } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  getProductInfoList,
  addProductInfo,
  setProductInfo
} from '../../reducers/remittanceProductManagement';
import RemittanceProductModal from './RemittanceProductModal';
import { connect } from 'react-redux';
const { Column } = Table;
const getLocaleString = value => <span>{value.toLocaleString()}</span>;
const StyledSection = styled.section`
  margin: 0 1rem 0 0;

  .ant-table-tbody {
    background-color: #fefefe;
  }

  .remittance-product-tools {
    margin-bottom: 1rem;
  }

  .remittance-product-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      border: 0;
      background: none;
      font-size: 1rem;
      box-shadow: none;
      overflow: hidden;

      &.delete {
        &:hover,
        &:focus {
          color: #ff4d4f;
        }
      }
    }
  }
`;
const mapState = ({ remittanceProductManagement }) =>
  remittanceProductManagement;

const mapDispatch = {
  getProductInfoList,
  addProductInfo,
  setProductInfo
};

class RemittanceProductManagement extends React.Component {
  constructor(props) {
    super(props);

    this.RemittanceProductModalRef = React.createRef();

    this.state = {
      products: []
    };
  }

  componentDidMount() {
    this.props.getProductInfoList();
  }

  showRemittanceProductModal = (action, data) => {
    const modal = this.RemittanceProductModalRef.current;
    modal.show(action, data);
  };

  onCreateBtnClick = () => {
    this.showRemittanceProductModal('create');
  };

  onEditBtnClick = rowData => {
    this.showRemittanceProductModal('edit', rowData);
  };

  renderRPTabelActions = (text, record) => {
    return (
      <div className="remittance-product-actions">
        <Button className="edit" onClick={() => this.onEditBtnClick(record)}>
          <LegacyIcon type="edit" />
        </Button>
      </div>
    );
  };

  renderRPStatus = (status, record) => {
    if (status === 1) {
      return <Tag color="green">啟用</Tag>;
    } else {
      return <Tag>停用</Tag>;
    }
  };

  render() {
    const { data, totalCount, addProductInfo, setProductInfo } = this.props;
    const pagination = {
      showSizeChanger: false,
      pageSize: 20,
      total: totalCount
    };
    return (
      <React.Fragment>
        <StyledSection>
          <h1>{'帳務管理 > 匯款商品管理'}</h1>
          <div className="remittance-product-tools">
            <Button onClick={this.onCreateBtnClick}>新增</Button>
          </div>
          <Table
            className="remittance-product-table"
            dataSource={data}
            rowKey="id"
            pagination={pagination}
          >
            <Column title="ID" dataIndex="id" key="id" />
            <Column title="商品名稱" dataIndex="productId" key="productId" />
            <Column
              title="商品點數"
              dataIndex="points"
              key="points"
              render={getLocaleString}
            />
            <Column
              title="商品金額"
              dataIndex="price"
              key="price"
              render={getLocaleString}
            />
            <Column
              title="狀態"
              dataIndex="status"
              key="status"
              render={this.renderRPStatus}
            />
            <Column title="備註" dataIndex="remark" key="remark" />
            <Column
              key="_action"
              width="10rem"
              render={this.renderRPTabelActions}
            />
          </Table>
        </StyledSection>
        <RemittanceProductModal
          addProductInfo={addProductInfo}
          setProductInfo={setProductInfo}
          ref={this.RemittanceProductModalRef}
        ></RemittanceProductModal>
      </React.Fragment>
    );
  }
}

export default connect(mapState, mapDispatch)(RemittanceProductManagement);
