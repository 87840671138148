import React, { PureComponent } from 'react';
import { Modal, Row, Input } from 'antd';
import { Form } from '@ant-design/compatible';

const FormItem = Form.Item;

@Form.create()
class ChangePasswordModal extends PureComponent {
  checkNewPassword = (rule, value, callback) => {
    const { getFieldValue } = this.props.form;
    const newPassword = getFieldValue('newPassword');
    if (value && newPassword === value) {
      return callback();
    } else {
      return callback('再輸入一次新密碼!, 新密碼不一致');
    }
  };

  handleChangePassword = () => {
    const {
      form: { validateFields },
      onCancel,
      onOk
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        onOk(values);
        onCancel();
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      onCancel
    } = this.props;
    return (
      <Modal
        visible={true}
        onCancel={onCancel}
        title="修改密碼"
        onOk={this.handleChangePassword}>
        <h3
          style={{
            margin: 10,
            color: 'red'
          }}>
          密碼修改成功後會自動登出
        </h3>
        <Form>
          <Row>
            <FormItem label="輸入舊密碼">
              {getFieldDecorator('oldPassword', {
                rules: [
                  {
                    required: true,
                    message: '輸入舊密碼!'
                  }
                ]
              })(<Input type="password" />)}
            </FormItem>
            <FormItem label="請輸入新密碼">
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    required: true,
                    message: '請輸入新密碼!'
                  }
                ]
              })(<Input type="password" />)}
            </FormItem>
            <FormItem label="再輸入一次新密碼">
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: '再輸入一次新密碼!'
                  },
                  {
                    validator: this.checkNewPassword
                  }
                ]
              })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
            </FormItem>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ChangePasswordModal;
