import React from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'antd';
import * as actions from '../../actions';
import downloadCSV from '../../utils/downloadCSV';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import { PageContainer, PageTitle } from '../../components/styled/page';

class ExportUserData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { exportDataIsLoading } = this.props;
    const { exportDataIsLoading: prevExportDataIsLoading } = prevProps;
    if (
      exportDataIsLoading === false &&
      exportDataIsLoading !== prevExportDataIsLoading
    ) {
      this.getBlobCsvUrlFromJson(this.props.dataMap);
    }
  }

  onSubmit = (values) => {
    const { loadUserExportData } = this.props;
    const { userIds } = values;
    loadUserExportData(userIds);
  };

  getBlobCsvUrlFromJson = (dataMap) => {
    return downloadCSV(
      dataMap,
      ['loginId', 'nickname', 'phoneCountryCode', 'phoneNumber'],
      ['帳號', '暱稱', '區碼', '電話']
    );
  };

  render() {
    return (
      <PageContainer>
        <PageTitle>{'帳號管理 > 匯出會員資料'}</PageTitle>
        <Form
          layout="inline"
          onFinish={this.onSubmit}
        >
          <Form.Item
            name="userIds"
            style={{ width: '35rem' }}
            rules={[
              { required: true, message: '會員欄位不得為空' }
            ]}
          >
            <LiveMasterSelect
              style={{ width: '100%' }}
              roleFilter={null}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <span>匯出帳號資料</span>
            </Button>
          </Form.Item>
        </Form>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  dataMap: state.contentManagement.userExportData,
  exportDataIsLoading: state.contentManagement.isLoading,
});

const mapDispatchToProps = {
  loadUserExportData: actions.loadUserExportData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportUserData);
