import React from 'react';
import { message, Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import VoiceChatTypeForm from './VoiceChatTypeForm';
import { addVoiceChatType, setVoiceChatType } from '../../../apis';

const actions = {
  create: {
    title: '新增聲聊類型',
    handler: 'createVoiceChatType'
  },
  edit: {
    title: '編輯聲聊類型',
    handler: 'updateVoiceChatType'
  }
};

class VoiceChatTypeModal extends React.Component {
  static propTypes = {
    onCompeleted: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      action: null,
      visible: false
    };
  }

  show = (action, data) => {
    if (!actions[action]) {
      console.error(
        `VoiceChatTypeModal: Could not support action "${action}"`
      );
      return;
    }

    this.setState({ action, visible: true }, () => {
      requestAnimationFrame(() => {
        const { form } = this;
        form.reset();
        if (data) form.setFormData(data);
      });
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  createVoiceChatType = formData => {
    const { onCompeleted } = this.props;
    const observable = addVoiceChatType(formData);
    observable.subscribe(
      () => {
        onCompeleted();
        message.success('聲聊類型新增成功');
      },
      () => message.error('聲聊類型新增失敗'),
    );
  };

  updateVoiceChatType = formData => {
    const { onCompeleted } = this.props;
    const observable = setVoiceChatType(formData);
    observable.subscribe(
      () => {
        onCompeleted();
        message.success('聲聊類型更新成功');
      },
      () => message.error('聲聊類型更新失敗'),
    );
  };

  onConfirm = () => {
    const { form } = this;
    const { action } = this.state;

    const iPromise = form.validateFields();
    iPromise.then((values) => {
      const handler = actions[action].handler;
      this[handler](values);
    });
  };

  renderFooter = () => {
    return (
      <React.Fragment>
        <Button type="primary" onClick={this.onConfirm}>
          <span>確定</span>
        </Button>
        <Button onClick={this.hide}>取消</Button>
      </React.Fragment>
    );
  };

  render() {
    const { action, visible } = this.state;
    const meta = actions[action];
    const title = meta && meta.title;

    return (
      <Modal
        className="voice-chat-modal"
        title={title}
        visible={visible}
        footer={this.renderFooter()}
        onCancel={this.hide}
        centered
        maskClosable
        width="auto"
      >
        <VoiceChatTypeForm
          ref={form => (this.form = form)}
          mode={action}
        />
      </Modal>
    );
  }
}

export default VoiceChatTypeModal;
