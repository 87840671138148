// APP 管理
export const SYSTEM_ANNOUNCEMENT_PUSH = '/system-announcement-push';
export const TAG_GROUP_MANAGEMENT = '/tag-group-management';
export const TAG_MANAGEMENT = '/tag-management';
export const APP_RANKING_MANAGEMENT = '/app-ranking-management';
export const BLOCKLIST_MANAGEMENT = '/blocklist-management';
export const FORBIDDEN_MANAGEMENT = '/forbidden-management';

// 帳號管理
export const ACCOUNT_CREATION_MANAGEMENT = '/account-creation-management';
export const USER_MANAGEMENT = '/user-management';
export const PERSONAL = '/personal';
export const DAILY_STATISTICS = '/daily-statistics-information';
export const DISMANTLING_STATISTICS = '/dismantling-statistics';
export const FANS_GIFT_RANKING = '/fans-gift-ranking';
export const EXPORT_USER_DATA = '/export-user-data';
export const ACCOUNT_DELETION_REQUEST = '/account-deletion-request';
export const LIVE_MASTER_ACCOUNT_MANAGEMENT = '/live-master-account-management';
export const LIVE_MASTER_ACCOUNT_MANAGEMENT_PERSONAL = `${LIVE_MASTER_ACCOUNT_MANAGEMENT}/personal`;
export const PHOTO_MANAGEMENT = '/photo-management';
export const LIVE_APPLICATION_REVIEW = '/live-application-review';
export const RESET_AUTH = '/reset-auth';

// 首頁管理
export const SPOTLIGHT_MANAGEMENT = '/spotlight-management';
export const TAB_MANAGEMENT = '/tab-management';
export const EVENT_BANNER_MANAGEMENT = '/event-banner-management';
export const AD_BANNER_MANAGEMENT = '/ad-banner-management';
export const FLASH_NEWS_MANAGEMENT = '/flash-news-management';
export const SERIES_MANAGEMENT = '/series-management';
export const PAST_EVENT_MANAGEMENT = '/past-event-management';
export const VIDEO_MANAGEMENT = '/video-management';
export const APP_CONFIG_MANAGEMENT = '/app-config-management';
export const BOTTOM_TABBAR_MANAGEMENT = '/bottom-tabbar-management';
export const FLOATING_SLOT_MANAGEMENT = '/floating-slot-management';
export const SIGNIN_EVENT_MANAGEMENT = '/signin-event-management';

// 活動管理
export const EVENT_CATEGORIES_MANAGEMENT = '/event-categories-management';
export const GIFT_LIST = '/gift-list';
export const AD_MANAGEMENT = '/ad-management';
export const OVERLAY_AD_MANAGEMENT = '/overlay-ad-management';
export const INTERACTIVE_GIFTBOX_MANAGEMENT = '/interactive-giftbox-management';
export const EFFECT_MANAGEMENT = '/effect-management';
export const DISPATCH_MANAGEMENT = '/dispatch-management';
export const DISPATCH_GIFT_MANAGEMENT = '/dispatch-gift-management';
export const LIVE_SHOW_MANAGEMENT = '/live-show-management';
export const QUERY_RANKING_LIST = '/query-ranking-list';

// 直播管理
export const LIVE_LIST = '/live-list';
export const LIVE_ROOM = '/live-room';
export const REPORTED_POSTS = '/reported-posts';
export const LIVE_MASTER_NETWORK_SPEED_SHEET_EXPORT =
  '/live-master-network-speed-sheet-export';

// 聲聊管理
export const PRIVATEVOICECHAT_SPOTLIGHT_MANAGEMENT =
  '/privatevoicechat-spotlight-management';
export const VOICE_CHAT_TYPE_MANAGEMENT = '/voice-chat-type-management';
export const VOICE_CHAT_LIST = '/voice-chat-list';
export const VOICE_CHAT_ROOM = '/voice-chat-room';
export const VOICE_CHAT_ROOM_REPORT = '/voice-chat-room-report';
export const VOICE_CHAT_KICKOUT_USER = '/voice-chat-kickout-user';

// 經營管理
export const DAU = '/dau';
export const MAU = '/mau';
export const CONTRACT_MANAGEMENT = '/contract-management';
export const AGENCY_MANAGEMENT = '/agency-management';
export const FAMILY_MANAGEMENT = '/family-management';
export const FAMILY_DETAIL = '/family-detail';
export const REGISTRATION_STAFF_GROWTH_REPORT =
  '/registration-staff-growth-report';
export const PERFORMANCE_GROWTH_REPORT = '/performance-growth-report';
export const STATISTICS_WATCHER = '/statistics-watcher';
export const LIVE_VIEWER_STATISTICS = '/live-viewer-statistics';
export const MOST_POPULAR_GIFT_RANKING = '/most-popular-gift-ranking';
export const LIVE_MASTER_HOURS_SHEET_EXPORT = '/live-master-hours-sheet-export';
export const GIFT_REPORT = '/gift-report';
export const ENQUIRY_MANAGEMENT = '/enquiry-management';
export const ENQUIRY_ASSISTANCE = '/enquiry-assistance';
export const MESSAGE_MANAGEMENT = '/message-management';
export const MESSAGE_ROOM_LIST = '/message-room-list';
export const DIALOG = `${MESSAGE_ROOM_LIST}/dialog`;

// 帳務管理
export const REMITTANCE_INSTRUCTION_REPORT = '/remittance-instruction-report';
export const ACCOUNT_SHEET_EXPORT = '/account-sheet-export';
export const POINT_RATIO_MANAGEMENT = '/point-ratio-management';
export const PAYMENT_TOOL_STATISTICS = '/payment-tool-statistics';
export const USER_CONSUME_SHEET_EXPORT = '/user-consume-sheet-export';
export const PURCHASE_ORDER_LIST = '/purchase-order-list';
export const REMITTANCE_PRODUCT_MANAGEMENT = '/remittance-product-management';
export const REMITTANCE_MANAGEMENT = '/remittance-management';
export const POINT_TRADING_MANAGEMENT = '/point-trading-management';

// others
export const REDIRECT = '/redirect';

// removed
export const OFF_LIST = '/off-list';
export const REPORTED_LIVE = '/reported-live';
export const REGULAR_SETTLEMENT = '/regular-settlement';
export const MANUAL_SETTLEMENT = '/manual-settlement';
export const ANIMATION_EFFECT_MANAGEMENT = '/animation-effect-management';
export const REWARD_MANAGEMENT = '/reward-management';
export const COMMENT_MANAGEMENT = '/comment-management';
export const STATISTICS_INFO = '/statistics-info';
export const BANK_ACCOUNT_SETTINGS = '/bank-account-settings';
export const LIVE_PROFIT_SHARE_DOC_APPLICATION_SETTING =
  '/live-profit-share-doc-application-setting';
export const TOTAL_SHARE_RATE = '/total-share-rate';
export const MANAGER_COMPANY_BANK_ACCOUNT_SETTINGS =
  '/manager-company-bank-account-settings';
export const ANCHOR_MANAGEMENT = '/anchor-management';
export const ANCHOR_APPLICATION = '/anchor-application';
export const ACHIEVEMENT_MANAGEMENT = '/achievement-management';
export const ASSIGNED_ACHIEVEMENTS = '/assigned-achievements';
