import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledVideoContent = styled.div`
  width: 16.5rem;
  margin: 0.25rem;
  position: relative;
  border-radius: 1em;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #212121;
  }

  &::after {
    content: '';
    padding-top: 75%;
    display: block;
  }
`;

function VideoContent(props) {
  const { message } = props;
  const source = _.get(message, 'videoUrl', '');
  return (
    <StyledVideoContent>
      <video
        src={source}
        controls
        disablePictureInPicture
      />
    </StyledVideoContent>
  );
}

VideoContent.propTypes = {
  message: PropTypes.object.isRequired,
};

export default VideoContent;
