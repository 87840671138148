import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ChatText = styled.div`
  padding: 0.5rem 0.75rem;
`;

function ChatContent(props) {
  const { message } = props;
  const text = _.get(message, 'textContent', '');
  return <ChatText>{text}</ChatText>;
}

ChatContent.propTypes = {
  message: PropTypes.object.isRequired,
};

export default ChatContent;
