import React from 'react';
import { Table } from 'antd';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { getFansGiftRankAPI } from '../../../apis';
import antdTablePagination from '../../../utils/antdTablePagination';
import { PageContainer, PageTitle } from '../../../components/styled/page';

const { Column } = Table;

const GoBackLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

class FansGiftRanking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataset: [],
      pagination: {
        ...new antdTablePagination(),
        current: 1,
      },
    };
  }

  componentDidMount() {
    this.fetchFansGiftRank();
  }

  fetchFansGiftRank = () => {
    const { match } = this.props;
    const { pagination } = this.state;
    const { id } = match.params;
    const { pageSize } = pagination;
    const page = pagination.current - 1;
    const observable = getFansGiftRankAPI(id, page, pageSize);
    observable.subscribe((res) => {
      const { pagination } = this.state;
      const { totalCount, users } = res;
      const newPagination = {
        ...pagination,
        total: totalCount,
      };
      this.setState({
        dataset: users,
        pagination: newPagination,
      });
    });
  }

  onTableChange = (pagination) => {
    this.setState({
      pagination,
    }, this.fetchFansGiftRank);
  }

  render() {
    const { history } = this.props;
    const { dataset, pagination } = this.state;
    return (
      <PageContainer>
        <PageTitle>
          <span>{'帳號管理 > '}</span>
          <GoBackLink onClick={history.goBack}>
            <span>{'直播主帳號管理'}</span>
          </GoBackLink>
          <span>{'> 粉絲贈禮排行榜'}</span>
        </PageTitle>
        <Table
          rowKey="createUserId"
          dataSource={dataset}
          pagination={pagination}
          onChange={this.onTableChange}
        >
          <Column
            title="帳號"
            dataIndex="loginId"
          />
          <Column
            title="累積贈禮"
            dataIndex="totalPoint"
          />
        </Table>
      </PageContainer>
    );
  }
}

export default withRouter(FansGiftRanking);
