// TODO: do not use local token when API compelete.

import md5 from 'blueimp-md5';

// set appSign when test if api do not compeleted.
const appSign = '';

function getZeGouToken(appId, userID) {
  let nonce = new Date().getTime().toString();
  let time = Math.floor(new Date().getTime() / 1000 + 30 * 60);
  let appSign32 = appSign.replace(/0x/g, '').replace(/,/g, '').substring(0, 32);

  if (appSign32.length < 32) {
    console.log('private sign erro!!!!');
    return null;
  }

  let sourece = md5(appId + appSign32 + userID + nonce + time);

  let jsonStr = JSON.stringify({
    'ver': 1,
    'expired': time,
    'nonce': nonce,
    'hash': sourece,
  });

  return btoa(jsonStr);
}

export default getZeGouToken;
