import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import styled from 'styled-components';
import {
  getSupportAPI,
  getUserInfoAPI,
  getSupportCategoryAPI,
} from '../../apis';
import ChatRoom from '../../components/InstantChatRoom';
import SupportInfo from './SupportInfo';
import SupportHistory from './SupportHistory';
import EnquiryContext from './EnquiryContext';

const { TabPane } = Tabs;

const StyledSection = styled.section`
  min-width: 900px;
  margin: 0 1rem 0 0;
`;

const StyledPanel = styled.div`
  display: flex;
`;

const StyledLeftPanel = styled.div`
  width: 33rem;
  flex-shrink: 0;

  .ant-tabs-nav {
    margin-bottom: -1px;
    user-select: none;
  }

  .ant-tabs-content {
    background: white;
    border: 1px solid #e8e8e8;
  }

  .ant-tabs-tabpane {
    height: 40rem;
    padding: 1rem;
  }
`;

const StyledRightPanel = styled.div`
  min-width: 33rem;
  margin-left: 2rem;
  flex-shrink: 0;
  flex-grow: 1;
`;

class EnquiryAssistance extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      support: {},
      client: {},
      supportCategories: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
  }

  async init() {
    this.fetchSupportCategories();
    await this.fetchSupport();
    await this.fetchUserInfo();
    this.setState({ isReady: true });
  }

  getContextValue = () => {
    return _.pick(this.state, [
      'client',
      'supportCategories',
    ]);
  }

  fetchSupport = () => {
    const { match } = this.props;
    const id = _.get(match, 'params.id');
    const iPromise = getSupportAPI(id).toPromise();
    iPromise.then((res) => {
      const { data } = res;
      this.setState({ support: data });
    });
    return iPromise;
  }

  fetchUserInfo = () => {
    const { support } = this.state;
    const uuid = _.get(support, 'user.id');
    const iPromise = getUserInfoAPI(uuid).toPromise();
    iPromise.then((data) => {
      this.setState({ client: data });
    });
    return iPromise;
  }

  fetchSupportCategories = () => {
    const observable = getSupportCategoryAPI();
    observable.subscribe((res) => {
      const { data } = res;
      this.setState({ supportCategories: data });
    });
  }

  renderChatroom = () => {
    const { auth } = this.props;
    const { client } = this.state;
    return (
      <ChatRoom
        master={auth}
        participant={client}
      />
    );
  }

  render() {
    const { isReady, support } = this.state;
    const ContextValue = this.getContextValue();

    return isReady ? (
      <EnquiryContext.Provider value={ContextValue}>
        <StyledSection>
          <h1>{'經營管理 > 客服意見管理'}</h1>
          <StyledPanel>
            <StyledLeftPanel>
              <Tabs type="card">
                <TabPane tab="反饋資訊" key="1">
                  <SupportInfo
                    dataset={support}
                    onUpdateSupport={this.fetchSupport}
                  ></SupportInfo>
                </TabPane>
                <TabPane tab="訊息紀錄" key="2">
                  <SupportHistory dataset={support}></SupportHistory>
                </TabPane>
              </Tabs>
            </StyledLeftPanel>
            <StyledRightPanel>
              {this.renderChatroom()}
            </StyledRightPanel>
          </StyledPanel>
        </StyledSection>
      </EnquiryContext.Provider>
    ) : null;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
)(EnquiryAssistance);
