import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'antd';

const StyledImage = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  &::after {
    content: '';
    padding-top: 75%;
    display: block;
  }
`;

const PreviewImage = styled(StyledImage)`
  width: 16.5rem;
  margin: 0.25rem;
  border-radius: 1em;
  background-size: cover;
  cursor: pointer;
`;

const OverviewImage = styled(StyledImage)`
  width: 40vw;
  background-size: contain;
`;

function ImageContent(props) {
  const { message } = props;
  const source = _.get(message, 'imageUrl', '');

  const [preview, setPreview] = useState(false);

  return (
    <React.Fragment>
      <PreviewImage src={source} onClick={() => setPreview(true)}/>
      <Modal
        title="預覽圖片"
        visible={preview}
        footer={null}
        width="auto"
        centered
        onCancel={() => setPreview(false)}
      >
        <OverviewImage src={source} />
      </Modal>
    </React.Fragment>
  );
}

ImageContent.propTypes = {
  message: PropTypes.object.isRequired,
};

export default ImageContent;
