import { head } from 'ramda';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { message, Button, Popconfirm, Table } from 'antd';
import { getFlashNewsListAPI, deleteFlashNewsAPI } from '../../apis';
import antdTablePagination from '../../utils/antdTablePagination';
import { findById } from '../../utils/mixin';
import { PageContainer, PageTitle } from '../../components/styled/page';
import FlashNewsModal from './FlashNewsModal';

const { Column } = Table;

const Actions = styled.header`
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
  }
`;

const Reference = styled.a`
  word-wrap: break-word;
  word-break: break-word;
`;

class FlashNewsManagement extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.FlashNewsModalRef = React.createRef();

    this.state = {
      loading: false,
      dataset: [],
      selectedRowKeys: [],
      pagination: new antdTablePagination(),
    };
  }

  componentDidMount() {
    this.fetchFlashNewsList();
  }

  fetchFlashNewsList = () => {
    this.setState({ loading: true });
    const iPromise = getFlashNewsListAPI().toPromise();;
    iPromise.then((res) => {
      const { data } = res;
      this.setState({ dataset: data });
    });
    iPromise.finally(() => {
      this.setState({ loading: false });
    });
  }

  showFlashNewsModal = (mode, data) => {
    const modal = this.FlashNewsModalRef.current;
    modal.show(mode, data);
  }

  onSelectRowChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onAddClick = () => {
    this.showFlashNewsModal('create');
  };

  onEditClick = () => {
    const { dataset, selectedRowKeys } = this.state;
    const editData = findById(head(selectedRowKeys), dataset);
    this.showFlashNewsModal('edit', editData);
  };

  onDeleteClick = () => {
    const { selectedRowKeys } = this.state;
    const arr = selectedRowKeys.map((id) => {
      return deleteFlashNewsAPI(id).toPromise();
    });
    const iPromise = Promise.all(arr);
    iPromise.then(() => {
      this.setState({ selectedRowKeys: [] });
      message.success('廣播資料，刪除成功');
    });
    iPromise.catch(() => {
      message.error('部分資料刪除失敗');
    });
    iPromise.finally(() => {
      this.fetchFlashNewsList();
    });
  };

  onTableChange = (pagination) => {
    this.setState({ pagination });
  }

  renderImage = (src) => {
    return (
      <img
        alt="Preview"
        src={src}
        style={{ width: '100%', height: 'auto' }}
      />
    );
  }

  renderReference = (refer) => {
    return (
      <Reference
        href={refer}
        rel="noreferrer"
        target="_blank"
      >
        <span>{refer}</span>
      </Reference>
    );
  }

  renderShelfTime = (rowData) => {
    const { startTime, endTime } = rowData;
    const format = 'YYYY-MM-DD HH:mm:ss';
    const start = moment.unix(startTime).format(format);
    const end = moment.unix(endTime).format(format);
    return (
      <React.Fragment>
        <div>{`${start} ~`}</div>
        <div>{` ${end}`}</div>
      </React.Fragment>
    );
  }

  render() {
    const {
      dataset, loading, pagination,
      selectedRowKeys,
    } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'首頁管理 > 官方廣播'}</PageTitle>
        <Actions>
          <Button
            type="primary"
            onClick={this.onAddClick}
          >
            <span>新增</span>
          </Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onEditClick}
          >
            <span>編輯</span>
          </Button>
          <Popconfirm
            title="是否確定刪除?"
            placement="right"
            onConfirm={this.onDeleteClick}
          >
            <Button
              type="danger"
              disabled={selectedRowKeys.length <= 0}
            >
              <span>刪除</span>
            </Button>
          </Popconfirm>
        </Actions>
        <Table
          rowKey="id"
          dataSource={dataset}
          loading={loading}
          pagination={pagination}
          onChange={this.onTableChange}
          rowSelection={{
            selectedRowKeys,
            onChange: this.onSelectRowChange,
          }}
        >
          <Column
            title="頭像圖片"
            dataIndex="imageUrl"
            width="12rem"
            render={this.renderImage}
          />
          <Column
            title="廣播內容"
            dataIndex={['i18nContents', 'texts', 'zh_TW']}
            width="30%"
          />
          <Column
            title="連結網址"
            dataIndex="linkUrl"
            render={this.renderReference}
          />
          <Column
            width="14rem"
            title="上下架時間"
            render={this.renderShelfTime}
          />
        </Table>
        <FlashNewsModal
          ref={this.FlashNewsModalRef}
          onCompleted={this.fetchFlashNewsList}
        />
      </PageContainer>
    );
  }
}

export default FlashNewsManagement;
