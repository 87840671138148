import produce from 'immer';
import moment from 'moment';
import {
  assoc,
  complement,
  compose,
  converge,
  evolve,
  fromPairs,
  identity,
  is,
  map as Rmap,
  mergeRight,
  prop,
  propSatisfies,
  when
} from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { map, mergeMap, pluck, switchMap, tap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import { addPointTransferAPI, getPointTransferListAPI } from '../apis';
import brandName from '../constants/brandName';
import downloadCSV from '../utils/downloadCSV';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { isReallyEmpty } from '../utils/mixin';

const formatCSVData = (uuid, data) =>
  Rmap(
    compose(
      value =>
        assoc('status', value.receiverId === uuid ? '轉入' : '轉出', value),
      when(
        propSatisfies(complement(isReallyEmpty), 'image'),
        converge(mergeRight, [identity, compose(fromPairs, prop('image'))])
      ),
      evolve({
        image: value =>
          value.map((image, index) => [
            `image${index + 1}`,
            `=HYPERLINK("${image}","圖片連結")`
          ]),
        createdAt: value => moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
      })
    ),
    data
  );

/**Type */
const GET_POINT_TRANSFER_LIST = createRequestTypes('GET_POINT_TRANSFER_LIST');
const ADD_POINT_TRANSFER = createRequestTypes('ADD_POINT_TRANSFER');
const DOWNLOAD_POINT_TRANSFER = createRequestTypes('DOWNLOAD_POINT_TRANSFER');
const DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO = createRequestTypes(
  'DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO'
);

const SHOW_MODAL = 'SHOW_ADD_TRANSFER_MODAL';

/**
 * Action Creator
 */
export const getPointTransferList = createAction(
  GET_POINT_TRANSFER_LIST.REQUEST
);
export const getPointTransferListSuccess = createAction(
  GET_POINT_TRANSFER_LIST.SUCCESS
);
export const getPointTransferListFailure = createAction(
  GET_POINT_TRANSFER_LIST.FAILURE
);
export const showModal = createAction(
  SHOW_MODAL,
  (isVisible = true) => isVisible
);
export const addPointTransfer = createAction(
  ADD_POINT_TRANSFER.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback
  })
);
export const addPointTransferSuccess = createAction(ADD_POINT_TRANSFER.SUCCESS);
export const addPointTransferFailure = createAction(ADD_POINT_TRANSFER.FAILURE);
export const downloadPointTransfer = createAction(
  DOWNLOAD_POINT_TRANSFER.REQUEST
);
export const downloadPointTransferSuccess = createAction(
  DOWNLOAD_POINT_TRANSFER.SUCCESS
);
export const downloadPointTransferFailure = createAction(
  DOWNLOAD_POINT_TRANSFER.FAILURE
);
export const downloadPointTransferWithoutRatio = createAction(
  DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO.REQUEST
);
export const downloadPointTransferWithoutRatioSuccess = createAction(
  DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO.SUCCESS
);
export const downloadPointTransferWithoutRatioFailure = createAction(
  DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO.FAILURE
);

/**
 * Epics
 */
export const getPointTransferListEpic = action$ =>
  action$.pipe(
    ofType(GET_POINT_TRANSFER_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getPointTransferListAPI(payload).pipe(
        map(getPointTransferListSuccess),
        catchRequestError(getPointTransferListFailure)
      )
    )
  );

export const addPointTransferEpic = action$ =>
  action$.pipe(
    ofType(ADD_POINT_TRANSFER.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      addPointTransferAPI(payload).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [addPointTransferSuccess(res), callbackAction]
            : [addPointTransferSuccess(res)];
        }),
        catchRequestError(addPointTransferFailure)
      )
    )
  );

export const downloadPointTransferListEpic = (action$, state$) =>
  action$.pipe(
    ofType(DOWNLOAD_POINT_TRANSFER.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getPointTransferListAPI(payload).pipe(
        tap(({ data }) => {
          const forDownload = formatCSVData(payload.userFilter, data);
          downloadCSV(
            forDownload,
            [
              'createdAt',
              'receiverXtarsId',
              'receiverLoginId',
              'receiverNickname',
              'status',
              'point',
              'senderRatio',
              'createXtarsId',
              'createNickname',
              'remark',
              'image1',
              'image2',
              'image3'
            ],
            [
              '轉點時間',
              `轉點對象 ${brandName} ID`,
              '轉點對象 Login ID',
              '轉點對象暱稱',
              '轉入/轉出',
              '點數',
              '轉點比值',
              `操作者 ${brandName} ID`,
              '操作者暱稱',
              '備註',
              '圖片1',
              '圖片2',
              '圖片3'
            ]
          );
        }),
        map(downloadPointTransferSuccess),
        catchRequestError(downloadPointTransferFailure)
      )
    )
  );

export const downloadPointTransferWithOutRatioEpic = (action$, state$) =>
  action$.pipe(
    ofType(DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getPointTransferListAPI(payload).pipe(
        tap(({ data }) => {
          const forDownload = formatCSVData(payload.userFilter, data);
          downloadCSV(
            forDownload,
            [
              'createdAt',
              'receiverXtarsId',
              'receiverLoginId',
              'receiverNickname',
              'status',
              'point',
              'createXtarsId',
              'createNickname',
              'remark',
              'image1',
              'image2',
              'image3'
            ],
            [
              '轉點時間',
              `轉點對象 ${brandName} ID`,
              '轉點對象 Login ID',
              '轉點對象暱稱',
              '轉入/轉出',
              '點數',
              `操作者 ${brandName} ID`,
              '操作者暱稱',
              '備註',
              '圖片1',
              '圖片2',
              '圖片3'
            ]
          );
        }),
        map(downloadPointTransferSuccess),
        catchRequestError(downloadPointTransferFailure)
      )
    )
  );
/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false,
  isShowModal: false,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_POINT_TRANSFER_LIST.REQUEST,
      ADD_POINT_TRANSFER.REQUEST,
      DOWNLOAD_POINT_TRANSFER.REQUEST,
      DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(ADD_POINT_TRANSFER.SUCCESS)]: produce(
      (draft, { payload }) => {
        draft.loading = false;
        draft.isShowModal = false;
      }
    ),
    [combineActions(
      DOWNLOAD_POINT_TRANSFER.SUCCESS,
      DOWNLOAD_POINT_TRANSFER.FAILURE,
      DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO.SUCCESS,
      DOWNLOAD_POINT_TRANSFER_WITHOUT_RATIO.FAILURE
    )]: produce(draft => {
      draft.loading = false;
    }),
    [GET_POINT_TRANSFER_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
    }),
    [GET_POINT_TRANSFER_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(ADD_POINT_TRANSFER.FAILURE)]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
