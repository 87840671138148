import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MessagePoolContext from './MessagePoolContext';
import Period from './Period';

const StyledMessagePool = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  background-color: #c8dcfa;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  user-select: text;
  outline: none;

  .message-pool-inner {
    width: 100%;
    padding-bottom: 0.5rem;
  }
`;

class MessagePool extends React.Component {

  static propTypes = {
    master: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
    messages: PropTypes.object,
    onLoadHistory: PropTypes.func,
  }

  static defaultProps = {
    messages: {},
  }

  constructor(props) {
    super(props);

    this.MessagePoolRef = React.createRef();

    this.state = {
      isAtBottom: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { messages: prevMessages } = prevProps;
    const { messages } = this.props;
    if (!_.isEqual(prevMessages, messages)) {
      requestAnimationFrame(this.checkToUpdateScroll);
    }
  }

  getContextValue = () => {
    const { master, participant } = this.props;
    return { master, participant };
  }

  focus = () => {
    const { MessagePoolRef } = this;
    const el = MessagePoolRef.current;
    el.focus();
  }

  checkToUpdateScroll = () => {
    const { isAtBottom } = this.state;
    if (isAtBottom) this.scrollToBottom();
  }

  requestToBottom = () => {
    this.setState({ isAtBottom: true });
  }

  scrollToBottom = () => {
    const { MessagePoolRef } = this;
    const el = MessagePoolRef.current;
    el.scroll({ top: el.scrollHeight });
  }

  infinite = _.throttle(() => {
    const { MessagePoolRef } = this;
    const { onLoadHistory } = this.props;
    const el = MessagePoolRef.current;
    if (el && el.scrollTop < 100) {
      if (onLoadHistory) onLoadHistory();
    }
  }, 1000, { leading: false });

  detectIsAtBottom = () => {
    const { MessagePoolRef } = this;
    const el = MessagePoolRef.current;
    const bottom = el.scrollHeight - el.offsetHeight;
    const isAtBottom = el.scrollTop >= bottom;
    this.setState({ isAtBottom });
  }

  onScroll = _.throttle(() => {
    this.infinite();
    this.detectIsAtBottom();
  }, 100);

  renderMessages = () => {
    const { messages } = this.props;
    const isEmpty = _.isEmpty(messages);
    return isEmpty ? null : (
      <React.Fragment>
        {_.map(messages, (period, key) => (
          <Period key={key} day={key} dataset={period} />
        ))}
      </React.Fragment>
    );
  }

  render() {
    const ContextValue = this.getContextValue();
    return (
      <MessagePoolContext.Provider value={ContextValue}>
        <StyledMessagePool
          ref={this.MessagePoolRef}
          onScroll={this.onScroll}
          tabIndex={0}
        >
          <div className="message-pool-inner">
            {this.renderMessages()}
          </div>
        </StyledMessagePool>
      </MessagePoolContext.Provider>
    );
  }
}

export default MessagePool;
