import React from 'react';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';
import FlashNewsForm from './FlashNewsForm';
import {
  addFlashNewsAPI,
  setFlashNewsAPI,
} from '../../../apis';
import copyValueFromTo from '../../../utils/copyValueFromTo';

const actions = Object.freeze({
  create: {
    title: '新增官方廣播',
    handler: 'createFlashNews',
  },
  edit: {
    title: '編輯官方廣播',
    handler: 'updateFlashNews',
  }
});

class FlashNewsModal extends React.Component {
  static propTypes = {
    onCompleted: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      action: null,
      visible: false,
      editData: null,
    };
  }

  show = (action, data) => {
    if (!actions[action]) {
      console.error(
        `FlashNewsModal: Could not support action "${action}"`
      );
      return;
    }

    this.setState({
      action,
      visible: true,
      editData: data,
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  generatePayload = (formData) => {
    const { dateRange } = formData;
    const payload = { ...formData };
    Object.assign(payload, {
      startTime: dateRange[0].unix(),
      endTime: dateRange[1].unix(),
    });
    delete payload.dateRange;
    return copyValueFromTo(['i18nContents', 'texts', 'zh_TW'], ['text'], payload);
  }

  createFlashNews = (formData) => {
    const payload = this.generatePayload(formData);
    const observable = addFlashNewsAPI(payload);
    observable.subscribe(() => {
      message.success('廣播資料，新增成功');
      this.onActionCompleted();
    }, () => {
      message.error('廣播資料，新增失敗');
    });
  };

  updateFlashNews = (formData) => {
    const payload = this.generatePayload(formData);
    const observable = setFlashNewsAPI(payload);
    observable.subscribe(() => {
      message.success('廣播資料，更新成功');
      this.onActionCompleted();
    }, () => {
      message.error('廣播資料，更新失敗');
    });
  };

  onFormMount = () => {
    const form = this.formRef.current;
    const { editData } = this.state;
    form.setFormData(editData);
  }

  onConfirm = () => {
    const form = this.formRef.current;
    const { action } = this.state;
    const iPromise = form.validateFields();
    iPromise.then((values) => {
      const handler = actions[action].handler;
      this[handler](values);
    });
  };

  onActionCompleted = () => {
    const { hide } = this;
    const { onCompleted } = this.props;
    if (onCompleted) onCompleted();
    hide();
  }

  render() {
    const { action, visible } = this.state;
    const meta = actions[action];
    const title = meta && meta.title;
    return (
      <Modal
        title={title}
        visible={visible}
        destroyOnClose={true}
        centered
        maskClosable
        onOk={this.onConfirm}
        onCancel={this.hide}
      >
        <FlashNewsForm
          ref={this.formRef}
          onMount={this.onFormMount}
        />
      </Modal>
    );
  }
}

export default FlashNewsModal;
