import { evolve, filter, range } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Form, Select } from 'antd';
import { getAgencyList } from '../../../../reducers/agencyManagement';

const FormItem = Form.Item;
const { Option } = Select;

const FilterForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .ant-form-explain {
    display: none;
  }
`;

class RevenueWithdrawFilters extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func
  };

  constructor(props) {
    super(props);

    const now = new Date();

    this.formRef = React.createRef();

    this.state = {
      currentYear: now.getFullYear(),
      yearList: [],
      agencyList: []
    };
  }

  componentDidMount() {
    if (this.props.agencyList.length <= 0) {
      this.props.getAgencyList({
        page: 1,
        agencyStatusFilter: 'progress',
        item: 9999
      });
    }
    this.init();
  }

  init = () => {
    const { currentYear } = this.state;
    const period = range(currentYear - 4, currentYear + 1);
    const yearList = period.reverse();
    this.setState({ yearList });
  };

  getFormData = () => {
    const form = this.formRef.current;
    return evolve({ year: Number }, form.getFieldsValue());
  };

  getFormAgency = () => {
    const form = this.formRef.current;
    const { agencyList } = this.state;
    const id = form.getFieldValue('agencyId');
    return agencyList.find(agency => agency.agencyId === id);
  };

  getFieldValue = (field) => {
    const form = this.formRef.current;
    return form.getFieldValue(field);
  };

  validateFields = () => {
    const form = this.formRef.current;
    return form.validateFields();
  };

  onAgencySearch = value => {
    const { agencyList } = this.props;
    const list = filter(agency => {
      const { agencyName } = agency;
      return agencyName.indexOf(value) >= 0;
    }, agencyList);
    this.setState({ agencyList: list });
  };

  onSubmit = (values) => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit(values);
  }

  render() {
    const { className } = this.props;
    const { agencyList, yearList, currentYear } = this.state;
    return (
      <FilterForm
        ref={this.formRef}
        layout="inline"
        className={className}
        onFinish={this.onSubmit}
      >
        <FormItem
          name="agencyId"
          label="經紀公司"
          rules={[
            { required: true },
          ]}
        >
          <Select
            style={{ width: '8rem' }}
            showSearch
            showArrow={false}
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={null}
            onSearch={this.onAgencySearch}
          >
            {agencyList.map(item => (
              <Option
                key={item.agencyId}
                title={item.agencyName}
                value={item.agencyId}
              >
                <span>{item.agencyName}</span>
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          name="year"
          label="年份"
          initialValue={currentYear}
          rules={[
            { required: true, type: 'number' },
          ]}
        >
          <Select style={{ width: '8rem' }}>
            {yearList.map((n) => (
              <Option key={n} value={n}>{n}</Option>
            ))}
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">
            <span>查詢</span>
          </Button>
        </FormItem>
      </FilterForm>
    );
  }
}

const mapStateToProps = state => ({
  agencyList: state.agencyManagement.data,
});

const mapDispatchToProps = {
  getAgencyList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(RevenueWithdrawFilters);
