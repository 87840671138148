import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'antd';
import { ControlOutlined } from '@ant-design/icons';

const ActivityButton = styled.button`
  padding: 0.5rem;
  border: 0;
  background: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  .anticon {
    margin-right: 0.25rem;
    font-size: 1.2em;
  }
`;

const ActivityNamesEnum = Object.freeze({
  active: '活躍成員',
  inactive: '非活躍成員',
});

const ActivityMenuItems = Object.freeze([{
  label: ActivityNamesEnum.active,
  key: 'active',
}, {
  label: ActivityNamesEnum.inactive,
  key: 'inactive',
}]);

class ActivityFilters extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.onDropdownClick(ActivityMenuItems[0]);
  };

  triggerChange = (key) => {
    const { onChange } = this.props;
    if (onChange) onChange(key);
  };

  onDropdownClick = (item) => {
    const { key } = item;
    this.setState({
      value: key,
    }, this.triggerChange.bind(this, key));
  };

  render() {
    const { value } = this.state;
    const name = ActivityNamesEnum[value];
    return (
      <Dropdown
        trigger="click"
        placement="bottomRight"
        menu={{
          items: ActivityMenuItems,
          selectable: true,
          selectedKeys: [value],
          onClick: this.onDropdownClick,
        }}
      >
        <ActivityButton>
          <ControlOutlined />
          <span>{name}</span>
        </ActivityButton>
      </Dropdown>
    );
  }
}

export default ActivityFilters;
