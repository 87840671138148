import { ajax } from 'rxjs/ajax';
import { API_V2 } from '../constants/endpoint';
import { createQs } from '../utils/mixin';
import { createRequest } from './utils';

/**
 * request for v2
 */
const request = createRequest(API_V2);

export const login = request('/individual/auth/web/login', 'POST');
export const getPersonInfoAPI = request('/individual/myInfo');
export const addAccountAPI = payload =>
  request('/backend/individual/register', 'POST')(payload);
export const getAppRankginListAPI = () =>
  request('/billboard/app/menu', 'GET')();
export const updateAppRankginListAPI = payload =>
  request('/backend/billboard/menu', 'PUT')(payload);
export const getAnimationEffectListAPI = () =>
  request('/backend/sfx/list', 'GET')();
export const addAnimationEffectAPI = payload =>
  request('/backend/sfx/', 'POST')(payload);
export const setAnimationEffectAPI = payload =>
  request(`/backend/sfx/${payload.id}`, 'POST')(payload);
export const deleteAnimationEffectAPI = id =>
  request(`/backend/sfx/${id}`, 'DELETE')();
export const liveInterruptionAPI = roomId =>
  request(`/backend/streamer/liveRoom/${roomId}`, 'DELETE')();
export const uploadSFX = payload => request('/cdn/sfx', 'POST')(payload);
export const getRewardListAPI = () =>
  request('/backend/sfx/reward/list', 'GET')();
export const addRewardAPI = payload =>
  request('/backend/sfx/reward/', 'POST')(payload);
export const setRewardAPI = payload =>
  request(`/backend/sfx/reward/${payload.id}`, 'POST')(payload);
export const deleteRewardAPI = id =>
  request(`/backend/sfx/reward/${id}`, 'DELETE')();
export const getLiveRecord = payload =>
  request('/support/streamer/liveRecord', 'POST', 'V2')(payload);
export const resetSMSAPI = payload =>
  request('/support/sms/reset', 'POST', 'V2')(payload);
export const resetEmailAPI = payload =>
  request('/support/email/reset', 'POST', 'V2')(payload);
export const uploadAPI = url => request(url, 'POST', 'V2');
export const getAchievementListAPI = () =>
  request('/backend/achievement/list', 'GET', 'V2')();
export const addAchievementAPI = payload =>
  request('/backend/achievement/', 'POST', 'V2')(payload);
export const setAchievementAPI = payload =>
  request(`/backend/achievement/${payload.id}`, 'POST', 'V2')(payload);
export const deleteAchievementAPI = id =>
  request(`/backend/achievement/${id}`, 'DELETE', 'V2')();
export const getAssignedAchievementListAPI = () =>
  request('/backend/achievement/issue/list', 'GET', 'V2')();
export const addAssignedAchievementAPI = payload =>
  request('/backend/achievement/issue/', 'POST', 'V2')(payload);
export const setAssignedAchievementAPI = payload =>
  request(`/backend/achievement/issue/${payload.id}`, 'POST', 'V2')(payload);
export const deleteAssignedAchievementAPI = id =>
  request(`/backend/achievement/issue/${id}`, 'DELETE', 'V2')();
export const getUserInfoAPI = id => request(`/backend/user/${id}`, 'GET')();
export const sendUserAPI = (id, body) =>
  request(`/backend/user/${id}`, 'POST')(body);
export const getRemoveReasonsAPI = type =>
  request(`/individual/report/${type}/reason`, 'GET')();
export const getAccountDeletionListAPI = payload =>
  request(`/backend/individual/remove/list${createQs(payload)}`, 'GET')();
export const getCoverageAPI = payload =>
  request('/backend/announcement/coverage', 'POST')(payload);
export const getTagListAPI = request('/tag/list', 'GET');
export const getTabListAPI = request('/individual/tabSetting', 'GET');
export const getSupportListAPI = payload =>
  request(`/support/support/list${createQs(payload)}`, 'GET')();
export const getSupportCategoryAPI = request('/support/supportCategory', 'GET');
export const getSupportAPI = id => request(`/support/support/${id}`, 'GET')();
export const setSupportAPI = payload =>
  request(`/support/support/${payload.id}`, 'POST')(payload);
export const getRevenueWithdrawListAPI = payload =>
  request(`/backend/streamer/revenue/withdraw${createQs(payload)}`, 'GET')();
export const getForbiddenListAPI = payload => {
  const { item, page } = payload;
  const qs = createQs({ item, page });
  return request(`/backend/forbidden/list${qs}`, 'GET')();
};
export const addForbiddenAPI = payload =>
  request('/backend/forbidden', 'POST')(payload);
export const updateForbiddenAPI = payload =>
  request('/backend/forbidden', 'PATCH')(payload);
export const deleteForbiddenAPI = id =>
  request(`/backend/forbidden/${id}`, 'DELETE')();
export const withdrawLiveMasterRevenueAPI = (userID, data) =>
  request(`/backend/streamer/revenue/withdraw/${userID}`, 'POST')(data);
export const withdrawAgencyRevenueAPI = payload =>
  request('/backend/streamer/revenue/withdraw', 'POST')(payload);
export const getLiveMasterRevenueAPI = ({
  liveMasterId,
  agencyIds,
  startTime,
  endTime,
  item,
  page
}) =>
  request(
    `/backend/streamer/revenue/summary?item=${item}&page=${page}`,
    'POST'
  )({ liveMasterId, agencyIds, startTime, endTime });
export const getReceiveGiftListAPI = ({
  liveMasterId,
  startTime,
  endTime,
  page,
  item
}) =>
  request(
    `/backend/streamer/receiveGift/detail?item=${item}&page=${page}`,
    'POST'
  )({ liveMasterId, startTime, endTime });
export const addMessageAPI = request('/backend/instantMessage', 'POST');
export const getVoiceChatTypeList = request(
  '/backend/voiceChat/type/list',
  'GET'
);
export const getPrivateVoiceChatList = request(
  '/backend/privateVoiceChat/list',
  'GET'
);
export const clearVoiceChatCacheAPI = id =>
  request(`/backend/voiceChat/${id}/cache`, 'DELETE')();
export const addVoiceChatType = payload =>
  request('/backend/voiceChat/type', 'POST')(payload);
export const setVoiceChatType = payload =>
  request(`/backend/voiceChat/type/${payload.id}`, 'PATCH')(payload);
export const kickoutVoiceChatUserAPI = (roomId, uuid) =>
  request(`/backend/voiceChat/${roomId}/identity/${uuid}`, 'DELETE')();
export const getPrivateVoiceChatRoom = id =>
  request(`/backend/privateVoiceChat/${id}`, 'GET')();
export const addPrivateVoiceChatAPI = request(
  '/backend/privateVoiceChat',
  'POST'
);
export const setPrivateVoiceChatAPI = payload =>
  request(`/backend/privateVoiceChat/${payload.id}`, 'PATCH')(payload);
export const getPrivateVoiceChatSessionAPI = payload =>
  request(
    `/backend/privateVoiceChat/session/list${createQs(payload)}`,
    'GET'
  )();
export const getPrivateVoiceChatLiveRecordingAPI = (id, payload = {}) =>
  request(
    `/backend/voiceChat/${id}/liveRecording${createQs(payload)}`,
    'GET'
  )();
export const getRoomListAPI = payload =>
  request(`/backend/instantMessage/roomList${createQs(payload)}`, 'GET')();
export const getDialogAPI = payload =>
  request(`/backend/instantMessage/history${createQs(payload)}`, 'GET')();
export const getAppRoomListAPI = payload =>
  request(`/individual/instantMessage/roomList${createQs(payload)}`, 'GET')();
export const getAppDialogAPI = payload =>
  request(`/individual/instantMessage/history${createQs(payload)}`, 'GET')();
export const getGiftListAPI = payload =>
  request(`/backend/gift/list${createQs(payload)}`, 'GET')();
export const addGiftAPI = payload => request('/backend/gift', 'POST')(payload);
export const setGiftAPI = payload =>
  request(`/backend/gift/${payload.id}`, 'PATCH')(payload);
export const deleteGiftAPI = id => request(`/backend/gift/${id}`, 'DELETE')();
export const getGiftCategoryListAPI = payload =>
  request(`/backend/giftCategory/list${createQs(payload)}`, 'GET')();
export const addGiftCategoryAPI = payload =>
  request('/backend/giftCategory', 'POST')(payload);
export const deleteGiftCategoryAPI = id =>
  request(`/backend/giftCategory/${id}`, 'DELETE')();
export const modifyRoleAPI = payload =>
  request('/backend/user/role', 'PATCH')(payload);
export const resetNicknameAPI = payload =>
  request('backend/deal/platform/card/nicknameReset/', 'POST')(payload);
export const getNicknameResetHistoryListAPI = payload =>
  request(`/backend/user/nicknameReset/history${createQs(payload)}`, 'GET')();
export const getLiveRoomListAPI = () =>
  request('/live/list/home/more', 'GET')();
export const getLiveRoomInfoAPI = roomId =>
  request(`/room/info/${roomId}`, 'GET')();
export const getLiveRoomRecordAPI = roomId =>
  request(`/backend/liveroom/${roomId}/liveRecording`, 'GET')();
export const getLiveMasterHoursAPI = payload =>
  request('/backend/streamer/dailyHoursReport', 'POST')(payload);
export const searchAPI = payload =>
  request(
    `/backend/user/search?item=${payload.item}&page=${payload.page}`,
    'POST'
  )(payload);
export const getChatBotSettingAPI = () =>
  request('/backend/chatBotSetting', 'GET')();
export const setChatBotSettingAPI = payload =>
  request('/backend/chatBotSetting', 'PUT')(payload);
export const getRemittanceListAPI = payload =>
  request(`/backend/remittance/list${createQs(payload)}`, 'GET')();
export const getRemittanceAPI = id =>
  request(`/backend/remittance/${id}`, 'GET')();
export const addRemittanceAPI = payload =>
  request('/backend/remittance', 'PUT')(payload);
export const setRemittanceAPI = payload =>
  request(`/backend/remittance/${payload.id}`, 'PUT')(payload);
export const allowRemittanceAPI = payload =>
  request('/backend/remittance/approve', 'POST')(payload);
export const invoiceAPI = id =>
  request(`/backend/remittance/ecpay/${id}/invoice`, 'POST')();
export const getTransactionListAPI = ({ id, startTime, endTime, item, page }) =>
  request(
    `/backend/user/transactionList/${id}${createQs({
      startTime,
      endTime,
      item,
      page
    })}`,
    'GET'
  )();
export const getUsageListAPI = ({ id, startTime, endTime, item, page }) =>
  request(
    `/backend/user/usageList/${id}${createQs({
      startTime,
      endTime,
      item,
      page
    })}`,
    'GET'
  )();
export const getAdBannerListAPI = payload =>
  request(`/backend/adBanner/list${createQs(payload)}`, 'GET')();
export const addAdBannerAPI = payload =>
  request('/backend/adBanner', 'POST')(payload);
export const setAdBannerAPI = payload =>
  request(`/backend/adBanner/${payload.id}`, 'PATCH')(payload);
export const deleteAdBannerAPI = id =>
  request(`/backend/adBanner/${id}`, 'DELETE')();
export const getProductList = () =>
  request('/productInfo/offline/enableList', 'GET')();

/** backend tab & tag */
// tab
export const updateBackendTabListAPI = payload =>
  request('/backend/tabSetting', 'POST')(payload);

// tag group
export const getBackendTagGroupListAPI = payload => {
  const { item, page, typeFilter } = payload;
  const qs = createQs({ item, page, typeFilter });
  return request(`/backend/tagGroup/list${qs}`, 'GET')();
};
export const createBackendTagGroupAPI = payload =>
  request('/backend/tagGroup', 'POST')(payload);
export const updateBackendTagGroupAPI = payload => {
  const { id, label, type } = payload;
  return request(`/backend/tagGroup/${id}`, 'PATCH')({ label, type });
};
export const deleteBackendTagGroupAPI = id =>
  request(`/backend/tagGroup/${id}`, 'DELETE')();

// tag
export const getBackendTagListAPI = payload => {
  const { groupFilter, item, page, typeFilter } = payload;
  const qs = createQs({ groupFilter, item, page, typeFilter });
  return request(`/backend/tag/list${qs}`, 'GET')();
};
export const createBackendTagAPI = payload =>
  request('/backend/tag', 'POST')(payload);
export const deleteBackendTagAPI = id =>
  request(`/backend/tag/${id}`, 'DELETE')();
export const updateBackendTagAPI = payload => {
  const { id, label, color, type, groupId } = payload;
  return request(
    `/backend/tag/${id}`,
    'PATCH'
  )({ label, color, type, groupId });
};
export const getFlashNewsListAPI = () =>
  request('/backend/module/flashNews/list', 'GET')();
export const addFlashNewsAPI = payload =>
  request('/backend/module/flashNews', 'POST')(payload);
export const setFlashNewsAPI = payload =>
  request(`/backend/module/flashNews/${payload.id}`, 'PATCH')(payload);
export const deleteFlashNewsAPI = id =>
  request(`/backend/module/flashNews/${id}`, 'DELETE')();
export const getVideoListAPI = () =>
  request('/backend/module/video/list/more', 'GET')();
export const addVideoAPI = payload =>
  request('/backend/module/video', 'POST')(payload);
export const setVideoAPI = payload =>
  request(`/backend/module/video/${payload.id}`, 'PATCH')(payload);
export const deleteVideoAPI = id =>
  request(`/backend/module/video/${id}`, 'DELETE')();
export const getSeriesListAPI = () => request('/module/series/list', 'GET')();
export const setSeriesListAPI = payload =>
  request('/backend/module/series', 'PUT')(payload);
export const getSpotlightListAPI = () =>
  request('/backend/module/spotlight/list', 'GET')();
export const addSpotlightAPI = payload =>
  request('/backend/module/spotlight', 'POST')(payload);
export const setSpotlightAPI = payload =>
  request(`/backend/module/spotlight/${payload.id}`, 'PATCH')(payload);
export const deleteSpotlightAPI = id =>
  request(`/backend/module/spotlight/${id}`, 'DELETE')();
// PastEvent
export const getPastEventListAPI = () =>
  request('/backend/module/pastEvent/list', 'GET')();
export const addPastEventAPI = payload =>
  request('/backend/module/pastEvent', 'POST')(payload);
export const setPastEventAPI = payload =>
  request(`/backend/module/pastEvent/${payload.id}`, 'PUT')(payload);
export const deletePastEventAPI = id =>
  request(`/backend/module/pastEvent/${id}`, 'DELETE')();
export const getPointTransferListAPI = payload =>
  request(`/backend/pointTransfer/list${createQs(payload)}`, 'GET')();
export const addPointTransferAPI = payload =>
  request('/backend/pointTransfer', 'POST')(payload);
export const editUserPointAPI = payload =>
  request('/backend/individual/points', 'PUT')(payload);
//report
export const getPurchaseOrderListApi = payload =>
  request(
    `/backend/purchaseOrder/list${createQs({
      page: payload.page,
      item: payload.item
    })}`,
    'POST'
  )(payload);
export const addUserTagAPI = ({ uuid, tags }) =>
  request(`/individual/${uuid}/tags`, 'POST')({ tags });
export const getAppConfigAPI = () => request('/module/appConfig', 'GET')();
export const setAppConfigAPI = payload =>
  request('/backend/module/appConfig', 'PUT')(payload);
//remittance-product
export const getProductInfoListAPI = platform =>
  request(`/backend/productInfo/${platform}/list`, 'GET')();
export const addProductInfoAPI = payload =>
  request('/backend/productInfo', 'POST')(payload);
export const setProductInfoAPI = payload =>
  request(`/backend/productInfo/${payload.id}`, 'PUT')(payload);
export const deleteProductInfoAPI = id =>
  request(`/backend/productInfo/${id}`, 'DELETE')();
export const getYoutubeDetailByIdAPI = payload =>
  ajax.getJSON(
    `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&key=${process.env.REACT_APP_YOUTUBE_KEY}&id=${payload}`
  );
// Blocklist
export const getBlocklistListAPI = () =>
  request('/backend/billboard/blockList/list', 'GET')();
export const addBlocklistAPI = payload =>
  request('/backend/billboard/blockList', 'POST')(payload);
export const setBlocklistAPI = payload =>
  request(`/backend/billboard/blockList/${payload.id}`, 'PATCH')(payload);
export const deleteBlocklistAPI = id =>
  request(`/backend/billboard/blockList/${id}`, 'DELETE')();
// greeting
export const getGreetingListAPI = payload =>
  request(`/backend/greeting/list${createQs(payload)}`, 'GET')();
export const setGreetingAPI = payload =>
  request('/backend/greeting', 'PUT')(payload);
export const deleteGreetingAPI = id =>
  request(`/backend/greeting/${id}`, 'DELETE')();
export const getVoiceChatRoomRankingAPI = payload =>
  request(`/backend/billboard/room/voiceChat${createQs(payload)}`, 'GET')();
export const getAccountRightListAPI = () =>
  ajax({
    url: `https://docs.google.com/spreadsheets/d/e/${process.env.REACT_APP_ACCOUNT_SHEET}/pub?output=csv`,
    method: 'GET',
    responseType: 'text'
  });
// Effect
// GET 取得特效列表
export const getEffectListAPI = payload =>
  request(`/backend/accomplishment/list${createQs(payload)}`, 'GET')();
// GET 取得單一特效
export const getEffectAPI = id =>
  request(`/backend/accomplishment/${id}`, 'GET')();
// POST 新增特效
export const addEffectAPI = payload =>
  request('/backend/accomplishment/create', 'POST')(payload);
// PATCH 編輯特效
export const setEffectAPI = payload =>
  request(`/backend/accomplishment/${payload.id}`, 'PATCH')(payload);
// DELETE 刪除特效
export const deleteEffectAPI = id =>
  request(`/backend/accomplishment/${id}`, 'DELETE')();
// Dispatch
// GET 取得發送特效設定列表
export const getDispatchListAPI = payload =>
  request(`/backend/accomplishment/send/list${createQs(payload)}`, 'GET')();
// GET 取得發送特效「人員」列表
export const getDispatchUsersAPI = ({ id, ...payload }) =>
  request(
    `/backend/accomplishment/send/${id}/users${createQs(payload)}`,
    'GET'
  )();
// DELETE 收回「人員(們)」發送特效
export const withdrawDispatchUserAPI = (id, uuid) =>
  request(`/backend/accomplishment/send/${id}/user/${uuid}`, 'DELETE')();
// GET 取得「單一」特效發送設定
export const getDispatchAPI = payload =>
  request(`/backend/accomplishment/send/${payload}`, 'GET')();
// POST 新增特效發送設定
export const addDispatchAPI = payload =>
  request('/backend/accomplishment/send', 'POST')(payload);
// PATCH 更新發送特效設定
export const setDispatchAPI = payload =>
  request(`/backend/accomplishment/${payload.id}/send`, 'PATCH')(payload);
// DELETE 收回(刪除)發送特效
export const deleteDispatchAPI = id =>
  request(`/backend/accomplishment/send/${id}`, 'DELETE')();
// GET 取得用戶背包內容
export const getUserBackpackListAPI = ({ id, ...payload }) =>
  request(`/backend/user/backpack/${id}${createQs(payload)}`, 'GET')();
//privateVoiceChat
// POST 後台新增 私密聲聊聚光燈
export const addPrivateVoiceChatSpotlightAPI = payload =>
  request('/backend/privateVoiceChat/spotlight', 'POST')(payload);
// GET 後台取得 私密聲聊聚光燈 列表
export const getPrivateVoiceChatSpotlightListAPI = () =>
  request('/backend/privateVoiceChat/spotlight/list', 'GET')();
// GET 後台取得指定 私密聲聊聚光燈
export const getPrivateVoiceChatSpotlightAPI = id =>
  request(`/backend/privateVoiceChat/spotlight/${id}`, 'GET')();
// PATCH 後台編輯 私密聲聊聚光燈
export const setPrivateVoiceChatSpotlightAPI = payload =>
  request(
    `/backend/privateVoiceChat/spotlight/${payload.id}`,
    'PATCH'
  )(payload);
// DELETE 後台刪除 私密聲聊聚光燈
export const deletePrivateVoiceChatSpotlightAPI = id =>
  request(`/backend/privateVoiceChat/spotlight/${id}`, 'DELETE')();

// 底部頁籤
// GET 取得底部頁籤列表
export const getBottomTabBarListAPI = () =>
  request('/backend/bottomTabBar/list', 'GET')();
// POST 新增底部頁籤
export const addBottomTabBarAPI = payload =>
  request('/backend/bottomTabBar', 'POST')(payload);
// GET 取得單一底部頁籤
export const getBottomTabBarAPI = id =>
  request(`/backend/bottomTabBar/${id}`, 'GET')();
// PATCH 編輯底部頁籤
export const setBottomTabBarAPI = payload =>
  request(`/backend/bottomTabBar/${payload.id}`, 'PATCH')(payload);
// DELETE 刪除底部頁籤
export const deleteBottomTabBarAPI = id =>
  request(`/backend/bottomTabBar/${id}`, 'DELETE')();
// 分潤方案
//GET 搜尋分潤方案名稱
export const getContractListAPI = payload =>
  request(`/backend/contractTemplate/list${createQs(payload)}`, 'GET')();
//GET 取得「單一」分潤方案
export const getContractAPI = id =>
  request(`/backend/contractTemplate/${id}`, 'GET')();
//POST 新增分潤方案
export const addContractAPI = payload =>
  request('/backend/contractTemplate/create', 'POST')(payload);
//PATCH 編輯分潤方案
export const setContractAPI = ({ id, ...payload }) =>
  request(`/backend/contractTemplate/${id}`, 'PATCH')(payload);
//DELETE 封存分潤方案
export const deleteContractAPI = id =>
  request(`/backend/contractTemplate/${id}`, 'DELETE')();

// 經紀公司
// GET 搜尋經紀公司
export const getAgencyListAPI = payload =>
  request(`/backend/agency/list${createQs(payload)}`, 'GET')();
// GET 取得「單一」經紀公司
export const getAgencyAPI = id => request(`/backend/agency/${id}`, 'GET')();
// POST 新增經紀公司
export const addAgencyAPI = payload =>
  request('/backend/agency/create', 'POST')(payload);
// PATCH 編輯經紀公司
export const setAgencyAPI = ({ id, ...payload }) =>
  request(`/backend/agency/${id}`, 'PATCH')(payload);
// DELETE 封存經紀公司
export const deleteAgencyAPI = id =>
  request(`/backend/agency/${id}`, 'DELETE')();

// 經紀公司直播主管理
// GET 搜尋經紀公司直播主
export const getAgencyMasterListAPI = ({ agencyId, ...payload }) =>
  request(
    `/backend/agency/${agencyId}/masterContract/list${createQs(payload)}`,
    'GET'
  )();
// GET 取得「單一」經紀公司直播主
export const getAgencyMasterAPI = id =>
  request(`/backend/masterContract/${id}`, 'GET')();
// POST 新增經紀公司直播主
export const addAgencyMasterAPI = payload =>
  request('/backend/masterContract/create', 'POST')(payload);
// POST 「合約更換」經紀公司直播主
export const replaceAgencyMasterContractAPI = payload =>
  request('/backend/masterContract/replace', 'POST')(payload);
// PATCH 「編輯」經紀公司直播主
export const setAgencyMasterAPI = ({ agencyMasterContractId, ...payload }) =>
  request(
    `/backend/masterContract/${agencyMasterContractId}`,
    'PATCH'
  )(payload);
// DELETE 「終止合約」經紀公司直播主
export const deleteAgencyMasterAPI = payload =>
  request('/backend/masterContract/terminate', 'POST')(payload);

// 隨機禮物
// 取得『單一』隨機禮物清單
export const getGiftRandomAPI = id =>
  request(`/backend/giftRandom/${id}`, 'GET')();
// POST 新增隨機禮物清單
export const addGiftRandomAPI = payload =>
  request('/backend/giftRandom/create', 'POST')(payload);
// PUT 修改隨機禮物資訊
export const setGiftRandomAPI = payload =>
  request(`/backend/giftRandom/${payload.id}`, 'PUT')(payload);

// POST 後台新增浮動版位
export const addFloatingSlotAPI = payload =>
  request('/backend/floatingAd', 'POST')(payload);
// GET 後台取得浮動版位列表
export const getFloatingSlotListAPI = payload =>
  request(`/backend/floatingAd/list${createQs(payload)}`, 'GET')();
// GET 後台取得單一浮動版位
export const getFloatingSlotAPI = id =>
  request(`/backend/floatingAd/${id}`, 'GET')();
// PATCH 後台編輯浮動版位
export const setFloatingSlotAPI = payload =>
  request(`/backend/floatingAd/${payload.id}`, 'PATCH')(payload);
// DELETE 後台刪除浮動版位
export const deleteFloatingSlotAPI = id =>
  request(`/backend/floatingAd/${id}`, 'DELETE')();

// 活動簽到
// GET 取得活動簽到列表
export const getSigninEventListAPI = payload =>
  request(`/backend/signinEvent/list${createQs(payload)}`, 'GET')();
// GET 取得單ㄧ活動簽到
export const getSigninEventAPI = id =>
  request(`/backend/signinEvent/${id}`, 'GET')();
// POST 新增活動簽到
export const addSigninEventAPI = payload =>
  request('/backend/signinEvent/create', 'POST')(payload);
// PATCH 編輯活動簽到資料
export const setSigninEventAPI = payload =>
  request(`/backend/signinEvent/${payload.id}`, 'PATCH')(payload);
// DELETE 刪除活動簽到
export const deleteSigninEventAPI = id =>
  request(`/backend/signinEvent/${id}`, 'DELETE')();
// GET 取得預設簽到列表
export const getContinuityEventListAPI = () =>
  request('/backend/continuityEvent/list', 'GET')();

// 榜單查詢
// GET 後台撈取直播主排行榜
export const getLiveMasterRankingAPI = ({ page, item, ...payload }) =>
  request(
    `/backend/billboard/streamer${createQs({ page, item })}`,
    'POST'
  )(payload);
// GET 後台撈取乾爹排行榜
export const getLiveGiftGiverRankingAPI = ({ page, item, ...payload }) =>
  request(
    `/backend/billboard/giftGiver${createQs({ page, item })}`,
    'POST'
  )(payload);
