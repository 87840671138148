import { head, dissoc, compose } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Popconfirm, Table } from 'antd';
import {
  getVideoList,
  addVideo,
  setVideo,
  deleteVideo,
  showModal,
} from '../../reducers/videoManagement';
import antdTablePagination from '../../utils/antdTablePagination';
import { PageContainer, PageTitle } from '../../components/styled/page';
import VideoFormModal from './VideoFormModal';

const Tools = styled.header`
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
  }
`;

const getVideo = media => {
  if (media) {
    return typeof media === 'string' ? media.trim() : media.videoUrl;
  } else {
    return undefined;
  }
};

const getDuration = media => {
  if (media) {
    return media.duration ? media.duration : 0;
  } else {
    return undefined;
  }
};

const getSubmitData = compose(dissoc('media'), value => ({
  ...value,
  url: getVideo(value.media),
  thumbnailUrl: value.thumbnailUrl ? value.thumbnailUrl : undefined,
  length: getDuration(value.media)
}));

const columns = [
  { title: '標題', dataIndex: 'title' },
  { title: '副標題', dataIndex: 'description' },
  {
    width: 304,
    title: '預覽圖片',
    dataIndex: 'thumbnailUrl',
    render: value => (
      <img src={value} alt="Preview" style={{ width: 280, height: 'auto' }} />
    )
  }
];

class VideoManagement extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isCreateMode: false,
      editData: undefined,
      selectedRow: undefined,
      selectedRowKeys: [],
      defaultPagination: new antdTablePagination(),
    };
  }

  componentDidMount() {
    this.props.getVideoList();
  }

  onSelectRowChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRow: head(selectedRows),
      selectedRowKeys
    });
  };

  onAddClick = () => {
    this.setState({ editData: null, isCreateMode: true });
    this.props.showModal();
  };

  onEditClick = () => {
    this.setState(({ selectedRow }) => ({
      editData: selectedRow,
      isCreateMode: false
    }));
    this.props.showModal();
  };

  onFormSubmit = (values) => {
    const { addVideo, setVideo } = this.props;
    const { isCreateMode } = this.state;
    if (isCreateMode) {
      addVideo(getSubmitData(values));
    } else {
      setVideo(getSubmitData(values));
    }
  };

  onFormCancel = () => {
    this.setState({ editData: null });
    this.props.showModal(false);
  };

  render() {
    const { data, loading, deleteVideo, isShowModal, totalCount } = this.props;
    const { editData, selectedRowKeys, isCreateMode, defaultPagination } = this.state;
    const pagination = { ...defaultPagination, total: totalCount };
    return (
      <PageContainer>
        <PageTitle>{'首頁管理 > 精彩影音'}</PageTitle>
        <Tools>
          <Button
            type="primary"
            onClick={this.onAddClick}
          >
            <span>新增</span>
          </Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onEditClick}
          >
            <span>編輯</span>
          </Button>
          <Popconfirm
            disabled={selectedRowKeys.length === 0}
            key={'cancel'}
            title="是否確定刪除?"
            placement="right"
            onConfirm={() => {
              deleteVideo(selectedRowKeys);
              this.setState({ selectedRowKeys: [] });
            }}
          >
            <Button
              type="danger"
              disabled={selectedRowKeys.length === 0}
            >
              <span>刪除</span>
            </Button>
          </Popconfirm>
        </Tools>
        <Table
          rowKey="id"
          scroll={{ x: true }}
          loading={loading}
          rowSelection={{
            selectedRowKeys,
            onChange: this.onSelectRowChange
          }}
          pagination={pagination}
          columns={columns}
          dataSource={data}
        />
        <VideoFormModal
          isCreateMode={isCreateMode}
          visible={isShowModal}
          editData={editData}
          onCancel={this.onFormCancel}
          onOk={this.onFormSubmit}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => state.videoManagement;

const mapDispatchToProps = {
  getVideoList,
  addVideo,
  setVideo,
  deleteVideo,
  showModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoManagement);
