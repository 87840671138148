import React from 'react';
import PropTypes from 'prop-types';
import Pickr from '@simonwep/pickr/dist/pickr.es5.min';
import '@simonwep/pickr/dist/themes/monolith.min.css';

class ColorPicker extends React.Component {

  static propTypes = {
    onSave: PropTypes.func,
    defaultValue: PropTypes.string,
  }

  static defaultProps = {
    defaultValue: '#000000',
  }

  constructor(props) {
    super(props);

    this.pickerRef = React.createRef();

    this.state = {
      picker: null,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    const { picker } = this.state;
    if (picker) picker.destroy();
  }

  show = () => {
    const { picker } = this.state;
    picker.show();
  }

  init = () => {
    const { defaultValue } = this.props;
    const picker = Pickr.create({
      el: this.pickerRef.current,
      theme: 'monolith',
      position: 'right-middle',
      default: defaultValue,
      components: {
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          input: true,
          save: true
        },
      },
      i18n: {
        'btn:save': '確定',
      },
    });

    const { onSave } = this.props;
    if (onSave) picker.on('save', onSave);

    this.setState({ picker });
  }

  render() {
    return (
      <div ref={this.pickerRef}></div>
    );
  }
}

export default ColorPicker;
