import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import downloadCSV from '../utils/downloadCSV';
import { connect } from 'react-redux';
import { DatePicker, Table, Button } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import * as actions from '../actions';

const { RangePicker } = DatePicker;

const Toolbar = styled.div`
  display: flex;
  margin-bottom: 8px;
  padding-right: 8px;

  > .search-button {
    margin-left: 8px;
  }

  > .export-button {
    margin-left: auto;
  }
`;

const mapStateToProps = state => ({
  dataMap: state.contentManagement.dataMap,
  isLoading: state.contentManagement.isLoading
});

const mapDispatchToProps = {
  loadDauReports: actions.loadDauReports
};

@connect(mapStateToProps, mapDispatchToProps)
export default class DAU extends Component {
  static propTypes = {
    dataMap: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadDauReports: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(Date.now()).subtract(7, 'days'),
      endDate: moment(Date.now())
    };
  }

  componentDidMount() {
    this.loadReportsBetweenDateRange();
  }

  formatDataMapToTableData = dataMap => {
    const dataEntries = Object.entries(dataMap);
    return dataEntries.map(entry => ({
      dateLabel: entry[0],
      repeatDAU: entry[1].uu,
      nonRepeatDAU: entry[1].nuu
    }));
  };

  loadReportsBetweenDateRange = () => {
    const { startDate, endDate } = this.state;
    const { loadDauReports } = this.props;
    loadDauReports({
      startDate: startDate.valueOf(),
      endDate: endDate.valueOf()
    });
  };

  getBlobCsvUrlFromJson = () => {
    const { dataMap } = this.props;
    const jsonData = this.formatDataMapToTableData(dataMap);
    return downloadCSV(
      jsonData,
      ['dateLabel', 'repeatDAU', 'nonRepeatDAU'],
      ['日期', '重複DAU', '不重複DAU']
    );
  };

  handleRangeTimeChange = date => {
    this.setState({
      startDate: date[0],
      endDate: date[1]
    });
  };

  handleSearchReport = () => {
    this.loadReportsBetweenDateRange();
  };

  render() {
    const { startDate, endDate } = this.state;
    const { dataMap, isLoading } = this.props;
    const tableData = this.formatDataMapToTableData(dataMap);
    const columns = [
      {
        title: '日期',
        dataIndex: 'dateLabel'
      },
      {
        title: '重複DAU',
        dataIndex: 'repeatDAU'
      },
      {
        title: '不重複DAU',
        dataIndex: 'nonRepeatDAU'
      }
    ];

    return (
      <div>
        <h1>{'經營管理 > DAU'}</h1>
        <Toolbar>
          <RangePicker
            style={{ width: '23rem', margin: '0 10px' }}
            format="YYYY/MM/DD HH:mm:ss"
            showTime={true}
            value={[startDate, endDate]}
            onChange={this.handleRangeTimeChange}
          />
          <Button
            style={{ marginRight: 10 }}
            className="search-button"
            onClick={this.handleSearchReport}
          >
            <LegacyIcon type="search" />
            <span>查詢</span>
          </Button>
          <Button type="primary" onClick={this.getBlobCsvUrlFromJson}>
            匯出 DAU 報表
          </Button>
        </Toolbar>
        <Table
          rowKey="dateLabel"
          dataSource={tableData}
          columns={columns}
          locale={{ emptyText: '沒有資料。' }}
          loading={isLoading}
        />
      </div>
    );
  }
}
