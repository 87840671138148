import { Table } from 'antd';
import { indexBy, isNil, prop } from 'ramda';
import React, { useMemo } from 'react';
import LightBox from '../../components/LightBox';
import PreviewModal from './PreviewModal';
import { typeList, debutLevel, sfxSubTypeList } from '../../constants/effect';
import { isReallyEmpty } from '../../utils/mixin';

const typeById = indexBy(prop('id'), typeList);
const sfxSubTypeById = indexBy(prop('id'), sfxSubTypeList);
const renderImg = value => (
  <img alt="preview" src={value} style={{ width: '100%' }} />
);

const columns = [
  { title: '編號', dataIndex: 'id' },
  { title: '名稱', dataIndex: 'name' },
  {
    title: '類型',
    dataIndex: 'type',
    render: value => typeById[value] && typeById[value].label
  },
  {
    title: '頭銜',
    dataIndex: 'badgeUrl',
    render: (value, record) => (
      <PreviewModal
        title="頭銜"
        disabled={isNil(value)}
        dataSource={record}
        columns={[
          { title: '頭銜', dataIndex: 'badgeUrl' },
          {
            title: '頭銜-縮圖',
            dataIndex: 'badgePreviewUrl',
            render: renderImg
          }
        ]}
      />
    )
  },
  {
    title: '徽章',
    dataIndex: 'badgeSimpleUrl',
    render: (value, record) => (
      <PreviewModal
        title="徽章"
        disabled={isNil(value)}
        dataSource={record}
        columns={[
          { title: '徽章', dataIndex: 'badgeSimpleUrl' },
          {
            title: '徽章-縮圖',
            dataIndex: 'badgeSimplePreviewUrl',
            render: renderImg
          }
        ]}
      />
    )
  },
  {
    title: '進場特效',
    dataIndex: 'debutUrl',
    render: (value, record) => (
      <PreviewModal
        title="進場特效"
        disabled={isNil(value)}
        dataSource={record}
        columns={[
          { title: '進場特效', dataIndex: 'debutUrl' },
          {
            title: '進場特效-縮圖',
            dataIndex: 'debutPreviewUrl',
            render: renderImg
          }
        ]}
      />
    )
  },
  {
    title: '頭像框',
    dataIndex: 'frameUrl',
    render: (value, record) => (
      <PreviewModal
        title="頭像框"
        disabled={isNil(value)}
        dataSource={record}
        columns={[
          { title: '頭像框', dataIndex: 'frameUrl' },
          {
            title: '頭像框-縮圖',
            dataIndex: 'framePreviewUrl',
            render: renderImg
          }
        ]}
      />
    )
  },
  {
    title: '進場特效等級',
    dataIndex: 'debutDuration',
    render: value => debutLevel[value]
  },
  {
    title: '留言框',
    dataIndex: 'bubbleUrl',
    render: (value, record) => (
      <PreviewModal
        disabled={isReallyEmpty(value)}
        title="留言框"
        dataSource={record}
        columns={[
          { title: '留言框', dataIndex: 'bubbleUrl' },
          { title: '留言框-縮圖', dataIndex: 'bubblePreviewUrl' }
        ]}
      />
    )
  },
  {
    title: '濾鏡動效',
    dataIndex: 'sfxPreviewUrl',
    render: value => <LightBox src={value} />
  },
  {
    title: '動效分類',
    dataIndex: 'subType',
    render: value => sfxSubTypeById[value].label
  }
];

const DispatchTable = ({
  loading,
  data,
  selectedRowKeys,
  pageSize,
  currentPage,
  total,
  onRowSelectionChange,
  onPageChange
}) => {
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: onRowSelectionChange
    }),
    [selectedRowKeys, onRowSelectionChange]
  );
  const pagination = useMemo(
    () => ({
      pageSize,
      current: currentPage,
      total,
      onChange: onPageChange,
      showSizeChanger: false
    }),
    [pageSize, currentPage, total, onPageChange]
  );
  return (
    <Table
      rowKey="id"
      scroll={{ x: true }}
      loading={loading}
      rowSelection={rowSelection}
      pagination={pagination}
      columns={columns}
      dataSource={data}
    ></Table>
  );
};
export default DispatchTable;
