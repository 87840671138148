import { evolve, pickAll, pipe, trim } from 'ramda';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal } from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class CreateAccountInfoModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
    };
  }

  onSubmit = () => {
    const form = this.formRef.current;
    const iPromise = form.validateFields();
    iPromise.then((values) => {
      const { onSubmit, onClose } = this.props;
      const result = pipe(
        evolve({
          accountNo: trim,
          bankName: trim,
          branchBank: trim,
          branchNo: trim,
        })
      )(values);
      onSubmit(result);
      onClose();
    });
  };

  renderFooter = () => {
    return (
      <Button
        type="primary"
        htmlType="submit"
        onClick={this.onSubmit}
      >
        <span>確定</span>
      </Button>
    );
  };

  render() {
    const { modalTitle, visible, onClose, account } = this.props;
    const {
      accountNo: currentAccountNo,
      bankName: currentBankName,
      branchBank: currentBranchBank,
      branchNo: currentBranchNo,
    } = pickAll(
      ['accountNo', 'bankName', 'branchBank', 'branchNo'],
      account || {}
    );

    return (
      <Modal
        title={modalTitle}
        visible={visible}
        onCancel={onClose}
        footer={this.renderFooter()}
      >
        <Form ref={this.formRef}>
          <FormItem
            label="銀行帳戶"
            name="accountNo"
            initialValue={currentAccountNo}
            rules={[
              { required: true, message: '請輸入銀行帳戶' },
            ]}
            hasFeedback={true}
            {...formItemLayout}
          >
            <Input />
          </FormItem>
          <FormItem
            label="銀行名稱"
            name="bankName"
            initialValue={currentBankName}
            rules={[
              { required: true, message: '請輸入銀行名稱' },
            ]}
            hasFeedback={true}
            {...formItemLayout}
          >
            <Input />
          </FormItem>
          <FormItem
            label="分行"
            name="branchBank"
            initialValue={currentBranchBank}
            rules={[
              { required: true, message: '請輸入分行' },
            ]}
            hasFeedback={true}
            {...formItemLayout}
          >
            <Input />
          </FormItem>
          <FormItem
            label="銀行代碼"
            name="branchNo"
            initialValue={currentBranchNo}
            rules={[
              { required: true, message: '請輸入銀行代碼' },
            ]}
            hasFeedback={true}
            {...formItemLayout}
          >
            <Input />
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default CreateAccountInfoModal;
