import { ZegoExpressEngine } from 'zego-express-engine-webrtc';
import store from '../../store';
import getZeGouToken from './token';

const appID = process.env.REACT_APP_ZEGO_APPID;
const server = process.env.REACT_APP_ZEGO_SOCKET;
// const logUrl = 'wss://weblogger-hk-test.zegocloud.com/log';

const isProduction = process.env.NODE_ENV === 'production';

function ZegoService() {
  // init zego
  let zego = new ZegoExpressEngine(appID, server);
  let room = null;

  // set config
  const logLevel = isProduction ? 'error' : 'disable';
  zego.setLogConfig({ logLevel, remoteLogLevel: logLevel });
  zego.setDebugVerbose(false);

  function loginRoom(roomID) {
    const states = store.getState();
    const { auth } = states;
    const { id, nickname } = auth;

    const token = getZeGouToken(appID, id);
    const loginPromise = zego.loginRoom(roomID, token, {
      userID: id,
      userName: nickname,
    });
    loginPromise.then(result => {
      if (result) room = roomID;
    });
    return loginPromise;
  }

  function logoutRoom() {
    if (room) zego.logoutRoom(room);
    room = null;
  }

  function destory() {
    logoutRoom();
    zego = null;
  }

  Object.defineProperty(this, 'loginRoom', {
    enumerable: true,
    value: loginRoom,
  });

  Object.defineProperty(this, 'logoutRoom', {
    enumerable: true,
    value: logoutRoom,
  });

  Object.defineProperty(this, 'on', {
    enumerable: true,
    value: zego.on.bind(zego),
  });

  Object.defineProperty(this, 'off', {
    enumerable: true,
    value: zego.off.bind(zego),
  });

  Object.defineProperty(this, 'destory', {
    enumerable: true,
    value: destory,
  });
}

export default ZegoService; 
