import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Space,
  Table,
  Tag
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer, PageTitle } from '../../components/styled/page';
import brandName from '../../constants/brandName';
import profileThumbnailPicture from '../../constants/profileThumbnailPicture';
import {
  addPointTransfer,
  downloadPointTransferWithoutRatio,
  getPointTransferList,
  showModal
} from '../../reducers/pointTransfer';
import { getUserInfo } from '../../reducers/userManagement';
import PointTradingModal from './PointTradingModal';
const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;

const getColumns = uuid => [
  {
    title: '轉點時間',
    dataIndex: 'createdAt',
    render: value => moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
  },
  {
    title: '轉點對象',
    dataIndex: 'receiverLoginId',
    render: (receiverLoginId, record) => (
      <>
        <p>暱稱：{record.receiverNickname}</p>
        <p>Login ID：{receiverLoginId}</p>
        <div>
          {brandName} ID：{record.receiverXtarsId}
        </div>
      </>
    )
  },
  {
    title: '轉入/轉出',
    render: (__, record) =>
      record.receiverId === uuid ? (
        <Tag color="green">轉入</Tag>
      ) : (
        <Tag color="red">轉出</Tag>
      )
  },
  {
    title: '點數',
    dataIndex: 'point',
    render: (value, record) =>
      record.receiverId === uuid ? (
        <span style={{ color: '#52c41a' }}>{value.toLocaleString()}</span>
      ) : (
        <span style={{ color: '#f5222d' }}>{value.toLocaleString()}</span>
      )
  },
  {
    title: '操作者',
    dataIndex: 'createNickname'
  },
  {
    title: '備註',
    dataIndex: 'remark'
  },
  {
    title: '圖片',
    dataIndex: 'image',
    render: value => (
      <Space>
        {value.map(v => (
          <Image key={v} width={50} src={v} />
        ))}
      </Space>
    )
  }
];
const item = 20;
const PointTradingManagement = () => {
  const dispatch = useDispatch();
  const {
    data,
    loading,
    totalCount,
    uuid,
    remainPoints,
    profilePicture,
    nickname,
    isShowModal
  } = useSelector(state => ({
    data: state.pointTransfer.data,
    loading: state.pointTransfer.loading,
    isShowModal: state.pointTransfer.isShowModal,
    totalCount: state.pointTransfer.totalCount,
    uuid: state.auth.id,
    remainPoints: state.userManagement.formValue.remainPoints,
    nickname: state.userManagement.formValue.nickname,
    profilePicture: state.userManagement.formValue.profilePicture
  }));

  const [dateTime, setDateTime] = useState(() => [
    moment().startOf('day').subtract(7, 'DAY'),
    moment().endOf('day')
  ]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getUserInfo(uuid));
  }, [dispatch, uuid]);

  useEffect(() => {
    dispatch(
      getPointTransferList({
        userFilter: uuid,
        startTime: dateTime[0].unix(),
        endTime: dateTime[1].unix(),
        item,
        page
      })
    );
  }, [dispatch, dateTime, page, uuid]);

  const onAddPointTradingClick = () => {
    dispatch(showModal(true));
  };

  const onSubmit = values => {
    dispatch(
      addPointTransfer(values, getUserInfo(uuid), () => {
        if (page === 1) {
          dispatch(
            getPointTransferList({
              userFilter: uuid,
              startTime: dateTime[0].unix(),
              endTime: dateTime[1].unix(),
              page,
              item
            })
          );
        } else {
          setPage(1);
        }
      })
    );
  };

  const onModalCancel = () => {
    dispatch(showModal(false));
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onConfirmDate = dates => {
    setDateTime(dates);
  };

  const onDownloadClick = () => {
    dispatch(
      downloadPointTransferWithoutRatio({
        userFilter: uuid,
        startTime: dateTime[0].unix(),
        endTime: dateTime[1].unix(),
        page: 1,
        item: totalCount
      })
    );
  };

  const pagination = {
    showSizeChanger: false,
    pageSize: 20,
    current: page,
    total: totalCount,
    onChange: onPageChange
  };

  return (
    <PageContainer>
      <PageTitle>{'帳務管理 > 業務轉點'}</PageTitle>
      <Row gutter={24}>
        <Col flex="120px">
          <FormItem>
            <Avatar
              size={96}
              style={{ marginBottom: 8 }}
              src={profilePicture || profileThumbnailPicture}
            />
          </FormItem>
        </Col>
        <Col flex="auto" style={{ maxWidth: '1400px' }}>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem label="暱稱">
                <Input disabled value={nickname} style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="點數">
                <Input.Group compact>
                  <Input
                    style={{
                      width: 'calc(100% - 200px)',
                      maxWidth: '240px'
                    }}
                    disabled
                    value={remainPoints?.toLocaleString()}
                  />
                  <Button type="primary" onClick={onAddPointTradingClick}>
                    轉移點數
                  </Button>
                </Input.Group>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <FormItem label="日期區間">
                <RangePicker
                  onOk={onConfirmDate}
                  allowClear={false}
                  style={{ width: '400px' }}
                  defaultValue={dateTime}
                  showTime={{
                    defaultValue: [
                      moment('00:00:00', 'HH:mm:ss'),
                      moment('23:59:59', 'HH:mm:ss')
                    ]
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </FormItem>
            </Col>
            <Col>
              <Button
                type="primary"
                disabled={isEmpty(data) || loading}
                onClick={onDownloadClick}
              >
                匯出報表
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        rowKey="id"
        columns={getColumns(uuid)}
        dataSource={data}
        loading={loading}
        pagination={pagination}
      />
      <PointTradingModal
        open={isShowModal}
        onOk={onSubmit}
        onCancel={onModalCancel}
      />
    </PageContainer>
  );
};

export default PointTradingManagement;
