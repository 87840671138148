import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Button, Table, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { getVoiceChatTypeList } from '../../apis';
import VoiceChatTypeModal from './VoiceChatTypeModal';
import { PageTitle, PageContainer } from '../../components/styled/page';
import { voiceChatTemplateConfig  } from '../../constants/voiceChat';
import { indexBy, prop } from 'ramda';
const voiceChatTemplateConfigById = indexBy(prop('id'), voiceChatTemplateConfig);
const { Column } = Table;

const BackgoroundField = styled.div`
  padding-top: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const InfoField = styled.div`
  display: inline-block;
  text-align: left;

  p {
    margin: 0.5rem 0;
  }
`;

const ActionsField = styled.div`
  button {
    border: 0 !important;
    background: none !important;
    font-size: 1.3em;
    overflow: hidden;

    & + button {
      margin-left: 0.5rem;
    }
  }
`;

const Tools = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

class VoiceChatTypeManagement extends React.Component {

  constructor(props) {
    super(props);

    this.VoiceChatTypeModalRef = React.createRef();

    this.state = {
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchVoiceChatTypeList();
  }

  fetchVoiceChatTypeList = () => {
    this.setState({ isLoading: true });
    const observable = getVoiceChatTypeList();
    observable.subscribe(res => {
      const { data } = res;
      this.setState({
        dataSource: data,
        isLoading: false,
      });
    });
  }

  onCreateBtnClick = () => {
    const modal = this.VoiceChatTypeModalRef.current;
    modal.show('create');
  }

  onEditBtnClick = (record) => {
    const modal = this.VoiceChatTypeModalRef.current;
    modal.show('edit', record);
  }

  onVoiceChatTypeModalCompeleted = () => {
    const modal = this.VoiceChatTypeModalRef.current;
    modal.hide();
    this.fetchVoiceChatTypeList();
  }

  renderBackgroundField = (rowData) => {
    const { backgroundUrl } = rowData;
    const style = { backgroundImage: `url(${backgroundUrl})` };
    return (
      <BackgoroundField style={style} />
    );
  }

  renderInfoField = (rowData) => {
    const maxSeats = _.get(rowData, 'maxSeats', '-');
    const maxVipSeats = _.get(rowData, 'maxVipSeats', '-');
    const stickerGroupId = _.get(rowData, 'stickerGroupId', '-');
    const uiTemplate = _.get(rowData, 'uiTemplate', '-');
    return (
      <InfoField>
        <p>表情貼群組ID：{stickerGroupId}</p>
        <p>麥位數量：{maxSeats}</p>
        <p>VIP麥位數量：{maxVipSeats}</p>
        <p>UI 版型：{uiTemplate} ({voiceChatTemplateConfigById[uiTemplate].info})</p>
      </InfoField>
    );
  }

  renderVoiceChatTypeList = () => {
    const { isLoading, dataSource } = this.state;

    return (
      <Table
        rowKey="id"
        dataSource={dataSource}
        loading={isLoading}
        pagination={false}
      >
        <Column
          title="背景底圖"
          key="background"
          width="12rem"
          align="center"
          render={this.renderBackgroundField}
        />
        <Column
          title="類型名稱"
          key="name"
          align="center"
          dataIndex="name"
        />
        <Column
          title="相關資訊"
          key="info"
          align="center"
          render={this.renderInfoField}
        />
        <Column
          title="功能"
          key="actions"
          width="8rem"
          align="center" render={this.renderActions}
        />
      </Table>
    );
  }

  renderActions = (rowData) => {
    return (
      <ActionsField>
        <Tooltip title="編輯">
          <Button
            onClick={() => this.onEditBtnClick(rowData)}
          >
            <EditOutlined />
          </Button>
        </Tooltip>
      </ActionsField>
    );
  }

  renderTools = () => {
    return (
      <Tools>
        <Button
          type="primary"
          onClick={this.onCreateBtnClick}
        >
          <span>新增</span>
        </Button>
      </Tools>
    );
  }

  render() {
    return (
      <PageContainer>
        <PageTitle>{'聲聊管理 > 聲聊類型管理'}</PageTitle>
        {this.renderTools()}
        {this.renderVoiceChatTypeList()}
        <VoiceChatTypeModal
          ref={this.VoiceChatTypeModalRef}
          onCompeleted={this.onVoiceChatTypeModalCompeleted}
        />
      </PageContainer>
    );
  };
}

export default VoiceChatTypeManagement;
