import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { map, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import { getOutsourcePointTransferListAPI } from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';

/**Type */
const GET_OUTSOURCE_POINT_TRANSFER_LIST = createRequestTypes(
  'GET_OUTSOURCE_POINT_TRANSFER_LIST'
);

/**
 * Action Creator
 */
export const getOutsourcePointTransferList = createAction(
  GET_OUTSOURCE_POINT_TRANSFER_LIST.REQUEST
);
export const getOutsourcePointTransferListSuccess = createAction(
  GET_OUTSOURCE_POINT_TRANSFER_LIST.SUCCESS
);
export const getOutsourcePointTransferListFailure = createAction(
  GET_OUTSOURCE_POINT_TRANSFER_LIST.FAILURE
);

/**
 * Epics
 */
export const getOutsourcePointTransferListEpic = action$ =>
  action$.pipe(
    ofType(GET_OUTSOURCE_POINT_TRANSFER_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getOutsourcePointTransferListAPI(payload).pipe(
        map(getOutsourcePointTransferListSuccess),
        catchRequestError(getOutsourcePointTransferListFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false,
  totalCount: 0
};

export default handleActions(
  {
    [GET_OUTSOURCE_POINT_TRANSFER_LIST.REQUEST]: produce(
      (draft, { payload }) => {
        draft.loading = true;
      }
    ),

    [GET_OUTSOURCE_POINT_TRANSFER_LIST.SUCCESS]: produce(
      (draft, { payload }) => {
        draft.loading = false;
        draft.data = payload.data;
        draft.totalCount = payload.totalCount;
      }
    ),
    [GET_OUTSOURCE_POINT_TRANSFER_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    })
  },
  initialState
);
