import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';
import { isVideo } from '../utils/media/index';
class LightBox extends PureComponent {
  static ImageModal = ({ visible, alt, src, onCancel }) => {
    return (
      <Modal
        visible={visible}
        footer={null}
        width={'80%'}
        onCancel={onCancel}
        bodyStyle={{
          padding: '8px',
          minHeight: '56px'
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
            margin: 'auto'
          }}
        />
      </Modal>
    );
  };
  static VideoModal = ({ visible, src, onCancel }) => {
    return (
      <Modal
        visible={visible}
        footer={null}
        width={'80%'}
        onCancel={onCancel}
        bodyStyle={{
          padding: '8px',
          minHeight: '56px'
        }}
      >
        <video
          controls
          muted
          autoPlay
          loop
          style={{ width: '100%' }}
          src={src}
        />
      </Modal>
    );
  };

  static defaultProps = {
    src: 'https://via.placeholder.com/450x150',
    alt: '圖片網址錯誤',
    style: {},
    children: '顯示圖片'
  };

  state = {
    visible: false
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  render() {
    const { visible } = this.state;
    const { src, alt, style, children } = this.props;

    return (
      <div style={style}>
        <Button
          disabled={!src}
          onClick={() => this.setState({ visible: true })}
        >
          {children}
        </Button>
        {isVideo(src) ? (
          <LightBox.VideoModal
            visible={visible}
            src={src}
            onCancel={this.handleCancel}
          />
        ) : (
          <LightBox.ImageModal
            visible={visible}
            src={src}
            alt={alt}
            onCancel={this.handleCancel}
          />
        )}
      </div>
    );
  }
}

export default LightBox;
