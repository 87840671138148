import { Button, DatePicker, Table } from 'antd';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import moment from 'moment';
import {
  always,
  complement,
  compose,
  converge,
  find,
  ifElse,
  isEmpty,
  isNil,
  map,
  nthArg,
  pipe,
  propEq,
  propOr,
  zipObj
} from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import TableContainer from '../../components/TableContainer';
import { PaymentTypes } from '../../constants/RemittancePaymentTypes';
import {
  addRemittance,
  allowRemittance,
  defaultQuery,
  downloadRemittance,
  getRemittance,
  getRemittanceList,
  resetOfflineProduct,
  resetRemittance,
  setRemittance,
  showModal
} from '../../reducers/remittanceManagement';
import { isReallyEmpty } from '../../utils/mixin';
import RemittanceForm from './RemittanceForm';
const RangePicker = DatePicker.RangePicker;

/**
 * Style
 */
const ButtonsContainer = styled.div`
  margin: 10px 0;
  .mr8:not(:last-child) {
    margin-right: 8px;
  }
`;
const findById = converge(find, [pipe(nthArg(0), propEq('id')), nthArg(1)]);
const hasInvoice = pipe(propOr(null, 'invoiceNumber'), complement(isNil));
const getDates = ifElse(
  isNil,
  always({}),
  compose(
    zipObj(['startTime', 'endTime']),
    map(v => v.unix())
  )
);
const generateTableColumn = () => {
  return [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: '消費者',
      dataIndex: 'consumer',
      render: (value, row) => <span>{value.nickname}</span>
    },
    {
      title: '建立日期',
      width: 200,
      dataIndex: 'createdAt',
      render: value => moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '交易金額',
      dataIndex: 'price',
      render: value => (value ? value.toLocaleString() : '無產品金額')
    },
    {
      title: '交易點數',
      dataIndex: 'points',
      render: value => (value ? value.toLocaleString() : '無產品點數')
    },
    {
      title: '入帳發點日期',
      width: 200,
      dataIndex: 'approvedAt',
      render: value =>
        isReallyEmpty(value)
          ? ''
          : moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '狀態',
      dataIndex: 'approvedAt',
      key: 'status',
      render: (approvedAt, { accountNumber, paymentType, image }) => {
        if (!isReallyEmpty(approvedAt)) {
          return '已批准';
        } else if (
          !isReallyEmpty(accountNumber) ||
          (paymentType === PaymentTypes[1].value && !isNil(image)) // cash 使用上傳檔案來判斷是否匯款
        ) {
          return '已匯款';
        } else {
          return '未匯款';
        }
      }
    },
    {
      title: '匯款帳號後五碼',
      dataIndex: 'accountNumber'
    },
    {
      title: '發票號碼',
      dataIndex: 'invoiceNumber'
    },
    {
      title: '交易方式',
      dataIndex: 'paymentType',
      render: paymentType =>
        paymentType === PaymentTypes[0].value ? '匯款' : '現金'
    }
  ];
};
const mapStateToProps = ({ remittanceManagement }) => remittanceManagement;
const mapDispatchToProps = {
  getRemittanceList,
  getRemittance,
  addRemittance,
  setRemittance,
  allowRemittance,
  showModal,
  resetRemittance,
  resetOfflineProduct
};
@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
class RemittanceManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: [],
      isChanged: false,
      isAllowed: false
    };
  }
  componentDidMount() {
    this.props.getRemittanceList();
  }
  componentWillUnmount() {
    this.props.resetOfflineProduct();
  }

  /**
   * 搜尋
   */
  handleSearchClick = (page = defaultQuery.page) => {
    const {
      getRemittanceList,
      form: { getFieldValue }
    } = this.props;
    const dates = getDates(getFieldValue('dates'));
    this.setState({ isChanged: false, selectedKeys: [] });
    getRemittanceList({
      userId: getFieldValue('userId'),
      ...dates,
      page: page
    });
  };

  /**
   * 清除項目
   */
  handleReset = () => {
    this.setState({ isChanged: true });
    this.props.form.resetFields();
  };

  /**
   * 匯出報表
   */
  handleDownloadClick = () => {
    const {
      totalCount,
      offlineProduct,
      form: { getFieldValue }
    } = this.props;
    const dates = getDates(getFieldValue('dates'));
    downloadRemittance(
      { userId: getFieldValue('userId'), ...dates, item: totalCount, page: 1 },
      offlineProduct
    );
  };

  /**
   * 搜尋項目改變時
   */
  handleControlsChange = () => {
    this.setState({ isChanged: true });
  };

  /**
   * 新增
   */
  handleAddClick = () => {
    this.setState({ isInvoiced: false, isAllowed: false });
    this.props.showModal(true);
  };

  /**
   * 編輯
   */
  handleEditClick = () => {
    const { data } = this.props;
    const { selectedKeys } = this.state;
    const current = findById(selectedKeys[0], data);
    const isInvoiced = hasInvoice(current);
    this.setState({ isInvoiced, isAllowed: false });
    this.props.getRemittance(selectedKeys[0]);
    this.props.showModal(true);
  };

  /**
   * 批准
   */
  handleAllowClick = () => {
    const { selectedKeys } = this.state;
    const { getRemittance, showModal } = this.props;
    this.setState({ isAllowed: true });
    getRemittance(selectedKeys[0]);
    showModal(true);
  };

  /**
   * 送出
   */
  handleSubmitClick = (value, mode, enableCallInvoice = false) => {
    const { addRemittance, setRemittance, allowRemittance } = this.props;
    if (mode === 'NEW') {
      addRemittance(value);
    } else if (mode === 'EDIT' || mode === 'DONE') {
      setRemittance(value, enableCallInvoice);
    } else if (mode === 'ALLOW') {
      allowRemittance({ remittanceId: value.id });
    }
  };

  /**
   * 取消
   */
  handleCancelClick = values => {
    const { showModal, resetRemittance } = this.props;
    this.setState({ isAllowed: false, isInvoiced: false });
    showModal(false);
    resetRemittance();
  };
  render() {
    const {
      loading,
      page,
      item,
      totalCount,
      data,
      isShowModal,
      remittanceLoading,
      currentRemittance,
      offlineProduct,
      form: { getFieldDecorator }
    } = this.props;

    const { selectedKeys, isAllowed, isChanged, isInvoiced } = this.state;
    return (
      <div>
        <h1>{'帳務管理 > 匯款訂單管理'}</h1>
        <Form
          layout="inline"
          onSubmit={e => {
            e.preventDefault();
            this.handleSearchClick(1);
          }}
        >
          <Form.Item label="消費者">
            {getFieldDecorator('userId')(
              <LiveMasterSelect
                style={{ width: '500px' }}
                typeFilter="nick_name"
                roleFilter="ROLE_USER"
                onSelectChange={this.handleControlsChange}
              />
            )}
          </Form.Item>
          <Form.Item label="建立日期">
            {getFieldDecorator('dates')(
              <RangePicker
                allowClear={true}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '100%' }}
                onChange={this.handleControlsChange}
                showTime={{
                  defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'),
                    moment('23:59:59', 'HH:mm:ss')
                  ]
                }}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: '8px' }}
            >
              <LegacyIcon type="search" />
              <span>搜尋</span>
            </Button>
            <Button onClick={this.handleReset} style={{ marginRight: '8px' }}>
              清除
            </Button>
            <Button
              onClick={this.handleDownloadClick}
              disabled={isEmpty(data) || isChanged}
            >
              匯出報表
            </Button>
          </Form.Item>
        </Form>
        <ButtonsContainer>
          <Button className="mr8" onClick={this.handleAddClick}>
            新增
          </Button>
          <Button
            className="mr8"
            onClick={this.handleEditClick}
            disabled={selectedKeys.length !== 1}
          >
            編輯
          </Button>
          <Button
            className="mr8"
            onClick={this.handleAllowClick}
            disabled={selectedKeys.length !== 1}
          >
            批准
          </Button>
        </ButtonsContainer>
        <TableContainer>
          <Table
            columns={generateTableColumn()}
            loading={loading}
            dataSource={data}
            rowKey="id"
            scroll={{ x: true }}
            rowSelection={{
              selectedRowKeys: selectedKeys,
              onChange: selectedKeys => {
                this.setState({ selectedKeys });
              }
            }}
            pagination={{
              showSizeChanger: false,
              onChange: page => this.handleSearchClick(page),
              current: page,
              pageSize: item,
              total: totalCount
            }}
          />
        </TableContainer>
        <RemittanceForm
          loading={remittanceLoading}
          currentRemittance={currentRemittance}
          onSubmit={this.handleSubmitClick}
          onCancel={this.handleCancelClick}
          isShowModal={isShowModal}
          isAllowed={isAllowed}
          productList={offlineProduct}
          isInvoiced={isInvoiced}
        />
      </div>
    );
  }
}
export default RemittanceManagement;
