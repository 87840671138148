import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Layout, Button } from 'antd';
import { rem } from 'polished';
import * as actions from '../actions';
import { changePassword } from '../reducers/auth';
import ChangePasswordModal from './ChangePasswordModal';
import brandName from '../constants/brandName';

const StyledHeader = styled(Layout.Header)`
  display: flex;
  font-size: ${rem('24px')};
  color: white;
  white-space: nowrap;
  height: 64px;
  padding-left: 16px;
  padding-right: 8px;
  align-items: center;

  > .auth-identity {
    margin-left: auto;

    > .user-name {
      font-size: 18px;
      margin-right: 8px;
    }

    > * {
      vertical-align: middle;
    }
  }
`;

class AppHeader extends PureComponent {
  state = {
    modalVisible: false
  };
  static propTypes = {
    loginId: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired
  };

  handleShowChangePasswordModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  handleCloseChangePasswordModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  handleChangePassword = data => {
    const { changePassword } = this.props;
    changePassword({
      newPassword: data.newPassword,
      password: data.oldPassword
    });
    console.log(data);
  };

  handleLogout = () => {
    this.props.logout();
  };

  render() {
    const { modalVisible } = this.state;

    return (
      <StyledHeader>
        <span>{brandName} 後台管理系統</span>
        <div className="auth-identity">
          <span className="user-name">{`歡迎回來, ${this.props.loginId}`}</span>
          <Button ghost onClick={this.handleLogout} style={{ marginRight: 10 }}>
            登出
          </Button>
          <Button ghost onClick={this.handleShowChangePasswordModal}>
            更改密碼
          </Button>
        </div>

        {modalVisible ? (
          <ChangePasswordModal
            onCancel={this.handleCloseChangePasswordModal}
            onOk={this.handleChangePassword}
          />
        ) : null}
      </StyledHeader>
    );
  }
}

const mapStateToProps = state => ({
  loginId: state.auth.loginId
});

export default connect(mapStateToProps, {
  logout: actions.logout,
  changePassword
})(AppHeader);
