import _ from 'lodash';
import {
  always, compose, cond,
  evolve, isNil, when,
  where,
} from 'ramda';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Input, Popconfirm, Table } from 'antd';
import { isNotNil } from '../../utils/mixin';
import antdTablePagination from '../../utils/antdTablePagination';
import {
  addDispatch, deleteDispatch, getDispatch,
  getDispatchList, getDispatchUsers, getTagsList,
  resetDispatch, setDispatch, showModal,
  withdrawDispatchUser,
} from '../../reducers/dispatchManagement';
import {
  getEffect,
  getEffectList,
  resetEffect,
} from '../../reducers/effectManagement';
import { PageContainer, PageTitle } from '../../components/styled/page';
import DispatchFormModal from './DispatchFormModal';
import DispatchUsersModal from './DispatchUsersModal';
import PreviewEffectModal from './PreviewEffectModal';

const { Search } = Input;

const SearchBar = styled.div`
  margin-bottom: 0.5rem;
`;

const ActionBar = styled.div`
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const getSubmitData = compose(
  when(
    where({ scheduleTime: isNotNil }),
    evolve({ scheduleTime: value => value.unix() })
  )
);

const getDateFormat = value => {
  return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '無';
};

const getSendingStatus = value => {
  return cond([
    [where({ sendTime: isNotNil }), always('已發送')],
    [where({ sendTime: isNil, scheduleTime: isNotNil }), always('已排程')],
    [where({ sendTime: isNil, scheduleTime: isNil }), always('無')]
  ])(value);
};

const DispatchManagement = () => {
  const dispatch = useDispatch();
  const {
    data,
    tagList,
    dispatchUsers,
    loadingDispatchUsers,
    loading,
    isShowModal,
    currentDispatch,
    totalCount,
    effectList,
    currentEffect,
  } = useSelector(({ effectManagement, dispatchManagement }) => ({
    ...dispatchManagement,
    effectList: effectManagement.data,
    currentEffect: effectManagement.currentEffect
  }));
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [showDispatchUsersModal, setShowDispatchUsersModal] = useState(false);
  const [showPreviewEffectModal, setShowPreviewEffectModal] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [page, setPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dispatchId, setDispatchId] = useState();

  const defaultPagination = new antdTablePagination();

  const columns = [{
    title: '編號',
    dataIndex: 'id',
  }, {
    title: '發送帳號',
    render: (value) => {
      return (
        <Button
          disabled={isNil(value.sendTime)}
          onClick={() => onDispatchUserClick(value.id)}
        >
          檢視
        </Button>
      );
    }
  }, {
    title: '發送時間',
    dataIndex: 'sendTime',
    render: (value, record) => getDateFormat(value || record.scheduleTime),
  }, {
    title: '發送狀態',
    render: value => getSendingStatus(value),
  }, {
    title: '特效名稱',
    dataIndex: 'name',
  }, {
    title: '特效預覽',
    width: 100,
    render: value => {
      return (
        <Button onClick={() => onPreviewEffectClick(value)}>檢視</Button>
      );
    }
  }, {
    title: '套裝',
    dataIndex: 'suitStatus',
    render: value => (value ? '是' : '否')
  }, {
    title: '期效',
    dataIndex: 'availableTimeDesc',
  }, {
    title: '發送後立即啟用',
    dataIndex: 'activated',
    render: value => (value ? '是' : '否')
  }];

  useEffect(() => {
    dispatch(getDispatchList({ filter: '', page: 1 }));
    dispatch(resetEffect());
    dispatch(getTagsList({ page: 1, item: 999 }));
    dispatch(
      getEffectList({
        filter: 'ALL',
        sfxFilter: 'TEMPORARY',
        page: 1,
        item: 999
      })
    );
  }, [dispatch]);

  /**
   * 取得、搜尋 Dispatch 列表
   */
  const searchDispatchList = (filterText = '', page = 1) => {
    setFilterText(filterText);
    setPage(page);
    setSelectedRowKeys([]);
    dispatch(getDispatchList({ filter: filterText, page }));
  };

  /**
   * 處理 Table 勾選
   */
  const onSelectRowChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  /**
   * 點選分頁
   */
  const onPageChange = (page) => {
    searchDispatchList(filterText, page);
  };

  /**
   * 搜尋
   */
  const onSearchClick = (value) => {
    searchDispatchList(value);
  };

  /**
   * 新增
   */
  const onAddClick = () => {
    setIsCreateMode(true);
    dispatch(showModal(true));
  };

  /**
   * 編輯
   */
  const onEditClick = () => {
    setIsCreateMode(false);
    dispatch(getDispatch(selectedRowKeys[0]));
    dispatch(showModal(true));
  };

  /**
   * 刪除
   */
  const onDelectClick = () => {
    const { pageSize } = defaultPagination;
    const maxPage = Math.ceil((totalCount - 1) / pageSize);
    const newPage = _.clamp(page, 1, maxPage);
    dispatch(
      deleteDispatch(
        selectedRowKeys,
        getDispatchList({ filter: filterText, page: newPage }),
      )
    );
    setSelectedRowKeys([]);
  };

  /**
   * 收回用戶
   */
  const onWithdrawClick = uuids => {
    dispatch(
      withdrawDispatchUser(
        { dispatchId, uuids },
        getDispatchUsers({ id: dispatchId, page: 1, item: 9999 })
      )
    );
  };

  /**
   * 點擊檢視特效
   */
  const onPreviewEffectClick = value => {
    dispatch(getEffect(value.acmpId));
    dispatch(getDispatch(value.id));
    setShowPreviewEffectModal(true);
  };

  /**
   * 查看發送帳號列表
   */
  const onDispatchUserClick = id => {
    dispatch(getDispatchUsers({ id, page: 1, item: 9999 }));
    setShowDispatchUsersModal(true);
    setDispatchId(id);
  };

  /**
   * 送出表單
   */
  const onSubmit = values => {
    if (isCreateMode) {
      dispatch(
        addDispatch(
          getSubmitData(values),
          getDispatchList({ filter: '', page: 1 })
        )
      );
      setFilterText('');
      setPage(1);
      setSelectedRowKeys([]);
    } else {
      dispatch(
        setDispatch(
          getSubmitData(values),
          getDispatchList({ filter: filterText, page })
        )
      );
    }
  };

  /**
   * 關閉表單
   */
  const onModalCancel = () => {
    dispatch(showModal(false));
    dispatch(resetDispatch());
  };

  /**
   * 關閉發送帳號
   */
  const onDispatchUsersCancel = () => {
    setShowDispatchUsersModal(false);
    setDispatchId(undefined);
  };

  /**
   * 關閉預覽
   */
  const onPrewEffectCancel = () => {
    dispatch(resetEffect());
    dispatch(resetDispatch());
    setShowPreviewEffectModal(false);
  };

  return (
    <PageContainer>
      <PageTitle>{'活動管理 > 發送特效'}</PageTitle>
      <SearchBar>
        <Search
          allowClear={true}
          value={filterText}
          style={{ maxWidth: '320px' }}
          placeholder="請輸入特效名稱"
          onChange={event => setFilterText(event.target.value)}
          onSearch={value => onSearchClick(value)}
          enterButton
        />
      </SearchBar>
      <ActionBar>
        <Button
          onClick={onAddClick}
        >
          <span>新增</span>
        </Button>
        <Button
          disabled={selectedRowKeys.length !== 1}
          onClick={onEditClick}
        >
          <span>編輯</span>
        </Button>
        <Popconfirm
          disabled={selectedRowKeys.length === 0}
          key={'cancel'}
          title="是否確定刪除?"
          placement="right"
          onConfirm={onDelectClick}
        >
          <Button
            type="danger"
            disabled={selectedRowKeys.length === 0}
          >
            <span>刪除</span>
          </Button>
        </Popconfirm>
      </ActionBar>
      <Table
        rowKey="id"
        scroll={{ x: true }}
        loading={loading}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectRowChange,
        }}
        pagination={{
          ...defaultPagination,
          total: totalCount,
          current: page,
          onChange: onPageChange,
        }}
        columns={columns}
        dataSource={data}
      />
      <PreviewEffectModal
        effectData={currentEffect}
        dispatchData={currentDispatch}
        visible={showPreviewEffectModal}
        onCancel={onPrewEffectCancel}
      />
      <DispatchUsersModal
        loading={loadingDispatchUsers}
        visible={showDispatchUsersModal}
        onWithdraw={onWithdrawClick}
        data={dispatchUsers}
        onCancel={onDispatchUsersCancel}
      />
      <DispatchFormModal
        isCreateMode={isCreateMode}
        visible={isShowModal}
        loading={loading}
        effectList={effectList}
        tagList={tagList}
        editData={currentDispatch}
        onOk={onSubmit}
        onCancel={onModalCancel}
      />
    </PageContainer>
  );
};

export default DispatchManagement;
