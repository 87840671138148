import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { find, identity, path, propEq } from 'ramda';
import { useWith as ramdaUseWith } from 'ramda';
import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { createContentType, setContentTypeIsNull, uploadAPI } from '../apis';
import { isReallyEmpty } from '../utils/mixin';
const createFile = url => {
  if (isReallyEmpty(url)) {
    return { uid: '-1', name: '無資料', status: 'error' };
  }
  const name = url.split('/').slice(-1)[0];

  return {
    uid: '-1',
    name: name.length > 1 ? name : 'Preview',
    response: url,
    status: 'done'
  };
};
const findByResponse = ramdaUseWith(find, [propEq('response'), identity]);
const beforeUpload = file => {
  const isValidType = /(.bundle)$/g.test(file.name);
  if (!isValidType) {
    message.error('檔案格式不符合！');
  }
  return isValidType;
};
const BundleUpload = memo(
  forwardRef(({ value, onChange, disabled }, ref) => {
    const [fileList, setFileList] = useState([]);
    const subscriptionRef = useRef();
    const handleChange = ({ file, file: { status }, fileList }) => {
      if (status) {
        setFileList(fileList.slice(-1));
      }
      if (status === 'done') {
        onChange(file.response);
      }
      if (status === 'removed') {
        onChange(null);
      }
    };

    useEffect(() => {
      const valueArray = isReallyEmpty(value) ? [] : [value];
      setFileList(list =>
        valueArray.map(v => findByResponse(v, list) || createFile(v))
      );
    }, [value]);

    //unsubscribe uploaders
    useEffect(() => {
      return () => {
        if (subscriptionRef.current) {
          subscriptionRef.current.unsubscribe();
        }
      };
    }, []);

    return (
      <Upload
        ref={ref}
        accept=".bundle"
        fileList={fileList}
        onChange={handleChange}
        disabled={disabled}
        beforeUpload={beforeUpload}
        customRequest={info => {
          const form = new FormData();
          form.append('file', info.file);
          setContentTypeIsNull();
          subscriptionRef.current = uploadAPI('/cdn/sfx/bundle')(
            form
          ).subscribe(
            res => {
              try {
                info.onSuccess(path(['data', 'url'], res));
              } catch (e) {
                message.error(e.name);
              }
              createContentType('application/json;charset=UTF-8');
            },
            error => {
              info.onError(error);
            }
          );
        }}
      >
        <Button disabled={disabled}>
          <UploadOutlined />
          上傳 Bundle 檔
        </Button>
      </Upload>
    );
  })
);
export default BundleUpload;
