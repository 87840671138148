import React, { Component } from 'react';
import styled from 'styled-components';
import fakeData from '../fake/commentManagement';
import { Input, Table, Button, Modal, message } from 'antd';

const Toolbar = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  .title {
    font-size: 16px;
  }

  .search-field {
    width: 150px;
    margin-left: 8px;
  }
`;

const BtnGroup = styled.div`
  white-space: nowrap;

  button {
    margin-right: 8px;
  }
`;

const TableContainer = styled.div`
  min-width: 600px;

  table {
    .time-stamp,
    .account-name {
      white-space: nowrap;
    }

    .comment {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

class CommentManagement extends Component {
  state = {
    isModalVisible: false,
    originComments: '',
    commenterAccount: '',
  };

  handleTextSearch = text => {
    if (text) message.warn(`你搜尋關鍵字 ${text}`);
  };

  handleDeleteByRowData = rowData => () => {
    message.success(`帳號 ${rowData.accountName} 刪除成功。`);
  };

  handleCheckOriginByRowData = rowData => () => {
    this.setState({
      isModalVisible: true,
      originComments: rowData.comment,
      commenterAccount: rowData.accountName,
    });
  };

  handleModalClose = () => {
    this.setState({ isModalVisible: false });
  };

  render() {
    const tableColumns = [
      {
        title: '時間',
        dataIndex: 'timeStamp',
        render: text => <div className="time-stamp">{text}</div>,
      },
      {
        title: '帳號',
        dataIndex: 'accountName',
        render: text => <div className="account-name">{text}</div>,
      },
      {
        title: '內容',
        dataIndex: 'comment',
        render: text => <div className="comment">{text}</div>,
      },
      {
        title: '功能',
        render: record => (
          <BtnGroup>
            <Button
              type="primary"
              onClick={this.handleCheckOriginByRowData(record)}
            >
              觀看原文
            </Button>
            <Button type="danger" onClick={this.handleDeleteByRowData(record)}>
              刪除
            </Button>
          </BtnGroup>
        ),
      },
    ];

    return (
      <div>
        <h1>留言管理</h1>
        <Toolbar>
          <span className="title">搜尋留言帳號：</span>
          <Input.Search
            className="search-field"
            placeholder="請輸入帳號搜尋留言"
            onSearch={this.handleTextSearch}
          />
        </Toolbar>
        <TableContainer>
          <Table rowKey="_id" columns={tableColumns} dataSource={fakeData} />
        </TableContainer>
        <Modal
          title={`觀看原文 (${this.state.commenterAccount})`}
          visible={this.state.isModalVisible}
          footer={<Button onClick={this.handleModalClose}>關閉</Button>}
          onCancel={this.handleModalClose}
        >
          <p>{this.state.originComments}</p>
        </Modal>
      </div>
    );
  }
}

export default CommentManagement;
