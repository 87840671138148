import { message } from 'antd';
import { always, cond, hasPath, path, pipe, T, pathOr } from 'ramda';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, pluck } from 'rxjs/operators';
import { showServerError } from '../actions/index';
import statusCode from '../constants/statusCode';
export { ofType };
const getMessage = cond([
  [hasPath(['response', 'Message']), path(['response', 'Message'])],
  [hasPath(['response', 'message']), path(['response', 'message'])],
  [T, always(undefined)]
]);
const getStatusCode = (msg, statusCode) => {
  if (statusCode) {
    return pathOr(msg, [msg], statusCode);
  } else {
    return msg;
  }
};
/**
 * filter action's type & return action's payload
 * @param {string} type type of action
 */
export const getPayloadByType = type => pipe(ofType(type), pluck('payload'));
export const catchRequestError = (callback, otherStatusCode) =>
  catchError(error => {
    let errorList = [callback(error)];
    const msg = getMessage(error);
    if (error instanceof AjaxError && error.status === 401) {
      errorList.push(showServerError());
    }
    if (msg) {
      message.error(getStatusCode(msg, otherStatusCode || statusCode));
      console.log(msg);
    } else if (error instanceof AjaxError) {
      message.error(error.message);
    }

    return from(errorList);
  });
