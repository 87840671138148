import React, { Component } from 'react';
import moment from 'moment';
import { mapObjIndexed, compose, evolve, path } from 'ramda';
import {
  Button,
  Select,
  Modal,
  Popconfirm,
  DatePicker,
  Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import LiveMasterSelect from '../../components/LiveMasterSelect';

const { Option } = Select;
const FormItem = Form.Item;

@Form.create({
  mapPropsToFields: compose(
    mapObjIndexed((value) => Form.createFormField({ value: value })),
    evolve({
      sfx: (e) => (e ? e.toString() : ''),
      liveMaster: (e) => e,
      startTime: (e) => moment.unix(e),
      endTime: (e) => moment.unix(e),
    }),
    path(['currentReward'])
  ),
})
class RewardForm extends Component {
  static defaultProps = {
    loading: false,
    effectList: [],
    isCreateMode: false,
    showModal: (v) => console.log(v),
  };

  /**
   * 產生表單元素
   */
  generateFormItem() {
    const {
      effectList,
      isCreateMode,
      form: { resetFields, getFieldValue },
    } = this.props;
    return [
      {
        key: 'sfx',
        label: '動效名稱：',
        options: { rules: [{ required: true, message: '請選擇動效' }] },
        render: () => (
          <Select placeholder="僅顯示當前有效動效">
            {effectList.map((e) => (
              <Option key={e.id} value={e.id.toString()}>
                {e.name}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        key: 'liveMaster',
        label: '主播帳號：',
        options: {
          rules: [
            {
              required: true,
              message: '請選擇主播帳號',
              type: isCreateMode ? 'array' : 'string',
            },
          ],
        },
        render: () => (
          <LiveMasterSelect
            style={{ width: '100%' }}
            typeFilter="uuid"
            mode={isCreateMode ? 'multiple' : 'default'}
          />
        ),
      },
      {
        key: 'startTime',
        label: '上架日期',
        options: {
          rules: [{ required: true, message: '請選擇上架日期' }],
        },
        render: () => (
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            disabledDate={(current) =>
              current < moment().subtract(1, 'day').endOf('day')
            }
            onChange={() => resetFields(['endTime'])}
          />
        ),
      },
      {
        key: 'endTime',
        label: '下架日期',
        options: {
          rules: [{ required: true, message: '請選擇下架日期' }],
        },
        render: () => (
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
            disabledDate={(current) => {
              const formStartTime = getFieldValue('startTime')
                ? getFieldValue('startTime').clone()
                : moment();
              return current < formStartTime.subtract(1, 'day').endOf('day');
            }}
          />
        ),
      },
    ];
  }

  /**
   * 關閉 Modal
   */
  handleModalClose = () => {
    const { showModal, setCurrentReward } = this.props;
    showModal(false);
    setCurrentReward({});
  };

  /**
   * 送出並驗證
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const { form, addReward, modifyReward, isCreateMode } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const result = {
          ...values,
          startTime: values.startTime.unix(),
          endTime: values.endTime.unix(),
        };
        isCreateMode ? addReward(result) : modifyReward(result);
      }
    });
  };

  render() {
    const {
      isShowModal,
      loading,
      isCreateMode,
      form: { getFieldDecorator, getFieldsError },
    } = this.props;
    const actionText = isCreateMode ? '新增' : '更新';
    const hasErrors = (fieldsError) =>
      Object.keys(fieldsError).some((field) => fieldsError[field]);
    return isShowModal ? (
      <Modal
        title={`${actionText} 主播獎勵動效`}
        width={600}
        visible={isShowModal}
        footer={[
          <Popconfirm
            key={'confirm'}
            title={`是否確定${actionText}`}
            placement="topLeft"
            onConfirm={this.handleSubmit}
          >
            <Button
              key={'add'}
              loading={loading}
              type="primary"
              disabled={hasErrors(getFieldsError())}
            >
              {actionText}
            </Button>
          </Popconfirm>,
          <Button key={'cancle'} onClick={this.handleModalClose}>
            取消
          </Button>,
        ]}
        closable={false}
        onCancel={this.handleModalClose}
      >
        <Form>
          {this.generateFormItem().map((item) => (
            <FormItem key={item.key} label={item.label}>
              {getFieldDecorator(item.key, item.options)(item.render())}
            </FormItem>
          ))}
          {isCreateMode
            ? null
            : getFieldDecorator('id')(<Input type="hidden" />)}
        </Form>
      </Modal>
    ) : null;
  }
}
export default RewardForm;
