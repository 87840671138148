import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Table, Form, InputNumber } from 'antd';

export default class CritalStrikeSetting extends PureComponent {
  _createColumns = () => {
    const { formFieldDecorator } = this.props;

    return [
      {
        title: '爆擊1 分數',
        dataIndex: ['additionEffects', '0', 'points'],
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}]additionEffects[0].points`, {
              validateTrigger: ['onBlur'],
              rules: [
                { required: true, whitespace: true, message: '此欄位必填' },
              ],
            })(
              <InputNumber
                size="large"
                className="w100"
                placeholder="爆擊1 分數"
                min={0}
              />
            )}
          </FormItem>
        ),
      },
      {
        title: '爆擊1 觸發率',
        dataIndex: 'additionEffects[0].probability',
        width: 90,
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].additionEffects[0].probability`, {
              validateTrigger: ['onBlur'],
              rules: [
                { required: true, whitespace: true, message: '此欄位必填' },
              ],
            })(
              <InputNumber
                size="large"
                className="w100"
                placeholder="爆擊1 觸發率"
                min={0}
                max={100}
              />
            )}
          </FormItem>
        ),
      },
      {
        title: '爆擊2 分數',
        dataIndex: 'additionEffects[1].points',
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].additionEffects[1].points`, {
              validateTrigger: ['onBlur'],
              rules: [
                { required: true, whitespace: true, message: '此欄位必填' },
              ],
            })(
              <InputNumber
                size="large"
                className="w100"
                placeholder="爆擊2 分數"
                min={0}
              />
            )}
          </FormItem>
        ),
      },
      {
        title: '爆擊2 觸發率',
        dataIndex: 'additionEffects[1].probability',
        width: 90,
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].additionEffects[1].probability`, {
              validateTrigger: ['onBlur'],
              rules: [
                { required: true, whitespace: true, message: '此欄位必填' },
              ],
            })(
              <InputNumber
                size="large"
                className="w100"
                placeholder="爆擊2 觸發率"
                min={0}
                max={100}
              />
            )}
          </FormItem>
        ),
      },
      {
        title: '爆擊點數上限',
        dataIndex: 'criticalStrike.limit',
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].additionEffects[2].points`, {
              validateTrigger: ['onBlur'],
              rules: [
                { required: true, whitespace: true, message: '此欄位必填' },
              ],
            })(
              <InputNumber
                size="large"
                className="w100"
                placeholder="爆擊點數上限"
                min={0}
              />
            )}
          </FormItem>
        ),
      },
    ];
  };

  render() {
    const { data } = this.props;

    const columns = this._createColumns();

    return (
      <Table
        rowKey="id"
        className="mt12"
        columns={columns}
        dataSource={data}
        size="middle"
        pagination={false}
      />
    );
  }
}

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-top: 5px;
  }

  .ant-input-number {
    margin-top: 10px;
  }
`;
