import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import zh_TW from 'antd/lib/locale-provider/zh_TW';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { loginInit } from './reducers/auth';
import * as serviceWorker from './serviceWorker';
import store from './store';

import '@ant-design/compatible/assets/index.css';
import './assets/css/common/common.css';

const GlobalStyle = createGlobalStyle`
  ${normalize()}
`;

store.dispatch(loginInit());

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zh_TW}>
      <App />
      <GlobalStyle />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
