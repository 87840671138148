import { API_V3 } from '../constants/endpoint';
import { createQs } from '../utils/mixin';
import { createRequest } from './utils';

/**
 * request for v3
 */
const request = createRequest(API_V3);
// GET 取得系統公告列表
export const getAnnouncementListAPI = ({ dept, pageSize, currentPage }) =>
  request(
    `/backend/announcement/list?item=${pageSize}&page=${currentPage}${
      dept ? '&dept=' + dept : ''
    }`,
    'GET'
  )();
// GET 取得『單ㄧ』系統公告
export const getAnnouncementAPI = (id) =>
  request(`/backend/announcement/${id}`, 'GET')();
// POST 新增系統公告
export const addAnnouncementAPI = (payload) =>
  request('/backend/announcement/create', 'POST')(payload);
// PATCH 編輯系統公告
export const setAnnouncementAPI = (payload) =>
  request(`/backend/announcement/${payload.id}`, 'PATCH')(payload);
// DELETE 刪除系統公告
export const deleteAnnouncementAPI = (id) =>
  request(`/backend/announcement/${id}`, 'DELETE')();
// POST 業務轉點列表（外站）
export const getOutsourcePointTransferListAPI = (payload) =>
  request(`/backend/outsource/pointTransfer/list${createQs(payload)}`, 'GET')();
// GET 讀取家族清單
export const getFamilyListAPI = () =>
  request('/backend/family/list', 'GET')();
// GET 讀取單一家族
export const getFamilyAPI = (id) =>
  request(`/backend/family/${id}`, 'GET')();
// POST 新增家族
export const addFamilyAPI = (payload) =>
  request('/backend/family', 'POST')(payload);
// PATCH 編輯家族
export const updateFamilyAPI = (payload) =>
  request(`/backend/family/${payload.familyId}/info`, 'PATCH')(payload);
// GET 讀取家族活躍成員
export const getFamilyActiveMembersAPI = (id, params) =>
  request(`/backend/family/${id}/active/list${createQs(params)}`, 'GET')();
// GET 讀取家族非活躍成員
export const getFamilyInActiveMembersAPI = (id, params) =>
  request(`/backend/family/${id}/inactive/list${createQs(params)}`, 'GET')();
// GET 使用者取得聲聊房列表
export const getVoiceChatList = payload =>
  request(`/backend/voiceChat/list${createQs(payload)}`, 'GET')();
// POST 後台建立聲聊房
export const addVoiceChatAPI = request('/backend/voiceChat', 'POST');
// GET 後台取得單一聲聊房資訊
export const getVoiceChatRoom = id =>
  request(`/backend/voiceChat/${id}`, 'GET')();
// PATCH 後台編輯聲聊房
export const setVoiceChatAPI = payload =>
  request(`/backend/voiceChat/${payload.id}`, 'PATCH')(payload);
