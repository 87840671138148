export default [
  { index: 1, label: 'bank-1', value: 'bank-1' },
  { index: 2, label: 'bank-2', value: 'bank-2' },
  { index: 3, label: 'bank-3', value: 'bank-3' },
  { index: 4, label: 'bank-4', value: 'bank-4' },
  { index: 5, label: 'bank-5', value: 'bank-5' },
  { index: 6, label: 'bank-6', value: 'bank-6' },
  { index: 7, label: 'bank-7', value: 'bank-7' },
  { index: 8, label: 'bank-8', value: 'bank-8' },
  { index: 9, label: 'bank-9', value: 'bank-9' },
  { index: 10, label: 'bank-10', value: 'bank-10' },
];
