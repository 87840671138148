/* eslint indent:0 */
import React, { PureComponent } from 'react';
import { Select, Input, DatePicker, Row, Col, Button } from 'antd';
import { Form } from '@ant-design/compatible';
import { pathOr, length, forEach, equals, slice, omit } from 'ramda';
import moment from 'moment';
import uuid from '../../utils/uuid';
import { TableContainer, Modal, Spin } from './components';
import RegularLiveNPC from './RegularLiveNPC';
import RandomLiveNPC from './RandomLiveNPC';
import CriticalStrikeSetting from './CritalStrikeSetting';
import { eventTypes, getFields, fieldsOfAdditionEffects } from './utils';
import UploadImage from '../../components/UploadImage';
import { giftType } from '../../constants/gifts';
const FormItem = Form.Item;

@Form.create()
export default class GiftBoxModal extends PureComponent {
  static defaultProps = {
    loading: false,
    visible: false,
    giftList: []
  };
  componentDidMount = () => {
    this._initializeForm();
  };

  componentDidUpdate(prevProps) {
    if (!this.props.loading && !equals(prevProps.npcs, this.props.npcs)) {
      this._initializeForm();
      this.getGiftList(this.props.currentGiftBoxEvent.giftCategoryId);
    }
  }

  _initializeForm = () => {
    const { form, npcs } = this.props;
    const type = eventTypes.NORMAL;
    const lengthOfNPC = length(npcs) || 3;
    const fields = getFields(type);
    const keys = [...Array(lengthOfNPC)].map((_, i) => i);
    forEach(k => {
      //刪除沒有對應 From 的欄位，避免 antd 一直報錯誤
      npcs[k] = omit(
        ['start', 'end', 'relatedEvent', 'type', 'level', 'npcGiftCategoryIds'],
        npcs[k]
      );
      npcs[k]['additionEffects'] = npcs[k]['additionEffects'].map(effect => {
        return omit(['type'], effect);
      });
      npcs[k]['animationSetting'] = omit(
        ['level', 'subLevel', 'levelUpUrl'],
        npcs[k]['animationSetting']
      );
      forEach(f => {
        form.getFieldDecorator(`npcs[${k}].${f}`, {
          initialValue: pathOr(f === 'id' ? uuid() : undefined, [k, f], npcs)
        });
      }, fields);
    }, keys);

    forEach(
      f =>
        form.getFieldDecorator(`npcs[0].${f}`, {
          initialValue: pathOr(
            f.indexOf('id') > -1 ? uuid() : undefined,
            ['[0]', f],
            npcs
          )
        }),
      fieldsOfAdditionEffects
    );

    form.setFieldsValue({ npcs });
  };

  _validateDateRange = (rule, value, callback) => {
    if (!value || (Array.isArray(value) && (!value[0] || !value[1]))) {
      return callback('此欄位必填');
    }
    callback();
  };

  _getFormState = () => {
    const {
      npcs: npcsFromDB,
      form: { getFieldsValue }
    } = this.props;

    const { type, npcs = [], giftCategoryId } = getFieldsValue();
    const isRandomLive = type === eventTypes.RANDOM;

    return {
      isRandomLive,
      giftCategoryId,
      canSubmit: !equals(npcs, npcsFromDB),
      npcs: isRandomLive ? slice(0, 1, npcs) : npcs,
      NPCSetting: isRandomLive ? RandomLiveNPC : RegularLiveNPC
    };
  };

  handleOk = () => {
    const { form, onOk } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (onOk) {
        onOk(values);
      }
    });
  };

  disabledDate = current => {
    const { dateList } = this.props;
    if (current) {
      const current_start = current.clone().startOf('day');
      const current_end = current.clone().endOf('day');
      return (
        current <
          moment()
            .subtract(1, 'day')
            .endOf('day') ||
        dateList.some(range => {
          return (
            current_start >= moment(range.startTime).startOf('day') &&
            current_end <= moment(range.endTime).endOf('day')
          );
        })
      );
    } else {
      return false;
    }
  };

  handleCategoryIdChange = id => {
    const {
      form: { resetFields }
    } = this.props;
    this.getGiftList(id);
    // 禮物分類更換時，清除計分禮物
    resetFields(['npcs[0].giftIds', 'npcs[1].giftIds', 'npcs[2].giftIds']);
  };

  getGiftList = categoryId => {
    if (categoryId) {
      this.props.getGiftList({
        typeFilter: giftType.liveRoom.value,
        giftCategoryId: categoryId,
        statusFilter: 'all',
        item: 999,
        page: 1
      });
    }
  };

  render() {
    const {
      loading,
      updating,
      isNew,
      currentGiftBoxEvent,
      visible,
      onCancel,
      giftCategoryList,
      giftList,
      okText,
      titlePrefix,
      form: { getFieldDecorator, setFields, getFieldError }
    } = this.props;

    const { npcs, NPCSetting, canSubmit, isRandomLive } = this._getFormState();

    return (
      <Modal
        visible={visible}
        title={`${titlePrefix}互動式禮物盒活動`}
        width={990}
        loading={updating}
        onOk={this.handleOk}
        onCancel={onCancel}
        okText={okText}
        maskClosable={false}
        footer={[
          <Button key="btn-cancel" onClick={onCancel}>
            取消
          </Button>,
          <Button
            key="btn-ok"
            type={canSubmit ? 'primary' : 'default'}
            disabled={!canSubmit}
            onClick={this.handleOk}
          >
            {okText}
          </Button>
        ]}
      >
        {loading ? (
          <Spin spinning />
        ) : (
          <Spin spinning={updating}>
            <Form>
              <Row>
                <Col sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="活動名稱">
                    {getFieldDecorator('eventName', {
                      initialValue: pathOr(
                        null,
                        ['eventName'],
                        currentGiftBoxEvent
                      ),
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '此欄位必填'
                        }
                      ]
                    })(
                      <Input placeholder="請輸入活動名稱" disabled={!isNew} />
                    )}
                  </FormItem>
                </Col>
                <Col sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="活動模式">
                    {getFieldDecorator('type', {
                      initialValue: String(
                        pathOr(eventTypes.NORMAL, ['type'], currentGiftBoxEvent)
                      ),
                      ...(isNew
                        ? { rules: [{ required: true, message: '此欄位必填' }] }
                        : {})
                    })(
                      <Select
                        allowClear
                        disabled={!isNew}
                        placeholder="請選擇活動模式"
                        onChange={this.handleEventTypeChange}
                      >
                        <Select.Option value="4">固定直播間</Select.Option>
                        {/* <Select.Option value="5">隨機直播間</Select.Option> */}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="禮物分類">
                    {getFieldDecorator('giftCategoryId', {
                      initialValue:
                        currentGiftBoxEvent &&
                        currentGiftBoxEvent.giftCategoryId
                          ? String(currentGiftBoxEvent.giftCategoryId)
                          : undefined,
                      ...(isNew
                        ? { rules: [{ required: true, message: '此欄位必填' }] }
                        : {})
                    })(
                      <Select
                        allowClear
                        disabled={!isNew}
                        onChange={this.handleCategoryIdChange}
                        placeholder="請選擇禮物分類"
                      >
                        {giftCategoryList.map(x => (
                          <Select.Option key={x.id} value={String(x.id)}>
                            {x.categoryName}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="活動有效時間">
                    {getFieldDecorator('range', {
                      initialValue: currentGiftBoxEvent
                        ? [
                            moment(currentGiftBoxEvent.startTime),
                            moment(currentGiftBoxEvent.endTime)
                          ]
                        : null,
                      rules: [
                        { required: true, message: '此欄位必填' },
                        {
                          validator: this._validateDateRange
                        }
                      ]
                    })(
                      <RangePicker
                        disabled={!isNew}
                        format="YYYY-MM-DD HH:mm:ss"
                        className="w100"
                        showTime={{
                          defaultValue: [
                            moment('00:00:00', 'HH:mm:ss'),
                            moment('23:59:59', 'HH:mm:ss')
                          ]
                        }}
                        disabledDate={this.disabledDate}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={24}>
                  <FormItem {...formItemLayout} label="活動圖檔">
                    {getFieldDecorator('bannerHash', {
                      initialValue: isNew ? '' : currentGiftBoxEvent.banner,
                      rules: [{ required: true, message: '請上傳活動圖檔' }]
                    })(
                      isNew ? (
                        <UploadImage uploadUrl="/image/create/eventBanner" />
                      ) : (
                        <img
                          src={currentGiftBoxEvent.banner}
                          alt="preview banner img"
                          style={{ width: 200 }}
                        ></img>
                      )
                    )}
                  </FormItem>
                </Col>
              </Row>
              <TableContainer
                span={24}
                label={'關卡圖片及數據設定:'}
                style={{ borderTop: '1px solid #e8e8e8', paddingTop: 16 }}
              >
                <NPCSetting
                  setFields={setFields}
                  getFieldError={getFieldError}
                  formFieldDecorator={getFieldDecorator}
                  isEdit={!isNew}
                  giftList={giftList}
                  npcs={npcs}
                />
              </TableContainer>
              {isRandomLive && (
                <TableContainer span={15} label="爆擊率設定:">
                  <CriticalStrikeSetting
                    formFieldDecorator={getFieldDecorator}
                    data={slice(0, 1, npcs)}
                  />
                </TableContainer>
              )}
            </Form>
          </Spin>
        )}
      </Modal>
    );
  }
}

const RangePicker = DatePicker.RangePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 }
  }
};
