import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';

const Content = styled.p`
  margin: 0;
  white-space: pre-line;
  line-height: 1.75;
`;

class Bulletin extends React.PureComponent {
  static propTypes = {
    family: PropTypes.object.isRequired,
  };

  render() {
    const { family } = this.props;
    const topic = _.get(family, 'bulletin.topic');
    const content = _.get(family, 'bulletin.content');
    return (
      <Card
        title={topic}
      >
        <Content>{content}</Content>
      </Card>
    );
  }
}

export default Bulletin;
