import produce from 'immer';
import moment from 'moment';
import {
  always,
  assocPath,
  complement,
  compose,
  cond,
  evolve,
  indexBy,
  is,
  map as ramdaMap,
  prop,
  propEq,
  propSatisfies,
  replace
} from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  addAgencyMasterAPI,
  deleteAgencyMasterAPI,
  getAgencyMasterAPI,
  getAgencyMasterListAPI,
  replaceAgencyMasterContractAPI,
  setAgencyMasterAPI
} from '../apis';
import { contractStatus } from '../constants/agency';
import brandName from '../constants/brandName';
import { type } from '../constants/contract';
import downloadCSV from '../utils/downloadCSV';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { isReallyEmpty } from '../utils/mixin';
const isNotReallyEmpty = complement(isReallyEmpty);
const getExpiredRemark = cond([
  [complement(propEq)('contractStatus', 'expired'), always('')],
  [propSatisfies(isNotReallyEmpty, 'expiredRemark'), prop('expiredRemark')],
  [
    propSatisfies(isNotReallyEmpty, 'expiredFileUrl'),
    always('此合約已終止，請參考合約終止憑證檔案')
  ],
  [propSatisfies(isReallyEmpty, 'deletedAt'), always('合約到期未續約')]
]);
const contractStatusByID = indexBy(
  prop('id'),
  ramdaMap(evolve({ label: replace('合約', '') }), contractStatus)
);
/**Type */
const GET_AGENCY_MASTER_LIST = createRequestTypes('GET_AGENCY_MASTER_LIST');
const ADD_AGENCY_MASTER = createRequestTypes('ADD_AGENCY_MASTER');
const GET_AGENCY_MASTER = createRequestTypes('GET_AGENCY_MASTER');
const SET_AGENCY_MASTER = createRequestTypes('SET_AGENCY_MASTER');
const REPLACE_AGENCY_MASTER_CONTRACT = createRequestTypes(
  'REPLACE_AGENCY_MASTER_CONTRACT'
);
const DELETE_AGENCY_MASTER = createRequestTypes('DELETE_AGENCY_MASTER');
const SHOW_AGENCY_MASTER_MODAL = 'SHOW_AGENCY_MASTER_MODAL';

/**
 * Action Creator
 */
export const showModal = createAction(
  SHOW_AGENCY_MASTER_MODAL,
  (isShowModal = true) => isShowModal
);

export const getAgencyMasterList = createAction(
  GET_AGENCY_MASTER_LIST.REQUEST,
  (payload = {}) => ({ item: 20, page: 1, ...payload })
);
export const getAgencyMasterListSuccess = createAction(
  GET_AGENCY_MASTER_LIST.SUCCESS
);
export const getAgencyMasterListFailure = createAction(
  GET_AGENCY_MASTER_LIST.FAILURE
);
export const addAgencyMaster = createAction(
  ADD_AGENCY_MASTER.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback
  })
);
export const addAgencyMasterSuccess = createAction(ADD_AGENCY_MASTER.SUCCESS);
export const addAgencyMasterFailure = createAction(ADD_AGENCY_MASTER.FAILURE);
export const getAgencyMaster = createAction(GET_AGENCY_MASTER.REQUEST);
export const getAgencyMasterSuccess = createAction(GET_AGENCY_MASTER.SUCCESS);
export const getAgencyMasterFailure = createAction(GET_AGENCY_MASTER.FAILURE);
export const setAgencyMaster = createAction(
  SET_AGENCY_MASTER.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const setAgencyMasterSuccess = createAction(SET_AGENCY_MASTER.SUCCESS);
export const setAgencyMasterFailure = createAction(SET_AGENCY_MASTER.FAILURE);
export const deleteAgencyMaster = createAction(
  DELETE_AGENCY_MASTER.REQUEST,
  (payload, callbackAction, callback) => ({ payload, callbackAction, callback })
);
export const deleteAgencyMasterSuccess = createAction(
  DELETE_AGENCY_MASTER.SUCCESS
);
export const deleteAgencyMasterFailure = createAction(
  DELETE_AGENCY_MASTER.FAILURE
);
export const replaceAgencyMasterContract = createAction(
  REPLACE_AGENCY_MASTER_CONTRACT.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const replaceAgencyMasterContractSuccess = createAction(
  REPLACE_AGENCY_MASTER_CONTRACT.SUCCESS
);
export const replaceAgencyMasterContractFailure = createAction(
  REPLACE_AGENCY_MASTER_CONTRACT.FAILURE
);
/**
 * Epics
 */
export const getAgencyMasterListEpic = action$ =>
  action$.pipe(
    ofType(GET_AGENCY_MASTER_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getAgencyMasterListAPI(payload).pipe(
        map(getAgencyMasterListSuccess),
        catchRequestError(getAgencyMasterListFailure)
      )
    )
  );

export const addAgencyMasterEpic = action$ =>
  action$.pipe(
    ofType(ADD_AGENCY_MASTER.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      addAgencyMasterAPI(payload).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [addAgencyMasterSuccess(res), callbackAction]
            : [addAgencyMasterSuccess(res)];
        }),
        catchRequestError(addAgencyMasterFailure)
      )
    )
  );

export const getAgencyMasterEpic = action$ =>
  action$.pipe(
    ofType(GET_AGENCY_MASTER.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getAgencyMasterAPI(payload).pipe(
        map(res =>
          getAgencyMasterSuccess(assocPath(['data', 'id'], payload, res))
        ),
        catchRequestError(getAgencyMasterFailure)
      )
    )
  );

export const setAgencyMasterEpic = action$ =>
  action$.pipe(
    ofType(SET_AGENCY_MASTER.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction }) =>
      setAgencyMasterAPI(payload).pipe(
        mergeMap(res =>
          callbackAction
            ? [setAgencyMasterSuccess(res), callbackAction]
            : [setAgencyMasterSuccess(res)]
        ),
        catchRequestError(setAgencyMasterFailure)
      )
    )
  );

export const replaceAgencyMasterContractEpic = action$ =>
  action$.pipe(
    ofType(REPLACE_AGENCY_MASTER_CONTRACT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction }) =>
      replaceAgencyMasterContractAPI(payload).pipe(
        mergeMap(res =>
          callbackAction
            ? [replaceAgencyMasterContractSuccess(res), callbackAction]
            : [replaceAgencyMasterContractSuccess(res)]
        ),
        catchRequestError(replaceAgencyMasterContractFailure)
      )
    )
  );

export const deleteAgencyMasterEpic = action$ =>
  action$.pipe(
    ofType(DELETE_AGENCY_MASTER.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      deleteAgencyMasterAPI(payload).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [deleteAgencyMasterSuccess(res), callbackAction]
            : [deleteAgencyMasterSuccess(res)];
        }),
        catchRequestError(deleteAgencyMasterFailure)
      )
    )
  );

/**
 * 下載
 */
export const downloadAgencyMasterList = payload => {
  getAgencyMasterListAPI(payload).subscribe(response => {
    const data = response.data;
    const contractTypeByID = indexBy(prop('id'), type);
    downloadCSV(
      ramdaMap(
        compose(
          record => ({
            ...record,
            expiredRemark: getExpiredRemark(record)
          }),
          evolve({
            contractStartDate: value => moment.unix(value).format('YYYY-MM-DD'),
            contractEndDate: value => moment.unix(value).format('YYYY-MM-DD'),
            contractType: value => contractTypeByID[value].label,
            contractStatus: value => contractStatusByID[value].label,
            createdAt: value =>
              value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '',
            deletedAt: value =>
              value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : ''
          })
        ),
        data
      ),
      [
        'nickname',
        'loginId',
        'contractStartDate',
        'contractEndDate',
        'contractName',
        'contractType',
        'contractStatus',
        'createdAt',
        'deletedAt',
        'remark',
        'expiredRemark',
        'fileUrl',
        'expiredFileUrl'
      ],
      [
        '直播主暱稱',
        `直播主 ${brandName} ID`,
        '合約開始日期',
        '合約結束日期',
        '合約名稱',
        '合約類型',
        '合約狀態',
        '新增時間',
        '移除時間',
        '備註',
        '合約終止備註',
        '合約電子檔',
        '下載合約終止憑證'
      ]
    );
  });
};

/**
 * Reducer
 */
const initialState = {
  data: [],
  currentAgencyMaster: null,
  loading: false,
  isShowModal: false,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_AGENCY_MASTER_LIST.REQUEST,
      ADD_AGENCY_MASTER.REQUEST,
      SET_AGENCY_MASTER.REQUEST,
      REPLACE_AGENCY_MASTER_CONTRACT.REQUEST,
      DELETE_AGENCY_MASTER.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [GET_AGENCY_MASTER.REQUEST]: produce(draft => {
      draft.loading = true;
      draft.currentAgencyMaster = null;
    }),
    [GET_AGENCY_MASTER.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentAgencyMaster = payload.data;
    }),
    [GET_AGENCY_MASTER.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.currentAgencyMaster = null;
    }),
    [combineActions(
      ADD_AGENCY_MASTER.SUCCESS,
      SET_AGENCY_MASTER.SUCCESS,
      REPLACE_AGENCY_MASTER_CONTRACT.SUCCESS
    )]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [GET_AGENCY_MASTER_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
    }),
    [GET_AGENCY_MASTER_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_AGENCY_MASTER.FAILURE,
      SET_AGENCY_MASTER.FAILURE,
      REPLACE_AGENCY_MASTER_CONTRACT.FAILURE,
      DELETE_AGENCY_MASTER.SUCCESS,
      DELETE_AGENCY_MASTER.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_AGENCY_MASTER_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
