import { pick } from 'ramda';
import React from 'react';
import { Input } from 'antd';
import { Form } from '@ant-design/compatible';

const FormItem = Form.Item;

class EnquiryForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  getFormData = () => {
    const { form } = this.props;
    return form.getFieldsValue();
  };

  setFormData = (data) => {
    const { form } = this.props;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = pick(fields, data);
    form.setFieldsValue(values);
  };

  validateFields = callback => {
    const { form } = this.props;
    return form.validateFields(callback);
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
  };

  render() {
    const { form: { getFieldDecorator } } = this.props;
    return (
      <Form>
        <FormItem label="狀態">
          {getFieldDecorator('status', {
            rules: [{ required: true, message: '請輸入狀態' }]
          })(
            <Input id="stickerGroupId" placeholder="請輸入處理狀態" />
          )}
        </FormItem>
        {getFieldDecorator('id')(<Input type="hidden" />)}
      </Form>
    );
  }
}

export default Form.create()(EnquiryForm);
