import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty, mapObjIndexed, compose, evolve, path, isNil } from 'ramda';
import { uploadSFX, setContentTypeIsNull, createContentType } from '../../apis';
import {
  Button,
  Input,
  Select,
  Modal,
  Popconfirm,
  Row,
  Col,
  Radio,
  Upload,
  DatePicker
} from 'antd';
import {
  Form,
  Icon as LegacyIcon,
} from '@ant-design/compatible';

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const defaultThumbnailURL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAQAAADa613fAAAAa0lEQVR42u3PMREAAAgEIL9/NTtpBHcPGpCeeiEiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIpcFhw+zsW+S2UgAAAAASUVORK5CYII=';
const defaultPreviewURL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAAI4CAQAAACxAPgQAAAEBElEQVR42u3SAQkAAAgEMb9/NTtpDBG2CMelp+BMDIgBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEAOCATEgGBADYkAwIAYEA2JAMCAGBANiQDAgBgQDYkAwIAYEA2JAMCAGBANiQDAgBgQDYkAwIAYEA2JAMCAGBANiQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMCAbEgBjQgBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEAOCATEgBgQDYkAwIAYEA2JAMCAGBANiQDAgBgQDYkAwIAYEA2JAMCAGBANiQDAgBgQDYkAwIAYEA2JAMCAGxIBgQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMiAFFwIAYEAyIAcGAGBAMiAHBgBgQDIgBwYAYEAyIAcGAGBAMiAHBgBgQDIgBwYAYEAyIAcGAGBAMiAExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEANiQANiQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMCAbEgBgQDIgBwYAYEAyIAcGAGBAMiAHBgBgQDIgBwYAYEAyIAcGAGBAMiAHBgBgQDIgBwYAYEAyIAcGAGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAGNCAGxIBgQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMiAHBgBgQDIgBwYAYEAyIAcGAGBAMiAHBgBgQDIgBwYAYEAyIAcGAGBAMiAHBgBgQDIgBwYAYEAyIATEgGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA2JAETAgBgQDYkAwIAYEA2JAMCAGBANiQDAgBgQDYkAwIAYEA2JAMCAGBANiQDAgBgQDYkAwIAYEA2JADAgGxIBgQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAYEAMCAbEgGBADAgGxIBgQAwIBsSAGNCAGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAYEAOCATEgGBADggExIBgQA4IBMSAGBANiQDAgBgQDYkAwIAYEA2JAMCAGBANiQDAgBgQD8tMCmFX8zpz7Od8AAAAASUVORK5CYII=';
@Form.create({
  mapPropsToFields: compose(
    mapObjIndexed(value => Form.createFormField({ value: value })),
    evolve({
      startTime: e => (isEmpty(e) || isNil(e) ? moment.unix() : moment.unix(e)),
      endTime: e =>
        isEmpty(e) || isNil(e) ? moment.endOf('year').unix() : moment.unix(e)
    }),
    path(['currentEffect'])
  )
})
class AnimationEffectForm extends Component {
  static defaultProps = {
    loading: false,
    showModal: v => console.log(v)
  };

  constructor(props) {
    super(props);
    this.state = {
      prevCurrentEffectID: null,
      enableDate: false,
      requiredNote: false,
      bundleFileList: [],
      thumbnailFileList: [],
      previewFileList: [],
      thumbnailURL: defaultThumbnailURL,
      previewURL: defaultPreviewURL
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentEffect, isCreateMode } = nextProps;
    if (!isCreateMode && currentEffect.id !== prevState.prevCurrentEffectID) {
      return {
        bundleFileList: [
          {
            uid: -1,
            name: currentEffect.bundleUrl.split('/').slice(-1)[0],
            url: currentEffect.bundleUrl
          }
        ],
        thumbnailFileList: [
          {
            uid: -1,
            name: currentEffect.thumbnailUrl.split('/').slice(-1)[0],
            url: currentEffect.thumbnailUrl
          }
        ],
        previewFileList: [
          {
            uid: -1,
            name: currentEffect.previewUrl.split('/').slice(-1)[0],
            url: currentEffect.previewUrl
          }
        ],
        thumbnailURL: currentEffect.thumbnailUrl,
        previewURL: currentEffect.previewUrl,
        enableDate: currentEffect.duration === 'temporary',
        prevCurrentEffectID: currentEffect.id
      };
    }
    return null;
  }
  generateFormItem() {
    const {
      form: { resetFields, getFieldValue },
      types
    } = this.props;

    return [
      {
        key: 'name',
        label: '名稱',
        options: {
          rules: [
            { required: true, message: '請輸入名稱' },
            {
              validator: (rule, value, callback) => {
                // 計算中文字元長度
                if (value && value.replace(/[^\x00-\xff]/g, 'xx').length > 10) {
                  callback('名稱長度超過');
                }
                callback();
              }
            }
          ]
        },
        render: () => <Input size="large" />
      },
      {
        key: 'note',
        label: '啟動說明',
        options: {
          rules: [
            { required: this.state.requiredNote, message: '請輸入說明' },
            {
              validator: (rule, value, callback) => {
                // 計算中文字元長度
                if (value && value.replace(/[^\x00-\xff]/g, 'xx').length > 32) {
                  callback('說明文字長度超過');
                }
                callback();
              }
            }
          ]
        },
        render: () => <Input size="large" />
      },
      {
        key: 'type',
        label: '分類',
        options: {
          rules: [{ required: true, message: '請選擇分類' }]
        },
        render: () => (
          <Select onChange={this.handleTypeChange}>
            {types.map(el => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        )
      },
      {
        key: 'duration',
        label: '類型',
        options: {
          rules: [{ required: true, message: '請選擇類型' }]
        },
        render: () => (
          <RadioGroup onChange={this.handleDurationChange}>
            <Radio value={'forever'}>永久型</Radio>
            <Radio value={'temporary'}>暫時型</Radio>
          </RadioGroup>
        )
      },
      {
        key: 'startTime',
        label: '上架日期',
        options: {
          rules: [
            { required: this.state.enableDate, message: '請選擇上架日期' }
          ]
        },
        render: () => (
          <DatePicker
            size="large"
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            disabled={!this.state.enableDate}
            disabledDate={current =>
              current <
              moment()
                .subtract(1, 'day')
                .endOf('day')
            }
            onChange={() => resetFields(['endTime'])}
          />
        )
      },
      {
        key: 'endTime',
        label: '下架日期',
        options: {
          rules: [
            { required: this.state.enableDate, message: '請選擇下架日期' }
          ]
        },
        render: () => (
          <DatePicker
            size="large"
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
            disabled={!this.state.enableDate}
            disabledDate={current => {
              const formStartTime = getFieldValue('startTime')
                ? getFieldValue('startTime').clone()
                : moment();
              return current < formStartTime.subtract(1, 'day').endOf('day');
            }}
          />
        )
      },
      {
        key: 'bundleUrl',
        label: '上傳 Bundle 檔',
        options: {
          rules: [
            {
              required: true,
              message: '請上傳 Bundle 檔'
            }
          ],
          getValueFromEvent: this.getValueFromUploadData
        },
        render: () => (
          <Upload
            accept=".bundle"
            fileList={this.state.bundleFileList}
            onChange={this.handleBundleChange}
            customRequest={info => {
              const form = new FormData();
              form.append('file', info.file);
              form.append('type', 'bundle');
              setContentTypeIsNull();
              uploadSFX(form).subscribe(res => {
                info.onSuccess(res);
                createContentType('application/json;charset=UTF-8');
              });
            }}
          >
            <Button>
              <LegacyIcon type="upload" />
              上傳 Bundle 檔
            </Button>
          </Upload>
        )
      },
      {
        key: 'thumbnailUrl',
        label: '縮圖',
        options: {
          rules: [
            {
              required: true,
              message: '請上傳縮圖檔'
            }
          ],
          getValueFromEvent: this.getValueFromUploadData
        },
        render: () => (
          <Upload
            accept="image/*"
            fileList={this.state.thumbnailFileList}
            onChange={this.handleThumbnailChange}
            customRequest={info => {
              const form = new FormData();
              form.append('file', info.file);
              form.append('type', 'thumbnail');
              setContentTypeIsNull();
              uploadSFX(form).subscribe(res => {
                info.onSuccess(res);
                createContentType('application/json;charset=UTF-8');
              });
            }}
          >
            <Button>
              <LegacyIcon type="upload" />
              上傳圖片
            </Button>
          </Upload>
        )
      },
      {
        key: 'previewUrl',
        label: '預覽效果',
        options: {
          rules: [
            {
              required: true,
              message: '請上傳預覽圖檔'
            }
          ],
          getValueFromEvent: this.getValueFromUploadData
        },
        render: () => (
          <Upload
            accept="image/*"
            fileList={this.state.previewFileList}
            onChange={this.handlePreviewChange}
            customRequest={info => {
              const form = new FormData();
              form.append('file', info.file);
              form.append('type', 'preview');
              setContentTypeIsNull();
              uploadSFX(form).subscribe(res => {
                info.onSuccess(res);
                createContentType('application/json;charset=UTF-8');
              });
            }}
          >
            <Button>
              <LegacyIcon type="upload" />
              上傳圖片
            </Button>
          </Upload>
        )
      }
    ];
  }

  /**
   * 檔案上傳完後，回傳API提供的 url
   */
  getValueFromUploadData = ({ file }) => {
    if (file.status === 'done') {
      return file.response.data.url;
    } else if (file.status === 'removed') {
      return undefined;
    }
    return file;
  };

  /**
   * 限制檔案保持一個
   */
  handleBundleChange = ({ fileList }) => {
    this.setState({ bundleFileList: fileList.slice(-1) });
  };

  /**
   * 圖片
   * -上傳後，顯示圖片
   * -刪除顯示預設圖片
   */
  handleThumbnailChange = ({ file, fileList }) => {
    if (file.status === 'done') {
      this.setState({
        thumbnailURL: URL.createObjectURL(file.originFileObj)
      });
    } else if (file.status === 'removed') {
      this.setState({ thumbnailURL: defaultThumbnailURL });
    }

    this.setState({ thumbnailFileList: fileList.slice(-1) });
  };

  handlePreviewChange = ({ file, fileList }) => {
    if (file.status === 'done') {
      this.setState({
        previewURL: URL.createObjectURL(file.originFileObj)
      });
    } else if (file.status === 'removed') {
      this.setState({ previewURL: defaultPreviewURL });
    }

    this.setState({ previewFileList: fileList.slice(-1) });
  };

  /**
   * 貼紙、手勢、表情
   */
  handleTypeChange = value => {
    this.setState({
      requiredNote: value !== 'sticker'
    });
  };

  /**
   * 永久、暫時
   */
  handleDurationChange = event => {
    this.setState({
      enableDate: event.target.value === 'temporary'
    });
  };

  /**
   * Modal 關閉後 reset 資料
   */
  afterClose = () => {
    this.props.form.resetFields();
    this.setState({
      thumbnailURL: defaultThumbnailURL,
      previewURL: defaultPreviewURL,
      bundleFileList: [],
      thumbnailFileList: [],
      previewFileList: [],
      prevCurrentEffectID: null
    });
    this.props.setCurrentAnimationEffect({});
  };

  /**
   * Submit
   */
  handleSubmit = e => {
    e.preventDefault();
    const {
      addAnimationEffect,
      modifyAnimationEffect,
      isCreateMode,
      form
    } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const result = {
          ...values,
          startTime: this.state.enableDate
            ? values.startTime.unix()
            : moment().unix(),
          // prettier-ignore
          endTime: this.state.enableDate
            ? values.endTime.unix()
            : moment().endOf('year').unix()
        };
        isCreateMode
          ? addAnimationEffect(result)
          : modifyAnimationEffect(result);
      }
    });
  };

  render() {
    const {
      loading,
      isShowModal,
      showModal,
      isCreateMode,
      form: { getFieldDecorator, getFieldsError }
    } = this.props;
    const buttonText = isCreateMode ? '新增' : '更新';
    const hasErrors = fieldsError =>
      Object.keys(fieldsError).some(field => fieldsError[field]);

    return (
      <div>
        <Modal
          width={700}
          afterClose={this.afterClose}
          visible={isShowModal}
          footer={[
            <Popconfirm
              key={'confirm'}
              title={`是否確定${buttonText}`}
              placement="bottomLeft"
              onConfirm={this.handleSubmit}
            >
              <Button
                key={'add'}
                loading={loading}
                type="primary"
                disabled={hasErrors(getFieldsError())}
              >
                {buttonText}
              </Button>
            </Popconfirm>,
            <Button key={'cancle'} onClick={() => showModal(false)}>
              取消
            </Button>
          ]}
          closable={false}
          onOk={() => showModal(false)}
          onCancel={() => showModal(false)}
        >
          <Form>
            <Row gutter={16}>
              <Col span={12} className="gutter-row">
                <img src={this.state.previewURL} width="100%" alt="preview" />
                <div>
                  <img
                    src={this.state.thumbnailURL}
                    width="100"
                    alt="thumbnail"
                  />
                </div>
              </Col>
              <Col span={12} className="gutter-row">
                {this.generateFormItem().map(item => (
                  <FormItem key={item.key} label={item.label}>
                    {getFieldDecorator(item.key, item.options)(item.render())}
                  </FormItem>
                ))}
                {isCreateMode
                  ? null
                  : getFieldDecorator('id')(<Input type="hidden" />)}
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default AnimationEffectForm;
