import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { Button, Table, Select, Popconfirm } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import AnimationEffectForm from './AnimationEffectForm';
import {
  showModal,
  getAnimationEffectList,
  addAnimationEffect,
  setCurrentAnimationEffect,
  modifyAnimationEffect,
  deleteAnimationEffect
} from '../../reducers/animationEffectManagement';

const types = [
  { id: 'sticker', name: '魔法貼紙' },
  { id: 'gesture', name: '魔法手勢' },
  { id: 'face', name: '魔法表情' }
];
const { Option } = Select;
/**
 * set margin
 */
const ButtonsContainer = styled.div`
  margin: 10px 0;
  .mr8:not(:last-child) {
    margin-right: 8px;
  }
`;

/**
 * set padding
 */
const TableContainer = styled.div`
  padding-right: 16px;
  .ant-table-wrapper {
    background-color: rgb(255, 255, 255);
    border-radius: 4px 4px 0px 0px;
  }
  .ant-pagination {
    margin-right: 16px;
  }
`;

const mapStateToProps = state => ({
  loading: state.animationEffectManagement.loading,
  isShowModal: state.animationEffectManagement.isShowModal,
  effectList: state.animationEffectManagement.effectList,
  currentEffect: state.animationEffectManagement.currentEffect
});

const mapDispatchToProps = {
  showModal,
  getAnimationEffectList,
  addAnimationEffect,
  setCurrentAnimationEffect,
  modifyAnimationEffect,
  deleteAnimationEffect
};
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class AnimationEffectManagement extends Component {
  state = {
    selectedEffects: [],
    status: 'all',
    type: 'all'
  };

  componentDidMount() {
    //取得動效列表
    this.props.getAnimationEffectList();
  }

  /*
   * generate table column
   */
  generateTableColumn() {
    const tableColumns = [
      {
        title: '編號',
        dataIndex: 'id'
      },
      {
        title: '動效名稱',
        dataIndex: 'name'
      },
      {
        title: '動效期間',
        dataIndex: 'startTime',
        render: (value, row, index) => {
          if (row['duration'] !== 'forever') {
            return (
              <p>
                {moment.unix(value).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
                {moment.unix(row['endTime']).format('YYYY-MM-DD HH:mm:ss')}
              </p>
            );
          } else {
            return '永久';
          }
        }
      },
      {
        title: '動效縮圖',
        dataIndex: 'thumbnailUrl',
        render: (value, row, index) => {
          return <img src={value} width="50" alt="Preview" />;
        }
      },
      {
        title: '動效分類',
        dataIndex: 'type',
        render: (value, row, index) => {
          return types.filter(item => {
            return item.id === value;
          })[0]['name'];
        }
      }
    ];
    return tableColumns;
  }
  /**
   * 全部、啟用、停用
   */
  handleStatusChange = value => {
    this.setState({ status: value });
  };

  /**
   * 貼紙、手勢、表情
   */
  handleTypeChange = value => {
    this.setState({ type: value });
  };

  handleDelete = () => {
    this.props.deleteAnimationEffect(this.state.selectedEffects);
    this.setState({ selectedEffects: [] });
  };

  handleEdit = () => {
    const { setCurrentAnimationEffect, effectList, showModal } = this.props;
    const current = effectList.filter(
      item => item.id === this.state.selectedEffects[0]
    )[0];
    setCurrentAnimationEffect(current);
    showModal(true);
  };

  /**
   * 勾選列表中的動效
   */
  onSelectRowChange = selectedRowKeys => {
    this.setState({ selectedEffects: selectedRowKeys });
  };

  render() {
    const {
      loading,
      effectList,
      showModal,
      isShowModal,
      currentEffect,
      addAnimationEffect,
      modifyAnimationEffect,
      setCurrentAnimationEffect
    } = this.props;
    const { type, status, selectedEffects } = this.state;
    const resultData = effectList
      .filter(value => status === 'all' || value.enable.toString() === status)
      .filter(value => type === 'all' || value.type === type);
    const rowSelection = {
      selectedRowKeys: selectedEffects,
      onChange: this.onSelectRowChange
    };
    return (
      <div>
        <h1>{'活動管理 > 動效管理'}</h1>
        <ButtonsContainer>
          <Button className="mr8" onClick={() => showModal(true)}>
            新增
          </Button>
          <Button
            className="mr8"
            onClick={this.handleEdit}
            disabled={selectedEffects.length !== 1}
          >
            編輯
          </Button>
          狀態：
          <Select
            style={{ width: 120 }}
            className="mr8"
            value={status}
            onChange={this.handleStatusChange}
          >
            <Option key={'all'} value="all">
              全部
            </Option>
            <Option key={'1'} value="true">
              啟用中
            </Option>
            <Option key={'0'} value="false">
              停用中
            </Option>
          </Select>
          分類：
          <Select
            style={{ width: 120 }}
            className="mr8"
            value={type}
            onChange={this.handleTypeChange}
          >
            <Option key={'all'} value="all">
              全部
            </Option>
            {types.map(type => (
              <Option key={type.id} value={type.id}>
                {type.name}
              </Option>
            ))}
          </Select>
          <Popconfirm
            key={'confirm'}
            title="是否確定刪除"
            placement="bottomLeft"
            onConfirm={() => {
              this.handleDelete();
            }}
          >
            <Button
              className="mr8"
              key={'add'}
              loading={loading}
              type="danger"
              disabled={selectedEffects.length < 1}
            >
              刪除
            </Button>
          </Popconfirm>
        </ButtonsContainer>
        <TableContainer>
          <Table
            columns={this.generateTableColumn()}
            dataSource={resultData}
            rowSelection={rowSelection}
            loading={loading}
            rowKey="id"
          />
        </TableContainer>
        <AnimationEffectForm
          types={types}
          loading={loading}
          isShowModal={isShowModal}
          isCreateMode={isEmpty(currentEffect)}
          showModal={showModal}
          currentEffect={currentEffect}
          addAnimationEffect={addAnimationEffect}
          modifyAnimationEffect={modifyAnimationEffect}
          setCurrentAnimationEffect={setCurrentAnimationEffect}
        />
      </div>
    );
  }
}

export default AnimationEffectManagement;
