import _ from 'lodash';
import URI from 'urijs';
import React from 'react';
import styled from 'styled-components';
import { Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ZegoService from '../../services/zego';
import ZegoUtils from '../../services/zego/utils';
import { getUserInfoAPI, getLiveRoomInfoAPI } from '../../apis';
import LiveRoomContext from './LiveRoomContext';
import ChatRoom from './ChatRoom';
import LivePlayer from './FlvLivePlayer';
import GiftArena from './GiftArena';
import liveRoomRecall from '../../components/LiveRoomRecall';

class LiveRoom extends React.Component {
  constructor(props) {
    super(props);

    this.playerRef = React.createRef();
    this.GiftArenaRef = React.createRef();

    this.state = {
      isLoginRoom: false,
      isTimeout: false,
      isEnded: false,
      stream: {},
      master: {},
      room: {},
      roomID: _.get(props, 'match.params.id'),
      timeout: null,
    };
  }

  componentDidMount() {
    this.init();
    // * Wait for Web Zego socket service purchase
    // this.initZego();
  }

  componentWillUnmount() {
    // * Wait for Web Zego socket service purchase
    // this.destoryZego();
  }

  getContextValue = () => {
    const { detectIdentity } = this;
    const { room, master } = this.state;
    return {
      room,
      master,
      detectIdentity,
    };
  }

  detectIdentity = (uuid, roles) => {
    const { master } = this.state;
    if (uuid === master.id) {
      return 'liveMaster';
    }

    const role = _.first(roles);
    switch (role) {
      case 'ROLE_LIVE_CONTROLLER':
        return 'official';
      case 'ROLE_LIVE_CONTROLLER_JUNIOR':
        return 'manager';
      default:
        return 'default';
    }
  }

  initZego = async () => {
    this.zego = new ZegoService();

    const { zego } = this;
    const { roomID } = this.state;
    zego.on('roomStreamUpdate', this.onZegoStreamUpdate);
    const isLoginRoom = await zego.loginRoom(roomID);

    const timeout = setTimeout(() => {
      this.setState({ isTimeout: true });
    }, 10000);

    this.setState({ isLoginRoom, timeout });
  }

  destoryZego = () => {
    const { zego } = this;
    const { timeout } = this.state;

    clearTimeout(timeout);

    zego.off('roomStateUpdate');
    zego.off('roomStreamUpdate');
    zego.destory();

    this.setState({
      stream: {},
      isTimeout: false,
      timeout: null,
    });
  }

  init = async () => {
    await this.fetchLiveRoomInfo();
    await this.fetchMasterInfo();
  }

  fetchLiveRoomInfo = () => {
    const { roomID } = this.state;
    const iPromise = getLiveRoomInfoAPI(roomID).toPromise();
    iPromise.then((res) => {
      const { data } = res;
      this.setState({ room: data });
    });
    return iPromise;
  }

  fetchMasterInfo = () => {
    const { room } = this.state;
    const { liveMasterId: uuid } = room;
    const iPromise = getUserInfoAPI(uuid).toPromise();
    iPromise.then((res) => {
      this.setState({ master: res });
    });
    return iPromise;
  }

  interruptLiveRoom = () => {
    const { roomID } = this.state;
    liveRoomRecall(roomID);
  };

  getMediaSource = () => {
    const { room, roomID } = this.state;
    const { obs } = room;

    if (_.isEmpty(room)) return;

    let source = null;
    if (obs) {
      const flv = _.get(obs, 'pullUrlFlv');
      const uri = new URI(flv).protocol('http');
      source = uri.toString();
    } else {
      source = ZegoUtils.generateCDNUrl(roomID);
    }
    return source;
  }

  onZegoStreamUpdate = (roomId, updateType, streamList) => {
    const { timeout } = this.state;
    clearTimeout(timeout);

    if (updateType === 'DELETE') {
      this.destoryZego();
      this.setState({ isEnded: true });
      return;
    }

    // set stream data
    const stream = streamList[0];
    this.setState({ stream }, () => {
      // load stream and play
      const { playerRef } = this;
      const player = playerRef.current;
      player.play();
    });
  }

  onReceiveGiftPrompt = (message) => {
    const arena = this.GiftArenaRef.current;
    arena.push(message);
  }

  renderPlayer = () => {
    const { playerRef } = this;
    const source = this.getMediaSource();

    // * Wait for Web Zego socket service purchase
    // const { stream } = this.state;
    // const source = _.get(stream, 'urlsFLV');

    return (
      <div className="live-room-player-wrapper">
        <GiftArena
          ref={this.GiftArenaRef}
          className="live-room-gift-arena"
        />
        {source ? (
          <LivePlayer ref={playerRef} src={source} />
        ) : this.renderPlayerMessage()}
      </div>
    );
  }

  renderPlayerMessage = () => {
    const { isEnded, isLoginRoom, isTimeout } = this.state;
    let message;
    if (isEnded) {
      message = '直播已結束';
    } else if (!isLoginRoom) {
      message = '正在登入房間...';
    } else if (isLoginRoom && !isTimeout) {
      message = '正在取得串流資源...';
    } else {
      message = 'Error: 連線逾時';
    }
    return (
      <div className="live-room-message">{message}</div>
    );
  }

  renderControls = () => {
    const { master } = this.state;
    return (
      <div className="live-room-controls">
        <div className="live-room-master">
          <Avatar size={64} src={master.profilePicture}>
            <UserOutlined />
          </Avatar>
          <div className="live-room-user">
            <p>{master.nickname}</p>
            <span>ID：{master.loginId}</span>
          </div>
        </div>
        <div className="live-room-tools">
          <Button
            type="danger"
            ghost
            onClick={this.interruptLiveRoom}
          >
            <span>立即下架</span>
          </Button>
        </div>
      </div>
    );
  }

  renderChatRoom = () => {
    return (
      <ChatRoom
        onReceiveGiftPrompt={this.onReceiveGiftPrompt}
      />
    );
  }

  render() {
    const { className } = this.props;
    const ContextValue = this.getContextValue();

    return (
      <LiveRoomContext.Provider value={ContextValue}>
        <section className={className}>
          <div className="live-room-main">
            <div className="live-room-main-left">
              {this.renderPlayer()}
              {this.renderControls()}
            </div>
            <div className="live-room-main-right">
              {this.renderChatRoom()}
            </div>
          </div>
        </section>
      </LiveRoomContext.Provider>
    );
  }
}

const StyledLiveRoom = styled(LiveRoom)`
  padding: 1rem 1rem 1rem 0;

  .live-room-main {
    display: flex;
    overflow: hidden;
    min-width: 900px;
    user-select: none;
  }

  .live-room-main-right,
  .live-room-main-left {
    position: relative;
  }

  .live-room-main-left {
    flex-grow: 1;
  }

  .live-room-main-right {
    max-width: 400px;
    min-height: 450px;
    margin-left: 1rem;
    flex-basis: 40%;
    flex-shrink: 0;
    background: #FEFEFE;
    border-radius: 5px;
  }

  .live-room-player-wrapper {
    position: relative;
    padding-top: 56.25%;
    background-color: #212121;
    overflow: hidden;

    .live-player-wrapper {
      position: absolute;
      top: 0;
      left: 0;
    }

    .live-room-gift-arena {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }

    .live-room-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      color: #FAFAFA;
    }
  }

  .live-room-controls {
    margin-top: 1rem;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  .live-room-tools {
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  .live-room-master {
    display: flex;
    align-items: center;

    .ant-avatar {
      margin: 0 1rem;
    }

    .live-room-user {
      p {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }
`;

export default StyledLiveRoom;
