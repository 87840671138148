import React from 'react';
import PropTypes from 'prop-types';
import flvjs from 'flv.js';
import DPlayer from 'dplayer';
import styled from 'styled-components';

Object.assign(flvjs.LoggingControl, {
  enableAll: false,
  enableWarn: false,
  enableError: true,
});

const StyledDiv = styled.div`
  min-height: 300px;

  &, .live-player {
    width: 100%;
    height: 100%;
  }

  button {
    outline: none;
  }

  video {
    width: 100%;
    height: 100%;
  }
`;

class LivePlayer extends React.Component {
  static propTypes = {
    src: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.ContainerRef = React.createRef();

    this.state = {
    };
  }

  componentDidMount() {
    const { current: el } = this.ContainerRef;
    const { src } = this.props;

    this.player = new DPlayer({
      container: el,
      live: true,
      preload: 'auto',
      hotkey: true,
      screenshot: true,
      theme: '#1890FF',
      video: {
        customType: {
          customFlv: (video, player) => {
            this.createFlvPlayer(video);
          },
        },
      },
    });

    const { player } = this;
    player.on('play', this.startToLoadFLV);
    player.on('pause', this.stopToLoadFLV);

    this.updateSource(src);
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;

    if (src !== prevProps.src) {
      this.updateSource(src);
    }
  }

  componentWillUnmount() {
    this.destroyFlvPlayer();
    this.destroyDPlayer();
  }

  createFlvPlayer = (video) => {
    // destroy before
    this.destroyFlvPlayer();

    // create new flv player
    const flvPlayer = flvjs.createPlayer({
      type: 'flv',
      url: video.src,
      isLive: true,
    }, {
      enableStashBuffer: false,
      reuseRedirectedURL: true,
      autoCleanupSourceBuffer: true,
    });
    flvPlayer.attachMediaElement(video);
    this.flvPlayer = flvPlayer;
  }

  destroyDPlayer = () => {
    const { player } = this;
    player.destroy();
  }

  destroyFlvPlayer = () => {
    const { flvPlayer } = this;
    if (!flvPlayer) return;

    flvPlayer.unload();
    flvPlayer.detachMediaElement();
    flvPlayer.destroy();
  }

  play = () => {
    const { player } = this;
    player.play();
  }

  startToLoadFLV = () => {
    const { flvPlayer } = this;
    flvPlayer.load();
  }

  stopToLoadFLV = () => {
    const { flvPlayer } = this;
    flvPlayer.unload();
  }

  updateSource = (source) => {
    const { player } = this;
    player.switchVideo({ url: source, type: 'customFlv' });
  }

  render() {
    return (
      <StyledDiv
        ref={this.ContainerRef}
        className="live-player-wrapper"
      />
    );
  }
}

export default LivePlayer;
