import { Image, Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContinuityEventList } from '../../reducers/signinEventManagement';

const columns = [
  {
    title: '天數',
    dataIndex: 'days'
  },
  {
    title: 'ID',
    dataIndex: ['gift', 'id']
  },
  {
    title: '禮物名稱',
    dataIndex: ['gift', 'name']
  },
  {
    title: '禮物縮圖',
    dataIndex: ['gift', 'thumbUrl'],
    render: value => <Image width={48} src={value} />
  },
  {
    title: '點數',
    dataIndex: ['gift', 'points']
  }
];
const ContinuityEventManagement = props => {
  const dispatch = useDispatch();
  const { continuityEventList, loading } = useSelector(
    state => state.signinEventManagement
  );

  useEffect(() => {
    dispatch(getContinuityEventList());
  }, [dispatch]);

  const pagination = {
    showSizeChanger: false,
    pageSize: 20
  };

  return (
    <Table
      rowKey="id"
      columns={columns}
      loading={loading}
      dataSource={continuityEventList}
      pagination={pagination}
    />
  );
};
export default ContinuityEventManagement;
