import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import themes from '../utils/themes';

class GiftPrompt extends React.Component {

  static propTypes = {
    message: PropTypes.object,
  };

  static defaultProps = {
    message: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      sender: null,
      recipient: null,
      content: null,
    };
  }

  componentDidMount() {
    this.setData();
  }

  setData = () => {
    const { message } = this.props;
    const propContent = _.get(message, 'data.content');
    const sender =  _.get(message, 'data.userName');
    const result = new RegExp('^.*(送了.*給)(.+)').exec(propContent);
    const content = _.trim(result[1]);
    const recipient = _.trim(result[2]);
    this.setState({ sender, content, recipient });
  }

  renderUserLevel = () => {
    const { message } = this.props;
    const levelId = _.get(message, 'data.userLevel.levelId');
    const levelNum = _.get(message, 'data.userLevel.levelNum');
    const levelTheme = themes.userLevel[levelId];
    const style = { color: levelTheme.number };
    return (
      <div className="user-level" style={style}>
        <img src={levelTheme.icon} alt={levelId}/>
        <span>{levelNum}</span>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { sender, recipient, content} = this.state;
    return (
      <div className={className}>
        {this.renderUserLevel()}
        <span className="username">{sender}</span>
        <span className="content">{content}</span>
        <span className="username">{recipient}</span>
      </div>
    );
  }
}

const StyledGiftPrompt = styled(GiftPrompt)`
  padding: 0.25rem;
  line-height: 1;
  border-radius: 0.313rem;
  word-break: break-all;
  background-color: ${themes.giftPrompt.background};

  & > * {
    vertical-align: middle;
  }

  .username {
    color: ${themes.username};
  }

  .content {
    color: ${themes.content};
  }

  .user-level {
    margin-right: 0.25rem;
    padding: 0.2rem 0.4rem 0.2rem 0.2rem;
    display: inline-block;
    align-items: center;
    border-radius: inherit;
    background-color: rgba(34, 34, 34, 0.2);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;

    * {
      vertical-align: middle;
    }

    img {
      height: 1rem;
      margin-right: 0.1rem;
    }
  }
`;

export default StyledGiftPrompt;
