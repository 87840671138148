import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Image, Row, Select, Table } from 'antd';
import moment from 'moment';
import {
  always,
  converge,
  evolve,
  identity,
  ifElse,
  isEmpty,
  isNil,
  map,
  mergeRight,
  omit,
  pipe,
  prop,
  zipObj
} from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import { PageContainer, PageTitle } from '../../components/styled/page';
import { giftType } from '../../constants/gifts';
import { useHasErrors } from '../../hooks/useHasErrors';
import { getGiftListCategory } from '../../reducers/giftListCategories';
import {
  getGiftGiverRanking,
  getLiveMasterRanking
} from '../../reducers/queryRankingList';
import downloadCSV from '../../utils/downloadCSV';
import TagPicker from './TagPicker';
const FormItem = Form.Item;
const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;
const defaultTags = [];
const getTagsId = evolve({ tags: map(prop('id')) });
const getDates = pipe(
  prop('dates'),
  ifElse(
    isNil,
    always({}),
    pipe(
      map(v => v.format('YYYY-MM-DD hh:mm:ss')),
      zipObj(['startTime', 'endTime'])
    )
  )
);
const getSubmitData = converge(
  pipe(mergeRight, getTagsId, omit(['dates', 'apiType'])),
  [identity, getDates]
);
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};
const apiByType = {
  liveMaster: getLiveMasterRanking,
  giftGiver: getGiftGiverRanking
};
const columnByType = {
  liveMaster: [
    { title: 'fansVariation', dataIndex: 'fansVariation' },
    { title: 'frameUrl', dataIndex: 'frameUrl' },
    { title: 'giftCount', dataIndex: 'giftCount' },
    {
      title: 'isLiveMaster',
      dataIndex: 'isLiveMaster',
      render: value => (value === true ? 'true' : 'false')
    },
    { title: 'levelId', dataIndex: 'levelId' },
    { title: 'levelNum', dataIndex: 'levelNum' },
    { title: 'liveTimeSec', dataIndex: 'liveTimeSec' },
    { title: 'liveTimeSum', dataIndex: 'liveTimeSum' },
    { title: 'nickname', dataIndex: 'nickname' },
    { title: 'points', dataIndex: 'points' },
    {
      title: 'profilePicture',
      dataIndex: 'profilePicture',
      render: src => <Image width={60} src={src} />
    },
    {
      title: 'profileThumbPicture',
      dataIndex: 'profileThumbPicture',
      render: src => <Image width={60} src={src} />
    },
    { title: 'ranking', dataIndex: 'ranking' },
    { title: 'userId', dataIndex: 'userId' }
  ],
  giftGiver: [
    { title: 'frameUrl', dataIndex: 'frameUrl' },
    { title: 'giftCount', dataIndex: 'giftCount' },
    {
      title: 'isTrackable',
      dataIndex: 'isTrackable',
      render: value => (value === true ? 'true' : 'false')
    },
    { title: 'levelId', dataIndex: 'levelId' },
    { title: 'levelNum', dataIndex: 'levelNum' },
    { title: 'nickname', dataIndex: 'nickname' },
    { title: 'points', dataIndex: 'points' },
    {
      title: 'profilePicture',
      dataIndex: 'profilePicture',
      render: src => <Image width={60} src={src} />
    },
    {
      title: 'profileThumbPicture',
      dataIndex: 'profileThumbPicture',
      render: src => <Image width={60} src={src} />
    },
    { title: 'ranking', dataIndex: 'ranking' },
    { title: 'userId', dataIndex: 'userId' }
  ]
};

const QueryRankingList = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const {
    loading,
    loadGiftCategory,
    giftGiverRanking,
    liveMasterRanking,
    giftCategoryList
  } = useSelector(({ giftListCategories, queryRankingList }) => ({
    liveMasterRanking: queryRankingList.liveMasterRanking,
    giftGiverRanking: queryRankingList.giftGiverRanking,
    loading: queryRankingList.loading,
    giftCategoryList: giftListCategories.data,
    loadGiftCategory: giftListCategories.loading
  }));
  const [columns, setColumns] = useState([]);
  const [apiType, setApiType] = useState();

  const onGiftTypeChange = type => {
    form.resetFields(['giftCategory']);
    dispatch(
      getGiftListCategory({
        typeFilter: type,
        statusFilter: 'all',
        item: 9999,
        page: 1
      })
    );
  };

  const onSubmit = values => {
    setColumns(columnByType[values.apiType]);
    dispatch(
      apiByType[values.apiType]({
        ...getSubmitData(values),
        item: 999,
        page: 1
      })
    );
    setApiType(values.apiType);
  };

  const onReset = () => {
    form.resetFields();
    setApiType(null);
    setColumns([]);
    setHasError(false);
  };

  const onDownloadClick = () => {
    downloadCSV(getData());
  };

  const pagination = {
    showSizeChanger: false,
    pageSize: 20
  };

  const getData = () => {
    if (apiType === 'liveMaster') {
      return liveMasterRanking;
    } else if (apiType === 'giftGiver') {
      return giftGiverRanking;
    } else {
      return [];
    }
  };
  const data = getData();

  return (
    <PageContainer>
      <PageTitle>{'活動管理 > 榜單查詢'}</PageTitle>
      <Form
        {...formItemLayout}
        form={form}
        onFinish={onSubmit}
        onFieldsChange={validateStatus}
      >
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              name="apiType"
              label="榜單種類"
              rules={[{ required: true, message: '請選擇榜單' }]}
            >
              <Select
                options={[
                  {
                    value: 'liveMaster',
                    label: '收禮榜'
                  },
                  {
                    value: 'giftGiver',
                    label: '送禮榜'
                  }
                ]}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="dates"
              label="查詢區間"
              rules={[{ required: true, message: '請輸入日期' }]}
            >
              <RangePicker
                style={{ width: '100%' }}
                showTime={{
                  defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'),
                    moment('23:59:59', 'HH:mm:ss')
                  ]
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              name="giftType"
              label="限定禮物類型"
              rules={[{ required: false, message: '請選擇禮物類型' }]}
            >
              <Select
                allowClear
                placeholder="不限定"
                onChange={onGiftTypeChange}
                options={[
                  {
                    value: giftType.liveRoom.value,
                    label: '一般直播間'
                  },
                  {
                    value: giftType.voiceChat.value,
                    label: '聲聊房'
                  }
                ]}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="giftCategory"
              label="限定禮物分類"
              rules={[{ required: false, message: '請選擇禮物分類' }]}
            >
              <Select
                loading={loadGiftCategory}
                allowClear
                placeholder="不限定"
                disabled={
                  isNil(form.getFieldValue('giftType')) || loadGiftCategory
                }
              >
                {giftCategoryList.map(category => (
                  <Option key={category.id} value={category.id}>
                    {category.categoryName}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              name="tags"
              label="限定直播主標籤"
              initialValue={defaultTags}
            >
              <TagPicker />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="liveMasterId" label="限定直播主 ID">
              <LiveMasterSelect typeFilter="login_id" mode="multiple" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex="auto">
            <Button onClick={onDownloadClick} disabled={isEmpty(data)}>
              下載 CSV
            </Button>
          </Col>
          <Col flex="120px">
            <FormItem style={{ width: '100%' }} wrapperCol={24} shouldUpdate>
              {() => {
                return (
                  <Button
                    block
                    onClick={onReset}
                    disabled={!form.isFieldsTouched()}
                  >
                    重置條件
                  </Button>
                );
              }}
            </FormItem>
          </Col>
          <Col flex="120px">
            <FormItem style={{ width: '100%' }} wrapperCol={24}>
              <Button
                block
                icon={<SearchOutlined />}
                type="primary"
                htmlType="submit"
                disabled={hasErrors}
              >
                搜尋
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Table
        rowKey="userId"
        columns={columns}
        loading={loading}
        dataSource={data}
        scroll={{ x: true }}
        pagination={pagination}
      />
    </PageContainer>
  );
};
export default QueryRankingList;
