import React, { PureComponent } from 'react';
import { Button, Modal } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';

class LightVideoBox extends PureComponent {
  static VideoModal = ({ visible, src, onCancel }) => {
    return (
      <Modal
        visible={visible}
        destroyOnClose={true}
        footer={null}
        width={'80%'}
        onCancel={onCancel}
        style={{ maxWidth: '600px', top: '50px' }}
        bodyStyle={{
          padding: '8px',
          minHeight: '300px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <video
          src={src}
          muted
          controls
          style={{
            width: '100%',
            height: 'calc(100% - 100px)'
          }}
        ></video>
      </Modal>
    );
  };

  state = {
    visible: false
  };

  render() {
    const { visible } = this.state;
    const { src } = this.props;

    return (
      <div>
        <Button
          disabled={!src}
          onClick={() => this.setState({ visible: true })}
        >
          <LegacyIcon type="play-circle" />
          顯示影片
        </Button>
        <LightVideoBox.VideoModal
          visible={visible}
          src={src}
          onCancel={() => this.setState({ visible: false })}
        />
      </div>
    );
  }
}

export default LightVideoBox;
