import { Modal } from 'antd';
import { deleteForbiddenAPI } from '../../apis';

const { confirm } = Modal;

function removeForbidden(id, onCompleted) {
  confirm({
    title: '刪除禁詞',
    content: '禁詞刪除後，將無法復原，確定要刪除？',
    okType: 'danger',
    centered: true,
    maskClosable: true,
    onOk() {
      const observable = deleteForbiddenAPI(id);
      observable.subscribe(onCompleted);
    }
  });
}

export default removeForbidden;
