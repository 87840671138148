import { pipe } from 'ramda';
import moment from 'moment';
import { switchMap, map } from 'rxjs/operators';
import { createAction, handleActions } from 'redux-actions';
import { ofType, catchRequestError } from '../utils/extendOperators';
import { getLiveViewerListAPI } from '../apis';
import { dateOptions } from '../constants/moments.js';

/**
 * Action Types
 */
const GET_LIVE_VIEWER_LIST = 'GET_LIVE_VIEWER_LIST';
const GET_LIVE_VIEWER_LIST_SUCCESS = 'GET_LIVE_VIEWER_LIST_SUCCESS';
const GET_LIVE_VIEWER_LIST_FAILURE = 'GET_LIVE_VIEWER_LIST_FAILURE';

/**
 * Action Creator
 */
export const getLiveViewerList = (
  startDate = +dateOptions.month,
  endDate = moment().valueOf(),
  keyword = '',
  isCustomize = false
) =>
  createAction(GET_LIVE_VIEWER_LIST)({
    startDate: +startDate,
    endDate,
    offsetInMillis: 28800000,
    keyword,
    isCustomize
  });

const getLiveViewerListSuccess = createAction(GET_LIVE_VIEWER_LIST_SUCCESS);
const getLiveViewerListFailure = createAction(GET_LIVE_VIEWER_LIST_FAILURE);

/**
 * Epics
 */
export const getLiveViewerListEpic = pipe(
  ofType(GET_LIVE_VIEWER_LIST),
  map(action => action.payload),
  switchMap(payload =>
    getLiveViewerListAPI(payload).pipe(
      map(getLiveViewerListSuccess),
      catchRequestError(getLiveViewerListFailure)
    )
  )
);

/**
 * Reducer
 */
const initialState = {
  liveViewerlist: [],
  startDate: dateOptions.week,
  endDate: moment()
    .endOf('day')
    .valueOf(),
  isCustomize: false
};

export default handleActions(
  {
    [GET_LIVE_VIEWER_LIST]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [GET_LIVE_VIEWER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      liveViewerlist: action.payload.data
    })
  },
  initialState
);
