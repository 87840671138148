import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Input, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import InputI18 from '../../components/InputI18';

const StyledTabRow = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  user-select: none;
  cursor: move;

  &.sortable-ghost {
    opacity: 0.5;
  }

  &.sortable-drag {
    opacity: 0.5;
  }

  .ranking-row-index {
    position: relative;
    width: 3rem;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
  }

  .ranking-row-field {
    padding: 0.5rem;
    text-align: center;

    &.ranking-row-name {
      flex-grow: 1;
    }

    &.ranking-row-setting {
      flex-grow: 3;
    }
  }

  .ranking-row-tools {
    button {
      border: 0;
      background: none;
      box-shadow: none;
      outline: 0;

      i {
        font-size: 1rem;
      }

      &::after {
        display: none;
      }

      &.delete {
        &:hover,
        &:focus {
          color: #ff4d4f;
        }
      }
    }
  }
`;

class TabRow extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    rowData: PropTypes.object.isRequired,
    onDelete: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  updateField = (field, value) => {
    const { rowData } = this.props;
    rowData[field] = value;
  };

  renderNameField = () => {
    const { rowData } = this.props;
    const { names } = rowData.i18nContents;

    return (
      <div className="ranking-row-field ranking-row-name">
        <InputI18
          defaultValue={names}
          onChange={value => {
            this.updateField('i18nContents', { names: value });
            // 目前 API name 這個欄位是必填，所以還是要帶入值
            this.updateField('name', value?.zh_TW);
          }}
        />
      </div>
    );
  };

  renderSettingField = () => {
    const { rowData } = this.props;
    const { uri } = rowData;

    return (
      <div className="ranking-row-field ranking-row-setting">
        <Input
          defaultValue={uri}
          onChange={event => {
            const { value } = event.target;
            this.updateField('uri', value);
          }}
        />
      </div>
    );
  };

  render() {
    const { index, onDelete } = this.props;

    return (
      <StyledTabRow>
        <Tooltip placement="top" title="排序">
          <div className="ranking-row-index">#{index + 1}</div>
        </Tooltip>
        <Tooltip placement="top" title="標題" zIndex={999}>
          {this.renderNameField()}
        </Tooltip>
        <Tooltip placement="top" title="Setting">
          {this.renderSettingField()}
        </Tooltip>
        <div className="ranking-row-tools">
          <Tooltip placement="top" title="刪除">
            <Button
              className="delete"
              onClick={() => onDelete(index)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </div>
      </StyledTabRow>
    );
  }
}

export default TabRow;
