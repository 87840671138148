import { evolve, isNil, pipe } from 'ramda';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Form, Input, Select, Switch } from 'antd';
import LiveMasterSelect from '../../../../components/LiveMasterSelect';
import UploadImg from '../../../../components/UploadImg';
import copyValueFromTo from '../../../../utils/copyValueFromTo';

const { Option } = Select;

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 16
  }
};
const mapDataToForm = pipe(
  evolve(
    { code: value => (isNil(value) ? '' : value) } //目前 api 只可空字串，之後如果修正在刪除此行
  ),
  copyValueFromTo(['roomStatus'], ['status'])
);
const VoiceChatForm = forwardRef(
  ({ typeList, giftCategoryList, mode }, ref) => {
    const [form] = Form.useForm();

    useImperativeHandle(
      ref,
      () => {
        const getFieldValue = field => {
          return form.getFieldValue(field);
        };

        const setFormData = data => {
          const values = mapDataToForm(data);
          form.setFieldsValue(values);
        };

        const validateFields = () => {
          return form.validateFields();
        };

        const reset = () => {
          form.resetFields();
        };

        return {
          getFieldValue,
          setFormData,
          validateFields,
          reset
        };
      },
      [form]
    );
    const isEditMode = () => {
      return mode === 'edit';
    };

    return (
      <Form form={form} {...formItemLayout}>
        <FormItem
          label="聲聊房類型"
          name="typeId"
          rules={[{ required: true, message: '請選擇聲聊房類型' }]}
        >
          <Select>
            {typeList.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="聲聊房主"
          name="masterId"
          rules={[{ required: true, message: '請選擇聲聊房主' }]}
        >
          <LiveMasterSelect roleFilter="ROLE_VOICE_MASTER" />
        </FormItem>
        <FormItem
          label="聲聊房標題"
          name="title"
          rules={[{ required: true, message: '請輸入聲聊房標題' }]}
        >
          <Input id="title" placeholder="聲聊房標題" />
        </FormItem>
        <FormItem
          label="通關碼"
          name="code"
          initialValue={''}
          rules={[
            {
              validator: (rule, value) => {
                if (!/^\d*$/.test(value)) {
                  return Promise.reject('通關碼請輸入數字');
                } else if ((value && value.length < 2) || value.length > 6) {
                  return Promise.reject('通關碼長度請輸入在 2 ~ 6 碼');
                } else {
                  return Promise.resolve();
                }
              }
            }
          ]}
        >
          <Input id="code" placeholder="通關碼" />
        </FormItem>
        <FormItem label="聲聊房簡介" name="description">
          <Input id="description" placeholder="聲聊房簡介" />
        </FormItem>
        <FormItem label="專屬禮物類別" name="giftCategoryId" initialValue={[]}>
          <Select mode="multiple" placeholder="禮物類別">
            {giftCategoryList.map(item => (
              <Option key={item.id} value={item.id}>
                {item.categoryName}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="啟用"
          name="status"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch id="status" />
        </FormItem>
        <FormItem
          label="官方"
          name="isOfficial"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch id="isOfficial" />
        </FormItem>
        <FormItem
          label="首頁 Banner"
          shouldUpdate={(prevValues, curValues) =>
            prevValues.isOfficial !== curValues.isOfficial
          }
        >
          {({ getFieldValue }) => (
            <FormItem
              name="bannerImgUrl"
              shouldUpdate={(prevValues, curValues) =>
                prevValues.isOfficial !== curValues.isOfficial
              }
            >
              <UploadImg
                accept="image/*"
                uploadUrl="/cdn/backend/photo"
                disabled={!getFieldValue('isOfficial')}
              />
            </FormItem>
          )}
        </FormItem>
        {isEditMode() && (
          <FormItem name="id" hidden>
            <Input type="hidden" />
          </FormItem>
        )}
      </Form>
    );
  }
);

VoiceChatForm.defaultProps = {
  typeList: [],
  giftCategoryList: []
};

export default VoiceChatForm;
