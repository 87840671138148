export const typeList = [
  { id: 'EVENT', label: '獎勵特效' },
  { id: 'SFX', label: '濾鏡特效' },
  { id: 'BUBBLE', label: '留言框特效' }
];
export const sfxTypeList = [
  { id: 'TEMPORARY', label: '發送型' },
  { id: 'FOREVER', label: '通用型' }
];
export const sfxSubTypeList = [
  { id: 'PACKAGE', label: '' },
  { id: 'STICKER', label: '魔法貼紙' },
  { id: 'GESTURE', label: '魔法手勢' },
  { id: 'FACESHOW', label: '魔法表情' }
];
export const debutLevel = {
  3000: '小',
  4500: '中',
  6000: '大',
  7000: '特大'
};
