import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Modal, Table } from 'antd';
import { getReportedPostsAPI } from '../../apis';
import { dateOptions } from '../../constants/moments';
import { PageTitle, PageContainer } from '../../components/styled/page';

const { Column } = Table;
const RangePicker = DatePicker.RangePicker;

const Filters = styled.div`
  margin-bottom: 1rem;
`;

class ReportedPosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoReportList: [],
      currentStartDate: dateOptions.month,
      currentEndDate: moment().endOf('day').valueOf(),
    };
  }

  componentDidMount() {
    this.fetchReportedPosts();
  }

  fetchReportedPosts = () => {
    const { currentStartDate, currentEndDate } = this.state;
    const obserable = getReportedPostsAPI({
      startDate: currentStartDate,
      endDate: currentEndDate,
      offsetInMillis: 28800000,
      page: 0,
      size: 10,
    });
    obserable.subscribe((response) => {
      const { photoReportList } = response;
      this.setState({ photoReportList });
    });
  }

  onDatePickChange = (date) => {
    this.setState({
      currentStartDate: date[0].valueOf(),
      currentEndDate: date[1].valueOf()
    }, this.fetchReportedPosts);
  };

  renderTime = (time) => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    return time && moment(time).format(format);
  }

  renderPhoto = (rowData) => {
    const photoPath = _.get(rowData, 'photoInfo.photoPath');
    return photoPath && (
      <Button
        onClick={() => {
          Modal.info({
            title: '被檢舉圖片',
            width: '80%',
            content: (
              <img
                alt="貼文圖片"
                src={photoPath}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            )
          });
        }}
      >
        <span>顯示圖片</span>
      </Button>
    );
  }

  render() {
    const { photoReportList } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'直播管理 > 被檢舉貼文'}</PageTitle>
        <Filters>
          <RangePicker
            format="YYYY/MM/DD HH:mm:ss"
            showTime={true}
            value={[
              moment(+this.state.currentStartDate),
              moment(+this.state.currentEndDate)
            ]}
            onChange={this.onDatePickChange}
            allowClear={false}
          />
        </Filters>
        <Table
          rowKey="id"
          dataSource={photoReportList}
          pagination={false}
        >
          <Column
            title="發佈帳號"
            key="account"
            dataIndex={['reportOwner', 'loginId']}
          />
          <Column
            title="發佈時間"
            key="createAt"
            dataIndex="createAt"
            render={this.renderTime}
          />
          <Column
            title="圖片"
            key="photo"
            render={this.renderPhoto}
          />
          <Column
            title="貼文"
            key="content"
            dataIndex={['photoInfo', 'content']}
          />
          <Column
            title="檢舉理由"
            key="reason"
            dataIndex="reason"
          />
        </Table>
      </PageContainer>
    );
  }
}

export default ReportedPosts;
