import mime from 'mime-types';

export const isImage = (media) => {
  const type = mime.lookup(media);
  if (!type) return false;

  const category = type.split('/')[0];
  return category === 'image';
};

export const isVideo = (media) => {
  const type = mime.lookup(media);
  if (!type) return false;

  const category = type.split('/')[0];
  return category === 'video';
};
