import { message } from 'antd';
import produce from 'immer';
import { groupBy, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, pluck, switchMap, map } from 'rxjs/operators';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { createRequestTypes } from '../actions/Types';
import {
  addBlocklistAPI,
  deleteBlocklistAPI,
  getBlocklistListAPI,
  setBlocklistAPI
} from '../apis';

/**Type */
const GET_BLOCKLIST_LIST = createRequestTypes('GET_BLOCKLIST_LIST');
const ADD_BLOCKLIST = createRequestTypes('ADD_BLOCKLIST');
const SET_BLOCKLIST = createRequestTypes('SET_BLOCKLIST');
const DELETE_BLOCKLIST = createRequestTypes('DELETE_BLOCKLIST');
const SHOW_BLOCKLIST_MODAL = 'SHOW_BLOCKLIST_MODAL';

/**
 * Action Creator
 */
export const getBlocklistList = createAction(GET_BLOCKLIST_LIST.REQUEST);
export const getBlocklistListSuccess = createAction(GET_BLOCKLIST_LIST.SUCCESS);
export const getBlocklistListFailure = createAction(GET_BLOCKLIST_LIST.FAILURE);
export const showModal = createAction(
  SHOW_BLOCKLIST_MODAL,
  (isShowModal = true) => isShowModal
);
export const addBlocklist = createAction(ADD_BLOCKLIST.REQUEST);
export const addBlocklistSuccess = createAction(ADD_BLOCKLIST.SUCCESS);
export const addBlocklistFailure = createAction(ADD_BLOCKLIST.FAILURE);
export const setBlocklist = createAction(SET_BLOCKLIST.REQUEST);
export const setBlocklistSuccess = createAction(SET_BLOCKLIST.SUCCESS);
export const setBlocklistFailure = createAction(SET_BLOCKLIST.FAILURE);
export const deleteBlocklist = createAction(
  DELETE_BLOCKLIST.REQUEST,
  (ids = []) => ids
);
export const deleteBlocklistSuccess = createAction(DELETE_BLOCKLIST.SUCCESS);
export const deleteBlocklistFailure = createAction(DELETE_BLOCKLIST.FAILURE);

/**
 * Epics
 */
export const getBlocklistListEpic = action$ =>
  action$.pipe(
    ofType(GET_BLOCKLIST_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getBlocklistListAPI().pipe(
        map(getBlocklistListSuccess),
        catchRequestError(getBlocklistListFailure)
      )
    )
  );

export const addBlocklistEpic = action$ =>
  action$.pipe(
    ofType(ADD_BLOCKLIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      addBlocklistAPI(payload).pipe(
        mergeMap(res => [addBlocklistSuccess(res), getBlocklistList()]),
        catchRequestError(addBlocklistFailure)
      )
    )
  );

export const setBlocklistEpic = action$ =>
  action$.pipe(
    ofType(SET_BLOCKLIST.REQUEST),
    pluck('payload'),
    mergeMap(payload =>
      setBlocklistAPI(payload).pipe(
        mergeMap(res => [setBlocklistSuccess(res), getBlocklistList()]),
        catchRequestError(setBlocklistFailure)
      )
    )
  );

export const deleteBlocklistEpic = action$ =>
  action$.pipe(
    ofType(DELETE_BLOCKLIST.REQUEST),
    pluck('payload'),
    switchMap(ids => {
      return forkJoin(
        ids.map(id =>
          deleteBlocklistAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      );
    }),
    mergeMap(res => {
      const errors = groupBy(v => v.Status)(res);
      if (errors.Error?.length > 0) {
        message.error(
          `刪除失敗: ${errors.Error.map(
            e => `id:${e.id} message: ${e.Message}`
          ).join(', ')}`
        );
        return [deleteBlocklistFailure(), getBlocklistList()];
      } else {
        return [deleteBlocklistSuccess(), getBlocklistList()];
      }
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false,
  isShowModal: false,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_BLOCKLIST_LIST.REQUEST,
      ADD_BLOCKLIST.REQUEST,
      SET_BLOCKLIST.REQUEST,
      DELETE_BLOCKLIST.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(ADD_BLOCKLIST.SUCCESS, SET_BLOCKLIST.SUCCESS)]: produce(
      (draft, { payload }) => {
        draft.loading = false;
        draft.isShowModal = false;
      }
    ),
    [GET_BLOCKLIST_LIST.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;
      draft.data = data;
      draft.totalCount = data.length;
    }),
    [GET_BLOCKLIST_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_BLOCKLIST.FAILURE,
      SET_BLOCKLIST.FAILURE,
      DELETE_BLOCKLIST.SUCCESS,
      DELETE_BLOCKLIST.FAILURE
    )]: produce(draft => {
      draft.loading = false;
    }),
    [SHOW_BLOCKLIST_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
