const generateCDNUrl = (streamId) => {
  const isProduction = process.env.NODE_ENV === 'production';
  const cdn = process.env.REACT_APP_ZEGO_PULL_CDN;
  const appID = process.env.REACT_APP_ZEGO_APPID;

  if (isProduction) {
    return `${cdn}/${streamId}.flv`;
  }
  return `${cdn}/zegotest-${appID}-${streamId}.flv`;
};

export default {
  generateCDNUrl,
};
