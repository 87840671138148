import { Button, Descriptions, Modal } from 'antd';
import React, { useState } from 'react';
import { renderPreviewer } from '../../components/UploadImg';
import { isReallyEmpty } from '../../utils/mixin';
const renderMediia = value =>
  renderPreviewer(value, { autoPlay: true, loop: true, muted: true });
const PreviewModal = ({
  children = '顯示圖片',
  title,
  dataSource,
  columns,
  ...prop
}) => {
  const [visible, setVisible] = useState(false);
  const disabled =
    prop.disabled || isReallyEmpty(dataSource) || isReallyEmpty(columns);
  const onShowClick = () => {
    setVisible(true);
  };
  const onCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button disabled={disabled} onClick={onShowClick}>
        {children}
      </Button>
      <Modal
        width={600}
        title={title}
        visible={visible}
        footer={null}
        onCancel={onCancel}
      >
        <Descriptions column={1} size="small" bordered>
          {!disabled &&
            columns.map(col => (
              <Descriptions.Item label={col.title} key={col.dataIndex}>
                {col.render
                  ? col.render(dataSource[col.dataIndex])
                  : renderMediia(dataSource[col.dataIndex])}
              </Descriptions.Item>
            ))}
        </Descriptions>
      </Modal>
    </>
  );
};
export default PreviewModal;
