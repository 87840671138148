import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { prop, __, indexBy, pathOr } from 'ramda';
import { List } from 'antd';

const createCategoryIndex = indexBy(prop('id', __));

const StyledList = styled(List)`
  width: 100%;
  padding: 24px 0;
  .ant-spin-container {
    display: flex;
    align-items: top;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: 50vh;
  }
  .ant-spin-nested-loading {
    margin: 0 auto;
    @media (min-width: 744px) {
      justify-content: flex-start;
      max-width: 496px;
    }
    @media (min-width: 992px) {
      max-width: 744px;
    }
    @media (min-width: 1208px) {
      max-width: 992px;
    }
    @media (min-width: 1456px) {
      max-width: 1240px;
    }
    @media (min-width: 1704px) {
      max-width: 1488px;
    }
    @media (min-width: 1952px) {
      max-width: 1736px;
    }
  }
  .ant-list-pagination {
    margin: 0 auto;
    @media (min-width: 752px) {
      max-width: 496px;
    }
    @media (min-width: 1000px) {
      max-width: 744px;
    }
    @media (min-width: 1248px) {
      max-width: 992px;
    }
    @media (min-width: 1496px) {
      max-width: 1240px;
    }
    @media (min-width: 1744px) {
      max-width: 1488px;
    }
    @media (min-width: 1992px) {
      max-width: 1736px;
    }
  }
`;
export const CardList = ({
  loading,
  dataSource,
  categoryDataSource,
  renderItem,
  pagination
}) => {
  const [categoryIndex, setCategoryIndex] = useState({});

  useEffect(() => {
    setCategoryIndex(createCategoryIndex(categoryDataSource));
  }, [categoryDataSource]);
  return (
    <StyledList loading={loading} pagination={pagination}>
      {dataSource.map(item => {
        const categoryName = pathOr(
          `無 (${item.categoryId})`,
          [item.categoryId, 'categoryName'],
          categoryIndex
        );
        return renderItem(item, categoryName);
      })}
    </StyledList>
  );
  // }
};
