import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space
} from 'antd';
import moment from 'moment';
import {
  assoc,
  both,
  cond,
  converge,
  evolve,
  has,
  identity,
  is,
  pipe,
  prop,
  props,
  T,
  when,
  __
} from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isReallyEmpty } from '../utils/mixin';
import UploadImg, { renderPreviewer } from './UploadImg';
const RangePicker = DatePicker.RangePicker;
const useHasErrors = () => {
  const [hasErrors, setHasError] = useState(false);
  const validateStatus = (...args) => {
    const allFields = args.pop();
    setHasError(allFields.filter(({ errors }) => errors.length).length > 0);
  };
  return [hasErrors, setHasError, validateStatus];
};
const mapDataToForm = pipe(
  evolve({
    startTime: e => moment.unix(e),
    endTime: e => moment.unix(e)
  }),
  when(
    both(has('startTime'), has('endTime')),
    converge(assoc('dateRange', __, __), [
      props(['startTime', 'endTime']),
      identity
    ])
  )
);
const getPreviewUrl = cond([
  [is(String), identity],
  [both(is(Object), has('previewUrl')), prop('previewUrl')],
  [T, identity]
]);
const isVideo = url => /\.(mp4)$/i.test(url);
const PreviewBg = styled.div`
  height: 10rem;
  margin-left: 1rem;
  padding: 0.5rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  border: #d9d9d9 solid 1px;
  border-radius: 2px;

  > img {
    object-fit: contain;
  }
`;

const SpotLightFormModal = ({
  editData,
  onCancel,
  onOk,
  visible,
  loading,
  enablePreviewUrl,
  isCreateMode
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [imageUrl, setImageUrl] = useState();
  const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    if (visible && editData && !isCreateMode) {
      form.setFieldsValue(mapDataToForm(editData));
      setImageUrl(editData.imageUrl);
      setPreviewUrl(editData.previewUrl);
    }
  }, [form, visible, editData, isCreateMode]);

  const onCopyClick = () => {
    const { setFieldsValue, getFieldValue, validateFields } = form;
    const image = getPreviewUrl(getFieldValue('imageUrl'));
    setFieldsValue({ previewUrl: image });
    setPreviewUrl(image);
    validateFields(['previewUrl'])
      .then(() => {
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const onFieldsChange = (__, allFields) => {
    setImageUrl(form.getFieldValue('imageUrl'));
    setPreviewUrl(form.getFieldValue('previewUrl'));
    validateStatus(allFields);
  };
  const onFinish = () => {
    onOk(form);
  };

  const onAfterClose = () => {
    form.resetFields();
    setImageUrl(undefined);
    setPreviewUrl(undefined);
    setHasError(false);
  };

  return (
    <Modal
      title={`${isCreateMode ? '新增' : '編輯'} Spotlight`}
      onCancel={onCancel}
      visible={visible}
      afterClose={onAfterClose}
      footer={
        <Space>
          <Popconfirm
            key="confirm"
            title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
            placement="topLeft"
            disabled={hasErrors}
            onConfirm={() => form.submit()}
          >
            <Button
              key={'add'}
              loading={loading}
              type="primary"
              disabled={hasErrors}
            >
              確定
            </Button>
          </Popconfirm>
          <Button key="cancle" onClick={onCancel}>
            取消
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          label="標題"
          name="title"
          rules={[
            {
              required: true,
              message: '請輸入標題'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="副標題" name="description">
          <Input />
        </Form.Item>
        <Form.Item label="連結" name="linkUrl">
          <Input />
        </Form.Item>
        <Form.Item
          label="上下架時間"
          name="dateRange"
          rules={[{ required: true, message: '請輸入上下架時間' }]}
        >
          <RangePicker
            style={{ width: '100%' }}
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss')
              ]
            }}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </Form.Item>
        <Row style={{ marginBottom: '24px' }}>
          <Col span={12}>
            <Form.Item
              label="上傳圖片 / 影片"
              name="imageUrl"
              rules={[{ required: true, message: '請上傳圖片' }]}
            >
              <UploadImg
                type="button"
                accept="video/*,image/*"
                uploadUrl="/cdn/backend/photo"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <PreviewBg>
              {renderPreviewer(imageUrl, {
                autoPlay: true,
                loop: true,
                muted: true
              })}
            </PreviewBg>
          </Col>
        </Row>
        {enablePreviewUrl && (
          <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <>
                    上傳縮圖
                    <Button
                      disabled={isReallyEmpty(imageUrl) || isVideo(imageUrl)}
                      type="link"
                      onClick={onCopyClick}
                    >
                      複製原檔
                    </Button>
                  </>
                }
                name="previewUrl"
                rules={[{ required: true, message: '請上傳縮圖' }]}
              >
                <UploadImg
                  type="button"
                  accept="image/*"
                  uploadUrl="/cdn/backend/photo"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <PreviewBg>
                {renderPreviewer(previewUrl, {
                  autoPlay: true,
                  loop: true,
                  muted: true
                })}
              </PreviewBg>
            </Col>
          </Row>
        )}
        <Form.Item hidden={true} name="type" initialValue="article">
          <Input key="type" type="hidden" />
        </Form.Item>
        {!isCreateMode && (
          <Form.Item hidden={true} name="id">
            <Input key="id" type="hidden" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default SpotLightFormModal;
