export const status = [
  { id: 'progress', label: '經紀公司合作中' },
  { id: 'expired', label: '經紀公司未合作' }
];
export const contractStatus = [
  { id: 'all', label: '全部' },
  { id: 'unsigned', label: '合約未開始' },
  { id: 'progress', label: '合約進行中' },
  { id: 'expired', label: '合約已失效' },
  { id: 'expiring', label: '合約即將到期' }
];
