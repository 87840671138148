import moment from 'moment';
import objectHash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, DatePicker, Form, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PageTitle, PageContainer } from '../../components/styled/page';
import {
  getVoiceChatRoomRanking,
  getVoiceChatRoomRankingCsv,
} from '../../reducers/voiceChatRoomReport';
import { isReallyEmpty } from '../../utils/mixin';

const { Column } = Table;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

const Divider = styled.hr`
  margin: 1rem 0;
  border: 0;
  border-bottom: 1px solid #DDD;
`;

const Actions = styled.div`
  margin-bottom: 0.5rem;
`;

const defaultRange = [
  moment().startOf('month'),
  moment().endOf('day'),
];

class VoiceChatRoomReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  dowloadChatRoomRankingCsv = () => {
    const { data } = this.props;
    getVoiceChatRoomRankingCsv(data);
  }

  onSearch = (values) => {
    const { currentRange } = values;
    const startTime = currentRange[0].startOf('day').unix();
    const endTime = currentRange[1].endOf('day').unix();
    this.props.getVoiceChatRoomRanking({
      startTime,
      endTime,
    });
  };

  renderPoint = (rowData) => {
    const { points } = rowData;
    return points.toLocaleString();
  }

  render() {
    const { loading, data } = this.props;
    return (
      <PageContainer>
        <PageTitle>{'聲聊管理 > 各廳流水業績查詢'}</PageTitle>
        <Form
          layout="inline"
          onFinish={this.onSearch}
        >
          <FormItem
            label="日期區間"
            name="currentRange"
            initialValue={defaultRange}
            rules={[
              { required: true, message: '請輸入日期區間' },
            ]}
          >
            <RangePicker
              disabledDate={current => current > moment().endOf('day')}
            />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
            >
              <span>查詢</span>
            </Button>
          </FormItem>
        </Form>
        <Divider />
        <Actions>
          <Button
            onClick={this.dowloadChatRoomRankingCsv}
            disabled={isReallyEmpty(data)}
          >
            <span>下載</span>
          </Button>
        </Actions>
        <Table
          loading={loading}
          pagination={false}
          dataSource={data}
          rowKey={record => objectHash(record)}
        >
          <Column
            title="排名"
            dataIndex="ranking"
            align="center"
          />
          <Column
            title="廳名"
            dataIndex={['user', 'nickname']}
          />
          <Column
            title="業績"
            render={this.renderPoint}
          />
        </Table>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => state.voiceChatRoomReport;

const mapDispatchToProps = {
  getVoiceChatRoomRanking,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoiceChatRoomReport);
