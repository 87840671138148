import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Avatar, Button, Popconfirm, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  addFloatingSlot,
  deleteFloatingSlot,
  getFloatingSlot,
  getFloatingSlotList,
  setFloatingSlot,
  showModal
} from '../../reducers/floatingSlotManagement';
import antdTablePagination from '../../utils/antdTablePagination';
import { PageContainer, PageTitle } from '../../components/styled/page';
import FloatingSlotFormModal from './FloatingSlotFormModal';

const defaultPagination = new antdTablePagination();
const Actions = styled.div`
  margin-bottom: 1rem;
`;

const FloatingSlotManagement = () => {
  const dispatch = useDispatch();
  const { data, currentData, loading, totalCount, isShowModal } = useSelector(
    state => state.floatingSlotManagement
  );
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [pagination, setPagination] = useState({
    ...defaultPagination,
    current: 1
  });

  const fetchFloatingSlotList = useCallback(() => {
    const { current, pageSize } = pagination;
    dispatch(getFloatingSlotList({
      page: current,
      item: pageSize,
    }));
  }, [dispatch, pagination]);

  useEffect(() => {
    fetchFloatingSlotList();
    return () => {
      setIsCreateMode(true);
    };
  }, [dispatch, pagination, fetchFloatingSlotList]);

  const onAddClick = () => {
    setIsCreateMode(true);
    dispatch(showModal(true));
  };

  const onModalCancel = () => {
    setIsCreateMode(true);
    dispatch(showModal(false));
  };

  const onEditClick = id => {
    setIsCreateMode(false);
    dispatch(getFloatingSlot(id));
    dispatch(showModal(true));
  };

  const onDeleteClick = id => {
    // const callback = fetchFloatingSlotList;
    dispatch(deleteFloatingSlot(id, null, () => {
      const { current, pageSize } = pagination;
      const maxPage = Math.ceil((totalCount - 1) / pageSize);
      const newPage = _.clamp(current, 1, maxPage);
      setPagination({ ...pagination, current: newPage });
    }));
  };

  const onSubmit = values => {
    if (isCreateMode) {
      dispatch(addFloatingSlot(values, null, () => {
        setPagination({ ...pagination, current: 1 });
      }));
    } else {
      dispatch(setFloatingSlot(values, null, () => {
        fetchFloatingSlotList();
      }));
    }
  };

  const onPageChange = (page) => {
    const newPagination = Object.assign({}, pagination, {
      current: page,
    });
    setPagination(newPagination);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: '頭像',
      width: 64,
      dataIndex: 'profilePicture',
      render: value => <Avatar src={value} />
    },
    {
      title: '主播暱稱',
      dataIndex: 'nickname'
    },
    {
      title: '標題',
      dataIndex: ['i18nContents', 'titles', 'zh_TW']
    },
    {
      title: '顯示時間',
      dataIndex: 'startTime',
      render: (value, record) =>
        `${moment.unix(value).format('YYYY-MM-DD')} ~ ${moment
          .unix(record?.endTime)
          .format('YYYY-MM-DD')}`
    },
    {
      title: '備註',
      dataIndex: 'remark'
    },
    {
      width: 64,
      dataIndex: null,
      key: 'actions',
      render: (__, record) => (
        <Space size="large">
          <Button
            title="編輯"
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => onEditClick(record.id)}
          />
          <Popconfirm
            title="是否確定刪除?"
            placement="topLeft"
            onConfirm={() => onDeleteClick(record.id)}
          >
            <Button
              title="刪除"
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      )
    }
  ];
  return (
    <PageContainer>
      <PageTitle>{'首頁管理 > 浮動版位'}</PageTitle>
      <Actions>
        <Button
          type="primary"
          onClick={onAddClick}
        >
          <span>新增</span>
        </Button>
      </Actions>
      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          ...pagination,
          total: totalCount,
          onChange: onPageChange,
        }}
      />
      <FloatingSlotFormModal
        isCreateMode={isCreateMode}
        editData={currentData}
        visible={isShowModal}
        loading={loading}
        onCancel={onModalCancel}
        onOk={onSubmit}
      />
    </PageContainer>
  );
};
export default FloatingSlotManagement;
