import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Space,
  Switch,
  Tooltip
} from 'antd';
import {
  compose,
  evolve,
  identity,
  ifElse,
  includes,
  is,
  path,
  prop,
  T
} from 'ramda';
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { giftType, sfxDuration } from '../constants/gifts';
import { useHasErrors } from '../hooks/useHasErrors';
import { isNotNil, isReallyEmpty } from '../utils/mixin';
import BundleUpload from './BundleUpload';
import UploadImg from './UploadImg';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { Option } = Select;
const defaultImages = {
  size100: 'http://via.placeholder.com/100x100',
  size400: 'http://via.placeholder.com/400x400'
};

const getSubmitData = (data, isBackpackGift) =>
  compose(
    ifElse(
      compose(is(Object), prop('animationUrl')),
      evolve({ animationUrl: path(['videoUrl']) }),
      identity
    ),
    ifElse(() => isBackpackGift, evolve({ isActive: T }), identity)
  )(data);
const isVideo = url => /\.(mp4)$/i.test(url);
const renderPreviewer = value => {
  const url = is(Object, value) ? value?.videoUrl : value;
  if (isReallyEmpty(url)) {
    return (
      <img
        alt="animationUrl"
        style={{ width: '100%' }}
        src={defaultImages.size400}
      />
    );
  }
  if (isVideo(url)) {
    return (
      <video muted controls autoPlay loop style={{ width: '100%' }} src={url} />
    );
  } else {
    return <img alt="animationUrl" style={{ width: '100%' }} src={url} />;
  }
};
const generalFields = [
  giftType.liveRoom.value,
  giftType.liveShow.value,
  giftType.voiceChat.value,
  giftType.backpackGift.value
];
const liveFields = [
  giftType.liveRoom.value,
  giftType.liveShow.value,
  giftType.voiceChat.value
];
const sfxGiftFields = [giftType.sfxGift.value, giftType.offensiveSfxGift.value];
const filterFieldByType = (type, allowArray, render) => {
  if (includes(type, allowArray)) {
    return render();
  } else {
    return null;
  }
};
const mapDataToForm = evolve({
  isActive: Boolean,
  isMultiple: Boolean
});

const StyledModal = styled(Modal)`
  .modal-content {
    display: flex;
  }

  .name-field {
    margin-bottom: 16px;

    span {
      margin-right: 8px;
    }
  }

  .left-content {
    .ant-card {
      width: 280px;
    }
    .ant-card + .ant-card {
      width: 150px;
    }
  }

  .right-content {
    padding: 0 16px;
    width: 100%;
    .ant-upload-list-item-name {
      max-width: 240px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
`;

const GiftListModal = ({
  visible,
  title,
  editData,
  isCreateMode,
  giftListCategories = [],
  deleteGift,
  typeFilter,
  onOk,
  onCancel
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [isArchive, setIsArchive] = useState(false);
  const [isBackpackGift, setIsBackpackGift] = useState(
    () => typeFilter === giftType.backpackGift.value
  );
  const [animationUrl, setAnimationUrl] = useState(defaultImages.size400);
  const [thumbUrl, setThumbUrl] = useState(defaultImages.size100);
  const { getFieldValue } = form;
  useEffect(() => {
    if (visible && !isReallyEmpty(editData)) {
      form.setFieldsValue(mapDataToForm(editData));
      setAnimationUrl(form.getFieldValue('animationUrl'));
      setThumbUrl(form.getFieldValue('thumbUrl'));
      setIsArchive(isNotNil(form.getFieldValue('deleteAt')));
    }
  }, [visible, form, editData]);

  useEffect(() => {
    const result = typeFilter === giftType.backpackGift.value;
    setIsBackpackGift(result);
    if (visible && result && isCreateMode) {
      form.setFieldsValue({ isActive: true });
    }
  }, [typeFilter, isCreateMode, form, giftListCategories, visible]);

  useEffect(() => {
    if (
      isBackpackGift &&
      isCreateMode &&
      visible &&
      giftListCategories.length > 0
    ) {
      form.setFieldsValue({ categoryId: giftListCategories[0]?.id });
    }
  }, [isBackpackGift, isCreateMode, form, visible, giftListCategories]);

  const renderPreviewAnimationUrl = () => {
    return (
      <Card style={{ minHeight: 250, marginBottom: 8 }}>
        {renderPreviewer(animationUrl)}
      </Card>
    );
  };
  const renderIsMultiple = () => {
    return (
      <FormItem
        label="類型"
        name="isMultiple"
        rules={[{ required: true, message: '請選擇類型' }]}
      >
        <RadioGroup disabled={isArchive}>
          <Radio value={false}>播放</Radio>
          <Radio value={true}>連擊</Radio>
        </RadioGroup>
      </FormItem>
    );
  };
  const renderAnimationUrl = () => {
    return (
      <FormItem
        label="圖片"
        name="animationUrl"
        rules={[{ required: true, message: '請選擇圖片' }]}
      >
        <UploadImg
          accept="video/*,image/*"
          disabled={isArchive}
          type="button"
          uploadUrl={'/cdn/gift/animation'}
        ></UploadImg>
      </FormItem>
    );
  };

  const renderBundleUrl = () => {
    return (
      <FormItem
        label="Bundle"
        name="animationUrl"
        rules={[{ required: true, message: '請選擇 Bundle' }]}
      >
        <BundleUpload disabled={isArchive} />
      </FormItem>
    );
  };

  const renderDuration = () => {
    return (
      <FormItem
        label="動效禮物秒數"
        name="duration"
        rules={[{ required: true, message: '請選擇秒數' }]}
      >
        <Select disabled={isArchive} style={{ width: '100%' }}>
          {sfxDuration.map(item => (
            <Option key={item.value} value={item.value} title={item.label}>
              {`${item.label} - ${item.value / 1000}s`}
            </Option>
          ))}
        </Select>
      </FormItem>
    );
  };

  const onFinish = values => {
    onOk(getSubmitData(values));
    onCancel();
    ifElse(
      () => isCreateMode,
      () => message.success('禮物已新增。'),
      () => message.success('禮物已變更。')
    );
  };

  const onArchiveClick = () => {
    deleteGift(getFieldValue('id'));
    onCancel();
  };

  const onFieldsChange = (_, allFields) => {
    validateStatus(allFields);
    setAnimationUrl(getFieldValue('animationUrl'));
    setThumbUrl(getFieldValue('thumbUrl'));
  };

  const onAfterClose = () => {
    form.resetFields();
    setAnimationUrl(defaultImages.size400);
    setThumbUrl(defaultImages.size100);
    setHasError(false);
  };

  return (
    <StyledModal
      centered
      title={title}
      width={660}
      visible={visible}
      onCancel={onCancel}
      afterClose={onAfterClose}
      footer={[
        <Popconfirm
          disabled={isCreateMode || isArchive}
          key="archive"
          title="是否確定封存？"
          onConfirm={onArchiveClick}
        >
          <Button
            disabled={isCreateMode || isArchive}
            style={{ float: 'left' }}
          >
            封存
          </Button>
        </Popconfirm>,
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button
          disabled={isArchive || hasErrors}
          key="confirm"
          type="primary"
          onClick={() => form.submit()}
          htmlType="submit"
        >
          確定
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <div className="modal-content">
          <div className="left-content" style={{ width: 300 }}>
            {filterFieldByType(
              typeFilter,
              generalFields,
              renderPreviewAnimationUrl
            )}
            <Card>
              <img
                src={thumbUrl || defaultImages.size100}
                alt="thumbUrl"
                width="100%"
              />
            </Card>
          </div>
          <div className="right-content">
            <div className="name-field">
              <FormItem
                label="名稱"
                name="name"
                rules={[{ required: true, message: '請輸入名稱' }]}
              >
                <Input disabled={isArchive} style={{ width: '100%' }} />
              </FormItem>
            </div>
            <div className="name-field">
              <FormItem
                label="點數"
                name="point"
                rules={[{ required: true, message: '請輸入點數' }]}
              >
                <InputNumber
                  disabled={isArchive}
                  min={0}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </div>
            <div>
              <FormItem
                label="分類"
                name="categoryId"
                rules={[{ required: true, message: '請輸入分類' }]}
              >
                <Select
                  disabled={isArchive}
                  style={{ width: '100%' }}
                  showSearch
                  filterOption={(input, { title }) =>
                    title &&
                    title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {giftListCategories.map(item => (
                    <Option
                      key={item.id}
                      value={item.id}
                      title={item.categoryName}
                    >
                      {item.categoryName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </div>
            <div>
              <FormItem
                label={
                  <Space>
                    啟用狀態
                    {isBackpackGift && (
                      <Tooltip placement="top" title={'背包禮物中禁止關閉！'}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                  </Space>
                }
                name="isActive"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch disabled={isArchive || isBackpackGift} />
              </FormItem>
              {filterFieldByType(typeFilter, liveFields, renderIsMultiple)}
            </div>
            {filterFieldByType(typeFilter, sfxGiftFields, renderDuration)}
            {filterFieldByType(typeFilter, generalFields, renderAnimationUrl)}
            {filterFieldByType(typeFilter, sfxGiftFields, renderBundleUrl)}
            <FormItem
              label="縮圖"
              name="thumbUrl"
              rules={[{ required: true, message: '請選擇圖片' }]}
            >
              <UploadImg
                disabled={isArchive}
                type="button"
                uploadUrl={'/cdn/gift/thumbUrl'}
              />
            </FormItem>
          </div>
        </div>
        {!isCreateMode && (
          <Form.Item hidden={true} name="id">
            <Input key="id" type="hidden" />
          </Form.Item>
        )}
      </Form>
    </StyledModal>
  );
};

export default GiftListModal;
