import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Tag
} from 'antd';
import {
  assoc,
  evolve,
  has,
  head,
  identity,
  ifElse,
  map,
  pipe,
  prop,
  times
} from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import UploadImg from '../../components/UploadImg';
import { useHasErrors } from '../../hooks/useHasErrors';
import { deleteGift } from '../../reducers/giftList';
import { findById, isNotNil, isReallyEmpty } from '../../utils/mixin';
import { sortByProps } from '../../utils/sortByProps';
const FormItem = Form.Item;
const { Option } = Select;
const mapDataToForm = evolve({
  isActive: Boolean,
  isMultiple: Boolean
});
const getSubmitData = pipe(
  evolve({
    giftRandomItems: map(
      evolve({
        itemAnimationUrl: ifElse(has('videoUrl'), prop('videoUrl'), identity)
      })
    )
  })
);
const getMaxPointThumbUrl = pipe(
  prop('giftRandomItems'),
  sortByProps(['itemPoint']),
  head,
  prop('itemThumbUrl')
);
const RandomItem = styled.div`
  border: 1px solid #d9d9d9;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
`;
const fallbackImg =
  'data:image/svg+xml,%3Csvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%25" viewBox="0 0 192 192" style="background-color:%23f5f5f5" enable-background="new 0 0 192 192" xml:space="preserve"%3E%3Cpath fill="%238c8c8c" opacity="1.000000" stroke="none" d="%0AM71.357506,79.660233 C72.195381,77.056747 73.977310,76.771965 75.891899,76.771606 C90.553040,76.768829 105.214500,76.828941 119.875099,76.740891 C123.605263,76.718483 124.958687,78.605705 124.965492,81.956200 C124.987129,92.618492 124.984444,103.280838 124.981682,113.943169 C124.980751,117.528992 123.551964,119.410332 119.580322,119.372070 C105.253639,119.234062 90.924240,119.237762 76.597313,119.359146 C72.754715,119.391701 71.039383,117.794891 71.060158,114.070641 C71.123322,102.745651 71.182388,91.420639 71.357506,79.660233 M114.568794,107.634193 C112.310928,104.968353 109.847641,102.447090 107.844810,99.601730 C105.056236,95.640068 102.866257,96.201805 100.298439,99.796341 C98.701118,102.032318 97.235634,104.560898 94.520561,105.618515 C91.622292,104.696869 90.996178,100.945847 87.575119,100.609573 C85.029518,103.645599 81.859261,106.263657 79.934349,110.734749 C91.494629,110.734749 102.442215,110.737534 113.389793,110.732109 C115.302650,110.731163 116.475746,110.251564 114.568794,107.634193 M86.413429,86.844521 C84.305153,88.611427 84.246582,90.588249 86.440727,92.100769 C88.095558,93.241531 89.807762,92.273552 90.318230,90.509224 C91.078926,87.879990 89.791893,86.472748 86.413429,86.844521 z"/%3E%3C/svg%3E';
const amountOfRandomItem = 5;
const pointList = [
  {
    id: 100,
    pointItems: [
      { point: 10, probability: 1500 },
      { point: 20, probability: 2500 },
      { point: 100, probability: 5500 },
      { point: 500, probability: 400 },
      { point: 1314, probability: 100 }
    ]
  },
  {
    id: 300,
    pointItems: [
      { point: 30, probability: 1500 },
      { point: 92, probability: 2500 },
      { point: 300, probability: 5500 },
      { point: 1520, probability: 400 },
      { point: 3344, probability: 100 }
    ]
  },
  {
    id: 1000,
    pointItems: [
      { point: 85, probability: 2200 },
      { point: 170, probability: 2500 },
      { point: 1314, probability: 5000 },
      { point: 5200, probability: 200 },
      { point: 13920, probability: 100 }
    ]
  }
];

const GiftRandomModal = ({
  isCreateMode = true,
  loading = false,
  giftListCategories = [],
  editData,
  onOk,
  onCancel,
  visible
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [isArchive, setIsArchive] = useState(false);

  useEffect(() => {
    if (visible && !isCreateMode && !isReallyEmpty(editData)) {
      form.setFieldsValue(mapDataToForm(editData));
      setIsArchive(isNotNil(form.getFieldValue('deleteAt')));
    }
  }, [form, editData, visible, isCreateMode]);

  useEffect(() => {
    if (visible && isCreateMode) {
      // 設定 giftRandomItems 預設值數量為 5 個
      form.setFieldsValue({
        giftRandomItems: times(v => ({}), amountOfRandomItem)
      });
    }
  }, [form, visible, isCreateMode]);

  useEffect(() => {
    if (visible && isCreateMode) {
      form.setFieldsValue({
        categoryId: giftListCategories[0]?.id
      });
    }
  }, [form, visible, isCreateMode, giftListCategories]);

  const onPointChange = id => {
    const pointItems = findById(id, pointList)?.pointItems;
    const giftRandomItems = form.getFieldValue('giftRandomItems');
    if (giftRandomItems && pointItems) {
      form.setFieldsValue({
        giftRandomItems: giftRandomItems.map((item, index) => ({
          ...item,
          itemPoint: pointItems[index].point,
          probability: pointItems[index].probability
        }))
      });
    }
  };

  const onSubmit = () => form.submit();

  const onArchiveClick = () => {
    dispatch(deleteGift(form.getFieldValue('id')));
    onCancel();
  };

  const onFinish = values => {
    const thumbUrl = getMaxPointThumbUrl(values);
    // 取最大獎的預覽圖，同步到 thumbUrl
    const data = assoc('thumbUrl', thumbUrl, values);
    onOk(getSubmitData(data));
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
    setIsArchive(false);
  };

  return (
    <Modal
      width={660}
      title={`${isCreateMode ? '新增' : '編輯'}隨機禮物`}
      onOk={onSubmit}
      onCancel={onCancel}
      visible={visible}
      afterClose={onAfterClose}
      footer={[
        <Popconfirm
          disabled={isCreateMode || isArchive}
          key="archive"
          title="是否確定封存？"
          onConfirm={onArchiveClick}
        >
          <Button
            disabled={isCreateMode || isArchive}
            style={{ float: 'left' }}
          >
            封存
          </Button>
        </Popconfirm>,
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button
          disabled={isArchive || hasErrors}
          loading={loading}
          htmlType="submit"
          key="confirm"
          type="primary"
          onClick={() => form.submit()}
        >
          確定
        </Button>
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onFieldsChange={validateStatus}>
          <FormItem
            name="name"
            label="名稱"
            rules={[{ required: true, message: '請輸入名稱' }]}
          >
            <Input disabled={isArchive} style={{ width: '100%' }} />
          </FormItem>
          <FormItem
            label="點數"
            name="point"
            rules={[{ required: true, message: '請選擇點數' }]}
          >
            <Select
              disabled={isArchive || !isCreateMode}
              style={{ width: '100%' }}
              onChange={onPointChange}
            >
              {pointList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.id}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label="分類"
            name="categoryId"
            rules={[{ required: true, message: '請選擇分類' }]}
          >
            <Select disabled={true} style={{ width: '100%' }}>
              {giftListCategories.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.categoryName}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label="啟用狀態"
            name="isActive"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch disabled={isArchive} />
          </FormItem>
          <FormItem
            shouldUpdate={(prevValues, curValues) =>
              prevValues.point !== curValues.point
            }
          >
            {({ getFieldValue }) => {
              const pointID = getFieldValue('point');
              const pointInfo = findById(pointID, pointList);
              const pointItems = pointInfo?.pointItems;
              return (
                pointItems && (
                  <Form.List name="giftRandomItems">
                    {(fields, { add, remove }, { errors }) => (
                      <Row gutter={16}>
                        {fields.map(field => (
                          <Col span={12} key={field.key}>
                            <RandomItem>
                              <FormItem
                                {...field}
                                name={[field.name, 'probability']}
                                key={`probability${field.key}`}
                                noStyle
                              >
                                <Input type="hidden" />
                              </FormItem>
                              <FormItem
                                {...field}
                                style={{ marginLeft: '12px' }}
                                label="點數"
                                name={[field.name, 'itemPoint']}
                                key={`itemPoint${field.key}`}
                              >
                                <Tag>{pointItems[field.name].point}</Tag>
                              </FormItem>
                              <FormItem
                                {...field}
                                label="名稱"
                                name={[field.name, 'itemName']}
                                key={`itemName${field.key}`}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: '請輸入名稱'
                                  }
                                ]}
                              >
                                <Input placeholder="請輸入名稱" />
                              </FormItem>
                              <div style={{ display: 'flex' }}>
                                <FormItem
                                  {...field}
                                  label="縮圖"
                                  style={{ width: '190px' }}
                                  name={[field.name, 'itemThumbUrl']}
                                  key={`itemThumbUrl${field.key}`}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '請輸入縮圖'
                                    }
                                  ]}
                                >
                                  <UploadImg
                                    type="button"
                                    accept="image/*"
                                    uploadUrl="/cdn/gift/thumbUrl"
                                  />
                                </FormItem>
                                <FormItem
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues.giftRandomItems?.[field.name]
                                      ?.itemThumbUrl !==
                                    curValues.giftRandomItems?.[field.name]
                                      ?.itemThumbUrl
                                  }
                                >
                                  {({ getFieldValue }) => {
                                    return (
                                      <Image
                                        width={80}
                                        height={80}
                                        src={
                                          getFieldValue([
                                            'giftRandomItems',
                                            field.name,
                                            'itemThumbUrl'
                                          ]) || fallbackImg
                                        }
                                        fallback={fallbackImg}
                                      />
                                    );
                                  }}
                                </FormItem>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <FormItem
                                  {...field}
                                  label="動畫"
                                  style={{ width: '190px' }}
                                  name={[field.name, 'itemAnimationUrl']}
                                  key={`itemAnimationUrl${field.key}`}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '請輸入動畫'
                                    }
                                  ]}
                                >
                                  <UploadImg
                                    type="button"
                                    // accept="image/*"
                                    accept="video/*,image/*"
                                    uploadUrl="/cdn/gift/animation"
                                  >
                                    上傳動畫
                                  </UploadImg>
                                </FormItem>
                              </div>
                            </RandomItem>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Form.List>
                )
              );
            }}
          </FormItem>
          <FormItem name="isMultiple" noStyle hidden initialValue={false}>
            <Input type="hidden" />
          </FormItem>
          <FormItem name="thumbUrl" noStyle hidden initialValue={''}>
            <Input type="hidden" />
          </FormItem>
          <FormItem name="animationUrl" noStyle hidden initialValue={''}>
            <Input type="hidden" />
          </FormItem>
          {!isCreateMode && (
            <FormItem name="id" noStyle hidden>
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default GiftRandomModal;
