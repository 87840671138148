import { Table } from 'antd';
import { indexBy, prop } from 'ramda';
import React, { useMemo } from 'react';
import PreviewModal from './PreviewModal';
import { typeList } from '../../constants/effect';
import { isReallyEmpty } from '../../utils/mixin';
const typeById = indexBy(prop('id'), typeList);
const columns = [
  { title: '編號', dataIndex: 'id' },
  { title: '名稱', dataIndex: 'name' },
  {
    title: '類型',
    dataIndex: 'type',
    render: value => typeById[value] && typeById[value].label
  },
  {
    title: '留言框',
    dataIndex: 'bubbleUrl',
    render: (value, record) => (
      <PreviewModal
        disabled={isReallyEmpty(value)}
        title="留言框"
        dataSource={record}
        columns={[
          { title: '留言框', dataIndex: 'bubbleUrl' },
          { title: '留言框-縮圖', dataIndex: 'bubblePreviewUrl' }
        ]}
      />
    )
  }
];

const BubbleTable = ({
  loading,
  data,
  selectedRowKeys,
  pageSize,
  currentPage,
  total,
  onRowSelectionChange,
  onPageChange
}) => {
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: onRowSelectionChange
    }),
    [selectedRowKeys, onRowSelectionChange]
  );
  const pagination = useMemo(
    () => ({
      pageSize,
      total,
      current: currentPage,
      onChange: onPageChange,
      showSizeChanger: false
    }),
    [pageSize, currentPage, total, onPageChange]
  );
  return (
    <Table
      rowKey="id"
      scroll={{ x: true }}
      loading={loading}
      rowSelection={rowSelection}
      pagination={pagination}
      columns={columns}
      dataSource={data}
    ></Table>
  );
};
export default BubbleTable;
