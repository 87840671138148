export const eventTypes = {
  NORMAL: 4,
  RANDOM: 5 // API 尚未設定好
};

export const fieldsOfAdditionEffects = [
  'additionEffects[0].id',
  'additionEffects[0].type',
  'additionEffects[0].points',
  'additionEffects[0].probability',
  'additionEffects[1].id',
  'additionEffects[1].type',
  'additionEffects[1].points',
  'additionEffects[1].probability',
  'additionEffects[2].id',
  'additionEffects[2].type',
  'additionEffects[2].points'
];

export function getFields(type) {
  if (+type === eventTypes.NORMAL) {
    return [
      'animationSetting.map[0].type',
      'animationSetting.map[0].url',
      'animationSetting.map[1].type',
      'animationSetting.map[1].url',
      'animationSetting.map[2].type',
      'animationSetting.map[2].url',
      'animationSetting.map[3].type',
      'animationSetting.map[3].url',
      'id',
      'name',
      'bonus',
      'giftIds',
      'npcSpacings[0].id',
      'npcSpacings[0].level',
      'npcSpacings[0].points',
      'npcSpacings[0].npcDefinitionId',
      'noGiftTime'
    ];
  }

  if (+type === eventTypes.RANDOM) {
    return [
      'animationSetting.map[0].type',
      'animationSetting.map[0].url',
      'animationSetting.map[1].type',
      'animationSetting.map[1].url',
      'animationSetting.map[2].type',
      'animationSetting.map[2].url',
      'animationSetting.map[3].type',
      'animationSetting.map[3].url',
      'id',
      'bonus',
      'giftIds',
      'npcSpacings[0].id',
      'npcSpacings[0].level',
      'npcSpacings[0].points',
      'npcSpacings[0].npcDefinitionId'
    ];
  }

  return [];
}
