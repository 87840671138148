import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Space,
  Tag
} from 'antd';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import UploadImg from '../../components/UploadImg';
import { useHasErrors } from '../../hooks/useHasErrors';
import { getUserInfo } from '../../reducers/userManagement';

const FormItem = Form.Item;

const PointTradingModal = ({ onOk, onCancel, loading, open }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const { uuid, remainPoints } = useSelector(state => ({
    uuid: state.auth.id,
    remainPoints: state.userManagement.formValue.remainPoints
  }));

  useEffect(() => {
    if (open) {
      dispatch(getUserInfo(uuid));
    }
  }, [dispatch, uuid, open]);

  const onRemarkChange = () => {
    form.validateFields(['image']);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };

  const onFinish = values => {
    onOk(values);
  };

  const pointValidator = (__, value) => {
    if (remainPoints - value < 0) {
      const error = new Error('持有點數不足');
      return Promise.reject(error);
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title="新增轉點"
      open={open}
      onCancel={onCancel}
      afterClose={onAfterClose}
      confirmLoading={loading}
      footer={
        <Space>
          <Button key="cancle" onClick={onCancel}>
            取消
          </Button>
          <Popconfirm
            key="confirm"
            title={'是否確定新增'}
            placement="topLeft"
            onConfirm={() => form.submit()}
          >
            <Button
              key={'add'}
              type="primary"
              loading={loading}
              disabled={hasErrors}
            >
              確定
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={validateStatus}
      >
        <p>
          目前點數：<Tag>{remainPoints?.toLocaleString()}</Tag>
        </p>
        <FormItem
          label="轉出對象"
          name="receiverId"
          rules={[{ required: true, message: '請輸入對象' }]}
        >
          <LiveMasterSelect
            roleFilter=""
            typeFilter="true_love_id"
            disabledTypeSelect
          />
        </FormItem>
        <FormItem
          label="點數"
          name="point"
          rules={[
            { required: true, message: '請輸入點數' },
            { type: 'number', message: '請輸入正確的數字' },
            { validator: pointValidator }
          ]}
        >
          <InputNumber
            placeholder="請輸入點數"
            style={{ width: '100%' }}
            min={0}
          />
        </FormItem>
        <FormItem
          label="備註"
          name="remark"
          rules={[{ required: true, message: '請輸入備註' }]}
        >
          <Input placeholder="請輸入備註" onChange={onRemarkChange} />
        </FormItem>
        <FormItem label="上傳截圖（最多三張）" name="image">
          <UploadImg uploadUrl="/cdn/backend/photo" mode="multiple" />
        </FormItem>
        <FormItem
          name="senderId"
          initialValue={uuid}
          rules={[{ required: true }]}
          hidden
        >
          <Input type="hidden" />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default PointTradingModal;
