import _ from 'lodash';
import { Modal } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getUserInfoAPI, getDialogAPI } from '../../../../apis';
import Utils from '../../../../components/InstantChatRoom/utils';
import EnquiryContext from '../../EnquiryContext';
import MessagePool from '../../../../components/InstantChatRoom/MessagePool';

const HistoryModalBody = styled.div`
  position: relative;
  width: 28rem;
  padding-top: 135%;
`;

const defaultState = {
  isReady: false,
  visible: false,
  dataset: {},
  participant: {},
  periods: {},
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
};

class HistoryModal extends React.Component {

  static contextType = EnquiryContext;

  static propTypes = {
    range: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = { ...defaultState };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  init = async () => {
    await Promise.all([
      this.fetchParticipantInfo(),
      this.fetchHistory(),
    ]);
    this.setState({ isReady: true });
  }

  show = (dataset) => {
    this.setState({
      dataset,
      visible: true,
    }, this.init);
  }

  hide = () => {
    this.setState({ ...defaultState });
  }

  addMessageToTop = (iPeriods) => {
    const { periods } = this.state;
    const newPeriods = { ...periods };
    _.forEach(iPeriods, (iPeriod, key) => {
      newPeriods[key] = _.unionWith(
        iPeriod,
        newPeriods[key],
        _.isEqual,
      );
    });
    this.setState({ periods: newPeriods });
  }

  fetchParticipantInfo = () => {
    const { dataset } = this.state;
    const { userId } = dataset;
    const uPromise = getUserInfoAPI(userId).toPromise();
    uPromise.then((response) => {
      this.setState({ participant: response });
    });
    return uPromise;
  }

  fetchDialog = (payload) => {
    const dialogPromise = getDialogAPI(payload).toPromise();
    dialogPromise.then((response) => {
      const { pagination } = this.state;
      const total = _.get(response, 'totalCount', 0);
      const newPagination = { ...pagination, total };
      this.setState({ pagination: newPagination });
    });
    return dialogPromise;
  }

  fetchHistory = () => {
    const { range } = this.props;
    const { dataset, pagination } = this.state;
    const dialogId = _.get(dataset, 'dialogId');
    if (_.isEmpty(dialogId)) return;

    const dPromise = this.fetchDialog({
      dialogId,
      startTime: range.startTime,
      endTime: range.endTime,
      item: pagination.pageSize,
      page: pagination.current,
    });
    dPromise.then((response) => {
      const data = _.get(response, 'data', []);
      if (!_.isArray(data)) return;
      const periods = Utils.processMessages(data);
      this.addMessageToTop(periods);
    });
    return dPromise;
  }

  onInfinityFetchHisyory = () => {
    const { pagination } = this.state;
    const { current, pageSize, total } = pagination;

    // check new page is valid
    const newPage = current + 1;
    if (newPage * pageSize > total) return;

    // update
    const newPagination = { ...pagination, current: newPage };
    this.setState({ pagination: newPagination }, this.fetchHistory);
  }

  renderMessagePool = () => {
    const { client } = this.context;
    const { isReady, participant, periods } = this.state;
    return isReady ? (
      <MessagePool
        master={client}
        participant={participant}
        messages={periods}
        onLoadHistory={this.onInfinityFetchHisyory}
      ></MessagePool>
    ) : null;
  }

  render() {
    const { visible } = this.state;
    return (
      <Modal
        title="歷史訊息"
        width="auto"
        visible={visible}
        footer={null}
        centered
        maskClosable
        onCancel={this.hide}
      >
        <HistoryModalBody>
          {this.renderMessagePool()}
        </HistoryModalBody>
      </Modal>
    );
  }
}

export default HistoryModal;
