import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import downloadCSV from '../utils/downloadCSV';
import { connect } from 'react-redux';
import { DatePicker, Table, Button } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import * as actions from '../actions';

import 'moment/locale/zh-tw';
moment.locale('zh-tw');

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-right: 8px;

  > .ant-calendar-picker {
    &:first-child {
      margin-right: 8px;
    }

    &:nth-child(2) {
      margin-left: 8px;
    }
  }

  > .search-button {
    margin-left: 8px;
  }

  > .export-button {
    margin-left: auto;
  }
`;

const mapStateToProps = state => ({
  dataMap: state.contentManagement.dataMap,
  isLoading: state.contentManagement.isLoading
});

const mapDispatchToProps = {
  loadMauReports: actions.loadMauReports
};

@connect(mapStateToProps, mapDispatchToProps)
export default class MAU extends Component {
  static propTypes = {
    dataMap: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadMauReports: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(Date.now()).subtract(7, 'days'),
      endDate: moment(Date.now())
    };
  }

  componentDidMount() {
    this.loadReportsBetweenDateRange();
  }

  formatDataMapToTableData = dataMap => {
    const dataEntries = Object.entries(dataMap);
    return dataEntries.map(entry => ({
      dateLabel: entry[0],
      repeatMAU: entry[1].uu,
      nonRepeatMAU: entry[1].nuu
    }));
  };

  loadReportsBetweenDateRange = () => {
    const { startDate, endDate } = this.state;
    const { loadMauReports } = this.props;
    loadMauReports({
      startDate: startDate.valueOf(),
      endDate: endDate.valueOf()
    });
  };

  getBlobCsvUrlFromJson = () => {
    const { dataMap } = this.props;
    const jsonData = this.formatDataMapToTableData(dataMap);
    downloadCSV(
      jsonData,
      ['dateLabel', 'repeatMAU', 'nonRepeatMAU'],
      ['日期', '重複MAU', '不重複MAU']
    );
  };

  handleMonthSelect = stateTag => date => {
    this.setState({
      [stateTag]: date
    });
  };

  handleSearchReport = () => {
    this.loadReportsBetweenDateRange();
  };

  render() {
    const { startDate, endDate } = this.state;
    const { dataMap, isLoading } = this.props;
    const tableData = this.formatDataMapToTableData(dataMap);
    const columns = [
      {
        title: '日期',
        dataIndex: 'dateLabel'
      },
      {
        title: '重複MAU',
        dataIndex: 'repeatMAU'
      },
      {
        title: '不重複MAU',
        dataIndex: 'nonRepeatMAU'
      }
    ];

    return (
      <div>
        <h1>{'經營管理 > MAU'}</h1>
        <Toolbar>
          <DatePicker
            style={{ margin: '0 10px' }}
            format="YYYY/MM/DD HH:mm:ss"
            showTime={true}
            value={startDate}
            onChange={this.handleMonthSelect('startDate')}
          />
          至
          <DatePicker
            style={{ margin: '0 10px' }}
            format="YYYY/MM/DD HH:mm:ss"
            showTime={true}
            value={endDate}
            onChange={this.handleMonthSelect('endDate')}
          />
          <Button
            style={{ marginRight: 10 }}
            className="search-button"
            onClick={this.handleSearchReport}
          >
            <LegacyIcon type="search" />
            <span>查詢</span>
          </Button>
          <Button type="primary" onClick={this.getBlobCsvUrlFromJson}>
            匯出 MAU 報表
          </Button>
        </Toolbar>
        <Table
          rowKey="dateLabel"
          dataSource={tableData}
          columns={columns}
          locale={{ emptyText: '沒有資料。' }}
          loading={isLoading}
        />
      </div>
    );
  }
}
