import React, { Component } from 'react';
import styled from 'styled-components';
import fakeData from '../fake/totalShareRate';
import classNames from 'classnames';
import { Table, Button, InputNumber, message } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';

const TableContainer = styled.div`
  min-width: 600px;

  .total-share-table {
    td {
      white-space: nowrap;
    }

    .in-rate-edit {
      width: 60px;
    }

    .rate-value {
      padding-left: 8px;
    }
    .modified-rate-value {
      color: red;
    }
  }

  .btn-group {
    text-align: center;
    padding-top: 48px;
  }
`;

class TotalShareRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingIndex: null,
      rates: fakeData.map(d => ({ _id: d._id, rate: d.rate })),
      newRateIndexes: [],
    };
  }

  handleButtonClick = () => {
    const newChangedRates = this.state.rates.filter(
      (rate, index) => this.state.newRateIndexes.indexOf(index) > -1
    );
    window.alert('更新拆帳比： ' + JSON.stringify(newChangedRates));
    message.success('設定成功。');
    this.setState({ newRateIndexes: [] });
  };

  handleEditClickAtIndex = index => () => {
    this.setState({
      editingIndex: index,
    });
  };

  handleRateBlurAtIndex = index => evt => {
    this.setState(prevState => ({
      editingIndex: null,
      rates: prevState.rates.map((r, i) => {
        if (i === index) return { _id: r._id, rate: Number(evt.target.value) };
        return r;
      }),
      newRateIndexes:
        prevState.newRateIndexes.indexOf(index) >= 0
          ? prevState.newRateIndexes
          : prevState.newRateIndexes.concat(index),
    }));
  };

  render() {
    const tableColumns = [
      {
        title: '名稱',
        dataIndex: 'title',
      },
      {
        title: '帳號',
        dataIndex: 'accountName',
      },
      {
        title: '比例',
        dataIndex: 'rate',
        width: 150,
        render: (rate, record) => {
          return this.state.editingIndex === record.index ? (
            <InputNumber
              onBlur={this.handleRateBlurAtIndex(record.index)}
              className="in-rate-edit"
              defaultValue={this.state.rates[record.index].rate}
              min={0}
              max={100}
            />
          ) : (
            <span
              className={classNames({
                'rate-value': true,
                'modified-rate-value':
                  this.state.newRateIndexes.indexOf(record.index) > -1,
              })}
            >
              {this.state.rates[record.index].rate}
            </span>
          );
        },
      },
      {
        title: '動作',
        render: record =>
          this.state.editingIndex === record.index ? (
            <Button
              type="default"
              shape="circle"
              onClick={() => this.setState({ editingIndex: null })}
            >
              <LegacyIcon type="check" />
            </Button>
          ) : (
            <Button
              type="primary"
              shape="circle"
              onClick={this.handleEditClickAtIndex(record.index)}
            >
              <LegacyIcon type="edit" />
            </Button>
          ),
      },
    ];
    return (
      <div>
        <h1>經紀公司 > 總金額拆帳比</h1>
        <TableContainer>
          <Table
            className="total-share-table"
            rowKey="_id"
            columns={tableColumns}
            dataSource={fakeData}
            pagination={false}
          />
          <div className="btn-group">
            <Button
              type="primary"
              onClick={this.handleButtonClick}
              disabled={!this.state.newRateIndexes.length}
            >
              儲存設定
            </Button>
          </div>
        </TableContainer>
      </div>
    );
  }
}

export default TotalShareRate;
