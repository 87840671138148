import { ajax } from 'rxjs/ajax';
import { throwError } from 'rxjs';
import { pluck, catchError } from 'rxjs/operators';
import { important } from '../utils/log';

/**
 * API headers Settings
 */
let _headers = {
  'Content-Type': 'application/json;charset=UTF-8'
};
export const setHeader = (token, nonce) => {
  _headers = {
    'X-Auth-Token': token,
    'X-Auth-Nonce': nonce,
    'Content-Type': 'application/json;charset=UTF-8'
  };
};

export const setContentTypeIsNull = () => {
  delete _headers['Content-Type'];
};

export const createContentType = contentType => {
  _headers['Content-Type'] = contentType;
};

/**
 * Creating an API instance 
 */
export const createRequest = root => (path, method = 'GET') => body => {
  return ajax({
    url: root + path,
    method,
    headers: _headers,
    crossDomain: true,
    body
  }).pipe(
    catchError(error => {
      important('api request fails');
      return throwError(error);
    }),
    pluck('response')
  );
};
