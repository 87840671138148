import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Modal, Card, Button, Table } from 'antd';

const StyledModal = styled(Modal)`
  .modal-content {
    display: flex;
  }

  .info {
    h3 {
      margin: 4px 0;
    }
  }

  .modal-footer {
    padding-top: 16px;
  }
`;

const StyledTable = styled(Table)`
  td {
    white-space: nowrap;
  }
`;

const tableColumns = [{
  title: '檢舉帳號',
  dataIndex: 'reporterAccount',
}, {
  title: '檢舉時間',
  dataIndex: 'timeStamp',
  render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
}, {
  title: '檢舉原因',
  dataIndex: 'reason',
}];

class ReportedStreamsModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
  };

  render() {
    const { account, imgUrl, tableData } = this.props.info;
    return (
      <StyledModal
        title="被檢舉直播"
        width={700}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={null}
      >
        <div className="modal-content">
          <Card
            style={{ width: 300, marginRight: '16px' }}
            bodyStyle={{ padding: '4px' }}
          >
            <img
              src={imgUrl}
              alt="avatar"
              width="100%"
            />
          </Card>
          <div className="info">
            <h3>直播主帳號：{account}</h3>
            <h3>檢舉紀錄：</h3>
            <StyledTable
              rowKey='index'
              columns={tableColumns}
              dataSource={tableData}
              pagination={false}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button.Group style={{ width: '100%' }}>
            <Button type="danger" size="large" style={{ width: '50%'}} >立即下架</Button>
            <Button type="default" size="large" style={{ width: '50%'}} >清除被檢舉狀態</Button>
          </Button.Group>
        </div>
      </StyledModal>
    );
  }
}

export default ReportedStreamsModal;
