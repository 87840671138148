import {
  Button, DatePicker, Form,
  Input, Modal, Popconfirm,
  Space,
} from 'antd';
import moment from 'moment';
import {
  assoc, both, converge,
  evolve, has, identity,
  pipe, props, toUpper,
  when, __,
} from 'ramda';
import React, { useEffect, useState } from 'react';
import UploadImg, { renderPreviewer } from '../../components/UploadImg';

const { RangePicker } = DatePicker;

const mapDataToForm = pipe(
  evolve({
    bannerZone: toUpper,
    startTime: e => moment.unix(e),
    endTime: e => moment.unix(e)
  }),
  when(
    both(has('startTime'), has('endTime')),
    converge(assoc('timeRange', __, __), [
      props(['startTime', 'endTime']),
      identity
    ])
  )
);

const useHasErrors = () => {
  const [hasErrors, setHasError] = useState(false);
  const validateFields = (...args) => {
    const allFields = args.pop();
    setHasError(allFields.filter(({ errors }) => errors.length).length > 0);
  };
  return [hasErrors, setHasError, validateFields];
};

const CreateAdFormModal = ({
  loading = false,
  editData,
  onCancel,
  onOk,
  visible,
  isCreateMode
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateFields] = useHasErrors();

  useEffect(() => {
    if (editData) {
      form.setFieldsValue(mapDataToForm(editData));
    }
  }, [form, editData]);

  const onFinish = () => {
    onOk(form);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };

  return (
    <Modal
      forceRender={true}
      title={`${isCreateMode ? '新增' : '編輯'} Banner`}
      onCancel={onCancel}
      visible={visible}
      afterClose={onAfterClose}
      footer={
        <Space>
          <Popconfirm
            disabled={hasErrors}
            key="confirm"
            title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
            placement="topLeft"
            onConfirm={() => form.submit()}
          >
            <Button
              key={'add'}
              loading={loading}
              type="primary"
              disabled={hasErrors}
            >
              確定
            </Button>
          </Popconfirm>
          <Button key="cancle" onClick={onCancel}>
            取消
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={validateFields}
      >
        <Form.Item label="連結" name="linkUrl">
          <Input />
        </Form.Item>
        <Form.Item
          label="上下架時間"
          name="timeRange"
          rules={[{ required: true, message: '請輸入上下架時間' }]}
        >
          <RangePicker
            style={{ width: '100%' }}
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss')
              ]
            }}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </Form.Item>
        <Form.Item
          label="上傳圖片"
          name="bannerUrl"
          rules={[{ required: true, message: '請上傳圖片' }]}
        >
          <UploadImg type="button" uploadUrl="/cdn/ad/banner" />
        </Form.Item>
        {!isCreateMode && (
          <Form.Item hidden={true} name="id">
            <Input key="id" type="hidden" />
          </Form.Item>
        )}
        <Form.Item hidden={true} name="bannerZone">
          <Input key="bannerZone" type="hidden" />
        </Form.Item>
        <Form.Item hidden={true} name="bannerType">
          <Input key="bannerType" type="hidden" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() =>
            renderPreviewer(form.getFieldValue('bannerUrl'), {
              autoPlay: true,
              loop: true
            })
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAdFormModal;
