import _ from 'lodash';
import objectHash from 'object-hash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Table } from 'antd';
import { EditOutlined, HomeOutlined } from '@ant-design/icons';
import { FAMILY_DETAIL } from '../../../constants/routes';
import antdTablePagination from '../../../utils/antdTablePagination';
import columns from './columns';

const FamilyThumb = styled.div`
  width: 100%;
  min-width: 6rem;

  &::after {
    content: '';
    width: 100%;
    padding-top: 100%;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${(props) => {
    const { src } = props;
    return src && 'url(' + src + ')';
  }};
  }
`;

const FamilyName = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

const FamilyInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  font-size: 0.85rem;
`;

const FamilyInfoField = styled.div`
  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

const FamilyActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const FamilyActionsBtn = styled(Button)`
  width: auto;
  height: auto;
  padding: 0.5rem;
  border: 0;
  background: none !important;
  font-size: 1.2rem;
  outline: none;
  box-shadow: none;

  &::after {
    display: none;
  }

  * {
    font-size: inherit;
  }
`;

class FamilyList extends React.PureComponent {
  static propTypes = {
    families: PropTypes.array,
    onEdit: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.columns = columns(this);

    this.state = {
      pagination: new antdTablePagination(),
    };
  }

  onEditClick = (rowData) => {
    const { onEdit } = this.props;
    if (!onEdit) return;
    onEdit({
      familyId: _.get(rowData, 'id'),
      name: _.get(rowData, 'name'),
      oopsvipId: _.get(rowData, 'adminUser.oopsvipId'),
    });
  };

  renderThumb = (rowData) => {
    const url = _.get(rowData, 'thumbUrl');
    return (
      <FamilyThumb src={url} />
    );
  };

  renderInfo = (rowData) => {
    const name = _.get(rowData, 'name');
    const level = _.get(rowData, 'familyLevel.level');
    const memebersQty = _.get(rowData, 'memberQtyProps.current');
    const memebersLimit = _.get(rowData, 'memberQtyProps.limit');
    const exp = _.get(rowData, 'totalExp');
    return (
      <React.Fragment>
        <FamilyName>{name}</FamilyName>
        <FamilyInfo>
          <FamilyInfoField>
            <label>等級：</label>
            <span>{level}</span>
          </FamilyInfoField>
          <FamilyInfoField>
            <label>成員：</label>
            <span>{`${memebersQty} / ${memebersLimit}`}</span>
          </FamilyInfoField>
        </FamilyInfo>
        <FamilyInfo>
          <FamilyInfoField>
            <label>EXP：</label>
            <span>{exp}</span>
          </FamilyInfoField>
        </FamilyInfo>
      </React.Fragment>
    );
  };

  renderBulletin = (rowData) => {
    const topic = _.get(rowData, 'bulletin.topic');
    const content = _.get(rowData, 'bulletin.content');
    return (
      <React.Fragment>
        <div>{topic}</div>
        <div>{content}</div>
      </React.Fragment>
    );
  };

  renderActions = (rowData) => {
    const onEdit = this.onEditClick.bind(this, rowData);
    return (
      <FamilyActions>
        <Link
          to={`${FAMILY_DETAIL}/${rowData.id}`}
        >
          <FamilyActionsBtn
            icon={<HomeOutlined />}
          />
        </Link>
        <FamilyActionsBtn
          icon={<EditOutlined />}
          onClick={onEdit}
        />
      </FamilyActions>
    );
  };

  render() {
    const { families } = this.props;
    const { pagination } = this.state;
    return (
      <Table
        rowKey={(record) => objectHash(record)}
        dataSource={families}
        columns={this.columns}
        pagination={pagination}
      />
    );
  }
}

export default FamilyList;
