import React from 'react';
import { Input, Select } from 'antd';
import PropTypes from 'prop-types';
import brandName from '../../constants/brandName';

const Option = Select.Option;

const SEARCH_TYPES = {
  nick_name: '暱稱',
  true_love_id: `${brandName} ID`,
  email: 'E-mail',
  login_id: 'Login ID',
  phone_number: '電話號碼',
  uuid: 'UUID',
  all: '查詢全部'
};

class UserSelector extends React.Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      typesEnum: Object.entries(SEARCH_TYPES)
    };
  }

  detectTypeSelectorPlaceholder = () => {
    const { value } = this.props;
    const { type } = value;
    let dynamicText = SEARCH_TYPES[type];
    if (!dynamicText || type === 'all') dynamicText = '關鍵字';
    return `請輸入 ${dynamicText}`;
  };

  triggerChange = changedValue => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue
      });
    }
  };

  onTypeSelected = type => {
    // set focus to keyword input
    const { keywordRef } = this;
    keywordRef.focus();

    // trigger change
    this.triggerChange({ type });
  };

  onKeywordInput = event => {
    const keyword = event.target.value;
    this.triggerChange({ keyword });
  };

  renderTypeSelector = () => {
    const { value } = this.props;
    const { typesEnum } = this.state;
    return (
      <Select
        style={{ width: '7.5rem', textAlign: 'left' }}
        value={value.type}
        onChange={this.onTypeSelected}
      >
        {typesEnum.map(([id, name]) => (
          <Option key={id} title={name}>
            <span>{name}</span>
          </Option>
        ))}
      </Select>
    );
  };

  render() {
    const { value } = this.props;
    return (
      <Input
        ref={input => (this.keywordRef = input)}
        style={{ width: '23rem' }}
        value={value.keyword}
        onChange={this.onKeywordInput}
        placeholder={this.detectTypeSelectorPlaceholder()}
        addonAfter={this.renderTypeSelector()}
        allowClear
      />
    );
  }
}

export default UserSelector;
