import { pick } from 'ramda';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { DatePicker, Form, Input } from 'antd';
import UploadImg, { renderPreviewer } from '../../../components/UploadImg';
import InputI18 from '../../../components/InputI18';
const { RangePicker } = DatePicker;

const FormItem = Form.Item;

const FormRow = styled.div`
  display: flex;

  & > * {
    width: 50%;
    flex-shrink: 0;
  }
`;

const Preview = styled.div`
  height: 10rem;
  margin: 1.75rem 0 0 0.5rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  background: #F5F5F5;
  border: #D9D9D9 solid 1px;
  border-radius: 2px;

  & > img {
    object-fit: contain;
  }
`;

class FlashNewsForm extends React.Component {
  static propTypes = {
    onMount: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      imagePreview: null,
    };
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      this.props.onMount();
    });
  }

  getFormData = () => {
    const form = this.formRef.current;
    return form.getFieldsValue();
  };

  setFormData = (data = {}) => {
    const form = this.formRef.current;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = pick(fields, data);
    const { startTime, endTime } = data;
    if (startTime && endTime) {
      values.dateRange = [
        moment.unix(startTime),
        moment.unix(endTime),
      ];
    }
    const { imageUrl } = values;
    if (imageUrl) {
      this.setState({ imagePreview: imageUrl });
    }
    form.setFieldsValue(values);
  };

  validateFields = () => {
    const form = this.formRef.current;
    return form.validateFields();
  };

  reset = () => {
    const form = this.formRef.current;
    form.resetFields();
  };

  onFormDataChange = (changedValues) => {
    const { imageUrl } = changedValues;
    if (imageUrl) {
      this.setState({ imagePreview: imageUrl });
    }
  }

  renderImagePreview = () => {
    const src = this.state.imagePreview;
    return src && renderPreviewer(src, {
      autoPlay: true,
      loop: true,
    });
  }

  render() {
    return (
      <Form
        ref={this.formRef}
        layout="vertical"
        onValuesChange={this.onFormDataChange}
      >
        <FormItem
          label="廣播內容"
          name={['i18nContents', 'texts']}
          rules={[
            { required: true, message: '請輸入標題' },
          ]}
        >
          <InputI18 />
        </FormItem>
        <FormItem
          label="上下架時間"
          name="dateRange"
          rules={[
            { required: true, message: '請輸入上下架時間' },
          ]}
        >
          <RangePicker
            style={{ width: '100%' }}
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss')
              ]
            }}
          />
        </FormItem>
        <FormRow>
          <FormItem
            label="上傳圖片"
            name="imageUrl"
            rules={[
              { required: true, message: '請上傳圖片' },
            ]}
          >
            <UploadImg
              type="button"
              uploadUrl="/cdn/backend/photo"
            />
          </FormItem>
          <Preview>
            {this.renderImagePreview()}
          </Preview>
        </FormRow>
        <FormItem
          label="連結網址"
          name="linkUrl"
          getValueFromEvent={(event) => event.target.value.trim()}
          rules={[
            { max: 255, message: '連結網址須小於 255 字元' },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          name="type"
          initialValue={'article'}
          noStyle
          hidden
        >
          <Input key="type" type="hidden" />
        </FormItem>
        <FormItem name="id" noStyle hidden>
          <Input key="id" type="hidden" />
        </FormItem>
      </Form>
    );
  }
}

export default FlashNewsForm;
