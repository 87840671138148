import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import {
  Button, Input, DatePicker,
  Select, Table, Tooltip, message,
} from 'antd';
import {
  Form,
  Icon as LegacyIcon,
} from '@ant-design/compatible';
import {
  getSupportListAPI,
  getSupportCategoryAPI,
  setSupportAPI,
} from '../../apis';
import { history } from '../../store';
import { ENQUIRY_ASSISTANCE } from '../../constants/routes';
import { createQs, parseQs } from '../../utils/mixin';
import { encodeSearch, decodeSearch } from '../../utils/codec';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import EnquiryModal from '../../components/EnquiryModal';

const { Column } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;

const StyledSection = styled.section`
  min-width: 900px;
  margin: 0 1rem 0 0;

  .ant-table {
    background-color: #FFF;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    border: 0;
    background: none !important;
    box-shadow: none !important;
    font-size: 1.2em;
    overflow: hidden;

    & + button {
      margin-left: 0.5rem;
    }
  }
`;

const Filters = styled(Form)`
  margin-bottom: 1rem;
`;

const FilterItem = styled(Form.Item)`
  margin: 0;
  display: flex;
  align-items: flex-start;
`;

class EnquiryManagement extends React.Component {

  constructor(props) {
    super(props);

    this.EnquiryModalRef = React.createRef();

    this.state = {
      isLoading: false,
      categories: [],
      dataSource: [],
      search: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  }

  componentDidMount() {
    const { pagination } = this.state;
    const { location } = this.props;

    // set pagination & search input by query string
    const queryStr = parseQs(location.search);
    const current = parseInt(queryStr.page, 10) || 1;
    const search = this.decodeSearch(queryStr.search);
    const newPagination = Object.assign({}, pagination, { current });

    this.setState({ search, pagination: newPagination }, () => {
      this.fetchSupportCategories();
      this.fetchSupportList();
    });
  }

  componentWillUnmount() {
  }

  encodeSearch = () => {
    const { search } = this.state;
    return encodeSearch(search);
  }

  decodeSearch = (search) => {
    return decodeSearch(search) || this.getFiltersValue();
  }

  updateQueryStr = () => {
    const { pagination } = this.state;
    const quertStr = createQs({
      search: this.encodeSearch(),
      page: pagination.current,
    });
    history.push({ search: quertStr });
  }

  getFiltersValue = () => {
    const { form: { getFieldsValue } } = this.props;
    const values = getFieldsValue();

    const startMoment = _.get(values, 'times[0]', Moment.invalid());
    const endMoment = _.get(values, 'times[1]', Moment.invalid());
    if (startMoment.isValid() && endMoment.isValid()) {
      Object.assign(values, {
        startTime: startMoment.format('X'),
        endTime: endMoment.format('X'),
      });
    }
    delete values.times;

    return values;
  }

  showEditModal = (dataset) => {
    const { EnquiryModalRef } = this;
    const modal = EnquiryModalRef.current;
    modal.show(dataset);
  }

  goto = (uri) => {
    history.push(uri);
  }

  fetchSupportCategories = () => {
    const observable = getSupportCategoryAPI();
    observable.subscribe((res) => {
      const { data } = res;
      this.setState({ categories: data });
    });
  }

  fetchSupportList = () => {
    this.setState({ isLoading: true });

    const { pagination, search } = this.state;
    const payload = {
      ...search,
      page: pagination.current,
      item: pagination.pageSize,
    };

    const observable = getSupportListAPI(payload);
    observable.subscribe((res) => {
      const { data, totalCount } = res;
      const newPagination = Object.assign({}, pagination, {
        total: totalCount,
      });

      this.setState({
        dataSource: data,
        pagination: newPagination,
        isLoading: false,
      }, this.updateQueryStr);
    });
  }

  updateSupport = (values) => {
    const observable = setSupportAPI(values);
    observable.subscribe(
      () => {
        message.success('狀態修改成功');
        this.fetchSupportList();
      },
      () => message.error('狀態修改失敗'),
    );
  }

  onSearch = (event) => {
    event.preventDefault();

    const { pagination } = this.state;
    const newPagination = Object.assign({}, pagination, { current: 1 });

    this.setState({
      search: this.getFiltersValue(),
      pagination: newPagination,
    }, () => {
      this.fetchSupportList();
    });
  };

  onTableChange = (iPagination) => {
    const { pagination } = this.state;
    const { current } = iPagination;

    const newPagination = Object.assign({}, pagination, { current });

    this.setState({ pagination: newPagination }, () => {
      this.fetchSupportList();
    });
  };

  renderFilters = () => {
    const { form: { getFieldDecorator } } = this.props;
    const { isLoading, categories, search } = this.state;

    return (
      <Filters layout="inline" onSubmit={this.onSearch}>
        <FilterItem label="標題">
          {getFieldDecorator('keyword', {
            initialValue: search.keyword,
            rules: [{ required: false }],
          })(
            <Input
              style={{ width: 168 }}
              allowClear={true}
              placeholder="請輸入標題"
            />
          )}
        </FilterItem>
        <FilterItem label="狀態">
          {getFieldDecorator('status', {
            initialValue: search.status,
            rules: [{ required: false }],
          })(
            <Input
              style={{ width: 144 }}
              allowClear={true}
              placeholder="請輸入狀態"
            />
          )}
        </FilterItem>
        <FilterItem label="用戶">
          {getFieldDecorator('UUID', {
            initialValue: search.UUID,
            rules: [{ required: false }],
          })(
            <LiveMasterSelect style={{ width: 304 }} roleFilter={null} />
          )}
        </FilterItem>
        <FilterItem label="類型">
          {getFieldDecorator('category', {
            initialValue: search.category,
            rules: [{ required: false }],
          })(
            <Select style={{ width: 144 }}>
              <Option key="all" value="" alt="全部">全部</Option>
              {categories.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  alt={item.label}
                >{item.label}</Option>
              ))}
            </Select>
          )}
        </FilterItem>
        <FilterItem label="日期">
          {getFieldDecorator('times', {
            rules: [{ required: false }],
            initialValue: [
              search.startTime && Moment.unix(search.startTime),
              search.endTime && Moment.unix(search.endTime),
            ],
          })(
            <RangePicker
              allowClear={true}
              showTime={{
                defaultValue: [
                  Moment('00:00:00', 'HH:mm:ss'),
                  Moment('23:59:59', 'HH:mm:ss'),
                ],
              }}
            />
          )}
        </FilterItem>
        <FilterItem>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isLoading}
          >查詢</Button>
        </FilterItem>
      </Filters>
    );
  }

  renderCategory = (rowData) => {
    const { categories } = this.state;
    const id = rowData.category;
    const category = _.find(categories, { id });
    return _.get(category, 'label');
  }

  renderCreateAt = (rowData) => {
    const { createAt } = rowData;
    return createAt ? Moment.unix(createAt).format('YYYY-MM-DD HH:mm:ss') : null;
  }

  renderActions = (rowData) => {
    const { id } = rowData;
    return (
      <Actions>
        <Tooltip title="編輯">
          <Button onClick={() => this.showEditModal(rowData)}>
            <LegacyIcon type="edit" />
          </Button>
        </Tooltip>
        <Tooltip title="回覆">
          <Button onClick={() => this.goto(`${ENQUIRY_ASSISTANCE}/${id}`)}>
            <LegacyIcon type="message" />
          </Button>
        </Tooltip>
      </Actions>
    );
  }

  render() {
    const { dataSource, isLoading, pagination } = this.state;

    return (
      <StyledSection>
        <h1>{'經營管理 > 客服意見管理'}</h1>
        {this.renderFilters()}
        <Table
          rowKey="id"
          dataSource={dataSource}
          loading={isLoading}
          onChange={this.onTableChange}
          pagination={pagination}
        >
          <Column title="問題分類" key="category" render={this.renderCategory} width="8rem"/>
          <Column title="標題" dataIndex="title" key="title" />
          <Column title="暱稱" dataIndex={['user','nickname']} key="userNickname" />
          <Column title="LoginID" dataIndex={['user','loginId']} key="userLoginId" />
          <Column title="發送時間" key="createAt" render={this.renderCreateAt} width="12rem"/>
          <Column title="處理人" dataIndex={['staff','nickname']} key="staffNickname" />
          <Column title="狀態" dataIndex="status" key="status" />
          <Column title="功能" key="actions" render={this.renderActions} width="8rem" />
        </Table>
        <EnquiryModal
          ref={this.EnquiryModalRef}
          onConfirm={this.updateSupport}
        />
      </StyledSection>
    );
  }
}

export default Form.create()(EnquiryManagement);
