export const NobilityEnum = Object.freeze({
  BARON: 'baron',
  DUKE: 'duke',
  EARL: 'earl',
  MARQUESS: 'marquess',
  VISCOUNT: 'viscount',
});

export const NobilitiesSort = Object.freeze([
  NobilityEnum.DUKE,
  NobilityEnum.MARQUESS,
  NobilityEnum.EARL,
  NobilityEnum.VISCOUNT,
  NobilityEnum.BARON,
]);

export const NobilityNameEnum = Object.freeze({
  baron: '男爵',
  duke: '公爵',
  earl: '伯爵',
  marquess: '侯爵',
  viscount: '子爵',
});

export const NobilitiesPathMap = Object.freeze({
  baron: 'barons',
  duke: 'dukes',
  earl: 'earls',
  marquess: 'marquesses',
  viscount: 'viscounts',
});
