import { Button, Form, Input, Modal, Select, Table, Tag, Space } from 'antd';
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/TableContainer';
import { status, contractStatus } from '../../constants/agency';
import { AGENCY_MANAGEMENT } from '../../constants/routes';
import {
  getAgencyList,
  addAgency,
  getAgency,
  setAgency,
  deleteAgency,
  showModal
} from '../../reducers/agencyManagement';
import { createQs } from '../../utils/mixin';
import AgencyFormModal from './AgencyFormModal';
const Search = Input.Search;
const Option = Select.Option;
const FormItem = Form.Item;

const columns = [
  {
    title: '經紀公司名稱',
    dataIndex: 'agencyName',
    render: (value, { agencyId }) => (
      <Link to={`${AGENCY_MANAGEMENT}/${agencyId}`}>{value}</Link>
    )
  },
  {
    title: '合約類型',
    dataIndex: 'contracts',
    render: value => (
      <Space wrap size={[0, 8]}>
        {value.map(contract => (
          <Tag key={contract.id}>{contract.name}</Tag>
        ))}
      </Space>
    )
  },
  {
    title: '合約類型即將到期人數',
    dataIndex: 'expiringNum',
    render: (value, { agencyId }) => (
      <Link
        to={{
          pathname: `${AGENCY_MANAGEMENT}/${agencyId}`,
          search: createQs({
            contractStatusFilter: contractStatus[4].id,
            contractTypeFilter: 'all'
          })
        }}
      >
        {value} 人
      </Link>
    )
  }
];
const defaultSearch = {
  keyword: '',
  agencyStatusFilter: status[0].id,
  page: 1
};
const mapState = ({ agencyManagement }) => agencyManagement;
const mapDispatch = {
  getAgencyList,
  addAgency,
  getAgency,
  setAgency,
  deleteAgency,
  showModal
};
class AgencyManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      ...defaultSearch
    };
  }

  componentDidMount() {
    this.getAgencyList();
  }

  /**
   * 取得經紀公司列表
   */
  getAgencyList = (keyword = '', page = 1) => {
    const { agencyStatusFilter } = this.state;
    this.setState({ page });
    this.props.getAgencyList({
      keyword,
      agencyStatusFilter,
      page
    });
  };
  /**
   * 處理 Table 勾選
   */
  onSelectRowChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    });
  };
  /**
   * 點選分頁
   */
  onPageChange = page => {
    this.getAgencyList(this.state.keyword, page);
  };
  /**
   * 新增
   */
  onAddClick = () => {
    this.setState({ isCreateMode: true });
    this.props.showModal();
  };

  /**
   * 編輯
   */
  onEditClick = () => {
    const { getAgency, showModal } = this.props;
    const { selectedRowKeys } = this.state;
    this.setState({
      isCreateMode: false
    });
    getAgency(selectedRowKeys[0]);
    showModal();
  };

  /**
   * 刪除
   */
  onDelectClick = () => {
    const { deleteAgency } = this.props;
    const { selectedRowKeys } = this.state;
    const { keyword, agencyStatusFilter } = this.state;
    Modal.confirm({
      title: '確定要封存該經紀公司？',
      icon: <ExclamationCircleOutlined />,
      content:
        '封存經紀公司後，你將無法編輯該經紀公司且無法再將直播主納入至該經紀公司旗下',
      onOk: () => {
        deleteAgency(
          selectedRowKeys,
          getAgencyList({
            keyword,
            agencyStatusFilter,
            page: 1
          })
        );
        this.setState({ selectedRowKeys: [], page: 1 });
      }
    });
  };

  /**
   * 送出表單
   */
  onFormSubmit = values => {
    const { addAgency, setAgency } = this.props;
    const { isCreateMode, keyword, page, agencyStatusFilter } = this.state;

    if (isCreateMode) {
      addAgency(
        values,
        getAgencyList({
          ...defaultSearch
        }),
        this.gotoAgency
      );
      this.setState({
        ...defaultSearch
      });
    } else {
      setAgency(
        values,
        getAgencyList({
          keyword,
          agencyStatusFilter,
          page
        })
      );
    }
  };

  /**
   * 關閉表單
   */
  onFormCancel = () => {
    this.props.showModal(false);
  };

  render() {
    const { data, loading, totalCount, isShowModal, currentAgency } =
      this.props;
    const { selectedRowKeys, keyword, agencyStatusFilter, isCreateMode, page } =
      this.state;
    return (
      <>
        <h1>{'經營管理 > 經紀公司管理'}</h1>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormItem style={{ marginRight: '12px', marginBottom: '8px' }}>
            <Search
              allowClear={true}
              value={keyword}
              style={{ width: '304px' }}
              placeholder="請輸入經紀公司名稱或直播主暱稱"
              onChange={event => this.setState({ keyword: event.target.value })}
              onSearch={value => this.getAgencyList(value, 1)}
              enterButton
            />
          </FormItem>
          <FormItem
            label="經紀公司狀態"
            style={{ display: 'flex', marginBottom: '8px' }}
          >
            <Select
              value={agencyStatusFilter}
              onChange={value =>
                this.setState({ agencyStatusFilter: value }, () => {
                  this.getAgencyList(keyword, 1);
                })
              }
              style={{ minWidth: 128 }}
            >
              {status.map(value => (
                <Option key={value.id} title={value.label}>
                  {value.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
        <Space style={{ marginBottom: '8px' }}>
          <Button onClick={this.onAddClick}>新增</Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onEditClick}
          >
            編輯
          </Button>

          <Button
            type="danger"
            onClick={this.onDelectClick}
            disabled={selectedRowKeys.length === 0}
          >
            封存
          </Button>
        </Space>
        <TableContainer>
          <Table
            rowKey="agencyId"
            scroll={{ x: true }}
            loading={loading}
            title={() => (
              <div>
                <InfoCircleOutlined style={{ marginRight: '8px' }} />
                合約即將到期人數： 顯示 60
                天內合約即將到期的直播主。到期後，若未更新合約，將會自動將直播主權限關閉，轉為一般用戶，並結算收益點數。
              </div>
            )}
            rowSelection={{
              selectedRowKeys,
              getCheckboxProps: record => {
                return {
                  disabled: agencyStatusFilter === status[1].id
                };
              },
              onChange: this.onSelectRowChange
            }}
            pagination={{
              pageSize: 20,
              total: totalCount,
              current: page,
              onChange: this.onPageChange
            }}
            columns={columns}
            dataSource={data}
          />
        </TableContainer>
        <AgencyFormModal
          isCreateMode={isCreateMode}
          visible={isShowModal}
          loading={loading}
          editData={isCreateMode ? null : currentAgency}
          onOk={this.onFormSubmit}
          onCancel={this.onFormCancel}
        />
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(AgencyManagement);
