import moment from 'moment';
import { pipe } from 'ramda';
import { switchMap, map } from 'rxjs/operators';
import { ofType, catchRequestError } from '../utils/extendOperators';
import { createAction, handleActions } from 'redux-actions';
import { loadPerformanceGrowthList } from '../apis';

/**
 * Action Types
 */
const GET_PERFORMANCE_GROWTH_LIST = 'GET_PERFORMANCE_GROWTH_LIST';
const GET_PERFORMANCE_GROWTH_LIST_SUCCESS =
  'GET_PERFORMANCE_GROWTH_LIST_SUCCESS';
const GET_PERFORMANCE_GROWTH_LIST_FAILURE =
  'GET_PERFORMANCE_GROWTH_LIST_FAILURE';

/**
 * Action Creators
 */
export const getPerformanceGrowthList = (
  startDate,
  endDate = moment()
    .endOf('day')
    .valueOf(),
  isCustomize = false
) =>
  createAction(GET_PERFORMANCE_GROWTH_LIST)({
    startDate,
    offsetInMillis: 28800000,
    endDate,
    isCustomize
  });

const getPerformanceGrowthListSuccess = createAction(
  GET_PERFORMANCE_GROWTH_LIST_SUCCESS
);
const getPerformanceGrowthListFailure = createAction(
  GET_PERFORMANCE_GROWTH_LIST_FAILURE
);

export const getPerformanceGrowthListEpic = pipe(
  ofType(GET_PERFORMANCE_GROWTH_LIST),
  map(action => action.payload),
  switchMap(payload =>
    loadPerformanceGrowthList(payload).pipe(
      map(getPerformanceGrowthListSuccess),
      catchRequestError(getPerformanceGrowthListFailure)
    )
  )
);

const initialState = {
  data: [],
  startDate: undefined,
  endDate: undefined,
  isCustomize: false
};

export default handleActions(
  {
    [GET_PERFORMANCE_GROWTH_LIST]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [GET_PERFORMANCE_GROWTH_LIST_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data.map(item => ({ ...item }))
    })
  },
  initialState
);
