import { Button, Image, Popconfirm, Space, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSigninEvent,
  deleteSigninEvent,
  getSigninEvent,
  getSigninEventList,
  setSigninEvent,
  showModal
} from '../../reducers/signinEventManagement';
import SigninEventFormModal from './SigninEventFormModal';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '名稱',
    dataIndex: 'name'
  },
  {
    title: '背景',
    dataIndex: 'backgroundUrl',
    render: value => <Image width={48} src={value} />
  },
  {
    title: '指定禮物 Icon',
    dataIndex: 'giftThumbUrl',
    render: value => <Image width={48} src={value} />
  },
  {
    title: '上架時間',
    dataIndex: 'startTime',
    render: value => <p>{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</p>
  },
  {
    title: '下架時間',
    dataIndex: 'endTime',
    render: value => <p>{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</p>
  }
];
const CreateEventManagement = props => {
  const dispatch = useDispatch();
  const { data, loading, totalCount, currentSigninEvent, isShowModal } =
    useSelector(state => state.signinEventManagement);
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [page, setPage] = useState(1);

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedRowKeys
  };

  useEffect(() => {
    dispatch(getSigninEventList());
    return () => {
      setIsCreateMode(true);
      setPage(1);
      setSelectedRowKeys([]);
    };
  }, [dispatch]);

  const onAddClick = () => {
    setIsCreateMode(true);
    dispatch(showModal(true));
  };

  const onEditClick = () => {
    setIsCreateMode(false);
    dispatch(getSigninEvent(selectedRowKeys[0]));
    dispatch(showModal(true));
  };

  const onDeleteClick = id => {
    dispatch(deleteSigninEvent(selectedRowKeys, getSigninEventList()));
    setSelectedRowKeys([]);
    setPage(1);
  };

  const onModalCancel = () => {
    setIsCreateMode(true);
    dispatch(showModal(false));
  };

  const onSubmit = values => {
    if (isCreateMode) {
      dispatch(
        addSigninEvent(values, getSigninEventList(), () => {
          setPage(1);
        })
      );
    } else {
      dispatch(setSigninEvent(values, getSigninEventList({ page })));
    }
    setSelectedRowKeys([]);
  };

  const onPageChange = page => {
    dispatch(getSigninEventList({ page }));
    setPage(page);
    setSelectedRowKeys([]);
  };

  const pagination = {
    showSizeChanger: false,
    pageSize: 20,
    current: page,
    total: totalCount,
    onChange: onPageChange
  };

  return (
    <>
      <Space
        style={{
          marginBottom: 16
        }}
      >
        <Button onClick={onAddClick}>新增</Button>
        <Button disabled={selectedRowKeys.length !== 1} onClick={onEditClick}>
          編輯
        </Button>
        <Popconfirm
          disabled={selectedRowKeys.length === 0}
          key={'cancel'}
          title="是否確定刪除?"
          placement="right"
          onConfirm={onDeleteClick}
        >
          <Button type="danger" disabled={selectedRowKeys.length === 0}>
            刪除
          </Button>
        </Popconfirm>
      </Space>
      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={data}
        rowSelection={rowSelection}
        pagination={pagination}
      />
      <SigninEventFormModal
        isCreateMode={isCreateMode}
        editData={currentSigninEvent}
        open={isShowModal}
        loading={loading}
        onCancel={onModalCancel}
        onOk={onSubmit}
      />
    </>
  );
};
export default CreateEventManagement;
