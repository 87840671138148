export const status = [
  { id: 'all', label: '全部' },
  { id: 'progress', label: '方案使用中' },
  { id: 'archived', label: '方案已封存' }
];
export const type = [
  { id: 'all', label: '全部' },
  { id: 'live_master', label: '一般直播主' },
  { id: 'voice_master', label: '聲聊直播主' },
  { id: 'obs_master', label: 'OBS 直播主' },
  { id: 'private_voice_master', label: '私密聲聊直播主' }
];
