import { Button, Descriptions, List, Modal } from 'antd';
import { indexBy, pipe, prop, toPairs } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { langList } from '../../components/InputI18';
import { userType } from '../../constants/roles';
const langListIndex = indexBy(prop('id'), langList);
const Container = styled.div`
  .ant-descriptions-item-content img {
    max-width: 100%;
  }
`;
const renderI18n = pipe(toPairs, values => (
  <List
    size="small"
    style={{ width: '380px' }}
    dataSource={values}
    renderItem={item => (
      <List.Item>
        <List.Item.Meta
          title={langListIndex[item[0]].label}
          description={item[1] || '無內容'}
        />
      </List.Item>
    )}
  />
));

const AnnouncementPreview = ({
  visible,
  loading,
  value,
  mode,
  coverageCount,
  onCancel,
  onOk
}) => {
  const actionText = mode === 'new' ? '新增' : '更新';
  const title = mode === 'preview' ? '歷史公告' : '預覽';
  const generateFooter = mode => {
    if (mode === 'new' || mode === 'edit') {
      return [
        <Button key={'cancle'} onClick={onCancel}>
          返回
        </Button>,
        <Button key={'add'} loading={loading} type="primary" onClick={onOk}>
          {actionText}
        </Button>
      ];
    }
    return null;
  };
  return (
    <Modal
      visible={visible}
      width={600}
      title={title}
      destroyOnClose={true}
      footer={generateFooter(mode)}
      closable={false}
      onCancel={onCancel}
    >
      {value && (
        <Container>
          <Descriptions column={1} size="small" bordered>
            {mode === 'new' && (
              <Descriptions.Item label="發送人數">
                <span style={{ color: '#f5222d' }}>約 {coverageCount} 人</span>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="用戶類型">
              <span>{userType[value.userType]}</span>
            </Descriptions.Item>
            <Descriptions.Item label="公告標題">
              {renderI18n(value.i18nContents.titles)}
            </Descriptions.Item>
            <Descriptions.Item label="公告訊息內容">
              {renderI18n(value.i18nContents.contents)}
            </Descriptions.Item>
            <Descriptions.Item label="公告圖片">
              {value.imgUrl ? (
                <img alt="preview" src={value.imgUrl} />
              ) : (
                '無圖片'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="圖片連結">
              {value.linkUrl ? (
                <a
                  href={value.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value.linkUrl}
                </a>
              ) : (
                '無連結'
              )}
            </Descriptions.Item>
          </Descriptions>
        </Container>
      )}
    </Modal>
  );
};
export default AnnouncementPreview;
