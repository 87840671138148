import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Select, DatePicker, Button, Table } from 'antd';
import { getPaymentToolStatistics } from '../reducers/paymentToolStatistics';
import { dateOptions } from '../constants/moments';
import downloadCSV from '../utils/downloadCSV';

const { RangePicker } = DatePicker;
const Option = Select.Option;

const Tools = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const columns = [
  {
    title: '支付工具',
    dataIndex: 'purchaseType'
  },
  {
    title: '次數',
    dataIndex: 'counts'
  },
  {
    title: '額度',
    dataIndex: 'amounts'
  }
];

class PaymentToolStatistics extends Component {
  componentDidMount() {
    const {
      getPaymentToolStatistics,
      startDate,
      endDate,
      currentPage,
      pageSize
    } = this.props;
    getPaymentToolStatistics(startDate, endDate, currentPage, pageSize);
  }

  handleTableChange = pagination => {
    const {
      getPaymentToolStatistics,
      startDate,
      endDate,
      pageSize
    } = this.props;
    getPaymentToolStatistics(
      startDate,
      endDate,
      pagination.current - 1,
      pageSize
    );
  };

  onChange = value => {
    const {
      getPaymentToolStatistics,
      startDate,
      endDate,
      currentPage,
      pageSize
    } = this.props;
    if (value !== 'customize') {
      getPaymentToolStatistics(+value, endDate, currentPage, pageSize);
    } else {
      getPaymentToolStatistics(startDate, endDate, currentPage, pageSize, true);
    }
  };

  onDatePickChange = date => {
    const { getPaymentToolStatistics, currentPage, pageSize } = this.props;
    getPaymentToolStatistics(
      date[0].valueOf(),
      date[1].valueOf(),
      currentPage,
      pageSize,
      true
    );
  };

  downloadCSVFromJson = () => {
    const { data } = this.props;
    downloadCSV(
      data,
      ['purchaseType', 'counts', 'amounts'],
      ['支付工具', '次數', '額度']
    );
  };

  render() {
    const {
      data,
      isCustomize,
      startDate,
      endDate,
      currentPage,
      pageSize,
      totalCount
    } = this.props;

    const pagination = {
      defaultCurrent: currentPage + 1,
      pageSize: pageSize,
      total: totalCount
    };

    return (
      <div>
        <h1>{'帳務管理 > 支付工具統計使用報表'}</h1>
        <Tools>
          <Select
            defaultValue={dateOptions.week}
            value={isCustomize ? 'customize' : String(startDate)}
            style={{ width: '8rem' }}
            onChange={this.onChange}
          >
            <Option value={dateOptions.week}>過去一週</Option>
            <Option value={dateOptions.month}>過去一個月</Option>
            <Option value={dateOptions.quart}>過去一季</Option>
            <Option value={dateOptions.half}>過去六個月</Option>
            <Option value={dateOptions.year}>過去一年</Option>
            <Option value="customize">自訂</Option>
          </Select>
          {isCustomize ? (
            <RangePicker
              style={{ width: '22rem' }}
              format="YYYY/MM/DD HH:mm:ss"
              showTime={true}
              value={[moment(+startDate), moment(+endDate)]}
              onChange={this.onDatePickChange}
              allowClear={false}
            />
          ) : null}
          <Button type="primary" onClick={this.downloadCSVFromJson}>
            匯出報表
          </Button>
        </Tools>
        <Table
          columns={columns}
          dataSource={data}
          onChange={this.handleTableChange}
          pagination={pagination}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.paymentToolStatistics.data,
    startDate: state.paymentToolStatistics.startDate,
    endDate: state.paymentToolStatistics.endDate,
    currentPage: state.paymentToolStatistics.currentPage,
    pageSize: state.paymentToolStatistics.pageSize,
    totalCount: state.paymentToolStatistics.totalCount,
    isCustomize: state.paymentToolStatistics.isCustomize
  }),
  { getPaymentToolStatistics }
)(PaymentToolStatistics);
