import {
  Bar, BarChart, CartesianGrid,
  Legend, Tooltip, XAxis, YAxis,
} from 'recharts';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Radio } from 'antd';
import { getDailyStatisticsAPI } from '../../../apis';
import { PageContainer, PageTitle } from '../../../components/styled/page';
import PeriodPicker from '../../../components/PeriodPicker';

const RadioGroup = Radio.Group;

const GoBackLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const Tools = styled.div`
  margin-bottom: 1.5rem;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }
`;

const typeMapping = {
  HOUR: 'hours',
  POINTS: 'points',
};

class DailyStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataset: [],
      filters: {
        type: 'HOUR',
        startDate: null,
        endDate: null,
      },
    };
  }

  fetchDailyStatistics = () => {
    const { match } = this.props;
    const { filters } = this.state;
    const { id } = match.params;
    const { type, startDate, endDate } = filters;
    const body = {
      startDate,
      endDate,
      offsetInMillis: 28800000,
    };
    const payload = { id, type, body };
    const observable = getDailyStatisticsAPI(payload);
    observable.subscribe((res) => {
      const { stslist } = res;
      this.setState({ dataset: stslist });
    });
  }

  onTypeChange = (evt) => {
    const { filters } = this.state;
    const type = evt.target.value;
    const newFilters = { ...filters, type };
    this.setState({
      filters: newFilters,
    }, this.fetchDailyStatistics);
  };

  onPeriodChange = (range) => {
    const { filters } = this.state;
    const startDate = range[0].valueOf();
    const endDate = range[1].valueOf();
    const newFilters = {
      ...filters,
      startDate,
      endDate,
    };
    this.setState({
      filters: newFilters,
    }, this.fetchDailyStatistics);
  }

  render() {
    const { history } = this.props;
    const { dataset, filters } = this.state;
    return (
      <PageContainer>
        <PageTitle>
          <span>{'帳號管理 >'}</span>
          <GoBackLink onClick={() => history.goBack()}>
            <span>{' 直播主帳號管理'}</span>
          </GoBackLink>
          <span>{' > 每日統計資訊'}</span>
        </PageTitle>
        <Tools>
          <PeriodPicker
            onChange={this.onPeriodChange}
          />
          <RadioGroup
            value={filters.type}
            onChange={this.onTypeChange}
          >
            <Radio value="HOUR">直播時數</Radio>
            <Radio value="POINTS">點數</Radio>
          </RadioGroup>
        </Tools>
        {dataset.length > 0 && (
          <BarChart
            width={1000}
            height={500}
            data={dataset}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="dateStr" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Bar
              dataKey={typeMapping[filters.type]}
              fill={filters.type === 'HOUR' ? '#8884d8' : '#82ca9d'}
            />
          </BarChart>
        )}
      </PageContainer>
    );
  }
}

export default withRouter(DailyStatistics);
