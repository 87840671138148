import { message } from 'antd';
import produce from 'immer';
import { groupBy, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  addEffectAPI,
  deleteEffectAPI,
  getEffectAPI,
  getEffectListAPI,
  setEffectAPI
} from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';
export const defaultQuery = { page: 1, item: 20 };
/**Type */
const GET_EffECT_LIST = createRequestTypes('GET_EffECT_LIST');
const ADD_EffECT = createRequestTypes('ADD_EffECT');
const GET_EffECT = createRequestTypes('GET_EffECT');
const SET_EffECT = createRequestTypes('SET_EffECT');
const DELETE_EffECT = createRequestTypes('DELETE_EffECT');
const SHOW_EffECT_MODAL = 'SHOW_EffECT_MODAL';
const RESET_EFFECT = 'RESET_EFFECT';
/**
 * Action Creator
 */
export const getEffectList = createAction(
  GET_EffECT_LIST.REQUEST,
  (payload = {}) => ({ ...defaultQuery, ...payload })
);
export const getEffectListSuccess = createAction(GET_EffECT_LIST.SUCCESS);
export const getEffectListFailure = createAction(GET_EffECT_LIST.FAILURE);
export const showModal = createAction(
  SHOW_EffECT_MODAL,
  (isShowModal = true) => isShowModal
);
export const addEffect = createAction(
  ADD_EffECT.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const addEffectSuccess = createAction(ADD_EffECT.SUCCESS);
export const addEffectFailure = createAction(ADD_EffECT.FAILURE);
export const getEffect = createAction(GET_EffECT.REQUEST);
export const getEffectSuccess = createAction(GET_EffECT.SUCCESS);
export const getEffectFailure = createAction(GET_EffECT.FAILURE);
export const setEffect = createAction(
  SET_EffECT.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const setEffectSuccess = createAction(SET_EffECT.SUCCESS);
export const setEffectFailure = createAction(SET_EffECT.FAILURE);
export const deleteEffect = createAction(
  DELETE_EffECT.REQUEST,
  (ids = [], callbackAction) => ({
    ids,
    callbackAction
  })
);
export const deleteEffectSuccess = createAction(DELETE_EffECT.SUCCESS);
export const deleteEffectFailure = createAction(DELETE_EffECT.FAILURE);
export const resetEffect = createAction(RESET_EFFECT);

/**
 * Epics
 */
export const getEffectListEpic = action$ =>
  action$.pipe(
    ofType(GET_EffECT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getEffectListAPI(payload).pipe(
        map(getEffectListSuccess),
        catchRequestError(getEffectListFailure)
      )
    )
  );

export const addEffectEpic = action$ =>
  action$.pipe(
    ofType(ADD_EffECT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction }) =>
      addEffectAPI(payload).pipe(
        mergeMap(res => {
          const actions = [addEffectSuccess(res)];
          if (callbackAction) actions.push(callbackAction);
          return actions;
        }),
        catchRequestError(addEffectFailure)
      )
    )
  );

export const getEffectEpic = action$ =>
  action$.pipe(
    ofType(GET_EffECT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getEffectAPI(payload).pipe(
        map(getEffectSuccess),
        catchRequestError(addEffectFailure)
      )
    )
  );

export const setEffectEpic = action$ =>
  action$.pipe(
    ofType(SET_EffECT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction }) =>
      setEffectAPI(payload).pipe(
        mergeMap(res => {
          const actions = [setEffectSuccess(res)];
          if (callbackAction) actions.push(callbackAction);
          return actions;
        }),
        catchRequestError(setEffectFailure)
      )
    )
  );

export const deleteEffectEpic = action$ =>
  action$.pipe(
    ofType(DELETE_EffECT.REQUEST),
    pluck('payload'),
    switchMap(({ ids, callbackAction }) => {
      return forkJoin(
        ids.map(id =>
          deleteEffectAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      ).pipe(
        mergeMap(res => {
          const errors = groupBy(v => v.Status)(res);
          const actions = [];
          if (errors.Error?.length > 0) {
            message.error(
              `刪除失敗: ${errors.Error.map(
                e => `id:${e.id} message: ${e.Message}`
              ).join(', ')}`
            );
            actions.push(deleteEffectFailure());
          } else {
            actions.push(deleteEffectSuccess());
          }
          if (callbackAction) actions.push(callbackAction);
          return actions;
        })
      );
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false,
  isShowModal: false,
  currentEffect: undefined,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_EffECT_LIST.REQUEST,
      ADD_EffECT.REQUEST,
      SET_EffECT.REQUEST,
      DELETE_EffECT.REQUEST
    )]: produce(draft => {
      draft.loading = true;
    }),
    [GET_EffECT.REQUEST]: produce(draft => {
      draft.loading = true;
      draft.currentEffect = undefined;
    }),
    [GET_EffECT.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentEffect = payload.data;
    }),
    [combineActions(GET_EffECT.FAILURE, SET_EffECT.FAILURE)]: produce(draft => {
      draft.loading = false;
      draft.currentEffect = undefined;
      draft.isShowModal = false;
    }),
    [combineActions(ADD_EffECT.SUCCESS, SET_EffECT.SUCCESS)]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [GET_EffECT_LIST.SUCCESS]: produce(
      (draft, { payload: { data, totalCount } }) => {
        draft.loading = false;
        draft.data = data;
        draft.totalCount = totalCount;
      }
    ),
    [GET_EffECT_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_EffECT.FAILURE,
      DELETE_EffECT.SUCCESS,
      DELETE_EffECT.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [RESET_EFFECT]: produce((draft, { payload }) => {
      draft.currentEffect = undefined;
    }),
    [SHOW_EffECT_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
