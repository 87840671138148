import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import { groupBy } from 'ramda';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck, mergeMap } from 'rxjs/operators';
import { catchRequestError } from '../utils/extendOperators';

import {
  getAchievementListAPI,
  addAchievementAPI,
  setAchievementAPI,
  deleteAchievementAPI
} from '../apis';
import { forkJoin } from 'rxjs';

export const TypesConfig = {
  stores: {
    name: '商城特效',
    columns: [
      'name',
      'type',
      'startTime',
      'endTime',
      'points',
      'badgeUrl',
      'badgeSimpleUrl',
      'debutUrl',
      'frameUrl',
      'titleIcon',
      'debutDuration'
    ]
  },
  levels: {
    name: '等級特效',
    columns: [
      'name',
      'type',
      'userLevel',
      'badgeUrl',
      'badgeSimpleUrl',
      'debutUrl',
      'frameUrl'
    ]
  },
  events: {
    name: '活動特效',
    columns: [
      'name',
      'type',
      'badgeUrl',
      'badgeSimpleUrl',
      'debutUrl',
      'frameUrl',
      'debutDuration'
    ]
  }
};

export const TypesConfigMap = {
  store: TypesConfig.stores,
  level: TypesConfig.levels,
  event: TypesConfig.events
};

export const UserLevel = {
  silver: '白銀',
  gold: '黃金',
  diamond: '鑽石',
  niello: '黑金'
};

export const DebutLevel = {
  3000: '小',
  4500: '中',
  6000: '大'
};

const SHOW_MODAL = 'ACHIEVEMENT.SHOW_MODAL';
const GET_ACHIEVEMENT = 'GET_ACHIEVEMENT';
const GET_ACHIEVEMENT_SUCCESS = 'GET_ACHIEVEMENT_SUCCESS';
const GET_ACHIEVEMENT_FAILURE = 'GET_ACHIEVEMENT_FAILURE';
const ADD_ACHIEVEMENT = 'ADD_ACHIEVEMENT';
const ADD_ACHIEVEMENT_SUCCESS = 'ADD_ACHIEVEMENT_SUCCESS';
const ADD_ACHIEVEMENT_FAILURE = 'ADD_ACHIEVEMENT_FAILURE';
const DELETE_ACHIEVEMENT = 'DELETE_ACHIEVEMENT';
const DELETE_ACHIEVEMENT_SUCCESS = 'DELETE_ACHIEVEMENT_SUCCESS';
const DELETE_ACHIEVEMENT_FAILURE = 'DELETE_ACHIEVEMENT_FAILURE';
const MODIFY_ACHIEVEMENT = 'MODIFY_ACHIEVEMENT';
const MODIFY_ACHIEVEMENT_SUCCESS = 'MODIFY_ACHIEVEMENT_SUCCESS';
const MODIFY_ACHIEVEMENT_FAILURE = 'MODIFY_ACHIEVEMENT_FAILURE';
export const showModal = createAction(SHOW_MODAL);
export const getAchievement = createAction(GET_ACHIEVEMENT);
export const getAchievementSuccess = createAction(GET_ACHIEVEMENT_SUCCESS);
export const getAchievementFailure = createAction(GET_ACHIEVEMENT_FAILURE);

export const addAchievement = createAction(ADD_ACHIEVEMENT);
export const addAchievementSuccess = createAction(ADD_ACHIEVEMENT_SUCCESS);
export const addAchievementFailure = createAction(ADD_ACHIEVEMENT_FAILURE);

export const modifyAchievement = createAction(MODIFY_ACHIEVEMENT);
export const modifyAchievementSuccess = createAction(
  MODIFY_ACHIEVEMENT_SUCCESS
);
export const modifyAchievementFailure = createAction(
  MODIFY_ACHIEVEMENT_FAILURE
);
export const deleteAchievement = createAction(DELETE_ACHIEVEMENT);
export const deleteAchievementSuccess = createAction(
  DELETE_ACHIEVEMENT_SUCCESS
);
export const deleteAchievementFailure = createAction(
  DELETE_ACHIEVEMENT_FAILURE
);

export const getAchievementEpic = action$ =>
  action$.pipe(
    ofType(GET_ACHIEVEMENT),
    pluck('payload'),
    switchMap(() =>
      getAchievementListAPI().pipe(
        map(getAchievementSuccess),
        catchRequestError(getAchievementFailure)
      )
    )
  );

export const addAchievementEpic = action$ =>
  action$.pipe(
    ofType(ADD_ACHIEVEMENT),
    pluck('payload'),
    switchMap(payload =>
      addAchievementAPI(payload).pipe(
        mergeMap(e => [addAchievementSuccess(e), getAchievement()]),
        catchRequestError(addAchievementFailure)
      )
    )
  );

export const modifyAchievementEpic = action$ => {
  return action$.pipe(
    ofType(MODIFY_ACHIEVEMENT),
    pluck('payload'),
    switchMap(payload =>
      setAchievementAPI(payload).pipe(
        mergeMap(e => [modifyAchievementSuccess(e), getAchievement()]),
        catchRequestError(modifyAchievementFailure)
      )
    )
  );
};

export const delectAchievementEpic = action$ =>
  action$.pipe(
    ofType(DELETE_ACHIEVEMENT),
    pluck('payload'),
    mergeMap(ids => {
      return forkJoin(
        ids.map(id => {
          return deleteAchievementAPI(id).pipe(
            catchRequestError(e => {
              return { Status: 'error', id: id, message: e.message };
            })
          );
        })
      );
    }),
    mergeMap(e => {
      const errors = groupBy(v => v.Status)(e);
      if (errors['error'] && errors['error'].length > 0) {
        return [deleteAchievementFailure()];
      } else {
        return [deleteAchievementSuccess(), getAchievement()];
      }
    })
  );

export const getVisibleAchievementList = (achievements, filterType) => {
  if (filterType === 'all') {
    return Object.values(achievements).reduce(
      (prev, current) => prev.concat(current),
      []
    );
  } else if (achievements[filterType]) {
    return achievements[filterType];
  }
  return [];
};

const initialState = {
  loading: false,
  isShowModal: false,
  achievements: {}
};

export default handleActions(
  {
    [combineActions(
      GET_ACHIEVEMENT,
      ADD_ACHIEVEMENT,
      MODIFY_ACHIEVEMENT,
      DELETE_ACHIEVEMENT
    )]: produce(draft => {
      draft.loading = true;
    }),
    [combineActions(GET_ACHIEVEMENT_SUCCESS)]: produce((draft, { payload }) => {
      draft.achievements = payload;
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [combineActions(
      ADD_ACHIEVEMENT_SUCCESS,
      MODIFY_ACHIEVEMENT_SUCCESS,
      DELETE_ACHIEVEMENT_SUCCESS
    )]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [combineActions(
      ADD_ACHIEVEMENT_FAILURE,
      GET_ACHIEVEMENT_FAILURE,
      MODIFY_ACHIEVEMENT_FAILURE,
      DELETE_ACHIEVEMENT_FAILURE
    )]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
