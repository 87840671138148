import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Message from './Message';

const MessageEnum = new Map([
  ['in', Message.Inout],
  ['out', Message.Inout],
  ['chat', Message.Chat],
  ['gift', Message.GiftPrompt],
]);

const StlyedToEndBtn = styled.button`
  padding: 0.4rem;
  position: absolute;
  left: 50%;
  bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  outline: none;
  transform: translateX(-50%);
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.85);
  }

  i {
    font-size: 1.3rem;
  }
`;

const StyledMessagePool = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  background-color: #E1F5FE;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  user-select: text;

  .message-pool-wrapper {
    width: 100%;
  }

  .message {
    width: 100%;
    padding: 0 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
  }
`;

class MessagePool extends React.Component {

  static propTypes = {
    messages: PropTypes.array,
  }

  static defaultProps = {
    messages: [],
  }

  constructor(props) {
    
    super(props);

    this.MessagePoolRef = React.createRef();

    this.state = {
      isAtBottom: true,
      scrollTimeout: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { messages: prevMessages } = prevProps;
    const { messages } = this.props;
    if (!_.isEqual(prevMessages, messages)) {
      requestAnimationFrame(this.checkToUpdateScroll);
    }
  }

  checkToUpdateScroll = () => {
    const { isAtBottom } = this.state;
    if (isAtBottom) this.scrollToBottom();
  }

  requestToBottom = () => {
    this.setState({ isAtBottom: true });
  }

  scrollToBottom = () => {
    const { MessagePoolRef } = this;
    const el = MessagePoolRef.current;
    el.scroll({ top: el.scrollHeight });
  }

  detectIsAtBottom = () => {
    const { MessagePoolRef } = this;
    const el = MessagePoolRef.current;
    const bottom = el.scrollHeight - el.offsetHeight;
    const isAtBottom = el.scrollTop >= bottom;
    this.setState({ isAtBottom });
  }

  onScroll = () => {
    const { scrollTimeout } = this.state;
    clearTimeout(scrollTimeout);
    this.setState({
      scrollTimeout: setTimeout(this.detectIsAtBottom, 100),
    });
  }

  renderMessage = (message) => {
    const { type, hash } = message;
    const comp = MessageEnum.get(type);
    return comp ? (
      <div key={hash} className="message">
        {React.createElement(comp, { message })}
      </div>
    ) : null;
  }

  renderToEndBtn = () => {
    const { isAtBottom } = this.state;
    return isAtBottom ? null : (
      <StlyedToEndBtn
        type="button"
        className="btn-to-end"
        onClick={this.scrollToBottom}
      >
        <span>捲軸已鎖定，點擊移至最新訊息</span>
      </StlyedToEndBtn>
    );
  }

  render() {
    const { messages } = this.props;
    return (
      <React.Fragment>
        <StyledMessagePool
          ref={this.MessagePoolRef}
          onScroll={this.onScroll}
        >
          <div className="message-pool-wrapper">
            {messages.map(message => this.renderMessage(message))}
          </div>
        </StyledMessagePool>
        {this.renderToEndBtn()}
      </React.Fragment>
    );
  }
}

export default MessagePool;
