import _ from 'lodash';
import { evolve } from 'ramda';
import React from 'react';
import {
  message, Button, Col, Form,
  Input, Row, Select,
} from 'antd';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  getUserInfoAPI,
  withdrawLiveMasterRevenueAPI,
  sendUserAPI,
} from '../../../../apis';
import { PageContainer, PageTitle } from '../../../../components/styled/page';
import WithdrawModal from './WithdrawModal';

const Option = Select.Option;

const GoBackLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const FormItem = styled(Form.Item)`
  display: flex;
  flex-grow: 1;

  .ant-form-item-label {
    width: 6rem;
    flex-shrink: 0;
  }

  .ant-form-item-control-wrapper {
    flex-grow: 1;
  }
`;

const FormRow = styled.div`
  display: flex;
  padding: 0 1rem;

  .ant-form-item.revenueSummary {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
  }
`;

class LiveMasterPersonal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.WithdrawModalRef = React.createRef();

    this.state = {
      userID: null,
      userData: null,
      isWithdrawing: false,
      isSaving: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { match } = this.props;
    const userID = _.get(match, 'params.id');
    if (!userID) return;

    this.setState({ userID }, () => {
      this.fetchUserInfo();
    });
  }

  fetchUserInfo = () => {
    const { userID } = this.state;
    const observable = getUserInfoAPI(userID);
    observable.subscribe((res) => {
      this.setFormData(res);
      this.setState({ userData: res });
    });
  }

  getFormData = () => {
    const form = this.formRef.current;
    const formData = form?.getFieldsValue();
    return evolve({
      identityStatus: Number,
      sexValue: Number,
      revenueSummary: Number,
    }, formData);
  };

  setFormData = (data) => {
    const form = this.formRef.current;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = evolve({
      identityStatus: String,
      sexValue: String,
      revenueSummary: String,
    }, _.pick(data, fields));
    form.setFieldsValue(values);
  };

  withdrawRevenue = (formData) => {
    this.setState({ isWithdrawing: true });

    const { userID } = this.state;
    const now = new Date();
    const month = _.padStart(now.getMonth() + 1, 2, '0');
    const year = now.getFullYear();
    const targetMonth = `${year}-${month}`;
    const observable = withdrawLiveMasterRevenueAPI(userID, {
      ...formData,
      targetMonth,
    });
    observable.subscribe((res) => {
      this.fetchUserInfo();
      message.success('結算成功');
      this.setState({ isWithdrawing: false });
    }, (err) => {
      message.error('結算失敗');
      this.setState({ isWithdrawing: false });
    });
  }

  onWithdrawRevenue = () => {
    const { WithdrawModalRef } = this;
    const modal = WithdrawModalRef.current;
    modal.show();
  }

  onSubmit = () => {
    this.setState({ isSaving: true });

    const { userID } = this.state;
    const formData = this.getFormData();
    const payload = _.pick(formData, [
      'identityStatus',
      'nickname',
      'realName',
      'sexValue',
      'phoneNumber',
    ]);

    const observable = sendUserAPI(userID, payload);
    observable.subscribe(() => {
      this.fetchUserInfo();
      message.success('更新成功');
      this.setState({ isSaving: false });
    }, () => {
      message.error('更新失敗');
      this.setState({ isSaving: false });
    });
  };

  renderHeader = () => {
    const { userID } = this.state;
    const liveMasterAccountMgmtHeader = (
      <PageTitle>
        <span>{'帳號管理 >'}</span>
        <GoBackLink onClick={() => this.props.history.goBack()}>
          <span> 直播主帳號管理 </span>
        </GoBackLink>
        <span>{'> 個人資料'}</span>
      </PageTitle>
    );
    const liveMasterHeader = <h1>{'主播 > 帳號管理'}</h1>;
    return userID ? liveMasterAccountMgmtHeader : liveMasterHeader;
  };

  renderForm = () => {
    const { isWithdrawing, isSaving, userData } = this.state;
    const revenueSummary = _.get(userData, 'revenueSummary', 0);
    const identityStatus = _.get(userData, 'identityStatus');
    const disableWithdraw = isWithdrawing || revenueSummary <= 0;
    return (
      <Form
        ref={this.formRef}
        onFinish={this.onSubmit}
      >
        <Row>
          <Col span={12}>
            <FormRow>
              <FormItem
                label="狀態"
                name="identityStatus"
                initialValue="1"
                hasFeedback={true}
              >
                <Select
                  disabled={identityStatus === '-1' || identityStatus === '0'}
                >
                  <Option value="-2">停權</Option>
                  <Option value="1">使用中</Option>
                </Select>
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label="暱稱"
                name="nickname"
                rules={[
                  { required: true, message: 'Please input your nickname!' },
                ]}
                hasFeedback={true}
              >
                <Input placeholder="暱稱" />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label="真實姓名"
                name="realName"
                rules={[
                  { required: true, message: 'Please input your real name!' },
                ]}
                hasFeedback={true}
              >
                <Input placeholder="真實姓名" />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label="帳號"
                name="loginId"
              >
                <Input disabled />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label="經紀公司"
                name="agencyName"
              >
                <Input disabled />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label="性別"
                name="sexValue"
                hasFeedback={true}
              >
                <Select>
                  <Option value="0">女</Option>
                  <Option value="1">男</Option>
                  <Option value="2">不公開</Option>
                </Select>
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label="綁定手機"
                name="phoneNumber"
                hasFeedback={true}
              >
                <Input />
              </FormItem>
            </FormRow>
          </Col>
          <Col span={12}>
            <FormRow className="revenueSummary">
              <FormItem
                label="未結算業績"
                name="revenueSummary"
                initialValue={0}
              >
                <Input disabled />
              </FormItem>
              <div className="ant-form-item revenueSummary">
                <Button
                  type="danger"
                  onClick={this.onWithdrawRevenue}
                  disabled={disableWithdraw}
                >結算</Button>
              </div>
            </FormRow>
            <FormRow>
              <FormItem
                label="粉絲人數"
                name="fansCount"
              >
                <Input disabled />
              </FormItem>
            </FormRow>
          </Col>
        </Row>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSaving}
          >儲存設定</Button>
        </FormItem>
      </Form>
    );
  }

  render() {
    return (
      <PageContainer>
        {this.renderHeader()}
        {this.renderForm()}
        <WithdrawModal
          ref={this.WithdrawModalRef}
          onSubmit={this.withdrawRevenue}
        />
      </PageContainer>
    );
  }
}

export default withRouter(LiveMasterPersonal);
