import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Modal, Select } from 'antd';
import {
  Form,
  Icon as LegacyIcon,
} from '@ant-design/compatible';
import {
  getManualSettlementList,
  sendSettlementList,
  selectRowKeys,
  openSearchModal,
  closeSearchModal
} from '../reducers/manualSettlement';
import { searchAgencyByKeyword } from '../reducers/agencyList';
import { searchLiveMasterByKeyword } from '../reducers/liveMasterList';

const FormItem = Form.Item;

const payeeType = {
  master: '實況主',
  agency: '經紀公司'
};

class ManualSettlement extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: '單號',
        dataIndex: 'settleNo'
      },
      {
        title: '帳號',
        dataIndex: 'payeeName'
      },
      {
        title: '角色',
        dataIndex: 'payeeType',
        render: text => payeeType[text]
      },
      {
        title: '金額',
        dataIndex: 'amount'
      }
    ];
    this.state = {
      identityList: [],
      agencyList: []
    };
  }

  componentDidMount() {
    const { getManualSettlementList } = this.props;
    getManualSettlementList(0);
  }

  onTableChange = pagination => {
    const { getManualSettlementList } = this.props;
    getManualSettlementList(pagination.current - 1);
  };

  onSearch = () => {
    const { identityList, agencyList } = this.state;
    const { getManualSettlementList } = this.props;
    getManualSettlementList(0, agencyList, identityList);
  };

  onSettle = () => {
    const { sendSettlementList, selectedRows } = this.props;
    sendSettlementList(selectedRows);
  };

  onRowSelectChange = (selectedRowKeys, selectedRows) => {
    const { selectRowKeys } = this.props;
    selectRowKeys(
      selectedRowKeys,
      selectedRows
        .filter(item => item.payeeType === 'master')
        .map(item => item.payeeId),
      selectedRows
        .filter(item => item.payeeType === 'agency')
        .map(item => item.payeeId)
    );
  };

  render() {
    const {
      showModal,
      loading,
      currentPage = 0,
      totalCount = 0,
      list,
      selectedRowKeys,
      searchLiveMasterByKeyword,
      identityListOptions,
      searchAgencyByKeyword,
      agencyListOptions,
      openSearchModal,
      closeSearchModal,
      size
    } = this.props;

    return (
      <div>
        <Modal
          title="搜尋結算列表"
          visible={showModal}
          confirmLoading={loading}
          onCancel={closeSearchModal}
          onOk={this.onSearch}>
          <Form>
            <FormItem label="搜尋直播主">
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                filterOption={false}
                onSearch={value => searchLiveMasterByKeyword(value)}
                onChange={value => {
                  this.setState({ identityList: value });
                }}>
                {identityListOptions.map(item => (
                  <Select.Option key={item.id}>{item.loginId}</Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem label="搜尋經紀公司">
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                filterOption={false}
                onSearch={value => searchAgencyByKeyword(value)}
                onChange={value => {
                  this.setState({ identityList: value.map(Number) });
                }}
                children={agencyListOptions.map(item => (
                  <Select.Option key={item.id}>{item.agencyName}</Select.Option>
                ))}
              />
            </FormItem>
          </Form>
        </Modal>
        <h1>{'帳務管理 > 結算'}</h1>
        <Button type="dashed" onClick={openSearchModal}>
          <LegacyIcon type="search" />搜尋設定
        </Button>
        <br />
        <Table
          columns={this.columns}
          dataSource={list}
          rowSelection={{
            onChange: this.onRowSelectChange,
            selectedRowKeys
          }}
          rowKey="payeeId"
          pagination={{
            current: currentPage + 1,
            pageSize: size,
            total: totalCount
          }}
          onChange={this.onTableChange}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="primary"
            onClick={this.onSettle}
            disabled={selectedRowKeys.length === 0}>
            結算
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.manualSettlement,
    agencyListOptions: state.agencyList.data,
    identityListOptions: state.liveMasterList.data
  }),
  {
    getManualSettlementList,
    sendSettlementList,
    selectRowKeys,
    searchLiveMasterByKeyword,
    searchAgencyByKeyword,
    openSearchModal,
    closeSearchModal
  }
)(ManualSettlement);
