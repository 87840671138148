import React from 'react';
import { Modal } from 'antd';
import produce from 'immer';
import { assocPath, groupBy, is, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  addContractAPI,
  deleteContractAPI,
  getContractAPI,
  getContractListAPI,
  setContractAPI
} from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';
const ERROR_MESSAGE_MAPPING = {
  CONTRACT_HAS_BEEN_REFERENCED: '目前尚有經紀公司使用該分潤方案，因此無法封存'
};
/**Type */
const GET_CONTRACT_LIST = createRequestTypes('GET_CONTRACT_LIST');
const ADD_CONTRACT = createRequestTypes('ADD_CONTRACT');
const GET_CONTRACT = createRequestTypes('GET_CONTRACT');
const SET_CONTRACT = createRequestTypes('SET_CONTRACT');
const DELETE_CONTRACT = createRequestTypes('DELETE_CONTRACT');
const SHOW_CONTRACT_MODAL = 'SHOW_CONTRACT_MODAL';

/**
 * Action Creator
 */
export const getContractList = createAction(
  GET_CONTRACT_LIST.REQUEST,
  (payload = {}) => ({ item: 20, page: 1, ...payload })
);
export const getContractListSuccess = createAction(GET_CONTRACT_LIST.SUCCESS);
export const getContractListFailure = createAction(GET_CONTRACT_LIST.FAILURE);
export const showModal = createAction(
  SHOW_CONTRACT_MODAL,
  (isShowModal = true) => isShowModal
);
export const addContract = createAction(
  ADD_CONTRACT.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback
  })
);
export const addContractSuccess = createAction(ADD_CONTRACT.SUCCESS);
export const addContractFailure = createAction(ADD_CONTRACT.FAILURE);
export const getContract = createAction(GET_CONTRACT.REQUEST);
export const getContractSuccess = createAction(GET_CONTRACT.SUCCESS);
export const getContractFailure = createAction(GET_CONTRACT.FAILURE);
export const setContract = createAction(
  SET_CONTRACT.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const setContractSuccess = createAction(SET_CONTRACT.SUCCESS);
export const setContractFailure = createAction(SET_CONTRACT.FAILURE);
export const deleteContract = createAction(
  DELETE_CONTRACT.REQUEST,
  (ids = [], callbackAction) => ({ ids, callbackAction })
);
export const deleteContractSuccess = createAction(DELETE_CONTRACT.SUCCESS);
export const deleteContractFailure = createAction(DELETE_CONTRACT.FAILURE);

/**
 * Epics
 */
export const getContractListEpic = action$ =>
  action$.pipe(
    ofType(GET_CONTRACT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getContractListAPI(payload).pipe(
        map(getContractListSuccess),
        catchRequestError(getContractListFailure)
      )
    )
  );

export const addContractEpic = action$ =>
  action$.pipe(
    ofType(ADD_CONTRACT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      addContractAPI(payload).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [addContractSuccess(res), callbackAction]
            : [addContractSuccess(res)];
        }),
        catchRequestError(addContractFailure)
      )
    )
  );

export const getContractEpic = action$ =>
  action$.pipe(
    ofType(GET_CONTRACT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getContractAPI(payload).pipe(
        map(res => getContractSuccess(assocPath(['data', 'id'], payload, res))),
        catchRequestError(getContractFailure)
      )
    )
  );

export const setContractEpic = action$ =>
  action$.pipe(
    ofType(SET_CONTRACT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction }) =>
      setContractAPI(payload).pipe(
        mergeMap(res =>
          callbackAction
            ? [setContractSuccess(res), callbackAction]
            : [setContractSuccess(res)]
        ),
        catchRequestError(setContractFailure)
      )
    )
  );

export const deleteContractEpic = action$ =>
  action$.pipe(
    ofType(DELETE_CONTRACT.REQUEST),
    pluck('payload'),
    switchMap(({ ids, callbackAction }) => {
      return forkJoin(
        ids.map(id =>
          deleteContractAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      ).pipe(
        mergeMap(res => {
          const errors = groupBy(v => v.Status)(res);
          const actions = [];
          if (errors.Error?.length > 0) {
            Modal.error({
              title: '無法封存分潤方案',
              content: errors.Error.map(e => (
                <div>
                  ID：{e.id}，原因：
                  {ERROR_MESSAGE_MAPPING[e.Message] || e.Message}
                </div>
              ))
            });
            actions.push(deleteContractFailure());
          } else {
            actions.push(deleteContractSuccess());
          }
          if (callbackAction) actions.push(callbackAction);
          return actions;
        })
      );
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  currentContract: null,
  loading: false,
  isShowModal: false,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_CONTRACT_LIST.REQUEST,
      ADD_CONTRACT.REQUEST,
      SET_CONTRACT.REQUEST,
      DELETE_CONTRACT.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [GET_CONTRACT.REQUEST]: produce(draft => {
      draft.loading = true;
      draft.currentContract = null;
    }),
    [GET_CONTRACT.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentContract = payload.data;
    }),
    [GET_CONTRACT.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.currentContract = null;
    }),
    [combineActions(ADD_CONTRACT.SUCCESS, SET_CONTRACT.SUCCESS)]: produce(
      (draft, { payload }) => {
        draft.loading = false;
        draft.isShowModal = false;
      }
    ),
    [GET_CONTRACT_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
    }),
    [GET_CONTRACT_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_CONTRACT.FAILURE,
      SET_CONTRACT.FAILURE,
      DELETE_CONTRACT.SUCCESS,
      DELETE_CONTRACT.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_CONTRACT_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
