export default {
  default: {
    color: '#9E9E9E',
    backgroundColor: '#E0E0E0',
  },
  oasis: {
    color: '#F56A00',
    backgroundColor: '#FDE3CF',
  },
};
