module.exports = [
  {
    _id: '59bb6eafd018b313f1bfcef3',
    index: 0,
    guid: '36034ba2-6617-4b81-af48-03da16ef428f',
    title: 'Fry Wall',
    rate: 40,
    accountName: 'clarissa8964@gmail.com',
  },
  {
    _id: '59bb6eaf0377bfc4a61513f5',
    index: 1,
    guid: '06bce0b7-ecd3-4090-bbe1-028229005762',
    title: 'Barrett Potter',
    rate: 69,
    accountName: 'leta2661@gmail.com',
  },
  {
    _id: '59bb6eaf89f9f95bd18e188f',
    index: 2,
    guid: '780ef4b9-47d1-4a33-85b8-fe4579e02a32',
    title: 'Moss Craig',
    rate: 58,
    accountName: 'brown8939@gmail.com',
  },
  {
    _id: '59bb6eaf2209a1357eb8f40e',
    index: 3,
    guid: 'c0ede02c-5794-45db-86fe-f34c6eca657c',
    title: 'Etta Gross',
    rate: 83,
    accountName: 'beth9159@gmail.com',
  },
  {
    _id: '59bb6eafad1ffe0916abfde0',
    index: 4,
    guid: '2d8b05a6-28e6-4e98-828f-570fc5b571f7',
    title: 'Harding Norris',
    rate: 31,
    accountName: 'angeline1796@gmail.com',
  },
  {
    _id: '59bb6eafe308e02f9d34b4aa',
    index: 5,
    guid: '41eabfae-158c-462f-9b82-dc73f57f7d2f',
    title: 'Dianne Lott',
    rate: 88,
    accountName: 'joanna8667@gmail.com',
  },
  {
    _id: '59bb6eaf23974e382251a4f9',
    index: 6,
    guid: 'de3b0cdc-63a4-4b8b-b4d4-d058bec1a68d',
    title: 'Dina Welch',
    rate: 45,
    accountName: 'hood6037@gmail.com',
  },
  {
    _id: '59bb6eafbfe053dcc0c01d54',
    index: 7,
    guid: 'e0f30ba2-967c-4698-b5b1-7fd4d8dc09a5',
    title: 'Juana Deleon',
    rate: 100,
    accountName: 'hunt6380@gmail.com',
  },
  {
    _id: '59bb6eaf5d5a17333bc62b5c',
    index: 8,
    guid: '68250ae6-4ea9-49ba-9a7d-a0f389ab629d',
    title: 'Monroe Herrera',
    rate: 47,
    accountName: 'chaney2542@gmail.com',
  },
  {
    _id: '59bb6eaf627762942ed67031',
    index: 9,
    guid: 'bc2cb5f8-d23c-4a4a-8d69-eaf8c12da488',
    title: 'Moreno Joyce',
    rate: 23,
    accountName: 'bennett5064@gmail.com',
  },
  {
    _id: '59bb6eaf3a5db0f008014272',
    index: 10,
    guid: '9a503343-dbcd-4e7a-98c7-21a4d060fa20',
    title: 'Gutierrez Hickman',
    rate: 28,
    accountName: 'vanessa1533@gmail.com',
  },
  {
    _id: '59bb6eaf1b3efbbd78be595f',
    index: 11,
    guid: '48cd1113-2c49-409e-9f15-a2118be6249c',
    title: 'Boyle Roberson',
    rate: 73,
    accountName: 'abbott8376@gmail.com',
  },
];
