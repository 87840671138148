import React from 'react';
import styled from 'styled-components';
import { Radio } from 'antd';
import EmailForm from './EmailForm';
import SMSForm from './SMSForm';
import { PageContainer, PageTitle } from '../../components/styled/page';

const Types = Object.freeze({
  SMS: 'sms',
  EMAIL: 'email',
});

const TabsWrapper = styled.div`
  margin-bottom: 1rem;
`;

class ResetAuth extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      resetType: Types.SMS,
    };
  }

  onTypeRadioChange = ({ target: { value } }) => {
    this.setState({ resetType: value });
  };

  renderForm = () => {
    const { resetType } = this.state;
    const isSMS = resetType === Types.SMS;
    return isSMS ? <SMSForm /> : <EmailForm />;
  }

  render() {
    const { resetType } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'帳號管理 > 重置驗證'}</PageTitle>
        <TabsWrapper>
          <Radio.Group
            value={resetType}
            onChange={this.onTypeRadioChange}
          >
            <Radio.Button value={Types.SMS}>簡訊重置</Radio.Button>
            <Radio.Button value={Types.EMAIL}>Email 重置</Radio.Button>
          </Radio.Group>
        </TabsWrapper>
        {this.renderForm()}
      </PageContainer>
    );
  }
}

export default ResetAuth;
