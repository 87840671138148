import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Table, Button, Popconfirm } from 'antd';
import {
  showModal,
  closeModal,
  getWelcomeBannerList,
  submitWelcomeBannerList,
  deleteWelcomeBannerList,
} from '../../reducers/overlayAdManagement';
import { PageContainer, PageTitle } from '../../components/styled/page';
import CreateOverlayAdFormModal from './CreateOverlayAdFormModal';

const { Column } = Table;

const Actions = styled.header`
  margin-bottom: 1rem;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

class OverlayAdManagement extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedRowKeys: []
    };
  }

  componentDidMount() {
    const { getWelcomeBannerList } = this.props;
    getWelcomeBannerList();
  }

  onSubmit = (formData) => {
    const { submitWelcomeBannerList } = this.props;
    const { currentRange, imageBannerHash } = formData;
    submitWelcomeBannerList({
      startAt: currentRange[0].format('x'),
      endAt: currentRange[1].format('x'),
      imgHash: imageBannerHash,
    });
  };

  renderBannerField = (rowData) => {
    const { bannerUrl } = rowData;
    return (
      <img src={bannerUrl} alt="" style={{ width: 300, height: 'auto' }} />
    );
  }

  renderTimeField = (value) => {
    const format = 'YYYY/MM/DD HH:mm:ss';
    const display = moment(value).format(format);
    return (
      <span>{display}</span>
    );
  }

  render() {
    const { selectedRowKeys } = this.state;
    const { loading, isModalOpen, data } = this.props;
    const { showModal, closeModal, deleteWelcomeBannerList } = this.props;
    return (
      <PageContainer>
        <PageTitle>{'活動管理 > 蓋版廣告管理'}</PageTitle>
        <Actions>
          <Button type="primary" onClick={showModal}>
            <span>新增</span>
          </Button>
          <Popconfirm
            title="是否確定刪除"
            onConfirm={() => deleteWelcomeBannerList(selectedRowKeys)}
            placement="bottomLeft"
          >
            <Button
              type="danger"
              disabled={selectedRowKeys.length === 0}
            >
              <span>刪除</span>
            </Button>
          </Popconfirm>
        </Actions>
        <Table
          rowKey="id"
          dataSource={data}
          loading={loading}
          rowSelection={{
            onChange: selectedRowKeys => this.setState({ selectedRowKeys })
          }}
        >
          <Column
            title="行銷圖片"
            key="banner"
            render={this.renderBannerField}
          />
          <Column
            title="上架時間"
            key="startAt"
            dataIndex="startAt"
            render={this.renderTimeField}
          />
          <Column
            title="下架時間"
            key="endAt"
            dataIndex="endAt"
            render={this.renderTimeField}
          />
        </Table>
        <CreateOverlayAdFormModal
          loading={loading}
          isShowModal={isModalOpen}
          closeModal={closeModal}
          addAD={this.onSubmit}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => state.overlayAdManagement;

const mapDispatchToProps = {
  showModal,
  closeModal,
  getWelcomeBannerList,
  submitWelcomeBannerList,
  deleteWelcomeBannerList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverlayAdManagement);
