import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ChatService from '../../../services/chat';
import ChatActions from './ChatActions';
import MessagePool from './MessagePool';
import LiveRoomContext from '../LiveRoomContext';
import GiftPanel from './GiftPanel';
import { history } from '../../../store';

class ChatRoom extends React.Component {

  static contextType = LiveRoomContext;

  static propTypes = {
    onReceiveGiftPrompt: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.GiftPanelRef = React.createRef();
    this.MessagePoolRef = React.createRef();

    this.state = {
      messages: [],
      messagesLimit: 500,
    };
  }

  componentDidMount() {
    // this.initChatService();
  }

  componentWillUnmount() {
    this.destory();
  }

  initChatService() {
    const { room } = this.context;
    ChatService.init();

    const channel = ChatService.joinRoom(room.roomId);
    channel.on('message_bcst', this.onReceiveChatMessage);
    channel.on('room_in_bcst', this.onReceiveEnterRoom);
    channel.on('room_left_bcst', this.onReceiveLeaveRoom);
    channel.on('gift_bcst', this.onReceiveGiftPrompt);
    this.channel = channel;
  }

  destory() {
    ChatService.disconnect();
  }

  isSelf = (userId) => {
    const { auth } = this.props;
    return auth.id === userId;
  }

  requestPoolToBottom = () => {
    const { MessagePoolRef } = this;
    const pool = MessagePoolRef.current;
    pool.requestToBottom();
  }

  sendMessage = (content) => {
    const { channel } = this;
    channel.push('message', { content });
    this.requestPoolToBottom();
  }

  sendGift = (giftId) => {
    const { channel } = this;
    const { master } = this.context;
    channel.push('gift', {
      giftId,
      targetUserId: master.id,
      count: 1,
    });
    this.requestPoolToBottom();
  }

  addDisplayMessage = (type, data, hash) => {
    const { messages, messagesLimit } = this.state;
    const msg = { type, data, hash };
    const newMessages = [...messages, msg];
    if (newMessages.length > messagesLimit) {
      newMessages.splice(0, 1);
    }
    this.setState({ messages: newMessages });
  }

  onReceiveEnterRoom = (response) => {
    const { data } = response;
    const {
      fromUser: { name: userName },
      joinTime
    } = data;
    const type = 'in';
    const hash = `${joinTime}:${userName}-${type}`;
    this.addDisplayMessage(type, data, hash);
  }

  onReceiveLeaveRoom = (response) => {
    const { data } = response;
    if (this.isSelf(data.userId)) {
      history.replace('/live-list');
      return;
    }
    const { userName, joinTime } = data;
    const type = 'leave';
    const hash = `${joinTime}:${userName}-${type}`;
    this.addDisplayMessage(type, data, hash);
  }

  onReceiveChatMessage = (response) => {
    const { data, sendTime } = response;
    const { userName } = data;
    const type = 'chat';
    const hash = `${sendTime}:${userName}-${type}`;
    this.addDisplayMessage(type, data, hash);
  }

  onReceiveGiftPrompt = (response) => {
    const { data, sendTime } = response;
    const { userName } = data;
    const type = 'gift';
    const hash = `${sendTime}:${userName}-${type}`;
    this.addDisplayMessage(type, data, hash);

    const { onReceiveGiftPrompt } = this.props;
    onReceiveGiftPrompt(response);
  }

  onGiftBtnClick = () => {
    const { GiftPanelRef } = this;
    const panel = GiftPanelRef.current;
    panel.show();
  }
  
  renderMessages = () => {
    const { messages } = this.state;
    return (
      <MessagePool
        ref={this.MessagePoolRef}
        messages={messages}
      ></MessagePool>
    );
  }

  renderActions = () => {
    return (
      <ChatActions
        onGiftBtnClick={this.onGiftBtnClick}
        onSendMessage={this.sendMessage}
      ></ChatActions>
    );
  }

  renderGiftPannel = () => {
    return (
      <GiftPanel
        ref={this.GiftPanelRef}
        onSendGift={this.sendGift}
      ></GiftPanel>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <div className="chatroom-header">實況聊天室</div>
        <div className="chatroom-body">
          {this.renderMessages()}
        </div>
        <div className="chatroom-footer">
          {this.renderActions()}
        </div>
        {this.renderGiftPannel()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const StyledChatRoom = styled(ChatRoom)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FAFAFA;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  .chatroom-header {
    border-bottom: 1px solid #EEE;
    font-size: 1rem;
    line-height: 2.5;
    text-align: center;
  }

  .chatroom-body {
    position: relative;
    height: calc(100% - 5.75rem);
  }

  .gift-pannel {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 20rem;
  }
`;

export default connect(
  mapStateToProps,
)(StyledChatRoom);
