import * as Types from '../actions/Types';
import { handleActions, createAction } from 'redux-actions';
import { pipe } from 'ramda';
import { pluck, switchMap, map } from 'rxjs/operators';
import { ofType, catchRequestError } from '../utils/extendOperators';
import {
  loadDauReports,
  loadMauReports,
  loadLiveMasterList,
  loadUserExportDataAPI
} from '../apis';

/**
 * Action Creators
 */
const loadDauReportsSuccess = createAction(Types.LOAD_DAU_REPORTS.SUCCESS);
const loadDauReportsFailure = createAction(Types.LOAD_DAU_REPORTS.FAILURE);
const loadMauReportsSuccess = createAction(Types.LOAD_MAU_REPORTS.SUCCESS);
const loadMauReportsFailure = createAction(Types.LOAD_MAU_REPORTS.FAILURE);
const loadLiveMasterListSuccess = createAction(
  Types.LOAD_LIVE_MASTER_LIST.SUCCESS
);
const loadLiveMasterListFailure = createAction(
  Types.LOAD_LIVE_MASTER_LIST.FAILURE
);
const loadUserExportDataSuccess = createAction(
  Types.LOAD_USER_EXPORT_DATA.SUCCESS
);
const loadUserExportDataFailure = createAction(
  Types.LOAD_USER_EXPORT_DATA.FAILURE
);

/**
 * Epics
 */
export const loadDauReportsEpic = pipe(
  ofType(Types.LOAD_DAU_REPORTS.REQUEST),
  pluck('payload'),
  switchMap(payload => loadDauReports(payload)),
  map(loadDauReportsSuccess),
  catchRequestError(loadDauReportsFailure)
);

export const loadMauReportsEpic = pipe(
  ofType(Types.LOAD_MAU_REPORTS.REQUEST),
  pluck('payload'),
  switchMap(payload => loadMauReports(payload)),
  map(loadMauReportsSuccess),
  catchRequestError(loadMauReportsFailure)
);

export const loadLiveMasterListEpic = pipe(
  ofType(Types.LOAD_LIVE_MASTER_LIST.REQUEST),
  pluck('payload'),
  switchMap(payload => loadLiveMasterList(payload)),
  map(loadLiveMasterListSuccess),
  catchRequestError(loadLiveMasterListFailure)
);

export const loadUserExportDataEpic = pipe(
  ofType(Types.LOAD_USER_EXPORT_DATA.REQUEST),
  pluck('payload'),
  switchMap(payload => loadUserExportDataAPI(payload)),
  map(loadUserExportDataSuccess),
  catchRequestError(loadUserExportDataFailure)
);

/**
 * Reducer
 */
const initialState = {
  isLoading: false,
  dataMap: {},
  liveMasterList: [],
  photoReportList: [],
  userExportData: []
};

export default handleActions(
  {
    [Types.LOAD_DAU_REPORTS.REQUEST]: (state, action) => ({
      ...state,
      isLoading: true
    }),
    [Types.LOAD_DAU_REPORTS.SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false
    }),
    [Types.LOAD_DAU_REPORTS.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false
    }),
    [Types.LOAD_MAU_REPORTS.REQUEST]: (state, action) => ({
      ...state,
      isLoading: true
    }),
    [Types.LOAD_MAU_REPORTS.SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false
    }),
    [Types.LOAD_MAU_REPORTS.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false
    }),
    [Types.LOAD_LIVE_MASTER_LIST.REQUEST]: (state, action) => ({
      ...state,
      isLoading: true
    }),
    [Types.LOAD_LIVE_MASTER_LIST.SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false
    }),
    [Types.LOAD_LIVE_MASTER_LIST.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false
    }),
    [Types.LOAD_USER_EXPORT_DATA.REQUEST]: (state, action) => ({
      ...state,
      isLoading: true
    }),
    [Types.LOAD_USER_EXPORT_DATA.SUCCESS]: (state, action) => ({
      ...state,
      userExportData: action.payload,
      isLoading: false
    }),
    [Types.LOAD_USER_EXPORT_DATA.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false
    })
  },
  initialState
);
