import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin
} from 'antd';

import {
  and,
  assoc,
  both,
  complement,
  compose,
  cond,
  converge,
  evolve,
  has,
  identity,
  ifElse,
  is,
  isNil,
  map,
  prop,
  props,
  reverse,
  T,
  toLower,
  when,
  where,
  whereEq,
  __
} from 'ramda';
import React, { useEffect } from 'react';
import BundleUpload from '../../components/BundleUpload';
import UploadImg from '../../components/UploadImg';
import { sfxSubTypeList, sfxTypeList, typeList } from '../../constants/effect';
import { useHasErrors } from '../../hooks/useHasErrors';
import { isReallyEmpty } from '../../utils/mixin';
import SFXDate from './SFXDate';

const isNotNil = complement(isNil);
const { Item: FormItem } = Form;
const { Option } = Select;
const BubbleDpiSetting = [
  {
    label: 'mdpi',
    dataIndex: 'bubbleMdpiUrl',
    imageSize: { width: 82, height: 38 }
  },
  {
    label: 'hdpi',
    dataIndex: 'bubbleHdpiUrl',
    imageSize: { width: 122, height: 56 }
  },
  {
    label: 'xhdpi',
    dataIndex: 'bubbleXhdpiUrl',
    imageSize: { width: 162, height: 74 }
  },
  {
    label: 'xxhdpi',
    dataIndex: 'bubbleXxhdpiUrl',
    imageSize: { width: 242, height: 110 }
  },
  {
    label: 'xxxhdpi',
    dataIndex: 'bubbleXxxhdpiUrl',
    imageSize: { width: 322, height: 146 }
  },
  {
    label: 'ioshdpi',
    dataIndex: 'bubbleIoshdpiUrl',
    imageSize: { width: 240, height: 108 }
  }
];
const sfxSubTypeOptions = sfxSubTypeList.slice(1);
const sfxTypeOptions = compose(
  reverse,
  map(
    evolve({
      id: toLower,
      label: value => (value === '通用型' ? `${value} (所有直播主)` : value)
    })
  )
)(sfxTypeList);
const getPreviewUrl = cond([
  [is(String), identity],
  [both(is(Object), has('previewUrl')), prop('previewUrl')],
  [T, identity]
]);
const mapDataToForm = compose(
  evolve({ debutDuration: value => (value === 0 ? null : value) }),
  when(
    isNotNil,
    ifElse(
      and(
        where({ sfxStartDate: isNotNil, sfxEndDate: isNotNil }),
        whereEq({ sfxStartDate: 1 })
      ),
      assoc('sfxDate', 'forever'),
      converge(assoc('sfxDate', __, __), [
        props(['sfxStartDate', 'sfxEndDate']),
        identity
      ])
    )
  )
);
const sfxDateValidator = (rule, value, callback) => {
  if (value === 'temporary') {
    return Promise.reject(new Error('請設定上下架日期'));
  } else {
    return Promise.resolve();
  }
};

const EffectFormModal = ({
  onOk,
  onCancel,
  isCreateMode,
  loading,
  editData,
  open
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();

  useEffect(() => {
    if (open) {
      if (!isReallyEmpty(editData) && !isCreateMode) {
        form.setFieldsValue(mapDataToForm(editData));
      }
    }
  }, [form, editData, open, isCreateMode]);

  const getCopyClickHandler = (formField, toField) => () => {
    const { getFieldValue, setFieldsValue } = form;
    setFieldsValue({ [toField]: getPreviewUrl(getFieldValue(formField)) });
    getValidateFieldHandler(toField)();
  };

  const getValidateFieldHandler = filedName => () => {
    requestAnimationFrame(() =>
      form.validateFields([filedName], { force: true })
    );
  };

  const renderEffectColumn = ({ getFieldValue }) => {
    const selectedType = getFieldValue('type');
    if (selectedType === typeList[0].id) {
      return renderEventColumn();
    } else if (selectedType === typeList[1].id) {
      return renderSFXColumn();
    } else if (selectedType === typeList[2].id) {
      return renderBubbleColumn();
    } else {
      return null;
    }
  };

  const renderEventColumn = () => {
    const { getFieldValue } = form;
    const badgeUrl = getFieldValue('badgeUrl');
    const badgeSimpleUrl = getFieldValue('badgeSimpleUrl');
    const debutUrl = getFieldValue('debutUrl');
    const frameUrl = getFieldValue('frameUrl');

    return (
      <>
        <Row>
          <Col span={8}>
            <FormItem label="頭銜" name="badgeUrl">
              <UploadImg
                accept="image/*"
                uploadUrl="/cdn/achievement/badge"
                onChange={getValidateFieldHandler('badgePreviewUrl')}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label={
                <>
                  頭銜 - 縮圖
                  <Button
                    size="small"
                    disabled={isReallyEmpty(badgeUrl)}
                    type="link"
                    onClick={getCopyClickHandler('badgeUrl', 'badgePreviewUrl')}
                  >
                    複製原檔
                  </Button>
                </>
              }
              name="badgePreviewUrl"
              rules={[
                {
                  required: isNotNil(badgeUrl),
                  message: '請上傳縮圖'
                }
              ]}
            >
              <UploadImg accept="image/*" uploadUrl="/cdn/achievement/badge" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="說明描述" name="badgeDesc">
              <Input placeholder="請輸入描述" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <FormItem label="徽章" name="badgeSimpleUrl">
              <UploadImg
                accept="image/*"
                uploadUrl="/cdn/achievement/badge"
                onChange={getValidateFieldHandler('badgeSimplePreviewUrl')}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label={
                <>
                  徽章 - 縮圖
                  <Button
                    size="small"
                    disabled={isReallyEmpty(badgeSimpleUrl)}
                    type="link"
                    onClick={getCopyClickHandler(
                      'badgeSimpleUrl',
                      'badgeSimplePreviewUrl'
                    )}
                  >
                    複製原檔
                  </Button>
                </>
              }
              name="badgeSimplePreviewUrl"
              rules={[
                {
                  required: isNotNil(badgeSimpleUrl),
                  message: '請上傳縮圖'
                }
              ]}
            >
              <UploadImg accept="image/*" uploadUrl="/cdn/achievement/badge" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="說明描述" name="badgeSimpleDesc">
              <Input placeholder="請輸入描述" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <FormItem
              label="進場特效"
              name="debutUrl"
              rules={[
                {
                  required: false,
                  message: '請上傳進場特效'
                }
              ]}
            >
              <UploadImg
                autoPlay={true}
                loop={true}
                accept="video/*"
                uploadUrl="/cdn/achievement/debut"
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="進場特效 - 縮圖"
              name="debutPreviewUrl"
              rules={[
                {
                  required: isNotNil(debutUrl),
                  message: '請上傳縮圖'
                }
              ]}
            >
              <UploadImg
                autoPlay={true}
                loop={true}
                accept="image/*"
                uploadUrl="/cdn/achievement/debut"
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="說明描述" name="debutDesc">
              <Input placeholder="請輸入描述" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <FormItem label="頭像框" name="frameUrl">
              <UploadImg
                accept="image/*"
                uploadUrl="/cdn/achievement/frame"
                onChange={getValidateFieldHandler('framePreviewUrl')}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label={
                <>
                  頭像框 - 縮圖
                  <Button
                    size="small"
                    disabled={isReallyEmpty(frameUrl)}
                    type="link"
                    onClick={getCopyClickHandler('frameUrl', 'framePreviewUrl')}
                  >
                    複製原檔
                  </Button>
                </>
              }
              name="framePreviewUrl"
              rules={[
                {
                  required: isNotNil(frameUrl),
                  message: '請上傳縮圖'
                }
              ]}
            >
              <UploadImg accept="image/*" uploadUrl="/cdn/achievement/frame" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="說明描述" name="frameDesc">
              <Input placeholder="請輸入描述" />
            </FormItem>
          </Col>
        </Row>
        <FormItem name="subType" initialValue="PACKAGE" hidden>
          <Input key="subType" type="hidden" />
        </FormItem>
        <FormItem name="debutDuration" initialValue={2500} hidden>
          <Input key="debutDuration" type="hidden" />
        </FormItem>
      </>
    );
  };

  const renderSFXColumn = () => {
    const { getFieldValue } = form;
    const sfxDuration = getFieldValue('sfxDuration');
    return (
      <>
        <FormItem label="啟用說明" name="remark">
          <Input />
        </FormItem>
        <FormItem
          label="分類"
          name="subType"
          rules={[
            {
              required: true,
              message: '請選擇分類'
            }
          ]}
        >
          <Select>
            {sfxSubTypeOptions.map(item => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="類別"
          name="sfxDuration"
          rules={[
            {
              required: true,
              message: '請選擇分類'
            }
          ]}
        >
          <Radio.Group
            onChange={value => {
              if (value?.target?.value === 'temporary') {
                form.setFieldValue('sfxDate', null);
              }
            }}
          >
            {sfxTypeOptions.map(item => (
              <Radio key={item.id} value={item.id}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
        </FormItem>
        <FormItem
          label="通用型時效設定"
          name="sfxDate"
          rules={[
            {
              required: sfxDuration !== 'temporary',
              message: '請設定時效'
            },
            {
              validator: sfxDateValidator
            }
          ]}
        >
          <SFXDate disabled={sfxDuration === 'temporary'}></SFXDate>
        </FormItem>
        <FormItem
          label="Bundle 檔"
          name="sfxBundleUrl"
          rules={[
            {
              required: true,
              message: '請上傳 Bundle 檔案'
            }
          ]}
        >
          <BundleUpload />
        </FormItem>
        <FormItem
          label="預覽效果"
          name="sfxPreviewUrl"
          rules={[
            {
              required: true,
              message: '請上傳預覽圖檔'
            }
          ]}
        >
          <UploadImg accept="image/*" uploadUrl="/cdn/sfx/preview" />
        </FormItem>
        <FormItem
          label="縮圖"
          name="sfxThumbnailUrl"
          rules={[
            {
              required: true,
              message: '請上傳縮圖'
            }
          ]}
        >
          <UploadImg accept="image/*" uploadUrl="/cdn/sfx/thumbnail" />
        </FormItem>
      </>
    );
  };

  const renderBubbleColumn = form => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {BubbleDpiSetting.map(bubble => (
            <FormItem
              key={bubble.dataIndex}
              name={bubble.dataIndex}
              label={bubble.label}
              style={{ width: '112px', height: '166px' }}
              rules={[
                {
                  required: true,
                  message: `請上傳 ${bubble.label}`
                }
              ]}
            >
              <UploadImg
                accept="image/*"
                uploadUrl="/cdn/bubble/frame"
                imageSize={bubble.imageSize}
              />
            </FormItem>
          ))}
        </div>
        <Row>
          <Col span={12}>
            <FormItem
              label="留言框"
              name="bubbleUrl"
              rules={[
                {
                  required: true,
                  message: '請上傳留言框'
                }
              ]}
            >
              <UploadImg accept="image/*" uploadUrl="/cdn/bubble/url" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="留言框-縮圖"
              name="bubblePreviewUrl"
              rules={[
                {
                  required: true,
                  message: '請上傳縮圖'
                }
              ]}
            >
              <UploadImg accept="image/*" uploadUrl="/cdn/bubble/preview" />
            </FormItem>
          </Col>
        </Row>
        <FormItem name="subType" initialValue="PACKAGE" hidden>
          <Input key="subType" type="hidden" />
        </FormItem>
      </>
    );
  };

  const onTypeChange = value => {
    if (value === 'EVENT' && form.getFieldValue('subType')) {
      form.setFieldsValue({ subType: 'PACKAGE' });
    } else if (value === 'SFX') {
      form.setFieldsValue({ subType: null });
    }
  };

  const onFinish = values => {
    onOk(values);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };

  return (
    <Modal
      width={720}
      title={`${isCreateMode ? '新增' : '編輯'} 特效`}
      onCancel={onCancel}
      open={open}
      afterClose={onAfterClose}
      footer={[
        <Popconfirm
          key="confirm"
          title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
          placement="topLeft"
          disabled={hasErrors}
          onConfirm={() => form.submit()}
        >
          <Button
            key={'add'}
            loading={loading}
            type="primary"
            disabled={hasErrors}
          >
            確定
          </Button>
        </Popconfirm>,
        <Button key="cancle" style={{ marginLeft: 8 }} onClick={onCancel}>
          取消
        </Button>
      ]}
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFieldsChange={validateStatus}
        >
          <FormItem
            label="名稱"
            name="name"
            rules={[
              {
                required: true,
                message: '請輸入名稱'
              }
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="類型"
            name="type"
            rules={[
              {
                required: true,
                message: '請選擇類型'
              }
            ]}
          >
            <Select onChange={onTypeChange}>
              {typeList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem noStyle shouldUpdate>
            {renderEffectColumn}
          </FormItem>
          {!isCreateMode && (
            <FormItem name="id" noStyle hidden>
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default EffectFormModal;
