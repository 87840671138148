import _ from 'lodash';
import moment from 'moment';
import objHash from 'object-hash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, DatePicker, Table } from 'antd';
import { getUsageListAPI } from '../../apis';
import downloadCSV from '../../utils/downloadCSV';

const { Column } = Table;
const { RangePicker } = DatePicker;

const UsageRecordWrapper = styled.div`
  .usage-record-ragnge {
    width: 24rem !important;
    margin-bottom: 1rem;
  }
  .usage-record-tools {
    display: flex;
    justify-content: space-between;
  }
  .ant-pagination {
    width: 100%;
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: center;
  }
`;

class UsageRecord extends React.Component {

  static propTypes = {
    userId: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      records: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
      },
      timeRange: {
        startTime: moment().startOf('day').subtract(7, 'DAY'),
        endTime: moment().endOf('day'),
      },
    };
  }

  componentDidMount() {
    this.fetchUsageRecord();
  }

  createUsageRecordObservable = (iOptions) => {
    const { userId } = this.props;
    const { timeRange, pagination } = this.state;

    const startTime = timeRange.startTime.unix();
    const endTime = timeRange.endTime.unix();
    const options = Object.assign({
      id: userId,
      item: pagination.pageSize,
      page: pagination.current,
      startTime,
      endTime,
    }, iOptions);

    return getUsageListAPI(options);
  }

  fetchUsageRecord = () => {
    this.setState({ isLoading: true });

    const { pagination } = this.state;
    const observable = this.createUsageRecordObservable();
    observable.subscribe((res) => {
      const { data, totalCount } = res;
      const newPagination = Object.assign({}, pagination, {
        total: totalCount,
      });
      const records = _.map(data, (r, index) => ({
        ...r,
        $id: index,
      }));
      this.setState({
        records,
        pagination: newPagination,
        isLoading: false,
      });
    });
  }

  generateRowKey = (record) => {
    return objHash(record);
  }

  onExportClick = () => {
    const { timeRange } = this.state;
    const startTime = timeRange.startTime.unix();
    const endTime = timeRange.endTime.unix();
    const observable = this.createUsageRecordObservable({
      item: 99999,
      page: 1,
      startTime,
      endTime,
    });
    observable.subscribe((res) => {
      const { data } = res;
      if (_.isEmpty(data)) return;

      const exportData = _.map(data, rowData => {
        const createAt = this.renderCreateAt(rowData);
        const liveMasterNickname = this.renderLiveMasterNickname(rowData);
        return {
          createAt,
          liveMasterNickname,
          giftName: rowData.giftName,
          point: rowData.point,
          agency: rowData.agency,
        };
      });

      downloadCSV(
        exportData,
        ['createAt', 'liveMasterNickname', 'agency', 'giftName', 'point'],
        ['使用時間', '直播主', '經紀公司', '禮物名稱', '送禮總點數'],
      );
    });
  }

  onRangeChanged = (value) => {
    const timeRange = {
      startTime: value[0],
      endTime: value[1],
    };
    this.setState({ timeRange }, this.fetchUsageRecord);
  }

  onTableChange = iPagination => {
    const { pagination } = this.state;
    const newPagination = Object.assign({}, pagination, {
      current: iPagination.current,
    });

    this.setState({ pagination: newPagination }, () => {
      this.fetchUsageRecord();
    });
  };

  renderCreateAt = (record) => {
    const { createAt } = record;
    return moment.unix(createAt).format('YYYY-MM-DD HH:mm:ss');
  }

  renderLiveMasterNickname = (record) => {
    const {
      liveMasterNickname,
      liveMasterLoginId,
    } = record;
    return `${liveMasterNickname} (${liveMasterLoginId})`;
  }

  render() {
    const {
      isLoading,
      timeRange,
      records,
      pagination,
    } = this.state;
    return (
      <UsageRecordWrapper>
        <div className="usage-record-tools">
          <RangePicker
            className="usage-record-ragnge"
            defaultValue={[
              timeRange.startTime,
              timeRange.endTime,
            ]}
            onOk={this.onRangeChanged}
            allowClear={false}
            showTime
          />
          <Button
            type="primary"
            onClick={this.onExportClick}
          >
            <span>匯出報表</span>
          </Button>
        </div>
        <Table
          rowKey={this.generateRowKey}
          dataSource={records}
          loading={isLoading}
          onChange={this.onTableChange}
          pagination={pagination}
        >
          <Column
            title="使用時間"
            key="createAt"
            render={this.renderCreateAt}
          />
          <Column
            title="直播主"
            key="liveMasterNickname"
            render={this.renderLiveMasterNickname}
          />
          <Column
            title="經紀公司"
            key="agency"
            dataIndex="agency"
          />
          <Column
            title="禮物名稱"
            key="giftName"
            dataIndex="giftName"
          />
          <Column
            title="送禮總點數"
            key="point"
            dataIndex="point"
          />
        </Table>
      </UsageRecordWrapper>
    );
  }
}

export default UsageRecord;
