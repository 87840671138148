import React, { Component } from 'react';
import { Table, message, Button } from 'antd';

const dataSource = [
  {
    key: 1,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 2,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 3,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 4,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 5,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 6,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 7,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 8,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 9,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 10,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
  {
    key: 11,
    applyAccount: '申請帳號',
    applyTime: '2017-09-18 12:34:56',
  },
];


const success = () => {
  message.success('批准成功');
};

class AnchorApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applyType: 'agency',
    };
    this.columns = [{
      title: '申請帳號',
      dataIndex: 'applyAccount',
    }, {
      title: '申請時間',
      dataIndex: 'applyTime',
    }, {
      title: '功能',
      dataIndex: 'features',
      render: () => 
        <div>
          <Button type="primary" onClick={success}>批准</Button>
          <Button>拒絕</Button>
        </div>,
    }];
  }

  render() {
    return (
      <div>
        <h1>經紀公司 > 主播加入申請</h1>
        <Table columns={this.columns} dataSource={dataSource} />
      </div>
    );
  }
}

export default AnchorApplication;