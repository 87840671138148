import { message } from 'antd';
import produce from 'immer';
import { groupBy, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, pluck, switchMap, map } from 'rxjs/operators';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { createRequestTypes } from '../actions/Types';
import {
  addSpotlightAPI,
  deleteSpotlightAPI,
  getSpotlightListAPI,
  setSpotlightAPI
} from '../apis';

/**Type */
const GET_SPOTLIGHT_LIST = createRequestTypes('GET_SPOTLIGHT_LIST');
const ADD_SPOTLIGHT = createRequestTypes('ADD_SPOTLIGHT');
const SET_SPOTLIGHT = createRequestTypes('SET_SPOTLIGHT');
const DELETE_SPOTLIGHT = createRequestTypes('DELETE_SPOTLIGHT');
const SHOW_SPOTLIGHT_MODAL = 'SHOW_SPOTLIGHT_MODAL';

/**
 * Action Creator
 */
export const getSpotlightList = createAction(GET_SPOTLIGHT_LIST.REQUEST);
export const getSpotlightListSuccess = createAction(GET_SPOTLIGHT_LIST.SUCCESS);
export const getSpotlightListFailure = createAction(GET_SPOTLIGHT_LIST.FAILURE);
export const showModal = createAction(
  SHOW_SPOTLIGHT_MODAL,
  (isShowModal = true) => isShowModal
);
export const addSpotlight = createAction(ADD_SPOTLIGHT.REQUEST);
export const addSpotlightSuccess = createAction(ADD_SPOTLIGHT.SUCCESS);
export const addSpotlightFailure = createAction(ADD_SPOTLIGHT.FAILURE);
export const setSpotlight = createAction(SET_SPOTLIGHT.REQUEST);
export const setSpotlightSuccess = createAction(SET_SPOTLIGHT.SUCCESS);
export const setSpotlightFailure = createAction(SET_SPOTLIGHT.FAILURE);
export const deleteSpotlight = createAction(
  DELETE_SPOTLIGHT.REQUEST,
  (ids = []) => ids
);
export const deleteSpotlightSuccess = createAction(DELETE_SPOTLIGHT.SUCCESS);
export const deleteSpotlightFailure = createAction(DELETE_SPOTLIGHT.FAILURE);

/**
 * Epics
 */
export const getSpotlightListEpic = action$ =>
  action$.pipe(
    ofType(GET_SPOTLIGHT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getSpotlightListAPI().pipe(
        map(getSpotlightListSuccess),
        catchRequestError(getSpotlightListFailure)
      )
    )
  );

export const addSpotlightEpic = action$ =>
  action$.pipe(
    ofType(ADD_SPOTLIGHT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      addSpotlightAPI(payload).pipe(
        mergeMap(res => [addSpotlightSuccess(res), getSpotlightList()]),
        catchRequestError(addSpotlightFailure)
      )
    )
  );

export const setSpotlightEpic = action$ =>
  action$.pipe(
    ofType(SET_SPOTLIGHT.REQUEST),
    pluck('payload'),
    mergeMap(payload =>
      setSpotlightAPI(payload).pipe(
        mergeMap(res => [setSpotlightSuccess(res), getSpotlightList()]),
        catchRequestError(setSpotlightFailure)
      )
    )
  );

export const deleteSpotlightsEpic = action$ =>
  action$.pipe(
    ofType(DELETE_SPOTLIGHT.REQUEST),
    pluck('payload'),
    switchMap(ids => {
      return forkJoin(
        ids.map(id =>
          deleteSpotlightAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      );
    }),
    mergeMap(res => {
      const errors = groupBy(v => v.Status)(res);
      if (errors.Error?.length > 0) {
        message.error(
          `刪除失敗: ${errors.Error.map(
            e => `id:${e.id} message: ${e.Message}`
          ).join(', ')}`
        );
        return [deleteSpotlightFailure(), getSpotlightList()];
      } else {
        return [deleteSpotlightSuccess(), getSpotlightList()];
      }
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false,
  isShowModal: false,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_SPOTLIGHT_LIST.REQUEST,
      ADD_SPOTLIGHT.REQUEST,
      SET_SPOTLIGHT.REQUEST,
      DELETE_SPOTLIGHT.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(ADD_SPOTLIGHT.SUCCESS, SET_SPOTLIGHT.SUCCESS)]: produce(
      (draft, { payload }) => {
        draft.loading = false;
        draft.isShowModal = false;
      }
    ),
    [GET_SPOTLIGHT_LIST.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;
      draft.data = data;
      draft.totalCount = data.length;
    }),
    [GET_SPOTLIGHT_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_SPOTLIGHT.FAILURE,
      SET_SPOTLIGHT.FAILURE,
      DELETE_SPOTLIGHT.SUCCESS,
      DELETE_SPOTLIGHT.FAILURE
    )]: produce(draft => {
      draft.loading = false;
    }),
    [SHOW_SPOTLIGHT_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
