import { FileOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip
} from 'antd';
import moment from 'moment';
import {
  always,
  assoc,
  compose,
  cond,
  converge,
  evolve,
  F,
  filter,
  ifElse,
  indexBy,
  isNil,
  map,
  nth,
  pick,
  pipe,
  prop,
  props,
  toPairs,
  tryCatch,
  uniq,
  when,
  where,
  whereEq
} from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import CsvIdImport from '../../components/CsvIdImport';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import { sfxSubTypeList, typeList } from '../../constants/effect';
import { useHasErrors } from '../../hooks/useHasErrors';
import { findById, isNotNil, isReallyEmpty } from '../../utils/mixin';
const { Item: FormItem } = Form;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const eventItemName = {
  badgeId: '頭銜',
  badgeSimpleId: '徽章',
  debutId: '進場',
  frameId: '頭框',
  bubbleId: '留言框'
};
const renderTags = tagList => {
  return (
    <Row>
      {tagList.map(tag => (
        <Col span={6} key={tag.id}>
          <Checkbox value={tag.id}>{tag.label}</Checkbox>
        </Col>
      ))}
    </Row>
  );
};
const isSFX = tryCatch(whereEq({ type: 'SFX' }), F);
const isEvent = tryCatch(whereEq({ type: 'EVENT' }), F);
const isBubble = tryCatch(whereEq({ type: 'BUBBLE' }), F);
const getSubType = pipe(prop('subType'), type =>
  findById(type, sfxSubTypeList)
);
const getItems = cond([
  [isNil, always([])],
  [
    isSFX,
    pipe(converge(assoc('value'), [prop('sfxId'), getSubType]), value => [
      value
    ])
  ],
  [
    isEvent,
    pipe(
      pick(['badgeId', 'badgeSimpleId', 'debutId', 'frameId']),
      toPairs,
      filter(pipe(nth(1), isNotNil)),
      map(value => ({
        id: value[1],
        value: value[1],
        label: eventItemName[value[0]]
      }))
    )
  ],
  [
    isBubble,
    pipe(
      pick(['bubbleId']),
      toPairs,
      filter(pipe(nth(1), isNotNil)),
      map(value => ({
        id: value[1],
        value: value[1],
        label: eventItemName[value[0]]
      }))
    )
  ]
]);
const getEffectName = ifElse(
  isSFX,
  compose(value => {
    return `${sfxTypes[value[0]].label} - ${value[1]}`;
  }, props(['subType', 'name'])),
  prop('name')
);
const sfxTypes = indexBy(prop('id'), sfxSubTypeList);
const mapDataToForm = when(
  where({ scheduleTime: isNotNil }),
  evolve({ scheduleTime: e => moment.unix(e) })
);
/*
 * 驗證天數
 */
const validateAvailableDays = ({ getFieldValue }) => {
  return {
    validator: (_, value) => {
      if (
        value === 'TIME_OFFSET' &&
        isReallyEmpty(getFieldValue('availableDays'))
      ) {
        return Promise.reject(new Error('請輸入時效天數'));
      }
      return Promise.resolve();
    }
  };
};
/**
 * 驗證是否有符合套裝設定
 */
const suitStatusValidator = ({ getFieldValue }) => {
  return {
    validator: (_, value) => {
      if (getFieldValue('suitStatus') && value?.length === 1) {
        return Promise.reject(new Error('套裝請勾選 2 組以上'));
      }
      return Promise.resolve();
    }
  };
};

/**
 * 驗證 users 是否為空
 */
const usersValidator = ({ getFieldValue }) => {
  return {
    validator: (_, value) => {
      const tagIds = getFieldValue('tagIds');
      const users = value;
      if (isReallyEmpty(tagIds) && isReallyEmpty(users)) {
        return Promise.reject(new Error('請輸入帳號 或 勾選群組'));
      }

      return Promise.resolve();
    }
  };
};

/**
 * 驗證 tagIds 是否為空
 */
const tagIdsValidator = ({ getFieldValue }) => {
  return {
    validator: (_, value) => {
      const tagIds = value;
      const users = getFieldValue('users');
      if (isReallyEmpty(tagIds) && isReallyEmpty(users)) {
        return Promise.reject(new Error('請輸入帳號 或 勾選群組'));
      }
      return Promise.resolve();
    }
  };
};

const DispatchFormModal = ({
  onOk,
  onCancel,
  editData,
  isCreateMode,
  loading,
  visible,
  tagList,
  effectList
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [effect, setEffect] = useState();
  const [isSFXType, setIsSFXType] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [typeLabel, setTypeLabel] = useState();
  const [isSend, setIsSend] = useState(false);
  const effectItems = useMemo(() => getItems(effect), [effect]);

  useEffect(() => {
    if (visible && !isReallyEmpty(editData) && !isCreateMode) {
      form.setFieldsValue(mapDataToForm(editData));
      setEffect(findById(editData?.acmpId, effectList));
      setIsUnlimited(editData?.availableTimeType === 'UNLIMITED');
      setIsSend(isNotNil(editData?.sendTime));
    }
  }, [form, editData, isCreateMode, visible, effectList]);

  useEffect(() => {
    setIsSFXType(isSFX(effect));
    setTypeLabel(findById(effect?.type, typeList)?.label);
  }, [effect]);

  const onFinish = values => {
    onOk(values);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
    setEffect(null);
    setIsSFXType(false);
    setIsUnlimited(false);
    setTypeLabel(null);
    setIsSend(false);
  };

  const footer = (
    <Space>
      <Popconfirm
        key="confirm"
        title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
        placement="topLeft"
        disabled={hasErrors}
        onConfirm={() => {
          form.validateFields();
          form.submit();
        }}
      >
        <Button
          key={'add'}
          loading={loading}
          type="primary"
          disabled={hasErrors}
        >
          確定
        </Button>
      </Popconfirm>
      <Button key="cancle" onClick={onCancel}>
        取消
      </Button>
    </Space>
  );
  return (
    <Modal
      width={660}
      title={`${isSend ? '已發送 - 檢視' : isCreateMode ? '新增' : '編輯'} ${
        typeLabel || ''
      }發送設定`}
      onCancel={onCancel}
      open={visible}
      afterClose={onAfterClose}
      footer={isSend ? null : footer}
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onFieldsChange={validateStatus}>
          <FormItem
            label="特效"
            name="acmpId"
            rules={[
              {
                required: true,
                message: '請選擇特效'
              }
            ]}
          >
            <Select
              showSearch
              onChange={value => {
                setEffect(findById(value, effectList));
                form.setFieldsValue({
                  suitStatus: false,
                  acmpItemIds: null,
                  availableTimeType: null,
                  activated: false
                });
              }}
              filterOption={(input, { name }) =>
                name && name.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {effectList.map(item => {
                const formattedName = getEffectName(item);
                return (
                  <Option
                    key={item.id}
                    value={item.id}
                    name={formattedName}
                    type={item.type}
                  >
                    {formattedName}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            labelCol={{ span: 24 }}
            label={
              <Space>
                <div className="ant-col ant-legacy-form-item-label">
                  <label title="帳號">帳號</label>
                </div>
                <CsvIdImport
                  onChange={data => {
                    const nowUsers = form.getFieldValue('users') || [];
                    message.success(`匯入 ${data.length} 筆帳號`);
                    form.validateFields(['tagIds']);
                    form.setFieldsValue({
                      users: uniq([...nowUsers, ...data])
                    });
                  }}
                >
                  <Button
                    size="small"
                    title="請匯入CSV"
                    icon={<FileOutlined />}
                  >
                    檔案匯入
                  </Button>
                </CsvIdImport>
              </Space>
            }
            name="users"
            rules={[usersValidator]}
          >
            <LiveMasterSelect
              mode="multiple"
              style={{ width: '100%' }}
              roleFilter={null}
              typeFilter="uuid"
              onChange={value => {
                if (
                  !isReallyEmpty(value) &&
                  isReallyEmpty(form.getFieldValue('tagIds'))
                ) {
                  form.validateFields(['tagIds']);
                }
              }}
            />
          </FormItem>
          <FormItem label="特定群組" name="tagIds" rules={[tagIdsValidator]}>
            <CheckboxGroup
              onChange={value => {
                if (
                  !isReallyEmpty(value) &&
                  isReallyEmpty(form.getFieldValue('users'))
                ) {
                  form.validateFields(['users']);
                }
              }}
            >
              {renderTags(tagList)}
            </CheckboxGroup>
          </FormItem>
          <FormItem
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.suitStatus !== curValues.suitStatus
            }
          >
            {({ getFieldValue, setFieldsValue, validateFields }) => (
              <div style={{ marginLeft: '80px' }}>
                <span style={{ marginRight: '8px' }}>套裝發送</span>
                <Switch
                  disabled={isSFXType || effectItems.length < 2}
                  checked={getFieldValue('suitStatus')}
                  checkedChildren="開"
                  unCheckedChildren="關"
                  onChange={value => {
                    setFieldsValue({ suitStatus: value });
                    validateFields(['acmpItemIds']);
                  }}
                />
              </div>
            )}
          </FormItem>
          <FormItem
            label="發送選項"
            name="acmpItemIds"
            rules={[
              {
                required: true,
                message: '請選擇發送選項'
              },
              suitStatusValidator
            ]}
          >
            <CheckboxGroup options={effectItems}>
              <span style={{ color: '#bfbfbf' }}>請選擇特效</span>
            </CheckboxGroup>
          </FormItem>
          <FormItem
            label="效期"
            name="availableTimeType"
            rules={[
              {
                required: true,
                message: '請選擇效期'
              },
              validateAvailableDays
            ]}
          >
            <Radio.Group
              onChange={event => {
                setIsUnlimited(event.target.value === 'UNLIMITED');
                if (event.target.value === 'UNLIMITED') {
                  form.setFieldsValue({
                    availableDays: 99999
                  });
                } else {
                  form.setFieldsValue({ availableDays: null });
                }
                if (!isSFXType) {
                  form.setFieldsValue({ activated: false });
                }
              }}
            >
              <Radio value="UNLIMITED">永久</Radio>
              <Radio value="TIME_OFFSET">
                時效
                <FormItem noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue, validateFields }) => (
                    <>
                      <InputNumber
                        disabled={isUnlimited}
                        onChange={value => {
                          setFieldsValue({ availableDays: value });
                          validateFields(['availableTimeType'], {
                            force: true
                          });
                        }}
                        value={
                          getFieldValue('availableTimeType') === 'TIME_OFFSET'
                            ? getFieldValue('availableDays')
                            : null
                        }
                        size="small"
                        min={1}
                        max={99999}
                        style={{ width: '80px', margin: '0 8px' }}
                      />
                      <span>天數</span>
                    </>
                  )}
                </FormItem>
              </Radio>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => (
                  <div style={{ marginTop: '8px' }}>
                    <Checkbox
                      disabled={isUnlimited && !isSFXType}
                      checked={getFieldValue('activated')}
                      onChange={e => {
                        setFieldsValue({ activated: e.target.checked });
                      }}
                    >
                      <Tooltip
                        placement="bottomLeft"
                        title={
                          isSFXType
                            ? '若勾選，用戶不需至背包啟用，且收到後時效就會開始計算'
                            : '若勾選，用戶收到特效後，時效就開始計算'
                        }
                      >
                        發送後立即啟用 <QuestionCircleOutlined />
                      </Tooltip>
                    </Checkbox>
                  </div>
                )}
              </FormItem>
            </Radio.Group>
          </FormItem>
          <FormItem label="排程" name="scheduleTime">
            <DatePicker
              allowClear={true}
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              onChange={value =>
                form.setFieldsValue({ scheduleStatus: isNotNil(value) })
              }
              disabledDate={current =>
                current < moment().subtract(1, 'day').endOf('day')
              }
            />
          </FormItem>
          {!isCreateMode && [
            <FormItem key="id" hidden={true} name="id">
              <Input key="id" type="hidden" />
            </FormItem>
          ]}
          <FormItem
            hidden={true}
            initialValue={false}
            key="suitStatus"
            name="suitStatus"
          >
            <Input key="suitStatus" type="hidden" />
          </FormItem>
          <FormItem hidden={true} key="availableDays" name="availableDays">
            <Input key="availableDays" type="hidden" />
          </FormItem>
          <FormItem
            hidden={true}
            initialValue={false}
            key="activated"
            name="activated"
          >
            <Input key="activated" type="hidden" />
          </FormItem>
          <FormItem
            hidden={true}
            initialValue={false}
            key="scheduleStatus"
            name="scheduleStatus"
          >
            <Input key="scheduleStatus" type="hidden" />
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};
export default DispatchFormModal;
