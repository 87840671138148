export const roles = {
  ROLE_ADMIN: '系統管理',
  ROLE_DEALER: '經紀公司',
  ROLE_SALES: '業務',
  ROLE_MASTER: '直播主',
  ROLE_USER: '一般用戶',
  ROLE_FINANCE_MANAGER: '帳務管理',
  ROLE_BUSINESS_MANAGER: '經營管理',
  ROLE_CONTENT_MANAGER: '內容管理',
  ROLE_AGENCY: '經紀公司',
  ROLE_FAMILY_ADMIN: '家族長',
  ROLE_LIVE_CONTROLLER: '場控人員',
  ROLE_AGENCY_MANAGER: '經紀公司管理員',
  ROLE_AGENCY_MANAGER_INTERN: '經紀公司管理實習生',
  ROLE_DESIGNER: '設計部',
  ROLE_PLANNING: '企劃部',
  ROLE_CUSTOMER_SERVICE: '客服部',
  ROLE_PROGRAM_MANAGER: '節目部',
  ROLE_VOICE_MASTER: '聲聊直播主',
  ROLE_PRIVATE_VOICE_MASTER: '私聊直播主',
  ROLE_OBS_MASTER: 'OBS 直播主',
};

export const departmentOwners = {
  xs_product: ['ROLE_ADMIN', 'ROLE_BUSINESS_MANAGER'],
  xs_customer_service: ['ROLE_CUSTOMER_SERVICE'],
  xs_planning: ['ROLE_PLANNING']
};

export const tagType = {
  xs_product: '系統公告',
  xs_customer_service: '小助手',
  xs_planning: '活動快訊'
};

export const userStatus = {
  '-2': '禁用',
  '-1': '未開通',
  0: '刪除',
  1: '啟用'
};

export const userType = {
  all: '全部用戶',
  common: '一般用戶',
  live_master: '直播主'
};

export const userLevel = {
  bronze: '青銅',
  silver: '白銀',
  gold: '黃金',
  diamond: '鑽石',
  niello: '黑金'
};

export const payRoles = {
  iab: 'Android APP 內購',
  iap: 'iOS APP 內購',
  pepay: 'PePay 金流',
  spgateway: '藍新金流',
  firstepay: '微信支付',
  remittance: '匯款',
  yipay: '乙禾網路',
  mycard: 'mycard',
  mypay: 'mypay',
  exchange: 'inAPP 收益點數兌換',
  esgame: 'esgame 金流'
};

export const payType = {
  CREDIT: '信用卡',
  VACC: 'ATM 轉帳',
  CVS: '超商代碼繳費',
  BARCODE: '超商條碼繳費',
  APPLEPAY: 'Apple Pay'
};

export const payTypeForEsgame = {
  'viettel-sms': 'viettel-sms',
  'vinaphone-sms': 'vinaphone-sms',
  'mbf-sms': 'mbf-sms',
  gate: 'gate',
  'vtc-pay': 'vtc-pay',
  'viettel-pay': 'viettel-pay',
  'momo-pay': 'momo-pay',
  'bank-vietcom': 'bank-vietcom'
};

export const specialAccountList = {
  jun: 'ROLE_DESIGNER',
  'tl-ye': 'ROLE_DESIGNER',
  mo84425: 'ROLE_PLANNING',
  chiao: 'ROLE_PLANNING',
  zorrotest01: 'ROLE_PLANNING',
  'tl-jun': 'ROLE_DESIGNER',
  'tl-carl': 'ROLE_PLANNING',
  'tl-mo': 'ROLE_PLANNING',
  'qa-market': 'ROLE_PLANNING',
  'qa-market1': 'ROLE_PLANNING',
  'qa-cs': 'ROLE_CUSTOMER_SERVICE',
  'qa-cs1': 'ROLE_CUSTOMER_SERVICE',
  Robert: 'ROLE_CUSTOMER_SERVICE',
  'tl-amber': 'ROLE_CUSTOMER_SERVICE',
  'tl-kelly': 'ROLE_CUSTOMER_SERVICE',
  'tl-robert': 'ROLE_CUSTOMER_SERVICE',
  'tl-abby': 'ROLE_CUSTOMER_SERVICE',
  'tl-baba': 'ROLE_AGENCY_MANAGER_INTERN',
  show01: 'ROLE_PROGRAM_MANAGER',
  'tl-vic': 'ROLE_PLANNING'
};
