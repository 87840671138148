import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import { switchMap, map, pluck } from 'rxjs/operators';
import { getPayloadByType, catchRequestError } from '../utils/extendOperators';

import { getEventsAPI, addEventAPI, deleteEventAPI } from '../apis';

/**
 * Type
 */

export const EventTypeEnum = {
  1: '禮物積分活動模式1 (連結至某禮物分類)',
  // 2: '禮物積分活動模式2 (連結至某外部網頁)',
  // 3: '頭銜類別活動'
};

export const ScoringTypeEnum = {
  1: '禮物點數累積',
  2: '禮物搜集'
};

const GET_EVENT_CATEGORY = 'GET_EVENT_CATEGORY';
const GET_EVENT_CATEGORY_SUCCESS = 'GET_EVENT_CATEGORY_SUCCESS';
const GET_EVENT_CATEGORY_FAILURE = 'GET_EVENT_CATEGORY_FAILURE';

const ADD_EVENT_CATEGORY = 'ADD_EVENT_CATEGORY';
const ADD_EVENT_CATEGORY_SUCCESS = 'ADD_EVENT_CATEGORY_SUCCESS';
const ADD_EVENT_CATEGORY_FAILURE = 'ADD_EVENT_CATEGORY_FAILURE';

const DELETE_EVENT_CATEGORY = 'DELETE_EVENT_CATEGORY';
const DELETE_EVENT_CATEGORY_SUCCESS = 'DELETE_EVENT_CATEGORY_SUCCESS';
const DELETE_EVENT_CATEGORY_FAILURE = 'DELETE_EVENT_CATEGORY_FAILURE';

const SHOW_MODAL = 'EVENT_CATEGORY.SHOW_MODAL';

/**
 * Creator
 */

export const getEventCategory = createAction(GET_EVENT_CATEGORY);
const getEventCategorySuccess = createAction(GET_EVENT_CATEGORY_SUCCESS);
const getEventCategoryFailure = createAction(GET_EVENT_CATEGORY_FAILURE);

export const addEventCategory = createAction(ADD_EVENT_CATEGORY);
const addEventCategorySuccess = createAction(ADD_EVENT_CATEGORY_SUCCESS);
const addEventCategoryFailure = createAction(ADD_EVENT_CATEGORY_FAILURE);

export const deleteEventCategory = createAction(DELETE_EVENT_CATEGORY);
const deleteEventCategorySuccess = createAction(DELETE_EVENT_CATEGORY_SUCCESS);
const deleteEventCategoryFailure = createAction(DELETE_EVENT_CATEGORY_FAILURE);

export const showModal = createAction(SHOW_MODAL);

export const getEventCategoryEpic = action$ =>
  action$.pipe(
    getPayloadByType(GET_EVENT_CATEGORY),
    switchMap(() =>
      getEventsAPI().pipe(
        pluck('events'),
        map(getEventCategorySuccess),
        catchRequestError(getEventCategoryFailure)
      )
    )
  );

export const addEventCategoryEpic = action$ =>
  action$.pipe(
    getPayloadByType(ADD_EVENT_CATEGORY),
    switchMap(payload =>
      addEventAPI(payload).pipe(
        map(addEventCategorySuccess),
        catchRequestError(addEventCategoryFailure)
      )
    )
  );

export const deleteEventCategoryEpic = action$ =>
  action$.pipe(
    getPayloadByType(DELETE_EVENT_CATEGORY),
    switchMap(payload =>
      deleteEventAPI(payload).pipe(
        map(() => deleteEventCategorySuccess(payload)),
        catchRequestError(deleteEventCategoryFailure)
      )
    )
  );

const initialState = {
  loading: false,
  isShowModal: false,
  events: []
};

export default handleActions(
  {
    [combineActions(
      GET_EVENT_CATEGORY,
      ADD_EVENT_CATEGORY,
      DELETE_EVENT_CATEGORY
    )]: produce(draft => {
      draft.loading = true;
    }),
    [GET_EVENT_CATEGORY_SUCCESS]: produce((draft, { payload }) => {
      draft.events = payload;
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [ADD_EVENT_CATEGORY_SUCCESS]: produce((draft, { payload }) => {
      draft.events.push(payload);
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [DELETE_EVENT_CATEGORY_SUCCESS]: produce((draft, { payload }) => {
      draft.events = draft.events.filter(el => el.id !== payload);
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
