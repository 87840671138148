import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Button, Form, Modal, Popconfirm, DatePicker } from 'antd';
import UploadImage from '../../components/UploadImage';

const { RangePicker } = DatePicker;

const FormItem = styled(Form.Item)`
  .ant-picker-input > input {
    text-align: center;
  }
`;

class CreateOverlayAdFormModal extends React.Component {
  static defaultProps = {
    loading: false,
    isShowModal: false,
    closeModal: () => console.log('close'),
    addAD: v => console.log(v)
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {};
  }

  onSubmit = () => {
    const form = this.formRef.current;
    const { addAD } = this.props;
    const iPromise = form.validateFields();
    iPromise.then(values => {
      addAD({
        ...values,
        currentRange: [
          values.currentRange[0].set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          }),
          values.currentRange[1].set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999
          })
        ]
      });
    });
  };

  renderFooter = () => {
    const { closeModal, loading } = this.props;
    return (
      <React.Fragment>
        <Popconfirm
          title="是否確定新增"
          placement="topLeft"
          onConfirm={this.onSubmit}
        >
          <Button type="primary" loading={loading}>
            <span>新增</span>
          </Button>
        </Popconfirm>
        <Button onClick={closeModal}>
          <span>取消</span>
        </Button>
      </React.Fragment>
    );
  };

  detectCurrentRangeDisabledDate = current => {
    const now = moment();
    const limit = now.endOf('day').subtract(1, 'day');
    return current <= limit;
  };

  reset = () => {
    const form = this.formRef.current;
    form.resetFields();
  };

  render() {
    const { isShowModal, closeModal } = this.props;

    return (
      <Modal
        open={isShowModal}
        closable={false}
        onCancel={closeModal}
        footer={this.renderFooter()}
        afterClose={this.reset}
      >
        <Form ref={this.formRef}>
          <FormItem
            label="有效期限"
            name="currentRange"
            rules={[{ required: true, message: '請輸入有效期限' }]}
          >
            <RangePicker
              style={{ width: '100%' }}
              disabledDate={this.detectCurrentRangeDisabledDate}
            />
          </FormItem>
          <FormItem
            label="行銷圖片"
            name="imageBannerHash"
            rules={[{ required: true, message: '請上傳行銷圖片' }]}
          >
            <UploadImage uploadUrl="/image/create/welcomeBanner" />
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default CreateOverlayAdFormModal;
