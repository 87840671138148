import React, { Component } from 'react';
import moment from 'moment';
import { Button, Table, Popconfirm } from 'antd';
import LightBox from '../../components/LightBox';
import { EventTypeEnum, ScoringTypeEnum } from '../../reducers/eventCategory';

const { Column } = Table;

class EventCategoriesTable extends Component {
  static defaultProps = {
    events: [],
    giftListCategories: [],
    deleteEventCategory: v => console.log(v)
  };

  renderType = (rowData) => {
    const { type } = rowData;
    return <span>{EventTypeEnum[type]}</span>;
  }

  renderStartTime = (rowData) => {
    let display = '';
    const startTime = parseInt(rowData.startTime, 10);
    if (startTime) {
      display = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
    }
    return display;
  }

  renderEndTime = (rowData) => {
    let display = '';
    const endTime = parseInt(rowData.endTime, 10);
    if (endTime) {
      display = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
    }
    return display;
  }

  renderGiftCategory = (rowData) => {
    const { giftListCategories } = this.props;
    let display = '';
    const id = parseInt(rowData.giftCategoryId, 10);
    if (id) {
      const target = giftListCategories.find((el) => el.id === id);
      display = target?.categoryName;
    }
    return display;
  }

  rednerBanner = (rowData) => {
    const { banner } = rowData;
    return <LightBox src={banner} />;
  }

  renderScoringType = (rowData) => {
    const { scoringType } = rowData;
    return ScoringTypeEnum[scoringType];
  }

  renderDelete = (rowData) => {
    const { deleteEventCategory } = this.props;
    const { id } = rowData;
    return (
      <Popconfirm
        title="是否確定移除"
        onConfirm={() => deleteEventCategory(id)}
        placement="right"
      >
        <Button>移除</Button>
      </Popconfirm>
    );
  }

  render() {
    const { events, giftListCategories } = this.props;
    const hasData = events.length > 0 && giftListCategories.length > 0;
    return hasData && (
      <Table
        rowKey="id"
        pagination={false}
        dataSource={events}
      >
        <Column
          key="type"
          title="活動類別"
          render={this.renderType}
        />
        <Column
          key="eventName"
          title="活動名稱"
          dataIndex="eventName"
        />
        <Column
          key="startTime"
          title="上架日期"
          render={this.renderStartTime}
        />
        <Column
          key="endTime"
          title="下架日期"
          render={this.renderEndTime}
        />
        <Column
          key="giftCategory"
          title="禮物頁選單"
          render={this.renderGiftCategory}
        />
        <Column
          key="banner"
          title="活動圖"
          render={this.rednerBanner}
        />
        <Column
          key="url"
          title="連結網址"
          dataIndex="url"
        />
        <Column
          key="scoringType"
          title="計分模式"
          render={this.renderScoringType}
        />
        <Column
          key="delete"
          title="移除"
          render={this.renderDelete}
        />
      </Table>
    );
  }
}

export default EventCategoriesTable;
