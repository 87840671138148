import React, { Component } from 'react';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import LightBox from './LightBox';
import { uploadImage, setContentTypeIsNull, createContentType } from '../apis';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: props.previewImage
        ? [
          {
            uid: '-1',
            name: 'preview.png',
            status: 'done',
            url: props.previewImage
          }
        ]
        : []
    };
  }

  static defaultProps = {
    uploadUrl: '',
    onChange: () => console.log('onChange')
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  handleChange = ({ file, fileList }) => {
    if (file.status === 'done') {
      this.props.onChange(file.response.hashs[0]);
    }

    this.setState({ fileList });
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const { uploadUrl } = this.props;

    return (
      <div>
        <Upload
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          customRequest={info => {
            const form = new FormData();
            form.append('img', info.file);
            setContentTypeIsNull();
            uploadImage(uploadUrl)(form).subscribe(res => {
              info.onSuccess(res);
              createContentType('application/json;charset=UTF-8');
            });
          }}
        >
          {fileList.length < 1 ? (
            <div>
              <PlusOutlined />
              <div>Upload</div>
            </div>
          ) : null}
        </Upload>
        <LightBox.ImageModal
          visible={previewVisible}
          src={previewImage}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }
}

export default UploadImage;
