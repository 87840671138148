import {
  compose,
  equals,
  not,
  paths,
  includes,
  useWith as ramdaUseWith
} from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Select, Tooltip, message, Space, Form } from 'antd';
import { CopyOutlined, SettingOutlined } from '@ant-design/icons';
import { giftType } from '../../constants/gifts';
import { LiveCard, GiftListModal } from '../../components';
import {
  getGiftList,
  closeGiftModal,
  setCurrentGift,
  addGift,
  setGift,
  deleteGift,
  changeStatus,
  changeCategoryId,
  updateQuery,
  defaultQuery,
  getGiftRandom,
  addGiftRandom,
  setGiftRandom
} from '../../reducers/giftList';
import { getGiftListCategory } from '../../reducers/giftListCategories';
import ItemCategoriesManagement from './ItemCategoriesManagement';
import GiftSubMenu from './GiftSubMenu';
import { CardList } from './CardList';
import { PageContainer, PageTitle } from '../../components/styled/page';
import GiftRandomModal from './GiftRandomModal';
const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const getGiftListParams = paths([
  ['match', 'params', 'type'],
  ['typeFilter'],
  ['statusFilter'],
  ['giftCategoryId'],
  ['orderBy'],
  ['page']
]);

const needUpdateGiftList = ramdaUseWith(compose(not, equals), [
  getGiftListParams,
  getGiftListParams
]);

const SubMenu = styled(GiftSubMenu)`
  margin: 0 -1rem 1rem;
`;

class GiftList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowRandomModal: false,
      isRandomCreateMode: true,
      isRandom: false,
      visibleItemModal: false,
      disableStatus: false,
      rate: 1
    };
  }

  componentDidMount() {
    const {
      updateQuery,
      match: {
        params: { type }
      }
    } = this.props;
    const giftTypeValue = giftType[type]?.value;
    const isRandom = includes(giftTypeValue, [giftType.giftRandom.value]);
    if (giftTypeValue) {
      updateQuery({
        ...defaultQuery,
        typeFilter: giftTypeValue
      });
      this.getGiftListCategoryByType(giftTypeValue);
      this.setState({
        isRandom
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      closeGiftModal,
      statusFilter,
      giftCategoryId,
      orderBy,
      page,
      item,
      getGiftList,
      updateQuery,
      match: {
        params: { type }
      }
    } = this.props;
    const giftTypeValue = giftType[type]?.value;
    const isBackpackGift = giftTypeValue === giftType.backpackGift.value;
    const isRandom = includes(giftTypeValue, [giftType.giftRandom.value]);
    const isChangedType = type !== prevProps.match.params.type;
    const getOrderBy = () => {
      if (isBackpackGift) {
        return 'create_at';
      } else if (isChangedType) {
        return defaultQuery.orderBy;
      } else {
        return orderBy;
      }
    };
    if (giftTypeValue) {
      if (isChangedType) {
        updateQuery({
          ...defaultQuery,
          typeFilter: giftTypeValue,
          orderBy: getOrderBy()
        });
        this.getGiftListCategoryByType(giftTypeValue);
        this.setState({ isRandom });
      }
      if (needUpdateGiftList(this.props, prevProps)) {
        closeGiftModal();
        this.onCancelGiftRandomModal();
        getGiftList({
          typeFilter: giftTypeValue,
          giftCategoryId,
          statusFilter,
          orderBy: getOrderBy(),
          item,
          page: isChangedType ? defaultQuery.page : page
        });
      }
    }
  }

  getGiftListCategoryByType = type => {
    const { getGiftListCategory } = this.props;
    getGiftListCategory({
      typeFilter: type,
      statusFilter: 'all',
      item: 9999,
      page: 1
    });
  };

  onImageClickWithInfo = info => () => {
    const { setCurrentGift } = this.props;
    setCurrentGift(info, false);
  };

  onGiftRandomClick = info => () => {
    const { getGiftRandom } = this.props;
    getGiftRandom(info?.id, () =>
      this.setState({ isShowRandomModal: true, isRandomCreateMode: false })
    );
  };

  onUploadButtonClick = () => {
    const { setCurrentGift } = this.props;
    setCurrentGift(
      {
        name: '',
        point: 0
      },
      true
    );
  };

  onAddClick = () => {
    if (this.state.isRandom) {
      this.setState({ isShowRandomModal: true, isRandomCreateMode: true });
    } else {
      this.onUploadButtonClick();
    }
  };

  onGiftListCategoryChange = (value, { props: { status } }) => {
    const { updateQuery } = this.props;
    this.setState({ disableStatus: status === 'archive' });
    updateQuery({
      giftCategoryId: value,
      statusFilter: status || 'all',
      page: 1
    });
  };

  onStatusFilterChange = value => {
    const { updateQuery } = this.props;
    updateQuery({ statusFilter: value, page: 1 });
  };

  onOrderChange = value => {
    const { updateQuery } = this.props;
    updateQuery({ orderBy: value, page: 1 });
  };

  onGiftListPageChange = pageNumber => {
    const { updateQuery } = this.props;
    updateQuery({ page: pageNumber });
  };

  onGiftListCategoryOpen = () => {
    const { typeFilter } = this.props;
    this.getGiftListCategoryByType(typeFilter);
    this.setState({ visibleItemModal: true });
  };
  onCopyIDClick = id => {
    navigator.clipboard.writeText(id).then(
      function () {
        message.success('ID 複製成功');
      },
      function () {
        message.error('ID 複製失敗');
      }
    );
  };
  onSubmit = data => {
    const { isCreateMode, addGift, setGift } = this.props;

    if (isCreateMode) {
      addGift({ isMultiple: false, ...data });
    } else {
      setGift({ ...data });
    }
  };

  onGiftRandomSubmit = data => {
    const {
      addGiftRandom,
      setGiftRandom,
      typeFilter,
      giftCategoryId,
      statusFilter,
      orderBy,
      item,
      page
    } = this.props;
    const { isRandomCreateMode } = this.state;
    if (isRandomCreateMode) {
      addGiftRandom(
        data,
        getGiftList({
          typeFilter,
          giftCategoryId,
          statusFilter,
          orderBy,
          item,
          page: 1
        })
      );
    } else {
      setGiftRandom(
        data,
        getGiftList({
          typeFilter,
          giftCategoryId,
          statusFilter,
          orderBy,
          item,
          page
        })
      );
    }
    this.setState({ isShowRandomModal: false, isRandomCreateMode: true });
  };

  onCloseGiftModal = () => {
    this.props.closeGiftModal();
  };

  onCancelGiftRandomModal = () => {
    this.setState({ isShowRandomModal: false });
  };

  renderGiftItem = (gift, categoryName) => {
    const {
      match: {
        params: { type }
      }
    } = this.props;
    return (
      <LiveCard
        imgUrl={gift.thumbUrl}
        key={gift.id}
        onImageClick={this.onImageClickWithInfo(gift)}
      >
        <h3 title={gift.name}>
          名稱：
          {gift.name}
        </h3>
        <p title={gift.id}>
          ID：
          {gift.id}
          <Tooltip placement="top" title={'複製'}>
            <Button
              size="small"
              type="link"
              icon={<CopyOutlined />}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.onCopyIDClick(gift.id);
              }}
            ></Button>
          </Tooltip>
        </p>
        <p title={categoryName}>
          分類：
          {categoryName}
        </p>
        <p className="price-tag">點數： {gift.point}</p>
        {type !== 'post' && <p>類型： {gift.isMultiple ? '連擊' : '播放'}</p>}
      </LiveCard>
    );
  };

  renderGiftRandomItem = (giftRandom, categoryName) => (
    <LiveCard
      imgUrl={giftRandom.thumbUrl}
      key={giftRandom.id}
      onImageClick={this.onGiftRandomClick(giftRandom)}
    >
      <h3 title={giftRandom.name}>
        名稱：
        {giftRandom.name}
      </h3>
      <p title={giftRandom.id}>
        ID：
        {giftRandom.id}
        <Tooltip placement="top" title={'複製'}>
          <Button
            size="small"
            type="link"
            icon={<CopyOutlined />}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.onCopyIDClick(giftRandom.id);
            }}
          />
        </Tooltip>
      </p>
      <p title={categoryName}>
        分類：
        {categoryName}
      </p>
      <p className="price-tag">點數： {giftRandom.point}</p>
    </LiveCard>
  );

  render() {
    const {
      giftListCategories,
      giftData,
      currentGift,
      currentGiftRandom,
      isModalVisible,
      isCreateMode,
      statusFilter,
      giftCategoryId,
      orderBy,
      page,
      item,
      totalCount,
      allGiftListCategories,
      archiveGiftListCategories,
      giftListLoading,
      categoriesLoading,
      typeFilter,
      deleteGift,
      match: {
        params: { type }
      }
    } = this.props;
    const {
      visibleItemModal,
      isShowRandomModal,
      isRandomCreateMode,
      isRandom
    } = this.state;
    const { disableStatus } = this.state;
    const renderItem = isRandom
      ? this.renderGiftRandomItem
      : this.renderGiftItem;
    return (
      <PageContainer>
        <PageTitle>{'活動管理 > 禮物列表'}</PageTitle>
        <SubMenu selectedKeys={type}></SubMenu>
        <Space size="middle" wrap={true} align="start">
          <Button onClick={this.onAddClick}>
            <span>新增</span>
          </Button>
          <FormItem label="分類">
            <Select
              style={{ width: 144 }}
              showSearch
              filterOption={(input, { props: { title } }) =>
                title && title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={this.onGiftListCategoryChange}
              value={giftCategoryId}
            >
              <Option key="all" value="" title="全部">
                全部
              </Option>
              {giftListCategories.map(item => (
                <Option
                  key={item.id}
                  value={item.id}
                  title={item.categoryName}
                  status="all"
                >
                  {item.categoryName}
                </Option>
              ))}
              <OptGroup label="封存">
                {archiveGiftListCategories.map(item => (
                  <Option
                    key={item.id}
                    value={item.id}
                    title={item.categoryName}
                    status="archive"
                    style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                  >
                    {item.categoryName}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </FormItem>
          <FormItem label="狀態">
            <Select
              disabled={disableStatus}
              style={{ width: 120 }}
              value={statusFilter}
              onChange={this.onStatusFilterChange}
            >
              <Option key="all" value="all">
                全部
              </Option>
              <Option key="on" value="on">
                上架
              </Option>
              <Option key="off" value="off">
                下架
              </Option>
              <Option key="archive" value="archive">
                封存
              </Option>
            </Select>
          </FormItem>
          <FormItem label="排序">
            <Select
              style={{ width: 160 }}
              value={orderBy}
              onChange={this.onOrderChange}
            >
              <Option key="point_desc" value="point_desc">
                點數由大 {'>'} 小
              </Option>
              <Option key="point_asc" value="point_asc">
                點數由小 {'>'} 大
              </Option>
              <Option key="create_at" value="create_at">
                上架時間由新 {'>'} 舊
              </Option>
            </Select>
          </FormItem>
          <Button
            type="primary"
            onClick={this.onGiftListCategoryOpen}
            icon={<SettingOutlined />}
          >
            管理禮物分類
          </Button>
        </Space>
        <CardList
          loading={!visibleItemModal && (giftListLoading || categoriesLoading)}
          dataSource={giftData}
          categoryDataSource={allGiftListCategories}
          pagination={{
            total: totalCount,
            pageSize: item,
            current: page,
            onChange: this.onGiftListPageChange,
            showSizeChanger: false
          }}
          renderItem={renderItem}
        />
        <ItemCategoriesManagement
          visible={visibleItemModal}
          onClose={() => this.setState({ visibleItemModal: false })}
        />
        <GiftListModal
          typeFilter={typeFilter}
          visible={isModalVisible}
          isCreateMode={isCreateMode}
          editData={currentGift}
          deleteGift={deleteGift}
          giftListCategories={giftListCategories}
          onOk={this.onSubmit}
          onCancel={this.onCloseGiftModal}
        />
        <GiftRandomModal
          visible={isShowRandomModal}
          isCreateMode={isRandomCreateMode}
          editData={currentGiftRandom}
          deleteGift={deleteGift}
          giftListCategories={giftListCategories}
          onOk={this.onGiftRandomSubmit}
          onCancel={this.onCancelGiftRandomModal}
        />
      </PageContainer>
    );
  }
}

export default connect(
  state => ({
    giftData: state.giftList.data,
    isModalVisible: state.giftList.isModalVisible,
    currentGift: state.giftList.currentGift,
    currentGiftRandom: state.giftList.currentGiftRandom,
    isCreateMode: state.giftList.isCreateMode,
    giftCategoryId: state.giftList.giftCategoryId,
    typeFilter: state.giftList.typeFilter,
    statusFilter: state.giftList.statusFilter,
    orderBy: state.giftList.orderBy,
    giftListLoading: state.giftList.loading,
    item: state.giftList.item,
    page: state.giftList.page,
    totalCount: state.giftList.totalCount,
    categoriesLoading: state.giftListCategories.loading,
    giftListCategories: state.giftListCategories.data,
    allGiftListCategories: state.giftListCategories.allData,
    archiveGiftListCategories: state.giftListCategories.archiveData
  }),
  {
    getGiftList,
    closeGiftModal,
    setCurrentGift,
    addGift,
    setGift,
    deleteGift,
    getGiftListCategory,
    changeStatus,
    changeCategoryId,
    updateQuery,
    getGiftRandom,
    addGiftRandom,
    setGiftRandom
  }
)(GiftList);
