import produce from 'immer';
import { assocPath, is } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  addFloatingSlotAPI,
  deleteFloatingSlotAPI,
  getFloatingSlotAPI,
  getFloatingSlotListAPI,
  setFloatingSlotAPI
} from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';

/**Type */
const GET_FLOATING_SLOT_LIST = createRequestTypes('GET_FLOATING_SLOT_LIST');
const ADD_FLOATING_SLOT = createRequestTypes('ADD_FLOATING_SLOT');
const GET_FLOATING_SLOT = createRequestTypes('GET_FLOATING_SLOT');
const SET_FLOATING_SLOT = createRequestTypes('SET_FLOATING_SLOT');
const DELETE_FLOATING_SLOT = createRequestTypes('DELETE_FLOATING_SLOT');
const SHOW_FLOATING_SLOT_MODAL = 'SHOW_FLOATING_SLOT_MODAL';
const defaultQuery = { item: 20, page: 1 };
/**
 * Action Creator
 */
export const getFloatingSlotList = createAction(
  GET_FLOATING_SLOT_LIST.REQUEST,
  ({ item = 20, page = 1 } = defaultQuery) => ({
    item,
    page
  })
);
export const getFloatingSlotListSuccess = createAction(
  GET_FLOATING_SLOT_LIST.SUCCESS
);
export const getFloatingSlotListFailure = createAction(
  GET_FLOATING_SLOT_LIST.FAILURE
);
export const showModal = createAction(
  SHOW_FLOATING_SLOT_MODAL,
  (isShowModal = true) => isShowModal
);
export const addFloatingSlot = createAction(
  ADD_FLOATING_SLOT.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback,
  })
);
export const addFloatingSlotSuccess = createAction(ADD_FLOATING_SLOT.SUCCESS);
export const addFloatingSlotFailure = createAction(ADD_FLOATING_SLOT.FAILURE);
export const getFloatingSlot = createAction(GET_FLOATING_SLOT.REQUEST);
export const getFloatingSlotSuccess = createAction(GET_FLOATING_SLOT.SUCCESS);
export const getFloatingSlotFailure = createAction(GET_FLOATING_SLOT.FAILURE);
export const setFloatingSlot = createAction(
  SET_FLOATING_SLOT.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback
  })
);
export const setFloatingSlotSuccess = createAction(SET_FLOATING_SLOT.SUCCESS);
export const setFloatingSlotFailure = createAction(SET_FLOATING_SLOT.FAILURE);
export const deleteFloatingSlot = createAction(
  DELETE_FLOATING_SLOT.REQUEST,
  (id, callbackAction, callback) => ({
    id,
    callbackAction,
    callback,
  })
);
export const deleteFloatingSlotSuccess = createAction(
  DELETE_FLOATING_SLOT.SUCCESS
);
export const deleteFloatingSlotFailure = createAction(
  DELETE_FLOATING_SLOT.FAILURE
);

/**
 * Epics
 */
export const getFloatingSlotListEpic = action$ =>
  action$.pipe(
    ofType(GET_FLOATING_SLOT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getFloatingSlotListAPI(payload).pipe(
        map(getFloatingSlotListSuccess),
        catchRequestError(getFloatingSlotListFailure)
      )
    )
  );

export const addFloatingSlotEpic = action$ =>
  action$.pipe(
    ofType(ADD_FLOATING_SLOT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      addFloatingSlotAPI(payload).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [addFloatingSlotSuccess(res), callbackAction]
            : [addFloatingSlotSuccess(res)];
        }),
        catchRequestError(addFloatingSlotFailure)
      )
    )
  );

export const getFloatingSlotEpic = action$ =>
  action$.pipe(
    ofType(GET_FLOATING_SLOT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getFloatingSlotAPI(payload).pipe(
        map(res =>
          getFloatingSlotSuccess(assocPath(['data', 'id'], payload, res))
        ),
        catchRequestError(getFloatingSlotFailure)
      )
    )
  );

export const setFloatingSlotEpic = action$ =>
  action$.pipe(
    ofType(SET_FLOATING_SLOT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      setFloatingSlotAPI(payload).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [setFloatingSlotSuccess(res), callbackAction]
            : [setFloatingSlotSuccess(res)];
        }),
        catchRequestError(setFloatingSlotFailure)
      )
    )
  );

export const deleteFloatingSlotEpic = action$ =>
  action$.pipe(
    ofType(DELETE_FLOATING_SLOT.REQUEST),
    pluck('payload'),
    switchMap(({ id, callbackAction, callback}) =>
      deleteFloatingSlotAPI(id).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [deleteFloatingSlotSuccess(res), callbackAction]
            : [deleteFloatingSlotSuccess(res)];
        }),
        catchRequestError(deleteFloatingSlotFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  currentData: null,
  totalCount: 0,
  loading: false,
  isShowModal: false
};

export default handleActions(
  {
    [combineActions(
      GET_FLOATING_SLOT_LIST.REQUEST,
      ADD_FLOATING_SLOT.REQUEST,
      SET_FLOATING_SLOT.REQUEST,
      DELETE_FLOATING_SLOT.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [GET_FLOATING_SLOT.REQUEST]: produce(draft => {
      draft.loading = true;
      draft.currentData = null;
    }),
    [GET_FLOATING_SLOT.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentData = payload.data;
    }),
    [GET_FLOATING_SLOT.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.currentData = null;
      draft.isShowModal = false;
    }),
    [combineActions(ADD_FLOATING_SLOT.SUCCESS, SET_FLOATING_SLOT.SUCCESS)]:
      produce((draft, { payload }) => {
        draft.loading = false;
        draft.isShowModal = false;
      }),
    [GET_FLOATING_SLOT_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
    }),
    [GET_FLOATING_SLOT_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_FLOATING_SLOT.FAILURE,
      SET_FLOATING_SLOT.FAILURE,
      DELETE_FLOATING_SLOT.SUCCESS,
      DELETE_FLOATING_SLOT.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_FLOATING_SLOT_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
