import { isEmpty } from 'ramda';
import moment from 'moment';
import {
  Button, Form, Input,
  Modal, Popconfirm, Table,
} from 'antd';
import React, { useRef, useState } from 'react';
import LiveMasterSelect from '../../components/LiveMasterSelect';

const FormItem = Form.Item;

const columns = [
  {
    width: 224,
    title: '活動圖片',
    dataIndex: 'bannerUrl',
    render: value => (
      <img src={value} alt="" style={{ width: 190, height: 'auto' }} />
    )
  },
  {
    title: '活動連結',
    dataIndex: 'linkUrl',
    render: value => (
      <a
        style={{
          wordWrap: 'break-word',
          wordBreak: 'break-word'
        }}
        rel="noreferrer"
        target="_blank"
        href={value}
      >
        {value}
      </a>
    )
  },
  {
    width: 208,
    title: '活動上下架時間',
    dataIndex: 'startTime',
    render: (value, record) => (
      <span>
        {moment.unix(value).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
        {moment.unix(record['endTime']).format('YYYY-MM-DD HH:mm:ss')}
      </span>
    )
  }
];

const EventSelect = (props) => {
  const {
    dataSource, value, onChange,
    disabled = false,
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState(() =>
    value ? [value] : []
  );

  const triggerChange = changedValue => {
    onChange(isEmpty(changedValue) ? null : changedValue[0]);
  };

  const onSelectRowChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    if (onChange && !disabled) {
      triggerChange(selectedRowKeys);
    }
  };

  return (
    <Table
      rowKey="id"
      style={{ border: '1px solid #e8e8e8', borderRadius: '0px' }}
      scroll={{ x: true, y: 336 }}
      columns={columns}
      pagination={false}
      dataSource={dataSource}
      rowSelection={{
        type: 'radio',
        selectedRowKeys,
        onChange: onSelectRowChange
      }}
    />
  );
};

const PastEventFormModal = (props) => {
  const {
    dataSource, editData, isCreateMode,
    loading, onCancel, onOk, visible,
  } = props;

  const formRef = useRef(null);

  const onSubmit = () => {
    const form = formRef.current;
    const iPromise = form.validateFields();
    iPromise.then(onOk);
  };

  const renderFooter = () => (
    <React.Fragment>
      <Popconfirm
        title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
        placement="topLeft"
        onConfirm={onSubmit}
        // onConfirm={() => onOk(form)}
      >
        <Button
          loading={loading}
          type="primary"
        >
          <span>確定</span>
        </Button>
      </Popconfirm>
      <Button
        style={{ marginLeft: 8 }}
        onClick={onCancel}
      >
        <span>取消</span>
      </Button>
    </React.Fragment>
  );

  return (
    <Modal
      width={980}
      title={`${isCreateMode ? '新增' : '編輯'} 上月活動內容`}
      confirmLoading={loading}
      destroyOnClose={true}
      visible={visible}
      footer={renderFooter()}
      onCancel={onCancel}
    >
      <Form ref={formRef}>
        <FormItem
          label="活動"
          name="eventId"
          initialValue={editData?.eventId}
          rules={[
            { required: true, message: '請選擇活動' },
          ]}
        >
          <EventSelect dataSource={dataSource} />
        </FormItem>
        <FormItem
          label="直播主"
          name="liveMasterId"
          initialValue={editData?.liveMasterId}
          rules={[
            {
              validator: (rule, value) => {
                if (value && value.length > 3) {
                  const error = new Error('最多指定 3 位直播主');
                  return Promise.reject(error);
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <LiveMasterSelect mode="multiple" />
        </FormItem>
        {!isCreateMode && (
          <FormItem
            name="id"
            initialValue={editData?.id}
            hidden
            noStyle
          >
            <Input type="hidden" />
          </FormItem>
        )}
      </Form>
    </Modal>
  );
};

export default PastEventFormModal;
