import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { getUserInfoAPI } from '../../apis';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import ChatRoom from '../../components/InstantChatRoom';

const StyledSection = styled.section`
  margin: 0 1rem 0 0;
`;

const Tools = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const EnterRoomButton = styled(Button)`
  margin-left: 0.5rem;
`;

const ChatRoomWrapper = styled.div`
  width: 32rem;
  height: calc(100vh - 18rem);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E0E0E0;
`;

const WaitingMessage = styled.div`
  display: flex;
  align-items: center;
  color: #9E9E9E;
  transform: translateY(-2rem);

  i {
    margin-right: 0.25rem;
  }
`;

class MessageManagement extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      clientID: '',
      client: null,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  chaneRoom = async () => {
    const { clientID } = this.state;
    await this.fetchUserInfo(clientID);
  }

  fetchUserInfo = (uuid) => {
    const iPromise = getUserInfoAPI(uuid).toPromise();
    iPromise.then((response) => {
      this.setState({ client: response });
    });
    return iPromise;
  }

  onClientChange = (selected) => {
    const clientID = _.get(selected[0], 'id');
    this.setState({ clientID, client: null });
  }

  renderChatRoom = () => {
    const { auth } = this.props;
    const { client, isLoading } = this.state;
    const isEmptyClient = _.isEmpty(client);
    return !isLoading && !isEmptyClient ? (
      <ChatRoom
        master={auth}
        participant={client}
      />
    ) : (
      <WaitingMessage>
        <LegacyIcon type="exclamation-circle" />
        <span>請選擇用戶並加入聊天室</span>
      </WaitingMessage>
    );
  }

  render() {
    const { clientID } = this.state;
    return (
      <StyledSection>
        <h1>{'經營管理 > 私訊管理'}</h1>
        <Tools>
          <LiveMasterSelect
            style={{ width: '25rem' }}
            value={clientID}
            roleFilter={null}
            onSelectChange={this.onClientChange}
          />
          <EnterRoomButton
            type="primary"
            onClick={this.chaneRoom}
          >
            <span>進入聊天室</span>
          </EnterRoomButton>
        </Tools>
        <ChatRoomWrapper>
          {this.renderChatRoom()}
        </ChatRoomWrapper>
      </StyledSection>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
)(MessageManagement);
