import { pick } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Form, Input } from 'antd';

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    width: 5rem;
  }
`;
const checkLength = () => ({
  validator(_, value) {
    const encoder = new TextEncoder();
    const result = encoder.encode(value).length;
    if (result && result > 30) {
      return Promise.reject(new Error('禁詞內容字元過長'));
    }
    return Promise.resolve();
  }
});

class ForbiddenForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {};
  }

  getFormData = () => {
    const form = this.formRef.current;
    return form.getFieldsValue();
  };

  setFormData = data => {
    const form = this.formRef.current;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = pick(fields, data);
    form.setFieldsValue(values);
  };

  validateFields = () => {
    const form = this.formRef.current;
    return form.validateFields();
  };

  reset = () => {
    const form = this.formRef.current;
    form.resetFields();
  };

  render() {
    return (
      <Form ref={this.formRef} layout="vertical" labelAlign="left">
        <FormItem
          label="禁詞內容"
          name="word"
          htmlFor="word"
          rules={[{ required: true, message: '請輸入禁詞內容' }, checkLength]}
        >
          <Input id="word" placeholder="禁詞內容" />
        </FormItem>
        <FormItem name="id" hidden>
          <Input type="hidden" />
        </FormItem>
      </Form>
    );
  }
}

export default ForbiddenForm;
