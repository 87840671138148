import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipe } from 'ramda';
import moment from 'moment';
import { Button, Select, DatePicker, Checkbox } from 'antd';
import { Form } from '@ant-design/compatible';
import { getLiveMasterHoursSheet } from '../reducers/accountSheetExport';
import { getAgencyList } from '../reducers/agencyManagement';
import LiveMasterSelect from '../components/LiveMasterSelect';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

class LiveMasterHoursSheetExport extends Component {
  componentDidMount() {
    this.props.getAgencyList({
      page: 1,
      agencyStatusFilter: 'progress',
      item: 9999
    });
  }

  generateFormItem() {
    const {
      agencyList,
      form: { getFieldValue }
    } = this.props;

    return [
      {
        key: 'userAll',
        style: { marginBottom: 0 },
        options: {
          initialValue: false,
          valuePropName: 'checked'
        },
        render: () => (
          <Checkbox
            onChange={({ target: { checked } }) =>
              this.props.form.setFieldsValue({
                userIds: checked ? [] : undefined
              })
            }
          >
            帳號全選
          </Checkbox>
        )
      },
      {
        key: 'userIds',
        render: () => (
          <LiveMasterSelect
            style={{ width: '100%' }}
            disabled={getFieldValue('userAll') === true}
            mode="multiple"
          />
        )
      },
      {
        key: 'agencyAll',
        style: { marginBottom: 0 },
        options: {
          initialValue: false,
          valuePropName: 'checked'
        },
        render: () => (
          <Checkbox
            onChange={({ target: { checked } }) =>
              this.props.form.setFieldsValue({
                agencyIds: checked ? [] : undefined
              })
            }
          >
            經紀公司全選
          </Checkbox>
        )
      },
      {
        key: 'agencyIds',
        render: () => (
          <Select
            disabled={getFieldValue('agencyAll') === true}
            showArrow={true}
            allowClear
            mode="multiple"
            placeholder="請選擇匯出經紀公司"
            filterOption={(inputValue, option) =>
              option.title.indexOf(inputValue) > -1
            }
          >
            {agencyList.map(item => (
              <Option key={item.agencyId} title={item.agencyName}>
                {item.agencyName}
              </Option>
            ))}
          </Select>
        )
      },
      {
        label: '日期區間',
        key: 'currentRange',
        options: {
          rules: [{ required: true, message: '請輸入匯出期間' }]
        },
        render: () => (
          <RangePicker
            disabledDate={current =>
              current > moment().endOf('day').subtract(1, 'day')
            }
          />
        )
      }
    ];
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.getLiveMasterHoursSheet({
          userIds: values.userIds,
          agencyIds: values.agencyIds,
          startDate: values.currentRange[0]
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0
            })
            .format('X'),
          endDate: values.currentRange[1]
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999
            })
            .format('X')
        });
      }
    });
  };

  enableSubmitButton = () => {
    const tmp = this.props.form.getFieldsValue();
    return (
      tmp.currentRange &&
      tmp.currentRange.length === 2 &&
      (tmp.agencyAll ||
        tmp.userAll ||
        (tmp.agencyIds && tmp.agencyIds.length > 0) ||
        (tmp.userIds && tmp.userIds.length > 0))
    );
  };

  render() {
    const getFormError = id => isFieldTouched(id) && getFieldError(id);

    const { getFieldDecorator, getFieldError, isFieldTouched } =
      this.props.form;
    const { loading } = this.props;

    return (
      <div style={{ paddingRight: '16px' }}>
        <h1>{'經營管理 > 直播主每日直播時數統計表'}</h1>
        <Form onSubmit={this.handleSubmit}>
          {this.generateFormItem().map(item => (
            <FormItem
              key={item.key}
              style={item.style}
              label={item.label}
              validateStatus={getFormError(item.key) ? 'error' : ''}
              help={getFormError(item.key) || ''}
            >
              {getFieldDecorator(item.key, item.options)(item.render())}
            </FormItem>
          ))}
          <FormItem>
            <Button
              htmlType="submit"
              loading={loading}
              disabled={!this.enableSubmitButton()}
            >
              匯出帳號資料
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  agencyList: state.agencyManagement.data,
  loading: state.accountSheetExport.loading
});

const mapDispatchToProps = {
  getLiveMasterHoursSheet,
  getAgencyList
};

export default pipe(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(LiveMasterHoursSheetExport);
