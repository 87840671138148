import { Table } from 'antd';
import moment from 'moment';
import { indexBy, prop } from 'ramda';
import React, { useMemo } from 'react';
import LightBox from '../../components/LightBox';
import { sfxSubTypeList } from '../../constants/effect';
const sfxSubTypeById = indexBy(prop('id'), sfxSubTypeList);
const columns = [
  { title: '編號', dataIndex: 'id' },
  { title: '名稱', dataIndex: 'name' },
  {
    title: '動效期間',
    dataIndex: 'sfxStartDate',
    render: (value, record) => {
      if (value && record.sfxEndDate) {
        return (
          <span>
            {moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}
            {' ~ '}
            {moment.unix(record.sfxEndDate).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        );
      } else {
        return '無';
      }
    }
  },

  {
    title: '動效縮圖',
    dataIndex: 'sfxPreviewUrl',
    render: value => <LightBox src={value} />
  },
  {
    title: '動效分類',
    dataIndex: 'subType',
    render: value => sfxSubTypeById[value].label
  }
];

const GeneralTable = ({
  loading,
  data,
  selectedRowKeys,
  pageSize,
  currentPage,
  total,
  onRowSelectionChange,
  onPageChange
}) => {
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: onRowSelectionChange
    }),
    [selectedRowKeys, onRowSelectionChange]
  );
  const pagination = useMemo(
    () => ({
      pageSize,
      total,
      current: currentPage,
      onChange: onPageChange,
      showSizeChanger: false
    }),
    [pageSize, currentPage, total, onPageChange]
  );
  return (
    <Table
      rowKey="id"
      scroll={{ x: true }}
      loading={loading}
      rowSelection={rowSelection}
      pagination={pagination}
      columns={columns}
      dataSource={data}
    ></Table>
  );
};
export default GeneralTable;
