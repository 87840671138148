module.exports = [
  {
    _id: '59bfe7c0f721df776c03ab2f',
    index: 0,
    guid: '9f53053a-c06d-4437-af45-7f58c4d5629d',
    captionText: 'Laborum ut do quis fugiat magna.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-05.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
    ],
  },
  {
    _id: '59bfe7c05f651932704d056c',
    index: 1,
    guid: '65114c2c-f5c7-411e-8767-dd6a74324d48',
    captionText:
      'Veniam esse enim enim minim esse labore ex esse commodo nostrud dolore ullamco commodo.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-02.png',
    docUrls: [],
  },
  {
    _id: '59bfe7c07e4523075b2ec170',
    index: 2,
    guid: 'ba8adfcf-ebd2-410e-9d05-08285ce4df37',
    captionText:
      'Ea ea id commodo proident tempor duis eiusmod adipisicing esse aliquip sunt proident veniam duis.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-05.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
    ],
  },
  {
    _id: '59bfe7c0c54584c205f9a8e8',
    index: 3,
    guid: 'b880e894-5610-4b50-9e44-5966d37e245f',
    captionText:
      'Incididunt ut sit commodo in ullamco anim cupidatat dolor et.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-04.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
    ],
  },
  {
    _id: '59bfe7c0470feb814e462a91',
    index: 4,
    guid: 'efe34264-77d5-4c14-a236-5484a40a8290',
    captionText:
      'Reprehenderit enim anim laboris elit esse aute veniam incididunt tempor in enim nisi consequat dolor.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-05.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
    ],
  },
  {
    _id: '59bfe7c0307e075e1945c931',
    index: 5,
    guid: '442603f8-f81d-451b-9b8d-93a8656a0aef',
    captionText:
      'Ipsum eu ut ipsum sit adipisicing et adipisicing cupidatat duis id exercitation.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-06.png',
    docUrls: [],
  },
  {
    _id: '59bfe7c003799469f37b8ec3',
    index: 6,
    guid: '1209b471-e7de-484e-83bb-228940f29fc7',
    captionText: 'Enim consectetur do non ea et laborum commodo eu non.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
    ],
  },
  {
    _id: '59bfe7c0798c240aba9c5ae3',
    index: 7,
    guid: '32531a89-a0bc-4aa8-b5d9-657983de68f7',
    captionText: 'Ex veniam qui commodo magna amet duis.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-03.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
    ],
  },
  {
    _id: '59bfe7c05eb7443f5dc5d138',
    index: 8,
    guid: '4e39793b-cb92-4ae8-b63b-14210e1e2ec7',
    captionText:
      'Tempor commodo dolore pariatur pariatur nostrud cillum ex aliqua voluptate mollit culpa ipsum commodo.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-04.png',
    docUrls: [],
  },
  {
    _id: '59bfe7c00e189839eb9b6bdd',
    index: 9,
    guid: 'ed1ed8f0-0a4c-403d-ba9f-ccd0527a38cc',
    captionText:
      'Ut incididunt et laborum fugiat pariatur minim nostrud commodo occaecat fugiat cillum consequat.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-02.png',
    docUrls: [],
  },
  {
    _id: '59bfe7c0533754bec42f79ea',
    index: 10,
    guid: '28d877f5-1346-49e2-a748-2517abb9419d',
    captionText:
      'Do et consectetur consequat fugiat ex aliqua proident est tempor irure.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-09.png',
    docUrls: [],
  },
  {
    _id: '59bfe7c0033eb822c34463b4',
    index: 11,
    guid: 'e6bd17a6-86d8-4f15-bf52-9e5dfdfbd6e8',
    captionText:
      'Cillum quis cupidatat dolore pariatur enim fugiat sit quis reprehenderit in tempor reprehenderit laboris.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-02.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
    ],
  },
  {
    _id: '59bfe7c0d788efb82fde0c64',
    index: 12,
    guid: '59841a1c-9321-4945-b905-787a17644a43',
    captionText:
      'Aute exercitation amet non mollit proident quis excepteur sint.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-04.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
    ],
  },
  {
    _id: '59bfe7c0060aee197357b7e2',
    index: 13,
    guid: 'c8f4d87a-3aab-4020-ab05-1746dd71f175',
    captionText:
      'Id eu eiusmod cupidatat veniam cupidatat laborum id excepteur voluptate Lorem veniam officia duis id.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-07.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
    ],
  },
  {
    _id: '59bfe7c0b165728f07f2775c',
    index: 14,
    guid: '1a8375c0-004d-4eb6-b6f9-c4efb36b98f4',
    captionText:
      'Consectetur anim et adipisicing incididunt consectetur ex do reprehenderit occaecat.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-05.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
    ],
  },
  {
    _id: '59bfe7c0444c90723fe2c0f6',
    index: 15,
    guid: '3f1ecd6c-6fcd-412c-988e-85f7809631de',
    captionText: 'Excepteur laboris do sunt irure magna velit dolor laborum.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-02.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
    ],
  },
  {
    _id: '59bfe7c0d4103929137735e5',
    index: 16,
    guid: '1a47f08d-a42b-4d54-a22d-0759e51fa289',
    captionText:
      'Amet in excepteur culpa cillum non exercitation consectetur ex quis nisi in excepteur veniam dolore.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-06.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-00.png',
    ],
  },
  {
    _id: '59bfe7c0f0e09aadebfac555',
    index: 17,
    guid: '23a85b81-ce85-4ecb-aa00-3b25eaea149a',
    captionText: 'Exercitation fugiat anim incididunt officia reprehenderit.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-09.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
    ],
  },
  {
    _id: '59bfe7c0fd983b57561663bd',
    index: 18,
    guid: 'b563c7bd-4e4a-4231-ac46-2ffe21b7ddec',
    captionText: 'Consectetur ipsum adipisicing ex officia.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-08.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
    ],
  },
  {
    _id: '59bfe7c07968d3bf8f5e58b0',
    index: 19,
    guid: 'ed6250aa-fbe5-47ce-aa1c-f4bbf5918550',
    captionText: 'Incididunt sit nostrud id fugiat laboris.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-07.png',
    docUrls: [],
  },
  {
    _id: '59bfe7c088ebcb0842687adc',
    index: 20,
    guid: '76c96aeb-0cca-4a0c-969d-2c280edd5c41',
    captionText: 'Nostrud anim ea do reprehenderit ipsum ullamco elit et.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-03.png',
    docUrls: [],
  },
  {
    _id: '59bfe7c05eb50d88f2e83894',
    index: 21,
    guid: 'cb31658c-7ddd-4ad2-9667-17763701eb01',
    captionText: 'Incididunt mollit ea Lorem nostrud nostrud in deserunt quis.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-07.png',
    docUrls: [
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-01.png',
    ],
  },
  {
    _id: '59bfe7c023c510fda6a7849b',
    index: 22,
    guid: '88d71cb7-ac15-41f7-aa4a-3b7007859d70',
    captionText:
      'Lorem Lorem nulla eiusmod minim mollit duis enim et incididunt aute.',
    avatarUrl:
      'https://cdn.idcreator.com/images/featured-customers/custom-id-cards-03.png',
    docUrls: [],
  },
];
