import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar } from 'antd';
import EnquiryContext from '../../EnquiryContext';
import avatarStylesheet from '../../../../constants/avatarStylesheet';
import { userLevel } from '../../../../constants/roles';
import brandName from '../../../../constants/brandName';

const avatarStyle = avatarStylesheet.oasis;

const StyledHistoryCard = styled.div`
  padding: 1rem;
  background-color: #f0f2f5;
  overflow: hidden;

  & + .history-card {
    margin-top: 1rem;
  }
`;

const MainInfo = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;

  .ant-avatar {
    margin-right: 0.75rem;
    flex-shrink: 0;
    user-select: none;
  }
`;

const UserInfo = styled.div`
  flex-grow: 1;
  overflow: hidden;

  p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const SubInfo = styled.div`
  margin-top: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

class HistoryCard extends React.Component {
  static contextType = EnquiryContext;

  static propTypes = {
    dataset: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const { dataset } = props;
    const nickname = _.get(dataset, 'nickname', '');
    const latestMsgTime = _.get(dataset, 'latestMsgTime');
    const userLevel = _.get(dataset, 'userLevel');

    this.state = {
      avatarName: nickname.substring(0, 1),
      latestMsgTime: this.transformTime(latestMsgTime),
      userLevel: this.transformUserLevel(userLevel)
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  transformTime(unixTime) {
    return Moment.unix(unixTime).format('YYYY-MM-DD hh:mm:ss');
  }

  transformUserLevel = level => {
    if (_.isEmpty(level)) return;

    const { levelId, levelNum } = level;
    const text = userLevel[levelId];
    return `${text}${levelNum}`;
  };

  render() {
    const { dataset, onClick } = this.props;
    const { avatarName, latestMsgTime, userLevel } = this.state;
    const { loginId, nickname, latestMsg } = dataset;

    return (
      <StyledHistoryCard className="history-card" onClick={onClick}>
        <MainInfo>
          <Avatar size={80} style={avatarStyle}>
            {avatarName}
          </Avatar>
          <UserInfo>
            <p>暱稱：{nickname}</p>
            <p>
              {brandName} ID：{loginId}
            </p>
            <p>等級：{userLevel}</p>
          </UserInfo>
        </MainInfo>
        <SubInfo>{latestMsg}</SubInfo>
        <SubInfo>發送時間：{latestMsgTime}</SubInfo>
      </StyledHistoryCard>
    );
  }
}

export default HistoryCard;
