import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Button } from 'antd';
import UsageRecord from './UsageRecord';
import PurchaseRecord from './PurchaseRecord';

const ButtonGroup = Button.Group;

const RecordTypes = [
  { id: 'usage', text: '點數使用紀錄', component: UsageRecord },
  { id: 'purchase', text: '點數儲值紀錄', component: PurchaseRecord },
];

const RecordTypeSelector = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

class PointHistoryModal extends React.Component {

  static propTypes = {
    userId: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      recordType: RecordTypes[0].id,
    };
  }

  show = () => {
    this.setState({ visible: true });
  }

  hide = () => {
    this.setState({ visible: false });
  }

  setRecordType = (recordType) => {
    this.setState({ recordType });
  }

  renderRecords = () => {
    const { userId } = this.props;
    const { recordType } = this.state;
    const target = _.find(RecordTypes, { id: recordType });
    return target && (
      <target.component userId={userId} />
    );
  }

  render() {
    const { recordType, visible } = this.state;

    return (
      <Modal
        width="60%"
        visible={visible}
        footer={null}
        onCancel={this.hide}
        centered
        maskClosable
      >
        <RecordTypeSelector>
          {RecordTypes.map(type => (
            <Button
              key={type.id}
              type={type.id === recordType ? 'primary' : ''}
              onClick={() => this.setRecordType(type.id)}
            >
              <span>{type.text}</span>
            </Button>
          ))}
        </RecordTypeSelector>
        {this.renderRecords()}
      </Modal>
    );
  }
}

export default PointHistoryModal;
