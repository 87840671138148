import React, { Component } from 'react';
import styled from 'styled-components';
import fakeData from '../fake/anchorManagement';
import { Input, Table, Select, message } from 'antd';
import { Link } from 'react-router-dom';
import { ANCHOR_MANAGEMENT } from '../constants/routes';

const { Option } = Select;
const { Search } = Input;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .title {
    font-size: 16px;
  }

  .tool-container {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }

  .select-field {
    margin-left: 4px;
    width: 100px;
  }

  .search-field {
    width: 250px;
    margin-left: 4px;
  }
`;

const TableContainer = styled.div`
  white-space: nowrap;

  .link-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .status-suspended,
  .danger-link {
    color: red;
  }
`;

class AnchorManagement extends Component {
  handleOnSearch = text => {
    if (text) message.success(`你搜尋了 ${text}`);
  };

  render() {
    const tableColumns = [
      {
        title: '名稱',
        dataIndex: 'nickname',
      },
      {
        title: '帳號',
        dataIndex: 'accountName',
      },
      {
        title: '狀態',
        dataIndex: 'status',
        render: text => {
          switch (text) {
            case 'normal':
              return <span>正常</span>;
            case 'suspended':
              return <span className="status-suspended">停權</span>;
            default:
              return null;
          }
        },
      },
      {
        title: '點數',
        dataIndex: 'point',
      },
      {
        title: '功能',
        render: record => (
          <div className="link-group">
            <Link to={`${ANCHOR_MANAGEMENT}/personal/${record._id}`}>個人資料</Link>
            <Link
              to={`${ANCHOR_MANAGEMENT}/daily-statistics-information/${record._id}`}
            >
              每日統計資訊
            </Link>
            <Link
              to={`${ANCHOR_MANAGEMENT}/dismantling-statistics/${record._id}`}
            >
              拆帳統計
            </Link>
            <Link to={`${ANCHOR_MANAGEMENT}/fans-gift-ranking/${record._id}`}>
              粉絲贈禮排行
            </Link>
            <Link
              to="#"
              className="danger-link"
              onClick={() => message.success(`帳號 ${record.accountName} 已經移除。`)}
            >
              移出
            </Link>
          </div>
        ),
      },
    ];

    return (
      <div>
        <h1>直播管理</h1>
        <Toolbar>
          <div className="tool-container">
            <span className="title">狀態：</span>
            <Select className="select-field" defaultValue="total">
              <Option value="total">全部</Option>
              <Option value="normal">正常</Option>
              <Option value="suspended">停權</Option>
            </Select>
          </div>
          <div className="tool-container">
            <div className="title">搜尋：</div>
            <Search
              className="search-field"
              onSearch={this.handleOnSearch}
              placeholder="請輸入搜尋字串"
            />
          </div>
        </Toolbar>
        <TableContainer>
          <Table rowKey="_id" columns={tableColumns} dataSource={fakeData} />
        </TableContainer>
      </div>
    );
  }
}

export default AnchorManagement;
