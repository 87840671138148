import { createAction, handleActions } from 'redux-actions';
import { switchMap, map } from 'rxjs/operators';
import { getReceiveGiftListAPI } from '../apis';
import { getPayloadByType, catchRequestError } from '../utils/extendOperators';
import moment from 'moment';

/**
 * Actions creators
 */
export const getGiftReport = createAction(
  'GET_GIFT_REPORT',
  ({
    page = initialState.page,
    liveMasterId = initialState.liveMasterId,
    startTime = initialState.startTime.format('X'),
    endTime = initialState.endTime.format('X'),
    item = initialState.item
  } = {}) => ({ liveMasterId, startTime, endTime, page, item })
);
const getGiftReportSuccess = createAction('GET_GIFT_REPORT_SUCCESS');
const getGiftReportFailure = createAction('GET_GIFT_REPORT_FAILURE');

export const changeLiveMasterName = createAction('CHANGE_LIVE_MASTER_NAME');
export const changeRangeTime = createAction('CHANGE_RANGE_TIME');

/**
 * Epics
 */
export const getGiftReportEpic = actions =>
  actions.pipe(
    getPayloadByType(getGiftReport),
    switchMap(payload =>
      getReceiveGiftListAPI(payload).pipe(
        map(getGiftReportSuccess),
        catchRequestError(getGiftReportFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  isLoading: false,
  startTime: moment().startOf('day').add(-7, 'days').unix(),
  endTime: moment().endOf('day').unix(),
  liveMasterId: [],
  data: [],
  page: 1,
  item: 10,
  totalCount: 0
};

export default handleActions(
  {
    [getGiftReport]: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: true
    }),
    [getGiftReportSuccess]: (state, action) => ({
      ...state,
      ...action.payload,
      data: action.payload.data.map((value, i) => ({
        ...value,
        id: `${state.liveMasterId.join('_')}${i + 1}_${state.page}`
      })),
      isLoading: false
    }),
    [getGiftReportFailure]: (state, action) => ({
      ...state,
      isLoading: false
    }),
    [changeLiveMasterName]: (state, action) => ({
      ...state,
      liveMasterId: action.payload
    }),
    [changeRangeTime]: (state, action) => ({
      ...state,
      ...action.payload
    })
  },
  initialState
);
