import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

class GiftArena extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gifts: [],
      current: null,
      timeoutRef: null,
    };
  }

  componentWillUnmount() {
    this.stopCarousel();
  }

  startCarousel = () => {
    const { gifts } = this.state;
    if (gifts.length <= 0) {
      this.stopCarousel();
      return;
    }
    const current = gifts.splice(0, 1)[0];
    const timeoutRef = setTimeout(this.startCarousel, 5000);
    this.setState({ timeoutRef, current });
  }

  stopCarousel = () => {
    const { timeoutRef } = this.state;
    clearTimeout(timeoutRef);

    this.setState({
      current: null,
      timeoutRef: null,
    });
  }

  push = (message) => {
    const { gifts } = this.state;
    const propContent = _.get(message, 'data.content');
    const content = propContent.slice(1);
    const url = _.get(message, 'data.giftUrl');
    const name = _.get(message, 'data.giftName');
    const gift = { content, url, name };
    this.preloadImage(url, () => {
      this.setState({ gifts: [...gifts, gift] }, () => {
        const { timeoutRef } = this.state;
        if (!timeoutRef) this.startCarousel();
      });
    });
  }

  preloadImage = (url, callback) => {
    let img = new Image();
    img.onload = callback;
    img.src = url;
    img = null;
  }

  render() {
    const { className } = this.props;
    const { current } = this.state;
    return !!current ? (
      <div className={className}>
        <img src={current.url} alt={current.name} />
        <p>{current.content}</p>
      </div>
    ) : null;
  }
}

const StyledGiftArena = styled(GiftArena)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  img {
    object-fit: contain;
  }

  p {
    max-width: 27.5%;
    margin: 0;
    padding: 0.25rem 0.5rem;
    position: absolute;
    top: 1rem;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0.25rem;
    color: #F0F0F0;
    transform: translateX(-50%);
    word-break: break-all;
  }
`;

export default StyledGiftArena;
