import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar } from 'antd';
import MessagePoolContext from '../MessagePoolContext';
import MessageComponent from './Content';

const MessageComponentEnum = new Map([
  ['text', MessageComponent.Chat],
  ['image', MessageComponent.Image],
  ['video', MessageComponent.Video],
]);

const Message = styled.div`
  width: 100%;
  display: flex;
  direction: ${props => props.master ? 'rtl' : 'ltr'};
  align-items: flex-start;
`;

const MessageHeader = styled.div`
  flex-shrink: 0;
`;

const MessageBody = styled.div`
  flex-grow: 1;
  margin-right: ${props => props.master ? '0.75rem' : '0'};
  margin-left: ${props => props.master ? '0' : '0.75rem'};
`;

const UserNickName = styled.div`
  display: flex;
  font-size: 0.9rem;
`;

const MessageContentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MessageContent = styled.div`
  max-width: 72%;
  margin-top: 0.25rem;
  background-color: ${props => props.master ? '#CCFF90' : '#FEFEFE'};
  line-height: 1;
  border-top-left-radius: ${props => props.master ? '1rem' : '0'};
  border-top-right-radius: ${props => props.master ? '0' : '1rem'};
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  white-space: break-spaces;
  word-break: break-word;
  direction: ltr;
`;

const MessgaeTime = styled.div`
  margin-right: ${props => props.master ? '0.5rem' : '0'};
  margin-left: ${props => props.master ? '0' : '0.5rem'};
  margin-bottom: 0.25rem;
  flex-shrink: 0;
  line-height: 1;
  font-size: 0.75rem;
  color: #9E9E9E;
`;

class InstantMessageChat extends React.Component {

  static contextType = MessagePoolContext;

  static propTypes = {
    message: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      user: {},
      isMaster: false,
    };
  }

  componentDidMount() {
    this.detectMaster();
  }

  componentWillUnmount() {
  }

  detectMaster = () => {
    const { message } = this.props;
    const { master, participant } = this.context;
    const { sender } = message;
    const senderId = _.get(sender, 'id', sender);
    const user = _.find([master, participant], { id: senderId });
    const isMaster = master.id === senderId;
    this.setState({ user, isMaster });
  }

  renderUserAvatar = () => {
    const { user } = this.state;
    const source = _.get(user, 'profilePicture');
    return (
      <Avatar src={source} size="large"></Avatar>
    );
  }

  renderMessageContent = () => {
    const { message } = this.props;
    const { msgType } = message;
    const comp = MessageComponentEnum.get(msgType);
    return comp ? React.createElement(comp, { message }) : null;
  }

  renderMessageTime = () => {
    const { message } = this.props;
    const createAt = Moment.unix(message.createAt);
    return createAt.format('HH:mm');
  }

  render() {
    const { user, isMaster } = this.state;
    return (
      <Message master={isMaster}>
        <MessageHeader>
          {this.renderUserAvatar()}
        </MessageHeader>
        <MessageBody master={isMaster}>
          <UserNickName>{user.nickname}</UserNickName>
          <MessageContentWrapper>
            <MessageContent master={isMaster}>
              {this.renderMessageContent()}
            </MessageContent>
            <MessgaeTime master={isMaster}>{this.renderMessageTime()}</MessgaeTime>
          </MessageContentWrapper>
        </MessageBody>
      </Message>
    );
  }
}

export default InstantMessageChat;
