import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Table } from 'antd';
import {
  Form,
  Icon as LegacyIcon
} from '@ant-design/compatible';
import {
  getPrivateVoiceChatSessionAPI,
  getPrivateVoiceChatLiveRecordingAPI,
} from '../../../apis';
import VoiceChatRoomContext from '../VoiceChatRoomContext';
import VoiceChatHistoryPlayer from './Player';

const { Column } = Table;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const Wrapper = styled.div`
  display: flex;
`;

const LeftPanel = styled.div`
  flex-grow: 1;
`;

const RightPanel = styled.div`
  width: 34rem;
  margin-left: 1rem;
  padding: 1rem 1rem 0;
  background: #FEFEFE;
  border-radius: 1rem;
`;

const RecordStatusIcon = styled(LegacyIcon)`
  font-size: 1.2rem;
  vertical-align: middle;
`;

const PlayListTable = styled(Table)`
  tr {
    cursor: pointer;
  }

  th, td {
    padding: 0.5rem !important;
    user-select: none;
    line-height: 1.75;
  }
`;

const Filters = styled(Form)`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .ant-form-item:last-child {
    margin-right: 0;
  }
`;

@Form.create()
class VoiceChatRoomHistory extends React.Component {

  static contextType = VoiceChatRoomContext;

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      isLoading: false,
      active: null,
      list: [],
      search: {},
      session: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
  }

  init = () => {
    this.fetchPrivateVoiceChatSession();
  }

  timeFormat = (value) => {
    return moment.unix(value).format('YYYY-MM-DD HH:mm:ss');
  }

  getFiltersValue = () => {
    const { form: { getFieldsValue } } = this.props;
    const values = getFieldsValue();

    const startMoment = _.get(values, 'times[0]', moment.invalid());
    const endMoment = _.get(values, 'times[1]', moment.invalid());
    if (startMoment.isValid() && endMoment.isValid()) {
      Object.assign(values, {
        startTime: startMoment.format('X'),
        endTime: endMoment.format('X'),
      });
    }
    delete values.times;

    return values;
  }

  fetchPrivateVoiceChatSession = () => {
    this.setState({ isLoading: true });

    const { roomId } = this.context;
    const { pagination, search } = this.state;
    const observable = getPrivateVoiceChatSessionAPI({
      privateVoiceChatId: roomId,
      item: pagination.pageSize,
      page: pagination.current,
      startTime: search.startTime,
      endTime: search.endTime,
    });
    observable.subscribe((response) => {
      const { pagination } = this.state;
      const { data: list, totalCount: total } = response;
      const newPagination = { ...pagination, total };
      this.setState({
        list,
        isLoading: false,
        pagination: newPagination,
      });
    });
  }

  fetchPrivateVoiceChatLiveRecording = () => {
    const  table = this.tableRef.current;
    const { roomId } = this.context;
    const { active } = this.state;

    active.loading = true;
    table.forceUpdate();

    const observable = getPrivateVoiceChatLiveRecordingAPI(roomId, {
      sessionId: active.id,
      itme: 9999,
    });
    observable.subscribe((response) => {
      // prcess data and save to state
      const info = _.pick(active, ['startTime', 'endTime']);
      const recordings = _.get(response, 'data.result', []);
      const session = { info, recordings };
      this.setState({ session });

      // update active record status
      if (!recordings.length) { active.silence = true; }
      active.loading = false;
      table.forceUpdate();
    });
  }

  onTableChange = (iPagination) => {
    const { pagination } = this.state;
    const { current } = iPagination;
    const newPagination = Object.assign({}, pagination, { current });

    this.setState({ pagination: newPagination }, () => {
      this.fetchPrivateVoiceChatSession();
    });
  };

  onRow = (record) => {
    return {
      onClick: () => this.onRowClick(record),
    };
  }

  onRowClick = (record) => {
    const { active } = this.props;
    if (active === record) return;
    this.setState({ active: record }, () => {
      this.fetchPrivateVoiceChatLiveRecording();
    });
  }

  onSearch = (event) => {
    event.preventDefault();

    const { pagination } = this.state;
    const newPagination = Object.assign({}, pagination, { current: 1 });

    this.setState({
      search: this.getFiltersValue(),
      pagination: newPagination,
    }, () => {
      this.fetchPrivateVoiceChatSession();
    });
  };

  renderRecordStatus = (record) => {
    const { active } = this.state;
    let iconProps = null;

    if (record.loading) {
      iconProps = {
        type: 'loading',
      };
    }
    else if (record.silence) {
      iconProps = {
        type: 'stop',
        style: { color: '#FF4D4F' },
      };
    }
    else if (active === record) {
      iconProps = {
        type: 'play-circle',
        theme: 'twoTone',
      };
    }
    
    return iconProps && (
      <RecordStatusIcon { ...iconProps } />
    );
  }

  renderRecordName = (rowdata) => {
    const { startTime } = rowdata;
    return this.timeFormat(startTime);
  }

  renderFilters = () => {
    const { isLoading, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Filters onSubmit={this.onSearch} layout="inline">
        <FormItem label="日期範圍">
          {getFieldDecorator('times')(
            <RangePicker
              showTime={{
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('23:59:59', 'HH:mm:ss'),
                ],
              }}
              format="YYYY-MM-DD HH:mm:ss"
              disabledDate={(current) => current > moment().endOf('day')}
            />
          )}
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            <span>查詢</span>
          </Button>
        </FormItem>
      </Filters>
    );
  }

  renderPlayList = () => {
    const { isLoading, list, pagination } = this.state;
    return (
      <PlayListTable
        ref={this.tableRef}
        rowKey="id"
        dataSource={list}
        pagination={pagination}
        loading={isLoading}
        onChange={this.onTableChange}
        onRow={this.onRow}
      >
        <Column
          title="播放狀態"
          key="playing"
          width="6rem"
          align="center"
          render={this.renderRecordStatus}
        />
        <Column
          title="ID"
          dataIndex="id"
          key="id"
        />
        <Column
          title="開始時間"
          key="name"
          render={this.renderRecordName}
        />
      </PlayListTable>
    );
  }

  render() {
    const { session } = this.state;
    return (
      <Wrapper>
        <LeftPanel>
          <VoiceChatHistoryPlayer session={session} />
        </LeftPanel>
        <RightPanel>
          {this.renderFilters()}
          {this.renderPlayList()}
        </RightPanel>
      </Wrapper>
    );
  }
}

export default VoiceChatRoomHistory;
