import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  getFamilyActiveMembersAPI,
  getFamilyInActiveMembersAPI,
} from '../../../apis';
import ActivityFilters from './ActivityFilters';
import MemberList from './MemberList';
import antdTablePagination from '../../../utils/antdTablePagination';

const ActivityActionMap = Object.freeze({
  active: getFamilyActiveMembersAPI,
  inactive: getFamilyInActiveMembersAPI,
});

const Filters = styled.div`
  margin-bottom: 0.75rem;
`;

class Mambers extends React.PureComponent {
  static propTypes = {
    family: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      activity: null,
      members: [],
      memberListPagination: {
        ...new antdTablePagination(),
        current: 1,
        onChange: this.onMemberListPaginationChange,
      },
    };
  }

  fetchMembers = () => {
    const { family } = this.props;
    const { activity, memberListPagination } = this.state;
    const id = _.get(family, 'id');
    const action = ActivityActionMap[activity];
    this.setState({ isLoading: true });
    const iPromise = action(id, {
      item: memberListPagination.pageSize,
      page: memberListPagination.current,
    }).toPromise();
    iPromise.then((res) => {
      this.setMembersFromRemoteList(res);
    });
    iPromise.finally(() => {
      this.setState({ isLoading: false });
    });
  };

  setMembersFromRemoteList = (response) => {
    const members = _.get(response, 'data', []);
    const total = _.get(response, 'totalCount', 0);
    this.patchMemberListPagination({ total });
    this.setState({ members });
  };

  patchMemberListPagination = (pagination, callback) => {
    const memberListPagination = {
      ...this.state.memberListPagination,
      ...pagination,
    };
    this.setState({
      memberListPagination,
    }, callback);
  };

  onActivityChange = (activity) => {
    this.setState({ activity }, this.fetchMembers);
  };

  onMemberListPaginationChange = (current) => {
    this.patchMemberListPagination({
      current,
    }, this.fetchMembers);
  };

  render() {
    const { members, memberListPagination } = this.state;
    return (
      <React.Fragment>
        <Filters>
          <ActivityFilters
            onChange={this.onActivityChange}
          />
        </Filters>
        <MemberList
          dataset={members}
          pagination={memberListPagination}
        />
      </React.Fragment>
    );
  }
}

export default Mambers;
