import { compose, head, pick } from 'ramda';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popconfirm, Table } from 'antd';
import styled from 'styled-components';
import {
  addAdBanner,
  closeModal,
  deleteAdBanner,
  getAdBannerList,
  setAdBanner,
  showModal,
  updateQuery
} from '../../reducers/adManagement';
import { findById } from '../../utils/mixin';
import CreateAdFormModal from './CreateAdFormModal';

const { Column } = Table;

const Reference = styled.a`
  word-wrap: break-word;
  word-break: break-word;
`;

const getSubmitData = compose(
  pick([
    'id',
    'linkUrl',
    'bannerUrl',
    'bannerZone',
    'bannerType',
    'startTime',
    'endTime'
  ]),
  value => ({
    ...value,
    startTime: value.timeRange ? value.timeRange[0].unix() : undefined,
    endTime: value.timeRange ? value.timeRange[1].unix() : undefined
  })
);

const Header = styled.header`
  margin-bottom: 10px;
  .ant-btn + .ant-btn,
  .ant-btn + span > .ant-btn {
    margin-left: 8px;
  }
`;

const AdManagement = ({ zone, type }) => {
  const dispatch = useDispatch();

  const { data, loading, isModalOpen, totalCount, page, item } = useSelector(
    state => state.adManagement
  );
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [editData, setEditData] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(getAdBannerList({ zone, type, item, page: 1 }));
    dispatch(updateQuery({ zone, type, item, page: 1 }));
    setSelectedRowKeys([]);
  }, [dispatch, zone, type, item]);

  const onAddClick = () => {
    setEditData(undefined);
    setIsCreateMode(true);
    dispatch(showModal());
  };

  const onEditClick = () => {
    setEditData(findById(head(selectedRowKeys), data));
    setIsCreateMode(false);
    dispatch(showModal());
  };

  const onDeleteClick = () => {
    dispatch(deleteAdBanner(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onPageChange = pagination => {
    dispatch(updateQuery({ page: pagination.current }));
    dispatch(getAdBannerList({ zone, type, item, page: pagination.current }));
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onFormSubmit = form => {
    form.validateFields().then(values => {
      if (isCreateMode) {
        dispatch(addAdBanner(getSubmitData(values)));
      } else {
        dispatch(setAdBanner(getSubmitData(values)));
      }
      setSelectedRowKeys([]);
    });
  };

  const onModalCancel = () => {
    setEditData(undefined);
    dispatch(closeModal(false));
  };

  const pagination = {
    current: page,
    pageSize: item,
    total: totalCount,
    showSizeChanger: false
  };

  const renderImage = src => {
    return <img src={src} style={{ width: 300, height: 'auto' }} alt="" />;
  };

  const renderReference = refer => {
    return (
      <Reference href={refer} rel="noreferrer" target="_blank">
        <span>{refer}</span>
      </Reference>
    );
  };

  const renderShelfTime = rowData => {
    const { startTime, endTime } = rowData;
    const format = 'YYYY-MM-DD HH:mm:ss';
    const start = moment.unix(startTime).format(format);
    const end = moment.unix(endTime).format(format);
    return (
      <React.Fragment>
        <div>{`${start} ~`}</div>
        <div>{` ${end}`}</div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Header>
        <Button onClick={onAddClick}>新增</Button>
        <Button disabled={selectedRowKeys.length !== 1} onClick={onEditClick}>
          <span>編輯</span>
        </Button>
        <Popconfirm
          disabled={selectedRowKeys.length === 0}
          key={'cancel'}
          title="是否確定刪除?"
          placement="right"
          onConfirm={onDeleteClick}
        >
          <Button type="danger" disabled={selectedRowKeys.length === 0}>
            <span>刪除</span>
          </Button>
        </Popconfirm>
      </Header>
      <Table
        rowKey="id"
        dataSource={data}
        loading={loading}
        pagination={pagination}
        onChange={onPageChange}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange
        }}
      >
        <Column
          width="21rem"
          title="行銷圖片"
          dataIndex="bannerUrl"
          render={renderImage}
        />
        <Column title="連結位置" dataIndex="linkUrl" render={renderReference} />
        <Column title="上下架時間" render={renderShelfTime} />
      </Table>
      <CreateAdFormModal
        isCreateMode={isCreateMode}
        visible={isModalOpen}
        loading={loading}
        editData={editData || { bannerZone: zone, bannerType: type }}
        onCancel={onModalCancel}
        onOk={onFormSubmit}
      />
    </React.Fragment>
  );
};

AdManagement.propTypes = {
  zone: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default AdManagement;
