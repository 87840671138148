module.exports = (() => {
  switch (process.env.NODE_ENV) {
    case 'test':
      return require('./configureStore.testing');
    case 'production':
      return require('./configureStore.production');
    default:
      return require('./configureStore.development');
  }
})();
