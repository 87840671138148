export const giftType = {
  liveRoom: { label: '直播間禮物', value: 1 },
  liveShow: { label: 'Live Show 禮物', value: 2 },
  post: { label: '動態禮物', value: 3 },
  voiceChat: { label: 'voiceChat 聲聊', value: 5 },
  sfxGift: { label: '動效禮物', value: 6 },
  instantMessage: { label: '一對一私訊扣點', value: 4 },
  backpackGift: { label: '背包禮物', value: 7 },
  giftRandom: { label: '隨機禮物', value: 8 },
  offensiveSfxGift: { label: '攻擊型動效禮物', value: 9 }
};

export const sfxDuration = [
  { label: '小', value: 3000 },
  { label: '中', value: 5000 },
  { label: '大', value: 10000 }
];
