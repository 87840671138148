/* eslint indent:0 */
import { createAction, handleActions, combineActions } from 'redux-actions';
import { pipe, find, filter, propEq, compose, pathOr } from 'ramda';
import { message } from 'antd';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {
  ofType,
  catchRequestError,
  getPayloadByType
} from '../utils/extendOperators';
import { createRequestTypes } from '../actions/Types';
import uuid from '../utils/uuid';
import { CDN_ROOT } from '../constants/endpoint';
import {
  listGiftBoxesAPI,
  addGiftBoxAPI,
  getGiftBoxAPI,
  removeGiftBoxAPI,
  modifyGiftBoxAPI,
  uploadImage as uploadImageAPI,
  setContentTypeIsNull,
  createContentType
} from '../apis';

const setForm = file => {
  setContentTypeIsNull();

  const form = new FormData();
  form.append('img', file);

  return form;
};

const getFileExt = file => file.name.split('.').pop();

const LIST_GIFTBOXES = createRequestTypes('LIST_GIFTBOXES');
const ADD_GIFTBOX = createRequestTypes('ADD_GIFTBOX');
const GET_GIFTBOX = createRequestTypes('GET_GIFTBOX');
const MODIFY_GIFTBOX = createRequestTypes('MODIFY_GIFTBOX');
const REMOVE_GIFTBOX = createRequestTypes('REMOVE_GIFTBOX');
const RESET_GIFTBOX = createRequestTypes('RESET_GIFTBOX');

export const listGiftBoxes = createAction(LIST_GIFTBOXES.REQUEST);
export const addGiftBox = createAction(ADD_GIFTBOX.REQUEST);
export const getGiftBox = createAction(GET_GIFTBOX.REQUEST);
export const modifyGiftBox = createAction(MODIFY_GIFTBOX.REQUEST);
export const removeGiftBox = createAction(REMOVE_GIFTBOX.REQUEST);
export const resetGiftBox = createAction(RESET_GIFTBOX.REQUEST);

export const listGiftBoxesEpic = (actions, { getState }) =>
  actions.pipe(
    ofType(LIST_GIFTBOXES.REQUEST),
    switchMap(({ payload = {} } = {}) =>
      listGiftBoxesAPI(payload).pipe(
        map(data =>
          createAction(LIST_GIFTBOXES.SUCCESS)({
            ...payload,
            ...data,
            loading: false
          })
        ),
        catchRequestError(error =>
          createAction(LIST_GIFTBOXES.FAILURE)({ error, loading: false })
        )
      )
    )
  );

export const addGiftBoxEpic = pipe(
  getPayloadByType(ADD_GIFTBOX.REQUEST),
  switchMap(({ event = {}, npcs = [], images = [], closeModal } = {}) =>
    uploadImagesIfNeed(npcs, images).pipe(
      switchMap(newNpcs =>
        addGiftBoxAPI(event).pipe(
          map(({ id }) =>
            newNpcs.map(n => ({
              ...n,
              relatedEvent: {
                ...n.relatedEvent,
                id
              }
            }))
          ),
          switchMap(body =>
            modifyGiftBoxAPI(body).pipe(
              tap(() => {
                message.success('活動已新增成功');
                closeModal();
              }),
              mergeMap(() => [
                createAction(ADD_GIFTBOX.SUCCESS)({ updating: false }),
                listGiftBoxes()
              ])
            )
          )
        )
      ),
      catchRequestError(error => {
        message.error(`新增活動失敗: ${error.message || error}`);
        console.log(error);
        return createAction(ADD_GIFTBOX.FAILURE)({ updating: false });
      })
    )
  )
);

const getGiftBoxEpic = pipe(
  ofType(GET_GIFTBOX.REQUEST),
  switchMap(({ payload = {} } = {}) =>
    getGiftBoxAPI(payload).pipe(
      map(data =>
        createAction(GET_GIFTBOX.SUCCESS)({
          npcs: data,
          loadingNPC: false
        })
      ),
      catchRequestError(error => {
        message.error('無法取得活動資料');
        return createAction(GET_GIFTBOX.FAILURE)({ error, loadingNPC: false });
      })
    )
  )
);

const modifyGiftBoxEpic = pipe(
  getPayloadByType(MODIFY_GIFTBOX.REQUEST),
  switchMap(({ npcs = [], images = [], closeModal } = {}) =>
    uploadImagesIfNeed(npcs, images).pipe(
      switchMap(newNpcs =>
        modifyGiftBoxAPI(newNpcs).pipe(
          tap(() => {
            message.success('活動已儲存成功');
            closeModal();
          }),
          mergeMap(() => [
            createAction(MODIFY_GIFTBOX.SUCCESS)({ updating: false }),
            listGiftBoxes()
          ])
        )
      ),
      catchRequestError(error => {
        message.error(`儲存活動失敗: ${error.message || error}`);
        console.log(error);
        return createAction(MODIFY_GIFTBOX.FAILURE)({ updating: false });
      })
    )
  )
);

const removeGiftBoxEpic = pipe(
  ofType(REMOVE_GIFTBOX.REQUEST),
  switchMap(({ payload = [] } = {}) =>
    forkJoin(...payload.map(id => removeGiftBoxAPI(id))).pipe(
      tap(() => message.success('活動已刪除')),
      mergeMap(() => [
        createAction(REMOVE_GIFTBOX.SUCCESS)({ loading: false }),
        listGiftBoxes()
      ]),
      catchRequestError(error => {
        message.error(`刪除活動失敗: ${error.message || message}`);
        return createAction(REMOVE_GIFTBOX.FAILURE)({ error, loading: false });
      })
    )
  )
);

const uploadImagesIfNeed = (npcs, images) =>
  forkJoin(
    ...(images.length === 0 ? [of({})] : []),
    ...images.map(a =>
      uploadImageAPI('/image/create/npcAnimation')(setForm(a.url.file)).pipe(
        map(res => ({
          ...a,
          type: a.type,
          url: `${CDN_ROOT}/npcAnimation/${res.hashs[0]}.${getFileExt(
            a.url.file
          )}`
        }))
      )
    )
  ).pipe(
    tap(() => createContentType('application/json;charset=UTF-8')),
    map(([...results]) =>
      npcs.map((n, nIdx) => ({
        ...n,
        animationSetting: {
          ...n.animationSetting,
          map: n.animationSetting.map.map((a, aIdx) => ({
            ...a,
            url: pathOr(
              a.url,
              ['url'],
              compose(
                find(propEq('aIdx', aIdx)),
                filter(propEq('nIdx', nIdx))
              )(results)
            )
          }))
        }
      }))
    )
  );

const nullNPC = () => ({
  additionEffects: [
    {
      id: undefined,
      points: undefined,
      probability: undefined,
      type: 1
    },
    {
      id: undefined,
      points: undefined,
      probability: undefined,
      type: 2
    },
    {
      // 爆擊上限不會有機率
      id: undefined,
      points: undefined,
      type: 3
    }
  ],
  animationSetting: {
    level: undefined,
    levelUpUrl: undefined,
    map: [
      {
        type: 1,
        url: undefined
        // url:
        //   'https://angle-live-casting.qcdn.network/npcAnimation/f04be1294d710da5c6ba0e76a83d8434.gif',
      },
      {
        type: 3,
        url: undefined
      },
      {
        type: 2,
        url: undefined
      },
      {
        type: 4,
        url: undefined
      }
    ]
  },
  id: uuid(),
  level: 1,
  name: undefined,
  noGiftTime: undefined,
  npcGiftCategoryIds: [],
  npcSpacings: [
    {
      id: undefined,
      npcDefinitionId: undefined,
      level: undefined,
      points: undefined
    }
  ],
  start: undefined,
  end: undefined,
  bonus: undefined,
  type: 1,
  relatedEvent: {}
});

const initialState = {
  loading: false,
  updating: false,
  loadingNPC: false,
  events: [],
  currentGiftBox: {
    eventName: undefined,
    startTime: undefined,
    endTime: undefined,
    giftCategoryId: undefined,
    type: 1, // 1 = 固定直播間, 2 = 隨機直播間
    npcs: [nullNPC(), nullNPC(), nullNPC()]
  },
  npcs: [nullNPC(), nullNPC(), nullNPC()],
  dateList: []
};

export const epics = [
  listGiftBoxesEpic,
  getGiftBoxEpic,
  addGiftBoxEpic,
  removeGiftBoxEpic,
  modifyGiftBoxEpic
];

export const actions = {
  listGiftBoxes,
  getGiftBox,
  modifyGiftBox,
  addGiftBox,
  removeGiftBox,
  resetGiftBox
};

export const dateList = handleActions(
  {
    [LIST_GIFTBOXES.SUCCESS]: (state, action) => {
      return action.payload.map(event => {
        return { startTime: event.startTime, endTime: event.endTime };
      });
    },
    [LIST_GIFTBOXES.FAILURE]: (state, action) => []
  },
  initialState.dateList
);

export default handleActions(
  {
    [LIST_GIFTBOXES.REQUEST]: state => ({
      ...state,
      loading: true
    }),

    [GET_GIFTBOX.REQUEST]: state => ({
      ...state,
      loadingNPC: true
    }),

    [ADD_GIFTBOX.REQUEST]: state => ({
      ...state,
      updating: true
    }),

    [MODIFY_GIFTBOX.REQUEST]: state => ({
      ...state,
      updating: true
    }),

    [RESET_GIFTBOX.REQUEST]: state => ({
      ...state,
      currentGiftBox: initialState.currentGiftBox,
      npcs: initialState.npcs
    }),
    [combineActions(LIST_GIFTBOXES.SUCCESS, LIST_GIFTBOXES.FAILURE)]: (
      state,
      action
    ) => ({
      ...state,
      ...action.payload,
      dateList: dateList(state, {
        type: action.type,
        payload: action.payload.events
      })
    }),
    [combineActions(
      ADD_GIFTBOX.SUCCESS,
      ADD_GIFTBOX.FAILURE,
      GET_GIFTBOX.SUCCESS,
      GET_GIFTBOX.FAILURE,
      MODIFY_GIFTBOX.SUCCESS,
      MODIFY_GIFTBOX.FAILURE,
      REMOVE_GIFTBOX.SUCCESS,
      REMOVE_GIFTBOX.FAILURE
    )]: (state, action) => ({
      ...state,
      ...action.payload
    })
  },
  initialState
);
