import { handleActions, createAction } from 'redux-actions';
import produce from 'immer';
import { switchMap, map } from 'rxjs/operators';
import { getPayloadByType, catchRequestError } from '../utils/extendOperators';

import { getPurchaseOrderListApi } from '../apis';
/**
 * Type
 */
const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER';
const GET_PURCHASE_ORDER_SUCCESS = 'GET_PURCHASE_ORDER_SUCCESS';
const GET_PURCHASE_ORDER_FAILURE = 'GET_PURCHASE_ORDER_FAILURE';

/**
 * Creator
 */
export const getPurchaseOrder = createAction(
  GET_PURCHASE_ORDER,
  ({ page = 1, item = 20, ...payload }) => ({ page, item, ...payload })
);
const getPurchaseOrderSuccess = createAction(GET_PURCHASE_ORDER_SUCCESS);
const getPurchaseOrderFailure = createAction(GET_PURCHASE_ORDER_FAILURE);

export const getOrderEpic = action$ =>
  action$.pipe(
    getPayloadByType(GET_PURCHASE_ORDER),
    switchMap(payload =>
      getPurchaseOrderListApi(payload).pipe(
        map(getPurchaseOrderSuccess),
        catchRequestError(getPurchaseOrderFailure)
      )
    )
  );

const initialState = {
  loading: false,
  order: [],
  page: 1,
  totalCount: 0,
  item: 20
};

export default handleActions(
  {
    [GET_PURCHASE_ORDER]: produce((draft, { payload }) => {
      draft.loading = true;
      draft.page = payload.page;
    }),
    [GET_PURCHASE_ORDER_SUCCESS]: produce((draft, { payload }) => {
      draft.order = payload.purchaseOrderList;
      draft.totalCount = payload.totalCount;
      draft.loading = false;
    }),
    [GET_PURCHASE_ORDER_FAILURE]: produce(draft => {
      draft.order = [];
      draft.page = 1;
      draft.totalCount = 0;
      draft.loading = false;
    })
  },
  initialState
);
