import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, DatePicker } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { getRoomListAPI } from '../../../apis';
import HistoryCard from './HistoryCard';
import HistoryModal from './HistoryModal';

const { RangePicker } = DatePicker;

const StyledSupportHistory = styled.div`
  height: 100%;
  overflow: hidden;
`;

const SearchHint = styled.div`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  label {
    flex-shrink: 0;
  }

  .ant-calendar-picker {
    flex-grow: 1;
  }

  button {
    margin-left: 0.5rem;
  }
`;

const SearchCards = styled.div`
  height: calc(100% - 5rem);
  overflow-y: auto;
`;

const EmptyHint = styled.div`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9E9E9E;

  i {
    margin-right: 0.25rem;
    transform: translateY(0.05em);
  }
`;

class SupportHistory extends React.Component {

  static propTypes = {
    dataset: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.HistoryModalRef = React.createRef();

    this.state = {
      defaultRange: this.genDefaultRange(),
      histories: [],
      search: {
        startTime: null,
        endTime: null,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
  }

  init = () => {
    const { defaultRange } = this.state;
    this.onRangChange(defaultRange);
    this.fetchHistoryList();
  }

  genDefaultRange = () => {
    const to = Moment().endOf('day');
    const from = Moment().subtract(7, 'days').startOf('day');
    return [ from, to ];
  }

  fetchHistoryList = () => {
    const { dataset, userId } = this.props;
    const { search } = this.state;

    const relatedID = _.get(dataset, 'user.id');
    const observable = getRoomListAPI({ ...search, userId: relatedID });
    observable.subscribe((response) => {
      const { data } = response;
      const histories = _.reject(data, { userId });
      this.setState({ histories });
    });
  }

  onRangChange = (range) => {
    if (!_.size(range)) return;

    const { search } = this.state;
    const startTime = range[0].unix();
    const endTime = range[1].unix();
    this.setState({
      search: { ...search, startTime, endTime },
    });
  }

  onSearch = () => {
    this.fetchHistoryList();
  }

  onCardClick = (dataset) => {
    const { HistoryModalRef } = this;
    const modal = HistoryModalRef.current;
    modal.show(dataset);
  }

  renderSearchHint = () => {
    const { dataset } = this.props;
    const nickname = _.get(dataset, 'user.nickname');
    return (
      <SearchHint>{`${nickname} 與其他人的聊天記錄`}</SearchHint>
    );
  }

  renderSearchBar = () => {
    const { defaultRange } = this.state;
    return (
      <SearchBar>
        <label>期間：</label>
        <RangePicker
          allowClear={true}
          defaultValue={defaultRange}
          showTime={{
            defaultValue: [
              Moment('00:00:00', 'HH:mm:ss'),
              Moment('23:59:59', 'HH:mm:ss'),
            ],
          }}
          onChange={this.onRangChange}
        />
        <Button
          type="primary"
          onClick={this.onSearch}
        >查詢</Button>
      </SearchBar>
    );
  }

  renderHistories = () => {
    const { histories } = this.state;
    const size = _.size(histories);
    return size ? (
      <SearchCards>
        {histories.map((data) => (
          <HistoryCard
            key={data.userId}
            dataset={data}
            onClick={() => this.onCardClick(data)}
          />
        ))}
      </SearchCards>
    ) : (
      <EmptyHint>
        <LegacyIcon type="exclamation-circle" />
        <span>查無此期間內的訊息</span>
      </EmptyHint>
    );
  }

  render() {
    const { search } = this.state;
    return (
      <StyledSupportHistory>
        {this.renderSearchHint()}
        {this.renderSearchBar()}
        {this.renderHistories()}
        <HistoryModal
          ref={this.HistoryModalRef}
          range={search}
        />
      </StyledSupportHistory>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.auth.id,
});

export default connect(
  mapStateToProps,
)(SupportHistory);
