import {
  complement, compose, concat,
  curry, equals, filter,
  flatten, groupBy, includes,
  map, omit, pathOr,
  prop, reject, uniq,
  uniqBy,
} from 'ramda';
import { of } from 'rxjs';
import { filter as rxFilter, pluck, switchMap } from 'rxjs/operators';
import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal, Tag, Tabs, Popconfirm, Spin } from 'antd';
import { EditOutlined, TagsOutlined } from '@ant-design/icons';
import tagTypes from '../../constants/tagTypes';
import { getBackendTagListAPI, addUserTagAPI } from '../../apis';
import { isReallyEmpty } from '../../utils/mixin';

const { TabPane } = Tabs;
const { CheckableTag } = Tag;

const TagsWrapper = styled.div`
  margin-bottom: 0.5rem;
  line-height: 2.2;
`;

const omitMeta = omit(['data-__field', 'data-__meta']);
const pickGroup = compose(uniqBy(prop('id')), flatten, map(prop('groups')));
const assignTag = curry((tagList, groupList) =>
  map(
    g => ({
      ...g,
      tags: compose(
        map(omit(['groups'])),
        filter(t => includes(g, t.groups))
      )(tagList)
    }),
    groupList
  )
);
const groupByType = groupBy(prop('type'));
const UserTag = props => (
  <CheckableTag
    style={{
      borderColor: '#d9d9d9',
      cursor: 'pointer'
    }}
    {...props}
  />
);
const GroupHeader = ({ label }) => (
  <div
    style={{
      borderBottom: '1px solid #E9E9E9',
      padding: '4px 0'
    }}
  >
    {
      <div>
        <TagsOutlined style={{ paddingRight: '8px' }} />
        {label}
      </div>
    }
  </div>
);
const defaultValue = [];
const UserTagEditor = memo(
  forwardRef(({ value = defaultValue, uuid, callback }, ref) => {
    const getTagList$ = useRef(
      getBackendTagListAPI({
        page: 1,
        item: 9999
      }).pipe(pluck('data'))
    );

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [groupListByType, setGroupListByType] = useState([]);
    const [selectedTags, setSelectedTags] = useState(value);

    const handlerTagClick = (checked, id) => {
      if (checked) {
        setSelectedTags(tags => compose(uniq, concat)(tags, [id]));
      } else {
        setSelectedTags(tags => reject(equals(id), tags));
      }
    };
    const handleSubmit = () => {
      const subscription = of({
        uuid,
        tags: selectedTags
      })
        .pipe(
          rxFilter(({ uuid }) => complement(isReallyEmpty)(uuid)),
          switchMap(payload => {
            setLoading(true);
            return addUserTagAPI(payload);
          })
        )
        .subscribe({
          next: () => {
            setLoading(false);
            setShowModal(false);
          },
          error: () => {
            setLoading(false);
            subscription.unsubscribe();
          },
          complete: () => {
            subscription.unsubscribe();
            if (callback) {
              callback();
            }
          }
        });
    };
    const handlercancelModal = () => {
      setShowModal(false);
    };
    useEffect(() => {
      if (showModal) {
        setSelectedTags(value.map(tag => tag.id));
      }
    }, [value, showModal]);
    useEffect(() => {
      const subscription = of(showModal)
        .pipe(
          rxFilter(showModal => showModal),
          switchMap(() => {
            setLoading(true);
            return getTagList$.current;
          })
        )
        .subscribe({
          next: data => {
            setLoading(false);
            setGroupListByType(groupByType(assignTag(data)(pickGroup(data))));
          },
          error: () => {
            setLoading(false);
          },
          complete: () => {
            setLoading(false);
          }
        });
      return () => {
        subscription.unsubscribe();
      };
    }, [showModal]);
    return (
      <div ref={ref}>
        <TagsWrapper>
          {value.map(tag => (
            <Tag key={tag.id} color="#108ee9">
              {tag.label}
            </Tag>
          ))}
        </TagsWrapper>
        <Button
          onClick={() => {
            setLoading(true);
            setShowModal(true);
          }}
        >
          <EditOutlined />
          <span>編輯標籤</span>
        </Button>
        <Modal
          visible={showModal}
          onCancel={handlercancelModal}
          destroyOnClose={true}
          confirmLoading={loading}
          footer={[
            <Popconfirm
              key="confirm"
              title="是否確定儲存"
              placement="topLeft"
              onConfirm={handleSubmit}
            >
              <Button key="ok" disabled={loading} type="primary">
                儲存
              </Button>
            </Popconfirm>,
            <Button
              key="cancle"
              style={{ marginLeft: 8 }}
              onClick={handlercancelModal}
            >
              取消
            </Button>
          ]}
        >
          <Spin spinning={loading}>
            <Tabs defaultActiveKey="user">
              {tagTypes.map(tagType => (
                <TabPane
                  tab={tagType.label}
                  key={tagType.value}
                  style={{ minHeight: '320px' }}
                >
                  {compose(
                    map(g => (
                      <div
                        key={g.id}
                        style={{
                          marginBottom: '8px'
                        }}
                      >
                        <GroupHeader label={g.label}></GroupHeader>
                        <div style={{ padding: '8px 16px' }}>
                          {g.tags.map(tag => (
                            <UserTag
                              key={`${g.id}_${tag.id}`}
                              checked={includes(tag.id, selectedTags)}
                              onChange={checked =>
                                handlerTagClick(checked, tag.id)
                              }
                            >
                              {tag.label}
                            </UserTag>
                          ))}
                        </div>
                      </div>
                    )),
                    pathOr([], [tagType.value])
                  )(groupListByType)}
                </TabPane>
              ))}
            </Tabs>
          </Spin>
        </Modal>
      </div>
    );
  }),
  (prevProps, nextProps) => equals(omitMeta(prevProps), omitMeta(nextProps))
);
export default UserTagEditor;
