import {
  compose,
  head,
  cond,
  T,
  isNil,
  props,
  not,
  identity,
  partition,
  propEq,
  zipObj,
  flatten
} from 'ramda';
import { useWith as ramdaUseWith } from 'ramda';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Table } from 'antd';
import { parseQs } from '../../utils/mixin';
import { getDialog } from '../../reducers/messageDialog';
import { userLevel } from '../../constants/roles';
import TableContainer from '../../components/TableContainer';
import LightBox from '../../components/LightBox';

import LightVideoBox from './LightVideoBox';
const Container = styled.div`
  margin: 16x 0;
  padding-right: 16px;
`;
const getUsers = ramdaUseWith(
  (users, currentUser) => {
    if (users && currentUser) {
      return compose(
        zipObj(['sender', 'receiver']),
        flatten,
        partition(propEq('id', currentUser))
      )(users);
    }
    return null;
  },
  [
    compose(
      cond([
        [compose(not, isNil), props(['sender', 'receiver'])],
        [T, () => null]
      ]),
      head
    ),
    identity
  ]
);
const mapStateToProps = ({
  messageDialog: { data, item, loading, totalCount }
}) => ({
  data,
  item,
  loading,
  totalCount
});
const mapDispatchToProps = {
  getDialog
};
@connect(mapStateToProps, mapDispatchToProps)
class MessageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      ...parseQs(props.location.search)
    };
  }

  componentDidMount() {
    this.getDialogByPage(1);
  }
  getDialogByPage = (page = 1) => {
    const {
      match: {
        params: { id }
      },
      getDialog,
      item
    } = this.props;
    const { startTime, endTime } = this.state;
    getDialog({ dialogId: id, item, page, startTime, endTime });
  };
  generateTableColumn = () => {
    const tableColumns = [
      {
        title: '發送時間',
        dataIndex: 'createAt',
        key: 'createAt',
        render: value =>
          value && moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: '發送者',
        dataIndex: ['sender', 'id'],
        render: (value, record) => (
          <span>{`${record.sender.loginId} (${record.sender.nickname})`}</span>
        )
      },
      {
        title: '發送對象等級',
        dataIndex: ['sender', 'userLevel'],
        render: value => `${userLevel[value.levelId]} ${value.levelNum}`
      },
      {
        title: '發送內容',
        dataIndex: 'textContent',
        render: (value, record) =>
          cond([
            [
              propEq('msgType', 'text'),
              record => (
                <span style={{ wordBreak: 'break-all' }}>
                  {record.textContent}
                </span>
              )
            ],
            [
              propEq('msgType', 'video'),
              record => <LightVideoBox src={record.videoUrl} />
            ],
            [
              propEq('msgType', 'image'),
              record => <LightBox src={record.imageUrl} />
            ],
            [T, () => '']
          ])(record)
      }
    ];
    return tableColumns;
  };

  /**
   * 點擊頁數
   */
  handleTableChange = pagination => {
    this.setState({ currentPage: pagination.current });
    this.getDialogByPage(pagination.current);
  };

  render() {
    const { currentUser, currentPage } = this.state;
    const { data, loading, item, totalCount } = this.props;
    const users = getUsers(data, currentUser);
    const pagination = {
      current: currentPage,
      pageSize: item,
      total: totalCount
    };
    return (
      <div>
        <h1>{'經營管理 > 私訊紀錄查詢'}</h1>
        <Container>
          <h3>私訊紀錄</h3>
          <h4>
            查詢帳號：
            {users ? (
              <span>
                {' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >{`${users.sender.loginId} (${users.sender.nickname})`}</span>{' '}
                <span>{`> ${users.receiver.loginId} (${users.receiver.nickname})`}</span>
              </span>
            ) : (
              ''
            )}
          </h4>
        </Container>
        <TableContainer>
          <Table
            loading={loading}
            dataSource={data}
            rowKey={record => `${record.sender.id}${record.createAt}`}
            columns={this.generateTableColumn()}
            onChange={this.handleTableChange}
            pagination={pagination}
          ></Table>
        </TableContainer>
      </div>
    );
  }
}

export default MessageDialog;
