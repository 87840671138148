import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { find, identity, path, propEq, useWith as ramdaUseWith } from 'ramda';
import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { createContentType, setContentTypeIsNull, uploadAPI } from '../apis';
import { isReallyEmpty } from '../utils/mixin';
const createFile = url => {
  if (isReallyEmpty(url)) {
    return { uid: '-1', name: '無資料', status: 'error' };
  }
  const name = url.split('/').slice(-1)[0];
  return {
    uid: '-1',
    name: name.length > 1 ? name : 'Preview',
    url: url,
    status: 'done'
  };
};
const findByUrl = ramdaUseWith(find, [propEq('url'), identity]);
const FileUpload = memo(
  forwardRef(
    ({ value, onChange, uploadUrl, children, disabled = false }, ref) => {
      const [fileList, setFileList] = useState([]);
      const subscriptionRef = useRef();
      const handleChange = ({ file, file: { status }, fileList }) => {
        if (status) {
          setFileList(
            fileList.slice(-1).map(file => {
              if (file.response) {
                file.url = file.response;
              }
              return file;
            })
          );
        }
        if (status === 'done') {
          onChange(file.response);
        }
        if (status === 'removed') {
          onChange(null);
        }
      };

      useEffect(() => {
        const valueArray = isReallyEmpty(value) ? [] : [value];
        setFileList(list =>
          valueArray.map(v => findByUrl(v, list) || createFile(v))
        );
      }, [value]);

      //unsubscribe uploaders
      useEffect(() => {
        return () => {
          if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
          }
        };
      }, []);

      return (
        <Upload
          disabled={disabled}
          ref={ref}
          fileList={fileList}
          onChange={handleChange}
          customRequest={info => {
            const form = new FormData();
            form.append('file', info.file);
            setContentTypeIsNull();
            subscriptionRef.current = uploadAPI(uploadUrl)(form).subscribe(
              res => {
                try {
                  info.onSuccess(path(['data', 'url'], res));
                } catch (e) {
                  message.error(e.name);
                }
                createContentType('application/json;charset=UTF-8');
              },
              error => {
                info.onError(error);
              }
            );
          }}
        >
          <Button disabled={disabled}>
            <UploadOutlined />
            {children || '上傳檔案'}
          </Button>
        </Upload>
      );
    }
  )
);
export default FileUpload;
