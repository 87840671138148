import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SketchOutlined } from '@ant-design/icons';

class GiftCategory extends React.Component {

  static propTypes = {
    gifts: PropTypes.array,
    onGiftSeleted: PropTypes.func,
  }

  static defaultProps = {
    gifts: [],
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderGift = (gift) => {
    const { onGiftSeleted } = this.props;
    const thumb = `url(${gift.thumbUrl})`;
    const thumbStyle = { backgroundImage: thumb };

    return (
      <div
        key={gift.id}
        className="gift"
        onClick={() => onGiftSeleted(gift.uuid)}
      >
        <div className="gift-thumb" style={thumbStyle}></div>
        <div className="gift-name">{gift.name}</div>
        <div className="gift-price">
          <SketchOutlined />
          <span>{gift.point}</span>
        </div>
      </div>
    );
  }

  render() {
    const { className, gifts } = this.props;
    return (
      <div className={className}>
        <div className="gift-inner">
          {gifts.map(gift => this.renderGift(gift))}
        </div>
      </div>
    );
  }
}

const StyledGiftCategory = styled(GiftCategory)`
  height: 100%;
  padding-top: 0.25rem;
  overflow-x: hidden;
  overflow-y: auto;

  .gift-inner {
    display: flex;
    flex-wrap: wrap;
  }

  .gift {
    padding: 1%;
    flex-basis: 25%;
    cursor: pointer;
    color: #757575;

    &:hover {
      color: #424242;
    }

    .gift-thumb {
      padding-top: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .gift-name {
      margin: 0.2rem 0 0;
      text-align: center;
    }

    .gift-price {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;

      i {
        margin-right: 0.2rem;
      }
    }
  }
`;

export default StyledGiftCategory;
