import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import UserSelector from './UserSelector';
import StatusSelector from './StatusSelector';
import RoleSelector from './RoleSelector';

const filtersEnum = Object.freeze({
  userFilter: 'userFilter',
  statusFilter: 'statusFilter',
  roleFilter: 'roleFilter',
});

const defaultValue = {
  userFilter: { keyword: '', type: 'all' },
  statusFilter: [],
  roleFilter: null,
};

class UserSearchBar extends React.Component {

  static propTypes = {
    initialValue: PropTypes.object,
    filters: PropTypes.array,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    filters: _.values(filtersEnum),
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.initialValue = {
      ...defaultValue,
      ...props.initialValue,
    };

    this.state = {
      filters: new Set(props.filters),
    };
  }

  componentDidMount() {
    const { initialValue } = this.props;
    const form = this.formRef.current;
    form.setFieldsValue({
      ...defaultValue,
      ...initialValue,
    });
  }

  getFieldsValue = () => {
    const form = this.formRef.current;
    return form.getFieldsValue();
  }

  setFieldsValue = (values) => {
    const form = this.formRef.current;
    form.setFieldsValue(values);
  }

  onSubmit = (values) => {
    const { onSubmit } = this.props;
    if (!onSubmit) return;
    onSubmit(values);
  }

  renderUserFilter = () => {
    const { filters } = this.state;
    const key = filtersEnum.userFilter;
    return filters.has(key) ? (
      <Form.Item
        name={key}
        initialValue={this.initialValue.userFilter}
      >
        <UserSelector />
      </Form.Item>
    ) : null;
  }

  renderStatusFilter = () => {
    const { filters } = this.state;
    const key = filtersEnum.statusFilter;
    return filters.has(key) ? (
      <Form.Item
        label="狀態"
        name={key}
        initialValue={this.initialValue.statusFilter}
      >
        <StatusSelector />
      </Form.Item>
    ) : null;
  }

  renderRoleFilter = () => {
    const { filters } = this.state;
    const key = filtersEnum.roleFilter;
    return filters.has(key) ? (
      <Form.Item
        label="角色"
        name={key}
        initialValue={this.initialValue.roleFilter}
      >
        <RoleSelector />
      </Form.Item>
    ) : null;
  }

  render() {
    return (
      <Form
        ref={this.formRef}
        layout="inline"
        onFinish={this.onSubmit}
      >
        {this.renderUserFilter()}
        {this.renderStatusFilter()}
        {this.renderRoleFilter()}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            <span>搜尋</span>
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default UserSearchBar;
