import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { getAgencyAPI, getAgencyListAPI } from '../../apis';

const StatusEnum = Object.freeze({
  ALL: 'all',
  EXPIRED: 'expired',
  PROCESS: 'process',
});

const StatusValues = Object.values(StatusEnum);

class AgencySelector extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    statusFilter: PropTypes.oneOf(StatusValues),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    statusFilter: StatusEnum.PROCESS,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      isFetching: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { value } = this.props;
    if (!value) return;

    this.fetchAgency(value);
  };

  fetchAgency = (id) => {
    const { isFetching } = this.state;
    if (isFetching) return;

    this.setState({ isFetching: true });
    const iPromise = getAgencyAPI(id).toPromise();
    iPromise.then((res) => {
      const { data } = res;
      this.setOptionsFromAgency([data]);
    });
    iPromise.finally(() => {
      this.setState({ isFetching: false });
    });
  };

  fetchAgencyList = (keyword) => {
    const { isFetching } = this.state;
    if (isFetching) return;

    const { statusFilter } = this.props;
    this.setState({ isFetching: true });
    const iPromise = getAgencyListAPI({
      keyword,
      agencyStatusFilter: statusFilter,
    }).toPromise();
    iPromise.then((res) => {
      const { data } = res;
      this.setOptionsFromAgency(data);
    });
    iPromise.finally(() => {
      this.setState({ isFetching: false });
    });
  };

  setOptionsFromAgency = (data) => {
    const options = _.map(data, (option) => ({
      label: option.agencyName,
      value: option.agencyId,
    }));
    this.setState({ options });
  };

  triggerChange = (value) => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  };

  onSearch = _.debounce(this.fetchAgencyList, 300);

  onSelect = (value) => {
    this.triggerChange(value);
  };

  render() {
    const { value } = this.props;
    const { isFetching, options } = this.state;
    return (
      <Select
        value={value}
        options={options}
        filterOption={false}
        showSearch={true}
        notFoundContent={isFetching && <Spin size="small" />}
        onSearch={this.onSearch}
        onSelect={this.onSelect}
      />
    );
  }
}

export default AgencySelector;
