import { red } from '@ant-design/colors';
import {
  DownloadOutlined,
  FileZipOutlined,
  GiftOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Tag
} from 'antd';
import moment from 'moment';
import { evolve, indexBy, isEmpty, prop } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/TableContainer';
import UserSelector from '../../components/UserSearchBar/UserSelector';
import { contractStatus } from '../../constants/agency';
import { type as contractType } from '../../constants/contract';
import { AGENCY_MANAGEMENT, USER_MANAGEMENT } from '../../constants/routes';
import { getAgency } from '../../reducers/agencyManagement';
import {
  addAgencyMaster,
  deleteAgencyMaster,
  downloadAgencyMasterList,
  getAgencyMaster,
  getAgencyMasterList,
  replaceAgencyMasterContract,
  setAgencyMaster,
  showModal
} from '../../reducers/agencyMasterManagement';
import { isReallyEmpty, parseQs } from '../../utils/mixin';
import AgencyMasterTerminateModal from '../AgencyManagement/AgencyMasterTerminateModal';
import AgencyMasterFormModal from './AgencyMasterFormModal';

const Option = Select.Option;
const FormItem = Form.Item;
const RedColor = red[0];
const MODE = { CREATE: 'CREATE', EDIT: 'EDIT', REPLACE: 'REPLACE' };
const ContractStatusColor = {
  unsigned: <Tag color="gold">未開始</Tag>,
  progress: <Tag color="green">進行中</Tag>,
  expired: <Tag>已失效</Tag>,
  expiring: <Tag color="red">即將到期</Tag>
};
const contractTypeByID = indexBy(prop('id'), contractType);
const getSubmitData = evolve({
  contractStartDate: value => moment(value).unix()
});
const columns = [
  {
    title: '狀態',
    width: 100,
    dataIndex: 'contractStatus',
    fixed: 'left',
    render: value => ContractStatusColor[value]
  },
  { title: '直播主暱稱', dataIndex: 'nickname', fixed: 'left' },
  { title: 'Login ID', dataIndex: 'loginId' },
  {
    title: '合約期間',
    dataIndex: 'contractStartDate',
    render: (value, { contractEndDate, ...record }) => {
      return {
        props: {
          style: {
            backgroundColor:
              record.contractStatus === contractStatus[4].id ? RedColor : ''
          }
        },
        children: (
          <div>
            <div>{moment.unix(value).format('YYYY-MM-DD')}~</div>
            <div>{moment.unix(contractEndDate).format('YYYY-MM-DD')}</div>
          </div>
        )
      };
    }
  },
  { title: '合約名稱', dataIndex: 'contractName' },
  {
    title: '新增時間',
    dataIndex: 'createdAt',
    render: value => moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
  },
  {
    title: '移除時間',
    dataIndex: 'deletedAt',
    render: value =>
      value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-'
  },
  {
    title: '合約類型',
    dataIndex: 'contractType',
    render: value => contractTypeByID[value].label
  },
  { title: '合約終止備註', dataIndex: 'expiredRemark' },
  { title: '備註', dataIndex: 'remark' },
  {
    title: '功能',
    render: value => (
      <Space
        size="small"
        split={<Divider type="vertical" style={{ margin: '0' }} />}
      >
        <Link to={`${USER_MANAGEMENT}/personal/${value.uuid}`}>
          <Tooltip placement="top" title="直播主資訊">
            <UserOutlined />
          </Tooltip>
        </Link>
        <Link to={`/gift-report?liveMasterId=${value.uuid}`}>
          <Tooltip placement="top" title="收禮報表">
            <GiftOutlined />
          </Tooltip>
        </Link>

        <a
          href={value.fileUrl}
          disabled={isReallyEmpty(value.fileUrl)}
          download
        >
          <Tooltip placement="top" title="下載合約">
            <DownloadOutlined />
          </Tooltip>
        </a>
        {value?.expiredFileUrl ? (
          <a href={value.expiredFileUrl} download>
            <Tooltip placement="topRight" title="下載終止合約憑證">
              <FileZipOutlined />
            </Tooltip>
          </a>
        ) : null}
      </Space>
    )
  }
];

const defaultSearch = {
  keyword: '',
  type: 'nick_name',
  contractStatusFilter: contractStatus[2].id,
  contractTypeFilter: contractType[0].id,
  page: 1
};
const mapState = ({
  agencyMasterManagement,
  agencyManagement: { currentAgency }
}) => ({ ...agencyMasterManagement, currentAgency });
const mapDispatch = {
  getAgencyMasterList,
  addAgencyMaster,
  getAgencyMaster,
  setAgencyMaster,
  replaceAgencyMasterContract,
  deleteAgencyMaster,
  showModal,
  getAgency
};

class AgencyMasterManagement extends Component {
  constructor(props) {
    super(props);
    const search = parseQs(this.props.history.location.search);
    this.state = {
      mode: undefined,
      selectedRowKeys: [],
      isUnsigned: false,
      isShowTerminate: false,
      ...defaultSearch,
      contractStatusFilter:
        search?.contractStatusFilter || defaultSearch.contractStatusFilter,
      contractTypeFilter:
        search?.contractTypeFilter || defaultSearch.contractTypeFilter
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      getAgency
    } = this.props;
    getAgency(id);
    this.getAgencyMasterList();
  }

  getUserSelectObject = () => {
    const { keyword, type } = this.state;
    if (isReallyEmpty(keyword)) {
      return { keyword: '', type: '' };
    } else {
      return { keyword, type };
    }
  };
  /**
   * 取得經紀公司下直播主列表
   */
  getAgencyMasterList = (page = 1) => {
    const {
      match: {
        params: { id }
      },
      getAgencyMasterList
    } = this.props;
    const { contractStatusFilter, contractTypeFilter } = this.state;
    this.setState({ page, selectedRowKeys: [] });
    getAgencyMasterList({
      agencyId: id,
      contractStatusFilter,
      contractTypeFilter,
      page,
      ...this.getUserSelectObject()
    });
  };

  /**
   * 下載表單
   */
  handleDownloadClick = () => {
    const {
      totalCount,
      match: {
        params: { id }
      }
    } = this.props;
    const { contractStatusFilter, contractTypeFilter } = this.state;
    downloadAgencyMasterList({
      agencyId: id,
      contractStatusFilter,
      contractTypeFilter,
      totalCount,
      page: 1,
      ...this.getUserSelectObject()
    });
  };

  /**
   * 直播主搜尋元件
   * */
  handleSearchUserChange = value => {
    this.setState(value);
  };
  /**
   * 處理 Table 勾選
   */
  onSelectRowChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      isUnsigned: selectedRows?.[0]?.contractStatus === 'unsigned',
      selectedRowKeys
    });
  };
  /**
   * 點選分頁
   */
  handlePageChange = page => {
    this.getAgencyMasterList(page);
  };
  /**
   * 新增
   */
  onAddClick = () => {
    this.setState({ isCreateMode: true, mode: MODE.CREATE });
    this.props.showModal();
  };

  /**
   * 編輯
   */
  onEditClick = () => {
    const { getAgencyMaster, showModal } = this.props;
    const { selectedRowKeys } = this.state;
    this.setState({
      mode: MODE.EDIT
    });
    getAgencyMaster(selectedRowKeys[0]);
    showModal();
  };

  /**
   * 更換合約
   */
  onReplaceClick = () => {
    const { getAgencyMaster, showModal } = this.props;
    const { selectedRowKeys } = this.state;
    this.setState({
      mode: MODE.REPLACE
    });
    getAgencyMaster(selectedRowKeys[0]);
    showModal();
  };

  /**
   * 刪除
   */
  onDelectClick = () => {
    const { getAgencyMaster } = this.props;
    const { selectedRowKeys } = this.state;
    getAgencyMaster(selectedRowKeys[0]);
    this.setState({
      isShowTerminate: true
    });
  };

  /**
   * 送出終止合約表單
   */
  onDelectSubmit = values => {
    const {
      deleteAgencyMaster,
      match: {
        params: { id }
      }
    } = this.props;
    const { contractTypeFilter, contractStatusFilter } = this.state;
    deleteAgencyMaster(
      values,
      getAgencyMasterList({
        agencyId: id,
        contractStatusFilter,
        contractTypeFilter,
        page: 1,
        ...this.getUserSelectObject()
      }),
      this.onDeleteFormCancel
    );
    this.setState({ selectedRowKeys: [], page: 1 });
  };

  /**
   * 送出表單
   */
  onFormSubmit = values => {
    const {
      match: {
        params: { id }
      },
      addAgencyMaster,
      setAgencyMaster,
      replaceAgencyMasterContract
    } = this.props;

    const { page, contractStatusFilter, contractTypeFilter, mode } = this.state;
    // prettier-ignore
    switch (mode) {
      case MODE.CREATE:
        addAgencyMaster(
          getSubmitData(values),
          getAgencyMasterList({
            agencyId: id,
            ...defaultSearch,
            ...this.getUserSelectObject(),
          })
        );
        this.setState({
          ...defaultSearch
        });
        break;
      case MODE.EDIT:
        setAgencyMaster(
          getSubmitData(values),
          getAgencyMasterList({
            agencyId: id,
            contractStatusFilter,
            contractTypeFilter,
            page,
            ...this.getUserSelectObject(),
          })
        );
        this.setState({ selectedRowKeys: [] });
        break;
      case MODE.REPLACE:
        replaceAgencyMasterContract(
          getSubmitData(values),
          getAgencyMasterList({
            agencyId: id,
            contractStatusFilter,
            contractTypeFilter,
            page,
            ...this.getUserSelectObject(),
          })
        );
        this.setState({ selectedRowKeys: [] });
        break;
      default:
        break;
    }
  };

  /**
   * 關閉表單
   */
  onFormCancel = () => {
    this.props.showModal(false);
    this.setState({ mode: undefined });
  };

  /**
   * 關閉終止合約表單
   */
  onDeleteFormCancel = () => {
    this.setState({ isShowTerminate: false });
  };

  render() {
    const {
      data,
      loading,
      totalCount,
      isShowModal,
      currentAgency,
      currentAgencyMaster
    } = this.props;
    const {
      selectedRowKeys,
      keyword,
      type,
      contractStatusFilter,
      contractTypeFilter,
      mode,
      isShowTerminate,
      page,
      isUnsigned
    } = this.state;
    return (
      <>
        <h1>
          {'經營管理 > '}
          <Link to={`${AGENCY_MANAGEMENT}`} style={{ color: 'inherit' }}>
            <span style={{ textDecoration: 'underline' }}>經紀公司管理</span>
            {' > '}
          </Link>
          {currentAgency ? (
            currentAgency.agencyName
          ) : (
            <Spin indicator={<LoadingOutlined />} />
          )}
        </h1>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormItem
            style={{
              marginRight: '12px',
              marginBottom: '8px'
            }}
          >
            <UserSelector
              value={{ keyword, type }}
              onChange={this.handleSearchUserChange}
            />
          </FormItem>
          <FormItem style={{ marginBottom: '8px' }}>
            <Button
              onClick={() => this.getAgencyMasterList()}
              type="primary"
              style={{ float: 'left', marginRight: '12px' }}
            >
              搜尋
            </Button>
            <Button
              disabled={isEmpty(data)}
              onClick={this.handleDownloadClick}
              style={{ float: 'left' }}
            >
              匯出
            </Button>
          </FormItem>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormItem
            label="合約狀態"
            style={{
              display: 'flex',
              marginBottom: '8px',
              marginRight: '12px'
            }}
          >
            <Select
              value={contractStatusFilter}
              onChange={value =>
                this.setState({ contractStatusFilter: value }, () => {
                  this.getAgencyMasterList();
                })
              }
              style={{ minWidth: 128 }}
            >
              {contractStatus.map(value => (
                <Option key={value.id} title={value.label}>
                  {value.label}
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            label="合約類型"
            style={{
              display: 'flex',
              marginBottom: '8px'
            }}
          >
            <Select
              value={contractTypeFilter}
              onChange={value =>
                this.setState({ contractTypeFilter: value }, () => {
                  this.getAgencyMasterList(1);
                })
              }
              style={{ minWidth: 128 }}
            >
              {contractType.map(value => (
                <Option key={value.id} title={value.label}>
                  {value.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
        <Space style={{ marginBottom: '8px' }}>
          <Button onClick={this.onAddClick}>新增</Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onEditClick}
          >
            編輯
          </Button>
          <Button
            type="danger"
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onDelectClick}
          >
            終止合約
          </Button>
          <Button
            disabled={selectedRowKeys.length !== 1 || isUnsigned}
            onClick={this.onReplaceClick}
          >
            更換合約
          </Button>
        </Space>
        <TableContainer>
          <Table
            title={() => (
              <div>
                <InfoCircleOutlined style={{ marginRight: '8px' }} />
                合約換約：
                「試播轉正式合約」、「續約」、「變更合約方案」皆可在此更新合約內容
              </div>
            )}
            rowKey="agencyMasterContractId"
            scroll={{ x: 1420 }}
            loading={loading}
            rowSelection={{
              selectedRowKeys,
              getCheckboxProps: record => {
                return {
                  disabled: record?.contractStatus === contractStatus[3].id
                };
              },
              onChange: this.onSelectRowChange
            }}
            pagination={{
              pageSize: 20,
              total: totalCount,
              current: page,
              onChange: this.handlePageChange
            }}
            columns={columns}
            dataSource={data}
          />
        </TableContainer>
        <AgencyMasterFormModal
          agencyName={currentAgency ? currentAgency.agencyName : ''}
          agencyId={currentAgency ? currentAgency.agencyId : null}
          contractList={currentAgency ? currentAgency.contracts : []}
          mode={mode}
          visible={isShowModal}
          loading={loading}
          editData={mode === MODE.CREATE ? null : currentAgencyMaster}
          onOk={this.onFormSubmit}
          onCancel={this.onFormCancel}
        />
        <AgencyMasterTerminateModal
          onOk={this.onDelectSubmit}
          onCancel={this.onDeleteFormCancel}
          visible={isShowTerminate}
          loading={loading}
          editData={currentAgencyMaster}
        />
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(AgencyMasterManagement);
