import { isEmpty } from 'ramda';
import React from 'react';
import { Button, Checkbox, Form, Input, Modal, Popconfirm } from 'antd';
import YoutubeIDInput from './YoutubeIDInput';
import UploadImg from '../../components/UploadImg';

const FormItem = Form.Item;

const isOutSource = url =>
  url ? !/(\/backend\/video\/)\w+(?=\.mp4$)/i.test(url) : false;

class VideoFormModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.formRef = React.createRef();

    this.state = {
      isOutSourceVideo: false
    };
  }

  componentDidUpdate(prevProps) {
    const preUrl = prevProps?.editData?.url;
    const url = this.props?.editData?.url;
    if (preUrl !== url) {
      this.setState({ isOutSourceVideo: isOutSource(url) });
    }
  }

  getMediaData = () => {
    const { editData } = this.props;
    const { isOutSourceVideo } = this.state;
    if (!editData) return;

    const { url, length } = editData;
    let media = url;
    if (isOutSourceVideo) {
      media = { videoUrl: url, duration: length };
    }
    return media;
  };

  onCheckBoxClickHandler = ({ target: { checked } }) => {
    const form = this.formRef.current;
    form.setFieldValue('media', null);
    this.setState({ isOutSourceVideo: checked });
  };

  onCancelHandler = () => {
    this.setState({ isOutSourceVideo: false });
    this.props.onCancel();
  };

  onConfirm = () => {
    const { onOk } = this.props;
    const form = this.formRef.current;
    const iPromise = form.validateFields();
    iPromise.then(onOk);
  };

  renderFooter = () => {
    const { isCreateMode, loading } = this.state;
    return (
      <React.Fragment>
        <Popconfirm
          title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
          placement="topLeft"
          onConfirm={this.onConfirm}
        >
          <Button loading={loading} type="primary">
            <span>確定</span>
          </Button>
        </Popconfirm>
        <Button style={{ marginLeft: 8 }} onClick={this.onCancelHandler}>
          <span>取消</span>
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { editData, isCreateMode, visible } = this.props;
    const { isOutSourceVideo } = this.state;
    return (
      <Modal
        title={`${isCreateMode ? '新增' : '編輯'} 影音`}
        footer={this.renderFooter()}
        destroyOnClose={true}
        visible={visible}
        onCancel={this.onCancelHandler}
      >
        <Form ref={this.formRef} layout="vertical">
          <FormItem
            label="標題"
            name="title"
            initialValue={editData?.title}
            rules={[{ required: true, message: '請輸入標題' }]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="副標題"
            name="description"
            initialValue={editData?.description}
          >
            <Input />
          </FormItem>
          <FormItem
            label={
              <React.Fragment>
                <span>影片上傳</span>
                <Checkbox
                  style={{ marginLeft: '1rem' }}
                  checked={isOutSourceVideo}
                  onChange={this.onCheckBoxClickHandler}
                >
                  <span>使用 Youtube 影片</span>
                </Checkbox>
              </React.Fragment>
            }
            name="media"
            initialValue={this.getMediaData()}
            rules={[
              {
                required: true,
                message: `${
                  isOutSourceVideo ? '請輸入 Youtube 影片 ID' : '請上傳影片'
                }`
              },
              {
                validator: (rule, value) => {
                  if (!isEmpty(value?.videoUrl) && value?.duration === null) {
                    const error = new Error('請輸入正確 Youtube 影片 ID');
                    return Promise.reject(error);
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            {isOutSourceVideo ? (
              <YoutubeIDInput />
            ) : (
              <UploadImg
                type="button"
                accept="video/mp4"
                uploadUrl={{
                  image: '/cdn/backend/photo',
                  video: '/cdn/backend/video'
                }}
              >
                <span>上傳影片</span>
              </UploadImg>
            )}
          </FormItem>
          <FormItem
            label="預覽圖上傳"
            name="thumbnailUrl"
            initialValue={editData?.thumbnailUrl}
            rules={[{ required: true, message: '請上傳預覽圖' }]}
          >
            <UploadImg
              type="button"
              accept="image/*"
              uploadUrl="/cdn/backend/photo"
            >
              <span>上傳預覽圖</span>
            </UploadImg>
          </FormItem>
          {!isCreateMode && (
            <FormItem name="id" initialValue={editData?.id} hidden noStyle>
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}

export default VideoFormModal;
