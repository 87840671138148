import _ from 'lodash';
import moment from 'moment';
import objHash from 'object-hash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DatePicker, Table } from 'antd';
import { getTransactionListAPI } from '../../apis';

const { Column } = Table;
const { RangePicker } = DatePicker;

const PurchaseRecordWrapper = styled.div`
  .purchase-record-ragnge {
    width: 24rem !important;
    margin-bottom: 1rem;
  }
  .ant-pagination {
    width: 100%;
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: center;
  }
`;

class PurchaseRecord extends React.Component {
  static propTypes = {
    userId: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      records: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
      },
      timeRange: {
        startTime: moment().startOf('day').subtract(7, 'DAY'),
        endTime: moment().endOf('day')
      }
    };
  }

  componentDidMount() {
    this.fetchPurchaseRecord();
  }

  fetchPurchaseRecord = () => {
    this.setState({ isLoading: true });
    const { userId } = this.props;
    const { timeRange, pagination } = this.state;
    const startTime = timeRange.startTime.unix();
    const endTime = timeRange.endTime.unix();
    const observable = getTransactionListAPI({
      id: userId,
      item: pagination.pageSize,
      page: pagination.current,
      startTime,
      endTime
    });
    observable.subscribe(res => {
      const { data, totalCount } = res;
      const newPagination = Object.assign({}, pagination, {
        total: totalCount,
      });
      const records = _.map(data, (r, index) => ({
        ...r,
        $id: index,
      }));
      this.setState({
        records,
        pagination: newPagination,
        isLoading: false,
      });
    });
  };

  generateRowKey = (record) => {
    return objHash(record);
  };

  onRangeChanged = value => {
    const timeRange = {
      startTime: value[0],
      endTime: value[1]
    };
    this.setState({ timeRange }, this.fetchPurchaseRecord);
  };

  onTableChange = iPagination => {
    const { pagination } = this.state;
    const newPagination = Object.assign({}, pagination, {
      current: iPagination.current
    });

    this.setState({ pagination: newPagination }, () => {
      this.fetchPurchaseRecord();
    });
  };

  renderCreateAt = record => {
    const { createAt } = record;
    return moment.unix(createAt).format('YYYY-MM-DD HH:mm:ss');
  };

  renderStatus = record => {
    const { status } = record;
    return status === 0 ? '未付款' : '已付款';
  };

  render() {
    const { isLoading, timeRange, records, pagination } = this.state;

    return (
      <PurchaseRecordWrapper>
        <RangePicker
          className="purchase-record-ragnge"
          defaultValue={[timeRange.startTime, timeRange.endTime]}
          onOk={this.onRangeChanged}
          allowClear={false}
          showTime
        />
        <Table
          rowKey={this.generateRowKey}
          dataSource={records}
          loading={isLoading}
          onChange={this.onTableChange}
          pagination={pagination}
        >
          <Column
            title="消費時間"
            key="createAt"
            render={this.renderCreateAt}
          />
          <Column title="訂單紀錄編號" key="orderId" dataIndex="orderId" />
          <Column
            title="金流通道"
            key="purchaseType"
            dataIndex="purchaseType"
          />
          <Column title="消費金額" key="amount" dataIndex="amount" />
          <Column title="儲值點數" key="points" dataIndex="points" />
          <Column title="訂單狀態" key="status" render={this.renderStatus} />
        </Table>
      </PurchaseRecordWrapper>
    );
  }
}

export default PurchaseRecord;
