import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory as createHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';
import rootEpic from '../reducers/rootEpic';

const history = createHistory();
const middleware = routerMiddleware(history);
const epicMiddleware = createEpicMiddleware();
const configureStore = () => {
  const store = createStore(
    reducers(history),
    applyMiddleware(middleware, epicMiddleware),
  );
  epicMiddleware.run(rootEpic);
  return store;
};

export default configureStore();
export {
  history,
};
