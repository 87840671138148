import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Popconfirm, Table } from 'antd';
import LiveMasterInfo from '../../components/LiveMasterInfo';
import {
  addBlocklist,
  deleteBlocklist,
  getBlocklistList,
  setBlocklist,
  showModal
} from '../../reducers/blocklistManagement';
import { findById } from '../../utils/mixin';
import antdTablePaignation from '../../utils/antdTablePagination';
import { PageContainer, PageTitle } from '../../components/styled/page';
import BlocklistFormModal from './BlocklistFormModal';

const { Column } = Table;

const Actions = styled.header`
  margin-bottom: 1rem;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

class BlocklistManagement extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isCreateMode: false,
      editData: undefined,
      selectedRowKeys: [],
      defaultPagination: new antdTablePaignation(),
    };
  }

  componentDidMount() {
    this.props.getBlocklistList();
  }

  /**
   * 處理 Table 勾選
   */
  onSelectRowChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  /**
   * 點擊新增
   */
  onAddClick = () => {
    this.setState({
      editData: null,
      isCreateMode: true,
    });
    this.props.showModal();
  };

  /**
   * 點擊編輯
   */
  onEditClick = () => {
    const { selectedRowKeys } = this.state;
    const { data } = this.props;
    this.setState({
      editData: findById(selectedRowKeys[0], data),
      isCreateMode: false
    });
    this.props.showModal();
  };

  /**
   * 點擊刪除
   */
  onDeleteClick = () => {
    const { selectedRowKeys } = this.state;
    this.props.deleteBlocklist(selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  /**
   * 送出表單
   */
  onFormSubmit = (values) => {
    const { addBlocklist, setBlocklist } = this.props;
    const { isCreateMode } = this.state;
    if (isCreateMode) {
      addBlocklist(values);
    } else {
      setBlocklist(values);
    }
    this.setState({ editData: null });
  };

  /**
   * 關閉表單
   */
  onFormCancel = () => {
    this.setState({ editData: null });
    this.props.showModal(false);
  };

  renderNickName = (rowData) => {
    const { uuid } = rowData;
    return <LiveMasterInfo value={uuid} />;
  }

  render() {
    const { data, loading, isShowModal, totalCount } = this.props;
    const { editData, selectedRowKeys, isCreateMode, defaultPagination } = this.state;
    const pagination = {
      ...defaultPagination,
      total: totalCount,
    };
    return (
      <PageContainer>
        <PageTitle>{'APP 管理 > APP 排行榜黑名單'}</PageTitle>
        <Actions>
          <Button
            type="primary"
            onClick={this.onAddClick}
          >
            <span>新增</span>
          </Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onEditClick}
          >
            <span>編輯</span>
          </Button>
          <Popconfirm
            disabled={selectedRowKeys.length === 0}
            key="cancel"
            title="是否確定刪除?"
            placement="right"
            onConfirm={this.onDeleteClick}
          >
            <Button
              type="danger"
              disabled={selectedRowKeys.length === 0}
            >
              <span>刪除</span>
            </Button>
          </Popconfirm>
        </Actions>
        <Table
          rowKey="id"
          loading={loading}
          rowSelection={{
            selectedRowKeys,
            onChange: this.onSelectRowChange
          }}
          pagination={pagination}
          dataSource={data}
        >
          <Column
            title="ID"
            key="createAt"
            dataIndex="id"
          />
          <Column
            title="暱稱"
            key="nickname"
            render={this.renderNickName}
          />
          <Column
            title="UUID"
            key="uuid"
            dataIndex="uuid"
          />
          <Column
            title="備註"
            key="remark"
            dataIndex="remark"
          />
        </Table>
        <BlocklistFormModal
          isCreateMode={isCreateMode}
          visible={isShowModal}
          editData={editData}
          onCancel={this.onFormCancel}
          onOk={this.onFormSubmit}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => state.blocklistManagement;

const mapDispatchToProps = {
  getBlocklistList,
  addBlocklist,
  setBlocklist,
  deleteBlocklist,
  showModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlocklistManagement);
