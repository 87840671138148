import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  getUserInfo,
  sendUserInfo,
  getUserUsePointHistory,
  getUserSavePointHistory,
  resetNickname,
  getUserBackpackList
} from '../../reducers/userManagement';
import { withRouter } from 'react-router-dom';
import { assoc, compose, curry, evolve, keys, pathOr, reduce } from 'ramda';
import styled from 'styled-components';
import {
  Alert,
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  ACCOUNT_CREATION_MANAGEMENT,
  USER_MANAGEMENT
} from '../../constants/routes';
import { showModal as showAddPointTransferModal } from '../../reducers/pointTransfer';
import { getOutsourcePointTransferList } from '../../reducers/outsourcePointTransfer';
import profileThumbnailPicture from '../../constants/profileThumbnailPicture';
import { userLevel as UserLevelEnum } from '../../constants/roles';
import AddPointModal from '../../components/AddPointModal';
import AddPointTransferModal from './AddPointTransferModal';
import NicknameHistoryModal from './NicknameHistoryModal';
import PointHistoryModal from '../../components/PointHistoryModal';
import PointTransferHistoryModal from './PointTransferHistoryModal';
import UserTagEditor from './UserTagEditor';
import UserBackpackListModal from './UserBackpackListModal';
import { PageContainer, PageTitle } from '../../components/styled/page';
import brandName from '../../constants/brandName';

const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

const FormItem = Form.Item;
const Option = Select.Option;

const FormLoading = styled(Spin)`
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const GoBackLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 14, offset: 5 }
  }
};

class Personal extends React.PureComponent {
  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.PointHistoryModalRef = React.createRef();
    this.formRef = React.createRef();

    this.state = {
      confirmDirty: false,
      editPointModalByRatioVisible: false,
      editPointModalVisible: false,
      pointTransferHistoryModalVisible: false,
      nicknameHistoryModalVisible: false,
      backpackModalVisible: false
    };
  }

  componentDidMount() {
    this.fetchUserInfo();
    this.fetchOutsourceTransferList();
  }

  fetchUserInfo = () => {
    const { match, getUserInfo } = this.props;
    const userID = match?.params?.id;
    if (userID) getUserInfo(userID);
  };

  fetchOutsourceTransferList = () => {
    const { match, getOutsourcePointTransferList } = this.props;
    const userID = match?.params?.id;
    getOutsourcePointTransferList({
      userFilter: userID,
      startTime: moment('2023-01-01 00:00:00').unix(),
      endTime: moment().unix(),
      item: 20,
      page: 1
    });
  };

  goBackToUserManagement = () => {
    const { history, location } = this.props;
    const from = location?.state?.from;
    if (from === ACCOUNT_CREATION_MANAGEMENT) {
      history.push({ pathname: `${USER_MANAGEMENT}` });
    } else {
      history.goBack();
    }
  };

  showNicknameHistory = () => {
    this.setState({ nicknameHistoryModalVisible: true });
  };

  hideNicknameHistory = () => {
    this.setState({ nicknameHistoryModalVisible: false });
  };

  onShowPointHistoryModal = () => {
    const { PointHistoryModalRef } = this;
    PointHistoryModalRef.current.show();
  };

  onShowEditUserPointModal = () => {
    this.setState({
      editPointModalVisible: !this.state.editPointModalVisible
    });
  };

  onCloseEditUserPointModal = () => {
    this.setState({
      editPointModalVisible: !this.state.editPointModalVisible
    });
  };

  onResetNicknameClick = () => {
    const {
      resetNickname,
      match: { params = {} }
    } = this.props;
    resetNickname(params.id);
  };

  onShowAddPointTransferModal = () => {
    this.props.showAddPointTransferModal(true);
  };

  onTogglePointTransferHistoryModal = () => {
    this.setState(state => ({
      pointTransferHistoryModalVisible: !state.pointTransferHistoryModalVisible
    }));
  };

  onBackpackListClick = () => {
    const {
      getUserBackpackList,
      match: { params = {} }
    } = this.props;
    this.setState({ backpackModalVisible: true });
    getUserBackpackList({ id: params.id, item: 20, page: 1 });
  };

  onBackpackListClose = () => {
    this.setState({ backpackModalVisible: false });
  };

  onSubmit = values => {
    const { sendUserInfo, match } = this.props;
    const result = compose(
      evolve({
        identityStatus: Number,
        sex: Number,
        publicSexInfo: Boolean
      }),
      assoc('id', match?.params?.id),
      renameKeys({ sexValue: 'sex' })
    )(values);
    sendUserInfo(result);
  };

  renderUserLevel = () => {
    const { formValue } = this.props;
    const level = pathOr({}, ['userLevel'], formValue);
    const { levelId, levelNum } = level;
    return `${UserLevelEnum[levelId] || ''} ${levelNum || ''}`;
  };

  renderForm = () => {
    const { formValue, loading, outsourceList } = this.props;
    return loading ? (
      <FormLoading spinning={true}></FormLoading>
    ) : (
      <Form ref={this.formRef} onFinish={this.onSubmit}>
        <Row>
          <Col sm={24} lg={3} style={{ textAlign: 'center' }}>
            <Avatar
              size={96}
              style={{ marginBottom: 8 }}
              src={formValue.profilePicture || profileThumbnailPicture}
            />
          </Col>
          <Col sm={24} lg={10}>
            <FormItem
              label="狀態"
              name="identityStatus"
              hasFeedback={true}
              initialValue={formValue.identityStatus}
              {...formItemLayout}
            >
              <Select>
                <Option value={1}>啟用</Option>
                <Option value={-2}>禁用</Option>
                <Option value={-1}>未開通</Option>
              </Select>
            </FormItem>
            <FormItem
              label="暱稱"
              name="nickname"
              initialValue={formValue.nickname}
              rules={[
                { required: true, message: 'Please input your nickname!' }
              ]}
              {...formItemLayout}
            >
              <Input.Search
                loading={loading}
                placeholder="暱稱"
                enterButton="重置暱稱修改時間"
                onSearch={this.onResetNicknameClick}
              />
            </FormItem>
            <FormItem label={<span />} colon={false} {...formItemLayout}>
              <Button
                style={{ width: '100%' }}
                onClick={this.showNicknameHistory}
              >
                <SearchOutlined />
                <span>查詢暱稱修改紀錄</span>
              </Button>
            </FormItem>
            <FormItem
              label="真實姓名"
              name="realName"
              initialValue={formValue.realName}
              hasFeedback={true}
              {...formItemLayout}
            >
              <Input placeholder="真實姓名" />
            </FormItem>
            <FormItem
              label="性別"
              name="sexValue"
              initialValue={formValue.sexValue}
              hasFeedback={true}
              {...formItemLayout}
            >
              <Select>
                <Option value={0}>女</Option>
                <Option value={1}>男</Option>
              </Select>
            </FormItem>
            <FormItem
              label="Login ID"
              name="loginId"
              initialValue={formValue.loginId}
              hasFeedback={true}
              {...formItemLayout}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              label={`${brandName} ID`}
              name="trueLoveId"
              initialValue={formValue.trueLoveId}
              hasFeedback={true}
              {...formItemLayout}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              label="綁定信箱"
              name="email"
              initialValue={formValue.email}
              hasFeedback={true}
              rules={[{ type: 'email', message: '請輸入正確的 email' }]}
              {...formItemLayout}
            >
              <Input />
            </FormItem>
            <FormItem
              label="綁定第三方"
              initialValue={formValue['3rdPartySource']}
              hasFeedback={true}
              {...formItemLayout}
            >
              <span>{formValue['3rdPartySource'] || '無'}</span>
            </FormItem>
            <FormItem
              label="個人簡介"
              name="selfDesc"
              initialValue={formValue.selfDesc}
              {...formItemLayout}
            >
              <Input.TextArea placeholder="個人簡介" />
            </FormItem>
          </Col>
          <Col sm={24} lg={10}>
            <FormItem label="使用者等級" hasFeedback={true} {...formItemLayout}>
              <span>{this.renderUserLevel()}</span>
            </FormItem>
            <FormItem
              label="點數"
              name="remainPoints"
              initialValue={formValue.remainPoints}
              hasFeedback={true}
              {...formItemLayout}
            >
              <Input disabled />
            </FormItem>
            {outsourceList.length > 0 ? (
              <FormItem {...tailFormItemLayout}>
                <Alert message="有收過幣商點數！" type="warning" showIcon />
              </FormItem>
            ) : null}
            <FormItem {...tailFormItemLayout}>
              <Button
                style={{ marginRight: 8, marginBottom: 8 }}
                onClick={this.onShowPointHistoryModal}
              >
                <SearchOutlined />
                <span>查詢點數使用歷程</span>
              </Button>
              <Button
                style={{ marginRight: 8, marginBottom: 8 }}
                onClick={this.onShowEditUserPointModal}
              >
                <span>修改點數</span>
              </Button>
              <Divider style={{ margin: '8px 0' }} />
              <Button
                style={{ marginRight: 8, marginBottom: 8 }}
                onClick={this.onTogglePointTransferHistoryModal}
              >
                <SearchOutlined />
                <span>查詢站內轉點歷程</span>
              </Button>
              <Button
                style={{ marginRight: 8, marginBottom: 8 }}
                onClick={this.onShowAddPointTransferModal}
              >
                <span>轉移點數</span>
              </Button>
              <Divider style={{ margin: '8px 0' }} />
              <Button
                style={{ marginRight: 8, marginBottom: 8 }}
                onClick={this.onBackpackListClick}
              >
                <SearchOutlined />
                <span>查詢背包狀態</span>
              </Button>
            </FormItem>
            {/* 目前 v1 不會顯示次欄位
            <FormItem {...formItemLayout} label="顯示性別" hasFeedback>
              {getFieldDecorator('publicSexInfo')(
                <Select>
                  <Option value="false">否</Option>
                  <Option value="true">是</Option>
                </Select>
              )}
            </FormItem>
            */}
            <FormItem
              label="國碼"
              name="phoneCountryCode"
              initialValue={formValue.phoneCountryCode}
              hasFeedback={true}
              {...formItemLayout}
            >
              <Input />
            </FormItem>
            <FormItem
              label="綁定手機"
              name="phoneNumber"
              initialValue={formValue.phoneNumber}
              hasFeedback={true}
              {...formItemLayout}
            >
              <Input />
            </FormItem>
            <FormItem
              label="粉絲人數"
              name="fansCount"
              initialValue={formValue.fansCount}
              hasFeedback={true}
              {...formItemLayout}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              label="標籤"
              name="tags"
              initialValue={formValue.tags}
              {...formItemLayout}
            >
              <UserTagEditor
                uuid={formValue.id}
                callback={this.fetchUserInfo}
              />
            </FormItem>
            <FormItem
              label="備註"
              name="note"
              initialValue={formValue.note}
              {...formItemLayout}
            >
              <Input.TextArea placeholder="請輸入備註" />
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                <span>儲存設定</span>
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    const {
      match: { params = {} }
    } = this.props;
    const {
      editPointModalVisible,
      pointTransferHistoryModalVisible,
      nicknameHistoryModalVisible,
      backpackModalVisible
    } = this.state;

    return (
      <PageContainer>
        <PageTitle>
          <span>{'帳號管理 > '}</span>
          <GoBackLink onClick={this.goBackToUserManagement}>
            <span>使用者帳號管理</span>
          </GoBackLink>
          <span>{' > 個人資料'}</span>
        </PageTitle>

        <PointHistoryModal ref={this.PointHistoryModalRef} userId={params.id} />

        {editPointModalVisible && (
          <AddPointModal
            userId={params.id}
            onCancel={this.onCloseEditUserPointModal}
          />
        )}

        <AddPointTransferModal uuid={params.id} />

        <PointTransferHistoryModal
          uuid={params.id}
          visible={pointTransferHistoryModalVisible}
          onCancel={this.onTogglePointTransferHistoryModal}
        />

        <NicknameHistoryModal
          visible={nicknameHistoryModalVisible}
          uuid={params.id}
          onCancel={this.hideNicknameHistory}
        />

        <UserBackpackListModal
          uuid={params.id}
          visible={backpackModalVisible}
          onCancel={this.onBackpackListClose}
        />

        {this.renderForm()}
      </PageContainer>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.userManagement.loading,
  formValue: state.userManagement.formValue,
  outsourceList: state.outsourcePointTransfer.data
});

const mapDispatchToProps = {
  getUserInfo,
  sendUserInfo,
  getUserUsePointHistory,
  getUserSavePointHistory,
  resetNickname,
  showAddPointTransferModal,
  getUserBackpackList,
  getOutsourcePointTransferList
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Personal);
