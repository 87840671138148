import {
  all,
  always,
  complement,
  compose,
  evolve,
  isNil,
  pickAll,
  values,
  when
} from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Modal, Radio, Spin } from 'antd';
import { useHasErrors } from '../../hooks/useHasErrors';
import UploadImg from '../../components/UploadImg';
import AnnouncementPreview from './AnnouncementPreview';
import { userType } from '../../constants/roles';
import { toOptionList, isReallyEmpty } from '../../utils/mixin';
import { getCoverage } from '../../reducers/systemAnnouncementPush';
import InputI18 from './InputI18';
const isNotReallyEmpty = complement(isReallyEmpty);
const FormItem = Form.Item;

const mapDataToForm = compose(
  evolve({
    lastLoginPeriod: when(isNil, always('anytime')),
    registerTimePeriod: when(isNil, always('anytime'))
  }),
  pickAll([
    'id',
    'i18nContents',
    'imgUrl',
    'linkUrl',
    'userType',
    'userLevel',
    'lastLoginPeriod',
    'registerTimePeriod'
  ])
);
const checkAllHasValue = compose(all(isNotReallyEmpty), values);
const userTypeOptions = toOptionList(userType);

// Validator
const titleValidator = (_, value) => {
  if (value && value.length > 10) {
    const err = new Error('標題長度超過 10 字');
    return Promise.reject(err);
  }
  return Promise.resolve();
};

const contentValidator = (_, value) => {
  if (value && value.length > 500) {
    const err = new Error('訊息內容長度超過 500 字');
    return Promise.reject(err);
  }
  return Promise.resolve();
};

const AnnouncementModal = ({
  loading = false,
  isCreateMode,
  editData,
  onOk,
  onCancel,
  visible
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { coverageCount, coverageLoading } = useSelector(
    state => state.systemAnnouncementPush
  );
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [showPreview, setShowPreview] = useState(false);
  const [actionText, setActionText] = useState('新增');
  const [formData, setFormData] = useState();

  useEffect(() => {
    if (visible && !isCreateMode && !isReallyEmpty(editData)) {
      form.setFieldsValue(mapDataToForm(editData));
    }
  }, [visible, form, editData, isCreateMode]);

  useEffect(() => {
    setActionText(isCreateMode ? '新增' : '更新');
  }, [isCreateMode]);

  const onFinish = values => {
    setFormData(values);
    setShowPreview(true);
  };

  const onPreviewOK = () => {
    onOk(formData);
    onPreviewCancel();
    onCancel();
  };

  const onPreviewCancel = () => {
    setShowPreview(false);
  };

  const onCoverageFieldsChange = () => {
    const coverageValues = form.getFieldsValue(['userType']);

    if (isCreateMode && checkAllHasValue(coverageValues)) {
      dispatch(getCoverage(coverageValues));
    }
  };

  const onSubmit = () => form.submit();

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
    setShowPreview(false);
    setFormData(null);
  };

  return (
    <>
      <Modal
        title={`${actionText} 公告${!isCreateMode ? '內容' : ''}`}
        width={600}
        visible={visible}
        closable={false}
        afterClose={onAfterClose}
        onOk={onSubmit}
        onCancel={onCancel}
        okButtonProps={{ disabled: hasErrors }}
      >
        <Spin spinning={loading}>
          {isCreateMode && (
            <div style={{ marginBottom: '1em' }}>
              發送人數：
              {coverageLoading ? (
                <Spin size="small" />
              ) : (
                !isNil(coverageCount) && (
                  <span style={{ color: '#f5222d' }}>
                    約 {coverageCount} 人
                  </span>
                )
              )}
            </div>
          )}
          <Form
            form={form}
            onFinish={onFinish}
            onFieldsChange={validateStatus}
            layout="vertical"
          >
            <FormItem
              name="userType"
              label="用戶類型"
              rules={[{ required: true, message: '請選擇用戶類型' }]}
            >
              <Radio.Group
                onChange={onCoverageFieldsChange}
                options={userTypeOptions}
                disabled={!isCreateMode}
              />
            </FormItem>
            <FormItem
              name={['i18nContents', 'titles']}
              label="公告標題"
              rules={[
                { required: true, message: '請輸入公告標題' },
                { validator: titleValidator }
              ]}
            >
              <InputI18 placeholder="請輸入內容，最多 10 字" />
            </FormItem>
            <FormItem
              name={['i18nContents', 'contents']}
              label="公告訊息內容"
              rules={[
                { required: true, message: '請輸入公告訊息內容' },
                { validator: contentValidator }
              ]}
            >
              <InputI18
                rows={3}
                placeholder="請輸入內容，最多 500 字"
                textArea
              />
            </FormItem>
            <FormItem
              name="imgUrl"
              label="公告圖片"
              rules={[
                { required: !isReallyEmpty(values.link), message: '請上傳圖片' }
              ]}
            >
              <UploadImg uploadUrl="/cdn/announcement/picture" />
            </FormItem>
            <FormItem
              name="linkUrl"
              label="圖片連結"
              rules={[{ required: false, message: '圖片連結' }]}
              getValueFromEvent={event => event.target.value.trim()}
            >
              <Input type="text" />
            </FormItem>
            {!isCreateMode && (
              <FormItem name="id" noStyle hidden>
                <Input type="hidden" />
              </FormItem>
            )}
          </Form>
        </Spin>
      </Modal>
      <AnnouncementPreview
        loading={loading}
        mode={isCreateMode ? 'new' : 'edit'}
        coverageCount={coverageCount}
        value={formData}
        visible={showPreview}
        onCancel={onPreviewCancel}
        onOk={onPreviewOK}
      />
    </>
  );
};

export default AnnouncementModal;
