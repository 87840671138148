import { pick } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Input } from 'antd';
import AgencySelector from '../../../../components/AgencySelector';
import UserSselector from '../../../../components/UserSselector';
import { ModeEnum } from '../constants';

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    width: 5rem;
  }
`;

class FamilyForm extends React.PureComponent {
  static propTypes = {
    mode: PropTypes.oneOf(Object.values(ModeEnum)),
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
    };
  }

  isEditMode = () => {
    const { mode } = this.props;
    return mode === ModeEnum.EDIT;
  };

  getFormData = () => {
    const form = this.formRef.current;
    return form.getFieldsValue();
  };

  getFieldValue = (field) => {
    const form = this.formRef.current;
    return form.getFieldValue(field);
  };

  setFormData = (data) => {
    const form = this.formRef.current;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = pick(fields, data);
    form.setFieldsValue(values);
  };

  validateFields = () => {
    const form = this.formRef.current;
    return form.validateFields();
  };

  reset = () => {
    const form = this.formRef.current;
    form.resetFields();
  };

  render() {
    const isEditMode = this.isEditMode();
    return (
      <Form
        ref={this.formRef}
        labelAlign="right"
      >
        {!isEditMode && (
          <FormItem
            label="經紀公司"
            name="agencyId"
            rules={[
              { required: true, message: '請選擇經紀公司' },
            ]}
          >
            <AgencySelector />
          </FormItem>
        )}
        <FormItem
          label="家族名稱"
          name="name"
          rules={[
            { required: true, message: '請輸入家族名稱' },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="家族長"
          name="oopsvipId"
          rules={[
            { required: true, message: '請選擇家族長' },
          ]}
        >
          <UserSselector
            roleFilter="ROLE_FAMILY_ADMIN"
            valueField="OOPSVIP_ID"
            defaultType="true_love_id"
          />
        </FormItem>
        {isEditMode && (
          <FormItem
            hidden={true}
            name="familyId"
          >
            <Input hidden={true} />
          </FormItem>
        )}
      </Form>
    );
  }
}

export default FamilyForm;
