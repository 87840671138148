import { createRequest } from './utils';
import { API_ROOT } from '../constants/endpoint';
/**
 * request for v1
 */
const request = createRequest(API_ROOT);

export const changePasswordAPI = body =>
  request('/personal/updatePassword', 'POST')(body);
export const loadDauReports = request('/backend/dau', 'POST');
export const loadMauReports = request('/backend/mau', 'POST');
export const loadLiveMasterList = request('/backend/stsPeople', 'POST');
export const getLiveMasterInfoAPI = id =>
  request(`/backend/liveMaster/${id}`, 'GET')();
export const sendLiveMasterAPI = (id, body) =>
  request(`/backend/liveMaster/${id}`, 'POST')(body);
export const searchLiveMasterListAPI = request(
  '/backend/liveMaster/search',
  'POST'
);
export const loadPopularGiftList = request(
  '/backend/popularGiftRanking',
  'POST'
);
export const getCurrencyListAPI = request('/backend/currencyList');
export const loadRegistrationStaffList = request(
  '/backend/registerReport',
  'POST'
);
export const loadPerformanceGrowthList = request(
  '/backend/performanceReport',
  'POST'
);
export const loadUserExportDataAPI = identityIds =>
  request('/backend/identity/export', 'POST')({ identityId: identityIds });
// export const getGiftListAPI = (status = 1, categoryId = null) =>
//   request('/mGift/list', 'POST')({ status, categoryId });
export const getImageHash = request('/image/create/gift', 'POST');
export const createGiftAPI = request('/gift', 'POST');
export const getMasterReturnGiftAPI = (status = 1, categoryId = null) =>
  request('/mGift/returnList', 'POST')({ status, categoryId });
export const modifyGiftAPI = (id, info) => request(`/gift/${id}`, 'PUT')(info);
export const getDailyStatisticsAPI = ({ id, type, body }) =>
  request(`/backend/stsReport/${id}/${type}`, 'POST')(body);
export const getStsReportAPI = (id, body) =>
  request(`/backend/stsReport/${id}/POINTS`, 'POST')(body);
export const getFansGiftRankAPI = (id, page, pageSize = 10) =>
  request(`/personal/${id}/giftListSum/${pageSize}/${page}`, 'GET')();
export const getLiveAppReviewListAPI = (pageSize, currentPage = 0) =>
  request(`/masterApplicationList/${pageSize}/${currentPage}`, 'GET')();
export const checkLiveAppReviewAPI = (id, info) =>
  request(`/masterApplication/${id}`, 'PUT')(info);
export const getManualSettlementListAPI = (page = 0, body, size = 10) =>
  request(`/settlementPreview/${size}/${page}`, 'POST')(body);
export const searchAgencyListAPI = request('/backend/agency/search', 'POST');
export const sendSettlementAPI = request('/settlement', 'POST');
export const getSettleByNoAPI = settleNo =>
  request(`/settlement/${settleNo}`)();
export const getReportedPostsAPI = request('/backend/inform', 'POST');
export const removeReportedPostAPI = id =>
  request(`/backend/photo/delete/${id}`, 'DELETE')();
export const restoreReportedPostAPI = id =>
  request(`/backend/inform/${id}`, 'DELETE')();
export const getUserListAPI = request('/backend/identity/search', 'POST');
export const getUserUsePointHistoryAPI = ({
  id,
  timeRange,
  pageSize = 10,
  currentPage = 0
}) =>
  request(
    `/personal/usageList/${id}/${pageSize}/${currentPage}${
      timeRange ? `?startAt=${timeRange[0]}&endAt=${timeRange[1]}` : ''
    }`,
    'GET'
  )();
export const getUserSavePointHistoryAPI = ({
  id,
  timeRange,
  pageSize = 10,
  currentPage = 0
}) =>
  request(
    `/personal/transactionList/${id}/${pageSize}/${currentPage}${
      timeRange ? `?startAt=${timeRange[0]}&endAt=${timeRange[1]}` : ''
    }`,
    'GET'
  )();

export const modifyAccountAPI = (id, info) =>
  request(`/identity/${id}/account`, 'PUT')(info);
// export const createAgencyAPI = request('/backend/agency/create', 'POST');
// export const editAgencyAPI = ({ id, ...body }) =>
//   request(`/backend/agency/edit/${id}`, 'PUT')(body);
// export const terminateContractAPI = ({ id, ...body }) =>
//   request(`/backend/agency/terminateContract/${id}`, 'PUT')(body);
// export const createContractAPI = request(
//   '/backend/agency/createContract',
//   'POST'
// );
export const getLiveViewerListAPI = payload =>
  request('/backend/stsPeopleReport', 'POST')(payload);
export const getSystemAnnouncementListAPI = ({ pageSize, currentPage }) =>
  request(`/backend/notification/list/${pageSize}/${currentPage}`, 'GET')();
export const pushNotificationAPI = payload =>
  request('/backend/notification/add/ANNONCEMENT', 'POST')(payload);
export const getPhotoListAPI = payload =>
  request('/backend/photoPost/list', 'POST')(payload);
export const removePhotoListAPI = id =>
  request(`/backend/photo/delete/${id}`, 'DELETE')();
export const getPurchaseToolReportAPI = payload =>
  request('/backend/purchaseToolReport', 'POST')(payload);
// export const getGiftListCategoryAPI = () =>
//   request('/mGift/categoryList', 'GET')();
// export const setGiftListCategoryAPI = payload =>
//   request('/mGift/categoryList', 'POST')(payload);
// export const getGiftReportAPI = (body) =>
//   request('/backend/liveRoomGift/report', 'POST')({ ...body });
// export const getAdBannerListAPI = (category, No) =>
//   request(`/adBanner/list/${category}/${No}`, 'GET')();
// export const removeAdBannerAPI = (id) =>
// request(`/adBanner/delete/${id}`, 'DELETE')();
// export const submitAdBannerAPI = request('/adBanner/upload', 'POST');

export const getWelcomeBannerListAPI = () =>
  request('/welcomeBanner/list', 'GET')();
export const deleteWelcomeBannerAPI = id =>
  request(`/welcomeBanner/delete/${id}`, 'DELETE')();
export const submitWelcomeBannerListAPI = request(
  '/welcomeBanner/upload',
  'POST'
);

export const uploadImage = url => request(url, 'POST');

export const getSystemMarqueeListAPI = () => request('/marquees', 'GET')();
export const setSystemMarqueeListAPI = payload =>
  request('/marquees', 'POST')(payload);

export const getBarragesAPI = () => request('/barrages', 'GET')();
export const setBarragesAPI = payload => request('/barrages', 'POST')(payload);

export const getEventsAPI = () => request('/mEvents', 'GET')();
export const addEventAPI = payload => request('/mEvent', 'POST')(payload);
export const deleteEventAPI = id => request(`/mEvent/${id}`, 'DELETE')();

export const getAvatarsAPI = () => request('/mAvatars', 'GET')();
export const addAvatarAPI = payload => request('/mAvatar', 'POST')(payload);
export const deleteAvatarAPI = id => request(`/mAvatar/${id}`, 'DELETE')();

export const getPointRatioAPI = () => request('/pointRatios', 'GET')();
export const setPointRatioAPI = payload =>
  request('/pointRatios', 'POST')(payload);

export const getReportAPI = payload =>
  request('/backend/liveMasterPointAmount/report', 'POST')(payload);

export const getUserConsumeAPI = ({ userIds, startDate, endDate }) =>
  request(
    `/backend/purchaseOrder/list?userId=${userIds}&startAt=${startDate}&endAt=${endDate}`,
    'GET'
  )();

export const getLiveMasterNetworkSpeedAPI = payload =>
  request('/backend/liveMaster/playEventReport', 'POST')(payload);

export const addGiftBoxAPI = payload => request('/mEvent', 'POST')(payload);
export const listGiftBoxesAPI = ({ eventType = 4 }) =>
  request(`/mEvents?type=${eventType}`, 'GET')();
export const getGiftBoxAPI = id =>
  request(`/npc/npcSettings?eventId=${id}`, 'GET')();
export const modifyGiftBoxAPI = payload =>
  request('/npc/npcSettings', 'POST')(payload);
export const removeGiftBoxAPI = id => request(`/mEvent/${id}`, 'DELETE')();
export const sendUserV1API = (id, body) =>
  request(`/backend/user/${id}`, 'POST')(body);
