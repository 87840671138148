export default [
  { name: 'Ranking 按鈕 Icon', key: 'rankingIconUrl', type: 'image' },
  { name: '個人訊息按鈕 Icon', key: 'messageIconUrl', type: 'image' },
  { name: '背景圖', key: 'backgroundUrl', type: 'image' },
  {
    name: 'Main Menu 區塊文字底線配色',
    key: 'menuUnderlineColor',
    type: 'color'
  },
  { name: '首頁按鈕 Icon', key: 'mainPageIconUrl', type: 'image' },
  {
    name: '首頁按鈕 Icon (啟用狀態)',
    key: 'mainPageIconUrlFocused',
    type: 'image'
  },
  { name: '活動頁面按鈕 Icon', key: 'eventPageIconUrl', type: 'image' },
  {
    name: '活動頁面按鈕 Icon (啟用狀態)',
    key: 'eventPageIconUrlFocused',
    type: 'image'
  },
  { name: '中間 Logo Icon', key: 'logoIconUrl', type: 'image' },
  { name: '追蹤頁面按鈕 Icon', key: 'trackPageIconUrl', type: 'image' },
  {
    name: '追蹤頁面按鈕 Icon (啟用狀態)',
    key: 'trackPageIconUrlFocused',
    type: 'image'
  },
  { name: '我的頁面按鈕 Icon', key: 'myPageIconUrl', type: 'image' },
  {
    name: '我的頁面按鈕 Icon (啟用狀態)',
    key: 'myPageIconUrlFocused',
    type: 'image'
  },
  { name: 'App 下方 Tab Bar 文字顏色', key: 'tabBarTextColor', type: 'color' },
  {
    name: 'App 下方 Tab Bar 文字顏色 (啟用狀態)',
    key: 'tabBarTextColorFocused',
    type: 'color'
  }
];