import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LiveRoomContext from '../../../../LiveRoomContext';
import themes from '../utils/themes';

class InoutMessage extends React.Component {

  static contextType = LiveRoomContext;

  static propTypes = {
    message: PropTypes.object,
  };

  static defaultProps = {
    message: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      content: '',
      userName: '',
      identity: 'default',
      theme: {},
    };
  }

  componentDidMount() {
    this.setData();
  }

  setData = () => {
    const { message } = this.props;
    const { detectIdentity } = this.context;
    const propContent = _.get(
      message,
      `data.content.${this.getLanguage().substr(0, 2)}`
    );
    const userName = _.get(message, 'data.fromUser.name');
    const uuid = _.get(message, 'data.fromUser.userId');
    const roles = _.get(message, 'data.fromUser.roles');
    //const result = new RegExp(`^${userName}:(.*)`).exec(propContent);
    const result = propContent.replace(/^\{fromUser\.name\}/, userName);
    const identity = detectIdentity(uuid, roles);
    const theme = themes.identity[identity];
    this.setState({
      content: result,
      userName,
      identity,
      theme,
    });
  };

  getLanguage = () => {
    const leng = navigator.language || navigator.userLanguage;
    return leng || 'zh-TW';
  };

  renderUserLevel = () => {
    const { message } = this.props;
    const { theme } = this.state;
    const levelId = _.get(message, 'data.fromUser.userLevel.levelId');
    const levelNum = _.get(message, 'data.fromUser.userLevel.levelNum');
    const levelTheme = themes.userLevel[levelId];
    const style = {
      color: levelTheme.number,
      backgroundColor: theme.foreground,
    };
    return (
      <div className="user-level" style={style}>
        <img src={levelTheme.icon} alt={levelId}/>
        <span>{levelNum}</span>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { content, userName, theme } = this.state;
    const wrapperStyle = { backgroundColor: theme.background };

    return (
      <div className={className} style={wrapperStyle}>
        {this.renderUserLevel()}
        <span className="username">{userName}</span>
        <span className="content">{content}~</span>
      </div>
    );
  }
}

const StyledInoutMessage = styled(InoutMessage)`
  padding: 0.25rem;
  line-height: 1;
  border-radius: 0.313rem;
  word-break: break-all;

  & > * {
    vertical-align: middle;
  }

  i {
    margin-right: 0.25rem;

    &.in {
      color: #00C853;
    }

    &.out {
      color: #D50000;
    }
  }

  .username {
    color: ${themes.username};
  }

  .content {
    color: ${themes.content};
  }

  .user-level {
    margin-right: 0.25rem;
    padding: 0.2rem 0.4rem 0.2rem 0.2rem;
    display: inline-block;
    align-items: center;
    border-radius: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;

    * {
      vertical-align: middle;
    }

    img {
      height: 1rem;
      margin-right: 0.1rem;
    }
  }
`;

export default StyledInoutMessage;
