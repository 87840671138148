import React from 'react';
import { message, Button, Form, Input } from 'antd';
import { resetEmailAPI } from '../../apis';

const FormItem = Form.Item;

class EmailForm extends React.Component {

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      isLoading: false,
    };
  }

  onFinish = (values) => {
    this.setState({ isLoading: true });

    const emails = values.emails.trim();
    const iPromise = resetEmailAPI({ emails }).toPromise();
    iPromise.then(() => {
      const form = this.formRef.current;
      form.resetFields();
      message.success('Email重置成功');
    });
    iPromise.catch((error) => {
      const { Message } = error.response;
      message.error(`Email重置失敗 - ${Message}`);
    });
    iPromise.finally(() => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <Form
        ref={this.formRef}
        layout="inline"
        onFinish={this.onFinish}
      >
        <FormItem
          key="emails"
          name="emails"
          rules={[
            { required: true, type: 'email', message: '請輸入正確的 Email' }
          ]}
        >
          <Input
            style={{ width: '18rem' }}
            type="text"
            placeholder="Email"
          />
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            <span>確定</span>
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default EmailForm;
