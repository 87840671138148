import { message } from 'antd';
import produce from 'immer';
import { groupBy, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, pluck, switchMap, map } from 'rxjs/operators';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { createRequestTypes } from '../actions/Types';
import {
  addPrivateVoiceChatSpotlightAPI,
  getPrivateVoiceChatSpotlightAPI,
  getPrivateVoiceChatSpotlightListAPI,
  setPrivateVoiceChatSpotlightAPI,
  deletePrivateVoiceChatSpotlightAPI
} from '../apis';

/**Type */
const GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST = createRequestTypes(
  'GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST'
);
const GET_PRIVATE_VOICECHAT_SPOTLIGHT = createRequestTypes(
  'GET_PRIVATE_VOICECHAT_SPOTLIGHT'
);
const ADD_PRIVATE_VOICECHAT_SPOTLIGHT = createRequestTypes(
  'ADD_PRIVATE_VOICECHAT_SPOTLIGHT'
);
const SET_PRIVATE_VOICECHAT_SPOTLIGHT = createRequestTypes(
  'SET_PRIVATE_VOICECHAT_SPOTLIGHT'
);
const DELETE_PRIVATE_VOICECHAT_SPOTLIGHT = createRequestTypes(
  'DELETE_PRIVATE_VOICECHAT_SPOTLIGHT'
);
const SHOW_PRIVATE_VOICECHAT_SPOTLIGHT_MODAL =
  'SHOW_PRIVATE_VOICECHAT_SPOTLIGHT_MODAL';

/**
 * Action Creator
 */
export const getPrivateVoiceChatSpotlightList = createAction(
  GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST.REQUEST
);
export const getPrivateVoiceChatSpotlightListSuccess = createAction(
  GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST.SUCCESS
);
export const getPrivateVoiceChatSpotlightListFailure = createAction(
  GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST.FAILURE
);
export const getPrivateVoiceChatSpotlight = createAction(
  GET_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST
);
export const getPrivateVoiceChatSpotlightSuccess = createAction(
  GET_PRIVATE_VOICECHAT_SPOTLIGHT.SUCCESS
);
export const getPrivateVoiceChatSpotlightFailure = createAction(
  GET_PRIVATE_VOICECHAT_SPOTLIGHT.FAILURE
);
export const addPrivateVoiceChatSpotlight = createAction(
  ADD_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST
);
export const addPrivateVoiceChatSpotlightSuccess = createAction(
  ADD_PRIVATE_VOICECHAT_SPOTLIGHT.SUCCESS
);
export const addPrivateVoiceChatSpotlightFailure = createAction(
  ADD_PRIVATE_VOICECHAT_SPOTLIGHT.FAILURE
);
export const setPrivateVoiceChatSpotlight = createAction(
  SET_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST
);
export const setPrivateVoiceChatSpotlightSuccess = createAction(
  SET_PRIVATE_VOICECHAT_SPOTLIGHT.SUCCESS
);
export const setPrivateVoiceChatSpotlightFailure = createAction(
  SET_PRIVATE_VOICECHAT_SPOTLIGHT.FAILURE
);
export const deletePrivateVoiceChatSpotlight = createAction(
  DELETE_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST,
  (ids = []) => ids
);
export const deletePrivateVoiceChatSpotlightSuccess = createAction(
  DELETE_PRIVATE_VOICECHAT_SPOTLIGHT.SUCCESS
);
export const deletePrivateVoiceChatSpotlightFailure = createAction(
  DELETE_PRIVATE_VOICECHAT_SPOTLIGHT.FAILURE
);
export const showModal = createAction(
  SHOW_PRIVATE_VOICECHAT_SPOTLIGHT_MODAL,
  (isShowModal = true) => isShowModal
);
/**
 * Epics
 */
export const getPrivateVoiceChatSpotlightListEpic = action$ =>
  action$.pipe(
    ofType(GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getPrivateVoiceChatSpotlightListAPI().pipe(
        map(getPrivateVoiceChatSpotlightListSuccess),
        catchRequestError(getPrivateVoiceChatSpotlightListFailure)
      )
    )
  );

export const addPrivateVoiceChatSpotlightEpic = action$ =>
  action$.pipe(
    ofType(ADD_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      addPrivateVoiceChatSpotlightAPI(payload).pipe(
        mergeMap(res => [
          addPrivateVoiceChatSpotlightSuccess(res),
          getPrivateVoiceChatSpotlightList()
        ]),
        catchRequestError(addPrivateVoiceChatSpotlightFailure)
      )
    )
  );

export const getPrivateVoiceChatSpotlightEpic = action$ =>
  action$.pipe(
    ofType(GET_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getPrivateVoiceChatSpotlightAPI(payload).pipe(
        map(getPrivateVoiceChatSpotlightSuccess),
        catchRequestError(getPrivateVoiceChatSpotlightFailure)
      )
    )
  );

export const setPrivateVoiceChatSpotlightEpic = action$ =>
  action$.pipe(
    ofType(SET_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      setPrivateVoiceChatSpotlightAPI(payload).pipe(
        mergeMap(res => [
          setPrivateVoiceChatSpotlightSuccess(res),
          getPrivateVoiceChatSpotlightList()
        ]),
        catchRequestError(setPrivateVoiceChatSpotlightFailure)
      )
    )
  );

export const deletePrivateVoiceChatSpotlightsEpic = action$ =>
  action$.pipe(
    ofType(DELETE_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST),
    pluck('payload'),
    switchMap(ids => {
      return forkJoin(
        ids.map(id =>
          deletePrivateVoiceChatSpotlightAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      );
    }),
    mergeMap(res => {
      const errors = groupBy(v => v.Status)(res);
      if (errors.Error?.length > 0) {
        message.error(
          `刪除失敗: ${errors.Error.map(
            e => `id:${e.id} message: ${e.Message}`
          ).join(', ')}`
        );
        return [
          deletePrivateVoiceChatSpotlightFailure(),
          getPrivateVoiceChatSpotlightList()
        ];
      } else {
        return [
          deletePrivateVoiceChatSpotlightSuccess(),
          getPrivateVoiceChatSpotlightList()
        ];
      }
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false,
  isShowModal: false,
  currentSpotlight: undefined,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST.REQUEST,
      ADD_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST,
      SET_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST,
      DELETE_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(
      ADD_PRIVATE_VOICECHAT_SPOTLIGHT.SUCCESS,
      SET_PRIVATE_VOICECHAT_SPOTLIGHT.SUCCESS
    )]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST.SUCCESS]: produce(
      (draft, { payload: { data } }) => {
        draft.loading = false;
        draft.data = data;
        draft.totalCount = data.length;
      }
    ),
    [GET_PRIVATE_VOICECHAT_SPOTLIGHT_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_PRIVATE_VOICECHAT_SPOTLIGHT.FAILURE,
      DELETE_PRIVATE_VOICECHAT_SPOTLIGHT.SUCCESS,
      DELETE_PRIVATE_VOICECHAT_SPOTLIGHT.FAILURE
    )]: produce(draft => {
      draft.loading = false;
    }),
    [GET_PRIVATE_VOICECHAT_SPOTLIGHT.REQUEST]: produce(draft => {
      draft.loading = true;
      draft.currentSpotlight = undefined;
    }),
    [GET_PRIVATE_VOICECHAT_SPOTLIGHT.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentSpotlight = payload.data;
    }),
    [combineActions(
      GET_PRIVATE_VOICECHAT_SPOTLIGHT.FAILURE,
      SET_PRIVATE_VOICECHAT_SPOTLIGHT.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.currentSpotlight = undefined;
      draft.isShowModal = false;
    }),
    [SHOW_PRIVATE_VOICECHAT_SPOTLIGHT_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
