import React, { Component } from 'react';
import { Radio, Button, Select } from 'antd';
const RadioGroup = Radio.Group;
const Option = Select.Option;
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

class RegularSettlement extends Component {
  render() {
    return (
      <div>
        <h1>內容管理 > 定期結算</h1>
        <RadioGroup>
          <Radio style={radioStyle} value={1}>
            每週
            <Select style={{ width: 200 }}>
              <Option value="1">不知道有什麼選項1</Option>
              <Option value="2">不知道有什麼選項2</Option>
            </Select>
          </Radio>
          <Radio style={radioStyle} value={2}>
            每月
            <Select style={{ width: 200 }}>
              <Option value="1">不知道有什麼選項1</Option>
              <Option value="2">不知道有什麼選項2</Option>
            </Select>
          </Radio>
        </RadioGroup> 
        <br />
        <Button type="primary">儲存設定</Button>
      </div>
    );
  }
}

export default RegularSettlement;