export const encodeSearch = (obj) => {
  try {
    const jStr = JSON.stringify(obj);
    return btoa(encodeURIComponent(jStr));
  } catch {
    return null;
  }
};

export const decodeSearch = (param) => {
  try {
    const jStr = atob(param);
    return JSON.parse(decodeURIComponent(jStr));
  } catch {
    return null;
  }
};
