import { Table } from 'antd';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { status } from '../../constants/contract';
import { getContractList as getContractListAction } from '../../reducers/contractManagement';
const columns = [
  { title: '分潤方案名稱', dataIndex: 'name' },
  {
    title: '分潤方案描述',
    dataIndex: 'description'
  },
  {
    title: '合約有效期間',
    dataIndex: 'availableDays',
    render: value => `${value} 天`
  }
];
const ContractPicker = memo(
  forwardRef(({ value, onChange }, ref) => {
    const data = useSelector(state => state.contractManagement.data);
    const totalCount = useSelector(
      state => state.contractManagement.totalCount
    );
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const getContractList = useCallback(
      payload => dispatch(getContractListAction(payload)),
      [dispatch]
    );
    const rowSelection = useMemo(
      () => ({
        selectedRowKeys: value || [],
        onChange
      }),
      [value, onChange]
    );
    const pagination = useMemo(
      () => ({
        showSizeChanger: false,
        pageSize: 20,
        total: totalCount,
        onChange: page => setPage(page)
      }),
      [totalCount]
    );

    useEffect(() => {
      getContractList({
        keyword: '',
        contractStatusFilter: status[1].id,
        contractTypeFilter: 'all',
        page: page
      });
    }, [getContractList, page]);
    return (
      <Table
        ref={ref}
        rowKey="contractId"
        scroll={{ x: true }}
        rowSelection={rowSelection}
        dataSource={data}
        columns={columns}
        pagination={pagination}
      />
    );
  })
);
export default ContractPicker;
