import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
  Form,
  Space,
  Alert,
  message
} from 'antd';
import { QuestionCircleOutlined, FileOutlined } from '@ant-design/icons';
import moment from 'moment';
import { evolve, uniq, when, where } from 'ramda';
import React, { useEffect, useState } from 'react';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import { findById, isNotNil, isReallyEmpty } from '../../utils/mixin';
import { useHasErrors } from '../../hooks/useHasErrors';
import { useDispatch, useSelector } from 'react-redux';
import CsvIdImport from '../../components/CsvIdImport';
import { getUserInfo } from '../../reducers/userManagement';
const { Item: FormItem } = Form;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const mapDataToForm = when(
  isNotNil,
  when(
    where({ scheduleTime: isNotNil }),
    evolve({ scheduleTime: e => moment.unix(e) })
  )
);
const renderTags = tagList => {
  return (
    <Row>
      {tagList.map(tag => (
        <Col span={6} key={tag.id}>
          <Checkbox value={tag.id}>{tag.label}</Checkbox>
        </Col>
      ))}
    </Row>
  );
};

const DispatchGiftFormModal = ({
  onOk,
  onCancel,
  isCreateMode,
  loading,
  visible,
  tagList,
  editData,
  effectList,
  giftList
}) => {
  const dispatch = useDispatch();
  const { remainPoints, uuid } = useSelector(state => ({
    uuid: state.auth.id,
    remainPoints: state.userManagement.formValue.remainPoints
  }));
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [isSend, setIsSend] = useState(false);
  const [availableTimeType, setAvailableTimeType] = useState('TIME_OFFSET');
  const [availableDays, setAvailableDays] = useState(null);
  const [activated, setActivated] = useState(true);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [isSFXType] = useState(false);
  const [giftPoint, setGiftPoint] = useState(0);
  const [costPoint, setCostPoint] = useState(0);
  const [selectedUserNum, setSelectedUserNum] = useState(0);
  const [giftQuantity, setGiftQuantity] = useState(0);
  const [isNotAllowed, setIsNotAllowed] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const { getFieldValue, setFieldsValue, validateFields } = form;

  useEffect(() => {
    if (visible && !isReallyEmpty(editData) && !isCreateMode) {
      form.setFieldsValue(mapDataToForm(editData));
      setIsSend(isNotNil(form.getFieldValue('sendTime')));
      setAvailableTimeType(form.getFieldValue('availableTimeType'));
      setAvailableDays(form.getFieldValue('availableDays'));
      setActivated(form.getFieldValue('activated'));
      setIsUnlimited(form.getFieldValue('availableTimeType') === 'UNLIMITED');
    }
  }, [form, editData, isCreateMode, visible]);

  useEffect(() => {
    if (visible) {
      dispatch(getUserInfo(uuid));
    }
  }, [visible, dispatch, uuid]);

  useEffect(() => {
    setCostPoint(giftPoint * selectedUserNum * giftQuantity);
  }, [visible, giftPoint, giftQuantity, selectedUserNum]);

  useEffect(() => {
    if (visible) {
      if (giftPoint === 0) {
        // 0 點禮物可以直接發送
        setIsNotAllowed(false);
      } else if (remainPoints <= 0 || remainPoints < giftPoint * giftQuantity) {
        // 持有點數不能小於等於0，也不能小於要發送的禮物點數*發送數量
        setIsNotAllowed(true);
      } else if (costPoint > remainPoints) {
        // 發送點數總和不能大於持有點數
        setIsNotAllowed(true);
      } else {
        setIsNotAllowed(false);
      }
    }
  }, [remainPoints, visible, costPoint, giftPoint, giftQuantity]);
  /*
   * 驗證天數
   */
  const validateAvailableDays = ({ getFieldValue }) => {
    return {
      validator: (_, value) => {
        if (
          value === 'TIME_OFFSET' &&
          isReallyEmpty(getFieldValue('availableDays'))
        ) {
          return Promise.reject(new Error('請輸入時效天數'));
        }
        return Promise.resolve();
      }
    };
  };

  /**
   * 驗證 users 是否為空
   */
  const usersValidator = ({ getFieldValue, validateFields }) => {
    return {
      validator: (_, value) => {
        const tagIds = getFieldValue('tagIds');
        const users = value;
        if (isReallyEmpty(tagIds) && isReallyEmpty(users)) {
          return Promise.reject(new Error('請輸入帳號 或 勾選群組'));
        }
        return Promise.resolve();
      }
    };
  };

  /**
   * 驗證 tagIds 是否為空
   */
  const tagIdsValidator = ({ getFieldValue, validateFields }) => {
    return {
      validator: (_, value) => {
        const tagIds = value;
        const users = getFieldValue('users');
        if (isReallyEmpty(tagIds) && isReallyEmpty(users)) {
          return Promise.reject(new Error('請輸入帳號 或 勾選群組'));
        }
        return Promise.resolve();
      }
    };
  };

  const onFinish = () => {
    onOk(form);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
    setIsSend(false);
    setAvailableTimeType('TIME_OFFSET');
    setAvailableDays(null);
    setActivated(true);
    setIsUnlimited(false);
    setGiftPoint(0);
    setGiftQuantity(0);
    setCostPoint(0);
    setSelectedUserNum(0);
    setIsNotAllowed(false);
  };

  const footer = (
    <Space>
      <Popconfirm
        key="confirm"
        title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
        placement="topLeft"
        disabled={hasErrors}
        onConfirm={() => {
          if (isNotAllowed) {
            modal.warning({
              content: <p>帳戶點數餘額不足，請先儲值再發送背包禮物</p>
            });
          } else {
            form.submit();
          }
        }}
      >
        <Button
          key={'add'}
          loading={loading}
          type="primary"
          disabled={hasErrors}
        >
          確定
        </Button>
      </Popconfirm>
      <Button key="cancle" onClick={onCancel}>
        取消
      </Button>
    </Space>
  );
  return (
    <Modal
      width={660}
      title={`${
        isSend ? '已發送 - 檢視' : isCreateMode ? '新增' : '編輯'
      } 背包禮物發送設定`}
      onCancel={onCancel}
      visible={visible}
      afterClose={onAfterClose}
      footer={isSend ? null : footer}
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onFieldsChange={validateStatus}>
          {isCreateMode && (
            <FormItem>
              <Alert
                message={
                  <>
                    <p>目前點數：{remainPoints?.toLocaleString()}</p>
                    <p>發送總和點數：{costPoint?.toLocaleString()}</p>
                    <div>
                      註：若勾選特定群組，發送點數不會納入總和點數計算中
                    </div>
                  </>
                }
                type="warning"
              />
            </FormItem>
          )}
          <FormItem
            label="禮物"
            name="acmpId"
            rules={[
              {
                required: true,
                message: '請選擇禮物'
              }
            ]}
          >
            <Select
              showSearch
              onChange={(__, props) => {
                const acmpItemIds = isReallyEmpty(props.giftid)
                  ? null
                  : [props.giftid];
                setFieldsValue({
                  acmpItemIds
                });
                const nowGiftPoint =
                  findById(props.giftV2Id, giftList)?.point || 0;
                setGiftPoint(nowGiftPoint);
              }}
              filterOption={(input, { name }) =>
                name && name.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {effectList.map(item => {
                return (
                  <Option
                    key={item.id}
                    value={item.id}
                    name={item.name}
                    type={item.type}
                    giftid={item.giftId}
                    giftV2Id={item.giftV2Id}
                  >
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            labelCol={{ span: 24 }}
            label={
              <Space>
                <div className="ant-col ant-legacy-form-item-label">
                  <label title="帳號">帳號</label>
                </div>
                <CsvIdImport
                  onChange={data => {
                    const nowUsers = getFieldValue('users') || [];
                    const result = uniq([...nowUsers, ...data]);
                    message.success(`匯入 ${data.length} 筆帳號`);
                    setFieldsValue({
                      users: result
                    });
                    setSelectedUserNum(result?.length);
                    validateFields(['users']);
                  }}
                >
                  <Button
                    size="small"
                    title="請匯入CSV"
                    icon={<FileOutlined />}
                  >
                    檔案匯入
                  </Button>
                </CsvIdImport>
              </Space>
            }
            name="users"
            rules={[usersValidator]}
          >
            <LiveMasterSelect
              mode="multiple"
              style={{ width: '100%' }}
              roleFilter={null}
              typeFilter="uuid"
              onChange={value => {
                if (
                  !isReallyEmpty(value) &&
                  isReallyEmpty(getFieldValue('tagIds'))
                ) {
                  validateFields(['tagIds']);
                }
                setSelectedUserNum(value?.length);
              }}
            />
          </FormItem>
          <FormItem label="特定群組" name="tagIds" rules={[tagIdsValidator]}>
            <CheckboxGroup
              onChange={value => {
                if (
                  !isReallyEmpty(value) &&
                  isReallyEmpty(getFieldValue('users'))
                ) {
                  validateFields(['users']);
                }
              }}
            >
              {renderTags(tagList)}
            </CheckboxGroup>
          </FormItem>
          <FormItem
            label="效期"
            name="availableTimeType"
            initialValue="TIME_OFFSET"
            rules={[
              {
                required: true,
                message: '請選擇效期'
              },
              validateAvailableDays
            ]}
          >
            <Radio.Group
              onChange={event => {
                if (event.target.value === 'UNLIMITED') {
                  setFieldsValue({
                    availableDays: 99999
                  });
                } else {
                  setFieldsValue({ availableDays: null });
                }
                if (!isSFXType) {
                  setFieldsValue({ activated: false });
                }
                setIsUnlimited(
                  getFieldValue('availableTimeType') === 'UNLIMITED'
                );
              }}
            >
              <Radio disabled={true} value="UNLIMITED">
                永久
              </Radio>
              <Radio value="TIME_OFFSET">
                時效
                <InputNumber
                  disabled={isUnlimited}
                  onChange={value => {
                    setAvailableDays(value);
                    setFieldsValue({ availableDays: value });
                    validateFields(['availableTimeType']);
                  }}
                  value={
                    availableTimeType === 'TIME_OFFSET' ? availableDays : null
                  }
                  size="small"
                  min={1}
                  max={99999}
                  style={{ width: '80px', margin: '0 8px' }}
                />
                天數
              </Radio>
              <div style={{ marginTop: '8px' }}>
                <Checkbox
                  disabled={true}
                  checked={activated}
                  onChange={e => {
                    setActivated(e.target.checked);
                    setFieldsValue({ activated: e.target.checked });
                  }}
                >
                  <Tooltip
                    placement="bottomLeft"
                    title={
                      isSFXType
                        ? '若勾選，用戶不需至背包啟用，且收到後時效就會開始計算'
                        : '若勾選，用戶收到特效後，時效就開始計算'
                    }
                  >
                    發送後立即啟用 <QuestionCircleOutlined />
                  </Tooltip>
                </Checkbox>
              </div>
            </Radio.Group>
          </FormItem>
          <FormItem
            label="發送數量"
            name="quantity"
            rules={[
              {
                required: true,
                message: '請輸入數量'
              }
            ]}
          >
            <InputNumber
              min={1}
              onChange={value => {
                setGiftQuantity(value);
              }}
            />
          </FormItem>
          <FormItem label="排程" name="scheduleTime">
            <DatePicker
              allowClear={true}
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              onChange={value =>
                setFieldsValue({ scheduleStatus: isNotNil(value) })
              }
              disabledDate={current =>
                current < moment().subtract(1, 'day').endOf('day')
              }
            />
          </FormItem>
          {!isCreateMode && [
            <FormItem key="id" hidden={true} name="id">
              <Input key="id" type="hidden" />
            </FormItem>
          ]}
          {[
            <FormItem
              key="suitStatus"
              hidden={true}
              name="suitStatus"
              initialValue={false}
            >
              <Input key="suitStatus" type="hidden" />
            </FormItem>,
            <FormItem key="acmpItemIds" hidden={true} name="acmpItemIds">
              <Input key="acmpItemIds" type="hidden" />
            </FormItem>,
            <FormItem key="availableDays" hidden={true} name="availableDays">
              <Input key="availableDays" type="hidden" />
            </FormItem>,
            <FormItem
              key="activated"
              hidden={true}
              name="activated"
              initialValue={activated}
            >
              <Input key="activated" type="hidden" />
            </FormItem>,
            <FormItem
              key="scheduleStatus"
              hidden={true}
              name="scheduleStatus"
              initialValue={false}
            >
              <Input key="scheduleStatus" type="hidden" />
            </FormItem>
          ]}
          {contextHolder}
        </Form>
      </Spin>
    </Modal>
  );
};

export default DispatchGiftFormModal;
