import objectHash from 'object-hash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Table } from 'antd';
import { getStsReportAPI } from '../../../apis';
import PeriodPicker from '../../../components/PeriodPicker';
import { PageContainer, PageTitle } from '../../../components/styled/page';

const { Column } = Table;

const Tools = styled.div`
  margin-bottom: 1rem;
`;

const GoBackLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

class DismantlingStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataset: [],
      filters: {
        startDate: 0,
        endDate: 0,
      },
    };
  }

  fetchDismantlingStatistics = () => {
    const { match } = this.props;
    const { filters } = this.state;
    const { id } = match.params;
    const payload = {
      startDate: filters.startDate,
      endDate: filters.endDate,
      offsetInMillis: 28800000,
    };
    const observable = getStsReportAPI(id, payload);
    observable.subscribe((res) => {
      const { stslist } = res;
      this.setState({ dataset: stslist });
    });
  }

  onPeriodChange = (range) => {
    const { filters } = this.state;
    const newFilters = {
      ...filters,
      startDate: range[0].valueOf(),
      endDate: range[1].valueOf(),
    };
    this.setState({
      filters: newFilters,
    }, this.fetchDismantlingStatistics);
  }

  render() {
    const { history } = this.props;
    const { dataset } = this.state;
    return (
      <PageContainer>
        <PageTitle>
          <span>{'帳號管理 > '}</span>
          <GoBackLink onClick={history.goBack}>
            <span>{'直播主帳號管理'}</span>
          </GoBackLink>
          <span>{' > 指定期間拆帳統計'}</span>
        </PageTitle>
        <Tools>
          <PeriodPicker
            onChange={this.onPeriodChange}
          />
        </Tools>
        <Table
          rowKey={(record) => objectHash(record)}
          dataSource={dataset}
        >
          <Column
            title="日期"
            dataIndex="dateStr"
          />
          <Column
            title="拆帳點數"
            dataIndex="points"
          />
        </Table>
      </PageContainer>
    );
  }
}

export default withRouter(DismantlingStatistics);
