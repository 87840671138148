import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipe } from 'ramda';
import moment from 'moment';
import { Button, DatePicker } from 'antd';
import { Form } from '@ant-design/compatible';
import { getUserConsumeSheet } from '../reducers/accountSheetExport';
import LiveMasterSelect from '../components/LiveMasterSelect';
import hasErrors from '../utils/hasErrors';
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

class UserConsumeSheetExport extends Component {
  generateFormItem() {
    return [
      {
        key: 'userIds',
        options: {
          rules: [{ required: true, message: '請選擇匯出帳號' }]
        },
        render: () => (
          <LiveMasterSelect style={{ width: '100%' }} roleFilter={null} />
        )
      },
      {
        key: 'currentRange',
        options: {
          rules: [{ required: true, message: '請輸入匯出期間' }]
        },
        render: () => (
          <RangePicker
            disabledDate={current =>
              current > moment().endOf('day').subtract(1, 'day')
            }
          />
        )
      }
    ];
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.getUserConsumeSheet({
          userIds: values.userIds,
          startDate: values.currentRange[0].startOf('day').format('YYYY-MM-DD'),
          endDate: values.currentRange[1].endOf('day').format('YYYY-MM-DD')
        });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { loading } = this.props;

    return (
      <div style={{ paddingRight: '16px' }}>
        <h1>{'帳務管理 > 粉絲期間消費報表'}</h1>
        <Form onSubmit={this.handleSubmit}>
          {this.generateFormItem().map(item => (
            <FormItem key={item.key} label={item.label}>
              {getFieldDecorator(item.key, item.options)(item.render())}
            </FormItem>
          ))}
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={hasErrors(getFieldsError())}
            >
              匯出帳號資料
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.accountSheetExport.loading
});

const mapDispatchToProps = {
  getUserConsumeSheet
};

export default pipe(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(UserConsumeSheetExport);
