import { message, Spin } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { pathOr } from 'ramda';
import React, { forwardRef, useEffect, useState } from 'react';
import { of } from 'rxjs';
import { catchError, switchMap, filter } from 'rxjs/operators';
import { getUserInfoAPI } from '../apis';
import { isReallyEmpty } from '../utils/mixin';
const spinIcon = <LegacyIcon type="loading" style={{ fontSize: 12 }} spin />;

const renderData = (render, data) => {
  if (isReallyEmpty(data)) {
    return null;
  }
  if (typeof render === 'function') {
    return render(data);
  } else {
    return <div>{data.nickname}</div>;
  }
};
/**
 *
 * @param {string} value - uuid
 */
const LiveMasterInfo = forwardRef(({ value, render, onChange }, ref) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  useEffect(() => {
    const subscription = of(value)
      .pipe(
        filter(value => !isReallyEmpty(value)),
        switchMap(id => {
          setLoading(true);
          return getUserInfoAPI(id).pipe(
            catchError(error => {
              const msg = pathOr(error.message, ['response', 'Message'], error);
              message.error(`LiveMaster: ${msg}`);
              return of({ Status: 'Error', Message: msg });
            })
          );
        })
      )
      .subscribe(
        data => {
          setLoading(false);
          setData(data);
        },
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    return () => {
      subscription.unsubscribe();
    };
  }, [value]);
  useEffect(() => {
    if (isReallyEmpty(value)) {
      setData(undefined);
    }
  }, [value]);
  return loading ? (
    <div style={{ paddingLeft: 4 }} ref={ref}>
      <Spin size="small" delay={300} indicator={spinIcon} />
    </div>
  ) : (
    renderData(render, data)
  );
});
export default LiveMasterInfo;
