import React from 'react';
import PropTypes from 'prop-types';
import flvjs from 'flv.js';
import styled from 'styled-components';

const flvEvents = flvjs.Events;

const FlvVoice = styled.video`
  display: none;
`;

class VoiceChatPlayer extends React.Component {

  static propTypes = {
    source: PropTypes.string,
    mute: PropTypes.bool,
    autoplay: PropTypes.bool,
  }

  static defaultProps = {
    source: '',
    mute: false,
    autoplay: false,
  }

  constructor(props) {
    super(props);

    this.videoRef = React.createRef();

    this.flvPlayer = null;

    this.state = {
      isPlaying: false,
    };
  }

  componentDidMount() {
    requestAnimationFrame(this.init);
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  componentDidUpdate(prevProps) {
    const { source } = this.props;
    const { source: prevSource } = prevProps;
    if (prevSource !== source) {
      this.init();
    }
  }

  init = () => {
    // detyory player before
    this.destroyPlayer();

    const { source } = this.props;
    if (!source) return;

    // create new flv player
    const videoEl = this.videoRef.current;
    const flvPlayer = flvjs.createPlayer({
      url: source,
      type: 'flv',
      isLive: true,
    });
    flvPlayer.attachMediaElement(videoEl);

    // binding player event
    flvPlayer.on(flvEvents.ERROR, (error) => {
      console.log(error);
    });

    this.flvPlayer = flvPlayer;

    this.load();

    const { autoplay } = this.props;
    if (autoplay) this.play();
  }

  destroyPlayer = () => {
    const { flvPlayer } = this;
    if (!flvPlayer) return;
    flvPlayer.unload();
    flvPlayer.detachMediaElement();
    flvPlayer.destroy();
    this.flvPlayer = null;
    this.setState({ isPlaying: false });
  }

  load = () => {
    const { flvPlayer } = this;
    if (!flvPlayer) return;
    flvPlayer.load();
  }

  unload = () => {
    const { flvPlayer } = this;
    if (!flvPlayer) return;
    flvPlayer.unload();
  }

  play = () => {
    const { flvPlayer } = this;
    const { isPlaying } = this.state;

    if (!flvPlayer || isPlaying) return;

    flvPlayer.play().then(() => {
      this.setState({ isPlaying: true });
    });
  }

  pause = () => {
    const { flvPlayer } = this;
    const { isPlaying } = this.state;

    if (!flvPlayer || isPlaying) return;

    flvPlayer.pause();
    this.setState({ isPlaying: false });
  }

  render() {
    return (
      <FlvVoice ref={this.videoRef} />
    );
  }
}

export default VoiceChatPlayer;
