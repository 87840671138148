import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose, pick, assoc, evolve } from 'ramda';
import { Form, Input, Modal } from 'antd';
import { editUserPoint } from '../reducers/userManagement';

const FormItem = Form.Item;

class AddPointModal extends PureComponent {
  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.state = {};
  }

  onSubmit = () => {
    const form = this.formRef.current;
    const validatePromise = form.validateFields();
    validatePromise.then((values) => {
      const { editUserPoint, userId, onCancel } = this.props;
      const result = compose(
        evolve({ addPoints: parseInt }),
        assoc('targetUserId', userId),
        pick(['addPoints', 'reason'])
      )(values);
      editUserPoint(result);
      onCancel();
    });
  };

  render() {
    const { formValue, onCancel } = this.props;
    return (
      <Modal visible={true} onCancel={onCancel} onOk={this.onSubmit}>
        <Form ref={this.formRef} layout="vertical">
          <FormItem
            label="現有點數"
            name="point"
            hasFeedback={true}
            initialValue={formValue.remainPoints}
          >
            <Input placeholder="現有點數" disabled />
          </FormItem>
          <FormItem
            label="新增/扣除點數"
            name="addPoints"
            hasFeedback={true}
            rules={[
              { required: true, message: '請輸入點數' },
            ]}
          >
            <Input placeholder="新增/扣除點數" type="number" />
          </FormItem>
          <FormItem
            label="修改原因"
            name="reason"
            hasFeedback={true}
            rules={[
              { required: true, message: '請輸入原因' },
            ]}
          >
            <Input />
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  formValue: state.userManagement.formValue
});

const mapDispatchToProps = {
  editUserPoint,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPointModal);
