import { handleActions, createAction } from 'redux-actions';
import { from, empty, Subject } from 'rxjs';
import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { getPayloadByType } from '../utils/extendOperators';
import {
  getWelcomeBannerListAPI,
  deleteWelcomeBannerAPI,
  submitWelcomeBannerListAPI
} from '../apis';
import { notification } from 'antd';
import { prop, sortWith, descend } from 'ramda';

const removeAllBanners = api => ids => {
  const subject = new Subject();
  from(ids)
    .pipe(
      mergeMap(id =>
        api(id).pipe(
          catchError(err => {
            notification.error({
              message: `Delete Fail. id: ${id}`,
              description: err.message
            });
            return empty();
          })
        )
      )
    )
    .subscribe(
      null,
      err => subject.error(err),
      () => {
        subject.next(true);
        subject.complete();
      }
    );

  return subject;
};
/**
 * Type
 */
const SHOW_ADD_OVERLAY_AD_MANAGEMENT_MODAL =
  'SHOW_ADD_OVERLAY_AD_MANAGEMENT_MODAL';
const CLOSE_ADD_OVERLAY_AD_MANAGEMENT_MODAL =
  'CLOSE_ADD_OVERLAY_AD_MANAGEMENT_MODAL';

/**
 * Action Creator
 */
export const selectAdBannerKeys = createAction('selectAdBannerKeys');
export const showModal = createAction(SHOW_ADD_OVERLAY_AD_MANAGEMENT_MODAL);
export const closeModal = createAction(CLOSE_ADD_OVERLAY_AD_MANAGEMENT_MODAL);
export const getWelcomeBannerList = createAction('GET_WELCOME_BANNER_LIST');
const getWelcomeBannerListSuccess = createAction(
  'GET_WELCOME_BANNER_LIST_SUCCESS'
);
const getWelcomeBannerListFailure = createAction(
  'GET_WELCOME_BANNER_LIST_FAILURE'
);

export const submitWelcomeBannerList = createAction(
  'SUBMIT_WELCOME_BANNER_LIST'
);
const submitWelcomeBannerListSuccess = createAction(
  'SUBMIT_WELCOME_BANNER_LIST_SUCCESS'
);
const submitWelcomeBannerListFailure = createAction(
  'SUBMIT_WELCOME_BANNER_LIST_FAILURE'
);

export const deleteWelcomeBannerList = createAction(
  'DELETE_WELCOME_BANNER_LIST'
);
const deleteWelcomeBannerListSuccess = createAction(
  'DELETE_WELCOME_BANNER_LIST_SUCCESS'
);

/**
 * Epics
 */

export const getWelcomeBannerListEpic = action$ =>
  action$.pipe(
    getPayloadByType(getWelcomeBannerList),
    switchMap(() =>
      getWelcomeBannerListAPI().pipe(
        mergeMap(res => [getWelcomeBannerListSuccess(res)]),
        catchError(err => [getWelcomeBannerListFailure(err)])
      )
    )
  );

export const deleteWelcomeBannerEpic = action$ =>
  action$.pipe(
    getPayloadByType(deleteWelcomeBannerList),
    mergeMap(removeAllBanners(deleteWelcomeBannerAPI)),
    mergeMap(() => [deleteWelcomeBannerListSuccess(), getWelcomeBannerList()])
  );

export const submitWelcomeBannerListEpic = action$ =>
  action$.pipe(
    getPayloadByType(submitWelcomeBannerList),
    mergeMap(payload =>
      submitWelcomeBannerListAPI(payload).pipe(
        mergeMap(res => [
          submitWelcomeBannerListSuccess(res),
          getWelcomeBannerList(),
          closeModal()
        ]),
        catchError(err => [submitWelcomeBannerListFailure(err)])
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  loading: false,
  isModalOpen: false,
  data: [],
  totalCount: 0
};

export default handleActions(
  {
    [getWelcomeBannerList]: state => ({
      ...state,
      loading: true
    }),
    [getWelcomeBannerListSuccess]: (
      state,
      { payload: { data, totalCount } }
    ) => ({
      ...state,
      loading: false,
      data: sortWith([descend(prop('startAt'))], data),
      totalCount
    }),
    [SHOW_ADD_OVERLAY_AD_MANAGEMENT_MODAL]: state => ({
      ...state,
      isModalOpen: true
    }),
    [CLOSE_ADD_OVERLAY_AD_MANAGEMENT_MODAL]: state => ({
      ...state,
      isModalOpen: false
    })
  },
  initialState
);
