import { pipe } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'antd';
import {
  getEventCategory,
  addEventCategory,
  deleteEventCategory,
  showModal,
} from '../../reducers/eventCategory';
import { getGiftListCategory } from '../../reducers/giftListCategories';
import { giftType } from '../../constants/gifts';
import { PageContainer, PageTitle } from '../../components/styled/page';
import EventCategoriesTable from './EventCategoriesTable';
import EventCategoriesForm from './EventCategoriesForm';

const Actions = styled.div`
  margin-bottom: 1rem;
`;

class EventCategoriesManagement extends React.Component {
  componentDidMount() {
    this.props.getEventCategory();
    this.props.getGiftListCategory({
      typeFilter: giftType.liveRoom.value,
      statusFilter: 'all',
      item: 999,
      page: 1
    });
  }

  render() {
    const {
      loading,
      isShowModal,
      showModal,
      events,
      giftListCategories,
      addEventCategory,
      deleteEventCategory
    } = this.props;

    return (
      <PageContainer>
        <PageTitle>{'活動管理 > 活動類別管理'}</PageTitle>
        <Actions>
          <Button
            type="primary"
            disabled={loading}
            onClick={() => showModal(true)}
          >
            <span>新增活動</span>
          </Button>
        </Actions>
        <EventCategoriesTable
          events={events}
          giftListCategories={giftListCategories}
          deleteEventCategory={deleteEventCategory}
        />
        <EventCategoriesForm
          loading={loading}
          isShowModal={isShowModal}
          events={events}
          giftListCategories={giftListCategories}
          showModal={showModal}
          addEventCategory={addEventCategory}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.eventCategory.loading,
  isShowModal: state.eventCategory.isShowModal,
  events: state.eventCategory.events,
  giftListCategories: state.giftListCategories.data
});

const mapDispatchToProps = {
  getEventCategory,
  addEventCategory,
  deleteEventCategory,
  showModal,
  getGiftListCategory
};

export default pipe(connect(mapStateToProps, mapDispatchToProps))(
  EventCategoriesManagement
);
