import React from 'react';
import styled from 'styled-components';
import {
  Button, Divider, Form,
  message,
} from 'antd';
import {
  clearVoiceChatCacheAPI,
  kickoutVoiceChatUserAPI,
} from '../../apis';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import VoiceChatRoomSelector from '../../components/VoiceChatRoomSelector';
import { PageContainer, PageTitle } from '../../components/styled/page';

const IDivider = styled(Divider)`
  &:not(:first-of-type) {
    margin-top: 2rem !important;
  }
`;

class VoiceChatUserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.KickoutFormRef = React.createRef();
    this.ClearCacheFormRef = React.createRef();

    this.state = {
    };
  }

  kickout = (roomId, uuid) => {
    const obserable = kickoutVoiceChatUserAPI(roomId, uuid);
    const iPromise = obserable.toPromise();
    iPromise.then(() => {
      // show success message
      message.success('踢出成功');

      // reset form
      const form = this.KickoutFormRef.current;
      form.resetFields();
    });
    iPromise.catch(() => {
      message.error('踢出失敗');
    });
  };

  clearCache = (roomId) => {
    const obserable = clearVoiceChatCacheAPI(roomId);
    const iPromise = obserable.toPromise();
    iPromise.then(() => {
      // show success message
      message.success('清除快取成功');

      // reset form
      const form = this.ClearCacheFormRef.current;
      form.resetFields();
    });
    iPromise.catch(() => {
      message.error('清除快取失敗');
    });
  };

  onKickoutFormFinish = (values) => {
    const { roomId, userId } = values;
    if (!roomId || !userId) return;
    this.kickout(roomId, userId);
  };

  onClearCacheFormFinish = (values) => {
    const { roomId } = values;
    if (!roomId) return;
    this.clearCache(roomId);
  }

  render() {
    return (
      <PageContainer>
        <PageTitle>{'聲聊管理 > 使用者管理'}</PageTitle>
        <IDivider
          orientation="left"
          orientationMargin="1rem"
        >
          <span>踢出使用者</span>
        </IDivider>
        <Form
          ref={this.KickoutFormRef}
          onFinish={this.onKickoutFormFinish}
        >
          <Form.Item
            label="聲聊聊天室"
            name="roomId"
            rules={[
              { required: true, message: '請選擇聊天室' },
            ]}
          >
            <VoiceChatRoomSelector
              style={{ width: '12rem' }}
            />
          </Form.Item>
          <Form.Item
            label="使用者"
            name="userId"
            rules={[
              { required: true, message: '請選擇用戶' },
            ]}
          >
            <LiveMasterSelect
              style={{ width: '22rem' }}
              typeFilter="nick_name"
              roleFilter={null}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="danger"
              htmlType="submit"
            >
              <span>踢出用戶</span>
            </Button>
          </Form.Item>
        </Form>
        <IDivider
          orientation="left"
          orientationMargin="1rem"
        >
          <span>清除房間快取</span>
        </IDivider>
        <Form
          ref={this.ClearCacheFormRef}
          onFinish={this.onClearCacheFormFinish}
        >
          <Form.Item
            label="聲聊聊天室"
            name="roomId"
            rules={[
              { required: true, message: '請選擇聊天室' },
            ]}
          >
            <VoiceChatRoomSelector
              style={{ width: '12rem' }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              <span>清除快取</span>
            </Button>
          </Form.Item>
        </Form>
      </PageContainer>
    );
  }
}

export default VoiceChatUserManagement;
