import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin
} from 'antd';
import moment from 'moment';
import { evolve, indexBy, prop, range, when } from 'ramda';
import React, { useEffect, useState } from 'react';
import FileUpload from '../../components/FileUpload';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import { type } from '../../constants/contract';
import { useHasErrors } from '../../hooks/useHasErrors';
import { findById, isNotNil, isReallyEmpty } from '../../utils/mixin';
const MODE = { CREATE: 'CREATE', EDIT: 'EDIT', REPLACE: 'REPLACE' };
const contractTypeByID = indexBy(prop('id'), type);
const { Item: FormItem } = Form;
const { TextArea } = Input;
const Option = Select.Option;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};
const disableBeforeCurrent = current =>
  current < moment().subtract(1, 'day').endOf('day');
const disableBeyondContractEndDate = (current, contractEndDate) =>
  current < moment().endOf('day') || current > moment.unix(contractEndDate);

const renderContractEndDate = (
  mode,
  contract,
  contractStartDate,
  contractEndDate
) => {
  const availableDays = contract.availableDays;
  if (isNotNil(contractEndDate) && mode === MODE.EDIT) {
    return contractEndDate.format('YYYY-MM-DD HH:mm:ss');
  } else if (isNotNil(contractStartDate) && isNotNil(availableDays)) {
    return contractStartDate
      .clone()
      .add(availableDays, 'days')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
  } else {
    return null;
  }
};
const mapDataToForm = when(
  isNotNil,
  evolve({
    contractStartDate: e => moment.unix(e),
    contractEndDate: e => moment.unix(e)
  })
);
const ControlsStatus = {
  CREATE: {
    title: '新增直播主',
    disableUuid: false,
    disableFileUrl: false,
    disableContractId: false,
    disableContractStartDate: false,
    isRequiredRemark: false,
    disabledDate: disableBeforeCurrent
  },
  EDIT: {
    title: '編輯直播主',
    disableUuid: true,
    disableFileUrl: false,
    disableContractId: true,
    disableContractStartDate: true,
    isRequiredRemark: false,
    disabledDate: disableBeforeCurrent
  },
  REPLACE: {
    title: '變更合約',
    disableUuid: true,
    disableFileUrl: false,
    disableContractId: false,
    disableContractStartDate: false,
    isRequiredRemark: true,
    disabledDate: disableBeyondContractEndDate
  }
};

const AgencyMasterFormModal = ({
  onOk,
  onCancel,
  visible,
  loading,
  isCreateMode,
  agencyId,
  agencyName,
  mode,
  contractList,
  editData
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [currentContract, setCurrentContract] = useState(null);
  const [oldContract, setOldContract] = useState(null);
  const {
    title,
    disableUuid,
    disableFileUrl,
    disableContractId,
    disableContractStartDate,
    disabledDate,
    isRequiredRemark
  } = ControlsStatus[mode] || ControlsStatus.CREATE;
  useEffect(() => {
    if (visible && !isReallyEmpty(editData)) {
      if (mode === MODE.EDIT) {
        form.setFieldsValue(mapDataToForm(editData));
        setCurrentContract(findById(editData.contractId, contractList));
      } else if (mode === MODE.REPLACE) {
        form.setFieldsValue({
          agencyMasterContractId: editData?.agencyMasterContractId
        });
        setOldContract(findById(editData.contractId, contractList));
      }
    }
  }, [form, editData, mode, visible, contractList]);

  const onFinish = values => {
    onOk(values);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
    setCurrentContract(null);
  };

  return (
    <Modal
      title={`${title} - ${
        mode === MODE.REPLACE ? editData?.nickname : agencyName
      }`}
      onCancel={onCancel}
      afterClose={onAfterClose}
      visible={visible}
      footer={
        <Space>
          <Popconfirm
            key="confirm"
            title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
            placement="topLeft"
            disabled={hasErrors}
            onConfirm={() => form.submit()}
          >
            <Button
              key={'add'}
              loading={loading}
              type="primary"
              disabled={hasErrors}
            >
              確定
            </Button>
          </Popconfirm>
          <Button key="cancle" onClick={onCancel}>
            取消
          </Button>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={onFinish}
          onFieldsChange={validateStatus}
          {...formItemLayout}
        >
          {mode === MODE.REPLACE ? (
            <Alert
              style={{ marginBottom: '18px' }}
              message={
                <>
                  <p>目前合約</p>
                  <p>合約名稱：{oldContract?.name}</p>
                  <p>合約簡述：{oldContract?.description}</p>
                  <p>
                    合約結束日期：
                    {editData &&
                      moment
                        .unix(editData?.contractEndDate)
                        .format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                </>
              }
              type="warning"
            />
          ) : (
            <FormItem
              label="用戶"
              name="uuid"
              rules={[
                {
                  required: true,
                  message: '請選擇直播主'
                }
              ]}
            >
              <LiveMasterSelect disabled={disableUuid} roleFilter={null} />
            </FormItem>
          )}
          <FormItem label="合約檔案" name="fileUrl">
            <FileUpload
              disabled={disableFileUrl}
              uploadUrl="/cdn/agencymaster/contract"
            />
          </FormItem>
          <FormItem
            label="合約名稱"
            name="contractId"
            rules={[
              {
                required: true,
                message: '請輸入名稱'
              }
            ]}
          >
            <Select
              disabled={disableContractId}
              onChange={value => {
                setCurrentContract(findById(value, contractList));
                form.resetFields(['contractStartDate']);
              }}
            >
              {contractList.map(value => (
                <Option key={value.id} value={value.id} title={value.name}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="合約簡述">
            {currentContract ? (
              <div>{currentContract.description}</div>
            ) : (
              '請選擇合約'
            )}
          </FormItem>
          <FormItem label="合約類型">
            {currentContract ? (
              <div>{contractTypeByID[currentContract.type].label}</div>
            ) : (
              '請選擇合約'
            )}
          </FormItem>
          <FormItem label="合約期間">
            {currentContract ? (
              <>
                <FormItem
                  label="合約起始日"
                  name="contractStartDate"
                  rules={[
                    {
                      required: true,
                      message: '請選擇起始日期'
                    }
                  ]}
                  style={{ display: 'flex', marginBottom: '8px' }}
                >
                  <DatePicker
                    allowClear={false}
                    showNow={false}
                    disabled={disableContractStartDate}
                    format="YYYY-MM-DD HH:mm:ss"
                    disabledTime={() => ({
                      disabledHours: () => range(1, 24),
                      disabledMinutes: () => range(1, 60),
                      disabledSeconds: () => range(1, 60)
                    })}
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    onChange={value => {
                      //強制為 00:00
                      value.startOf('day');
                    }}
                    disabledDate={current =>
                      disabledDate(current, editData?.contractEndDate)
                    }
                  />
                </FormItem>
                <FormItem
                  label="合約結束日"
                  style={{ display: 'flex', marginBottom: 0 }}
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.contractStartDate !== curValues.contractStartDate
                  }
                >
                  {({ getFieldValue }) => {
                    const contractStartDate =
                      getFieldValue('contractStartDate');
                    const contractEndDate = getFieldValue('contractEndDate');
                    if (contractStartDate) {
                      return renderContractEndDate(
                        mode,
                        currentContract,
                        contractStartDate,
                        contractEndDate
                      );
                    } else {
                      return null;
                    }
                  }}
                </FormItem>
              </>
            ) : (
              '請選擇合約'
            )}
          </FormItem>
          <FormItem
            label="備註"
            name="remark"
            rules={[
              {
                required: isRequiredRemark,
                message: '請輸入備註'
              }
            ]}
          >
            <TextArea placeholder="請輸入備註" rows={2} />
          </FormItem>
          {mode !== MODE.CREATE && (
            <FormItem hidden={true} name="agencyMasterContractId">
              <Input key="agencyMasterContractId" type="hidden" />
            </FormItem>
          )}
          {isNotNil(agencyId) && (
            <FormItem
              key="id"
              hidden={true}
              name="agencyId"
              initialValue={agencyId}
            >
              <Input key="agencyId" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default AgencyMasterFormModal;
