import { message } from 'antd';
import produce from 'immer';
import moment from 'moment';
import {
  compose,
  converge,
  curry,
  evolve,
  find,
  identity,
  isEmpty,
  map as Rmap,
  mergeRight,
  pick,
  pickAll,
  prop,
  propEq
} from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  addRemittanceAPI,
  allowRemittanceAPI,
  getProductList,
  getRemittanceAPI,
  getRemittanceListAPI,
  invoiceAPI,
  setRemittanceAPI
} from '../apis';
import { PaymentTypes } from '../constants/RemittancePaymentTypes';
import downloadCSV from '../utils/downloadCSV';
import { catchRequestError } from '../utils/extendOperators';
import { isReallyEmpty } from '../utils/mixin';
const mergeProductInfo = (remittances, productList) =>
  Rmap(
    converge(mergeRight, [
      identity,
      compose(
        pickAll(['points', 'price']),
        curry(id => find(propEq('id', id), productList) || {}),
        prop('productNo')
      )
    ]),
    remittances
  );
/**Type */
const GET_REMITTANCE_LIST = createRequestTypes('GET_REMITTANCE_LIST');
const GET_REMITTANCE = createRequestTypes('GET_REMITTANCE');
const ADD_REMITTANCE = createRequestTypes('ADD_REMITTANCE');
const SET_REMITTANCE = createRequestTypes('SET_REMITTANCE');
const ALLOW_REMITTANCE = createRequestTypes('ALLOW_REMITTANCE');
const SET_INVOICE = createRequestTypes('SET_INVOICE');
const RESET_OFFLINE_PRODUCT = 'RESET_OFFLINE_PRODUCT';
const SHOW_MODAL = 'REMITTANCE.SHOW_MODAL';

const RESET_REMITTANCE = 'RESET_REMITTANCE';

export const defaultQuery = {
  userId: '',
  item: 20,
  page: 1
};

/**
 * download
 */
export const downloadRemittance = (payload, offlineProduct) => {
  getRemittanceListAPI(payload).subscribe(response => {
    const data = mergeProductInfo(response.data, offlineProduct);
    downloadCSV(
      Rmap(
        compose(
          value => {
            let status;
            if (!isReallyEmpty(value.approvedAt)) {
              status = '已批准';
            } else if (!isReallyEmpty(value.accountNumber)) {
              status = '已匯款';
            } else {
              status = '未匯款';
            }
            return { ...value, status };
          },
          evolve({
            consumer: value => value.nickname,
            createdAt: value =>
              moment.unix(value).format('YYYY-MM-DD HH:mm:ss'),
            approvedAt: value =>
              value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '',
            price: value => (value ? value.toLocaleString() : '無產品金額'),
            points: value => (value ? value.toLocaleString() : '無產品點數'),
            paymentType: value =>
              value === PaymentTypes[0].value ? '匯款' : '現金'
          })
        ),
        data
      ),
      [
        'id',
        'consumer',
        'createdAt',
        'price',
        'points',
        'approvedAt',
        'status',
        'accountNumber',
        'invoiceNumber',
        'note',
        'paymentType'
      ],
      [
        'ID',
        '消費者',
        '建立日期',
        '交易金額',
        '交易點數',
        '入帳發點日期',
        '狀態',
        '匯款帳號後五碼',
        '發票號碼',
        '備註',
        '交易方式'
      ]
    );
  });
};

/**
 * Creator
 */
export const getRemittanceList = createAction(
  GET_REMITTANCE_LIST.REQUEST,
  (payload = {}) =>
    pick(['userId', 'startTime', 'endTime', 'item', 'page'], {
      ...defaultQuery,
      ...payload
    })
);
export const getRemittanceListSuccess = createAction(
  GET_REMITTANCE_LIST.SUCCESS
);
export const getRemittanceListFaiure = createAction(
  GET_REMITTANCE_LIST.FAILURE
);
export const getRemittance = createAction(GET_REMITTANCE.REQUEST);
export const getRemittanceSuccess = createAction(GET_REMITTANCE.SUCCESS);
export const getRemittanceFaiure = createAction(GET_REMITTANCE.FAILURE);
export const addRemittance = createAction(ADD_REMITTANCE.REQUEST);
export const addRemittanceSuccess = createAction(ADD_REMITTANCE.SUCCESS);
export const addRemittanceFaiure = createAction(ADD_REMITTANCE.FAILURE);
export const setRemittance = createAction(
  SET_REMITTANCE.REQUEST,
  (value, enableCallInvoice = false) => ({ ...value, enableCallInvoice })
);
export const setRemittanceSuccess = createAction(SET_REMITTANCE.SUCCESS);
export const setRemittanceFaiure = createAction(SET_REMITTANCE.FAILURE);
export const allowRemittance = createAction(ALLOW_REMITTANCE.REQUEST);
export const allowRemittanceSuccess = createAction(ALLOW_REMITTANCE.SUCCESS);
export const allowRemittanceFaiure = createAction(ALLOW_REMITTANCE.FAILURE);
export const showModal = createAction(SHOW_MODAL);
export const resetRemittance = createAction(RESET_REMITTANCE);
export const resetOfflineProduct = createAction(RESET_OFFLINE_PRODUCT);
export const setInvoice = createAction(SET_INVOICE.REQUEST);
export const setInvoiceSuccess = createAction(SET_INVOICE.SUCCESS);
export const setInvoiceFaiure = createAction(SET_INVOICE.FAILURE);
/**
 * Epic
 */
export const getRemittanceListEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_REMITTANCE_LIST.REQUEST),
    pluck('payload'),
    withLatestFrom(state$),
    switchMap(
      ([
        payload,
        {
          remittanceManagement: { offlineProduct }
        }
      ]) =>
        forkJoin([
          getRemittanceListAPI(payload),
          // prettier-ignore
          isEmpty(offlineProduct)? 
            getProductList().pipe(
              catchError(error => message.error('轉帳方案讀取失敗'))
            )
            : of({productInfos:offlineProduct})
        ]).pipe(
          map(getRemittanceListSuccess),
          catchRequestError(getRemittanceListFaiure)
        )
    )
  );
export const getRemittanceEpic = action$ =>
  action$.pipe(
    ofType(GET_REMITTANCE.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getRemittanceAPI(payload).pipe(
        map(getRemittanceSuccess),
        catchRequestError(getRemittanceFaiure)
      )
    )
  );

export const addRemittanceEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_REMITTANCE.REQUEST),
    pluck('payload'),
    withLatestFrom(state$),
    switchMap(([payload, { remittanceManagement }]) =>
      addRemittanceAPI(payload).pipe(
        mergeMap(result => [
          addRemittanceSuccess(result),
          getRemittanceList(remittanceManagement),
          showModal(false)
        ]),
        catchRequestError(addRemittanceFaiure)
      )
    )
  );

export const setRemittanceEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_REMITTANCE.REQUEST),
    pluck('payload'),
    withLatestFrom(state$),
    switchMap(([payload, { remittanceManagement }]) => {
      return setRemittanceAPI(payload).pipe(
        mergeMap(result => {
          const actions = [
            setRemittanceSuccess(result),
            getRemittanceList(remittanceManagement),
            showModal(false)
          ];
          if (payload.enableCallInvoice) {
            actions.push(setInvoice(payload.id));
          }
          return actions;
        }),
        catchRequestError(setRemittanceFaiure)
      );
    })
  );

export const allowRemittanceEpic = (action$, state$) =>
  action$.pipe(
    ofType(ALLOW_REMITTANCE.REQUEST),
    pluck('payload'),
    withLatestFrom(state$),
    switchMap(([payload, { remittanceManagement }]) =>
      allowRemittanceAPI(payload).pipe(
        mergeMap(result => {
          return [
            allowRemittanceSuccess(result),
            getRemittanceList(remittanceManagement),
            showModal(false)
          ];
        }),
        catchRequestError(allowRemittanceFaiure)
      )
    )
  );

export const setInvoiceEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_INVOICE.REQUEST),
    pluck('payload'),
    withLatestFrom(state$),
    switchMap(([payload, { remittanceManagement }]) =>
      invoiceAPI(payload).pipe(
        tap(result => {
          if (result.Message === 'SUCCESS') {
            message.success('開立發票成功');
          }
        }),
        mergeMap(() => [getRemittanceList(remittanceManagement)]),
        catchRequestError(setInvoiceFaiure)
      )
    )
  );

const initialState = {
  loading: false,
  remittanceLoading: false,
  data: [],
  offlineProduct: [],
  currentRemittance: undefined,
  userId: defaultQuery.userId,
  page: defaultQuery.page,
  item: defaultQuery.item,
  isShowModal: false,
  totalCount: 0
};

export default handleActions(
  {
    [GET_REMITTANCE_LIST.REQUEST]: produce((draft, { payload }) => {
      draft.loading = true;
      draft.userId = payload.userId;
      draft.page = payload.page;
    }),
    [GET_REMITTANCE_LIST.SUCCESS]: produce(
      (draft, { payload: [remittances, { productInfos = [] }] }) => {
        draft.loading = false;
        draft.data = mergeProductInfo(remittances.data, productInfos);
        draft.totalCount = remittances.totalCount;
        draft.offlineProduct = productInfos;
      }
    ),
    [GET_REMITTANCE_LIST.FAILURE]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = [];
    }),
    [GET_REMITTANCE.REQUEST]: produce(draft => {
      draft.remittanceLoading = true;
      draft.currentRemittance = null;
    }),
    [GET_REMITTANCE.SUCCESS]: produce((draft, { payload }) => {
      draft.remittanceLoading = false;
      draft.currentRemittance = payload.data;
    }),
    [combineActions(GET_REMITTANCE.FAILURE, SET_REMITTANCE.SUCCESS)]: produce(
      (draft, { payload }) => {
        draft.remittanceLoading = false;
        draft.currentRemittance = null;
      }
    ),
    [combineActions(ADD_REMITTANCE.REQUEST, SET_REMITTANCE.REQUEST)]: produce(
      (draft, { payload }) => {
        draft.remittanceLoading = true;
      }
    ),
    [combineActions(
      ADD_REMITTANCE.SUCCESS,
      SET_REMITTANCE.FAILURE,
      ADD_REMITTANCE.FAILURE
    )]: produce((draft, { payload }) => {
      draft.remittanceLoading = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    }),
    [RESET_REMITTANCE]: produce((draft, { payload }) => {
      draft.currentRemittance = null;
    }),
    [RESET_OFFLINE_PRODUCT]: produce(draft => {
      draft.offlineProduct = [];
    })
  },
  initialState
);
