import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { getVoiceChatList } from '../../../apis';
import { VOICE_CHAT_ROOM } from '../../../constants/routes';
import { Button, Pagination } from 'antd';
import VoiceChatCard from './VoiceChatCard';
import VoiceChatModal from './VoiceChatModal';
import LiveMasterSelect from '../../../components/LiveMasterSelect';

const VoiceChatTools = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
`;

const VoiceChatToolsLeft = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const VoiceChatToolsRight = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const VoiceChatPagination = styled(Pagination)`
  margin: 1rem 0;
  text-align: right;
`;

class PubliceVoiceChat extends React.Component {

  constructor(props) {
    super(props);

    this.VoiceChatModalRef = React.createRef();

    this.state = {
      isLoading: false,
      list: [],
      currentList: [],
      filter: {
        roomOwnerId: undefined,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchVoiceChatList();
  }

  fetchVoiceChatList = () => {
    this.setState({ isLoading: true });
    const observable = getVoiceChatList({ isOfficial: this.props.isOfficial });
    observable.subscribe(res => {
      const { data } = res;
      const list = _.orderBy(
        data,
        ['roomStatus', 'roomId'],
        'desc',
      );
      this.setState({
        list,
        isLoading: false,
      }, this.processVoiceChatList);
    });
  };

  processVoiceChatList = () => {
    const { filter, list, pagination } = this.state;
    const iFilter = _.pickBy(filter, _.identity);
    const currentList = _.filter(list, iFilter);
    const newPagination = {
      ...pagination,
      current: 1,
      total: currentList.length,
    };
    this.setState({
      currentList,
      pagination: newPagination,
    });
  }

  getPageList = () => {
    const { currentList ,pagination } = this.state;
    const { current, pageSize } = pagination;
    const offset = (current - 1) * pageSize;
    return currentList.slice(offset, offset + pageSize);
  }

  onCreateBtnClick = () => {
    const modal = this.VoiceChatModalRef.current;
    modal.show('create');
  };

  onEditBtnClick = (record) => {
    const modal = this.VoiceChatModalRef.current;
    modal.show('edit', record);
  };

  onEnterBtnClick = (record) => {
    const { roomId } = record;
    window.open(`${VOICE_CHAT_ROOM}/${roomId}`, '_blank');
  }

  onLiveMasterSelected = (master) => {
    const { filter } = this.state;
    const roomOwnerId = _.get(master, '0.id');
    const newFilter = { ...filter, roomOwnerId };
    this.setState({ filter: newFilter }, this.processVoiceChatList);
  }

  onPagenationChange = (page) => {
    const pagination = {
      ...this.state.pagination,
      current: page,
    };
    this.setState({ pagination });
  }

  render() {
    const { isLoading, filter, pagination } = this.state;
    const list = this.getPageList();
    return (
      <div>
        <VoiceChatTools>
          <VoiceChatToolsLeft>
            <Button type="primary" onClick={this.onCreateBtnClick}>
              <span>新增</span>
            </Button>
            <LiveMasterSelect
              style={{ width: '20rem' }}
              roleFilter="ROLE_VOICE_MASTER"
              value={filter.roomOwnerId}
              onSelectChange={this.onLiveMasterSelected}
            />
          </VoiceChatToolsLeft>
          <VoiceChatToolsRight>
            <Button
              type="primary"
              loading={isLoading}
              onClick={this.fetchVoiceChatList}
            >
              <span>刷新列表</span>
            </Button>
          </VoiceChatToolsRight>
        </VoiceChatTools>
        <div>
          {list.map((data) => (
            <VoiceChatCard
              key={data.roomId}
              dataset={data}
              onEdit={this.onEditBtnClick}
              onEnter={this.onEnterBtnClick}
            />
          ))}
        </div>
        <VoiceChatPagination
          {...pagination}
          onChange={this.onPagenationChange}
        />
        <VoiceChatModal
          ref={this.VoiceChatModalRef}
          onCompleted={this.fetchVoiceChatList}
        />
      </div>
    );
  }
}

export default PubliceVoiceChat;
