import moment from 'moment';
import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, DatePicker, Form, Modal, Table, Tag } from 'antd';
import {
  downloadPointTransfer,
  getPointTransferList
} from '../../reducers/pointTransfer';
import antdTablePagination from '../../utils/antdTablePagination';
import LightBox from '../../components/LightBox';
import brandName from '../../constants/brandName';

const RangePicker = DatePicker.RangePicker;
const FormItem = Form.Item;

const Actions = styled.div`
  display: flex;
`;

const UserInfoContainer = styled.div`
  p {
    margin-bottom: 8px;
  }
  p:last-child {
    margin-bottom: 0px;
  }
`;

const getColumns = uuid => {
  return [
    {
      title: '轉點時間',
      dataIndex: 'createdAt',
      render: value => moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '轉點對象',
      dataIndex: 'receiverLoginId',
      render: (receiverLoginId, record) => (
        <UserInfoContainer>
          <p>暱稱：{record.receiverNickname}</p>
          <p>Login ID：{receiverLoginId}</p>
          <p>
            {brandName} ID：{record.receiverXtarsId}
          </p>
        </UserInfoContainer>
      )
    },
    {
      title: '轉入/轉出',
      render: (__, record) =>
        record.receiverId === uuid ? (
          <Tag color="green">轉入</Tag>
        ) : (
          <Tag color="red">轉出</Tag>
        )
    },
    {
      title: '點數',
      dataIndex: 'point',
      render: (value, record) =>
        record.receiverId === uuid ? (
          <span style={{ color: '#52c41a' }}>{value.toLocaleString()}</span>
        ) : (
          <span style={{ color: '#f5222d' }}>{value.toLocaleString()}</span>
        )
    },
    {
      title: '轉點比值',
      dataIndex: 'senderRatio',
      render: value => value
    },
    {
      title: '操作者',
      dataIndex: 'createNickname'
    }
  ];
};

const renderImage = images => {
  return images.map((imageUrl, index) => (
    <LightBox
      key={imageUrl}
      style={{ display: 'inline-block', margin: '8px 8px 0 0' }}
      src={imageUrl}
    >{`圖片 ${index + 1}`}</LightBox>
  ));
};

const renderExpanded = data => {
  return (
    <React.Fragment>
      <span>{`備註：${data.remark ? data.remark : '無'}`}</span>
      <div>
        <label>圖片：</label>
        {isEmpty(data.image) ? '無' : renderImage(data.image)}
      </div>
    </React.Fragment>
  );
};

const PointTransferHistory = ({
  uuid,
  data,
  totalCount,
  item = 20,
  loading,
  visible,
  getPointTransferList,
  downloadPointTransfer,
  onCancel
}) => {
  const [dateTime, setDateTime] = useState(() => [
    moment().startOf('day').subtract(7, 'DAY'),
    moment().endOf('day')
  ]);
  const [pagination, setPagination] = useState({
    ...new antdTablePagination(),
    current: 1
  });
  const handleConfirmDate = value => {
    setDateTime(value);
  };
  useEffect(() => {
    if (visible) {
      getPointTransferList({
        userFilter: uuid,
        startTime: dateTime[0].unix(),
        endTime: dateTime[1].unix(),
        page: pagination.current,
        item: pagination.pageSize
      });
    }
  }, [visible, dateTime, uuid, item, pagination, getPointTransferList]);

  const handleDownloadClick = () => {
    downloadPointTransfer({
      userFilter: uuid,
      startTime: dateTime[0].unix(),
      endTime: dateTime[1].unix(),
      page: 1,
      item: totalCount
    });
  };

  return (
    <Modal
      title="站內轉點歷程"
      width={990}
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <Actions>
        <FormItem label="日期區間" style={{ display: 'flex' }}>
          <RangePicker
            onOk={handleConfirmDate}
            allowClear={false}
            style={{ width: '400px' }}
            defaultValue={dateTime}
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss')
              ]
            }}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </FormItem>
        <FormItem style={{ marginLeft: 'auto' }}>
          <Button
            disabled={isEmpty(data) || loading}
            onClick={handleDownloadClick}
          >
            <span>匯出報表</span>
          </Button>
        </FormItem>
      </Actions>
      <Table
        rowKey="id"
        dataSource={data}
        columns={getColumns(uuid)}
        loading={loading}
        expandedRowRender={renderExpanded}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: totalCount,
          onChange: page =>
            setPagination({
              ...pagination,
              current: page
            })
        }}
      />
    </Modal>
  );
};

const mapStateToProps = state => {
  const { pointTransfer } = state;
  const { data, loading, totalCount } = pointTransfer;
  return { data, loading, totalCount };
};

const mapDispatchToProps = {
  getPointTransferList,
  downloadPointTransfer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PointTransferHistory);
