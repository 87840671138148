import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, Spin } from 'antd';
import { AppstoreOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  ACCOUNT_SHEET_EXPORT,
  ACCOUNT_DELETION_REQUEST,
  // ACHIEVEMENT_MANAGEMENT,
  AD_MANAGEMENT,
  AGENCY_MANAGEMENT,
  FAMILY_MANAGEMENT,
  ANCHOR_APPLICATION,
  ANCHOR_MANAGEMENT,
  // ANIMATION_EFFECT_MANAGEMENT,
  // ASSIGNED_ACHIEVEMENTS,
  BANK_ACCOUNT_SETTINGS,
  COMMENT_MANAGEMENT,
  DAU,
  DISMANTLING_STATISTICS,
  ENQUIRY_MANAGEMENT,
  EVENT_CATEGORIES_MANAGEMENT,
  EXPORT_USER_DATA,
  FANS_GIFT_RANKING,
  GIFT_LIST,
  GIFT_REPORT,
  INTERACTIVE_GIFTBOX_MANAGEMENT,
  LIVE_APPLICATION_REVIEW,
  VOICE_CHAT_LIST,
  VOICE_CHAT_TYPE_MANAGEMENT,
  LIVE_MASTER_ACCOUNT_MANAGEMENT,
  LIVE_MASTER_HOURS_SHEET_EXPORT,
  LIVE_MASTER_NETWORK_SPEED_SHEET_EXPORT,
  LIVE_VIEWER_STATISTICS,
  MANAGER_COMPANY_BANK_ACCOUNT_SETTINGS,
  MAU,
  OVERLAY_AD_MANAGEMENT,
  PAYMENT_TOOL_STATISTICS,
  PERFORMANCE_GROWTH_REPORT,
  PERSONAL,
  PHOTO_MANAGEMENT,
  POINT_RATIO_MANAGEMENT,
  PURCHASE_ORDER_LIST,
  REGISTRATION_STAFF_GROWTH_REPORT,
  // OFF_LIST,
  // REPORTED_LIVE,
  REPORTED_POSTS,
  RESET_AUTH,
  // REWARD_MANAGEMENT,
  STATISTICS_INFO,
  // LIVE_PROFIT_SHARE_DOC_APPLICATION_SETTING,
  // REGULAR_SETTLEMENT,
  // MANUAL_SETTLEMENT,
  SYSTEM_ANNOUNCEMENT_PUSH,
  FORBIDDEN_MANAGEMENT,
  TOTAL_SHARE_RATE,
  USER_CONSUME_SHEET_EXPORT,
  USER_MANAGEMENT,
  MESSAGE_MANAGEMENT,
  MESSAGE_ROOM_LIST,
  REMITTANCE_MANAGEMENT,
  REMITTANCE_PRODUCT_MANAGEMENT,
  TAB_MANAGEMENT,
  TAG_MANAGEMENT,
  TAG_GROUP_MANAGEMENT,
  SPOTLIGHT_MANAGEMENT,
  EVENT_BANNER_MANAGEMENT,
  AD_BANNER_MANAGEMENT,
  REDIRECT,
  SERIES_MANAGEMENT,
  FLASH_NEWS_MANAGEMENT,
  VIDEO_MANAGEMENT,
  PAST_EVENT_MANAGEMENT,
  APP_CONFIG_MANAGEMENT,
  APP_RANKING_MANAGEMENT,
  ACCOUNT_CREATION_MANAGEMENT,
  BLOCKLIST_MANAGEMENT,
  VOICE_CHAT_ROOM_REPORT,
  VOICE_CHAT_KICKOUT_USER,
  EFFECT_MANAGEMENT,
  DISPATCH_MANAGEMENT,
  CONTRACT_MANAGEMENT,
  PRIVATEVOICECHAT_SPOTLIGHT_MANAGEMENT,
  DISPATCH_GIFT_MANAGEMENT,
  BOTTOM_TABBAR_MANAGEMENT,
  FLOATING_SLOT_MANAGEMENT,
  SIGNIN_EVENT_MANAGEMENT,
  // POINT_TRADING_MANAGEMENT,
  QUERY_RANKING_LIST
} from '../../constants/routes';
import PropTypes from 'prop-types';
import {
  compose,
  equals,
  find,
  findIndex,
  has,
  ifElse,
  isEmpty,
  isNil,
  map,
  mergeAll,
  pathOr,
  prop,
  propEq
} from 'ramda';
import { roles } from '../../constants/roles';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const MenuSpining = () => (
  <div
    style={{
      paddingTop: '120px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Spin indicator={antIcon} />
  </div>
);

const StyledMenu = styled(Menu)`
  user-select: none;
`;

const rolesK = mergeAll(Object.keys(roles).map(v => ({ [v]: v })));
const getFirstPartPath = pathname => `/${pathname.split('/')[1]}`;
const getDefaultOpenKeyByPathname = (routeRules, pathname) => {
  const firstPartPath = getFirstPartPath(pathname);
  const matchPath = ifElse(
    has('matchpaths'),
    value => findIndex(equals(pathname), value.matchpaths) > -1,
    propEq('key', firstPartPath)
  );
  return compose(
    (value = []) => [
      value.key,
      compose(prop('key'), find(matchPath), pathOr([], ['children']))(value)
    ],
    find(value => {
      return findIndex(matchPath)(value.children) > -1;
    })
  )(routeRules);
};
const routeRules = [
  {
    key: 'appManagement',
    label: 'APP 管理',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: SYSTEM_ANNOUNCEMENT_PUSH,
        label: <Link to={SYSTEM_ANNOUNCEMENT_PUSH}>系統公告推播</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_PLANNING,
          rolesK.ROLE_CUSTOMER_SERVICE
        ]
      },
      {
        key: TAG_GROUP_MANAGEMENT,
        label: <Link to={TAG_GROUP_MANAGEMENT}>標籤群組(TagGroup)管理</Link>,
        roles: [
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_LIVE_CONTROLLER
        ]
      },
      {
        key: TAG_MANAGEMENT,
        label: <Link to={TAG_MANAGEMENT}>標籤(Tag)管理</Link>,
        roles: [
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_LIVE_CONTROLLER
        ]
      },
      {
        key: APP_RANKING_MANAGEMENT,
        label: <Link to={APP_RANKING_MANAGEMENT}>APP 排行榜管理</Link>,
        roles: [
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_LIVE_CONTROLLER
        ]
      },
      {
        key: BLOCKLIST_MANAGEMENT,
        label: <Link to={BLOCKLIST_MANAGEMENT}>APP 排行榜黑名單</Link>,
        roles: [
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_LIVE_CONTROLLER
        ]
      },
      {
        key: FORBIDDEN_MANAGEMENT,
        label: <Link to={FORBIDDEN_MANAGEMENT}>禁詞管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_PLANNING,
          rolesK.ROLE_CUSTOMER_SERVICE
        ]
      }
    ]
  },
  {
    key: 'userManagement',
    label: '帳號管理',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: ACCOUNT_CREATION_MANAGEMENT,
        label: <Link to={ACCOUNT_CREATION_MANAGEMENT}>新創帳號</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      },
      {
        key: USER_MANAGEMENT,
        label: <Link to={USER_MANAGEMENT}>使用者帳號管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER
        ]
      },
      {
        key: EXPORT_USER_DATA,
        label: <Link to={EXPORT_USER_DATA}>使用者資料匯出</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER
        ]
      },
      {
        key: ACCOUNT_DELETION_REQUEST,
        label: <Link to={ACCOUNT_DELETION_REQUEST}>帳號刪除申請</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER
        ]
      },
      {
        key: LIVE_MASTER_ACCOUNT_MANAGEMENT,
        label: <Link to={LIVE_MASTER_ACCOUNT_MANAGEMENT}>直播主帳號管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER_INTERN,
          rolesK.ROLE_AGENCY_MANAGER
        ]
      },
      {
        key: PHOTO_MANAGEMENT,
        label: <Link to={PHOTO_MANAGEMENT}>照片管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER
        ]
      },
      {
        key: LIVE_APPLICATION_REVIEW,
        label: <Link to={LIVE_APPLICATION_REVIEW}>直播申請審核</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER
        ]
      },
      {
        key: RESET_AUTH,
        label: <Link to={RESET_AUTH}>重置驗證</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN
        ]
      }
    ]
  },
  {
    key: 'homePageManagement',
    label: '首頁管理',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: SPOTLIGHT_MANAGEMENT,
        label: <Link to={SPOTLIGHT_MANAGEMENT}>Spotlight - 主廣告</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: TAB_MANAGEMENT,
        label: <Link to={TAB_MANAGEMENT}>Main Menu 分類</Link>,
        roles: [rolesK.ROLE_ADMIN, rolesK.ROLE_BUSINESS_MANAGER]
      },
      {
        key: EVENT_BANNER_MANAGEMENT,
        label: <Link to={EVENT_BANNER_MANAGEMENT}>Event Banner</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: AD_BANNER_MANAGEMENT,
        label: <Link to={AD_BANNER_MANAGEMENT}>Ad Banner</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: FLASH_NEWS_MANAGEMENT,
        label: <Link to={FLASH_NEWS_MANAGEMENT}>官方廣播</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      },
      {
        key: SERIES_MANAGEMENT,
        label: <Link to={SERIES_MANAGEMENT}>專題直播主</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      },
      {
        key: PAST_EVENT_MANAGEMENT,
        label: <Link to={PAST_EVENT_MANAGEMENT}>上月活動</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      },
      {
        key: VIDEO_MANAGEMENT,
        label: <Link to={VIDEO_MANAGEMENT}>精彩影音</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      },
      {
        key: APP_CONFIG_MANAGEMENT,
        label: <Link to={APP_CONFIG_MANAGEMENT}>AppConfig</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      },
      {
        key: BOTTOM_TABBAR_MANAGEMENT,
        label: <Link to={BOTTOM_TABBAR_MANAGEMENT}>底部頁籤</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      },
      {
        key: FLOATING_SLOT_MANAGEMENT,
        label: <Link to={FLOATING_SLOT_MANAGEMENT}>浮動版位</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      },
      {
        key: SIGNIN_EVENT_MANAGEMENT,
        label: <Link to={SIGNIN_EVENT_MANAGEMENT}>簽到任務</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_ADMIN]
      }
    ]
  },
  {
    key: 'eventManagement',
    label: '活動管理',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: EVENT_CATEGORIES_MANAGEMENT,
        label: <Link to={EVENT_CATEGORIES_MANAGEMENT}>活動類別管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: GIFT_LIST,
        label: <Link to={GIFT_LIST}>禮物列表</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: AD_MANAGEMENT + '/spotlight',
        matchpaths: [
          AD_MANAGEMENT + '/spotlight',
          AD_MANAGEMENT + '/event/0',
          AD_MANAGEMENT + '/hot/0'
        ],
        label: <Link to={AD_MANAGEMENT + '/spotlight'}>廣告管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: `${REDIRECT}${EVENT_BANNER_MANAGEMENT}`,
        label: (
          <Link to={`${REDIRECT}${EVENT_BANNER_MANAGEMENT}`}>活動廣告管理</Link>
        ),
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: OVERLAY_AD_MANAGEMENT,
        label: <Link to={OVERLAY_AD_MANAGEMENT}>蓋板廣告管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: INTERACTIVE_GIFTBOX_MANAGEMENT,
        label: (
          <Link to={INTERACTIVE_GIFTBOX_MANAGEMENT}>互動式禮物盒設定</Link>
        ),
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: EFFECT_MANAGEMENT,
        label: <Link to={EFFECT_MANAGEMENT}>特效管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: DISPATCH_MANAGEMENT,
        label: <Link to={DISPATCH_MANAGEMENT}>發送特效</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: DISPATCH_GIFT_MANAGEMENT,
        label: <Link to={DISPATCH_GIFT_MANAGEMENT}>發送背包禮物</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: QUERY_RANKING_LIST,
        label: <Link to={QUERY_RANKING_LIST}>榜單查詢</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_PLANNING
        ]
      }
    ]
  },
  {
    key: 'liveManagement',
    label: '直播管理',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: REPORTED_POSTS,
        label: <Link to={REPORTED_POSTS}>被檢舉貼文</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER
        ]
      },
      {
        key: LIVE_MASTER_NETWORK_SPEED_SHEET_EXPORT,
        label: (
          <Link to={LIVE_MASTER_NETWORK_SPEED_SHEET_EXPORT}>
            直播主網速查詢
          </Link>
        ),
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER_INTERN
        ]
      },
    ]
  },
  {
    key: 'voiceChatMa',
    label: '聲聊管理',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: PRIVATEVOICECHAT_SPOTLIGHT_MANAGEMENT,
        label: (
          <Link to={PRIVATEVOICECHAT_SPOTLIGHT_MANAGEMENT}>
            私密聲聊 Spotlight
          </Link>
        ),
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_DESIGNER,
          rolesK.ROLE_PLANNING
        ]
      },
      {
        key: VOICE_CHAT_TYPE_MANAGEMENT,
        label: <Link to={VOICE_CHAT_TYPE_MANAGEMENT}>聲聊類型管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER_INTERN
        ]
      },
      {
        key: VOICE_CHAT_LIST,
        label: <Link to={VOICE_CHAT_LIST}>聲聊房列表</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER_INTERN
        ]
      },
      {
        key: VOICE_CHAT_ROOM_REPORT,
        label: <Link to={VOICE_CHAT_ROOM_REPORT}>各廳流水業績查詢</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER_INTERN
        ]
      },
      {
        key: VOICE_CHAT_KICKOUT_USER,
        label: <Link to={VOICE_CHAT_KICKOUT_USER}>聲聊使用者管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN,
          rolesK.ROLE_CONTENT_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER_INTERN
        ]
      }
    ]
  },
  {
    key: 'operatingManagement',
    label: '經營管理',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: CONTRACT_MANAGEMENT,
        label: <Link to={CONTRACT_MANAGEMENT}>分潤方案管理</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_AGENCY_MANAGER]
      },
      {
        key: AGENCY_MANAGEMENT,
        label: <Link to={AGENCY_MANAGEMENT}>經紀公司管理</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_AGENCY_MANAGER]
      },
      {
        key: FAMILY_MANAGEMENT,
        label: <Link to={FAMILY_MANAGEMENT}>家族管理</Link>,
        roles: [rolesK.ROLE_AGENCY, rolesK.ROLE_LIVE_CONTROLLER]
      },
      {
        key: DAU,
        label: <Link to={DAU}>DAU</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: MAU,
        label: <Link to={MAU}>MAU</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: REGISTRATION_STAFF_GROWTH_REPORT,
        label: (
          <Link to={REGISTRATION_STAFF_GROWTH_REPORT}>註冊會員成長報表</Link>
        ),
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: PERFORMANCE_GROWTH_REPORT,
        label: <Link to={PERFORMANCE_GROWTH_REPORT}>業績成長報表</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: LIVE_VIEWER_STATISTICS,
        label: <Link to={LIVE_VIEWER_STATISTICS}>直播人流統計</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: LIVE_MASTER_HOURS_SHEET_EXPORT,
        label: (
          <Link to={LIVE_MASTER_HOURS_SHEET_EXPORT}>
            直播主每日直播時數統計表
          </Link>
        ),
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_AGENCY_MANAGER_INTERN
        ]
      },
      {
        key: GIFT_REPORT,
        label: <Link to={GIFT_REPORT}>送禮報表</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: ENQUIRY_MANAGEMENT,
        label: <Link to={ENQUIRY_MANAGEMENT}>客服意見管理</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: MESSAGE_MANAGEMENT,
        label: <Link to={MESSAGE_MANAGEMENT}>私訊管理</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: MESSAGE_ROOM_LIST,
        label: <Link to={MESSAGE_ROOM_LIST}>私訊紀錄查詢</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      }
    ]
  },
  {
    key: 'accountManagement',
    label: '帳務管理',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: ACCOUNT_SHEET_EXPORT,
        label: <Link to={ACCOUNT_SHEET_EXPORT}>直播主收益管理</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_FINANCE_MANAGER,
          rolesK.ROLE_AGENCY_MANAGER_INTERN
        ]
      },
      {
        key: POINT_RATIO_MANAGEMENT,
        label: <Link to={POINT_RATIO_MANAGEMENT}>匯入比值對照表</Link>,
        roles: [
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_FINANCE_MANAGER
        ]
      },
      {
        key: PAYMENT_TOOL_STATISTICS,
        label: <Link to={PAYMENT_TOOL_STATISTICS}>支付工具使用統計報表</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: USER_CONSUME_SHEET_EXPORT,
        label: <Link to={USER_CONSUME_SHEET_EXPORT}>粉絲期間消費報表</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: PURCHASE_ORDER_LIST,
        label: <Link to={PURCHASE_ORDER_LIST}>交易資料查詢</Link>,
        roles: [rolesK.ROLE_BUSINESS_MANAGER, rolesK.ROLE_CUSTOMER_SERVICE]
      },
      {
        key: REMITTANCE_PRODUCT_MANAGEMENT,
        label: <Link to={REMITTANCE_PRODUCT_MANAGEMENT}>匯款商品管理</Link>,
        roles: [
          rolesK.ROLE_LIVE_CONTROLLER,
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN
        ]
      },
      {
        key: REMITTANCE_MANAGEMENT,
        label: <Link to={REMITTANCE_MANAGEMENT}>匯款訂單管理</Link>,
        roles: [
          rolesK.ROLE_LIVE_CONTROLLER,
          rolesK.ROLE_BUSINESS_MANAGER,
          rolesK.ROLE_CUSTOMER_SERVICE,
          rolesK.ROLE_ADMIN
        ]
      }
      // 先隱藏
      // {
      //   key: POINT_TRADING_MANAGEMENT,
      //   label: <Link to={POINT_TRADING_MANAGEMENT}>業務管理</Link>,
      //   roles: [
      //     rolesK.ROLE_BUSINESS_MANAGER,
      //     rolesK.ROLE_CUSTOMER_SERVICE,
      //     rolesK.ROLE_ADMIN
      //   ]
      // }
    ]
  },
  {
    key: 'managerCompany',
    label: '經紀公司',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: ANCHOR_MANAGEMENT,
        label: <Link to={ANCHOR_MANAGEMENT}>主播管理</Link>,
        roles: [rolesK.ROLE_AGENCY]
      },
      {
        key: ANCHOR_APPLICATION,
        label: <Link to={ANCHOR_APPLICATION}>主播加入申請</Link>,
        roles: [rolesK.ROLE_AGENCY]
      },
      {
        key: TOTAL_SHARE_RATE,
        label: <Link to={TOTAL_SHARE_RATE}>總金額拆帳比</Link>,
        roles: [rolesK.ROLE_AGENCY]
      },
      {
        key: MANAGER_COMPANY_BANK_ACCOUNT_SETTINGS,
        label: (
          <Link to={MANAGER_COMPANY_BANK_ACCOUNT_SETTINGS}>銀行帳戶設定</Link>
        ),
        roles: [rolesK.ROLE_AGENCY]
      }
    ]
  },
  {
    key: 'anchor',
    label: '主播',
    icon: <AppstoreOutlined />,
    children: [
      {
        key: BANK_ACCOUNT_SETTINGS,
        label: <Link to={BANK_ACCOUNT_SETTINGS}>銀行帳戶設定</Link>,
        roles: [rolesK.ROLE_MASTER]
      },
      {
        key: PERSONAL,
        label: <Link to={PERSONAL}>帳號管理</Link>,
        roles: [rolesK.ROLE_MASTER]
      },
      {
        key: COMMENT_MANAGEMENT,
        label: <Link to={COMMENT_MANAGEMENT}>留言管理</Link>,
        roles: [rolesK.ROLE_MASTER]
      },
      {
        key: STATISTICS_INFO,
        label: <Link to={STATISTICS_INFO}>統計資訊</Link>,
        roles: [rolesK.ROLE_MASTER]
      },
      {
        key: FANS_GIFT_RANKING,
        label: <Link to={FANS_GIFT_RANKING}>粉絲贈禮排行榜</Link>,
        roles: [rolesK.ROLE_MASTER]
      },
      {
        key: DISMANTLING_STATISTICS,
        label: <Link to={DISMANTLING_STATISTICS}>指定期間拆帳統計</Link>,
        roles: [rolesK.ROLE_MASTER]
      }
    ]
  }
];

class SideMenu extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    const pathname = props.location.pathname;
    this.state = {
      defaultOpenKey: getDefaultOpenKeyByPathname(routeRules, pathname)
    };
  }
  shouldComponentUpdate(nextProps) {
    const {
      location: { pathname },
      accountRightObj,
      roles
    } = this.props;
    return (
      nextProps.location.pathname !== pathname ||
      nextProps.roles[0] !== roles[0] ||
      nextProps.accountRightObj !== accountRightObj
    );
  }

  getPermission = () => {
    const { roles, accountRightObj } = this.props;
    if (isNil(accountRightObj) || isEmpty(roles)) {
      return null;
    }
    const filterByRole = path => path.roles.includes(roles[0]);
    const filterByAccount = path =>
      pathOr(false, [path.key.replace(/^\//g, '')], accountRightObj);
    const filterMenu = isEmpty(accountRightObj)
      ? filterByRole
      : filterByAccount;
    return compose(
      map(({ children, ...item }) => {
        const menuItems = children.filter(filterMenu);
        if (menuItems.length > 0) {
          item.children = menuItems;
          return item;
        } else {
          return null;
        }
      })
    )(routeRules);
  };

  render() {
    const { className } = this.props;
    const defaultOpenKey = getDefaultOpenKeyByPathname(
      routeRules,
      this.props.location.pathname
    );
    const items = this.getPermission();

    return items ? (
      <StyledMenu
        className={className}
        mode="inline"
        selectedKeys={[defaultOpenKey[1]]}
        defaultOpenKeys={[defaultOpenKey[0]]}
        style={{ height: '100%' }}
        items={items}
      />
    ) : (
      <MenuSpining />
    );
  }
}

const enhance = compose(
  connect(state => ({
    roles: state.auth.roles.map(item => item.name),
    id: state.auth.id,
    loginId: state.auth.loginId,
    accountRightObj: state.auth.accountRightObj
  })),
  withRouter
);

export default enhance(SideMenu);
