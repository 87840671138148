import { Button, message, Upload } from 'antd';
import { filter, pipe, split } from 'ramda';
import React, { useState } from 'react';
const validatUUID = value =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(value);
const validatFileType = type => /^.+.([cC][sS][vV])$/.test(type);
const csvToArray = split(/\r\n|\n|\s|,/);
const getData = pipe(csvToArray, filter(validatUUID));
const CsvIdImport = ({ onChange, children }) => {
  const [fileList, setFileList] = useState([]);
  const uploadProps = {
    showUploadList: false,
    multiple: false,
    accept: '.csv',
    beforeUpload: file => {
      return new Promise((resolve, reject) => {
        if (validatFileType(file.type)) {
          setFileList([file]);
          resolve(true);
        } else {
          setFileList([]);
          reject(new Error('檔案格式錯誤，請重新操作'));
          message.error('檔案格式錯誤，請重新操作');
        }
      });
    },
    onChange: ({ file }) => {
      if (file?.status === 'done') {
        onChange(file?.response?.data);
      }
    },
    customRequest: file => {
      const reader = new FileReader();
      reader.onload = () => {
        const data = getData(reader.result);
        file.onSuccess({ data });
      };
      reader.readAsText(file.file);
    },
    fileList
  };
  return <Upload {...uploadProps}>{children || <CsvIdImport.Button />}</Upload>;
};

CsvIdImport.Button = () => {
  return <Button>檔案匯入</Button>;
};

export default CsvIdImport;
