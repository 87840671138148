import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import { switchMap, map, pluck } from 'rxjs/operators';
import { getPayloadByType, catchRequestError } from '../utils/extendOperators';

import { getPointRatioAPI, setPointRatioAPI } from '../apis';

/**
 * Type
 */

const GET_POINT_RATIO_CATEGORY = 'GET_POINT_RATIO_CATEGORY';
const GET_POINT_RATIO_CATEGORY_SUCCESS = 'GET_POINT_RATIO_CATEGORY_SUCCESS';
const GET_POINT_RATIO_CATEGORY_FAILURE = 'GET_POINT_RATIO_CATEGORY_FAILURE';

const SET_POINT_RATIO_CATEGORY = 'SET_POINT_RATIO_CATEGORY';
const SET_POINT_RATIO_CATEGORY_SUCCESS = 'SET_POINT_RATIO_CATEGORY_SUCCESS';
const SET_POINT_RATIO_CATEGORY_FAILURE = 'SET_POINT_RATIO_CATEGORY_FAILURE';

const SHOW_MODAL = 'SHOW_MODAL';

/**
 * Creator
 */

export const getPointRatio = createAction(GET_POINT_RATIO_CATEGORY);
const getPointRatioSuccess = createAction(GET_POINT_RATIO_CATEGORY_SUCCESS);
const getPointRatioFailure = createAction(GET_POINT_RATIO_CATEGORY_FAILURE);

export const setPointRatio = createAction(SET_POINT_RATIO_CATEGORY);
const setPointRatioSuccess = createAction(SET_POINT_RATIO_CATEGORY_SUCCESS);
const setPointRatioFailure = createAction(SET_POINT_RATIO_CATEGORY_FAILURE);

export const showModal = createAction(SHOW_MODAL);

export const getPointRatioEpic = action$ =>
  action$.pipe(
    getPayloadByType(GET_POINT_RATIO_CATEGORY),
    switchMap(() =>
      getPointRatioAPI().pipe(
        pluck('pointRatios'),
        map(getPointRatioSuccess),
        catchRequestError(getPointRatioFailure)
      )
    )
  );

export const setPointRatioEpic = action$ =>
  action$.pipe(
    getPayloadByType(SET_POINT_RATIO_CATEGORY),
    switchMap(payload =>
      setPointRatioAPI(payload).pipe(
        pluck('pointRatios'),
        map(setPointRatioSuccess),
        catchRequestError(setPointRatioFailure)
      )
    )
  );

const initialState = {
  loading: false,
  isShowModal: false,
  ratio: []
};

export default handleActions(
  {
    [combineActions(
      GET_POINT_RATIO_CATEGORY,
      SET_POINT_RATIO_CATEGORY
    )]: produce(draft => {
      draft.loading = true;
    }),
    [combineActions(
      GET_POINT_RATIO_CATEGORY_SUCCESS,
      SET_POINT_RATIO_CATEGORY_SUCCESS
    )]: produce((draft, { payload }) => {
      draft.ratio = payload;
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
