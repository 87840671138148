import React from 'react';
import styled from 'styled-components';
import {
  Button, Form, Input,
  Modal, Popconfirm,
} from 'antd';
import LiveMasterSelect from '../../components/LiveMasterSelect';

const FormItem = Form.Item;

const Footer = styled.div`
  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

class BlocklistFormModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.formRef = React.createRef();

    this.state = {};
  }

  onSubmit = () => {
    const form = this.formRef.current;
    const { onOk } = this.props;
    form.validateFields().then(onOk);
  }

  renderFooter = () => {
    const { isCreateMode, loading, onCancel } = this.props;
    const popConfirmTitle = `是否確定${isCreateMode ? '新增' : '儲存'} `;
    return (
      <Footer>
        <Popconfirm
          key="confirm"
          title={popConfirmTitle}
          placement="topLeft"
          onConfirm={this.onSubmit}
        >
          <Button
            key="add"
            type="primary"
            loading={loading}
          >
            <span>確定</span>
          </Button>
        </Popconfirm>
        <Button
          key="cancle"
          onClick={onCancel}
        >
          <span>取消</span>
        </Button>
      </Footer>
    );
  }

  render() {
    const {
      editData, isCreateMode, visible,
      onCancel,
    } = this.props;
    const title = `${isCreateMode ? '新增' : '編輯'}黑名單`;
    return (
      <Modal
        title={title}
        visible={visible}
        footer={this.renderFooter()}
        destroyOnClose={true}
        onCancel={onCancel}
      >
        <Form
          ref={this.formRef}
          layout="vertical"
        >
          <FormItem
            label="使用者"
            name="uuid"
            initialValue={editData?.uuid}
            rules={[
              { required: true, message: '請選擇使用者' },
            ]}
          >
            <LiveMasterSelect disabled={!isCreateMode} />
          </FormItem>
          <FormItem
            label="備註"
            name="remark"
            initialValue={editData?.remark}
          >
            <Input />
          </FormItem>
          {!isCreateMode && (
            <FormItem
              name="id"
              initialValue={editData?.id}
              hidden
            >
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}

export default BlocklistFormModal;
