/**
 * helper functions
 */

export const createRequestTypes = baseType => ({
  REQUEST: `${baseType}_REQUEST`,
  SUCCESS: `${baseType}_SUCCESS`,
  FAILURE: `${baseType}_FAILURE`
});

/**
 * Authentication Types
 */
export const AUTH_INIT = createRequestTypes('AUTH_INIT');
export const AUTH_LOGIN = createRequestTypes('AUTH_LOGIN');
export const AUTH_LOGOUT = createRequestTypes('AUTH_LOGOUT');
export const AUTH_SERVER_ERROR = 'AUTH_SERVER_ERROR';

/**
 * Content Management
 */
export const LOAD_DAU_REPORTS = createRequestTypes('LOAD_DAU_REPORTS');
export const LOAD_MAU_REPORTS = createRequestTypes('LOAD_MAU_REPORTS');
export const LOAD_LIVE_MASTER_LIST = createRequestTypes(
  'LOAD_LIVE_MASTER_LIST'
);
export const LOAD_USER_EXPORT_DATA = createRequestTypes(
  'LOAD_USER_EXPORT_DATA'
);
