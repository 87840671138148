import { handleActions, createAction } from 'redux-actions';
import { pipe } from 'ramda';
import { pluck, switchMap, map } from 'rxjs/operators';
import { ofType, catchRequestError } from '../utils/extendOperators';
import { searchLiveMasterListAPI } from '../apis';

/**
 * Action Types
 */

const SEARCH_LIVE_MASTER_LIST = 'SEARCH_LIVE_MASTER_LIST';
const SEARCH_LIVE_MASTER_LIST_SUCCESS = 'SEARCH_LIVE_MASTER_LIST_SUCCESS';
const SEARCH_LIVE_MASTER_LIST_FAILURE = 'SEARCH_LIVE_MASTER_LIST_FAILURE';

/**
 * Action Creators
 */
export const searchLiveMasterList = createAction(SEARCH_LIVE_MASTER_LIST);
const searchLiveMasterListSuccess = createAction(
  SEARCH_LIVE_MASTER_LIST_SUCCESS
);
const searchLiveMasterListFailure = createAction(
  SEARCH_LIVE_MASTER_LIST_FAILURE
);

/**
 * Epics
 */

export const loadLiveMasterSearchListEpic = pipe(
  ofType(SEARCH_LIVE_MASTER_LIST),
  pluck('payload'),
  switchMap(payload =>
    searchLiveMasterListAPI(payload).pipe(
      map(searchLiveMasterListSuccess),
      catchRequestError(searchLiveMasterListFailure)
    )
  )
);

/**
 * Reducer
 */
const initialState = {
  page: 0,
  size: 5,
  isLoading: false,
  identityList: [],
  totalCount: 0,
  currentLiveMaster: {},
};
export default handleActions(
  {
    [SEARCH_LIVE_MASTER_LIST]: (state, action) => ({
      ...state,
      page: action.payload.page,
      size: action.payload.size,
      isLoading: true,
    }),
    [SEARCH_LIVE_MASTER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    [SEARCH_LIVE_MASTER_LIST_FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState
);
