import { prop } from 'ramda';
import { Manager } from 'socket.io-client';
import store from '../../store';

function InstantMessageServer() {
  let socket;

  function init() {
    const auth = prop('auth', store.getState());
    const { token, nonce } = auth;
    const server = process.env.REACT_APP_INSTANT_MESSAGE_SERVER;
    const namespace = process.env.REACT_APP_INSTANT_MESSAGE_NAMESPACE;

    const manager = new Manager(server, {
      upgrade: false,
      autoConnect: false,
      forceBase64: true,
      transports: ['polling'],
      transportOptions: {
        polling: {
          extraHeaders: {
            'X-Auth-Token': token,
            'X-Auth-Nonce': nonce,
          },
        },
      },
    });

    socket = manager.socket(namespace);

    socket.on('connect', () => {
      console.info(`Server is connected. SocketID：${socket.id}`);
    });

    socket.on('disconnect', () => {
      console.warn('Server is disconnected.');
    });

    socket.on('connect_error', (error) => {
      console.log(error);
    });

    connect();
  }

  function connect() {
    socket.connect();
  }

  function disconnect() {
    socket.disconnect();
  }

  function destroy() {
    socket.destroy();
    socket = null;
  }

  function on(event, handler) {
    socket.on(event, handler);
  }

  Object.defineProperty(this, 'init', {
    enumerable: true,
    value: init,
  });

  Object.defineProperty(this, 'disconnect', {
    enumerable: true,
    value: disconnect,
  });

  Object.defineProperty(this, 'destroy', {
    enumerable: true,
    value: destroy,
  });

  Object.defineProperty(this, 'on', {
    enumerable: true,
    value: on,
  });
}

export default new InstantMessageServer();
