import React, { Component } from 'react';

class Home extends Component {
  render() {
    return (
      <div>
        <h1>這個是首頁！！</h1>
      </div>
    );
  }
}

export default Home;
