import { isNil } from 'ramda';
import moment from 'moment';
import objHash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Table } from 'antd';
import { getUserBackpackList } from '../../reducers/userManagement';
import antdTablePagination from '../../utils/antdTablePagination';

const getDateFormat = value => {
  return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '無';
};

const formatBoolean = value => (value === 1 ? '是' : '否');

const getTimeleft = (expiredTime, record) => {
  if (record.availableDays === 99999) {
    return '永久';
  } else if (record.activated <= 0 && isNil(record.expiredTime)) {
    return '未啟用';
  } else {
    const now = moment();
    const exp = moment.unix(expiredTime);
    const diffDuration = moment.duration(exp.diff(now));
    if (exp.isAfter(now)) {
      const year = diffDuration.years() ? diffDuration.years() + '年' : '';
      const month = diffDuration.months() ? diffDuration.months() + '月' : '';
      const day = diffDuration.days() ? diffDuration.days() + '天' : '';
      const hour = diffDuration.hours() ? diffDuration.hours() + '小時' : '';
      const minute = diffDuration.minutes()
        ? diffDuration.minutes() + '分'
        : '';
      return `${year}${month}${day}${hour}${minute}`;
    } else {
      return '已失效';
    }
  }
};

const attributeMapping = {
  BADGE: '頭銜',
  BADGE_SIMPLE: '徽章',
  DEBUT: '進場特效',
  FRAME: '頭像框',
  STICKER: '魔法貼紙',
  GESTURE: '魔法手勢',
  FACESHOW: '魔法表情',
  BUBBLE: '留言框',
  USER_NICKNAME_RESET_CARD: '道具',
  GIFT: '背包禮物'
};

const typeMapping = {
  ACCOMPLISHMENT_EVENT: '獎勵特效',
  ACCOMPLISHMENT_SFX: '濾鏡特效',
  PLATFORM_PRODUCT: '道具',
  ACCOMPLISHMENT_BUBBLE: '留言框特效',
  ACCOMPLISHMENT_GIFT: '背包禮物'
};

const columns = [
  { title: '名稱', dataIndex: 'name' },
  {
    title: '屬性',
    dataIndex: 'attribute',
    render: value => attributeMapping[value] || value
  },
  { title: '是否為套裝', dataIndex: 'suited', render: formatBoolean },
  { title: '類型', dataIndex: 'type', render: value => typeMapping[value] },
  { title: '獲得時間', dataIndex: 'sendTime', render: getDateFormat },
  { title: '啟用時間', dataIndex: 'activatedTime', render: getDateFormat },
  {
    title: '效期',
    dataIndex: 'availableDays',
    render: value => (value === 99999 ? '永久型' : `${value} 天`)
  },
  {
    title: '發送立即啟用',
    dataIndex: 'activatedWhenSend',
    render: formatBoolean
  },
  {
    title: '剩餘時間',
    dataIndex: 'expiredTime',
    render: getTimeleft
  }
];

class UserBackpackListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: new antdTablePagination(),
    };
  }

  fetchUserBackpackList = () => {
    const { uuid, getUserBackpackList } = this.props;
    const { pagination } = this.state;
    getUserBackpackList({
      id: uuid,
      page: pagination.current,
      item: pagination.pageSize,
    });
  }

  onPageChange = (page) => {
    const { pagination } = this.state;
    const newPagination = {
      ...pagination,
      current: page,
    };
    this.setState({
      pagination: newPagination,
    }, this.fetchUserBackpackList);
  };

  render() {
    const {
      data, totalCount, visible,
      isLoading, onCancel,
    } = this.props;
    const { pagination } = this.state;
    return (
      <Modal
        width={'95%'}
        title="背包狀態"
        footer={null}
        visible={visible}
        destroyOnClose={true}
        onCancel={onCancel}
      >
        <Table
          rowKey={(rowData) => objHash(rowData)}
          scroll={{ y: 460 }}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={{
            ...pagination,
            total: totalCount,
            onChange: this.onPageChange,
          }}

        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { userManagement } = state;
  return {
    data: userManagement.backpack.data,
    totalCount: userManagement.backpack.totalCount,
    isLoading: userManagement.backpack.isLoading,
  };
};

const mapDispatchToProps = {
  getUserBackpackList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserBackpackListModal);
