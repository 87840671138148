import { combineActions, createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { switchMap, pluck, map, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getAppConfigAPI, setAppConfigAPI } from '../apis';
import { createRequestTypes } from '../actions/Types';
import { catchRequestError } from '../utils/extendOperators';

/**
 * Action Types
 */

const GET_APP_CONFIG = createRequestTypes('GET_APP_CONFIG');
const SET_APP_CONFIG = createRequestTypes('SET_APP_CONFIG');
const SHOW_APP_CONFIG_MODAL = 'SHOW_APP_CONFIG_MODAL';

/**
 * Action Creators
 */
export const getAppConfig = createAction(GET_APP_CONFIG.REQUEST);
export const getAppConfigSuccess = createAction(GET_APP_CONFIG.SUCCESS);
export const getAppConfigFailure = createAction(GET_APP_CONFIG.FAILURE);
export const setAppConfig = createAction(SET_APP_CONFIG.REQUEST);
export const setAppConfigSuccess = createAction(SET_APP_CONFIG.SUCCESS);
export const setAppConfigFailure = createAction(SET_APP_CONFIG.FAILURE);
export const showModal = createAction(
  SHOW_APP_CONFIG_MODAL,
  (isShowModal = true) => isShowModal
);

/**
 * Epics
 */

export const getAppConfigEpic = action$ =>
  action$.pipe(
    ofType(GET_APP_CONFIG.REQUEST),
    pluck('payload'),
    switchMap(() =>
      getAppConfigAPI().pipe(
        map(getAppConfigSuccess),
        catchRequestError(getAppConfigFailure)
      )
    )
  );

export const setAppConfigEpic = action$ =>
  action$.pipe(
    ofType(SET_APP_CONFIG.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      setAppConfigAPI(payload).pipe(
        mergeMap(e => [setAppConfigSuccess(e), getAppConfig()]),
        catchRequestError(setAppConfigFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  loading: false,
  isShowModal: false,
  data: {}
};

export default handleActions(
  {
    [combineActions(GET_APP_CONFIG.REQUEST, SET_APP_CONFIG.REQUEST)]: produce(
      draft => {
        draft.loading = true;
      }
    ),
    [GET_APP_CONFIG.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data.pageTheme;
      draft.isShowModal = false;
    }),
    [SET_APP_CONFIG.SUCCESS]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [GET_APP_CONFIG.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = {};
    }),
    [SET_APP_CONFIG.FAILURE]: produce(draft => {
      draft.loading = false;
    }),
    [SHOW_APP_CONFIG_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
