import React from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import {
  getLiveAppReviewList,
  checkLiveAppReview,
  openAccountModal,
  closeAccountModal,
} from '../reducers/liveApplicationReview';
import CreateAccountInfoModal from '../components/CreateAccountInfoModal';
import { PageContainer, PageTitle } from '../components/styled/page';

const { Column } = Table;

class LiveApplicationReview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { getLiveAppReviewList, pageSize, currentPage } = this.props;
    getLiveAppReviewList(pageSize, currentPage);
  }

  agreeLiveApplication = record => {
    const { checkLiveAppReview, openAccountModal, pageSize } = this.props;

    if (record.account) {
      checkLiveAppReview(record.identityId, 1, null, pageSize);
    } else {
      openAccountModal(record);
    }
  };

  agreeLiveApplicationWithAccount = data => {
    const { checkLiveAppReview, currentReviewItem, pageSize } = this.props;
    checkLiveAppReview(currentReviewItem.identityId, 1, data, pageSize);
  };

  refuseLiveApplication = record => {
    const { checkLiveAppReview, pageSize } = this.props;
    checkLiveAppReview(record.identityId, 2, null, pageSize);
  };

  onTableChange = pagination => {
    const { getLiveAppReviewList, pageSize } = this.props;
    getLiveAppReviewList(pageSize, pagination.current - 1);
  };

  renderActions = (record) => {
    return (
      <React.Fragment>
        <Button
          type="primary"
          onClick={() => {
            this.agreeLiveApplication(record);
          }}
        >
          <span>批准</span>
        </Button>
        <Button
          onClick={() => {
            this.refuseLiveApplication(record);
          }}
        >
          <span>拒絕</span>
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const {
      data,
      currentPage,
      pageSize,
      totalCount,
      isModalVisible,
      closeAccountModal,
    } = this.props;

    const pagination = {
      defaultCurrent: currentPage + 1,
      pageSize: pageSize,
      total: totalCount,
    };

    return (
      <PageContainer>
        <PageTitle>{'帳號管理 > 直播申請審核'}</PageTitle>
        <Table
          rowKey="identityId"
          dataSource={data}
          pagination={pagination}
          onChange={this.onTableChange}
        >
          <Column
            title="申請帳號"
            dataIndex="loginId"
          />
          <Column
            title="申請時間"
            dataIndex="applyAt"
          />
          <Column
            title="功能"
            render={this.renderActions}
          />
        </Table>
        <CreateAccountInfoModal
          modalTitle="新增 Account 資料"
          visible={isModalVisible}
          onSubmit={this.agreeLiveApplicationWithAccount}
          onClose={closeAccountModal}
        />
      </PageContainer>
    );
  }
}

export default connect(
  state => ({
    data: state.liveApplicationReview.data,
    currentPage: state.liveApplicationReview.currentPage,
    pageSize: state.liveApplicationReview.pageSize,
    totalCount: state.liveApplicationReview.totalCount,
    isModalVisible: state.liveApplicationReview.isModalVisible,
    currentReviewItem: state.liveApplicationReview.currentReviewItem,
  }),
  {
    getLiveAppReviewList,
    checkLiveAppReview,
    openAccountModal,
    closeAccountModal,
  }
)(LiveApplicationReview);
