import React from 'react';
import styled from 'styled-components';
import { Form, Input } from 'antd';

const FormItem = Form.Item;

const InfoForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .ant-form-explain {
    display: none;
  }
`;

class RevenueWithdrawInfo extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
    };
  }

  getFormData = () => {
    const form = this.formRef.current;
    return form.getFieldsValue();
  };

  getFieldValue = (field) => {
    const form = this.formRef.current;
    return form.getFieldValue(field);
  };

  validateFields = () => {
    const form = this.formRef.current;
    return form.validateFields();
  };

  render() {
    const { className } = this.props;
    return (
      <InfoForm
        ref={this.formRef}
        layout="inline"
        className={className}
      >
        <FormItem
          name="email"
          label="接收Email"
          rules={[
            { required: true, type: 'email' },
          ]}
        >
          <Input type="email" />
        </FormItem>
      </InfoForm>
    );
  }
}

export default RevenueWithdrawInfo;
