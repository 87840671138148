import React from 'react';
import { Modal, Form, Row, Col, Layout, Table, Spin } from 'antd';
import styled from 'styled-components';

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
`;

const StyledModal = styled(Modal)`
  animation-duration: 0s !important;
  transition: none !important;

  * {
    animation-duration: 0s !important;
    transition: none !important;
  }

  .pull-right {
    float: right;
  }

  .mt12 {
    margin-top: 12px;
  }

  .mb0 {
    margin-bottom: 0 !important;
  }

  .w70 {
    width: 90%;
  }

  .w100 {
    width: 100% !important;
  }
`;

const Divider = styled.div`
  display: block;
  clear: both;
  width: 95%;
  min-width: 95%;
  height: 1px;
  margin: 12px 0 24px 0;
  background: #e8e8e8;
`;

const FormItem = styled(Form.Item)`
  .ant-col-24.ant-form-item-label {
    padding-left: 15px;
  }
`;

const TableContainer = props => {
  return (
    <Row style={props.style}>
      <Col span={props.span}>
        <FormItem
          colon
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
        >
          {props.children}
        </FormItem>
      </Col>
    </Row>
  );
};

const Content = styled(Layout.Content)`
  padding: 0 16px 0 0;

  .ml3 {
    margin-left: 3px;
  }

  .mb12 {
    margin-bottom: 12px;
  }
`;

const StyledTable = styled(Table)`
  background: #fff;

  .ant-table-pagination.ant-pagination {
    margin-right: 16px;
  }
`;

const LinkButton = styled.span`
  cursor: pointer;
  color: #108ee9;

  &:hover {
    color: #40a9ff;
  }
`;

export {
  StyledSpin as Spin,
  StyledModal as Modal,
  Divider,
  TableContainer,
  LinkButton,
  StyledTable as Table,
  Content
};
