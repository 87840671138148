import moment from 'moment';
import { pipe } from 'ramda';
import { switchMap, map } from 'rxjs/operators';
import { ofType, catchRequestError } from '../utils/extendOperators';
import { createAction, handleActions } from 'redux-actions';
import { getPurchaseToolReportAPI } from '../apis';
import { dateOptions } from '../constants/moments.js';

/**
 * Action Types
 */
const GET_PAYMENT_TOOL_STATISTICS = 'GET_PAYMENT_TOOL_STATISTICS';
const GET_PAYMENT_TOOL_STATISTICS_SUCCESS =
  'GET_PAYMENT_TOOL_STATISTICS_SUCCESS';
const GET_PAYMENT_TOOL_STATISTICS_FAILURE =
  'GET_PAYMENT_TOOL_STATISTICS_FAILURE';

/**
 * Action Creators
 */
export const getPaymentToolStatistics = (
  startDate = dateOptions.week,
  endDate = moment()
    .endOf('day')
    .valueOf(),
  page = 0,
  size = 10,
  isCustomize = false
) =>
  createAction(GET_PAYMENT_TOOL_STATISTICS)({
    startDate,
    offsetInMillis: 28800000,
    endDate,
    page,
    size,
    isCustomize
  });

const getPaymentToolStatisticsSuccess = createAction(
  GET_PAYMENT_TOOL_STATISTICS_SUCCESS
);
const getPaymentToolStatisticsFailure = createAction(
  GET_PAYMENT_TOOL_STATISTICS_FAILURE
);

export const getPaymentToolStatisticsEpic = pipe(
  ofType(GET_PAYMENT_TOOL_STATISTICS),
  map(action => action.payload),
  switchMap(payload =>
    getPurchaseToolReportAPI(payload).pipe(
      map(getPaymentToolStatisticsSuccess),
      catchRequestError(getPaymentToolStatisticsFailure)
    )
  )
);

const initialState = {
  data: [],
  startDate: dateOptions.week,
  endDate: moment()
    .endOf('day')
    .valueOf(),
  isCustomize: false,
  currentPage: 0,
  totalCount: 0,
  pageSize: 10
};

export default handleActions(
  {
    [GET_PAYMENT_TOOL_STATISTICS]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [GET_PAYMENT_TOOL_STATISTICS_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data.map(item => ({ ...item })),
      totalCount: action.payload.totalCount
    })
  },
  initialState
);
