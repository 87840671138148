import React from 'react';
import AdManagement from './AdManagement';
import { PageContainer, PageTitle } from '../../components/styled/page';

const AdBanner = () => (
  <PageContainer>
    <PageTitle>{'首頁管理 > Ad Banner'}</PageTitle>
    <AdManagement zone="HOT" type="0" />
  </PageContainer>
);
export default AdBanner;
