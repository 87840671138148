import { Form } from '@ant-design/compatible';
import { Input, InputNumber, Switch } from 'antd';
import { evolve, pick } from 'ramda';
import React from 'react';
import brandName from '../../../constants/brandName';

const formItemLayout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 16
  }
};
const FormItem = Form.Item;
class RemittanceProductForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      platform: 'offline'
    };
  }

  isEditMode = () => {
    const { mode } = this.props;
    return mode === 'edit';
  };

  getFormData = () => {
    const { form } = this.props;
    return evolve({ status: Number }, form.getFieldsValue());
  };

  getFieldValue = field => {
    const { form } = this.props;
    return form.getFieldValue(field);
  };

  setFormData = data => {
    const { form } = this.props;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = evolve(
      { status: status => status === 1 },
      pick(fields, data)
    );
    form.setFieldsValue(values);
  };

  validateFields = callback => {
    const { form } = this.props;
    return form.validateFields(callback);
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
  };

  getProductId = () => {
    const { platform } = this.state;
    const {
      form: { getFieldsValue }
    } = this.props;
    const { points, price } = getFieldsValue(['points', 'price']);
    if (points && price) {
      return `${brandName.toLocaleLowerCase()}.${platform}.remittance.${points}.${price}`;
    } else {
      return undefined;
    }
  };

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { platform } = this.state;
    const isEditMode = this.isEditMode();

    return (
      <Form {...formItemLayout}>
        <FormItem label="商品點數" htmlFor="points">
          {getFieldDecorator('points', {
            rules: [{ required: true, message: '請輸入商品點數' }]
          })(
            <InputNumber
              id="points"
              min={0}
              style={{ width: '100%' }}
              placeholder="商品點數"
              disabled={isEditMode}
            />
          )}
        </FormItem>
        <FormItem label="商品金額" htmlFor="price">
          {getFieldDecorator('price', {
            rules: [{ required: true, message: '請輸入商品金額' }]
          })(
            <InputNumber
              id="price"
              min={0}
              style={{ width: '100%' }}
              placeholder="商品金額"
              disabled={isEditMode}
            />
          )}
        </FormItem>

        <FormItem label="商品名稱">
          {getFieldDecorator('productId', {
            initialValue: this.getProductId()
          })(<Input id="productId" disabled />)}
        </FormItem>
        <FormItem label="備註">
          {getFieldDecorator('remark')(
            <Input id="remark" placeholder="備註" />
          )}
        </FormItem>
        <FormItem label="啟用">
          {getFieldDecorator('status', {
            valuePropName: 'checked',
            initialValue: true
          })(<Switch id="status" />)}
        </FormItem>
        {getFieldDecorator('platform', { initialValue: [platform] })(
          <Input type="hidden" />
        )}
        {getFieldDecorator('productType', { initialValue: 'remittance' })(
          <Input type="hidden" />
        )}
        {getFieldDecorator('production', { initialValue: 1 })(
          <Input type="hidden" />
        )}
        {isEditMode ? getFieldDecorator('id')(<Input type="hidden" />) : null}
      </Form>
    );
  }
}

export default Form.create()(RemittanceProductForm);
