import {
  concat, filter, map,
  pick, pipe, prop,
  reduce, test, values,
} from 'ramda';
import memoize from 'memoize-one';
import React  from 'react';
import styled from 'styled-components';
import { Button, Input, Modal, Spin, Table } from 'antd';
import { isReallyEmpty } from '../../utils/mixin';
import antdTablePagination from '../../utils/antdTablePagination';

const { Search } = Input;

const Actions = styled.div`
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const filterByDeletedTime = pipe(
  filter(pipe(prop('deletedTime'), isReallyEmpty)),
  map(prop('uuid'))
);

const findByNicknameAndUUID = (text, data) => {
  if (isReallyEmpty(text)) return data;
  return filter(
    pipe(
      pick(['nickname', 'uuid']),
      values,
      reduce(concat, ''),
      test(RegExp(text, 'gi'))
    )
  )(data);
};

const getTextWithGrayColor = (value, record) =>
  isReallyEmpty(record.deletedTime) ? (
    value
  ) : (
    <span style={{ color: '#bfbfbf' }}>{value}</span>
  );

const columns = [{
  title: '帳號 UUID',
  dataIndex: 'uuid',
  render: getTextWithGrayColor,
}, {
  title: '暱稱',
  dataIndex: 'nickname',
  render: getTextWithGrayColor,
}, {
  title: '變更紀錄',
  dataIndex: 'deletedTime',
  render: (value) => value && '已收回',
}];

class DispatchUsersModal extends React.Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      selectedRowKeys: [],
      searchText: undefined,
      filterText: undefined,
      pagination: new antdTablePagination(),
    };

    this.state = { ...this.defaultState };
  }

  resetState = () => {
    const { defaultState } = this;
    this.setState({ ...defaultState });
  };

  filterByNameAndId = memoize((text, data) =>
    findByNicknameAndUUID(text, data)
  );

  /**
   * 點擊收回
   */
  onWithdrawClick = () => {
    const { selectedRowKeys } = this.state;
    this.props.onWithdraw(selectedRowKeys);
    this.resetState();
  };

  /**
   * 過濾清單
   */
  onSearchClick = (text) => {
    const { pagination } = this.state;
    const newPagination = { ...pagination, current: 1 };
    this.setState({
      filterText: text,
      pagination: newPagination,
    });
  };

  /**
   * 處理 Table 勾選
   */
  onSelectRowChange = (__, selectedRows) => {
    this.setState({
      selectedRowKeys: filterByDeletedTime(selectedRows)
    });
  };

  /**
   * 點選分頁
   */
  onPageChange = (page) => {
    const { pagination } = this.state;
    const newPagination = { ...pagination, current: page };
    this.setState({ pagination: newPagination });
  };

  /**
   * 關閉
   */
  onCancelClick = () => {
    this.resetState();
    this.props.onCancel();
  };

  render() {
    const { visible, totalCount, data, loading } = this.props;
    const { pagination, selectedRowKeys, searchText, filterText } = this.state;
    const filterData = this.filterByNameAndId(filterText, data);
    return (
      <Modal
        width={720}
        title="發送帳號"
        footer={null}
        visible={visible}
        destroyOnClose={true}
        onCancel={this.onCancelClick}
      >
        <Spin spinning={loading}>
          <Actions>
            <Button
              disabled={selectedRowKeys.length === 0}
              onClick={this.onWithdrawClick}
            >
              <span>收回發送</span>
            </Button>
            <Search
              allowClear={true}
              value={searchText}
              style={{ maxWidth: '280px' }}
              placeholder="請輸入帳號/暱稱"
              onChange={event =>
                this.setState({ searchText: event.target.value })
              }
              onSearch={this.onSearchClick}
              enterButton
            />
          </Actions>
          <Table
            rowKey="uuid"
            dataSource={filterData}
            columns={columns}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectRowChange
            }}
            pagination={{
              ...pagination,
              total: totalCount,
              onChange: this.onPageChange
            }}
          />
        </Spin>
      </Modal>
    );
  }
}
export default DispatchUsersModal;
