import { message } from 'antd';
import produce from 'immer';
import { groupBy, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, pluck, switchMap, map } from 'rxjs/operators';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { createRequestTypes } from '../actions/Types';
import {
  addProductInfoAPI,
  getProductInfoListAPI,
  setProductInfoAPI,
  deleteProductInfoAPI
} from '../apis';

/**Type */
const GET_PRODUCT_INFO_LIST = createRequestTypes('GET_PRODUCT_INFO_LIST');
const ADD_PRODUCT_INFO = createRequestTypes('ADD_PRODUCT_INFO');
const SET_PRODUCT_INFO = createRequestTypes('SET_PRODUCT_INFO');
const DELETE_PRODUCT_INFO = createRequestTypes('DELETE_PRODUCT_INFO');

/**
 * Action Creator
 */
export const getProductInfoList = createAction(
  GET_PRODUCT_INFO_LIST.REQUEST,
  (platform = 'offline') => platform
);
export const getProductInfoListSuccess = createAction(
  GET_PRODUCT_INFO_LIST.SUCCESS
);
export const getProductInfoListFailure = createAction(
  GET_PRODUCT_INFO_LIST.FAILURE
);

export const addProductInfo = createAction(ADD_PRODUCT_INFO.REQUEST);
export const addProductInfoSuccess = createAction(ADD_PRODUCT_INFO.SUCCESS);
export const addProductInfoFailure = createAction(ADD_PRODUCT_INFO.FAILURE);
export const setProductInfo = createAction(SET_PRODUCT_INFO.REQUEST);
export const setProductInfoSuccess = createAction(SET_PRODUCT_INFO.SUCCESS);
export const setProductInfoFailure = createAction(SET_PRODUCT_INFO.FAILURE);
export const deleteProductInfo = createAction(
  DELETE_PRODUCT_INFO.REQUEST,
  (ids = []) => ids
);
export const deleteProductInfoSuccess = createAction(
  DELETE_PRODUCT_INFO.SUCCESS
);
export const deleteProductInfoFailure = createAction(
  DELETE_PRODUCT_INFO.FAILURE
);

/**
 * Epics
 */
export const getProductInfoListEpic = action$ =>
  action$.pipe(
    ofType(GET_PRODUCT_INFO_LIST.REQUEST),
    pluck('payload'),
    switchMap(platform =>
      getProductInfoListAPI(platform).pipe(
        map(getProductInfoListSuccess),
        catchRequestError(getProductInfoListFailure)
      )
    )
  );

export const addProductInfoEpic = action$ =>
  action$.pipe(
    ofType(ADD_PRODUCT_INFO.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      addProductInfoAPI(payload).pipe(
        mergeMap(res => [addProductInfoSuccess(res), getProductInfoList()]),
        catchRequestError(addProductInfoFailure)
      )
    )
  );

export const setProductInfoEpic = action$ =>
  action$.pipe(
    ofType(SET_PRODUCT_INFO.REQUEST),
    pluck('payload'),
    mergeMap(payload =>
      setProductInfoAPI(payload).pipe(
        mergeMap(res => [setProductInfoSuccess(res), getProductInfoList()]),
        catchRequestError(setProductInfoFailure)
      )
    )
  );
// 目前頁面上沒有使用刪除功能，先實作起來
export const deleteProductInfoEpic = action$ =>
  action$.pipe(
    ofType(DELETE_PRODUCT_INFO.REQUEST),
    pluck('payload'),
    switchMap(ids => {
      return forkJoin(
        ids.map(id =>
          deleteProductInfoAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      );
    }),
    mergeMap(res => {
      const errors = groupBy(v => v.Status)(res);
      if (errors.Error?.length > 0) {
        message.error(
          `刪除失敗: ${errors.Error.map(
            e => `id:${e.id} message: ${e.Message}`
          ).join(', ')}`
        );
        return [deleteProductInfoFailure(), getProductInfoList()];
      } else {
        return [deleteProductInfoSuccess(), getProductInfoList()];
      }
    })
  );

/**
 * Reducer
 */
const initialState = {
  data: [],
  loading: false,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_PRODUCT_INFO_LIST.REQUEST,
      ADD_PRODUCT_INFO.REQUEST,
      SET_PRODUCT_INFO.REQUEST,
      DELETE_PRODUCT_INFO.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(
      ADD_PRODUCT_INFO.SUCCESS,
      SET_PRODUCT_INFO.SUCCESS
    )]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [GET_PRODUCT_INFO_LIST.SUCCESS]: produce((draft, { payload: { data } }) => {
      draft.loading = false;
      draft.data = data;
      draft.totalCount = data.length;
    }),
    [GET_PRODUCT_INFO_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [combineActions(
      ADD_PRODUCT_INFO.FAILURE,
      SET_PRODUCT_INFO.FAILURE,
      DELETE_PRODUCT_INFO.SUCCESS,
      DELETE_PRODUCT_INFO.FAILURE
    )]: produce(draft => {
      draft.loading = false;
    })
  },
  initialState
);
