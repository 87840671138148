import _ from 'lodash';
import React from 'react';
import { Select } from 'antd';
import { roles } from '../../constants/roles';

const Option = Select.Option;

const ROLES = _.pick(roles, [
  'ROLE_ADMIN',
  'ROLE_MASTER',
  'ROLE_USER',
  'ROLE_BUSINESS_MANAGER',
  'ROLE_LIVE_CONTROLLER',
  'ROLE_AGENCY_MANAGER',
  'ROLE_VOICE_MASTER',
  'ROLE_PRIVATE_VOICE_MASTER',
  'ROLE_OBS_MASTER'
]);

class RoleSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rolesEnum: Object.entries(ROLES)
    };
  }

  triggerChange = value => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  };

  onRoleSelected = value => {
    this.triggerChange(value);
  };

  render() {
    const { value } = this.props;
    const { rolesEnum } = this.state;
    return (
      <Select
        style={{ minWidth: '9rem' }}
        placeholder="請篩選角色"
        value={value}
        onChange={this.onRoleSelected}
        allowClear
      >
        {rolesEnum.map(([id, name]) => (
          <Option key={id} title={name}>
            {name}
          </Option>
        ))}
      </Select>
    );
  }
}

export default RoleSelector;
