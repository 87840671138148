import React from 'react';
import { Modal } from 'antd';
import TagManageForm from './TagManageForm';
import { createBackendTagAPI, updateBackendTagAPI } from '../../../apis';

const actions = {
  create: {
    title: '新增標籤',
    handler: 'createTag'
  },
  edit: {
    title: '編輯標籤',
    handler: 'updateTag'
  }
};

class TagManageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      action: null,
      visible: false
    };
  }

  show = (action, data) => {
    if (!actions[action]) {
      console.error(`TagManageModal: Could not support action "${action}"`);
      return;
    }

    this.setState({ action, visible: true }, () => {
      const { form } = this;
      form.reset();
      if (data) form.setFormData(data);
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  createTag = formData => {
    const { hide } = this;
    const { onCompleted } = this.props;

    const observable = createBackendTagAPI({
      groupId: formData.groups,
      label: formData.label,
      type: formData.type,
      color: formData.color
    });
    observable.subscribe(() => {
      onCompleted();
      hide();
    });
  };

  updateTag = formData => {
    const { hide } = this;
    const { onCompleted } = this.props;

    const observable = updateBackendTagAPI({
      id: formData.id,
      groupId: formData.groups,
      label: formData.label,
      type: formData.type,
      color: formData.color
    });
    observable.subscribe(() => {
      onCompleted();
      hide();
    });
  };

  onConfirm = () => {
    const { form } = this;
    const { action } = this.state;

    const iPromise = form.validateFields();
    iPromise.then((values) => {
      const handler = actions[action].handler;
      this[handler](values);
    });
  };

  render() {
    const { action, visible } = this.state;
    const meta = actions[action];
    const title = meta && meta.title;

    return (
      <Modal
        title={title}
        visible={visible}
        centered
        maskClosable
        onOk={this.onConfirm}
        onCancel={this.hide}
      >
        <TagManageForm
          ref={form => this.form = form}
        ></TagManageForm>
      </Modal>
    );
  }
}

export default TagManageModal;
