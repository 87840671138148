import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Avatar, Tabs } from 'antd';
import defaultAvatar from '../../constants/profileThumbnailPicture';
import { getVoiceChatRoom, getUserInfoAPI } from '../../apis';
import VoiceChatRoomContext from './VoiceChatRoomContext';
import VoiceChatRoomLive from './Live';
import VoiceChatRoomHistory from './History';

const { TabPane } = Tabs;

const ContentMap = new Map([
  [ 'Live', VoiceChatRoomLive ],
  [ 'History', VoiceChatRoomHistory ],
]);

const StyledSection = styled.section`
  min-width: 850px;
  margin-right: 1rem;
  padding-bottom: 1rem;
`;

const RoomHead = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
`;

const RoomInfo = styled.div`
  margin-left: 1rem;
  font-size: 1rem;

  p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

class VoiceChatRoom extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      roomId: _.get(props, 'match.params.id'),
      room: {},
      master: {},
      activeTab: 'Live',
      isReady: false,
      isPrivate: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
  }

  init = async () => {
    await this.fetchVoiceChatRoom();
    await this.fetchMasterInfo();

    const { master } = this.state;
    const isPrivate = !!_.find(master.roles, { name: 'ROLE_PRIVATE_VOICE_MASTER' });

    this.setState({ isPrivate, isReady: true });
  }

  getContextValue = () => {
    const { roomId, room, master, isPrivate } = this.state;
    return { roomId, room, master, isPrivate };
  }

  fetchVoiceChatRoom = () => {
    const { roomId } = this.state;
    const iPromise = getVoiceChatRoom(roomId).toPromise();
    iPromise.then((response) => {
      const { data: room } = response;
      this.setState({ room });
    });
    return iPromise;
  }

  fetchMasterInfo = () => {
    const { room } = this.state;
    const { masterId } = room;
    const iPromise = getUserInfoAPI(masterId).toPromise();
    iPromise.then((master) => {
      this.setState({ master });
    });
    return iPromise;
  }

  onTabChange = (tabKey) => {
    this.setState({ activeTab: tabKey });
  }

  renderRoomHead = () => {
    const { master, room } = this.state;
    const { nickname, profilePicture } = master;
    const avatar = profilePicture || defaultAvatar;
    return (
      <RoomHead>
        <Avatar size={64} src={avatar} />
        <RoomInfo>
          <p>{room.title}</p>
          <span>{nickname}</span>
        </RoomInfo>
      </RoomHead>
    );
  }

  renderTabs = () => {
    const { activeTab, isPrivate } = this.state;
    return (
      <Tabs
        animated={false}
        defaultActiveKey={activeTab}
        onChange={this.onTabChange}
      >
        <TabPane tab="即時監控" key="Live"></TabPane>
        {isPrivate && (
          <TabPane tab="場次記錄" key="History"></TabPane>
        )}
      </Tabs>
    );
  }

  renderTabContent = () => {
    const { activeTab, isReady } = this.state;
    const content = ContentMap.get(activeTab);
    const souldRender = isReady && content;
    return souldRender ? React.createElement(content) : null;
  }

  render() {
    const ContextValue = this.getContextValue();
    return (
      <VoiceChatRoomContext.Provider value={ContextValue}>
        <StyledSection>
          {this.renderRoomHead()}
          {this.renderTabs()}
          {this.renderTabContent()}
        </StyledSection>
      </VoiceChatRoomContext.Provider>
    );
  }
}

export default VoiceChatRoom;
