import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import classNames from 'classnames';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { getUserInfoAPI } from '../../../../../apis';
import ZegoUtils from '../../../../../services/zego/utils';
import defaultAvatar from '../../../../../constants/profileThumbnailPicture';
import VoiceChatLiveContext from '../../VoiceChatLiveContext';
import VoiceChatPlayer from './VoiceChatPlayer';

const Wrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
`;

const StatusButton = styled.button`
  margin-right: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: none;
  border: 0;
  border-radius: 50%;
  font-size: 1.2rem;

  i.xif-volume-up {
    color: #52C41A;
  }

  i.xif-volume-off {
    color: #CCCCCC;
  }

  i.xif-volume-mute {
    color: #FF4D4F;
  }
`;

const GhostAvatar = styled(Avatar)`
  flex-shrink: 0;
  user-select: none;
`;

const Nickname = styled.span`
  padding: 0 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

function initState(props) {
  const { dataset } = props;
  const { userId } = dataset;
  const isVacantSeat = !userId;
  return {
    user: {},
    isMuted: true,
    source: null,
    isVacantSeat,
  };
}

class SeatCard extends React.Component {

  static contextType = VoiceChatLiveContext;

  static propTypes = {
    dataset: PropTypes.object.isRequired,
  }

  static defaultProps = {
    dataset: {},
  }

  constructor(props) {
    super(props);

    this.state = initState(this.props);
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
  }

  init = () => {
    const { isVacantSeat } = this.state;
    if (isVacantSeat) return;

    this.fetchUserInfo();

    const { dataset } = this.props;
    const { userId, streamId } = dataset;
    const id = streamId || userId;
    const source = ZegoUtils.generateCDNUrl(id);
    this.setState({ source });
  }

  fetchUserInfo = () => {
    const { dataset } = this.props;
    const { userId } = dataset;
    const iPromise = getUserInfoAPI(userId).toPromise();
    iPromise.then((user) => {
      this.setState({ user });
    });
    return iPromise;
  }

  detectMute = () => {
    const { seatsMute } = this.context;
    const { userId } = this.props.dataset;
    if (!userId) return true;
    return seatsMute.indexOf(userId) >= 0;
  }

  detectStatusIcon = () => {
    const isMute = this.detectMute();
    const { isVacantSeat } = this.state;
    if (isVacantSeat) return 'xif-volume-off';
    return isMute ? 'xif-volume-mute' : 'xif-volume-up';
  }

  renderStatus = memoizeOne((seatsMute) => {
    const iconClass = this.detectStatusIcon();
    const cssClass = classNames('xif', iconClass);
    return (
      <StatusButton disabled>
        <i className={cssClass}></i>
      </StatusButton>
    );
  })

  renderPlayer = memoizeOne(({ seatsMute, couldPlay }) => {
    const { source } = this.state;
    const mute = this.detectMute();
    return couldPlay && source && !mute ? (
      <VoiceChatPlayer
        source={source}
        autoplay={true}
      />
    ) : null;
  })

  renderAvatar = () => {
    const { user } = this.state;
    const source = user.profilePicture || defaultAvatar;
    return (
      <GhostAvatar size={40} src={source} />
    );
  }

  renderNickName = () => {
    const { user } = this.state;
    const text = user.nickname || '空座';
    return (
      <Nickname>{text}</Nickname>
    );
  }

  render() {
    return (
      <Wrapper>
        <VoiceChatLiveContext.Consumer>
          {({ seatsMute }) => this.renderStatus(seatsMute)}
        </VoiceChatLiveContext.Consumer>
        <VoiceChatLiveContext.Consumer>
          {({ seatsMute, couldPlay }) => this.renderPlayer({ seatsMute, couldPlay })}
        </VoiceChatLiveContext.Consumer>
        {this.renderAvatar()}
        {this.renderNickName()}
      </Wrapper>
    );
  }
}

export default SeatCard;
