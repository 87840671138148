import { useState } from 'react';

export const useHasErrors = () => {
  const [hasErrors, setHasError] = useState(false);
  const validateStatus = (...args) => {
    const allFields = args.pop();
    setHasError(allFields.filter(({ errors }) => errors.length).length > 0);
  };
  return [hasErrors, setHasError, validateStatus];
};
