import brandName from '../../constants/brandName';

export const TypesEnum = Object.freeze({
  NICKNAME: 'nick_name',
  LOGIN_ID: 'login_id',
  UUID: 'uuid',
  OOPSVIP_ID: 'true_love_id',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  ALL: 'all',
});

export const TypesNameMap = Object.freeze({
  [TypesEnum.NICKNAME]: '暱稱',
  [TypesEnum.LOGIN_ID]: 'Login ID',
  [TypesEnum.UUID]: 'UUID',
  [TypesEnum.OOPSVIP_ID]: `${brandName} ID`,
  [TypesEnum.EMAIL]: 'E-mail',
  [TypesEnum.PHONE_NUMBER]: '電話號碼',
  [TypesEnum.ALL]: '查詢全部',
});

export const ValueFieldEnum = Object.freeze({
  OOPSVIP_ID: 'OOPSVIP_ID',
  USER_ID: 'USER_ID',
});

export const ValuePathMap = Object.freeze({
  OOPSVIP_ID: 'trueLoveId',
  USER_ID: 'id',
});
