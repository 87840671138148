import { combineEpics } from 'redux-observable';
import {
  loginEpic,
  logoutEpic,
  loginInitEpic,
  getLiveMasterPersonalInfoEpic,
  changePasswordEpic,
  getAccountRightListEpic
} from './auth';
import {
  loadDauReportsEpic,
  loadMauReportsEpic,
  loadLiveMasterListEpic,
  loadUserExportDataEpic
} from './contentManagement';
import { loadLiveMasterSearchListEpic } from './accountManagement';
import { getPaymentToolStatisticsEpic } from './paymentToolStatistics';
import {
  getLiveMasterInfoEpic,
  getCurrencyListEpic,
  sendLiveMasterInfoEpic,
  showSubmitErrorEpic
} from './liveMasterEditForm';
import { getRegistrationStaffListEpic } from './registrationStaffGrowth';
import { getPerformanceGrowthListEpic } from './performanceGrowth';
import {
  getGiftListEpic,
  addGiftEpic,
  setGiftEpic,
  deleteGiftEpic,
  changeStatusEpic,
  changeCategoryIdEpic,
  getGiftRandomEpic,
  addGiftRandomtEpic,
  setEGiftRandomtEpic
} from './giftList';
import {
  getGiftListCategoryEpic,
  addGiftListCategoryEpic,
  deleteGiftListCategoryEpic
} from './giftListCategories';
import { getPhotoListEpic, removePhotoListEpic } from './photoManagement';
import {
  getLiveAppReviewListEpic,
  checkLiveAppReviewEpic
} from './liveApplicationReview';
import {
  getManualSettlementListEpic,
  sendSettlementListEpic
} from './manualSettlement';
import {
  getUserListEpic,
  getUserInfoEpic,
  sendUserInfoEpic,
  editUserPointEpic,
  modifyAccountEpic,
  modifyRoleEpic,
  getUserUsePointHistoryEpic,
  getUserSavePointHistoryEpic,
  disableUserEpic,
  openAccountModalEpic,
  resetNicknameEpic,
  getNicknameHistoryListEpic,
  getUserBackpackListEpic
} from './userManagement';
import { searchAgencyByKeywordEpic } from './agencyList';
import { searchLiveMasterByKeywordEpic } from './liveMasterList';
import {
  getAgencyListEpic,
  addAgencyEpic,
  getAgencyEpic,
  setAgencyEpic,
  deleteAgencyEpic
} from './agencyManagement';
import {
  getAgencyMasterListEpic,
  addAgencyMasterEpic,
  getAgencyMasterEpic,
  setAgencyMasterEpic,
  replaceAgencyMasterContractEpic,
  deleteAgencyMasterEpic
} from './agencyMasterManagement';
import { getLiveViewerListEpic } from './liveViewerStatistics';
import {
  getSystemAnnouncementListEpic,
  pushNotificationEpic,
  getAnnouncementListEpic,
  getCoverageEpic,
  getTagListEpic,
  addAnnouncementEpic,
  getAnnouncementEpic,
  modifyAnnouncementEpic,
  delectAnnouncementEpic
} from './systemAnnouncementPush';
import { getGiftReportEpic } from './giftReport';
import {
  getAdBannerListEpic,
  deleteAdBannersEpic,
  addAdBannerEpic,
  setAdBannerEpic
} from './adManagement';
import {
  getSpotlightListEpic,
  deleteSpotlightsEpic,
  addSpotlightEpic,
  setSpotlightEpic
} from './spotlightManagement';
import {
  getWelcomeBannerListEpic,
  deleteWelcomeBannerEpic,
  submitWelcomeBannerListEpic
} from './overlayAdManagement';
import {
  getAccountSheetTWDEpic,
  getAccountSheetUSDEpic,
  getUserConsumeSheetEpic,
  getLiveMasterHoursSheetEpic,
  getLiveMasterNetworkSpeedSheetEpic
} from './accountSheetExport';
import { getPointRatioEpic, setPointRatioEpic } from './pointRatio';
import {
  getEventCategoryEpic,
  addEventCategoryEpic,
  deleteEventCategoryEpic
} from './eventCategory';
import { getAvatarEpic, addAvatarEpic, deleteAvatarEpic } from './avatar';
import { getOrderEpic } from './purchaseOrderList';
import { epics as giftBoxEpics } from './giftBoxManagement';
import {
  getAnimationEffectListEpic,
  addAnimationEffectEpic,
  delectAnimationEffectEpic,
  modifyAnimationEffectEpic
} from './animationEffectManagement';
import {
  getRewardListEpic,
  addRewardEpic,
  delectRewardEpic,
  modifyRewardEpic
} from './rewardManagement';

import {
  getAchievementEpic,
  addAchievementEpic,
  modifyAchievementEpic,
  delectAchievementEpic
} from './achievementManagement';

import {
  getAssignedAchievementListEpic,
  addAssignedAchievementEpic,
  modifyAssignedAchievementEpic,
  delectAssignedAchievementEpic
} from './assignedAchievements';
import {
  getSupportCategoryEpic,
  getSupportListEpic,
  getSupportEpic,
  setSupportEpic
} from './enquiryManagement';
import { addMessageEpic } from './messageManagement';
import { getRoomListEpic } from './messageRoomList';
import { getDialogEpic } from './messageDialog';
import {
  getRemittanceListEpic,
  getRemittanceEpic,
  addRemittanceEpic,
  setRemittanceEpic,
  allowRemittanceEpic,
  setInvoiceEpic
} from './remittanceManagement';
import { setSeriesListEpic, getSeriesListEpic } from './seriesManagement';
import {
  addVideoEpic,
  deleteVideoEpic,
  getVideoListEpic,
  setVideoEpic
} from './videoManagement';
import {
  getPastEventListEpic,
  addPastEventEpic,
  setPastEventEpic,
  deletePastEventEpic
} from './pastEventManagement';
import {
  getPointTransferListEpic,
  addPointTransferEpic,
  downloadPointTransferListEpic,
  downloadPointTransferWithOutRatioEpic
} from './pointTransfer';
import { getAppConfigEpic, setAppConfigEpic } from './appConfigManagement';
import {
  addProductInfoEpic,
  deleteProductInfoEpic,
  getProductInfoListEpic,
  setProductInfoEpic
} from './remittanceProductManagement';
import { addAccountEpic } from './accountCreationManagement';
import {
  getBlocklistListEpic,
  addBlocklistEpic,
  setBlocklistEpic,
  deleteBlocklistEpic
} from './blocklistManagement';
import { getVoiceChatRoomRankingEpic } from './voiceChatRoomReport';
import {
  getEffectListEpic,
  addEffectEpic,
  getEffectEpic,
  setEffectEpic,
  deleteEffectEpic
} from './effectManagement';
import {
  getDispatchListEpic,
  addDispatchEpic,
  getDispatchEpic,
  setDispatchEpic,
  deleteDispatchEpic,
  getTagsListEpic,
  getDispatchUserEpic,
  withdrawDispatchUserEpic
} from './dispatchManagement';
import {
  getContractListEpic,
  addContractEpic,
  getContractEpic,
  setContractEpic,
  deleteContractEpic
} from './contractManagement';
import {
  getPrivateVoiceChatSpotlightListEpic,
  deletePrivateVoiceChatSpotlightsEpic,
  addPrivateVoiceChatSpotlightEpic,
  getPrivateVoiceChatSpotlightEpic,
  setPrivateVoiceChatSpotlightEpic
} from './privateVoiceChatSpotlightManagement';
import {
  getBottomTabBarListEpic,
  deleteBottomTabBarEpic,
  addBottomTabBarEpic,
  getBottomTabBarEpic,
  setBottomTabBarEpic
} from './bottomTabbarManagement';
import {
  getFloatingSlotListEpic,
  deleteFloatingSlotEpic,
  addFloatingSlotEpic,
  getFloatingSlotEpic,
  setFloatingSlotEpic
} from './floatingSlotManagement';
import {
  getSigninEventListEpic,
  deleteSigninEventEpic,
  addSigninEventEpic,
  getSigninEventEpic,
  setSignInMissionEpic,
  getContinuityEventListEpic
} from './signinEventManagement';
import {
  getLiveMasterRankingEpic,
  getGiftGiverRankingEpic
} from './queryRankingList';
import { getOutsourcePointTransferListEpic } from './outsourcePointTransfer';
export default combineEpics(
  loginEpic,
  logoutEpic,
  loginInitEpic,
  getLiveMasterPersonalInfoEpic,
  changePasswordEpic,
  getAccountRightListEpic,
  loadDauReportsEpic,
  loadMauReportsEpic,
  loadLiveMasterListEpic,
  loadUserExportDataEpic,
  getLiveMasterInfoEpic,
  sendLiveMasterInfoEpic,
  showSubmitErrorEpic,
  getCurrencyListEpic,
  loadLiveMasterSearchListEpic,
  getPaymentToolStatisticsEpic,
  getRegistrationStaffListEpic,
  getPerformanceGrowthListEpic,
  getGiftListEpic,
  addGiftEpic,
  setGiftEpic,
  deleteGiftEpic,
  getPhotoListEpic,
  removePhotoListEpic,
  getLiveAppReviewListEpic,
  checkLiveAppReviewEpic,
  getManualSettlementListEpic,
  sendSettlementListEpic,
  searchLiveMasterByKeywordEpic,
  searchAgencyByKeywordEpic,
  getUserListEpic,
  getUserInfoEpic,
  sendUserInfoEpic,
  editUserPointEpic,
  modifyAccountEpic,
  modifyRoleEpic,
  getUserUsePointHistoryEpic,
  getUserSavePointHistoryEpic,
  disableUserEpic,
  openAccountModalEpic,
  resetNicknameEpic,
  getNicknameHistoryListEpic,
  getUserBackpackListEpic,
  getSystemAnnouncementListEpic,
  pushNotificationEpic,
  getLiveViewerListEpic,
  getGiftListCategoryEpic,
  addGiftListCategoryEpic,
  deleteGiftListCategoryEpic,
  changeStatusEpic,
  changeCategoryIdEpic,
  getGiftRandomEpic,
  addGiftRandomtEpic,
  setEGiftRandomtEpic,
  getGiftReportEpic,
  getAdBannerListEpic,
  deleteAdBannersEpic,
  addAdBannerEpic,
  setAdBannerEpic,
  getSpotlightListEpic,
  deleteSpotlightsEpic,
  addSpotlightEpic,
  setSpotlightEpic,
  getWelcomeBannerListEpic,
  deleteWelcomeBannerEpic,
  submitWelcomeBannerListEpic,
  getAccountSheetTWDEpic,
  getAccountSheetUSDEpic,
  getUserConsumeSheetEpic,
  getLiveMasterHoursSheetEpic,
  getLiveMasterNetworkSpeedSheetEpic,
  getPointRatioEpic,
  setPointRatioEpic,
  getEventCategoryEpic,
  addEventCategoryEpic,
  deleteEventCategoryEpic,
  getAvatarEpic,
  addAvatarEpic,
  deleteAvatarEpic,
  getOrderEpic,
  ...giftBoxEpics,
  getAnimationEffectListEpic,
  addAnimationEffectEpic,
  delectAnimationEffectEpic,
  modifyAnimationEffectEpic,
  getRewardListEpic,
  addRewardEpic,
  delectRewardEpic,
  modifyRewardEpic,
  getAchievementEpic,
  addAchievementEpic,
  delectAchievementEpic,
  modifyAchievementEpic,
  getAssignedAchievementListEpic,
  addAssignedAchievementEpic,
  modifyAssignedAchievementEpic,
  delectAssignedAchievementEpic,
  getAnnouncementListEpic,
  getCoverageEpic,
  getTagListEpic,
  addAnnouncementEpic,
  getAnnouncementEpic,
  modifyAnnouncementEpic,
  delectAnnouncementEpic,
  getSupportListEpic,
  getSupportEpic,
  setSupportEpic,
  getSupportCategoryEpic,
  addMessageEpic,
  getRoomListEpic,
  getDialogEpic,
  getRemittanceListEpic,
  getRemittanceEpic,
  addRemittanceEpic,
  setRemittanceEpic,
  allowRemittanceEpic,
  setInvoiceEpic,
  getSeriesListEpic,
  setSeriesListEpic,
  getVideoListEpic,
  addVideoEpic,
  setVideoEpic,
  deleteVideoEpic,
  getPastEventListEpic,
  addPastEventEpic,
  setPastEventEpic,
  deletePastEventEpic,
  getPointTransferListEpic,
  addPointTransferEpic,
  downloadPointTransferListEpic,
  downloadPointTransferWithOutRatioEpic,
  getAppConfigEpic,
  setAppConfigEpic,
  addProductInfoEpic,
  deleteProductInfoEpic,
  getProductInfoListEpic,
  setProductInfoEpic,
  addAccountEpic,
  getBlocklistListEpic,
  addBlocklistEpic,
  setBlocklistEpic,
  deleteBlocklistEpic,
  getVoiceChatRoomRankingEpic,
  getEffectListEpic,
  addEffectEpic,
  getEffectEpic,
  setEffectEpic,
  deleteEffectEpic,
  getDispatchListEpic,
  addDispatchEpic,
  getDispatchEpic,
  setDispatchEpic,
  deleteDispatchEpic,
  getTagsListEpic,
  getDispatchUserEpic,
  withdrawDispatchUserEpic,
  getPrivateVoiceChatSpotlightListEpic,
  deletePrivateVoiceChatSpotlightsEpic,
  addPrivateVoiceChatSpotlightEpic,
  getPrivateVoiceChatSpotlightEpic,
  setPrivateVoiceChatSpotlightEpic,
  getBottomTabBarListEpic,
  deleteBottomTabBarEpic,
  addBottomTabBarEpic,
  getBottomTabBarEpic,
  setBottomTabBarEpic,
  getContractListEpic,
  addContractEpic,
  getContractEpic,
  setContractEpic,
  deleteContractEpic,
  getAgencyListEpic,
  addAgencyEpic,
  getAgencyEpic,
  setAgencyEpic,
  deleteAgencyEpic,
  getAgencyMasterListEpic,
  addAgencyMasterEpic,
  getAgencyMasterEpic,
  setAgencyMasterEpic,
  replaceAgencyMasterContractEpic,
  deleteAgencyMasterEpic,
  getFloatingSlotListEpic,
  deleteFloatingSlotEpic,
  addFloatingSlotEpic,
  getFloatingSlotEpic,
  setFloatingSlotEpic,
  getSigninEventListEpic,
  deleteSigninEventEpic,
  addSigninEventEpic,
  getSigninEventEpic,
  setSignInMissionEpic,
  getContinuityEventListEpic,
  getLiveMasterRankingEpic,
  getGiftGiverRankingEpic,
  getOutsourcePointTransferListEpic
);
