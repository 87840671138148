function Channel(socket, topic) {
  const core = socket.channel(topic);
  core.join();

  function push(event, payload, timeout) {
    socket.push({
      event,
      payload,
      timeout,
      topic: core.topic,
    });
  }

  Object.defineProperty(this, 'join', {
    enumerable: true,
    value: core.join.bind(core),
  });

  Object.defineProperty(this, 'onClose', {
    enumerable: true,
    value: core.onClose.bind(core),
  });

  Object.defineProperty(this, 'onError', {
    enumerable: true,
    value: core.onError.bind(core),
  });

  Object.defineProperty(this, 'on', {
    enumerable: true,
    value: core.on.bind(core),
  });

  Object.defineProperty(this, 'off', {
    enumerable: true,
    value: core.off.bind(core),
  });

  Object.defineProperty(this, 'push', {
    enumerable: true,
    value: push,
  });

  Object.defineProperty(this, 'leave', {
    enumerable: true,
    value: core.leave.bind(core),
  });
}

export default Channel;