import { createAction, handleActions } from 'redux-actions';
import { pipe, dissoc } from 'ramda';
import { message } from 'antd';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ofType, catchRequestError } from '../utils/extendOperators';
import { getLiveAppReviewListAPI, checkLiveAppReviewAPI } from '../apis';

/**
 * Action Types
 */
const GET_LIVE_APP_REVIEW_LIST = 'GET_LIVE_APP_REVIEW_LIST';
const GET_LIVE_APP_REVIEW_LIST_SUCCESS = 'GET_LIVE_APP_REVIEW_LIST_SUCCESS';
const GET_LIVE_APP_REVIEW_LIST_FAILURE = 'GET_LIVE_APP_REVIEW_LIST_FAILURE';
const CHECK_LIVE_APP_REVIEW = 'REFUSE_LIVE_APP_REVIEW';
const CHECK_LIVE_APP_REVIEW_SUCCESS = 'CHECK_LIVE_APP_REVIEW_SUCCESS';
const CHECK_LIVE_APP_REVIEW_FAILURE = 'CHECK_LIVE_APP_REVIEW_FAILURE';
const CLOSE_ACCOUNT_MODAL = 'CLOSE_ACCOUNT_MODAL';
const OPEN_ACCOUNT_MODAL = 'OPEN_ACCOUNT_MODAL';

/**
 * Action Creators
 */
export const getLiveAppReviewList = (pageSize, currentPage) =>
  createAction(GET_LIVE_APP_REVIEW_LIST)({ pageSize, currentPage });
const getLiveAppReviewListSuccess = createAction(
  GET_LIVE_APP_REVIEW_LIST_SUCCESS
);
const getLiveAppReviewListFailure = createAction(
  GET_LIVE_APP_REVIEW_LIST_FAILURE
);

export const checkLiveAppReview = (id, status, account, pageSize = 5) =>
  createAction(CHECK_LIVE_APP_REVIEW)({ id, status, account, pageSize });

const checkLiveAppReviewSuccess = createAction(CHECK_LIVE_APP_REVIEW_SUCCESS);
const checkLiveAppReviewFailure = createAction(CHECK_LIVE_APP_REVIEW_FAILURE);
export const openAccountModal = createAction(OPEN_ACCOUNT_MODAL);
export const closeAccountModal = createAction(CLOSE_ACCOUNT_MODAL);

/**
 * Epics
 */
export const getLiveAppReviewListEpic = pipe(
  ofType(GET_LIVE_APP_REVIEW_LIST),
  map(action => action.payload),
  switchMap(({ pageSize, currentPage }) =>
    getLiveAppReviewListAPI(pageSize, currentPage).pipe(
      map(getLiveAppReviewListSuccess),
      catchRequestError(getLiveAppReviewListFailure)
    )
  )
);

const removeIdAndPageSize = pipe(dissoc('id'), dissoc('pageSize'));
const messgaeStatusMapping = {
  1: '批准',
  2: '拒絕',
};

export const checkLiveAppReviewEpic = pipe(
  ofType(CHECK_LIVE_APP_REVIEW),
  map(action => action.payload),
  switchMap(payload =>
    checkLiveAppReviewAPI(payload.id, removeIdAndPageSize(payload)).pipe(
      tap(() => {
        message.success(`審核${messgaeStatusMapping[payload.status]}成功`);
      }),
      mergeMap(res => [
        checkLiveAppReviewSuccess(res),
        getLiveAppReviewList(payload.pageSize, 0),
      ]),
      catchRequestError(
        pipe(error => {
          message.error(`審核${messgaeStatusMapping[payload.status]}失敗`);
          return error;
        }, checkLiveAppReviewFailure)
      )
    )
  )
);

const initialState = {
  data: [],
  currentPage: 0,
  totalCount: 0,
  pageSize: 10,
  currentReviewItem: {},
  isModalVisible: false,
};

export default handleActions(
  {
    [GET_LIVE_APP_REVIEW_LIST]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [GET_LIVE_APP_REVIEW_LIST_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      totalCount: action.payload.totalCount,
    }),
    [OPEN_ACCOUNT_MODAL]: (state, action) => ({
      ...state,
      currentReviewItem: action.payload,
      isModalVisible: true,
    }),
    [CLOSE_ACCOUNT_MODAL]: state => ({
      ...state,
      isModalVisible: false,
    }),
  },
  initialState
);
