import React from 'react';
import styled from 'styled-components';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';

const { Option } = Select;
const { TextArea } = Input;

const FormItem = styled(Form.Item)`
  display: flex !important;
  margin: 0 0 1rem 0 !important;

  .ant-form-item-control-wrapper {
    flex-grow: 1;
  }
`;

class WithdrawForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  getFormData = () => {
    const { form } = this.props;
    return form.getFieldsValue();
  };

  getFieldValue = field => {
    const { form } = this.props;
    return form.getFieldValue(field);
  };

  validateFields = callback => {
    const { form } = this.props;
    return form.validateFields(callback);
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.onSubmit} layout="inline">
        <FormItem label="結算原因">
          {getFieldDecorator('reason', {
            initialValue: 'undefined',
            rules: [{ required: true, message: '請選擇結算原因' }]
          })(
            <Select>
              <Option value="undefined">未定義</Option>
              <Option value="role_change">身份變更</Option>
              <Option value="contract_change">合約變更</Option>
              <Option value="routine_withdraw">定期結算</Option>
            </Select>
          )}
        </FormItem>
        <FormItem label="結算備註">
          {getFieldDecorator('remark')(
            <TextArea></TextArea>
          )}
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(WithdrawForm);
