import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { switchMap, pluck, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getRoomListAPI } from '../apis';
import { createRequestTypes } from '../actions/Types';
import { catchRequestError } from '../utils/extendOperators';

/**
 * Action Types
 */
const GET_ROOM_LIST = createRequestTypes('GET_ROOM_LIST');

/**
 * Action Creators
 */
export const getRoomList = createAction(GET_ROOM_LIST.REQUEST);
export const getRoomListSuccess = createAction(GET_ROOM_LIST.SUCCESS);
export const getRoomListFailure = createAction(GET_ROOM_LIST.FAILURE);

/**
 * Epics
 */
export const getRoomListEpic = action$ =>
  action$.pipe(
    ofType(GET_ROOM_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getRoomListAPI(payload).pipe(
        map(getRoomListSuccess),
        catchRequestError(getRoomListFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  loading: false,
  data: [],
  item: 10,
  totalCount: 0
};

export default handleActions(
  {
    [GET_ROOM_LIST.REQUEST]: produce(draft => {
      draft.loading = true;
    }),
    [GET_ROOM_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
    }),
    [GET_ROOM_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    })
  },
  initialState
);
