import { isEmpty } from 'ramda';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Input } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { getYoutubeDetailByIdAPI } from '../../apis';
import { isReallyEmpty } from '../../utils/mixin';

const getYoutubeIdByUrl = url => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : url;
};
const getSeconds = value => moment.duration(value).asSeconds();
const defaultOnchange = () => {};
const YoutubeIDInput = forwardRef(
  ({ value, disabled = false, ...props }, ref) => {
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [videoId, setVideoId] = useState(
      getYoutubeIdByUrl(value?.videoUrl || '')
    );
    const [duration, setDuration] = useState(value?.duration || undefined);
    const [onChange] = useState(() => props?.onChange || defaultOnchange);
    const onSearch$ = useRef(new Subject());
    const onYoutuberDetailLoaded = useCallback(payload => {
      if (isEmpty(payload.items)) {
        setLoading(false);
        setHasError(true);
        setDuration(null);
      } else {
        setLoading(false);
        setHasError(false);
        setDuration(getSeconds(payload?.items[0]?.contentDetails?.duration));
      }
    }, []);

    const triggerChange = useCallback(
      value => {
        if (onChange && !disabled) {
          onChange(value);
        }
      },
      [onChange, disabled]
    );

    useEffect(() => {
      const subscribe = () => {
        return onSearch$.current
          .pipe(
            filter(value => !isEmpty(value.trim())),
            tap(() => {
              setLoading(true);
            }),
            debounceTime(400),
            switchMap(getYoutubeDetailByIdAPI)
          )
          .subscribe(
            payload => onYoutuberDetailLoaded(payload),
            () => {
              setLoading(false);
              setHasError(true);
            },
            () => {
              setLoading(false);
            }
          );
      };
      const subscription = subscribe();
      return () => {
        subscription.unsubscribe();
      };
    }, [onYoutuberDetailLoaded]);

    useEffect(() => {
      onSearch$.current.next(videoId);
    }, [videoId]);

    useEffect(() => {
      if (!isEmpty(videoId)) {
        triggerChange({
          videoUrl: `https://www.youtube.com/watch?v=${videoId}`,
          duration
        });
      }
    }, [duration, videoId, loading, triggerChange]);

    const onInputChange = e => {
      const id = getYoutubeIdByUrl(e?.target?.value);
      setVideoId(id);
      setDuration(undefined);
      if (isEmpty(id)) {
        triggerChange(null);
      }
    };

    return (
      <Input
        value={videoId}
        ref={ref}
        onChange={onInputChange}
        placeholder="請輸入 Youtube 影片 ID"
        prefix={
          loading ? (
            <LoadingOutlined />
          ) : isReallyEmpty(videoId) ? (
            <YoutubeOutlined />
          ) : hasError ? (
            <CloseCircleTwoTone twoToneColor="#eb2f96" />
          ) : (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          )
        }
      />
    );
  }
);
export default YoutubeIDInput;
