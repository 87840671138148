import { DatePicker, Radio } from 'antd';
import moment from 'moment';
import { all, complement, is, isNil } from 'ramda';
import React, { forwardRef, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { isReallyEmpty } from '../../utils/mixin';

const Wrapper = styled.div`
  label {
    flex-shrink: 0;
  }
`;

const DateWarpper = styled.div`
  .ant-input {
    border: 1px solid #d9d9d9 !important;
  }
`;
const RangePicker = DatePicker.RangePicker;
const PlaceHolder = ['上架日期', '下架日期'];
const SFXDate = memo(
  forwardRef(({ value, onChange, disabled }, ref) => {
    const [durationType, setDurationType] = useState();
    const [enableDate, setEnableDate] = useState(false);
    const [dates, setDates] = useState([]);

    const handleDatesRangeChange = value => {
      triggerChange(value);
    };

    const handleDurationTypeChange = ({ target: { value } }) => {
      setEnableDate(value === 'temporary');
      if (value === 'forever') {
        setDurationType('forever');
        onChange(value);
      } else {
        setDurationType('temporary');
        if (isReallyEmpty(dates)) {
          onChange(value);
        } else {
          triggerChange(dates);
        }
      }
    };

    const triggerChange = (dateRange = []) => {
      if (dateRange.length === 2 && all(complement(isNil))(dateRange)) {
        onChange([dateRange[0].unix(), dateRange[1].unix()]);
      } else {
        onChange([]);
      }
    };

    useEffect(() => {
      if (value === 'forever' || value === 'temporary') {
        setEnableDate(value === 'temporary');
        setDurationType(value);
      } else if (is(Array, value)) {
        setDates(value.map(moment.unix));
        setEnableDate(true);
        setDurationType('temporary');
      }
    }, [value]);

    useEffect(() => {
      if (disabled) {
        setDurationType(undefined);
        setEnableDate(false);
        setDates([]);
      }
    }, [disabled]);

    return (
      <Wrapper ref={ref}>
        <Radio.Group
          disabled={disabled}
          onChange={handleDurationTypeChange}
          value={durationType}
          style={{ display: 'flex', alignItems: 'start' }}
        >
          <Radio value="forever">永久型</Radio>
          <Radio value="temporary">
            暫時型
            <DateWarpper>
              <RangePicker
                value={dates}
                disabled={!enableDate || disabled}
                onChange={handleDatesRangeChange}
                style={{ width: 370, margin: '8px 0' }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'),
                    moment('23:59:59', 'HH:mm:ss')
                  ]
                }}
                placeholder={PlaceHolder}
              />
            </DateWarpper>
          </Radio>
        </Radio.Group>
      </Wrapper>
    );
  })
);
export default SFXDate;
