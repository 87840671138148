import {
  Button,
  Col,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Tabs,
  Checkbox,
  Popconfirm
} from 'antd';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import {
  always,
  assoc,
  complement,
  compose,
  cond,
  curry,
  equals,
  where,
  evolve,
  find,
  identity,
  isNil,
  keys,
  mapObjIndexed,
  omit,
  path,
  pick,
  propEq,
  propSatisfies,
  reduce,
  T
} from 'ramda';
import { useWith as ramdaUseWith } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import UploadImg from '../../components/UploadImg';
import donationCodeList from '../../constants/donationCodeList';
import { isReallyEmpty } from '../../utils/mixin';
import { PaymentTypes } from '../../constants/RemittancePaymentTypes';
import hasErrors from '../../utils/hasErrors';
const Option = Select.Option;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const FormItemForReceipt = styled(Form.Item)`
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 0.5rem;
  }
  margin-bottom: 12px;
`;
const IconWithSpot = styled(LegacyIcon)`
  ::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
};
const findProduct = ramdaUseWith(find, [propEq('id'), identity]);
const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);
const isNotNil = complement(isNil);
const isNotReallyEmpty = complement(isReallyEmpty);

const label = { NEW: '新增', EDIT: '編輯', DONE: '已匯款-編輯', ALLOW: '批准' };
const confirm = {
  NEW: '是否確定新增？',
  DONE: '是否確定儲存？',
  EDIT: '是否確定儲存？',
  ALLOW: '是否確定批准？',
  INVOICE: (
    <>
      <div>確定要開立發票？</div>
      <div>發票一旦開立，相關資訊不得修改</div>
      <div>請確認發票資訊是否正確</div>
    </>
  )
};

const isCertificated = value => {
  var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;
  return regexp.test(value);
};
const isCellPhoneCode = value => {
  var regexp = /^\/[0-9A-Z.+-]{7}$/;
  return regexp.test(value);
};
const onlyNumber = (rule, value, callback) => {
  if (value) {
    try {
      if (!/^[0-9]*$/gm.test(value)) {
        throw new Error('請輸入數字');
      }
    } catch (err) {
      callback(err);
      return;
    }
  }
  callback();
};
const carrierNumValidator = (rule, value, callback) => {
  if (isReallyEmpty(value) || isCellPhoneCode(value) || isCertificated(value)) {
    return callback();
  } else {
    return callback('請確認格式');
  }
};

const RECEIPT_TYPE = {
  PERSONAL: 'PERSONAL',
  DONATE: 'DONATE',
  BUSINESS: 'BUSINESS'
};
const defaultHolders = {
  [RECEIPT_TYPE.PERSONAL]: {},
  [RECEIPT_TYPE.DONATE]: {},
  [RECEIPT_TYPE.BUSINESS]: {}
};
const receiptFields = [
  'receiptName',
  'receiptTel',
  'receiptEmail',
  'carrierNum',
  'carrierType',
  'receiptAddr',
  'loveCode',
  'receiptDonate',
  'businessNo'
];
const defaultReceiptValues = {
  receiptName: '',
  receiptTel: '',
  receiptEmail: '',
  carrierNum: '',
  carrierType: '',
  receiptAddr: '',
  loveCode: '',
  receiptDonate: 0,
  businessNo: ''
};
const getType = currentRemittance => {
  if (currentRemittance?.businessNo) {
    return RECEIPT_TYPE.BUSINESS;
  } else if (currentRemittance?.loveCode) {
    return RECEIPT_TYPE.DONATE;
  } else if (currentRemittance?.receiptName) {
    return RECEIPT_TYPE.PERSONAL;
  } else {
    return RECEIPT_TYPE.DONATE;
  }
};
const RemittanceForm = ({
  loading,
  currentRemittance,
  onSubmit,
  onCancel,
  isAllowed = false,
  productList = [],
  isShowModal,
  isInvoiced,
  form: {
    getFieldDecorator,
    validateFields,
    getFieldValue,
    getFieldsValue,
    getFieldsError,
    resetFields
  }
}) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [productNo, setProductNo] = useState();
  const [mode, setMode] = useState('NEW');
  const [enableReceipt, setEnableReceipt] = useState(false);
  const [receiptType, setReceiptType] = useState();
  const [holders, setHolders] = useState();
  const [enableCallInvoice, setEnableCallInvoice] = useState(false);
  const [confirmText, setConfirmText] = useState(confirm.NEW);
  const [isApproved, setIsApproved] = useState(false);
  const [isCash, setIsCash] = useState(false);

  useEffect(() => {
    setSelectedProduct(findProduct(productNo, productList));
  }, [productNo, productList, selectedProduct]);

  useEffect(() => {
    setMode(
      cond([
        [always(isAllowed), always('ALLOW')],
        [isNil, always('NEW')],
        [propSatisfies(isNotNil, 'approvedAt'), always('DONE')],
        [propSatisfies(isNotNil, 'accountNumber'), always('DONE')],
        [
          where({
            paymentType: equals(PaymentTypes[1].value),
            image: isNotReallyEmpty
          }),
          always('DONE')
        ],
        [T, always('EDIT')]
      ])(currentRemittance)
    );
  }, [currentRemittance, isAllowed]);

  useEffect(() => {
    const type = getType(currentRemittance);
    setProductNo(
      currentRemittance ? parseInt(currentRemittance.productNo) : undefined
    );
    setReceiptType(type);
    setHolders({
      ...defaultHolders,
      [type]: pick(receiptFields, currentRemittance || {})
    });
    setIsApproved(
      propSatisfies(isNotNil, 'approvedAt', currentRemittance || {})
    );
    setEnableReceipt(false);
    setEnableCallInvoice(false);
    setIsCash(
      propSatisfies(
        value => value === PaymentTypes[1].value,
        'paymentType',
        currentRemittance || {}
      )
    );
  }, [currentRemittance]);

  useEffect(() => {
    setConfirmText(enableCallInvoice ? confirm.INVOICE : confirm[mode]);
  }, [enableCallInvoice, mode]);

  const hasFieldErrors = hasErrors(getFieldsError());

  const handleEnableCallInvoiceChange = ({ target }) => {
    setEnableCallInvoice(target.checked);
    if (!enableReceipt && target.checked) {
      setEnableReceipt(true);
    }
  };

  const toggleReceipt = () => {
    if (enableCallInvoice) {
      setEnableReceipt(true);
    } else {
      setEnableReceipt(value => !value);
      resetFields(receiptFields);
    }
  };

  const handlePaymentTypeChange = value => {
    setIsCash(value === PaymentTypes[1].value);
    if (isNotNil(getFieldsError(['image']).image)) {
      resetFields(['image']);
    }
  };

  const handleTabChange = activeKey => {
    if (mode === 'ALLOW' || isInvoiced) {
      return;
    }
    setHolders(holders => ({
      ...holders,
      [receiptType]: getFieldsValue(receiptFields)
    }));
    setReceiptType(activeKey);
    resetFields(receiptFields);
  };

  const handleCancel = () => {
    setEnableReceipt(false);
    setEnableCallInvoice(false);
    setHolders(defaultHolders);
    onCancel();
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        if (enableReceipt) {
          onSubmit(
            { ...defaultReceiptValues, ...values },
            mode,
            enableCallInvoice
          );
        } else {
          onSubmit(values, mode, enableCallInvoice);
        }
        // 更新一次發票 Data，避免發票資訊區塊閃爍
        setHolders(holders => ({
          ...holders,
          [receiptType]: getFieldsValue(receiptFields)
        }));
      }
    });
  };
  return (
    <div>
      <Modal
        centered
        title={`${label[mode]}匯款單`}
        width={600}
        open={isShowModal}
        destroyOnClose={true}
        onCancel={handleCancel}
        footer={[
          <Popconfirm
            disabled={hasFieldErrors || (mode === 'ALLOW' && isApproved)}
            key="confirm"
            title={confirmText}
            onConfirm={handleSubmit}
          >
            <Button
              key="confirm"
              type="primary"
              htmlType="submit"
              disabled={hasFieldErrors || (mode === 'ALLOW' && isApproved)}
            >
              {isAllowed ? '確定匯款，進行點發' : '確定'}
            </Button>
          </Popconfirm>,
          <Button
            key="cancel"
            style={{ marginLeft: '8px' }}
            onClick={handleCancel}
          >
            取消
          </Button>
        ]}
      >
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Spin spinning={loading}>
            <>
              <FormItem label="消費者">
                {getFieldDecorator('consumerUserId', {
                  rules: [{ required: true, message: '請選擇消費者' }]
                })(
                  <LiveMasterSelect
                    placeholder="請輸入暱稱"
                    style={{ width: '500px' }}
                    type="nick_name"
                    roleFilter=""
                    disabled={mode === 'DONE' || mode === 'ALLOW'}
                  />
                )}
              </FormItem>
              <FormItem label="轉帳方案">
                {getFieldDecorator('productNo', {
                  rules: [{ required: true, message: '請選擇方案' }]
                })(
                  <Select
                    placeholder="請選擇轉帳方案"
                    style={{ width: '320px' }}
                    onChange={e => setProductNo(parseInt(e))}
                    disabled={mode === 'DONE' || mode === 'ALLOW'}
                  >
                    {productList.map(item => (
                      <Option key={item.id} value={item.id} title={item.price}>
                        {`${item.remark} (${item.price} - TWD)`}
                      </Option>
                    ))}
                  </Select>
                )}
                {selectedProduct && (
                  <span style={{ marginLeft: '16px', fontSize: 13 }}>
                    {`(NTD ${selectedProduct.price} :
                ${selectedProduct.points} points)`}
                  </span>
                )}
              </FormItem>
              <div style={{ display: 'flex' }}>
                <FormItem label="匯款方式" style={{ marginRight: '24px' }}>
                  {getFieldDecorator('paymentType', {
                    initialValue: PaymentTypes[0].value
                  })(
                    <Select
                      placeholder="請選擇匯款方式"
                      style={{ width: '240px' }}
                      onChange={handlePaymentTypeChange}
                      disabled={mode === 'DONE' || mode === 'ALLOW'}
                    >
                      {PaymentTypes.map(item => (
                        <Option
                          key={item.value}
                          value={item.value}
                          title={item.label}
                        >
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>

                <FormItem label="匯款帳號後五碼">
                  {getFieldDecorator('accountNumber', {
                    rules: [
                      {
                        required: mode === 'ALLOW' && !isCash,
                        message: '請輸入匯款帳號'
                      },
                      { validator: onlyNumber }
                    ]
                  })(
                    <Input
                      style={{ width: '240px' }}
                      placeholder="請輸入匯款帳號後五碼"
                      maxLength={5}
                      disabled={mode === 'DONE' || mode === 'ALLOW' || isCash}
                    />
                  )}
                </FormItem>
              </div>
              <FormItem label="備註">
                {getFieldDecorator('note')(
                  <Input disabled={mode === 'ALLOW'} />
                )}
              </FormItem>
              <FormItem label="上傳截圖（最多三張）">
                {getFieldDecorator('image', {
                  rules: [
                    {
                      required: isCash,
                      message: '請上傳截圖'
                    }
                  ]
                })(
                  <UploadImg
                    uploadUrl="/cdn/backend/photo"
                    mode="multiple"
                    disabled={mode === 'DONE' || mode === 'ALLOW'}
                  />
                )}
              </FormItem>
              <FormItem>
                <Checkbox
                  checked={enableCallInvoice}
                  onChange={handleEnableCallInvoiceChange}
                  disabled={!isApproved || isInvoiced}
                >
                  開立發票
                </Checkbox>
              </FormItem>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '-36px'
                }}
              >
                <Button type="link" onClick={toggleReceipt}>
                  發票編輯 <LegacyIcon type={enableReceipt ? 'up' : 'down'} />
                </Button>
              </div>
            </>
            {enableReceipt && (
              <div style={{ minHeight: '220px' }}>
                <Tabs
                  animated={false}
                  onChange={handleTabChange}
                  activeKey={receiptType}
                  tabBarStyle={{ textAlign: 'center' }}
                >
                  <TabPane tab="個人電子發票" key={RECEIPT_TYPE.PERSONAL}>
                    {receiptType === RECEIPT_TYPE.PERSONAL && (
                      <Row gutter={12}>
                        <Col span={12}>
                          <FormItemForReceipt>
                            {getFieldDecorator('receiptName', {
                              initialValue: holders?.[receiptType]?.receiptName,
                              rules: [
                                {
                                  required: true,
                                  message: '請輸入發票收件人'
                                }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="user" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="發票收件人"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                        <Col span={12}>
                          <FormItemForReceipt>
                            {getFieldDecorator('receiptTel', {
                              initialValue: holders?.[receiptType]?.receiptTel,
                              rules: [
                                { required: true, message: '請輸入聯絡電話' }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="phone" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="聯絡電話"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                        <Col span={12}>
                          <FormItemForReceipt>
                            {getFieldDecorator('receiptEmail', {
                              initialValue:
                                holders?.[receiptType]?.receiptEmail,
                              rules: [
                                {
                                  required: true,
                                  message: '請輸入 E-mail'
                                },
                                {
                                  type: 'email',
                                  message: '請輸入正確 E-mail 格式'
                                }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="mail" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="E-mail"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                        <Col span={12}>
                          <FormItemForReceipt>
                            {getFieldDecorator('carrierNum', {
                              initialValue: holders?.[receiptType]?.carrierNum,
                              rules: [
                                { required: false, message: '請輸入載具碼' },
                                {
                                  validator: carrierNumValidator
                                }
                              ]
                            })(
                              <Input
                                prefix={<LegacyIcon type="barcode" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="填寫載具碼"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                        <Col span={24}>
                          <FormItemForReceipt>
                            {getFieldDecorator('receiptAddr', {
                              initialValue: holders?.[receiptType]?.receiptAddr,
                              rules: [
                                { required: true, message: '請輸入寄送地址' }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="home" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="寄送地址"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                        {getFieldDecorator('carrierType', {
                          initialValue: getFieldValue('carrierNum')
                            ? isCertificated(getFieldValue('carrierNum'))
                              ? 1
                              : 0
                            : ''
                        })(<Input type="hidden" />)}
                      </Row>
                    )}
                  </TabPane>
                  <TabPane tab="電子發票捐贈" key={RECEIPT_TYPE.DONATE}>
                    {receiptType === RECEIPT_TYPE.DONATE && (
                      <>
                        <FormItem
                          style={{
                            margin: '0 auto',
                            display: 'block',
                            width: '100%',
                            maxWidth: '368px'
                          }}
                        >
                          {getFieldDecorator('loveCode', {
                            initialValue:
                              holders?.[receiptType]?.loveCode ||
                              donationCodeList[0].value,
                            rules: [
                              { required: true, message: '請選擇捐贈單位' }
                            ]
                          })(
                            <Radio.Group
                              disabled={mode === 'ALLOW' || isInvoiced}
                            >
                              {donationCodeList.map(item => (
                                <Radio
                                  key={item.value}
                                  value={item.value}
                                  style={radioStyle}
                                >
                                  {item.label}
                                </Radio>
                              ))}
                            </Radio.Group>
                          )}
                        </FormItem>
                        {getFieldDecorator('receiptDonate', {
                          initialValue: 1
                        })(<input type="hidden" />)}
                      </>
                    )}
                  </TabPane>
                  <TabPane tab="公司三聯式" key={RECEIPT_TYPE.BUSINESS}>
                    {receiptType === RECEIPT_TYPE.BUSINESS && (
                      <Row gutter={12}>
                        <Col span={12}>
                          <FormItemForReceipt>
                            {getFieldDecorator('businessNo', {
                              initialValue: holders?.[receiptType]?.businessNo,
                              rules: [
                                { required: true, message: '請輸入統一編號' }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="number" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="統一編號"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                        <Col span={12}>
                          <FormItemForReceipt>
                            {getFieldDecorator('receiptTel', {
                              initialValue: holders?.[receiptType]?.receiptTel,
                              rules: [
                                { required: true, message: '請輸入聯絡電話' }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="phone" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="聯絡電話"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                        <Col span={12}>
                          <FormItemForReceipt>
                            {getFieldDecorator('receiptName', {
                              initialValue: holders?.[receiptType]?.receiptName,
                              rules: [
                                { required: true, message: '請輸入公司抬頭' }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="profile" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="公司抬頭"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                        <Col span={12}>
                          <FormItemForReceipt>
                            {getFieldDecorator('receiptEmail', {
                              initialValue:
                                holders?.[receiptType]?.receiptEmail,
                              rules: [
                                {
                                  required: true,
                                  message: '請輸入 E-mail'
                                },
                                {
                                  type: 'email',
                                  message: '請輸入正確 E-mail 格式'
                                }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="mail" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="E-mail"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>

                        <Col span={24}>
                          <FormItemForReceipt>
                            {getFieldDecorator('receiptAddr', {
                              initialValue: holders?.[receiptType]?.receiptAddr,
                              rules: [
                                { required: true, message: '請輸入寄送地址' }
                              ]
                            })(
                              <Input
                                prefix={<IconWithSpot type="home" />}
                                disabled={mode === 'ALLOW' || isInvoiced}
                                placeholder="寄送地址"
                              />
                            )}
                          </FormItemForReceipt>
                        </Col>
                      </Row>
                    )}
                  </TabPane>
                </Tabs>
              </div>
            )}
            {getFieldDecorator('id')(<Input type="hidden" />)}
          </Spin>
        </Form>
      </Modal>
    </div>
  );
};
export default Form.create({
  mapPropsToFields: compose(
    mapObjIndexed(value => Form.createFormField({ value: value })),
    renameKeys({ consumer: 'consumerUserId' }),
    evolve({
      productNo: value => parseInt(value),
      consumer: value => value.id
    }),
    omit(receiptFields),
    path(['currentRemittance'])
  )
})(RemittanceForm);
