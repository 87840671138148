import _ from 'lodash';
import Moment from 'moment';
import objHash from 'object-hash';

export default {
  processMessages(messages) {
    // create object hash for render key
    const hashed = _.map(messages, (message) => {
      const $hash = objHash(message);
      return { ...message, $hash };
    });
    // group by date
    const periods = _.groupBy(hashed, (message) => {
      const createAt = Moment.unix(message.createAt);
      const createDate = createAt.startOf('day');
      return createDate.unix();
    });
    return periods;
  },
};
