export default {
  name: [
    { required: true, message: '請輸入類型名稱' },
  ],
  stickerGroupId: [
    { required: true, message: '請輸入表情貼群組ID' },
  ],
  uiTemplate: [
    { required: true, message: '請選擇 UI 版型' },
  ],
  maxSeats: [
    { required: true, message: '請輸入聲麥位數量' },
    { type: 'number', message: '請輸入正確的麥位數量' },
  ],
  maxVipSeats: [
    { required: true, message: '請輸入VIP聲麥位數量' },
    { type: 'number', message: '請輸入正確的VIP聲麥位數量' },
  ],
  backgroundUrl: [
    { required: true, message: '' },
    { type: 'url', message: '' },
  ],
};
