import { message } from 'antd';
import produce from 'immer';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { combineLatest } from 'rxjs';
import { concatMap, map, pluck, switchMap, tap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import { addAccountAPI, modifyRoleAPI, searchAPI, sendUserAPI } from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { isReallyEmpty } from '../utils/mixin';

/**Type */
const ADD_ACCOUNT = createRequestTypes('ADD_ACCOUNT');

/**
 * Action Creator
 */

export const addAccount = createAction(
  ADD_ACCOUNT.REQUEST,
  (payload, callback) => ({ ...payload, callback })
);
export const addAccountSuccess = createAction(ADD_ACCOUNT.SUCCESS);
export const addAccountFailure = createAction(ADD_ACCOUNT.FAILURE);

/**
 * Epics
 */
export const addAccountEpic = action$ =>
  action$.pipe(
    ofType(ADD_ACCOUNT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      addAccountAPI(payload)
        .pipe(
          concatMap(() =>
            searchAPI({
              keyword: payload.loginId,
              type: 'login_id',
              item: 20,
              page: 1
            })
          ),
          concatMap(value => {
            const data = value.data[0];
            if (!isReallyEmpty(data)) {
              return combineLatest([
                sendUserAPI(data.id, { identityStatus: 1 }),
                modifyRoleAPI({ ids: [data.id], role: payload.role })
              ]).pipe(
                tap(() => {
                  if (payload.callback) {
                    payload.callback(data);
                  }
                })
              );
            } else {
              message.error('建立失敗，無法取得 id');
              throw new Error('無法取得 id');
            }
          })
        )
        .pipe(map(addAccountSuccess), catchRequestError(addAccountFailure))
    )
  );

/**
 * Reducer
 */
const initialState = {
  loading: false
};

export default handleActions(
  {
    [combineActions(ADD_ACCOUNT.REQUEST)]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(ADD_ACCOUNT.SUCCESS)]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [combineActions(ADD_ACCOUNT.FAILURE)]: produce(draft => {
      draft.loading = false;
    })
  },
  initialState
);
