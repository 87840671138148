import React from 'react';
import { Select } from 'antd';
import { userStatus } from '../../constants/roles';

const Option = Select.Option;

class StatusSelector extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userStatusEnum: Object.entries(userStatus),
    };
  }

  triggerChange = (value) => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  }

  onStatusSeleted = (value) => {
    this.triggerChange(value);
  }

  render() {
    const { value } = this.props;
    const { userStatusEnum } = this.state;
    return (
      <Select
        style={{ minWidth: '7.5rem' }}
        mode="multiple"
        value={value}
        placeholder="請篩選狀態"
        onChange={this.onStatusSeleted}
        allowClear
      >
        {userStatusEnum.map(([id, name]) => (
          <Option
            key={id}
            title={name}
            value={id}
          >{name}</Option>
        ))}
      </Select>
    );
  }
}

export default StatusSelector;
