import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipe } from 'ramda';
import produce from 'immer';
import styled from 'styled-components';
import { Button, InputNumber, Table, Modal, Popconfirm } from 'antd';
import { Form } from '@ant-design/compatible';
import hasErrors from '../utils/hasErrors';
import {
  getPointRatio,
  setPointRatio,
  showModal
} from '../reducers/pointRatio';

const FormItem = Form.Item;

const Actions = styled.div`
  margin-bottom: 1rem;
`;

class PointRatioManagement extends Component {
  componentDidMount() {
    this.props.getPointRatio();
  }

  generateTableColumn() {
    const { ratio, setPointRatio } = this.props;

    return [
      {
        title: '金額',
        dataIndex: 'money'
      },
      {
        title: '點數',
        dataIndex: 'point'
      },
      {
        title: '比值',
        dataIndex: 'ratio'
      },
      {
        title: '移除',
        key: 'delete',
        dataIndex: 'money',
        render: money => (
          <Popconfirm
            title="是否確定移除"
            onConfirm={() =>
              setPointRatio(ratio.filter(n => n.money !== money))
            }
            placement="right"
          >
            <Button>移除</Button>
          </Popconfirm>
        )
      }
    ];
  }

  generateFormItem() {
    const { ratio } = this.props;

    return [
      {
        key: 'money',
        label: '金額',
        options: {
          rules: [
            { required: true, message: '請輸入金額' },
            {
              validator: (rule, value, callback) => {
                ratio.find(el => el.money === value)
                  ? callback('重複的金額')
                  : callback();
              }
            }
          ]
        },
        render: () => <InputNumber size="large" min={1} />
      },
      {
        key: 'point',
        label: '點數',
        options: {
          rules: [{ required: true, message: '請輸入點數' }]
        },
        render: () => <InputNumber size="large" min={1} />
      },
      {
        key: 'ratio',
        label: '比值',
        options: {
          rules: [{ required: true, message: '請輸入點數' }]
        },
        render: () => <InputNumber size="large" min={0.01} step={0.01} />
      }
    ];
  }

  handleSubmit = e => {
    const { ratio, setPointRatio } = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        setPointRatio(
          produce(ratio, draft => {
            draft.push(values);
          })
        );
      }
    });
  };

  render() {
    const { loading, isShowModal, showModal, ratio } = this.props;
    const { getFieldDecorator, getFieldsError } = this.props.form;

    return (
      <div>
        <h1>{'帳務管理 > 匯入比值對照表'}</h1>
        <Actions>
          <Button type="primary" onClick={() => showModal(true)}>
            新增比值
          </Button>
        </Actions>
        {ratio.length > 0 && (
          <Table
            rowKey="money"
            pagination={false}
            columns={this.generateTableColumn()}
            dataSource={ratio}
          />
        )}

        {isShowModal ? (
          <Modal
            visible={true}
            footer={null}
            closable={false}
            onCancel={() => showModal(false)}
          >
            <Form onSubmit={this.handleSubmit}>
              {this.generateFormItem().map(item => (
                <FormItem key={item.key} label={item.label}>
                  {getFieldDecorator(item.key, item.options)(item.render())}
                </FormItem>
              ))}
              <FormItem>
                <Button
                  htmlType="submit"
                  loading={loading}
                  disabled={hasErrors(getFieldsError())}
                >
                  新增
                </Button>
              </FormItem>
            </Form>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.pointRatio.loading,
  isShowModal: state.pointRatio.isShowModal,
  ratio: state.pointRatio.ratio
});

const mapDispatchToProps = {
  getPointRatio,
  setPointRatio,
  showModal
};

export default pipe(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(PointRatioManagement);
