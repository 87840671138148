import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InstantMessageServer from '../../services/InstantMessage';
import {
  getAppRoomListAPI,
  getAppDialogAPI,
  addMessageAPI,
} from '../../apis';
import { userLevel } from '../../constants/roles';
import uploadCDN from '../../utils/uploadCDN';
import Utils from './utils';
import ChatActions from './ChatActions';
import MessagePool from './MessagePool';

const uploadTypeEnum = new Map([
  ['image', 'instantMessagePhoto'],
  ['video', 'instantMessageVideo'],
]);

const StyledEnquiryChatRoom = styled.div`
  width: 100%;
  height: 100%;
  background-color: #FAFAFA;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const EnquiryChatRoomHeader = styled.div`
  padding: 0 1rem;
  border-bottom: 1px solid #EEE;
  font-size: 1rem;
  line-height: 2.5;
`;

const EnquiryChatRoomBody = styled.div`
  height: calc(100% - 5.75rem);
  position: relative;
`;

class EnquiryChatRoom extends React.Component {

  static propTypes = {
    master: PropTypes.object.isRequired,
    participant: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.MessagePoolRef = React.createRef();

    this.state = {
      dialogId: null,
      periods: {},
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    InstantMessageServer.disconnect();
  }

  init = async () => {
    await this.fetchRoomList();
    this.fetchHistory();
    this.initMessageServer();
  }

  initMessageServer = () => {
    const socket = InstantMessageServer;
    socket.init();
    socket.on('instantMessage', this.onReceiveMessage);
    socket.on('instantMessageSend', this.onSendMessage);
  }

  transformUserLevel = (level) => {
    if (_.isEmpty(level)) return;

    const { levelId, levelNum } = level;
    const text = userLevel[levelId];
    return `${text}${levelNum}`;
  }

  sendMessage = (text) => {
    const { participant } = this.props;
    addMessageAPI({
      msgType: 'text',
      receiver: participant.id,
      textContent: text,
    }).subscribe();
  }

  sendMedia = async (media) => {
    const { participant } = this.props;
    const { file, fileType } = media;
    const uploadType = uploadTypeEnum.get(fileType[0]);
    if (_.isEmpty(uploadType)) return;

    const cdnMedia = await uploadCDN(file, uploadType);
    if (_.isEmpty(cdnMedia)) return;

    const payload = { receiver: participant.id };
    switch (fileType[0]) {
      case 'image':
        payload.msgType = 'image';
        payload.imageUrl = cdnMedia.url;
        break;
      case 'video':
        payload.msgType = 'video';
        payload.videoUrl = cdnMedia.url;
        payload.previewUrl = '';
        break;
      default:
    }
    addMessageAPI(payload).subscribe();
  }

  requestPoolToBottom = () => {
    const { MessagePoolRef } = this;
    const pool = MessagePoolRef.current;
    pool.requestToBottom();
  }

  setMessagePoolFocus = () => {
    const { MessagePoolRef } = this;
    const pool = MessagePoolRef.current;
    pool.focus();
  }

  addMessageToTop = (iPeriods) => {
    const { periods } = this.state;
    const newPeriods = { ...periods };
    _.forEach(iPeriods, (iPeriod, key) => {
      newPeriods[key] = _.unionWith(
        iPeriod,
        newPeriods[key],
        _.isEqual,
      );
    });
    this.setState(
      { periods: newPeriods },
      this.setMessagePoolFocus,
    );
  }

  addMessageToBottom = (iPeriods) => {
    const { periods } = this.state;
    const newPeriods = { ...periods };
    _.forEach(iPeriods, (iPeriod, key) => {
      newPeriods[key] = _.unionWith(
        newPeriods[key],
        iPeriod,
        _.isEqual,
      );
    });
    this.setState(
      { periods: newPeriods },
      this.setMessagePoolFocus,
    );
  }

  fetchRoomList = () => {
    const { participant } = this.props;
    const userId = _.get(participant, 'id');
    const payload = { item: 9999, page: 1 };
    const iPromise = getAppRoomListAPI(payload).toPromise();
    iPromise.then((response) => {
      const roomList = _.get(response, 'data', []);
      const room = _.find(roomList, { userId });
      const dialogId = _.get(room, 'dialogId');
      this.setState({ dialogId });
    });
    return iPromise;
  }

  fetchDialog = (payload) => {
    const dialogPromise = getAppDialogAPI(payload).toPromise();
    dialogPromise.then((response) => {
      const { pagination } = this.state;
      const total = _.get(response, 'totalCount', 0);
      const newPagination = { ...pagination, total };
      this.setState({ pagination: newPagination });
    });
    return dialogPromise;
  }

  fetchHistory = () => {
    const { dialogId, pagination } = this.state;
    if (_.isEmpty(dialogId)) return;

    const dialogPromise = this.fetchDialog({
      dialogId,
      item: pagination.pageSize,
      page: pagination.current,
    });
    dialogPromise.then((response) => {
      const data = _.get(response, 'data', []);
      if (!_.isArray(data)) return;
      const periods = Utils.processMessages(data);
      this.addMessageToTop(periods);
    });
  }

  fetchSendedMessage = () => {
    const { dialogId } = this.state;
    if (_.isEmpty(dialogId)) return;

    const dialogPromise = this.fetchDialog({
      dialogId,
      item: 10,
      page: 1,
    });
    dialogPromise.then((response) => {
      const data = _.get(response, 'data', []);
      if (!_.isArray(data)) return;
      const periods = Utils.processMessages(data);
      this.addMessageToBottom(periods);
      requestAnimationFrame(this.requestPoolToBottom);
    });
  }

  onInfinityFetchHisyory = () => {
    const { pagination } = this.state;
    const { current, pageSize, total } = pagination;

    // check new page is valid
    const newPage = current + 1;
    const limit = Math.ceil(total / pageSize);
    if (newPage > limit) return;

    // update
    const newPagination = { ...pagination, current: newPage };
    this.setState({ pagination: newPagination }, this.fetchHistory);
  }

  onReceiveMessage = (message) => {
    const { participant } = this.props;

    // check whether sender is participant
    const sender = _.get(message, 'sender');
    if (participant.id !== sender) return;

    // start processe
    const periods = Utils.processMessages([message]);
    this.addMessageToBottom(periods);
  }

  onSendMessage = async (message) => {
    const { Status } = message;
    if (Status !== 'OK') return;

    const { dialogId } = this.state;
    if (_.isEmpty(dialogId)) {
      await this.fetchRoomList();
    }
    setTimeout(this.fetchSendedMessage, 300);
  }

  renderActions = () => {
    return (
      <ChatActions
        onSendMessage={this.sendMessage}
        onSendMedia={this.sendMedia}
      ></ChatActions>
    );
  }

  renderMessagePool = () => {
    const { master, participant } = this.props;
    const { periods } = this.state;
    return (
      <MessagePool
        ref={this.MessagePoolRef}
        master={master}
        participant={participant}
        messages={periods}
        onLoadHistory={this.onInfinityFetchHisyory}
      ></MessagePool>
    );
  }

  renderParticipantInfo = () => {
    const { participant } = this.props;
    const nickname = _.get(participant, 'nickname');
    const userLevel = _.get(participant, 'userLevel');
    const displayLevel = this.transformUserLevel(userLevel);
    return `${nickname} (${displayLevel})`;
  }

  render() {
    return (
      <StyledEnquiryChatRoom>
        <EnquiryChatRoomHeader>
          <span>傳訊息給 {this.renderParticipantInfo()}</span>
        </EnquiryChatRoomHeader>
        <EnquiryChatRoomBody>
          {this.renderMessagePool()}
        </EnquiryChatRoomBody>
        <div className="chatroom-footer">
          {this.renderActions()}
        </div>
      </StyledEnquiryChatRoom>
    );
  }
}

export default EnquiryChatRoom;
