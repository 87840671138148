import { pick } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Form, Input, Select } from 'antd';
import { getBackendTagGroupListAPI } from '../../../apis';
import tagTypes from '../../../constants/tagTypes';
import ColorSelector from './ColorSelector';

const { Option } = Select;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    width: 5rem;
  }
`;

class TagManageForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      tagGroups: []
    };
  }

  componentDidMount() {
    this.fetchTagGroup();
  }

  getFormData = () => {
    const form = this.formRef.current;
    return form.getFieldsValue();
  };

  getFieldValue = (field) => {
    const form = this.formRef.current;
    return form.getFieldValue(field);
  };

  setFormData = (data) => {
    const form = this.formRef.current;
    const formData = this.getFormData();
    const fields = Object.keys(formData);
    const values = pick(fields, data);
    form.setFieldsValue(values);
  };

  validateFields = () => {
    const form = this.formRef.current;
    return form.validateFields();
  };

  reset = () => {
    const form = this.formRef.current;
    form.resetFields();
  };

  fetchTagGroup = () => {
    const observable = getBackendTagGroupListAPI({
      page: 1,
      item: 9999,
    });
    observable.subscribe((res) => {
      const { data } = res;
      this.setState({ tagGroups: data });
    });
  };

  render() {
    const { tagGroups } = this.state;
    return (
      <Form ref={this.formRef} labelAlign="right">
        <FormItem
          label="標籤名稱"
          name="label"
          htmlFor="label"
          rules={[
            { required: true, message: '請輸入標籤' },
          ]}
        >
          <Input id="label" placeholder="標籤名稱" />
        </FormItem>
        <FormItem
          label="所屬群組"
          name="groups"
          rules={[
            { required: true, message: '請選擇群組' },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="請選擇群組"
            allowClear
          >
            {tagGroups.map((group) => (
              <Option key={group.id} value={group.id}>
                <span>{group.label}</span>
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="類型"
          name="type"
          rules={[
            { required: true, message: '請選擇類型' },
          ]}
        >
          <Select placeholder="請選擇類型">
            {tagTypes.map(type => (
              <Option key={type.value} value={type.value}>
                <span>{type.label}</span>
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="色碼"
          name="color"
          rules={[
            { required: true, message: '請選擇標籤顏色' },
          ]}
        >
          <ColorSelector />
        </FormItem>
        <FormItem name="id" hidden>
          <Input type="hidden" />
        </FormItem>
      </Form>
    );
  }
}

export default TagManageForm;
