import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from 'antd';

const StyledCard = styled.div`
  .ant-card {
    margin-right: 8px;
    margin-bottom: 16px;
    box-shadow: 0 6px 18px -6px rgba(0, 0, 0, 0.09);
    transition: all 0.3s ease;
    background: #fff;
    border-radius: 7px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 6px 18px -6px rgba(0, 0, 0, 0.36);
      .card__thumb {
        opacity: 0.8;
      }
    }
  }

  .card {
    width: 240px;

    &__head {
      padding-top: 100%;
      display: block;
      position: relative;
      opacity: 1;
      transition: all 0.3s ease;
    }
    
    &__thumb{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 1;
      transition: opacity 0.3s ease;
    }

    &__content {
      line-height: 1.5;
      padding: 10px 16px;

      p {
        margin-bottom: 8px;
      }

      p, h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__activities {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;

      i {
        width: 2.2rem;
        height: 2.2rem;
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        & + i {
          margin-top: 0.5rem;
        }
      }
    }
  }
`;

const renderActivities = (activities) => {
  if (!activities) return;

  return (
    <div className="card__activities">
      {activities.map((img, index) => (
        <i
          key={`activity-${index}`}
          style={{ backgroundImage: `url(${img})` }}
        ></i>
      ))}
    </div>
  );
};

const LiveCard = ({ imgUrl, children, activities, onImageClick }) => (
  <StyledCard>
    <Card
      className="card"
      bodyStyle={{ padding: '0px' }}
      onClick={onImageClick}
    >
      <div className="card__head">
        <div className="card__thumb" style={{ backgroundImage: `url(${imgUrl})` }}></div>
        {renderActivities(activities)}
      </div>
      <div className="card__content">{children}</div>
    </Card>
  </StyledCard>
);

LiveCard.propTypes = {
  imgUrl: PropTypes.string,
  children: PropTypes.any,
  onImageClick: PropTypes.func
};

export default LiveCard;
