import objHash from 'object-hash';
import React from 'react';
import memoizeOne from 'memoize-one';
import styled from 'styled-components';
import VoiceChatLiveContext from '../VoiceChatLiveContext';
import SeatCard from './SeatCard';

const Wrapper = styled.div`
  padding: 0.5rem 0;
  background-color: #FAFAFA;
  border-radius: 1rem;
  overflow: hidden;
`;

class SeatManager extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      seats: [],
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  renderSeats = memoizeOne((seats) => {
    return seats.map((seat) => (
      <SeatCard
        key={objHash(seat)}
        dataset={seat}
      />
    ));
  })

  render() {
    return (
      <Wrapper>
        <VoiceChatLiveContext.Consumer>
          {({ seats }) => this.renderSeats(seats)}
        </VoiceChatLiveContext.Consumer>
      </Wrapper>
    );
  }
}

export default SeatManager;
