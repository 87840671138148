import _ from 'lodash';
import urlEnum from './url';
import store from '../../store';

const getAuth = () => {
  const state = store.getState();
  return _.pick(state.auth, ['token', 'nonce']);
};

const createFormData = (data) => {
  const formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    if (value) formData.append(key, value);
  }
  return formData;
};

export default (file, type, onprogress) => new Promise((resolve, reject) => {
  const isSupported = urlEnum.has(type);
  if (!isSupported) {
    reject(`the upload type: ${type} do not supported`);
    return;
  }

  // create request
  const url = urlEnum.get(type);
  const XMLRequest = new XMLHttpRequest(); // eslint-disable-line
  XMLRequest.open('POST', url, true);

  // set header
  const { token, nonce } = getAuth();
  XMLRequest.setRequestHeader('X-Auth-Token', token);
  XMLRequest.setRequestHeader('X-Auth-Nonce', nonce);

  // set onprogress callback
  if (_.isFunction(onprogress)) {
    XMLRequest.onprogress = onprogress;
  }

  // set onload callback
  XMLRequest.onload = () => {
    if (XMLRequest.status === 200) {
      const response = JSON.parse(XMLRequest.response);
      resolve(response.data);
    } else {
      reject();
    }
  };

  // send request
  const payload = createFormData({ file });
  XMLRequest.send(payload);
});
