import { find, isEmpty, isNil, pathOr, propEq, any } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Avatar, Button, Popconfirm, Table, Tag } from 'antd';
import { PERSONAL, USER_MANAGEMENT } from '../../constants/routes';
import {
  roles as RolesEnum,
  userStatus as UserStatusEnum,
} from '../../constants/roles';
import {
  closeAccountModal,
  closeRoleModal,
  disableUser,
  getUserList,
  modifyAccount,
  modifyRole,
  openAccountModal,
  openRoleModal,
} from '../../reducers/userManagement';
import antdTablePagination from '../../utils/antdTablePagination';
import { PageContainer, PageTitle } from '../../components/styled/page';
import ChangeRoleModal from '../../components/ChangeRoleModal';
import CreateAccountInfoModal from '../../components/CreateAccountInfoModal';
import UserSearchBar from '../../components/UserSearchBar';
import brandName from '../../constants/brandName';

const { Column } = Table;

const Actions = styled.div`
  margin: 0.5rem 0 0.75rem;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const hasRoleMaster = any(propEq('name', 'ROLE_MASTER'));

class UserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.UserSearchBarRef = React.createRef();

    this.state = {
      currentSelectId: '',
      currentRoleType: '',
      selectedAccounts: [],
      disabledChangeRole: false,
      isSubmitted: pathOr(
        false,
        ['history', 'location', 'state', 'isSubmitted'],
        this.props
      ),
      pagination: {
        ...new antdTablePagination(),
        current: pathOr(1, ['history', 'location', 'state', 'page'], this.props),
      },
    };

    this.onRowSelectionChange = (selectedRowKeys, selectedRows) => {
      const roles = pathOr('', [0, 'roles'], selectedRows);
      const voiceMaster = find(propEq('name', 'ROLE_VOICE_MASTER'), roles);
      const roleTypeId = isNil(voiceMaster)
        ? pathOr('', [0, 'id'], roles)
        : voiceMaster.id;
      this.setState({
        selectedAccounts: selectedRowKeys,
        currentSelectId: selectedRowKeys[0],
        currentRoleType: String(roleTypeId),
        disabledChangeRole: hasRoleMaster(roles)
      });
    };
  }

  componentDidMount() {
    const { UserSearchBarRef } = this;
    const { history } = this.props;
    const state = pathOr({}, ['location', 'state'], history);
    if (isEmpty(state)) return;

    UserSearchBarRef.setFieldsValue({
      roleFilter: state.roleFilter,
      statusFilter: state.statusFilter,
      userFilter: {
        keyword: state.keyword,
        type: state.type
      }
    });
  }

  /**
   * 取得 search 用的欄位
   */
  getQueryParams = () => {
    const { UserSearchBarRef } = this;
    const { pagination, isSubmitted } = this.state;
    const search = UserSearchBarRef.getFieldsValue();
    return {
      keyword: search?.userFilter?.keyword,
      type: search?.userFilter?.type,
      statusFilter: search?.statusFilter,
      roleFilter: search?.roleFilter,
      page: pagination.current,
      item: pagination.pageSize,
      isSubmitted,
    };
  };

  getUsers() {
    const { getUserList } = this.props;
    this.updateHistoryState();
    getUserList({
      ...this.getQueryParams()
    });
  }

  /**
   * 更新 History
   */
  updateHistoryState = () => {
    this.props.history.replace({
      state: {
        ...this.getQueryParams()
      }
    });
  };

  /**
   * 點擊頁數
   */
  handleTableChange = (pagination) => {
    const { pagination: statePagination } = this.state;
    const newPagination = {
      ...statePagination,
      current: pagination.current,
    };
    this.setState({
      pagination: newPagination,
    }, this.getUsers);
  };

  /**
   * 點擊搜尋
   */
  handleSearchClick = () => {
    const { pagination } = this.state;
    const newPagination = {
      ...pagination,
      current: 1,
    };
    this.setState({
      pagination: newPagination,
      isSubmitted: true,
      selectedAccounts: [],
    }, this.getUsers);
  };

  /**
   * 點擊停權
   */
  handleDisableUserClick = () => {
    const { selectedAccounts } = this.state;
    this.props.disableUser(selectedAccounts, this.getQueryParams());
  };

  /**
   * 開啟銀行帳戶面板
   */
  openAccountInfo = () => {
    const { openAccountModal } = this.props;
    const { currentSelectId } = this.state;
    openAccountModal(currentSelectId);
  };

  /**
   * 編輯銀行帳戶
   */
  changeAccountInfo = data => {
    const { modifyAccount } = this.props;
    modifyAccount(this.state.currentSelectId, data, this.getQueryParams());
  };

  /**
   * 變更權限
   */
  changeRoleInfo = data => {
    const { modifyRole } = this.props;
    modifyRole(data, this.getQueryParams());
    this.setState({
      currentRoleType: String(data.role)
    });
  };

  /**
   * 進入個人編輯頁
   */
  handleGotoPersonalPage = () => {
    const { currentSelectId } = this.state;
    this.props.history.push({
      pathname: `${USER_MANAGEMENT}${PERSONAL}/${currentSelectId}`
    });
  };

  renderAvatar = rowData => {
    const { profileThumbnailPicture } = rowData;
    return <Avatar src={profileThumbnailPicture} />;
  };

  renderRoles = rowData => {
    const { roles } = rowData;
    return roles.map(role => {
      const text = RolesEnum[role.name];
      return <Tag key={text}>{text}</Tag>;
    });
  };

  renderStatus = rowData => {
    const { status } = rowData;
    return UserStatusEnum[status];
  };

  render() {
    const {
      pagination, selectedAccounts, isSubmitted,
      disabledChangeRole,
    } = this.state;
    const {
      userList,
      totalCount,
      isAccountModalVisible,
      closeAccountModal,
      isRoleModalVisible,
      openRoleModal,
      closeRoleModal,
      loading,
      formValue,
    } = this.props;

    return (
      <PageContainer>
        <PageTitle>{'帳號管理 > 使用者帳號管理'}</PageTitle>
        <UserSearchBar
          ref={ref => (this.UserSearchBarRef = ref)}
          initialValue={{ userFilter: { keyword: '', type: 'login_id' } }}
          onSubmit={this.handleSearchClick}
        />
        <Actions>
          <Button
            type="primary"
            onClick={openRoleModal}
            disabled={selectedAccounts.length !== 1 || disabledChangeRole}
          >
            <span>變更權限</span>
          </Button>
          <Button
            type="primary"
            onClick={this.handleGotoPersonalPage}
            disabled={selectedAccounts.length !== 1}
          >
            <span>編輯帳戶資料</span>
          </Button>
          <Button
            type="primary"
            disabled={selectedAccounts.length !== 1}
            onClick={this.openAccountInfo}
          >
            <span>編輯銀行帳戶</span>
          </Button>
          <Popconfirm
            key={'confirm'}
            title={`是否停權 ${selectedAccounts.length} 筆帳號？`}
            placement="bottomLeft"
            disabled={selectedAccounts.length < 1}
            onConfirm={this.handleDisableUserClick}
          >
            <Button
              type="danger"
              disabled={selectedAccounts.length < 1}
            >
              <span>停權帳號</span>
            </Button>
          </Popconfirm>
        </Actions>
        <Table
          rowKey={record => record.id}
          loading={loading}
          dataSource={isSubmitted ? userList : []}
          scroll={{ x: true }}
          rowSelection={{
            selectedRowKeys: selectedAccounts,
            onChange: this.onRowSelectionChange
          }}
          pagination={{
            ...pagination,
            total: isSubmitted ? totalCount : 0,
          }}
          onChange={this.handleTableChange}
        >
          <Column
            title="頭像"
            key="profileThumbnailPicture"
            width="65"
            align="center"
            render={this.renderAvatar}
          />
          <Column
            title="暱稱"
            dataIndex="nickname"
            key="nickname"
          />
          <Column
            title="Login ID"
            dataIndex="loginId"
            key="loginId"
          />
          <Column
            title={`${brandName} ID`}
            dataIndex="trueLoveId"
            key="trueLoveId"
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Column
            title="手機號碼"
            dataIndex="verifiedPhone"
            key="verifiedPhone"
          />
          <Column
            title="角色"
            key="roles"
            render={this.renderRoles}
          />
          <Column
            title="帳號狀態"
            key="status"
            render={this.renderStatus}
          />
        </Table>
        <CreateAccountInfoModal
          modalTitle="編輯銀行帳戶"
          account={formValue.account}
          visible={isAccountModalVisible}
          onSubmit={this.changeAccountInfo}
          onClose={closeAccountModal}
        />
        <ChangeRoleModal
          roles={this.props.roles}
          currentSelectId={this.state.currentSelectId}
          currentRoleType={this.state.currentRoleType}
          visible={isRoleModalVisible}
          onClose={closeRoleModal}
          onSubmit={this.changeRoleInfo}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.auth.roles.map(item => item.name),
  userList: state.userManagement.userList,
  page: state.userManagement.page,
  totalCount: state.userManagement.totalCount,
  isAccountModalVisible: state.userManagement.isAccountModalVisible,
  isRoleModalVisible: state.userManagement.isRoleModalVisible,
  loading: state.userManagement.loading,
  formValue: state.userManagement.formValue
});

const mapDispatchToProps = {
  getUserList,
  openAccountModal,
  closeAccountModal,
  modifyAccount,
  openRoleModal,
  closeRoleModal,
  modifyRole,
  disableUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
