import IconBronze from './icons/level-bronze.svg';
import IconDarkgold from './icons/level-darkgold.svg';
import IconDiamond from './icons/level-diamond.svg';
import IconGold from './icons/level-gold.svg';
import IconSilver from './icons/level-silver.svg';

export default {
  username: '#FFECD6',
  content: '#FAFAFA',
  giftPrompt: {
    background: 'rgba(212, 77, 57, 0.75)',
  },
  identity: {
    liveMaster: {
      foreground: '#D44D39',
      background: 'rgba(242, 105, 78, 0.75)',
    },
    voiceMaster: {
      foreground: '#FFA25A',
      background: 'rgba(255, 134, 41, 0.75)',
    },
    manager: {
      foreground: '#4FCCF5',
      background: 'rgba(0, 155, 194, 0.75)',
    },
    official: {
      foreground: '#4D448A',
      background: 'rgba(45, 52, 126, 0.75)',
    },
    mention: {
      foreground: '#926AC7',
      background: 'rgba(119, 70, 185, 0.75)',
    },
    default: {
      foreground: '#777777',
      background: 'rgba(34, 34, 34, 0.5)',
    },
  },
  userLevel: {
    bronze: {
      number: '#CFFCF1',
      icon: IconBronze,
    },
    silver: {
      number: '#CFF1FC',
      icon: IconSilver,
    },
    gold: {
      number: '#FCEFCF',
      icon: IconGold,
    },
    diamond: {
      number: '#FCCFE5',
      icon: IconDiamond,
    },
    niello: {
      number: '#E2CFFC',
      icon: IconDarkgold,
    }
  },
};
