import React, { createRef } from 'react';
import { Button, Modal, notification } from 'antd';
import VoiceChatForm from './VoiceChatForm';
import {
  addVoiceChatAPI,
  getGiftCategoryListAPI,
  getVoiceChatRoom,
  getVoiceChatTypeList,
  setVoiceChatAPI
} from '../../../../apis';
import { prop, sortBy } from 'ramda';

const actions = {
  create: {
    title: '新增聲聊房',
    handler: 'createVoiceRoom'
  },
  edit: {
    title: '編輯聲聊房',
    handler: 'updateVoiceRoom'
  }
};

class VoiceChatModal extends React.Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.form = createRef();
    this.state = {
      action: null,
      visible: false,
      typeList: [],
      giftCategoryList: []
    };
  }

  componentDidMount() {
    this.fetchVoiceChatTypeList();
    this.fetchGiftCategoryList();
  }

  componentWillUnmount() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  fetchVoiceChatTypeList = () => {
    this.subscriptions.push(
      getVoiceChatTypeList().subscribe(res => {
        const typeList = sortBy(prop('id'))(res.data);
        this.setState({ typeList });
      })
    );
  };

  fetchGiftCategoryList = () => {
    const payload = {
      typeFilter: 5,
      page: 1,
      item: 999
    };
    this.subscriptions.push(
      getGiftCategoryListAPI(payload).subscribe(res => {
        const giftCategoryList = res.data;
        this.setState({ giftCategoryList });
      })
    );
  };

  show = (action, data) => {
    if (!actions[action]) {
      console.error(`VoiceChatModal: Could not support action "${action}"`);
      return;
    }

    this.setState({ action, visible: true }, () => {
      requestAnimationFrame(() => {
        if (action === 'edit' && data) this.fetchRoomInfo(data?.roomId);
      });
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  createVoiceRoom = formData => {
    const { onCompleted } = this.props;
    this.subscriptions.push(
      addVoiceChatAPI(formData).subscribe(() => {
        onCompleted();
        this.hide();
      })
    );
  };

  updateVoiceRoom = formData => {
    const { onCompleted } = this.props;
    const observable = setVoiceChatAPI(formData);
    observable.subscribe(() => {
      onCompleted();
      this.hide();
    });
  };

  fetchRoomInfo = id => {
    const observable = getVoiceChatRoom(id);
    this.subscriptions.push(
      observable.subscribe({
        next: res => {
          const { data } = res;
          this.form.current.reset();
          this.form.current.setFormData(data);
        },
        error: err => {
          notification.error({
            message: err?.name,
            description: err?.message,
            duration: 3,
            placement: 'top'
          });
        }
      })
    );
  };

  onConfirm = () => {
    const form = this.form.current;
    const { action } = this.state;

    const iPromise = form.validateFields();
    iPromise.then(values => {
      const handler = actions[action].handler;
      this[handler](values);
      this.hide();
    });
  };

  renderFooter = () => {
    return (
      <React.Fragment>
        <Button type="primary" onClick={this.onConfirm}>
          <span>確定</span>
        </Button>
        <Button onClick={this.hide}>取消</Button>
      </React.Fragment>
    );
  };

  render() {
    const { action, visible, typeList, giftCategoryList } = this.state;
    const meta = actions[action];
    const title = meta && meta.title;
    return (
      <Modal
        className="voice-chat-modal"
        title={title}
        open={visible}
        footer={this.renderFooter()}
        onOk={this.onConfirm}
        onCancel={this.hide}
        centered
        maskClosable
        destroyOnClose={true}
      >
        <VoiceChatForm
          ref={this.form}
          typeList={typeList}
          giftCategoryList={giftCategoryList}
          mode={action}
        ></VoiceChatForm>
      </Modal>
    );
  }
}
VoiceChatModal.defaultProps = {
  onCompleted: () => {}
};
export default VoiceChatModal;
