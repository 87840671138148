import _ from 'lodash';
import { move, remove } from 'ramda';
import hash from 'object-hash';
import Sortable from 'sortablejs';
import React from 'react';
import { Button, message } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import {
  getAppRankginListAPI,
  updateAppRankginListAPI,
} from '../../apis';
import RankingRow from './RankingRow';
import { PageContainer, PageTitle } from '../../components/styled/page';

const Tools = styled.div`
  margin-bottom: 1rem;
`;

const RankingContiner = styled.div`
  & > div {
    margin-bottom: 0.5rem;
  }
`;

const CreateButton = styled(Button)`
  width: 100%;
  height: auto;
  padding: 0.6rem;
  background: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  border-style: dashed;
`;

class AppRankingManagement extends React.Component {
  constructor(props) {
    super(props);

    this.rankingContainerRef = React.createRef();

    this.state = {
      rankingSortable: null,
      rankings: [],
    };
  }

  componentDidMount() {
    this.fetchRanking();
    this.initRankingSortable();
  }

  initRankingSortable = () => {
    const containerEl = this.rankingContainerRef.current;
    const rankingSortable = new Sortable(containerEl, {
      onUpdate: this.onRankingSort,
    });
    this.setState(() => ({ rankingSortable }));
  }

  fetchRanking = () => {
    const observable = getAppRankginListAPI();
    observable.subscribe(res => {
      const rankings = res.data;

      _.forEach(rankings, ranking => {
        // generate tab id
        const id = hash(ranking);
        Object.assign(ranking, { id });
      });

      this.setState(() => ({ rankings }));
    });
  };

  onRankingSort = evt => {
    const { rankings } = this.state;
    const { oldIndex, newIndex } = evt;
    const newRankings = move(oldIndex, newIndex, rankings);

    this.setState(() => ({ rankings: newRankings }));
  };

  onRankingDelete = index => {
    const { rankings } = this.state;
    const newRankings = remove(index, 1, rankings);
    this.setState(() => ({ rankings: newRankings }));
  };

  onSave = () => {
    const { rankings } = this.state;
    const data = [...rankings];

    data.forEach((ranking, index) => {
      // remove useless params
      delete ranking.id;

      // set order
      ranking.order = index + 1;
    });

    const observable = updateAppRankginListAPI({ data });
    observable.subscribe((res) => {
      message.success('儲存成功');
      this.fetchRanking();
    }, () => {
      message.error('儲存失敗');
    });
  };

  onCreateBtnClick = () => {
    const { rankings } = this.state;
    const ranking = {
      id: Date.now(),
      order: rankings.length + 1,
      i18nContents: { names: {} }
    };
    this.setState({ rankings: [...rankings, ranking] });
  };

  render() {
    const { rankingContainerRef } = this;
    const { rankings } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'APP 管理 > APP 排行榜管理'}</PageTitle>
        <Tools>
          <Button type="primary" onClick={this.onSave}>
            <span>儲存</span>
          </Button>
        </Tools>
        <RankingContiner ref={rankingContainerRef}>
          {rankings.map((ranking, index) => (
            <RankingRow
              key={ranking.id}
              index={index}
              rowData={ranking}
              onDelete={this.onRankingDelete}
            ></RankingRow>
          ))}
          <CreateButton
            size="large"
            onClick={this.onCreateBtnClick}
          >
            <PlusOutlined />
          </CreateButton>
        </RankingContiner>
      </PageContainer>
    );
  }
}

export default AppRankingManagement;
