import { createAction, handleActions } from 'redux-actions';
import { pipe } from 'ramda';
import { message } from 'antd';
import { switchMap, mergeMap, map, tap } from 'rxjs/operators';
import { ofType, catchRequestError } from '../utils/extendOperators';
import { getPhotoListAPI, removePhotoListAPI } from '../apis';

/**
 * Action Types
 */
const GET_PHOTO_LIST = 'GET_PHOTO_LIST';
const GET_PHOTO_LIST_SUCCESS = 'GET_PHOTO_LIST_SUCCESS';
const GET_PHOTO_LIST_FAILURE = 'GET_PHOTO_LIST_FAILURE';
const SET_CURRENT_PHOTO_CONTENT = 'SET_CURRENT_PHOTO_CONTENT';
const CLOSE_PHOTO_MODAL = 'CLOSE_PHOTO_MODAL';
const REMOVE_PHOTO_LIST = 'REMOVE_PHOTO_LIST';
const REMOVE_PHOTO_LIST_SUCCESS = 'REMOVE_PHOTO_LIST_SUCCESS';
const REMOVE_PHOTO_LIST_FAILURE = 'REMOVE_PHOTO_LIST_FAILURE';

/**
 * Action Creator
 */
export const getPhotoList = (name = '', page = 0, size = 10) =>
  createAction(GET_PHOTO_LIST)({
    input: name,
    page,
    size,
  });
const getPhotoListSuccess = createAction(GET_PHOTO_LIST_SUCCESS);
const getPhotoListFailure = createAction(GET_PHOTO_LIST_FAILURE);
export const setCurrentPhotoContent = createAction(SET_CURRENT_PHOTO_CONTENT);
export const closePhotoModal = createAction(CLOSE_PHOTO_MODAL);
export const removePhotoList = (id = 0, name = '', page = 0, size = 10) =>
  createAction(REMOVE_PHOTO_LIST)({
    id,
    input: name,
    page,
    size,
  });
const removePhotoListSucess = createAction(REMOVE_PHOTO_LIST_SUCCESS);
const removePhotoListFailure = createAction(REMOVE_PHOTO_LIST_FAILURE);

/**
 * Epics
 */
export const getPhotoListEpic = pipe(
  ofType(GET_PHOTO_LIST),
  map(action => action.payload),
  switchMap(payload =>
    getPhotoListAPI(payload).pipe(
      map(getPhotoListSuccess),
      catchRequestError(getPhotoListFailure)
    )
  )
);

export const removePhotoListEpic = pipe(
  ofType(REMOVE_PHOTO_LIST),
  map(action => action.payload),
  switchMap(payload =>
    removePhotoListAPI(payload.id).pipe(
      tap(() => {
        message.success('照片已刪除成功');
      }),
      mergeMap(res => [
        removePhotoListSucess(res),
        getPhotoList(payload.input, payload.page, payload.size),
      ]),
      catchRequestError(
        pipe(error => {
          message.error('照片刪除失敗');
          return error;
        }, removePhotoListFailure)
      )
    )
  )
);

const initalState = {
  data: [],
  currentPage: 0,
  totalCount: 0,
  pageSize: 10,
  isModalVisible: false,
  currentPhotoContent: {},
};

export default handleActions(
  {
    [GET_PHOTO_LIST]: (state, action) => ({
      ...state,
      ...action.payload,
      currentPage: action.payload.page,
    }),
    [GET_PHOTO_LIST_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      totalCount: action.payload.totalCount,
    }),
    [SET_CURRENT_PHOTO_CONTENT]: (state, action) => ({
      ...state,
      currentPhotoContent: action.payload,
      isModalVisible: true,
    }),
    [CLOSE_PHOTO_MODAL]: state => ({
      ...state,
      isModalVisible: false,
      currentPhotoContent: {},
    }),
    [REMOVE_PHOTO_LIST_SUCCESS]: state => ({
      ...state,
      isModalVisible: false,
    }),
  },
  initalState
);
