import _ from 'lodash';
import {
  always, compose, cond,
  evolve, isNil, when,
  where,
} from 'ramda';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Input, Popconfirm, Table } from 'antd';
import styled from 'styled-components';
import { giftType } from '../../constants/gifts';
import {
  addDispatch,
  deleteDispatch,
  getDispatch,
  getDispatchList,
  getDispatchUsers,
  getTagsList,
  resetDispatch,
  setDispatch,
  showModal,
  withdrawDispatchUser,
} from '../../reducers/dispatchManagement';
import {
  getEffect,
  getEffectList,
  resetEffect,
} from '../../reducers/effectManagement';
import { getGiftList } from '../../reducers/giftList';
import { isNotNil } from '../../utils/mixin';
import antdTablePagination from '../../utils/antdTablePagination';
import { PageContainer, PageTitle } from '../../components/styled/page';
import TableContainer from '../../components/TableContainer';
import DispatchGiftFormModal from './DispatchGiftFormModal';
import DispatchUsersModal from './DispatchUsersModal';
import PreviewGiftModal from './PreviewGiftModal';

const { Search } = Input;

const SearchBar = styled.div`
  margin-bottom: 0.5rem;
`;

const ActionBar = styled.div`
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const getSubmitData = compose(
  when(
    where({ scheduleTime: isNotNil }),
    evolve({ scheduleTime: value => value.unix() })
  )
);

const getDateFormat = value => {
  return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '無';
};

const getSendingStatus = value => {
  return cond([
    [where({ sendTime: isNotNil }), always('已發送')],
    [where({ sendTime: isNil, scheduleTime: isNotNil }), always('已排程')],
    [where({ sendTime: isNil, scheduleTime: isNil }), always('無')]
  ])(value);
};

class DispatchGiftManagement extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      filterText: '',
      page: 1,
      isCreateMode: false,
      showDispatchUsersModal: false,
      showPreviewEffectModal: false,
      selectedRowKeys: [],
      dispatchId: undefined,
      defaultPagination: new antdTablePagination(),
    };
  }

  columns = [
    { title: '編號', dataIndex: 'id' },
    {
      title: '發送帳號',
      render: value => {
        return (
          <Button
            disabled={isNil(value.sendTime)}
            onClick={() => this.handleDispatchUserClick(value.id)}
          >
            檢視
          </Button>
        );
      }
    },
    {
      title: '發送時間',
      dataIndex: 'sendTime',
      render: (value, record) => getDateFormat(value || record.scheduleTime)
    },
    { title: '發送狀態', render: value => getSendingStatus(value) },
    { title: '禮物名稱', dataIndex: 'name' },
    {
      title: '預覽',
      width: 100,
      render: value => {
        return (
          <Button onClick={() => this.handlePreviewEffectClick(value)}>
            檢視
          </Button>
        );
      }
    },
    { title: '期效', dataIndex: 'availableTimeDesc' },
    { title: '發送數量', dataIndex: 'quantity' },
    {
      title: '發送人員',
      dataIndex: 'createUser',
      render: value => value?.nickname
    }
  ];

  componentDidMount() {
    const { resetEffect, getTagsList, getEffectList, getGiftList } = this.props;
    this.getDispatchList();
    resetEffect();
    getTagsList({ page: 1, item: 999 });
    getGiftList({
      typeFilter: giftType.backpackGift.value,
      statusFilter: 'all',
      orderBy: 'create_at',
      item: 999,
      page: 1,
    });
    getEffectList({
      filter: 'GIFT',
      sfxFilter: 'TEMPORARY',
      page: 1,
      item: 999,
    });
  }

  /**
   * 取得特效列表
   */
  getDispatchList = (filterText = '', page = 1) => {
    const { getDispatchList } = this.props;
    const { defaultPagination } = this.state;
    this.setState({
      filterText,
      page,
      selectedRowKeys: [],
    });
    getDispatchList({
      giftFilter: 'GIFT',
      filter: filterText,
      item: defaultPagination.pageSize,
      page,
    });
  };

  /**
   * 處理 Table 勾選
   */
  handleSelectRowChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    });
  };

  /**
   * 點選分頁
   */
  handlePageChange = page => {
    this.getDispatchList(this.state.filterText, page);
  };

  /**
   * 新增
   */
  handleAddClick = () => {
    this.setState({ editData: null, isCreateMode: true });
    this.props.showModal();
  };

  /**
   * 編輯
   */
  handleEditClick = () => {
    const { selectedRowKeys } = this.state;
    this.setState({
      isCreateMode: false
    });

    this.props.getDispatch(selectedRowKeys[0]);
    this.props.showModal();
  };

  /**
   * 刪除
   */
  handleDelectClick = () => {
    const { deleteDispatch, totalCount } = this.props;
    const { selectedRowKeys, defaultPagination, page } = this.state;
    const { pageSize } = defaultPagination;
    const deleteSize = selectedRowKeys.length;
    const maxPage = Math.ceil((totalCount - deleteSize) / pageSize);
    const newPage = _.clamp(page, 1, maxPage);
    deleteDispatch(
      selectedRowKeys,
      null,
      this.getDispatchList.bind(this, null, newPage),
    );
    this.setState({ selectedRowKeys: [] });
  };

  /**
   * 收回用戶
   */
  handleWithdrawClick = uuids => {
    const { dispatchId } = this.state;
    this.props.withdrawDispatchUser(
      { dispatchId, uuids },
      getDispatchUsers({ id: dispatchId, page: 1, item: 9999 })
    );
  };

  /**
   * 點擊檢視特效
   */
  handlePreviewEffectClick = dispatch => {
    const { getEffect } = this.props;
    getEffect(dispatch.acmpId);
    this.setState({ showPreviewEffectModal: true });
  };

  /**
   * 查看發送帳號列表
   */
  handleDispatchUserClick = id => {
    this.props.getDispatchUsers({ id, page: 1, item: 9999 });
    this.setState({ showDispatchUsersModal: true, dispatchId: id });
  };

  /**
   * 送出表單
   */
  handleFormSubmit = form => {
    const { addDispatch, setDispatch } = this.props;
    const { isCreateMode, filterText, page } = this.state;
    const data = getSubmitData(form.getFieldsValue());

    if (isCreateMode) {
      addDispatch(
        data,
        getDispatchList({ giftFilter: 'GIFT', filter: '', page: 1 })
      );
      this.setState({ filterText: '', page: 1, selectedRowKeys: [] });
    } else {
      setDispatch(
        data,
        getDispatchList({ giftFilter: 'GIFT', filter: filterText, page })
      );
    }
  };

  /**
   * 關閉表單
   */
  handleFormCancel = () => {
    const { resetDispatch, showModal } = this.props;
    showModal(false);
    resetDispatch();
  };
  /**
   *
   * 關閉發送帳號
   */
  handleDispatchUsersCancel = () => {
    this.setState({ showDispatchUsersModal: false, dispatchId: undefined });
  };

  /**
   * 關閉預覽
   */
  handlePrewGiftCancel = () => {
    const { resetEffect } = this.props;
    this.setState({ showPreviewEffectModal: false });
    resetEffect();
  };

  render() {
    const {
      currentDispatch, currentEffect, data,
      dispatchUsers, effectList, giftList,
      isShowModal, loading, loadingDispatchUsers,
      tagList, totalCount,
    } = this.props;
    const {
      defaultPagination, filterText, isCreateMode,
      page, selectedRowKeys, showDispatchUsersModal,
      showPreviewEffectModal,
    } = this.state;

    return (
      <PageContainer>
        <PageTitle>{'活動管理 > 發送背包禮物'}</PageTitle>
        <SearchBar>
          <Search
            allowClear={true}
            value={filterText}
            style={{ maxWidth: '20rem' }}
            placeholder="請輸入禮物名稱"
            onChange={event => this.setState({ filterText: event.target.value })}
            onSearch={value => this.getDispatchList(value, 1)}
            enterButton
          />
        </SearchBar>
        <ActionBar>
          <Button
            onClick={this.handleAddClick}
          >
            <span>新增</span>
          </Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            onClick={this.handleEditClick}
          >
            <span>編輯</span>
          </Button>
          <Popconfirm
            disabled={selectedRowKeys.length === 0}
            key={'cancel'}
            title="是否確定刪除?"
            placement="right"
            onConfirm={this.handleDelectClick}
          >
            <Button
              type="danger"
              disabled={selectedRowKeys.length === 0}
            >
              <span>刪除</span>
            </Button>
          </Popconfirm>
        </ActionBar>
        <TableContainer>
          <Table
            rowKey="id"
            scroll={{ x: true }}
            columns={this.columns}
            dataSource={data}
            loading={loading}
            rowSelection={{
              selectedRowKeys,
              onChange: this.handleSelectRowChange
            }}
            pagination={{
              ...defaultPagination,
              total: totalCount,
              current: page,
              onChange: this.handlePageChange
            }}
          />
        </TableContainer>
        <PreviewGiftModal
          giftList={giftList}
          effectData={currentEffect}
          visible={showPreviewEffectModal}
          onCancel={this.handlePrewGiftCancel}
        />
        <DispatchUsersModal
          loading={loadingDispatchUsers}
          visible={showDispatchUsersModal}
          onWithdraw={this.handleWithdrawClick}
          data={dispatchUsers}
          onCancel={this.handleDispatchUsersCancel}
        />
        <DispatchGiftFormModal
          isCreateMode={isCreateMode}
          visible={isShowModal}
          loading={loading}
          effectList={effectList}
          giftList={giftList}
          tagList={tagList}
          editData={currentDispatch}
          onOk={this.handleFormSubmit}
          onCancel={this.handleFormCancel}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.dispatchManagement,
  effectList: state.effectManagement.data,
  currentEffect: state.effectManagement.currentEffect,
  giftList: state.giftList.data,
});

const mapDispatchToProps = {
  getTagsList,
  getDispatchList,
  addDispatch,
  getDispatch,
  setDispatch,
  deleteDispatch,
  showModal,
  resetDispatch,
  getDispatchUsers,
  withdrawDispatchUser,
  getEffectList,
  getEffect,
  resetEffect,
  getGiftList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DispatchGiftManagement);
