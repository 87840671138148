import { createAction, handleActions } from 'redux-actions';
import { map, switchMap } from 'rxjs/operators';
import { pipe } from 'ramda';
import { searchAgencyListAPI } from '../apis';
import { catchRequestError, getPayloadByType } from '../utils/extendOperators';

const SEARCH_AGENCY_BY_KEYWORD = 'SEARCH_AGENCY_BY_KEYWORD';
const SEARCH_AGENCY_BY_KEYWORD_SUCCESS = 'SEARCH_AGENCY_BY_KEYWORD_SUCCESS';
const SEARCH_AGENCY_BY_KEYWORD_FAILURE = 'SEARCH_AGENCY_BY_KEYWORD_FAILURE';

export const searchAgencyByKeyword = (keyword, page = 0, size = 999999) =>
  createAction(SEARCH_AGENCY_BY_KEYWORD)({ input: keyword, page, size });

const searchAgencyByKeywordSuccess = createAction(
  SEARCH_AGENCY_BY_KEYWORD_SUCCESS
);
const searchAgencyByKeywordFailure = createAction(
  SEARCH_AGENCY_BY_KEYWORD_FAILURE
);

export const searchAgencyByKeywordEpic = pipe(
  getPayloadByType(SEARCH_AGENCY_BY_KEYWORD),
  switchMap(payload =>
    searchAgencyListAPI(payload).pipe(
      map(searchAgencyByKeywordSuccess),
      catchRequestError(searchAgencyByKeywordFailure)
    )
  )
);

const initialState = {
  input: '',
  page: 0,
  size: 10,
  data: [],
  totalCount: 0,
};

export default handleActions(
  {
    [SEARCH_AGENCY_BY_KEYWORD]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [SEARCH_AGENCY_BY_KEYWORD_SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);
