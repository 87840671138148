import React from 'react';
import { getForbiddenListAPI } from '../../apis';
import styled from 'styled-components';
import { message, Button, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import antdTablePaignation from '../../utils/antdTablePagination';
import { PageContainer, PageTitle } from '../../components/styled/page';
import ForbiddenModal from './ForbiddenModal';
import removeForbidden from './removeForbidden';

const { Column } = Table;

const Tools = styled.div`
  margin-bottom: 1rem;

  button {
    & + button {
      margin-left: 0.5rem;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    border: 0;
    background: none !important;
    box-shadow: none !important;
    font-size: 1.2em;
    overflow: hidden;

    & + button {
      margin-left: 0.5rem;
    }

    &.delete {
      &:hover,
      &:focus {
        color: #ff4d4f;
      }
    }
  }
`;

class ForbiddenManagement extends React.Component {
  constructor(props) {
    super(props);

    this.ForbiddenModalRef = React.createRef();

    this.state = {
      dataset: [],
      pagination: new antdTablePaignation(),
    };
  }

  componentDidMount() {
    this.fetchForbiddenList();
  }

  fetchForbiddenList = () => {
    this.setState({ isLoading: true });

    const { pagination } = this.state;
    const { current, pageSize } = pagination;

    const observable = getForbiddenListAPI({
      page: current,
      item: pageSize
    });
    observable.subscribe((response) => {
      const { data, totalCount } = response;
      const newPagination = Object.assign({}, pagination, {
        total: totalCount,
      });

      this.setState({
        dataset: data,
        pagination: newPagination,
        isLoading: false
      });
    });
  }

  showForbiddenModal = (action, data) => {
    const modal = this.ForbiddenModalRef.current;
    modal.show(action, data);
  };

  onCreateBtnClick = () => {
    this.showForbiddenModal('create');
  };

  onRowEdit = (rowData) => {
    this.showForbiddenModal('edit', rowData);
  }

  onRowDelete = (rowData) => {
    const { id } = rowData;
    removeForbidden(id, (response) => {
      const { Status } = response;
      if (Status !== 'Ok') {
        message.error('禁詞刪除失敗');
        return;
      }
      this.fetchForbiddenList();
    });
  }

  onTableChange = iPagination => {
    const { pagination } = this.state;
    const newPagination = Object.assign({}, pagination, {
      current: iPagination.current,
    });

    this.setState({ pagination: newPagination }, () => {
      this.fetchForbiddenList();
    });
  };

  renderActions = (rowData) => {
    return (
      <Actions>
        <Tooltip title="編輯">
          <Button onClick={() => this.onRowEdit(rowData)}>
            <EditOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="刪除">
          <Button
            className="delete"
            onClick={() => this.onRowDelete(rowData)}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </Actions>
    );
  }

  render() {
    const { dataset, isLoading, pagination } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'APP 管理 > 禁詞管理'}</PageTitle>
        <Tools>
          <Button
            type="primary"
            onClick={this.onCreateBtnClick}
          >
            <span>新增</span>
          </Button>
        </Tools>
        <Table
          rowKey="id"
          dataSource={dataset}
          loading={isLoading}
          pagination={pagination}
          onChange={this.onTableChange}
        >
          <Column title="ID" dataIndex="id" key="id" />
          <Column title="禁詞內容" dataIndex="word" key="word" />
          <Column
            title="功能"
            key="actions"
            width="8rem"
            render={this.renderActions}
          />
        </Table>
        <ForbiddenModal
          ref={this.ForbiddenModalRef}
          onCompleted={this.fetchForbiddenList}
        />
      </PageContainer>
    );
  }
}

export default ForbiddenManagement;
