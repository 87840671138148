import { message } from 'antd';
import produce from 'immer';
import { evolve, groupBy, pathOr, toUpper } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  mergeMap,
  pluck,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { catchRequestError, ofType } from '../utils/extendOperators';
import { createRequestTypes } from '../actions/Types';
import {
  addAdBannerAPI,
  deleteAdBannerAPI,
  getAdBannerListAPI,
  setAdBannerAPI
} from '../apis';
export const defaultQuery = {
  zone: '',
  type: '',
  page: 1,
  item: 20
};

/**Type */
const GET_AD_BANNER_LIST = createRequestTypes('GET_AD_BANNER_LIST');
const SHOW_ADD_AD_MANAGEMENT_MODAL = 'SHOW_ADD_AD_MANAGEMENT_MODAL';
const CLOSE_ADD_AD_MANAGEMENT_MODAL = 'CLOSE_ADD_AD_MANAGEMENT_MODAL';
const ADD_AD_BANNER = createRequestTypes('ADD_AD_BANNER');
const SET_AD_BANNER = createRequestTypes('SET_AD_BANNER');
const DELETE_AD_BANNER = createRequestTypes('DELETE_AD_BANNER');
const UPDATE_QUERY = 'UPDATE_AD_MANAGEMENT_QUERY';

/**
 * Action Creator
 */
export const getAdBannerList = createAction(
  GET_AD_BANNER_LIST.REQUEST,
  ({
    zone = defaultQuery.zone,
    type = defaultQuery.type,
    item = defaultQuery.item,
    page = defaultQuery.page
  }) => ({ zone: zone.toUpperCase(), type, item, page })
);
export const getAdBannerListSuccess = createAction(GET_AD_BANNER_LIST.SUCCESS);
export const getAdBannerListFailure = createAction(GET_AD_BANNER_LIST.FAILURE);
export const deleteAdBanner = createAction(
  DELETE_AD_BANNER.REQUEST,
  (ids = []) => ids
);
export const deleteAdBannerSuccess = createAction(DELETE_AD_BANNER.SUCCESS);
export const deleteAdBannerFailure = createAction(DELETE_AD_BANNER.FAILURE);
export const showModal = createAction(SHOW_ADD_AD_MANAGEMENT_MODAL);
export const closeModal = createAction(CLOSE_ADD_AD_MANAGEMENT_MODAL);
export const addAdBanner = createAction(ADD_AD_BANNER.REQUEST);
export const addAdBannerSuccess = createAction(ADD_AD_BANNER.SUCCESS);
export const addAdBannerFailure = createAction(ADD_AD_BANNER.FAILURE);
export const setAdBanner = createAction(SET_AD_BANNER.REQUEST);
export const setAdBannerSuccess = createAction(SET_AD_BANNER.SUCCESS);
export const setAdBannerFailure = createAction(SET_AD_BANNER.FAILURE);
export const updateQuery = createAction(
  UPDATE_QUERY,
  evolve({ zone: toUpper })
);

/**
 * Epics
 */
export const getAdBannerListEpic = action$ =>
  action$.pipe(
    ofType(GET_AD_BANNER_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getAdBannerListAPI(payload).pipe(
        mergeMap(res => [getAdBannerListSuccess(res)]),
        catchRequestError(getAdBannerListFailure)
      )
    )
  );

export const deleteAdBannersEpic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_AD_BANNER.REQUEST),
    pluck('payload'),
    switchMap(ids => {
      return forkJoin(
        ids.map(id =>
          deleteAdBannerAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      );
    }),
    withLatestFrom(state$),
    mergeMap(
      ([
        res,
        {
          adManagement: { zone, type, page, items }
        }
      ]) => {
        const errors = groupBy(v => v.Status)(res);
        if (errors['Error'] && errors['Error'].length > 0) {
          message.error(
            `Delete Fail: ${errors['Error']
              .map(e => `id:${e.id} message: ${e.Message}`)
              .join(', ')}`
          );
          return [
            deleteAdBannerFailure(),
            getAdBannerList({ zone, type, page, items })
          ];
        } else {
          return [
            deleteAdBannerSuccess(),
            getAdBannerList({ zone, type, page, items })
          ];
        }
      }
    )
  );

export const addAdBannerEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_AD_BANNER.REQUEST),
    pluck('payload'),
    mergeMap(payload =>
      addAdBannerAPI(payload).pipe(
        withLatestFrom(state$),
        mergeMap(
          ([
            res,
            {
              adManagement: { zone, type, page, item }
            }
          ]) => [
            addAdBannerSuccess(res),
            getAdBannerList({
              zone,
              type,
              page,
              item
            })
          ]
        ),
        catchRequestError(addAdBannerFailure)
      )
    )
  );

export const setAdBannerEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_AD_BANNER.REQUEST),
    pluck('payload'),
    mergeMap(payload =>
      setAdBannerAPI(payload).pipe(
        withLatestFrom(state$),
        mergeMap(
          ([
            res,
            {
              adManagement: { zone, type, page, item }
            }
          ]) => [
            setAdBannerSuccess(res),
            getAdBannerList({
              zone,
              type,
              page,
              item
            })
          ]
        ),
        catchRequestError(setAdBannerFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  loading: false,
  isModalOpen: false,
  data: [],
  zone: defaultQuery.zone,
  type: defaultQuery.type,
  page: defaultQuery.page,
  item: defaultQuery.item,
  totalCount: 0
};

export default handleActions(
  {
    [GET_AD_BANNER_LIST.REQUEST]: produce(
      (draft, { payload: { zone, type } }) => {
        draft.loading = true;
        draft.zone = zone.toUpperCase();
        draft.type = type;
      }
    ),
    [GET_AD_BANNER_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
      draft.loading = false;
    }),
    [combineActions(
      GET_AD_BANNER_LIST.FAILURE,
      DELETE_AD_BANNER.SUCCESS,
      DELETE_AD_BANNER.FAILURE
    )]: produce(draft => {
      draft.loading = false;
    }),
    [SHOW_ADD_AD_MANAGEMENT_MODAL]: produce(draft => {
      draft.isModalOpen = true;
    }),
    [CLOSE_ADD_AD_MANAGEMENT_MODAL]: produce(draft => {
      draft.isModalOpen = false;
    }),
    [combineActions(
      ADD_AD_BANNER.REQUEST,
      SET_AD_BANNER.REQUEST,
      DELETE_AD_BANNER.REQUEST
    )]: produce(draft => {
      draft.loading = true;
    }),
    [combineActions(
      ADD_AD_BANNER.SUCCESS,
      ADD_AD_BANNER.FAILURE,
      SET_AD_BANNER.SUCCESS,
      SET_AD_BANNER.FAILURE
    )]: produce(draft => {
      draft.isModalOpen = false;
      draft.loading = false;
    }),
    [UPDATE_QUERY]: produce(
      (draft, { payload: { zone, type, item, page } }) => {
        draft.zone = zone || draft.zone;
        draft.type = type || draft.type;
        draft.item = item || draft.item;
        draft.page = page || draft.page;
      }
    )
  },
  initialState
);
