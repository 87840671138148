import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import { ofType } from 'redux-observable';
import { createRequestTypes } from '../actions/Types';
import {
  switchMap,
  map,
  pluck,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators';
import {
  getSupportListAPI,
  getSupportAPI,
  setSupportAPI,
  getSupportCategoryAPI
} from '../apis';
import { catchRequestError } from '../utils/extendOperators';
const SHOW_MODAL = 'ENQUIRYMANAGEMENT.SHOW_MODAL';
const GET_SUPPORT_CATEGORY = createRequestTypes('GET_SUPPORT_CATEGORY');
const GET_SUPPORT_LIST = createRequestTypes('GET_SUPPORT_LIST');
const GET_SUPPORT = createRequestTypes('GET_SUPPORT');
const SET_SUPPORT = createRequestTypes('SET_SUPPORT');
const RESET_SUPPORT = 'RESET_SUPPORT';

/**
 * Creator
 */
export const showModal = createAction(SHOW_MODAL);
export const resetSupportData = createAction(RESET_SUPPORT);
export const getSupportCategory = createAction(GET_SUPPORT_CATEGORY.REQUEST);
export const getSupportCategorySuccess = createAction(
  GET_SUPPORT_CATEGORY.SUCCESS
);
export const getSupportCategoryFailure = createAction(
  GET_SUPPORT_CATEGORY.FAILURE
);
export const getSupportList = createAction(
  GET_SUPPORT_LIST.REQUEST,
  payload => ({ ...payload, item: initialState.search.item })
);
export const getSupportListSuccess = createAction(GET_SUPPORT_LIST.SUCCESS);
export const getSupportListFailure = createAction(GET_SUPPORT_LIST.FAILURE);
export const getSupport = createAction(GET_SUPPORT.REQUEST);
export const getSupportSuccess = createAction(GET_SUPPORT.SUCCESS);
export const getSupportFailure = createAction(GET_SUPPORT.FAILURE);
export const setSupport = createAction(SET_SUPPORT.REQUEST);
export const setSupportSuccess = createAction(SET_SUPPORT.SUCCESS);
export const setSupportFailure = createAction(SET_SUPPORT.FAILURE);

/**
 * Epic
 */
export const getSupportCategoryEpic = action$ =>
  action$.pipe(
    ofType(GET_SUPPORT_CATEGORY.REQUEST),
    pluck('payload'),
    switchMap(() =>
      getSupportCategoryAPI().pipe(
        map(getSupportCategorySuccess),
        catchRequestError(getSupportCategoryFailure)
      )
    )
  );

export const getSupportListEpic = action$ =>
  action$.pipe(
    ofType(GET_SUPPORT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getSupportListAPI(payload).pipe(
        map(getSupportListSuccess),
        catchRequestError(getSupportListFailure)
      )
    )
  );
export const getSupportEpic = action$ =>
  action$.pipe(
    ofType(GET_SUPPORT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getSupportAPI(payload).pipe(
        map(getSupportSuccess),
        catchRequestError(getSupportFailure)
      )
    )
  );

export const setSupportEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_SUPPORT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      setSupportAPI(payload).pipe(
        withLatestFrom(state$.pipe(pluck('enquiryManagement', 'search'))),
        mergeMap(([value, search]) => [
          setSupportSuccess(value),
          getSupportList(search)
        ]),
        catchRequestError(setSupportFailure)
      )
    )
  );

const initialState = {
  search: {
    startTime: '',
    endTime: '',
    keyword: '',
    status: '',
    UUID: [],
    category: '',
    page: 1,
    item: 10
  },
  data: [],
  category: [],
  supportData: null,
  totalCount: 0,
  isShowModal: false,
  loading: false
};

export default handleActions(
  {
    [combineActions(
      GET_SUPPORT.REQUEST,
      SET_SUPPORT.REQUEST,
      GET_SUPPORT_CATEGORY.REQUEST
    )]: produce(draft => {
      draft.loading = true;
    }),
    [GET_SUPPORT.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.supportData = payload.data;
    }),
    [combineActions(GET_SUPPORT.FAILURE, RESET_SUPPORT)]: produce(draft => {
      draft.loading = false;
      draft.supportData = null;
    }),
    [GET_SUPPORT_LIST.REQUEST]: produce((draft, { payload }) => {
      draft.loading = true;
      draft.search = payload;
    }),
    [GET_SUPPORT_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
    }),
    [GET_SUPPORT_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [SET_SUPPORT.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.isShowModal = false;
      draft.supportData = null;
    }),
    [SET_SUPPORT.FAILURE]: produce(draft => {
      draft.loading = false;
    }),
    [GET_SUPPORT_CATEGORY.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.category = payload.data;
    }),
    [GET_SUPPORT_CATEGORY.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.category = [];
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
