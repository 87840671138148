import { Modal } from 'antd';
import { deleteBackendTagAPI } from '../../apis';

const { confirm } = Modal;

function removeTag(id, onCompleted) {
  confirm({
    title: '刪除標籤',
    content: '標籤刪除後，將無法復原，確定要刪除？',
    okType: 'danger',
    centered: true,
    maskClosable: true,
    onOk() {
      const observable = deleteBackendTagAPI(id);
      observable.subscribe(onCompleted);
    }
  });
}

export default removeTag;
