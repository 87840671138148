module.exports = [
  {
    _id: '59bccebd180688de716c2a26',
    index: 0,
    guid: '4142014a-3523-47b9-9d19-f3993e154322',
    anchorName: 'Leta Herrera',
    nickname: 'nisi',
    accountName: 'potts8482',
    status: 'suspended',
    point: 14,
    gender: 'female',
    liveHour: 47,
    phone: '0992-151-338',
    coinAmount: 262,
    coinType: 'zcash',
    shareRate: 39,
    fansNumber: 5018,
  },
  {
    _id: '59bccebd6f20fae6cfbf0cf3',
    index: 1,
    guid: '440b8de4-cac5-4dac-b327-4507ab838277',
    anchorName: 'Meadows Kennedy',
    nickname: 'consectetur',
    accountName: 'tami3300',
    status: 'normal',
    point: 75,
    gender: 'female',
    liveHour: 157,
    phone: '0999-945-424',
    coinAmount: 297,
    coinType: 'ethereum',
    shareRate: 47,
    fansNumber: 573,
  },
  {
    _id: '59bccebdbce0e64d3575f4f7',
    index: 2,
    guid: 'e7180542-2ec0-4d14-92d1-4cecb9b9ba9b',
    anchorName: 'Walls Freeman',
    nickname: 'deserunt',
    accountName: 'graves1900',
    status: 'normal',
    point: 46,
    gender: 'male',
    liveHour: 77,
    phone: '0997-851-137',
    coinAmount: 809,
    coinType: 'bitcoin',
    shareRate: 11,
    fansNumber: 2373,
  },
  {
    _id: '59bccebdad2a134e69eb37e1',
    index: 3,
    guid: 'afecccdb-a964-47a8-99cc-ce1b8946a3fb',
    anchorName: 'Lara Salas',
    nickname: 'consequat',
    accountName: 'jane5738',
    status: 'suspended',
    point: 81,
    gender: 'female',
    liveHour: 1,
    phone: '0993-856-134',
    coinAmount: 86,
    coinType: 'ethereum',
    shareRate: 14,
    fansNumber: 5299,
  },
  {
    _id: '59bccebd67c13ded0b82a219',
    index: 4,
    guid: '699294b9-031d-4d50-8ddf-ee874a75a24d',
    anchorName: 'Jenny Pitts',
    nickname: 'aliqua',
    accountName: 'beach9336',
    status: 'normal',
    point: 68,
    gender: 'male',
    liveHour: 270,
    phone: '0991-653-527',
    coinAmount: 836,
    coinType: 'bitcoin',
    shareRate: 31,
    fansNumber: 8588,
  },
  {
    _id: '59bccebdc3097893347bfd6e',
    index: 5,
    guid: 'c2b5bd40-c301-4c68-88b9-457ad19561ae',
    anchorName: 'Margarita Orr',
    nickname: 'nostrud',
    accountName: 'lowe4295',
    status: 'suspended',
    point: 39,
    gender: 'female',
    liveHour: 226,
    phone: '0995-751-821',
    coinAmount: 32,
    coinType: 'ethereum',
    shareRate: 59,
    fansNumber: 9822,
  },
  {
    _id: '59bccebd4c5afa62b63cdad9',
    index: 6,
    guid: '7a635832-cfc1-4b99-97af-43f9880e9258',
    anchorName: 'Irwin Pope',
    nickname: 'commodo',
    accountName: 'katy3410',
    status: 'normal',
    point: 98,
    gender: 'male',
    liveHour: 283,
    phone: '0991-552-939',
    coinAmount: 355,
    coinType: 'zcash',
    shareRate: 66,
    fansNumber: 7872,
  },
  {
    _id: '59bccebde6172314781e69c9',
    index: 7,
    guid: '30f9c934-0384-4060-8963-6deab22089cb',
    anchorName: 'Carpenter Boyer',
    nickname: 'laboris',
    accountName: 'gail7880',
    status: 'suspended',
    point: 30,
    gender: 'female',
    liveHour: 196,
    phone: '0982-246-527',
    coinAmount: 823,
    coinType: 'bitcoin',
    shareRate: 21,
    fansNumber: 6369,
  },
  {
    _id: '59bccebdff45d2b3f2ea621f',
    index: 8,
    guid: 'b4274034-132a-4e8d-997d-04ad2b395726',
    anchorName: 'Mcmahon Mcknight',
    nickname: 'pariatur',
    accountName: 'kay7365',
    status: 'suspended',
    point: 68,
    gender: 'female',
    liveHour: 149,
    phone: '0989-147-239',
    coinAmount: 957,
    coinType: 'zcash',
    shareRate: 50,
    fansNumber: 3009,
  },
  {
    _id: '59bccebd9ab7f2addb28ec05',
    index: 9,
    guid: '1459320f-6a56-4de0-8d82-5f0b343b303c',
    anchorName: 'Mckee Randolph',
    nickname: 'eu',
    accountName: 'rivas7285',
    status: 'suspended',
    point: 38,
    gender: 'female',
    liveHour: 165,
    phone: '0990-759-021',
    coinAmount: 232,
    coinType: 'bitcoin',
    shareRate: 62,
    fansNumber: 4460,
  },
  {
    _id: '59bccebd0c2e099d194c77ec',
    index: 10,
    guid: '1c36d79f-6391-45b5-9f98-d194c45a35fb',
    anchorName: 'Cotton Thornton',
    nickname: 'reprehenderit',
    accountName: 'simone1154',
    status: 'suspended',
    point: 1,
    gender: 'male',
    liveHour: 32,
    phone: '0989-249-639',
    coinAmount: 319,
    coinType: 'zcash',
    shareRate: 21,
    fansNumber: 2513,
  },
  {
    _id: '59bccebd292cfa0a07f5bbc1',
    index: 11,
    guid: '31fe42d5-f423-4c78-bca8-9f58da73097a',
    anchorName: 'Henry Nielsen',
    nickname: 'velit',
    accountName: 'jennie3111',
    status: 'suspended',
    point: 35,
    gender: 'female',
    liveHour: 260,
    phone: '0985-151-639',
    coinAmount: 640,
    coinType: 'zcash',
    shareRate: 18,
    fansNumber: 8156,
  },
  {
    _id: '59bccebd864373dc716650a1',
    index: 12,
    guid: '673119c2-bcb9-4753-8333-e3a20abaf3f2',
    anchorName: 'Shelby Gamble',
    nickname: 'mollit',
    accountName: 'thomas5599',
    status: 'normal',
    point: 72,
    gender: 'male',
    liveHour: 186,
    phone: '0993-744-221',
    coinAmount: 940,
    coinType: 'bitcoin',
    shareRate: 37,
    fansNumber: 3370,
  },
  {
    _id: '59bccebd770a67550cda93e4',
    index: 13,
    guid: '6ce81536-7069-49e8-a05b-066d2005c66a',
    anchorName: 'Aguirre House',
    nickname: 'irure',
    accountName: 'sweeney8328',
    status: 'suspended',
    point: 89,
    gender: 'female',
    liveHour: 108,
    phone: '0985-143-422',
    coinAmount: 157,
    coinType: 'zcash',
    shareRate: 94,
    fansNumber: 5996,
  },
  {
    _id: '59bccebdd9f82fa7f946831e',
    index: 14,
    guid: 'd1afb875-14bf-4455-9290-c865b69ed869',
    anchorName: 'Muriel Rowe',
    nickname: 'consequat',
    accountName: 'flynn9970',
    status: 'suspended',
    point: 7,
    gender: 'male',
    liveHour: 186,
    phone: '0980-950-525',
    coinAmount: 859,
    coinType: 'bitcoin',
    shareRate: 77,
    fansNumber: 6923,
  },
  {
    _id: '59bccebdc37e3d357734a634',
    index: 15,
    guid: '72c55398-7a8d-45bc-960a-a6676808f599',
    anchorName: 'Oconnor Clayton',
    nickname: 'reprehenderit',
    accountName: 'hansen7326',
    status: 'normal',
    point: 87,
    gender: 'female',
    liveHour: 6,
    phone: '0982-450-126',
    coinAmount: 937,
    coinType: 'ethereum',
    shareRate: 95,
    fansNumber: 2150,
  },
  {
    _id: '59bccebd27351f216f34d3c0',
    index: 16,
    guid: '492d369a-5bd5-453d-8c62-c8d099d30540',
    anchorName: 'Morton Stephens',
    nickname: 'ex',
    accountName: 'chavez8202',
    status: 'normal',
    point: 17,
    gender: 'female',
    liveHour: 49,
    phone: '0995-043-739',
    coinAmount: 884,
    coinType: 'bitcoin',
    shareRate: 47,
    fansNumber: 3721,
  },
  {
    _id: '59bccebda9734ceaf2d3e65c',
    index: 17,
    guid: '37e085a9-0eb3-42b7-9325-dabd3d8a8c3c',
    anchorName: 'Guadalupe Alford',
    nickname: 'laborum',
    accountName: 'millie6078',
    status: 'suspended',
    point: 20,
    gender: 'female',
    liveHour: 144,
    phone: '0995-144-229',
    coinAmount: 779,
    coinType: 'ethereum',
    shareRate: 44,
    fansNumber: 6300,
  },
  {
    _id: '59bccebd738c9a9fb74e75dd',
    index: 18,
    guid: 'eeb2a046-b5a2-4226-9a2c-5c65396ae29a',
    anchorName: 'Reba Wells',
    nickname: 'fugiat',
    accountName: 'fowler2664',
    status: 'suspended',
    point: 10,
    gender: 'female',
    liveHour: 176,
    phone: '0992-656-721',
    coinAmount: 438,
    coinType: 'bitcoin',
    shareRate: 28,
    fansNumber: 9887,
  },
  {
    _id: '59bccebdc9aec27292f1d999',
    index: 19,
    guid: '13912113-e034-4e18-a8a9-f7afc31564d3',
    anchorName: 'Cannon Rosario',
    nickname: 'esse',
    accountName: 'andrea6134',
    status: 'normal',
    point: 70,
    gender: 'female',
    liveHour: 149,
    phone: '0985-045-934',
    coinAmount: 735,
    coinType: 'zcash',
    shareRate: 55,
    fansNumber: 1303,
  },
  {
    _id: '59bccebdae0a40af36a5b234',
    index: 20,
    guid: '1d2c025d-9cc0-41c8-ab36-8aff92c51ab3',
    anchorName: 'Jessica Armstrong',
    nickname: 'ut',
    accountName: 'deloris3013',
    status: 'suspended',
    point: 86,
    gender: 'male',
    liveHour: 299,
    phone: '0981-759-724',
    coinAmount: 117,
    coinType: 'zcash',
    shareRate: 42,
    fansNumber: 3905,
  },
];
