import _ from 'lodash';
import { pipe, groupBy } from 'ramda';
import { createAction, handleActions, combineActions } from 'redux-actions';
import produce from 'immer';
import { message, Modal } from 'antd';
import { forkJoin } from 'rxjs';
import { pluck, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  searchAPI,
  getUserInfoAPI,
  sendUserAPI,
  modifyAccountAPI,
  modifyRoleAPI,
  editUserPointAPI,
  getTransactionListAPI,
  getUsageListAPI,
  resetNicknameAPI,
  getNicknameResetHistoryListAPI,
  getUserBackpackListAPI
} from '../apis';
import { ofType, catchRequestError } from '../utils/extendOperators';
import { LIVE_MASTER_ACCOUNT_MANAGEMENT_PERSONAL } from '../constants/routes';

/**
 * Action Types
 */
const GET_USER_LIST = 'GET_USER_LIST';
const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';
const GET_USER_INFO = createRequestTypes('GET_USER_INFO');
const GET_USER_USE_POINT_HISTORY = createRequestTypes(
  'GET_USER_USE_POINT_HISTORY'
);
const GET_USER_SAVE_POINT_HISTORY = createRequestTypes(
  'GET_USER_SAVE_POINT_HISTORY'
);
const SEND_USER_INFO = createRequestTypes('SEND_USER_INFO');
const EDIT_USER_POINT = createRequestTypes('EDIT_USER_POINT');
const DISABLE_USER = 'DISABLE_USER';
const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
const DISABLE_USER_FAILURE = 'DISABLE_USER_FAILURE';
const CLOSE_ACCOUNT_MODAL = 'CLOSE_ACCOUNT_MODAL';
const OPEN_ACCOUNT_MODAL = createRequestTypes('OPEN_ACCOUNT_MODAL');
const MODIFY_ACCOUNT = 'MODIFY_ACCOUNT';
const MODIFY_ACCOUNT_SUCCESS = 'MODIFY_ACCOUNT_SUCCESS';
const MODIFY_ACCOUNT_FAILURE = 'MODIFY_ACCOUNT_FAILURE';
const OPEN_ROLE_MODAL = 'OPEN_ROLE_MODAL';
const CLOSE_ROLE_MODAL = 'CLOSE_ROLE_MODAL';
const MODIFY_ROLE = 'MODIFY_ROLE';
const MODIFY_ROLE_SUCCESS = 'MODIFY_ROLE_SUCCESS';
const MODIFY_ROLE_FAILURE = 'MODIFY_ROLE_FAILURE';
const RESET_NICKNAME = createRequestTypes('RESET_NICKNAME');
const GET_NICKNAME_HISTORY_LIST = createRequestTypes(
  'GET_NICKNAME_HISTORY_LIST'
);
const GET_USER_BACKPACK_LIST = createRequestTypes('GET_USER_BACKPACK_LIST');

export const defaultQuery = {
  keyword: '',
  type: 'login_id',
  statusFilter: [1],
  roleFilter: null,
  item: 20,
  page: 1
};
/**
 * Action Creator
 */
export const getUserList = ({
  keyword = defaultQuery.keyword,
  type = defaultQuery.type,
  statusFilter = defaultQuery.statusFilter,
  roleFilter = defaultQuery.roleFilter,
  page = defaultQuery.page,
  item = defaultQuery.item
}) =>
  createAction(GET_USER_LIST)({
    keyword,
    type,
    statusFilter,
    roleFilter,
    page,
    item
  });
export const getUserUsePointHistory = ({
  id,
  startTime,
  endTime,
  page = 1,
  item = 20
}) =>
  createAction(GET_USER_USE_POINT_HISTORY.REQUEST)({
    id,
    startTime,
    endTime,
    page,
    item
  });
export const getUserSavePointHistory = ({
  id,
  startTime,
  endTime,
  page = 1,
  item = 20
}) =>
  createAction(GET_USER_SAVE_POINT_HISTORY.REQUEST)({
    id,
    startTime,
    endTime,
    page,
    item
  });
const getUserListSuccess = createAction(GET_USER_LIST_SUCCESS);
const getUserListFailure = createAction(GET_USER_LIST_FAILURE);
export const getUserInfo = createAction(GET_USER_INFO.REQUEST);
export const sendUserInfo = createAction(SEND_USER_INFO.REQUEST);
export const editUserPoint = createAction(EDIT_USER_POINT.REQUEST);
export const disableUser = createAction(DISABLE_USER, (ids, search) => ({
  ids: ids,
  search: search
}));
export const disableUserSuccess = createAction(DISABLE_USER_SUCCESS);
export const disableUserFailure = createAction(DISABLE_USER_FAILURE);
export const openAccountModal = createAction(OPEN_ACCOUNT_MODAL.REQUEST);
export const closeAccountModal = createAction(CLOSE_ACCOUNT_MODAL);
export const modifyAccount = (id = '', account = {}, search) =>
  createAction(MODIFY_ACCOUNT)({ id, account, search });
const modifyAccountSuccess = createAction(MODIFY_ACCOUNT_SUCCESS);
const modifyAccountFailure = createAction(MODIFY_ACCOUNT_FAILURE);
export const openRoleModal = createAction(OPEN_ROLE_MODAL);
export const closeRoleModal = createAction(CLOSE_ROLE_MODAL);
export const modifyRole = (roleInfo = {}, search) =>
  createAction(MODIFY_ROLE)({ roleInfo, search });
const modifyRoleSuccess = createAction(MODIFY_ROLE_SUCCESS);
const modifyRoleFailure = createAction(MODIFY_ROLE_FAILURE);
export const resetNickname = createAction(RESET_NICKNAME.REQUEST, id => ({
  userId: id
}));
export const resetNicknameSuccess = createAction(RESET_NICKNAME.SUCCESS);
export const resetNicknameFailure = createAction(RESET_NICKNAME.FAILURE);

export const getNicknameHistoryList = createAction(
  GET_NICKNAME_HISTORY_LIST.REQUEST
);
export const getNicknameHistoryListSuccess = createAction(
  GET_NICKNAME_HISTORY_LIST.SUCCESS
);
export const getNicknameHistoryListFailure = createAction(
  GET_NICKNAME_HISTORY_LIST.FAILURE
);
export const getUserBackpackList = createAction(GET_USER_BACKPACK_LIST.REQUEST);
export const getUserBackpackListSuccess = createAction(
  GET_USER_BACKPACK_LIST.SUCCESS
);
export const getUserBackpackListFailure = createAction(
  GET_USER_BACKPACK_LIST.FAILURE
);

/**
 * Epics
 */
export const disableUserEpic = pipe(
  ofType(DISABLE_USER),
  pluck('payload'),
  mergeMap(payload => {
    return forkJoin(
      payload.ids.map(id => {
        return sendUserAPI(id, { identityStatus: -2 }).pipe(
          catchRequestError(e => {
            return { Status: 'error', id: id, message: e.message };
          })
        );
      })
    ).pipe(map(e => ({ result: e, search: payload.search })));
  }),
  mergeMap(e => {
    const errors = groupBy(v => v.Status)(e.result);
    if (errors['error'] && errors['error'].length > 0) {
      return [disableUserFailure()];
    } else {
      if (e.search) {
        return [disableUserSuccess(), getUserList(e.search)];
      } else {
        return [disableUserSuccess()];
      }
    }
  })
);

export const getUserListEpic = pipe(
  ofType(GET_USER_LIST),
  map(action => action.payload),
  switchMap(payload =>
    searchAPI(payload).pipe(
      map(res => getUserListSuccess(res)),
      catchRequestError(getUserListFailure)
    )
  )
);

export const getUserInfoEpic = pipe(
  ofType(GET_USER_INFO.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    getUserInfoAPI(payload).pipe(
      map(createAction(GET_USER_INFO.SUCCESS)),
      catchRequestError(createAction(GET_USER_INFO.FAILURE))
    )
  )
);

export const openAccountModalEpic = pipe(
  ofType(OPEN_ACCOUNT_MODAL.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    getUserInfoAPI(payload).pipe(
      map(createAction(OPEN_ACCOUNT_MODAL.SUCCESS)),
      catchRequestError(createAction(OPEN_ACCOUNT_MODAL.FAILURE))
    )
  )
);

export const getUserUsePointHistoryEpic = pipe(
  ofType(GET_USER_USE_POINT_HISTORY.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    getUsageListAPI(payload).pipe(
      map(res => createAction(GET_USER_USE_POINT_HISTORY.SUCCESS)(res)),
      catchRequestError(createAction(GET_USER_USE_POINT_HISTORY.FAILURE))
    )
  )
);

export const getUserSavePointHistoryEpic = pipe(
  ofType(GET_USER_SAVE_POINT_HISTORY.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    getTransactionListAPI(payload).pipe(
      map(res => createAction(GET_USER_SAVE_POINT_HISTORY.SUCCESS)(res)),
      catchRequestError(createAction(GET_USER_SAVE_POINT_HISTORY.FAILURE))
    )
  )
);

export const sendUserInfoEpic = pipe(
  ofType(SEND_USER_INFO.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    sendUserAPI(payload.id, payload).pipe(
      tap(() => {
        message.success('修改成功');
      }),
      map(createAction(SEND_USER_INFO.SUCCESS)),
      catchRequestError(createAction(SEND_USER_INFO.FAILURE))
    )
  )
);

export const editUserPointEpic = pipe(
  ofType(EDIT_USER_POINT.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    editUserPointAPI(payload).pipe(
      tap(() => {
        message.success('修改點數成功');
      }),
      map(createAction(EDIT_USER_POINT.SUCCESS)),
      catchRequestError(
        pipe(error => {
          message.error('修改點數失敗');
          return error;
        }, createAction(EDIT_USER_POINT.FAILURE))
      )
    )
  )
);

export const modifyAccountEpic = pipe(
  ofType(MODIFY_ACCOUNT),
  map(action => action.payload),
  switchMap(payload =>
    modifyAccountAPI(payload.id, payload.account).pipe(
      tap(() => {
        message.success('帳號設定成功');
      }),
      mergeMap(res => [modifyAccountSuccess(res), getUserList(payload.search)]),
      catchRequestError(
        pipe(error => {
          message.error('帳號設定失敗');
          return error;
        }, modifyAccountFailure)
      )
    )
  )
);

const onRoleErrorNeedWithdraw = payload => {
  const userID = _.get(payload, 'roleInfo.ids[0]');
  Modal.confirm({
    title: '尚有未結算業績',
    content: '該直播主有未結算業績點數，需要前往結算',
    okText: '前往結算',
    cancelText: '取消',
    onOk: () => {
      const url = `${LIVE_MASTER_ACCOUNT_MANAGEMENT_PERSONAL}/${userID}`;
      window.location.assign(url);
    }
  });
};

export const modifyRoleEpic = pipe(
  ofType(MODIFY_ROLE),
  map(action => action.payload),
  switchMap(payload =>
    modifyRoleAPI(payload.roleInfo).pipe(
      tap(() => {
        message.success('權限設定成功');
      }),
      mergeMap(res => [modifyRoleSuccess(res), getUserList(payload.search)]),
      catchRequestError(
        pipe(error => {
          const { response } = error;
          const { Message } = response;
          if (Message === 'NEED_WITHDRAW') {
            onRoleErrorNeedWithdraw(payload);
          } else {
            message.error('權限設定失敗');
          }
          return error;
        }, modifyRoleFailure)
      )
    )
  )
);

export const resetNicknameEpic = pipe(
  ofType(RESET_NICKNAME.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    resetNicknameAPI(payload).pipe(
      tap(() => {
        message.success('重置成功');
      }),
      map(createAction(RESET_NICKNAME.SUCCESS)),
      catchRequestError(createAction(RESET_NICKNAME.FAILURE))
    )
  )
);

export const getNicknameHistoryListEpic = pipe(
  ofType(GET_NICKNAME_HISTORY_LIST.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    getNicknameResetHistoryListAPI(payload).pipe(
      map(createAction(GET_NICKNAME_HISTORY_LIST.SUCCESS)),
      catchRequestError(createAction(GET_NICKNAME_HISTORY_LIST.FAILURE))
    )
  )
);

export const getUserBackpackListEpic = action$ =>
  action$.pipe(
    ofType(GET_USER_BACKPACK_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getUserBackpackListAPI(payload).pipe(
        map(getUserBackpackListSuccess),
        catchRequestError(getUserBackpackListFailure)
      )
    )
  );

const initialState = {
  userList: [],
  item: defaultQuery.item,
  page: defaultQuery.page,
  totalCount: 0,
  loading: false,
  isAccountModalVisible: false,
  isRoleModalVisible: false,
  formData: {
    currencyList: []
  },
  nicknameHistory: {
    isLoading: false,
    data: [],
    totalCount: 0
  },
  formValue: {},
  pointHistory: {
    use: {},
    save: {},
    isLoading: false
  },
  backpack: {
    isLoading: false,
    totalCount: 0,
    data: []
  }
};

const userManagement = handleActions(
  {
    [GET_USER_LIST]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: true
    }),
    [GET_USER_LIST_FAILURE]: (state, action) => ({
      ...state,
      loading: false
    }),
    [GET_USER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      userList: action.payload.data,
      totalCount: action.payload.totalCount
    }),
    [GET_USER_INFO.REQUEST]: (state, action) => ({
      ...state,
      loading: true,
      formValue: {}
    }),
    [GET_USER_INFO.SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      formValue: action.payload
    }),
    [GET_USER_INFO.FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      formValue: {}
    }),
    [OPEN_ACCOUNT_MODAL.REQUEST]: (state, action) => ({
      ...state,
      loading: true,
      isAccountModalVisible: false
    }),
    [OPEN_ACCOUNT_MODAL.SUCCESS]: (state, action) => ({
      ...state,
      formValue: action.payload,
      loading: false,
      isAccountModalVisible: true
    }),
    [OPEN_ACCOUNT_MODAL.FAILURE]: (state, action) => ({
      ...state,
      formValue: {},
      loading: false
    }),
    [CLOSE_ACCOUNT_MODAL]: state => ({
      ...state,
      isAccountModalVisible: false
    }),
    [OPEN_ROLE_MODAL]: (state, action) => ({
      ...state,
      isRoleModalVisible: true
    }),
    [CLOSE_ROLE_MODAL]: state => ({
      ...state,
      isRoleModalVisible: false
    }),

    [GET_USER_USE_POINT_HISTORY.REQUEST]: (state, action) => ({
      ...state,
      pointHistory: { ...state.pointHistory, isLoading: true }
    }),
    [GET_USER_USE_POINT_HISTORY.SUCCESS]: (state, action) => ({
      ...state,
      pointHistory: {
        ...state.pointHistory,
        use: action.payload,
        isLoading: false
      }
    }),
    [GET_USER_USE_POINT_HISTORY.FAILURE]: (state, action) => ({
      ...state,
      pointHistory: { ...state.pointHistory, isLoading: false }
    }),
    [GET_USER_SAVE_POINT_HISTORY.REQUEST]: (state, action) => ({
      ...state,
      pointHistory: { ...state.pointHistory, isLoading: true }
    }),
    [GET_USER_SAVE_POINT_HISTORY.SUCCESS]: (state, action) => ({
      ...state,
      pointHistory: {
        ...state.pointHistory,
        save: action.payload,
        isLoading: false
      }
    }),
    [GET_USER_SAVE_POINT_HISTORY.FAILURE]: (state, action) => ({
      ...state,
      pointHistory: { ...state.pointHistory, isLoading: false }
    }),
    [EDIT_USER_POINT.SUCCESS]: (state, { payload }) => ({
      ...state,
      formValue: {
        ...state.formValue,
        remainPoints: payload.data.remainPoints
      }
    }),
    [DISABLE_USER]: (state, action) => ({
      ...state,
      loading: true
    }),
    [combineActions(DISABLE_USER_SUCCESS, DISABLE_USER_FAILURE)]: (
      state,
      action
    ) => ({
      ...state,
      loading: false
    }),
    [combineActions(RESET_NICKNAME.REQUEST)]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(RESET_NICKNAME.SUCCESS, RESET_NICKNAME.FAILURE)]: produce(
      (draft, { payload }) => {
        draft.loading = false;
      }
    ),
    [GET_NICKNAME_HISTORY_LIST.REQUEST]: produce(draft => {
      draft.nicknameHistory.isLoading = true;
    }),
    [GET_NICKNAME_HISTORY_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.nicknameHistory.isLoading = false;
      draft.nicknameHistory.data = payload.data;
      draft.nicknameHistory.totalCount = payload.totalCount;
    }),
    [GET_NICKNAME_HISTORY_LIST.FAILURE]: produce(draft => {
      draft.nicknameHistory.isLoading = false;
      draft.nicknameHistory.data = [];
      draft.nicknameHistory.totalCount = 0;
    }),
    [GET_USER_BACKPACK_LIST.REQUEST]: produce(draft => {
      draft.backpack.isLoading = true;
      draft.backpack.data = [];
      draft.backpack.totalCount = 0;
    }),
    [GET_USER_BACKPACK_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.backpack.isLoading = false;
      draft.backpack.data = payload.data.items;
      draft.backpack.totalCount = payload.totalCount;
    }),
    [GET_USER_BACKPACK_LIST.FAILURE]: produce(draft => {
      draft.backpack.isLoading = false;
      draft.backpack.data = [];
      draft.backpack.totalCount = 0;
    })
  },
  initialState
);

export default userManagement;
