import React, { useState, useEffect, useRef, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Select, message, Input, Spin, Empty } from 'antd';
import { Subject, of, forkJoin } from 'rxjs';
import { getUserInfoAPI, searchAPI } from '../apis';
import profileThumbnailPicture from '../constants/profileThumbnailPicture';
import {
  filter as rxFilter,
  map as rxMap,
  tap as rxTap,
  debounceTime,
  catchError,
  switchMap
} from 'rxjs/operators';
import {
  isEmpty,
  map,
  includes,
  compose,
  prop,
  reject,
  __,
  not,
  always,
  pick,
  tryCatch,
  isNil,
  cond,
  is,
  pathOr,
  equals,
  identity,
  ifElse,
  find,
  propEq,
  assoc,
  curry
} from 'ramda';
import { useWith as ramdaUseWith } from 'ramda';
import { isReallyEmpty } from '../utils/mixin';
import { dissoc } from 'ramda';
import brandName from '../constants/brandName';
const filterTypes = {
  nick_name: '暱稱',
  login_id: 'Login ID',
  uuid: 'UUID',
  true_love_id: `${brandName} ID`,
  email: 'E-mail',
  phone_number: '電話號碼',
  all: '查詢全部'
};
const { Option } = Select;
const InputGroup = Input.Group;
const renameKey = curry((oldKey, newKey, obj) =>
  assoc(newKey, prop(oldKey, obj), dissoc(oldKey, obj))
);
const pickUserProps = tryCatch(
  compose(
    renameKey('profilePicture', 'thumbnail'),
    pick(['id', 'loginId', 'nickname', 'profilePicture'])
  ),
  always(null)
);
const pickUser = cond([
  [isReallyEmpty, always([])],
  [
    is(Array),
    compose(
      reject(isNil),
      map(item => {
        if (item.uuid) {
          return {
            id: item.uuid,
            nickname: item.nickname,
            loginId: item.loginid,
            thumbnail: item.thumbnail || profileThumbnailPicture
          };
        }
        return null;
      })
    )
  ],
  [
    is(Object),
    item => [
      {
        id: item.uuid,
        nickname: item.nickname,
        loginId: item.loginid,
        thumbnail: item.thumbnail || profileThumbnailPicture
      }
    ]
  ]
]);
const rejectById = (value, target) =>
  reject(compose(includes(__, map(prop('id'), value)), prop('id')), target);
const isEqualsList = ramdaUseWith(equals, [identity, map(prop('id'))]);
const defaultUserListCatche = [];
const defaultStatusFilter = [1];
const LiveMasterSelect = memo(
  forwardRef(
    (
      {
        value = undefined,
        mode = 'default',
        disabled = false,
        style,
        placeholder,
        showArrow,
        allowClear = true,
        onChange,
        onSelectChange,
        typeFilter = 'nick_name',
        statusFilter = defaultStatusFilter,
        roleFilter = 'ROLE_MASTER',
        userListCatche = defaultUserListCatche,
        disabledTypeSelect = false
      },
      ref
    ) => {
      const [selectedList, setSelectedList] = useState([]);
      const [type, setType] = useState(typeFilter);
      const [dropList, setDropList] = useState([]);
      const [loading, setLoading] = useState(false);
      const onSearch$ = useRef(new Subject());
      const userList = useRef(selectedList);
      useEffect(() => {
        const subscription = of(value)
          .pipe(
            rxTap(() => {
              if (!isReallyEmpty(userList.current) && isReallyEmpty(value)) {
                //清除時，重置下拉選單
                setSelectedList([]);
                userList.current = [];
              }
            }),
            rxFilter(compose(not, isReallyEmpty)),
            rxMap(value => (typeof value === 'string' ? [value] : value)),
            rxFilter(value => !isEqualsList(value, userList.current)),
            rxTap(() => {
              setLoading(true);
            }),
            switchMap(ids =>
              forkJoin(
                ids.map(id => {
                  const indexObj =
                    find(propEq('id', id), userList.current) ||
                    find(propEq('id', id), userListCatche);
                  if (indexObj) {
                    return of(indexObj);
                  } else {
                    return getUserInfoAPI(id).pipe(
                      catchError(error => {
                        const msg = pathOr(
                          error.message,
                          ['response', 'Message'],
                          error
                        );
                        message.error(`LiveMaster: ${msg}`);
                        return of({ Status: 'Error', Message: msg });
                      })
                    );
                  }
                })
              )
            )
          )
          .subscribe(
            data => {
              setLoading(false);
              const users = data
                .filter(data => data !== null && data.Status !== 'Error')
                .map(pickUserProps);
              setSelectedList(users);
              userList.current = users;
              if (onSelectChange) {
                onSelectChange(userList.current);
              }
            },
            () => {
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        return () => {
          subscription.unsubscribe();
        };
      }, [value, userListCatche, onSelectChange]);

      useEffect(() => {
        const subscription = onSearch$.current
          .pipe(
            debounceTime(400),
            rxFilter(keyword => !isEmpty(keyword.trim())),
            rxTap(() => {
              setLoading(true);
            }),
            switchMap(keyword =>
              searchAPI({
                keyword: keyword,
                type: type,
                page: 1,
                item: 20,
                statusFilter: statusFilter,
                roleFilter: roleFilter
              })
            )
          )
          .subscribe(
            ({ data }) => {
              setLoading(false);
              setDropList(data);
            },
            () => {
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );

        return () => subscription.unsubscribe();
      }, [roleFilter, statusFilter, type]);

      const handleSearch = value => {
        onSearch$.current.next(value);
      };

      const handleSelectChange = (value, option) => {
        if (!disabled) {
          userList.current = pickUser(option);
          if (onSelectChange) {
            onSelectChange(userList.current);
          }
          setSelectedList(userList.current);
          triggerChange(
            ifElse(
              () => mode !== 'default',
              map(prop('id')),
              pathOr(undefined, ['0', 'id'])
            )(userList.current)
          );
        }
      };

      const triggerChange = changedValue => {
        if (onChange && !disabled) {
          onChange(changedValue);
        }
      };

      return (
        <InputGroup compact style={style}>
          <Select
            mode={mode}
            ref={ref}
            disabled={disabled}
            value={value}
            style={{ minWidth: 112, width: 'calc(100% - 116px)' }}
            placeholder={
              placeholder || `請輸入${type === 'all' ? '' : filterTypes[type]}`
            }
            showArrow={showArrow}
            allowClear={allowClear}
            showSearch
            onSearch={handleSearch}
            onChange={handleSelectChange}
            loading={loading}
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={
              loading ? (
                <Spin size="small" />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
          >
            {selectedList
              .concat(rejectById(selectedList, dropList))
              .map(item => (
                <Option
                  disabled={disabled}
                  key={item.id}
                  uuid={item.id}
                  loginid={item.loginId}
                  nickname={item.nickname}
                  thumbnail={
                    item.profileThumbnailPicture || profileThumbnailPicture
                  }
                >
                  {`${item.nickname} (${item.loginId})`}
                </Option>
              ))}
          </Select>
          <Select
            style={{ width: 116 }}
            disabled={disabled || disabledTypeSelect}
            value={type}
            onChange={value => {
              setType(value);
              setDropList([]);
            }}
          >
            {Object.entries(filterTypes).map(([id, name]) => (
              <Option key={id} title={name}>
                {name}
              </Option>
            ))}
          </Select>
        </InputGroup>
      );
    }
  )
);
LiveMasterSelect.propTypes = {
  onSelectChange: PropTypes.func,
  typeFilter: PropTypes.string
};

export default LiveMasterSelect;
