import { DatePicker, Form, Input, Modal, Spin } from 'antd';
import moment from 'moment';
import {
  compose,
  converge,
  curry,
  curryN,
  dissocPath,
  evolve,
  mergeRight,
  of,
  pathOr,
  zipObj
} from 'ramda';
import { useEffect } from 'react';
import InputI18 from '../../components/InputI18';
import LiveMasterSelect from '../../components/LiveMasterSelect';
import { useHasErrors } from '../../hooks/useHasErrors';
import { isReallyEmpty } from '../../utils/mixin';
const spreadPath = curryN(2, converge(mergeRight, [dissocPath, pathOr({})]));
const spreadProp = curry((prop, obj) => spreadPath(of(prop), obj));
const flattenDates = compose(
  spreadProp('dates'),
  evolve({
    dates: values =>
      zipObj(
        ['startTime', 'endTime'],
        values.map(v => v.unix())
      )
  })
);
const FormItem = Form.Item;
const { TextArea } = Input;
const RangePicker = DatePicker.RangePicker;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};

const FloatingSlotFormModal = ({
  isCreateMode,
  editData,
  loading,
  onOk,
  onCancel,
  visible
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();

  useEffect(() => {
    if (visible && !isReallyEmpty(editData) && !isCreateMode) {
      form.setFieldsValue({
        dates: [moment.unix(editData.startTime), moment.unix(editData.endTime)],
        ...editData
      });
    }
  }, [form, editData, visible, isCreateMode]);

  const onSubmit = () => form.submit();

  const onFinish = values => {
    onOk(flattenDates(values));
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };

  return (
    <Modal
      confirmLoading={loading}
      title={`${isCreateMode ? '新增' : '編輯'}浮動版位`}
      onOk={onSubmit}
      onCancel={onCancel}
      visible={visible}
      afterClose={onAfterClose}
      okButtonProps={{ disabled: hasErrors }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={onFinish}
          onFieldsChange={validateStatus}
          {...formItemLayout}
        >
          <FormItem
            name="userId"
            label="主播"
            rules={[{ required: true, message: '請輸入主播' }]}
          >
            <LiveMasterSelect />
          </FormItem>
          <FormItem
            name={['i18nContents', 'titles']}
            label="標題"
            rules={[{ required: true, message: '請輸入標題' }]}
          >
            <InputI18 />
          </FormItem>
          <FormItem
            name="dates"
            label="顯示時間"
            rules={[{ required: true, message: '請輸入顯示時間' }]}
          >
            <RangePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD HH:mm:ss"
              disabledDate={(current) =>
                current < moment().endOf('day').subtract(1, 'day')
              }
              showTime={{
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('23:59:59', 'HH:mm:ss')
                ]
              }}
            />
          </FormItem>
          <FormItem name="remark" label="備註">
            <TextArea placeholder="請輸入備註" rows={2} />
          </FormItem>
          {!isCreateMode && (
            <FormItem name="id" noStyle hidden>
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default FloatingSlotFormModal;
