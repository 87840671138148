import { Form, Input, Modal, Spin } from 'antd';
import { useEffect } from 'react';
import InputI18 from '../../components/InputI18';
import UploadImg from '../../components/UploadImg';
import { useHasErrors } from '../../hooks/useHasErrors';
import { isReallyEmpty } from '../../utils/mixin';
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 }
  }
};

const BottomTabBarModal = ({
  isCreateMode,
  editData,
  loading,
  onOk,
  onCancel,
  visible
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();

  useEffect(() => {
    if (visible && !isReallyEmpty(editData) && !isCreateMode) {
      form.setFieldsValue(editData);
    }
  }, [form, editData, visible, isCreateMode]);

  const onSubmit = () => form.submit();

  const onFinish = values => {
    onOk(values);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };

  return (
    <Modal
      title={`${isCreateMode ? '新增' : '編輯'}底部頁籤`}
      onOk={onSubmit}
      onCancel={onCancel}
      visible={visible}
      afterClose={onAfterClose}
      okButtonProps={{ disabled: hasErrors }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={onFinish}
          onFieldsChange={validateStatus}
          {...formItemLayout}
        >
          <FormItem
            name={['i18nContents', 'names']}
            label="名稱"
            rules={[{ required: true, message: '請輸入名稱' }]}
          >
            <InputI18 />
          </FormItem>
          <FormItem
            name="iconUrl"
            label="Icon (Normal)"
            rules={[{ required: true, message: '請輸入 Icon (Normal)' }]}
          >
            <UploadImg accept="image/*" uploadUrl="/cdn/backend/photo" />
          </FormItem>
          <FormItem
            name="activatedIconUrl"
            label="Icon (Activated)"
            rules={[{ required: true, message: '請輸入 Icon (Activated)' }]}
          >
            <UploadImg accept="image/*" uploadUrl="/cdn/backend/photo" />
          </FormItem>
          {!isCreateMode && (
            <FormItem name="id" noStyle hidden>
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default BottomTabBarModal;
