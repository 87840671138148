import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Modal, Table } from 'antd';
import { getNicknameHistoryList } from '../../reducers/userManagement';
import antdTablePagination from '../../utils/antdTablePagination';

const RangePicker = DatePicker.RangePicker;
const FormItem = Form.Item;

const columns = [
  {
    title: '修改前名稱',
    dataIndex: 'nickname'
  },
  {
    title: '修改時間',
    dataIndex: 'resetTime',
    render: value => moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
  }
];

const NicknameHistory = memo(
  ({
    visible,
    uuid,
    data,
    isLoading,
    totalCount,
    getNicknameHistoryList,
    onCancel,
  }) => {
    const [dateTime, setDateTime] = useState(() => [
      moment().startOf('day').subtract(7, 'DAY'),
      moment().endOf('day')
    ]);

    const [pagination, setPagination] = useState({
      ...new antdTablePagination(),
      current: 1,
    });

    useEffect(() => {
      if (visible) {
        getNicknameHistoryList({
          userId: uuid,
          startTime: dateTime[0].unix(),
          endTime: dateTime[1].unix(),
          page: pagination.current,
          item: pagination.pageSize,
        });
      }
    }, [
      visible, uuid, dateTime,
      pagination, getNicknameHistoryList,
    ]);

    const handleCancel = () => {
      setPagination({
        ...pagination,
        current: 1,
      });
      setDateTime([
        moment().startOf('day').subtract(7, 'DAY'),
        moment().endOf('day')
      ]);
      onCancel();
    };

    const handleConfirmDate = (value) => {
      setDateTime(value);
    };

    return (
      <Modal
        width={600}
        title="暱稱修改紀錄"
        footer={null}
        visible={visible}
        destroyOnClose={true}
        onCancel={handleCancel}
      >
        <FormItem
          label="日期區間"
          style={{ display: 'flex' }}
        >
          <RangePicker
            style={{ width: '400px' }}
            format="YYYY-MM-DD HH:mm:ss"
            defaultValue={dateTime}
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss'),
              ],
            }}
            allowClear={false}
            onOk={handleConfirmDate}
          />
        </FormItem>
        <Table
          rowKey="id"
          dataSource={data}
          columns={columns}
          loading={isLoading}
          bordered={true}
          pagination={{
            ...pagination,
            total: totalCount,
            onChange: (page) => setPagination({
              ...pagination,
              current: page,
            }),
          }}
        />
      </Modal>
    );
  }
);

const mapStateToProps = (state) => ({
  ...state.userManagement.nicknameHistory,
});

const mapDispatchToProps = {
  getNicknameHistoryList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NicknameHistory);
