import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import UploadModal from './UploadModal';

const StyledMediaUpload = styled.div`
  display: inline-block;

  button {
    font-size: 1.1rem;

    &:disabled,
    &[disabled] {
      background-color: inherit;
    }
  }

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
  }

  input {
    display: none;
  }
`;

class MediaUpload extends React.Component {

  static propTypes = {
    onMediaUpload: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.UploadModalRef = React.createRef();

    this.state = {
      isUploading: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onClick = (event) => {
    const { isUploading } = this.state;
    if (isUploading) event.preventDefault();
  }

  onFileSelected = (event) => {
    const { target } = event;
    const { files } = target;
    if (!_.size(files)) return;

    const { UploadModalRef } = this;
    const modal = UploadModalRef.current;
    modal.show(files[0]);
    target.value = null;
  }

  render() {
    const { className, onMediaUpload } = this.props;
    const { isUploading } = this.state;
    return (
      <StyledMediaUpload className={className}>
        <Button
          type="danger"
          className="ant-btn-icon-only"
        >
          <label htmlFor="send-media" onClick={this.onClick}>
            <LegacyIcon type={isUploading ? 'loading' : 'picture'} />
          </label>
        </Button>
        <input
          id="send-media"
          type="file"
          accept="image/*,video/mp4"
          onChange={this.onFileSelected}
        />
        <UploadModal
          ref={this.UploadModalRef}
          onMediaUpload={onMediaUpload}
        ></UploadModal>
      </StyledMediaUpload>
    );
  }
}

export default MediaUpload;
