import React from 'react';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';
import ForbiddenForm from './ForbiddenForm';
import {
  addForbiddenAPI,
  updateForbiddenAPI,
} from '../../../apis';

const actions = {
  create: {
    title: '新增禁詞',
    handler: 'createForbidden'
  },
  edit: {
    title: '編輯禁詞',
    handler: 'updateForbidden'
  }
};

class ForbiddenModal extends React.Component {
  static propTypes = {
    onCompleted: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      action: null,
      visible: false
    };
  }

  show = (action, data) => {
    if (!actions[action]) {
      console.error(
        `ForbiddenModal: Could not support action "${action}"`
      );
      return;
    }

    this.setState({ action, visible: true });
    requestAnimationFrame(() => {
      const { form } = this;
      form.reset();
      if (data) form.setFormData(data);
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  onConfirm = () => {
    const { form } = this;
    const { action } = this.state;
    const iPromise = form.validateFields();
    iPromise.then((values) => {
      const handler = actions[action].handler;
      this[handler](values);
    });
  };

  createForbidden = formData => {
    const { hide } = this;
    const { onCompleted } = this.props;
    const { word } = formData;
    const observable = addForbiddenAPI({ word });
    observable.subscribe((response) => {
      const { Status } = response;
      if (Status !== 'Ok') {
        message.error('禁詞新增失敗');
        return;
      }
      onCompleted();
      hide();
    });
  };

  updateForbidden = formData => {
    const { hide } = this;
    const { onCompleted } = this.props;
    const observable = updateForbiddenAPI(formData);
    observable.subscribe((response) => {
      const { Status } = response;
      if (Status !== 'Ok') {
        message.error('禁詞更新失敗');
        return;
      }
      onCompleted();
      hide();
    });
  };

  render() {
    const { action, visible } = this.state;
    const meta = actions[action];
    const title = meta && meta.title;

    return (
      <Modal
        title={title}
        visible={visible}
        centered
        maskClosable
        onOk={this.onConfirm}
        onCancel={this.hide}
      >
        <ForbiddenForm
          ref={form => (this.form = form)}
        ></ForbiddenForm>
      </Modal>
    );
  }
}

export default ForbiddenModal;
