import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DatePicker, Select } from 'antd';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Selector = styled(Select)`
  width: 8rem;
`;

const CustomizeRange = styled(RangePicker)`
  width: 23rem;
  margin-left: 0.5rem;
`;

const PEROID_KEYS = Object.freeze({
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUART: 'QUART',
  HALF: 'HALF',
  YEAR: 'YEAR',
  CUSTOMIZE: 'CUSTOMIZE',
});

const PERIOD_OFFSET = Object.freeze({
  [PEROID_KEYS.WEEK]: 7,
  [PEROID_KEYS.MONTH]: 30,
  [PEROID_KEYS.QUART]: 90,
  [PEROID_KEYS.HALF]: 180,
  [PEROID_KEYS.YEAR]: 365,
  [PEROID_KEYS.CUSTOMIZE]: 0,
});

const PeriodOptions = [
  { value: PEROID_KEYS.WEEK, label: '過去一週' },
  { value: PEROID_KEYS.MONTH, label: '過去一個月' },
  { value: PEROID_KEYS.QUART, label: '過去一季' },
  { value: PEROID_KEYS.HALF, label: '過去六個月' },
  { value: PEROID_KEYS.YEAR, label: '過去一年' },
  { value: PEROID_KEYS.CUSTOMIZE, label: '自訂' },
];

class PeriodPicker extends React.Component {

  static propTypes = {
    defaultPeriod: PropTypes.string,
  }

  static defaultProps = {
    defaultPeriod: PEROID_KEYS.WEEK,
  }

  constructor(props) {
    super(props);

    const { defaultPeriod } = props;

    this.state = {
      period: defaultPeriod.toLocaleUpperCase(),
    };
  }

  componentDidMount() {
    requestAnimationFrame(this.init);
  }

  componentWillUnmount() {
  }

  init = () => {
    const { period } = this.state;
    this.onPeriodChange(period);
  }

  triggerChange = (value) => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  }

  onPeriodChange = (period) => {
    this.setState({ period });

    // check whether custimize
    if (period === PEROID_KEYS.CUSTOMIZE) return;

    // calculate start date and end date
    const start = moment().startOf('date');
    const end = moment().endOf('date');
    const offset = PERIOD_OFFSET[period];
    start.subtract(offset, 'days');

    // update
    this.triggerChange([start, end]);
  }

  onRangeChange = (range) => {
    this.triggerChange(range);
  }

  render() {
    const { period } = this.state;
    const isCustomize = period === PEROID_KEYS.CUSTOMIZE;
    return (
      <Wrapper>
        <Selector
          value={period}
          onChange={this.onPeriodChange}
        >
          {PeriodOptions.map((period) => (
            <Option
              key={period.value}
              value={period.value}
            >
              <span>{period.label}</span>
            </Option>
          ))}
        </Selector>
        {isCustomize && (
          <CustomizeRange
            allowClear={false}
            format="YYYY/MM/DD HH:mm:ss"
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss'),
              ],
            }}
            onChange={this.onRangeChange}
          />
        )}
      </Wrapper>
    );
  }
}

export default PeriodPicker;
