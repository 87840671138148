import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Table, Tooltip } from 'antd';
import {
  UserOutlined, LineChartOutlined,
  DollarOutlined, GiftOutlined,
} from '@ant-design/icons';
import {
  LIVE_MASTER_ACCOUNT_MANAGEMENT,
  PERSONAL,
  DAILY_STATISTICS,
  DISMANTLING_STATISTICS,
  FANS_GIFT_RANKING,
} from '../../constants/routes';
import { history } from '../../store';
import { searchAPI } from '../../apis';
import { createQs, parseQs } from '../../utils/mixin';
import antdTablePagination from '../../utils/antdTablePagination';
import { encodeSearch, decodeSearch } from '../../utils/codec';
import UserSearchBar from '../../components/UserSearchBar';
import { PageContainer, PageTitle } from '../../components/styled/page';

const { Column } = Table;

const Filters = styled.div`
  margin-bottom: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    border: 0 !important;
    background: none !important;
    font-size: 1.2em;
    overflow: hidden;
    box-shadow: none !important;

    & + button {
      margin-left: 0.5rem;
    }
  }
`;

class LiveMasterAccountManagement extends Component {
  constructor(props) {
    super(props);

    this.UserSearchBarRef = React.createRef();

    this.state = {
      isLoading: false,
      dataSource: [],
      search: {},
      pagination: new antdTablePagination(),
    };
  }

  componentDidMount() {
    const { pagination } = this.state;
    const { location } = this.props;

    // set pagination & search input by query string
    const queryStr = parseQs(location.search);
    const current = parseInt(queryStr.page, 10) || 1;
    const search = this.decodeSearch(queryStr.search);
    const newPagination = Object.assign({}, pagination, { current });

    const { UserSearchBarRef } = this;
    UserSearchBarRef.setFieldsValue(search);

    this.setState({ search, pagination: newPagination }, () => {
      this.fetchLiveMasterList();
    });
  }

  goto = (uri) => {
    history.push(uri);
  }

  encodeSearch = () => {
    const { search } = this.state;
    return encodeSearch(search);
  }

  decodeSearch = (search) => {
    const { UserSearchBarRef } = this;
    return decodeSearch(search) || UserSearchBarRef.getFieldsValue();
  }

  updateQueryStr = () => {
    const { pagination } = this.state;
    const quertStr = createQs({
      search: this.encodeSearch(),
      page: pagination.current,
    });
    history.push({ search: quertStr });
  }

  fetchLiveMasterList = () => {
    this.setState({ isLoading: true });

    const { pagination, search } = this.state;
    const payload = {
      keyword: _.get(search, 'userFilter.keyword'),
      page: pagination.current,
      item: pagination.pageSize,
      type: _.get(search, 'userFilter.type'),
      statusFilter: _.get(search, 'statusFilter'),
      roleFilter: 'ROLE_MASTER',
    };

    searchAPI(payload).subscribe((res) => {
      const { data, totalCount } = res;
      const newPagination = Object.assign({}, pagination, {
        total: totalCount,
      });

      this.setState({
        dataSource: data,
        pagination: newPagination,
        isLoading: false,
      }, this.updateQueryStr);
    });
  }

  onSearchTypeChange = value => {
    this.setState({ searchType: value });
  }

  onSearch = value => {
    const { pagination } = this.state;
    const newPagination = Object.assign({}, pagination, { current: 1 });

    this.setState({
      search: value,
      pagination: newPagination,
    }, () => {
      this.fetchLiveMasterList();
    });
  };

  onTableChange = iPagination => {
    const { pagination } = this.state;
    const { current } = iPagination;

    const newPagination = Object.assign({}, pagination, { current });

    this.setState({ pagination: newPagination }, () => {
      this.fetchLiveMasterList();
    });
  };

  renderActions = (rowData) => {
    const { id } = rowData;

    return (
      <Actions>
        <Tooltip title="個人資料">
          <Button onClick={() => this.goto(`${LIVE_MASTER_ACCOUNT_MANAGEMENT}${PERSONAL}/${id}`)}>
            <UserOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="每日統計資訊">
          <Button onClick={() => this.goto(`${LIVE_MASTER_ACCOUNT_MANAGEMENT}${DAILY_STATISTICS}/${id}`)}>
            <LineChartOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="指定期間拆帳統計">
          <Button onClick={() => this.goto(`${LIVE_MASTER_ACCOUNT_MANAGEMENT}${DISMANTLING_STATISTICS}/${id}`)}>
            <DollarOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="粉絲贈禮排行榜">
          <Button onClick={() => this.goto(`${LIVE_MASTER_ACCOUNT_MANAGEMENT}${FANS_GIFT_RANKING}/${id}`)}>
            <GiftOutlined />
          </Button>
        </Tooltip>
      </Actions>
    );
  }

  render() {
    const { dataSource, isLoading, pagination } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'帳號管理 > 直播主帳號管理'}</PageTitle>
        <Filters>
          <UserSearchBar
            ref={ref => this.UserSearchBarRef = ref}
            initialValue={{
              userFilter: { keyword: '', type: 'nick_name' },
            }}
            filters={['userFilter', 'statusFilter']}
            onSubmit={this.onSearch}
          />
        </Filters>
        <Table
          rowKey="id"
          dataSource={dataSource}
          loading={isLoading}
          pagination={pagination}
          onChange={this.onTableChange}
        >
          <Column
            title="名稱"
            dataIndex="nickname"
            key="nickname"
            width="27%"
          />
          <Column
            title="帳號"
            dataIndex="loginId"
            key="loginId"
            width="32%"
          />
          <Column
            title="經紀公司"
            dataIndex="agencyName"
            key="agencyName"
          />
          <Column
            title="功能"
            key="actions"
            width="16rem"
            render={this.renderActions}
          />
        </Table>
      </PageContainer>
    );
  }
}

export default LiveMasterAccountManagement;
