import React, { Component } from 'react';
import { Input, Button, Cascader, message } from 'antd';
import { Form } from '@ant-design/compatible';
import fakeBankList from '../fake/bankList';

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class BankAccountSettingsForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        message.success('設定已儲存。');
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="銀行名稱" hasFeedback>
          {getFieldDecorator('bankName', {
            rules: [{ required: true, message: 'Please select your bank!' }],
          })(<Cascader options={fakeBankList} />)}
        </FormItem>
        <FormItem {...formItemLayout} label="分行" hasFeedback>
          {getFieldDecorator('branchBank', {
            rules: [
              { required: true, message: 'Please input your branch bank!' },
            ],
          })(<Input type="branchBank" placeholder="Branch Bank" />)}
        </FormItem>
        <FormItem {...formItemLayout} label="帳號" hasFeedback>
          {getFieldDecorator('bankAccount', {
            rules: [
              { required: true, message: 'Please input your bank account!' },
            ],
          })(<Input type="bankAccount" placeholder="Bank Account" />)}
        </FormItem>
        <FormItem style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit">
            儲存設定
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(BankAccountSettingsForm);
