import moment from 'moment';
import { countBy } from 'ramda';
import React from 'react';
import {
  message,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Popconfirm,
  Space
} from 'antd';
import UploadImage from '../../components/UploadImage';
import { EventTypeEnum, ScoringTypeEnum } from '../../reducers/eventCategory';
const FormItem = Form.Item;
const Option = Select.Option;

class EventCategoriesForm extends React.Component {
  static defaultProps = {
    loading: false,
    isShowModal: false,
    events: [],
    giftListCategories: [],
    showModal: v => console.log(v),
    addEventCategory: v => console.log(v)
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      eventType: null
    };
  }

  detectStarTimeDisabledDate = current => {
    const now = moment();
    const limit = now.subtract(1, 'day').endOf('day');
    return current < limit;
  };

  detectEndTimeDisabledDate = current => {
    const form = this.formRef.current;
    const oStartTime = form.getFieldValue('startTime');
    const startTime = oStartTime ? oStartTime.clone() : moment();
    const limit = startTime.subtract(1, 'day').endOf('day');
    return current < limit;
  };

  onModalClose = () => {
    this.setState({ eventType: null });
    this.props.showModal(false);
  };

  onEventTypeChange = value => {
    this.setState({
      eventType: parseInt(value, 10)
    });
  };

  onStartTimeChange = () => {
    const form = this.formRef.current;
    form.resetFields(['endTime']);
  };

  onGiftCategoryChange = () => {
    const form = this.formRef.current;
    form.setFieldsValue({
      collectGiftIds: []
    });
  };

  onSubmit = () => {
    const { events, addEventCategory } = this.props;
    const form = this.formRef.current;
    const iPromise = form.validateFields();
    iPromise.then(values => {
      const eventCount = countBy(el => el.type, events);
      if (eventCount[1] + eventCount[2] >= 10) {
        message.error('禮物積分活動最多只能有 10 個');
      } else if (eventCount[3] >= 10) {
        message.error('頭銜類別活動最多只能有 10 個');
      } else {
        addEventCategory({
          ...values,
          startTime: values.startTime.format('x'),
          endTime: values.endTime.format('x'),
          collectGiftIds: []
        });
      }
    });
  };

  renderCommonFields = () => {
    return (
      <React.Fragment>
        <FormItem
          label="活動名稱"
          name="eventName"
          rules={[{ required: true, message: '請輸入活動名稱' }]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="上架日期"
          name="startTime"
          rules={[{ required: true, message: '請選擇上架日期' }]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            disabledDate={this.detectStarTimeDisabledDate}
            onChange={this.onStartTimeChange}
          />
        </FormItem>
        <FormItem
          label="下架日期"
          name="endTime"
          rules={[{ required: true, message: '請選擇下架日期' }]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
            disabledDate={this.detectEndTimeDisabledDate}
          />
        </FormItem>
      </React.Fragment>
    );
  };

  renderGiftCategoryField = () => {
    const { giftListCategories } = this.props;
    return (
      <FormItem
        key="giftCategoryField"
        label="禮物類別"
        name="giftCategoryId"
        rules={[{ required: true, message: '請選擇禮物類別' }]}
      >
        <Select onChange={this.onGiftCategoryChange}>
          {giftListCategories.map(el => (
            <Option key={el.id} value={el.id}>
              <span>{el.categoryName}</span>
            </Option>
          ))}
        </Select>
      </FormItem>
    );
  };

  renderScoringTypeField = () => {
    return (
      <FormItem
        key="scoringTypeField"
        label="計分模式"
        name="scoringType"
        rules={[{ required: true, message: '請選擇計分模式' }]}
      >
        <Select>
          {Object.entries(ScoringTypeEnum).map(([key, value]) => (
            <Option key={key} value={key}>
              <span>{value}</span>
            </Option>
          ))}
        </Select>
      </FormItem>
    );
  };

  renderBannerField = () => {
    return (
      <FormItem
        key="bannerHashField"
        label="活動圖"
        name="bannerHash"
        rules={[{ required: true, message: '請上傳活動圖檔' }]}
      >
        <UploadImage uploadUrl="/image/create/eventBanner" />
      </FormItem>
    );
  };

  renderUrlField = () => {
    return (
      <FormItem
        key="urlField"
        label="URL"
        name="url"
        rules={[{ required: true, message: '請輸入 url' }]}
      >
        <Input />
      </FormItem>
    );
  };

  renderFooter = () => {
    const { loading } = this.props;
    return (
      <Space>
        <Popconfirm title="是否確定新增" onConfirm={this.onSubmit}>
          <Button type="primary" loading={loading}>
            <span>新增</span>
          </Button>
        </Popconfirm>
        <Button onClick={this.onModalClose}>
          <span>取消</span>
        </Button>
      </Space>
    );
  };

  render() {
    const { isShowModal } = this.props;
    const { eventType } = this.state;
    return (
      <Modal
        visible={isShowModal}
        closable={false}
        destroyOnClose={true}
        footer={this.renderFooter()}
        onCancel={this.onModalClose}
      >
        <Form
          ref={this.formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
        >
          <FormItem
            label="活動類別"
            name="type"
            rules={[{ required: true, message: '請選擇活動類別' }]}
          >
            <Select
              placeholder="請先選擇活動類別"
              onChange={this.onEventTypeChange}
            >
              {Object.entries(EventTypeEnum).map(([key, value]) => (
                <Option key={key} value={key}>
                  {value}
                </Option>
              ))}
            </Select>
          </FormItem>
          {eventType && this.renderCommonFields()}
          {eventType === 1 && [
            this.renderBannerField(),
            this.renderGiftCategoryField(),
            this.renderScoringTypeField()
          ]}
          {eventType === 2 && [this.renderBannerField(), this.renderUrlField()]}
        </Form>
      </Modal>
    );
  }
}

export default EventCategoriesForm;
