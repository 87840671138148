import React from 'react';
import { Tabs } from 'antd';
import PubliceVoiceChat from './PubliceVoiceChat';
import { PageContainer, PageTitle } from '../../components/styled/page';

const items = [
  {
    label: '一般聲聊',
    key: 'item-1',
    children: <PubliceVoiceChat isOfficial={false} />
  },
  {
    label: '官方聲聊',
    key: 'item-2',
    children: <PubliceVoiceChat isOfficial={true} />
  }
];
const VoiceChatList = () => {
  return (
    <PageContainer>
      <PageTitle>{'直播管理 > 聲聊房列表'}</PageTitle>
      <Tabs animated={false} items={items} />
    </PageContainer>
  );
};

export default VoiceChatList;
