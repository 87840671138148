import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Table, Select, InputNumber, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import ImageUpload from './ImageUpload';

export default class RegularLiveNPC extends PureComponent {
  _validateImage = (rule, value, callback) => {
    if (!value) {
      return callback('此欄位必填');
    }
    callback();
  };

  handleChange = (path) => (changedValue) => {
    const { setFields, getFieldError } = this.props;
    if (setFields && changedValue && getFieldError(path)) {
      setFields({
        [path]: { error: undefined },
      });
    }
  };

  _createColumns = () => {
    const { formFieldDecorator, giftList, isEdit } = this.props;

    return [
      {
        title: '名稱',
        dataIndex: 'name',
        width: 70,
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].name`, {
              rules: !isEdit && [{ required: true, message: '此欄位必填' }],
            })(<Input className="w100" placeholder="名稱" disabled={isEdit} />)}
          </FormItem>
        ),
      },
      {
        title: '正常動畫',
        dataIndex: ['animationSetting', 'map', '0', 'url'],
        width: 80,
        render: (value, record, i) => {
          return (
            <FormItem className="mb0">
              {formFieldDecorator(`npcs[${i}].animationSetting.map[0].url`, {
                rules: [{ validator: this._validateImage }],
              })(
                <ImageUpload
                  onChange={this.handleChange.bind(this, `npcs[${i}].animationSetting.map[0].url`)}
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: '收禮動畫',
        dataIndex: ['animationSetting', 'map', '1', 'url'],
        width: 80,
        render: (value, record, i) => {
          return (
            <FormItem className="mb0">
              {formFieldDecorator(`npcs[${i}].animationSetting.map[1].url`, {
                rules: [{ validator: this._validateImage }],
              })(
                <ImageUpload
                  onChange={this.handleChange.bind(this, `npcs[${i}].animationSetting.map[1].url`)}
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: '生氣動畫',
        dataIndex: ['animationSetting', 'map', '2', 'url'],
        width: 80,
        render: (value, record, i) => {
          return (
            <FormItem className="mb0">
              {formFieldDecorator(`npcs[${i}].animationSetting.map[2].url`, {
                rules: [{ validator: this._validateImage }],
              })(
                <ImageUpload
                  onChange={this.handleChange.bind(this, `npcs[${i}].animationSetting.map[2].url`)}
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: '過關全版動畫',
        dataIndex: ['animationSetting', 'map', '3', 'url'],
        width: 80,
        render: (value, record, i) => {
          return (
            <FormItem className="mb0">
              {formFieldDecorator(`npcs[${i}].animationSetting.map[3].url`, {
                rules: [{ validator: this._validateImage }],
              })(
                <ImageUpload
                  onChange={this.handleChange.bind(this, `npcs[${i}].animationSetting.map[3].url`)}
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: '無人送禮時間',
        dataIndex: 'noGiftTime',
        width: 80,
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].noGiftTime`, {
              rules: !isEdit && [{ required: true, message: '此欄位必填' }],
            })(
              <InputNumber
                className="w100"
                placeholder="無人送禮時間"
                min={0}
              />
            )}
          </FormItem>
        ),
      },
      {
        title: '過關點數',
        dataIndex: 'npcSpacings',
        width: 110,
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].npcSpacings[0].points`, {
              rules: !isEdit && [{ required: true, message: '此欄位必填' }],
            })(
              <InputNumber
                className="w100"
                placeholder="過關點數"
                min={0}
                disabled={isEdit}
              />
            )}
          </FormItem>
        ),
      },
      {
        title: '計分禮物',
        dataIndex: 'giftIds',
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].giftIds`, {
              rules: !isEdit && [{ required: true, message: '此欄位必填' }],
            })(
              <Select
                mode="multiple"
                className="w100"
                placeholder="計分禮物"
                allowClear
                disabled={isEdit}
              >
                {giftList.map((x) => (
                  <Select.Option key={`${x.id}`} value={`${x.uuid}`}>
                    {x.name} / {x.point}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
        ),
      },
      {
        title: '主播回饋點數',
        dataIndex: 'bonus',
        width: 90,
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].bonus`, {
              rules: !isEdit && [{ required: true, message: '此欄位必填' }],
            })(
              <InputNumber
                className="w100"
                min={0}
                placeholder="主播回饋點數"
                disabled={isEdit}
              />
            )}
          </FormItem>
        ),
      },
    ];
  };

  render() {
    const { npcs, isEdit, formFieldDecorator } = this.props;

    const columns = this._createColumns();
    return (
      <div>
        <Table
          rowKey="id"
          className="mt12"
          columns={columns}
          dataSource={npcs}
          pagination={false}
          size="middle"
        />
        {npcs.map((v, i) => {
          return formFieldDecorator(`npcs[${i}].id`)(
            <Input type="hidden" key={isEdit ? `npcs[${i}].id` : i} />
          );
        })}
        {npcs.map((v, i) => {
          return [1, 2, 3, 4].forEach((value, index) =>
            formFieldDecorator(
              `npcs[${i}].animationSetting.map[${index}].type`,
              { initialValue: value }
            )(
              <Input
                type="hidden"
                key={`npcs[${i}].animationSetting.map[${index}].type`}
              />
            )
          );
        })}
      </div>
    );
  }
}

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-top: 5px;
  }

  .ant-input-number,
  .ant-select-selection {
    margin-top: 10px;
  }
`;
