import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, A11y } from 'swiper';
import { isImage, isVideo } from '../../../utils/media';
import PreviewModal from './PreviewModal';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Pagination, A11y]);

const MEDIA_TYPE = Object.freeze({
  IMAGE: 'image',
  VIDEO: 'video',
  OTHERS: 'others',
});

const StyledEnquiryMedia = styled.div`
  position: relative;
`;

const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullets {
    top: 0.3rem;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }

  .swiper-pagination-bullet {
    width: 1.25rem;
    height: 1.25rem;
    text-align: center;
    line-height: 1.25rem;
    font-size: 0.75rem;
    color: #000;
    opacity: 1;
    background: rgba(255, 255, 255, 0.2);
    pointer-events: all;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }
`;

const SlideInner = styled.div`
  width: 100%;
  position: relative;
  padding-top: 75%;
`;

const CarouselContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #212121;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const PreviewButton = styled(Button)`
  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
`;

class EnquiryMedia extends React.Component {

  static propTypes = {
    files: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);

    this.CarouselRef = React.createRef();
    this.PreviewModalRef = React.createRef();

    this.state = {
      files: [],
      swiperPaginationConfig: {
        clickable: true,
        renderBullet: this.renderSwiperBullet,
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { files } = this.props;
    if (!_.isEqual(files, prevProps.files)) this.init();
  }

  componentWillUnmount() {
  }

  init = () => {
    const { files } = this.props;
    this.setFiles(files);
  }

  setFiles = (files) => {
    const result = files.map((url) => {
      const type = this.detectMediaType(url);
      return { url, type };
    });
    this.setState({ files: result });
  }

  detectMediaType = (url) => {
    if (isImage(url)) {
      return MEDIA_TYPE.IMAGE;
    } else if (isVideo(url)) {
      return MEDIA_TYPE.VIDEO;
    } else {
      return MEDIA_TYPE.OTHERS;
    }
  }

  detectMediaElement = (media) => {
    const { url, type } = media;
    if (type === MEDIA_TYPE.IMAGE) {
      return this.renderImage(url);
    } else if (type === MEDIA_TYPE.VIDEO) {
      return this.renderVideo(url);
    } else {
      return null;
    }
  };

  onCarouselNext = () => {
    const carousel = this.CarouselRef.current;
    carousel.next();
  }

  onCarouselPrev = () => {
    const carousel = this.CarouselRef.current;
    carousel.prev();
  }

  renderImage = (url) => {
    const modal = this.PreviewModalRef.current;
    const style = { backgroundImage: `url(${url})` };
    return (
      <CarouselContent style={style}>
        <PreviewButton
          shape="circle"
          onClick={() => modal.show(url)}
        >
          <LegacyIcon type="zoom-in" />
        </PreviewButton>
      </CarouselContent>
    );
  };

  renderVideo = (url) => {
    return (
      <CarouselContent>
        <video
          src={url}
          width="100%"
          height="100%"
          muted controls
          controlsList="noremoteplayback"
          disablePictureInPicture
        />
      </CarouselContent>
    );
  }

  renderSwiperBullet = (index, className) => {
    return `<span class="${className}">${index + 1}</span>`;
  }

  render() {
    const { files, swiperPaginationConfig } = this.state;

    return (
      <StyledEnquiryMedia>
        <StyledSwiper pagination={swiperPaginationConfig}>
          {files.map((media, key) => (
            <SwiperSlide key={key}>
              <SlideInner>
                {this.detectMediaElement(media)}
              </SlideInner>
            </SwiperSlide>
          ))}
        </StyledSwiper>
        <PreviewModal ref={this.PreviewModalRef} />
      </StyledEnquiryMedia>
    );
  }
}

export default EnquiryMedia;
