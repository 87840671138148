import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, pathOr } from 'ramda';
import styled from 'styled-components';
import moment from 'moment';

import { Button, Input, Select, Table, Popconfirm } from 'antd';
import RewardForm from './RewardForm';
import {
  showModal,
  getRewardList,
  addReward,
  setCurrentReward,
  modifyReward,
  deleteReward,
} from '../../reducers/rewardManagement';
const { Option } = Select;
const types = [
  { id: 'sticker', name: '魔法貼紙' },
  { id: 'gesture', name: '魔法手勢' },
  { id: 'face', name: '魔法表情' },
];
/**
 * Style
 */
const ButtonsContainer = styled.div`
  margin: 10px 0;
  .mr8:not(:last-child) {
    margin-right: 8px;
  }
`;

const TableContainer = styled.div`
  padding-right: 16px;
  .ant-table-wrapper {
    background-color: rgb(255, 255, 255);
    border-radius: 4px 4px 0px 0px;
  }
  .ant-pagination {
    margin-right: 16px;
  }
`;
const mapStateToProps = ({ rewardManagement }) => ({
  loading: rewardManagement.loading,
  isShowModal: rewardManagement.isShowModal,
  rewardList: rewardManagement.rewardList,
  effectList: rewardManagement.effectList,
  liveMasterList: rewardManagement.liveMasterList,
  currentReward: rewardManagement.currentReward,
});

const mapDispatchToProps = {
  showModal,
  getRewardList,
  addReward,
  setCurrentReward,
  modifyReward,
  deleteReward,
};
@connect(mapStateToProps, mapDispatchToProps)
class RewardManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'all',
      selectedRewards: [],
      searchText: '',
    };
    this.searchInput = '';
  }

  componentDidMount() {
    //取得獎勵列表
    this.props.getRewardList();
  }

  generateTableColumn() {
    const tableColumns = [
      {
        title: '編號',
        dataIndex: 'id',
      },
      {
        title: '帳號',
        dataIndex: 'liveMasterLoginId',
      },
      {
        title: '暱稱',
        dataIndex: 'liveMasterName',
      },
      {
        title: '動效名稱',
        dataIndex: 'sfxName',
      },
      {
        title: '動效分類',
        dataIndex: 'sfxType',
        render: (value, row, index) => {
          return pathOr(
            '',
            [0, 'name'],
            types.filter((item) => {
              return item.id === value;
            })
          );
        },
      },
      {
        title: '動效期間',
        dataIndex: 'startTime',
        render: (value, row, index) => {
          if (row['duration'] !== 'forever') {
            return (
              <span>
                {moment.unix(value).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
                {moment.unix(row['endTime']).format('YYYY-MM-DD HH:mm:ss')}
              </span>
            );
          } else {
            return '永久';
          }
        },
      },
    ];
    return tableColumns;
  }

  /**
   * 輸入
   */
  handleSearchChange = (event) => {
    this.searchInput = event.target.value;
    if (event.target.value === '') {
      this.handleSearchClick();
    }
  };

  /**
   * 點擊搜尋
   */
  handleSearchClick = () => {
    this.setState({ searchText: this.searchInput.trim() });
  };

  /**
   * 點擊新增獎勵
   */
  handleAddClick = () => {
    const { showModal } = this.props;
    showModal(true);
  };

  /**
   * 點擊編輯獎勵
   */
  handleEditClick = () => {
    const { showModal, setCurrentReward, rewardList } = this.props;
    const current = rewardList.find(
      (item) => item.id === this.state.selectedRewards[0]
    );
    setCurrentReward(Object.assign({}, current));
    showModal(true);
  };

  /**
   * 點擊刪除獎勵
   */
  handleDeleteClick = () => {
    const { deleteReward } = this.props;
    deleteReward(this.state.selectedRewards);
    this.setState({ selectedRewards: [] });
  };

  /**
   * 貼紙、手勢、表情
   */
  handleTypeChange = (value) => {
    this.setState({ type: value });
  };

  /**
   * 勾選列表中的獎勵
   */
  onSelectRowChange = (selectedRowKeys) => {
    this.setState({ selectedRewards: selectedRowKeys });
  };

  render() {
    const {
      loading,
      showModal,
      isShowModal,
      effectList,
      liveMasterList,
      rewardList,
      currentReward,
      setCurrentReward,
      modifyReward,
      addReward,
    } = this.props;

    const { type, selectedRewards, searchText } = this.state;
    const rewardListByFilter = rewardList
      .filter((reward) => type === 'all' || reward.sfxType === type)
      .filter((reward) => {
        const reg = RegExp(searchText);
        return (
          searchText === '' ||
          reg.test(reward.sfxName) ||
          reg.test(reward.liveMasterLoginId) ||
          reg.test(reward.liveMasterName)
        );
      });

    return (
      <div>
        <h1>{'活動管理 > 主播獎勵動效'}</h1>
        <ButtonsContainer>
          <Input
            className="mr8"
            style={{ width: 200 }}
            placeholder="請輸入動效名稱/主播帳號/暱稱"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                this.handleSearchClick();
              }
            }}
            onChange={this.handleSearchChange}
          />
          <Button type="primary" onClick={this.handleSearchClick}>
            搜尋
          </Button>
        </ButtonsContainer>
        <ButtonsContainer>
          <Button className="mr8" onClick={this.handleAddClick}>
            新增
          </Button>
          <Button
            className="mr8"
            onClick={this.handleEditClick}
            disabled={selectedRewards.length !== 1}
          >
            編輯
          </Button>
          分類：
          <Select
            style={{ width: 120 }}
            className="mr8"
            value={type}
            onChange={this.handleTypeChange}
          >
            <Option key={'all'} value="all">
              全部
            </Option>
            {types.map((type) => (
              <Option key={type.id} value={type.id}>
                {type.name}
              </Option>
            ))}
          </Select>
          <Popconfirm
            key={'confirm'}
            title="是否確定刪除"
            placement="bottomLeft"
            onConfirm={this.handleDeleteClick}
          >
            <Button
              className="mr8"
              loading={loading}
              type="danger"
              disabled={selectedRewards.length < 1}
            >
              刪除
            </Button>
          </Popconfirm>
        </ButtonsContainer>
        <TableContainer>
          <Table
            columns={this.generateTableColumn()}
            dataSource={rewardListByFilter}
            rowSelection={{
              selectedRowKeys: selectedRewards,
              onChange: this.onSelectRowChange,
            }}
            loading={loading}
            rowKey="id"
          />
        </TableContainer>
        <RewardForm
          loading={loading}
          isShowModal={isShowModal}
          isCreateMode={isEmpty(currentReward)}
          showModal={showModal}
          effectList={effectList}
          liveMasterList={liveMasterList}
          currentReward={currentReward}
          setCurrentReward={setCurrentReward}
          modifyReward={modifyReward}
          addReward={addReward}
        />
      </div>
    );
  }
}
export default RewardManagement;
