import { Button, Form, Input, Modal, Popconfirm, Space, Spin } from 'antd';
import { when } from 'ramda';
import React, { useEffect } from 'react';
import { useHasErrors } from '../../hooks/useHasErrors';

import { isNotNil, isReallyEmpty } from '../../utils/mixin';
import ContractPicker from './ContractPicker';
const { Item: FormItem } = Form;
const mapDataToForm = when(isNotNil, value => ({
  id: value.agencyId,
  agencyName: value.agencyName,
  contractIds: value.contracts.map(c => c.id)
}));

const AgencyFormModal = ({
  onOk,
  onCancel,
  visible,
  loading,
  editData,
  isCreateMode
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();

  useEffect(() => {
    if (visible && !isReallyEmpty(editData) && !isCreateMode) {
      form.setFieldsValue(mapDataToForm(editData));
    }
  }, [form, editData, isCreateMode, visible]);

  const onFinish = values => {
    onOk(values);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };
  return (
    <Modal
      width={990}
      title={`${isCreateMode ? '新增' : '編輯'} 經紀公司`}
      visible={visible}
      onCancel={onCancel}
      afterClose={onAfterClose}
      //避免 ContractPicker 在關閉時被 resetFields 重新 fetch List
      destroyOnClose
      footer={
        <Space>
          <Popconfirm
            key="confirm"
            title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
            placement="topLeft"
            onConfirm={() => form.submit()}
          >
            <Button
              key={'add'}
              loading={loading}
              type="primary"
              disabled={hasErrors}
            >
              確定
            </Button>
          </Popconfirm>
          <Button key="cancle" onClick={onCancel}>
            取消
          </Button>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onFieldsChange={validateStatus}>
          <FormItem
            label="名稱"
            name="agencyName"
            rules={[
              {
                required: true,
                message: '請輸入名稱'
              }
            ]}
          >
            <Input placeholder="請輸入經紀公司名稱" />
          </FormItem>
          <FormItem
            label="分潤方案類型"
            name="contractIds"
            rules={[
              {
                required: true,
                message: '請選擇分潤方案類型'
              }
            ]}
          >
            <ContractPicker />
          </FormItem>
          {!isCreateMode && (
            <FormItem key="id" hidden={true} name="id">
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default AgencyFormModal;
