import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import views from './views';
import contentManagement from './contentManagement';
import accountManagement from './accountManagement';
import paymentToolStatistics from './paymentToolStatistics';
import liveMasterEditForm from './liveMasterEditForm';
import registrationStaffGrowth from './registrationStaffGrowth';
import performanceGrowth from './performanceGrowth';
import giftList from './giftList';
import photoManagement from './photoManagement';
import liveApplicationReview from './liveApplicationReview';
import manualSettlement from './manualSettlement';
import userManagement from './userManagement';
import agencyList from './agencyList';
import liveMasterList from './liveMasterList';
import liveViewerStatistics from './liveViewerStatistics';
import systemAnnouncementPush from './systemAnnouncementPush';
import giftListCategories from './giftListCategories';
import giftReport from './giftReport';
import adManagement from './adManagement';
import spotlightManagement from './spotlightManagement';
import accountSheetExport from './accountSheetExport';
import pointRatio from './pointRatio';
import eventCategory from './eventCategory';
import avatar from './avatar';
import purchaseOrderList from './purchaseOrderList';
import giftBoxManagement from './giftBoxManagement';
import animationEffectManagement from './animationEffectManagement';
import rewardManagement from './rewardManagement';
import achievementManagement from './achievementManagement';
import assignedAchievements from './assignedAchievements';
import enquiryManagement from './enquiryManagement';
import messageManagement from './messageManagement';
import messageRoomList from './messageRoomList';
import messageDialog from './messageDialog';
import remittanceManagement from './remittanceManagement';
import overlayAdManagement from './overlayAdManagement';
import seriesManagement from './seriesManagement';
import videoManagement from './videoManagement';
import pastEventManagement from './pastEventManagement';
import pointTransfer from './pointTransfer';
import appConfigManagement from './appConfigManagement';
import remittanceProductManagement from './remittanceProductManagement';
import accountCreationManagement from './accountCreationManagement';
import blocklistManagement from './blocklistManagement';
import voiceChatRoomReport from './voiceChatRoomReport';
import effectManagement from './effectManagement';
import dispatchManagement from './dispatchManagement';
import contractManagement from './contractManagement';
import agencyManagement from './agencyManagement';
import agencyMasterManagement from './agencyMasterManagement';
import privateVoiceChatSpotlightManagement from './privateVoiceChatSpotlightManagement';
import bottomTabbarManagement from './bottomTabbarManagement';
import floatingSlotManagement from './floatingSlotManagement';
import signinEventManagement from './signinEventManagement';
import queryRankingList from './queryRankingList';
import outsourcePointTransfer from './outsourcePointTransfer';
export default history =>
  combineReducers({
    auth,
    giftReport,
    manualSettlement,
    contentManagement,
    accountManagement,
    liveMasterEditForm,
    paymentToolStatistics,
    registrationStaffGrowth,
    giftListCategories,
    performanceGrowth,
    giftList,
    photoManagement,
    liveApplicationReview,
    userManagement,
    views,
    agencyList,
    liveMasterList,
    liveViewerStatistics,
    systemAnnouncementPush,
    adManagement,
    spotlightManagement,
    accountSheetExport,
    pointRatio,
    eventCategory,
    avatar,
    router: connectRouter(history),
    purchaseOrderList,
    giftBoxManagement,
    animationEffectManagement,
    rewardManagement,
    achievementManagement,
    assignedAchievements,
    enquiryManagement,
    messageManagement,
    messageRoomList,
    messageDialog,
    remittanceManagement,
    overlayAdManagement,
    seriesManagement,
    videoManagement,
    pastEventManagement,
    appConfigManagement,
    remittanceProductManagement,
    pointTransfer,
    accountCreationManagement,
    blocklistManagement,
    voiceChatRoomReport,
    effectManagement,
    dispatchManagement,
    privateVoiceChatSpotlightManagement,
    bottomTabbarManagement,
    contractManagement,
    agencyManagement,
    agencyMasterManagement,
    floatingSlotManagement,
    signinEventManagement,
    queryRankingList,
    outsourcePointTransfer
  });
