import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LiveRoomContext from '../../../../LiveRoomContext';
import themes from '../utils/themes';

class ChatMessage extends React.Component {

  static contextType = LiveRoomContext;

  static propTypes = {
    message: PropTypes.object,
  };

  static defaultProps = {
    message: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      content: '',
      userName: '',
      identity: 'default',
      theme: {},
    };
  }

  componentDidMount() {
    this.setData();
  }

  setData = () => {
    const { message } = this.props;
    const { detectIdentity } = this.context;
    const propContent = _.get(message, 'data.content');
    // const userName =  _.get(message, 'data.userName');
    const uuid = _.get(message, 'data.userId');
    const roles = _.get(message, 'data.roles');
    const result = new RegExp('^@(.+):(.*)').exec(propContent);
    const identity = detectIdentity(uuid, roles);
    const theme = themes.identity[identity];
    this.setState({
      userName: result[1],
      content: result[2],
      identity,
      theme,
    });
  }

  renderUserLevel = () => {
    const { message } = this.props;
    const { theme } = this.state;
    const levelId = _.get(message, 'data.userLevel.levelId');
    const levelNum = _.get(message, 'data.userLevel.levelNum');
    const levelTheme = themes.userLevel[levelId];
    const style = {
      color: levelTheme.number,
      backgroundColor: theme.foreground,
    };
    return (
      <div className="user-level" style={style}>
        <img src={levelTheme.icon} alt={levelId} />
        <span>{levelNum}</span>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { content, userName, theme } = this.state;
    const wrapperStyle = { backgroundColor: theme.background };

    return (
      <div className={className} style={wrapperStyle}>
        {/* {this.renderUserLevel()} */}
        <span className="username">{userName}：</span>
        <span className="content">{content}</span>
      </div>
    );
  }
}

const StyledChatMessage = styled(ChatMessage)`
  padding: 0.25rem;
  line-height: 1;
  border-radius: 0.313rem;
  word-break: break-all;

  & > * {
    vertical-align: middle;
  }

  .username {
    color: ${themes.username};
  }

  .content {
    color: ${themes.content};
  }

  .user-level {
    margin-right: 0.25rem;
    padding: 0.2rem 0.4rem 0.2rem 0.2rem;
    display: inline-block;
    align-items: center;
    border-radius: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;

    * {
      vertical-align: middle;
    }

    img {
      height: 1rem;
      margin-right: 0.1rem;
    }
  }
`;

export default StyledChatMessage;
