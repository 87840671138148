import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select, Spin } from 'antd';
import { searchAPI } from '../../apis';
import { roles } from '../../constants/roles';
import {
  TypesEnum, TypesNameMap,
  ValueFieldEnum, ValuePathMap,
} from './constants';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const UserSelectWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const TypeSelectWrapper = styled.div`
  width: 8rem;
  flex-shrink: 0;
  overflow: hidden;
`;

const TypeSelect = styled(Select)`
  .ant-select-selector {
    background-color: #FAFAFA !important;
  }
`;

const TypeOtions = _.map(TypesEnum, (value) => ({
  label: TypesNameMap[value],
  value,
}));

class UserSselector extends React.Component {
  static propTypes = {
    defaultType: PropTypes.oneOf(Object.values(TypesEnum)),
    roleFilter: PropTypes.oneOf(Object.keys(roles)),
    statusFilter: PropTypes.array,
    valueField: PropTypes.oneOf(Object.keys(ValueFieldEnum)),
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    statusFilter: [1],
    defaultType: TypesEnum.NICKNAME,
    valueField: ValueFieldEnum.USER_ID,
  };

  constructor(props) {
    super(props);

    this.UserSearchRef = React.createRef();

    this.state = {
      fetching: false,
      userlist: [],
      type: props.defaultType,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
  }

  init = () => {
    const { value } = this.props;
    if (!value) return;

    this.fetchUser({
      type: this.detectPresetTypeByValueField(),
      keyword: value,
      page: 1,
      item: 1,
    });
  };

  fetchUser = (payload) => {
    this.setState({ fetching: true });
    const iPromise = searchAPI(payload).toPromise();
    iPromise.then((res) => {
      const { data } = res;
      this.setUserListFromSearch(data);
    });
    iPromise.finally(() => {
      this.setState({ fetching: false });
    });
  };

  detectPresetTypeByValueField = () => {
    const { valueField } = this.props;
    let type = TypesEnum.UUID;
    if (valueField === ValueFieldEnum.OOPSVIP_ID) {
      type = TypesEnum.OOPSVIP_ID;
    }
    return type;
  };

  search = (keyword) => {
    const { roleFilter, statusFilter } = this.props;
    const { type } = this.state;
    this.fetchUser({
      keyword,
      roleFilter,
      statusFilter,
      type,
      page: 1,
      item: 20,
    });
  };

  setUserListFromSearch = (data) => {
    const { valueField } = this.props;
    const userlist = _.map(data, (option) => ({
      label: `${option.nickname} (${option.loginId})`,
      value: option[ValuePathMap[valueField]],
    }));
    this.setState({ userlist });
  };

  triggerChange = (value) => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
  };

  onSearch = _.debounce(this.search, 300);

  onTypeSelect = (type) => {
    this.setState({ type });
  };

  onUserSelect = (value) => {
    this.triggerChange(value);
  };

  render() {
    const { defaultType, value } = this.props;
    const { fetching, userlist } = this.state;
    return (
      <Wrapper>
        <UserSelectWrapper>
          <Select
            ref={this.UserSearchRef}
            options={userlist}
            filterOption={false}
            showSearch={true}
            notFoundContent={fetching && <Spin size="small" />}
            value={value}
            onSearch={this.onSearch}
            onSelect={this.onUserSelect}
          />
        </UserSelectWrapper>
        <TypeSelectWrapper>
          <TypeSelect
            options={TypeOtions}
            filterOption={false}
            defaultValue={defaultType}
            onSelect={this.onTypeSelect}
          />
        </TypeSelectWrapper>
      </Wrapper>
    );
  }
}

export default UserSselector;
