/* eslint no-unused-vars:1, consistent-return:0, indent:0, no-multi-spaces:0, no-nested-ternary:0, no-use-before-define:0, semi:0, no-trailing-spaces:0, prefer-const:0, no-empty:0, react/sort-comp:0 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Layout, Modal } from 'antd';
import {
  length,
  evolve,
  map,
  filter,
  compose,
  slice,
  addIndex,
  forEach
} from 'ramda';
import moment from 'moment';
import { getGiftListCategory } from '../../reducers/giftListCategories';
import { getGiftList } from '../../reducers/giftList';
import { actions } from '../../reducers/giftBoxManagement';
import GiftBoxModal from './GiftBoxModal';
import { Table, LinkButton, Content } from './components';
import { ADD, EDIT } from './actionTypes';
import { eventTypes } from './utils';
import { giftType } from '../../constants/gifts';

const GIFTBOX_EVENT_TYPE = 4;

const forEachIndexed = addIndex(forEach);
const mapIndexed = addIndex(map);

class GiftBoxManagement extends PureComponent {
  state = {
    currentGiftBoxEventId: null,
    currentGiftBoxEvent: null,
    selectedGiftBoxEventIds: [],
    selectedGiftBoxEvents: [],
    modalVisible: false
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedGiftBoxEventIds: selectedRowKeys,
        selectedGiftBoxEvents: selectedRows
      });
    },
    type: 'checkbox'
  };

  componentDidMount = () => {
    const { getGiftListCategory, listGiftBoxes } = this.props;
    getGiftListCategory({
      typeFilter: giftType.liveRoom.value,
      statusFilter: 'all',
      item: 999,
      page: 1
    });
    listGiftBoxes({ type: GIFTBOX_EVENT_TYPE });
  };

  _createColumns = () => {
    return [
      {
        title: '活動名稱',
        dataIndex: 'eventName'
      },
      {
        title: '活動模式',
        dataIndex: 'type',
        render: value => (+value === 1 ? '隨機直播間' : '固定直播間')
      },
      {
        title: '活動期限',
        render: (val, r) => {
          return `${moment(r.startTime).format(
            'YYYY-MM-DD HH:mm:ss'
          )} - ${moment(r.endTime).format('YYYY-MM-DD HH:mm:ss')}`;
        }
      },
      {
        title: '活動設定細節',
        render: (val, r) => {
          return (
            <div>
              <LinkButton onClick={this.handleEdit(r)}>查看/編輯</LinkButton>
            </div>
          );
        }
      }
    ];
  };

  handleAdd = () => {
    this.setState({
      modalVisible: true,
      currentGiftBoxEvent: null,
      currentGiftBoxEventId: null,
      actionType: ADD
    });
  };

  handleEdit = giftboxEvent => () => {
    this.setState({
      modalVisible: true,
      currentGiftBoxEvent: giftboxEvent,
      currentGiftBoxEventId: giftboxEvent.id,
      actionType: EDIT
    });

    this.props.getGiftBox(giftboxEvent.id);
  };

  handleDelete = () => {
    const { selectedGiftBoxEventIds } = this.state;
    const count = length(selectedGiftBoxEventIds);

    Modal.confirm({
      title: '確認要刪除活動資料嗎',
      content: `共選擇 ${count} 資料，刪除後資料將無法復原，確認要刪除嗎？`,
      onOk: () => {
        this.props.removeGiftBox(selectedGiftBoxEventIds);
        this.setState({
          selectedGiftBoxEventIds: [],
          selectedGiftBoxEvents: []
        });
      }
    });
  };

  handleOk = formValues => {
    const { actionType, currentGiftBoxEventId } = this.state;
    const { addGiftBox, modifyGiftBox } = this.props;

    let {
      range,
      npcs,
      type,
      eventName,
      giftCategoryId,
      bannerHash
    } = formValues;
    const [startTime, endTime] = range;
    const isRandomLiveEvent = +type === eventTypes.RANDOM;

    npcs = isRandomLiveEvent ? slice(0, 1, npcs) : npcs;

    const mapNPC = mapIndexed(({ additionEffects, id, ...n }, idx) => ({
      ...n,
      ...(currentGiftBoxEventId ? { id } : {}),
      ...(isRandomLiveEvent
        ? {
            additionEffects: map(a => filter(Boolean, a), additionEffects)
          }
        : {}),
      start: startTime.valueOf(),
      end: startTime.valueOf(),
      level: idx + 1,
      relatedEvent: {
        id: currentGiftBoxEventId,
        startTime: startTime.valueOf(),
        endTime: endTime.valueOf(),
        eventName,
        giftCategoryId,
        type,
        bannerHash
      }
    }));

    const formatNPC = compose(formatValue, mapNPC, filterNull)(npcs);

    const request = {
      [ADD]: () =>
        addGiftBox({
          event: {
            eventName,
            startTime: startTime.valueOf(),
            endTime: endTime.valueOf(),
            giftCategoryId: Number(giftCategoryId),
            type: GIFTBOX_EVENT_TYPE,
            bannerHash
          },
          npcs: formatNPC,
          images: flattenUploadImageList(formatNPC),
          closeModal: this.handleCancel
        }),
      [EDIT]: () =>
        modifyGiftBox({
          npcs: formatNPC,
          images: flattenUploadImageList(formatNPC),
          closeModal: this.handleCancel
        })
    };

    request[actionType]();
  };

  handleCancel = e => {
    e && e.preventDefault();

    if (e && e.keyCode === 27) {
      return;
    }

    this.setState({
      modalVisible: false
    });

    this.props.resetGiftBox();
  };

  render() {
    const {
      props: { giftBox, giftCategoryList, giftList, dateList, getGiftList },
      state: {
        currentGiftBoxEvent,
        selectedGiftBoxEventIds,
        actionType,
        modalVisible
      }
    } = this;

    const columns = this._createColumns();
    const isNew = actionType === ADD;
    const disableDeleteBtn = length(selectedGiftBoxEventIds) === 0;

    return (
      <Layout>
        <Content>
          <h1>{'活動管理 > 互動式禮物盒設定'}</h1>
          {modalVisible && (
            <GiftBoxModal
              visible={modalVisible}
              loading={giftBox.loadingNPC}
              updating={giftBox.updating}
              currentGiftBoxEvent={currentGiftBoxEvent}
              npcs={giftBox.npcs}
              giftCategoryList={giftCategoryList}
              giftList={giftList}
              dateList={dateList}
              isNew={isNew}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okText={isNew ? '儲存' : '儲存變更'}
              titlePrefix={isNew ? '新增' : '查看/編輯'}
              getGiftList={getGiftList}
            />
          )}
          <Row className="mb12">
            <Col span={24}>
              <Button onClick={this.handleAdd}>新增</Button>
              <Button
                type="danger"
                disabled={disableDeleteBtn}
                style={{ marginLeft: 8 }}
                onClick={this.handleDelete}
              >
                刪除
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={giftBox.events}
                rowSelection={this.rowSelection}
                loading={giftBox.loading}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const flattenUploadImageList = npcs => {
  const results = [];

  forEachIndexed((n, nIdx) => {
    forEachIndexed((a, aIdx) => {
      if (typeof a.url === 'object') {
        results.push({ nIdx, aIdx, ...a });
      }
    }, n.animationSetting.map);
  }, npcs);

  return results;
};

const filterNull = map(n => filter(Boolean, n));

const formatValue = map(
  evolve({
    name: String,
    noGiftTime: Number,
    bonus: Number,
    level: Number,
    type: Number,
    npcSpacings: map(
      evolve({
        type: Number,
        points: Number
      })
    ),
    additionEffects: map(
      evolve({
        type: Number,
        probability: Number,
        points: Number
      })
    ),
    relatedEvent: {
      endTime: Number,
      giftCategoryId: Number,
      startTime: Number,
      type: Number
    }
  })
);

const mapStateToProps = state => ({
  giftBox: state.giftBoxManagement,
  giftList: state.giftList.data,
  giftCategoryList: state.giftListCategories.data,
  dateList: state.giftBoxManagement.dateList
});

const mapDispatchToProps = {
  ...actions,
  getGiftList,
  getGiftListCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftBoxManagement);
