import {
  always,
  compose,
  contains,
  equals,
  evolve,
  find,
  head,
  identity,
  isNil,
  prop,
  propEq,
  merge,
  nth,
  pick,
  toPairs,
  tryCatch,
  values,
  when,
  isEmpty
} from 'ramda';
import { useWith as ramdaUseWith } from 'ramda';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Table, Popconfirm, notification } from 'antd';
import { userType, departmentOwners, tagType } from '../../constants/roles';
import {
  getAnnouncementList,
  addAnnouncement,
  getAnnouncement,
  modifyAnnouncement,
  deleteAnnouncement,
  getTagList,
  pushNotification,
  showModal,
  setDepartment
} from '../../reducers/systemAnnouncementPush';
import { omitWhen } from '../../utils/mixin';
import { PageContainer, PageTitle } from '../../components/styled/page';
import LightBox from '../../components/LightBox';
import AnnouncementModal from './AnnouncementModal';
import AnnouncementPreview from './AnnouncementPreview';

const { Column } = Table;

export const hasRole = v => compose(contains(v), nth(1));

export const getDepartmentByRole = tryCatch(
  compose(head, ramdaUseWith(find, [hasRole, toPairs])),
  always(undefined)
);

export const getTagIdByDepartment = compose(
  prop('id'),
  ramdaUseWith(find, [propEq('label'), identity])
);

const Controls = styled.div`
  .item {
    margin: 0 8px 12px 0;
  }
`;

class SystemAnnouncementPush extends React.Component {
  constructor(props) {
    super(props);

    const { roles, setDepartment } = props;
    const department = getDepartmentByRole(roles[0], departmentOwners);
    setDepartment(department);

    this.state = {
      isCreateMode: true,
      department: department,
      selectedRowKeys: [],
      previewVisible: false,
      previewData: null
    };
  }

  componentDidMount() {
    const { getAnnouncementList, getTagList, pageSize, currentPage } =
      this.props;
    const { department } = this.state;
    // 取得 tag 資訊（新增時會使用到）
    getTagList();
    getAnnouncementList(department, pageSize, currentPage);
  }

  onTableChange = pagination => {
    const { getAnnouncementList } = this.props;
    const { department } = this.state;
    getAnnouncementList(department, pagination.pageSize, pagination.current);
  };

  onSelectRowChange = selectedRowKeys => {
    this.setState({ selectedRowKeys: selectedRowKeys });
  };

  onTitleClick = record => {
    const data = evolve({
      lastLoginPeriod: when(isNil, always('anytime')),
      registerTimePeriod: when(isNil, always('anytime'))
    })(record);
    this.setState({ previewData: data, previewVisible: true });
  };

  onAdd = () => {
    const { showModal, tagList } = this.props;
    if (tagList.length === values(tagType).length) {
      this.setState({ isCreateMode: true });
      showModal(true);
    } else {
      notification.error({
        message: 'Tag 列表異常',
        description: '無法取得完整 Tag 列表'
      });
    }
  };

  onEdit = () => {
    const { showModal, getAnnouncement } = this.props;
    const { selectedRowKeys } = this.state;
    this.setState({ isCreateMode: false });
    showModal(true);
    getAnnouncement(selectedRowKeys[0]);
  };

  onDelete = () => {
    const { deleteAnnouncement } = this.props;
    const { selectedRowKeys } = this.state;
    deleteAnnouncement(selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onSubmit = data => {
    if (this.state.isCreateMode) {
      this.addAnnouncement(data);
    } else {
      this.modifyAnnouncement(data);
    }
  };

  onCancelModal = () => {
    const { showModal } = this.props;
    this.setState({ isCreateMode: true });
    showModal(false);
  };

  addAnnouncement = data => {
    const { addAnnouncement, tagList } = this.props;
    const { department, loginId } = this.state;
    /** 小喬特製公告規則 */
    const tag =
      loginId === 'tl-chiao'
        ? 4
        : getTagIdByDepartment(tagType[department], tagList);

    const formValues = omitWhen(
      equals('anytime'),
      ['lastLoginPeriod', 'registerTimePeriod'],
      merge(data, { department: department, tagId: tag })
    );
    addAnnouncement(formValues);
    this.setState({ selectedRowKeys: [] });
  };

  modifyAnnouncement = data => {
    const { modifyAnnouncement } = this.props;
    modifyAnnouncement(
      compose(
        evolve({ linkUrl: v => (isEmpty(v) ? null : v) }),
        pick(['i18nContents', 'imgUrl', 'linkUrl', 'id'])
      )(data)
    );
    this.setState({ selectedRowKeys: [] });
  };

  renderCreateTime = rowData => {
    const { createdAt } = rowData;
    const format = 'YYYY-MM-DD HH:mm:ss';
    const date = moment.unix(createdAt).format(format);
    const dateArray = date.split(' ');
    return (
      <>
        <div>{dateArray[0]}</div>
        <div>{dateArray[1]}</div>
      </>
    );
  };

  renderTitle = rowData => {
    const title = rowData?.i18nContents?.titles?.en || '';
    return (
      <Button
        type="link"
        style={{ padding: 0 }}
        onClick={() => this.onTitleClick(rowData)}
      >
        <span>{title}</span>
      </Button>
    );
  };

  renderPicture = rowData => {
    const { imgUrl } = rowData;
    return imgUrl && <LightBox src={imgUrl} />;
  };

  renderAuthorInfo = rowData => {
    const type = userType[rowData.userType];
    return <span>用戶類型：{type}</span>;
  };

  render() {
    const {
      data,
      loading,
      currentPage,
      pageSize,
      totalCount,
      isShowModal,
      currentAnnouncement
    } = this.props;
    const { selectedRowKeys, previewData, previewVisible, isCreateMode } =
      this.state;
    const isDisbleDelete = selectedRowKeys.length < 1 || loading;
    const pagination = {
      defaultCurrent: currentPage,
      current: currentPage,
      pageSize: pageSize,
      total: totalCount,
      showSizeChanger: false
    };
    return (
      <PageContainer>
        <PageTitle>{'APP 管理 > 系統公告推播'}</PageTitle>
        <h2>發布訊息</h2>
        <Controls>
          <Button type="primary" className="item" onClick={this.onAdd}>
            <span>新增</span>
          </Button>
          <Button
            className="item"
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onEdit}
          >
            <span>編輯</span>
          </Button>
          <Popconfirm
            key={'confirm'}
            title="是否確定刪除"
            placement="bottomLeft"
            onConfirm={this.onDelete}
            disabled={isDisbleDelete}
          >
            <Button type="danger" className="item" disabled={isDisbleDelete}>
              <span>刪除</span>
            </Button>
          </Popconfirm>
        </Controls>
        <h2>歷史訊息</h2>
        <Table
          rowKey="id"
          dataSource={data}
          loading={loading}
          pagination={pagination}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: this.onSelectRowChange
          }}
          onChange={this.onTableChange}
        >
          <Column title="發佈時間" render={this.renderCreateTime} />
          <Column title="標題" render={this.renderTitle} />
          <Column title="圖片" render={this.renderPicture} />
          <Column title="發送對象" render={this.renderAuthorInfo} />
          <Column title="發文者" dataIndex={['createUser', 'name']} />
        </Table>
        <AnnouncementModal
          loading={loading}
          isCreateMode={isCreateMode}
          editData={currentAnnouncement}
          visible={isShowModal}
          onOk={this.onSubmit}
          onCancel={this.onCancelModal}
        />
        <AnnouncementPreview
          loading={loading}
          mode="preview"
          value={previewData}
          visible={previewVisible}
          onCancel={() => this.setState({ previewVisible: false })}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = state => {
  const { auth, systemAnnouncementPush } = state;
  return {
    data: systemAnnouncementPush.data,
    loading: systemAnnouncementPush.loading,
    pageSize: systemAnnouncementPush.pageSize,
    currentPage: systemAnnouncementPush.currentPage,
    totalCount: systemAnnouncementPush.totalCount,
    isShowModal: systemAnnouncementPush.isShowModal,
    tagList: systemAnnouncementPush.tagList,
    department: systemAnnouncementPush.department,
    currentAnnouncement: systemAnnouncementPush.currentAnnouncement,
    loginId: auth.loginId,
    roles: auth.roles.map(role => role.name)
  };
};

const mapDispatchToProps = {
  getAnnouncementList,
  pushNotification,
  showModal,
  addAnnouncement,
  getAnnouncement,
  modifyAnnouncement,
  deleteAnnouncement,
  getTagList,
  setDepartment
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemAnnouncementPush);
