import { Button, message, Modal, Upload } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  adjust,
  assocPath,
  compose,
  concat,
  cond,
  converge,
  dissoc,
  find,
  gt,
  has,
  identity,
  ifElse,
  is,
  last,
  mergeLeft,
  path,
  pathOr,
  paths,
  pluck,
  prop,
  propEq,
  props,
  T,
  whereEq,
  when,
  zipObj,
  __
} from 'ramda';
import { useWith as ramdaUseWith } from 'ramda';
import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { forkJoin, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import styled from 'styled-components';
import { createContentType, setContentTypeIsNull, uploadAPI } from '../apis';
import { isReallyEmpty } from '../utils/mixin';

const Container = styled.div`
  .ant-upload-picture-card-wrapper {
    min-height: 112px;
    display: flex;
    .ant-upload-list-item-info {
      display: flex;
      justify-content: center;
      align-item: center;
      > span {
        width: 100%;
      }
      img {
        object-fit: contain;
      }
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }
    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
`;
const gridBG = {
  background: `linear-gradient(-90deg, rgba(0,0,0,.05) 1px, transparent 1px),
  linear-gradient(rgba(0,0,0,.05) 1px, transparent 1px), 
  linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
  linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
  linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
  linear-gradient(-90deg, #aaa 1px, transparent 1px),
  linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
  linear-gradient(#00a 1px, transparent 1px),
  #fff`,
  backgroundSize: `4px 4px,
  4px 4px,
  80px 80px,
  80px 80px,
  80px 80px,
  80px 80px,
  80px 80px,
  80px 80px`
};
const getUploadUrlByType = ramdaUseWith(
  (url, type) => ifElse(is(Object), pathOr('', [type]), identity)(url),
  [identity, type => type.split('/').shift()]
);

const isVideo = url => /\.(mp4)$/i.test(url);

const createFormData = data => {
  const formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    if (value) {
      formData.append(key, value);
    }
  }
  return formData;
};

const processResultData = ifElse(
  compose(gt(__, 1), prop('length')),
  compose(
    zipObj(['videoUrl', 'previewUrl', 'duration']),
    converge(concat, [
      paths([[0, 'url']]),
      paths([
        [1, 'url'],
        [1, 'duration']
      ])
    ]),
    pluck('data')
  ),
  path([0, 'data', 'url'])
);

const transformValueToUrlData = ifElse(
  is(Object),
  compose(
    when(propEq('duration', undefined), dissoc('duration')),
    zipObj(['thumbUrl', 'url', 'duration']),
    props(['previewUrl', 'videoUrl', 'duration'])
  ),
  url => ({ url, thumbUrl: url })
);

const transformFileToValue = ifElse(
  compose(type => /^video\/*/i.test(type), prop('type')),
  compose(
    when(propEq('duration', undefined), dissoc('duration')),
    zipObj(['previewUrl', 'videoUrl', 'duration']),
    props(['thumbUrl', 'url', 'duration'])
  ),
  prop('url')
);

const updateLastFile = ramdaUseWith(
  (urlObj, fileList) => adjust(-1, mergeLeft(urlObj), fileList),
  [transformValueToUrlData, identity]
);

const findFileByUrl = ramdaUseWith(find, [
  compose(propEq('url'), ifElse(is(Object), path(['videoUrl']), identity)),
  identity
]);

const createFile = (file, index) => {
  if (isReallyEmpty(file)) {
    return {
      uid: '-' + (index + 1),
      name: '無資料',
      status: 'error'
    };
  }
  const thumbUrl = pathOr(file, ['previewUrl'], file);
  const url = pathOr(file, ['videoUrl'], file);
  const name = url.split('/').slice(-1)[0];

  return {
    uid: '-' + (index + 1),
    name: name.length > 1 ? name : 'Preview',
    thumbUrl: thumbUrl,
    url: url,
    duration: file.duration,
    status: 'done',
    type: isVideo(url) ? 'video/mp4' : `image/${name.split('.').slice(-1)[0]}`
  };
};

const createScreenshot = (file, currentTime = 1) => {
  const scaleFactor = 1;
  const video = document.createElement('video');
  video.src = URL.createObjectURL(file);
  video.autoplay = false;
  video.currentTime = currentTime;
  video.setAttribute('crossorigin', 'anonymous');
  return new Promise((resolve, reject) => {
    video.oncanplay = () => {
      const w = video.videoWidth * scaleFactor;
      const h = video.videoHeight * scaleFactor;
      const canvas = document.createElement('canvas');
      canvas.width = w;
      canvas.height = h;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, w, h);
      resolve({
        name: file.name,
        data: canvas.toDataURL(),
        duration: video.duration
      });
    };
  });
};

const base64ToFileObj = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
const addImageProcess = file => {
  const src = URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = src;
  });
};
const beforeUpload = (file, accept, fileSize, imageSize) => {
  return new Promise(async (resolve, reject) => {
    const isLtSize = file.size / 1024 / 1024 < fileSize;
    const isValidType = new RegExp(accept.replace(/,/g, '|')).test(file.type);
    const fileImageSize = imageSize ? await addImageProcess(file) : false;
    let isValidDimention = true;

    if (!isValidType) {
      message.error('檔案格式不符合！');
    }

    if (!isLtSize) {
      message.error(`檔案容量請小於 ${fileSize}MB !`);
    }

    if (fileImageSize && imageSize && !whereEq(fileImageSize, imageSize)) {
      message.error('圖片尺寸錯誤！');
      isValidDimention = false;
    }
    if (isLtSize && isValidType && isValidDimention) {
      resolve(true);
    } else {
      reject(new Error('圖片尺寸錯誤'));
    }
  });
};

const renderUI = ({
  type,
  children,
  disabled,
  maxFileCount,
  mode,
  fileList
}) => {
  const { Button, Card } = UploadImg;
  const maxCount = mode === 'multiple' ? maxFileCount : 1;
  // prettier-ignore
  switch (type) {
    case 'card':
      return (
        fileList.length < maxCount && (
          <Card disabled={disabled}>{children}</Card>
        )
      );
    case 'button':
      return (
        <Button disabled={disabled || fileList.length >= maxCount}>
          {children}
        </Button>
      );
    default:
      return false;
  }
};

export const renderPreviewer = (
  value,
  option = { muted: true, autoPlay: false, loop: false }
) => {
  if (isReallyEmpty(value)) {
    return false;
  }
  const url = cond([
    [has('videoUrl'), prop('videoUrl')],
    [T, identity]
  ])(value);
  if (isVideo(url)) {
    return (
      <video
        controls
        muted={option.muted}
        autoPlay={option.autoPlay}
        loop={option.loop}
        style={{ width: '100%' }}
        src={url}
      />
    );
  } else {
    return <img alt="preview" style={{ width: '100%' }} src={url} />;
  }
};

const showErrorMessage = (type, isVideo) => {
  // prettier-ignore
  switch (type) {
    case 'TypeError':
      message.error(`表單${isVideo ? '影片' : '圖片'}欄位錯誤`);
      break;
    default:
      message.error(`${isVideo ? '影片' : '圖片'}錯誤`);
      break;
  }
};

const UploadImg = memo(
  forwardRef(
    (
      {
        value = undefined,
        mode = 'default',
        accept = 'image/*',
        disabled = false,
        type = 'card',
        maxFileCount = 3,
        fileSize = 30,
        imageSize = null,
        showUploadList = true,
        uploadUrl,
        children,
        onChange,
        enableGridBackground = false,
        muted = true,
        autoPlay = false,
        loop = false
      },
      ref
    ) => {
      const [fileList, setFileList] = useState([]);
      const [previewVisible, setPreviewVisible] = useState(false);
      const [previewImage, setPreviewImage] = useState();
      const subscriptionRef = useRef();
      const listType = type === 'card' ? 'picture-card' : 'text';

      const handleChange = ({ file: { status, response }, fileList }) => {
        if (status) {
          setFileList(fileList);
        }
        if (status === 'done') {
          fileList = updateLastFile(
            response,
            mode === 'multiple' ? fileList : fileList.slice(-1)
          );
        }
        if (status === 'done' || status === 'removed') {
          triggerChange(fileList);
        }
      };

      const handlePreview = file => {
        setPreviewVisible(true);
        setPreviewImage(file.url);
      };

      const triggerChange = fileList => {
        if (onChange) {
          if (isReallyEmpty(fileList)) {
            onChange(null);
            return;
          }
          if (mode === 'multiple') {
            onChange(fileList.map(transformFileToValue));
          } else {
            onChange(compose(transformFileToValue, last)(fileList));
          }
        }
      };
      //map value to fileList
      useEffect(() => {
        const valueArray = is(Array, value) ? value : [value];
        setFileList(list => {
          if (isReallyEmpty(value)) {
            return [];
          }
          return valueArray.map(
            (v, index) => findFileByUrl(v, list) || createFile(v, index)
          );
        });
      }, [value]);

      //unsubscribe uploaders
      useEffect(() => {
        return () => {
          if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
          }
        };
      }, []);

      return (
        <Container>
          <Upload
            name="file"
            ref={ref}
            accept={accept}
            listType={listType}
            fileList={fileList}
            beforeUpload={file =>
              beforeUpload(file, accept, fileSize, imageSize)
            }
            onPreview={handlePreview}
            onChange={handleChange}
            showUploadList={showUploadList}
            disabled={disabled}
            openFileDialogOnClick={
              fileList.length <= 0 || typeof children !== 'function'
            }
            customRequest={info => {
              setContentTypeIsNull();
              const fileType = info.file.type;
              const isVideo = fileType === 'video/mp4';
              const content$ = of(info).pipe(
                map(({ file }) => createFormData({ file, type })),
                switchMap(formData =>
                  uploadAPI(getUploadUrlByType(uploadUrl, fileType))(formData)
                )
              );
              const screenshot$ = of(info).pipe(
                mergeMap(({ file }) => createScreenshot(file)),
                switchMap(base64 => {
                  const file = base64ToFileObj(
                    base64.data,
                    base64.name.split('.')[0] + '.jpg'
                  );
                  const formData = createFormData({ file });
                  return uploadAPI(getUploadUrlByType(uploadUrl, 'image/*'))(
                    formData
                  ).pipe(map(assocPath(['data', 'duration'], base64.duration)));
                })
              );
              const uploaders = isVideo ? [content$, screenshot$] : [content$];
              subscriptionRef.current = forkJoin(uploaders).subscribe(
                res => {
                  try {
                    info.onSuccess(processResultData(res));
                  } catch (e) {
                    showErrorMessage(e.name, isVideo);
                  }
                  createContentType('application/json;charset=UTF-8');
                },
                error => {
                  info.onError(error);
                }
              );
            }}
          >
            {renderUI({
              type,
              disabled,
              children,
              mode,
              maxFileCount,
              fileList
            })}
          </Upload>
          <Modal
            destroyOnClose={true}
            open={previewVisible}
            footer={null}
            bodyStyle={enableGridBackground ? gridBG : null}
            onCancel={() => setPreviewVisible(false)}
          >
            {renderPreviewer(previewImage, {
              muted,
              autoPlay,
              loop
            })}
          </Modal>
        </Container>
      );
    }
  )
);
UploadImg.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.string,
  mode: PropTypes.string,
  fileSize: PropTypes.number,
  maxFileCount: PropTypes.number
};

UploadImg.Button = props => {
  return (
    <Button {...props}>
      <UploadOutlined />
      {props.children || '上傳圖片'}
    </Button>
  );
};

UploadImg.Card = props => {
  return (
    <div {...props}>
      <PlusOutlined />
      <div className="ant-upload-text">{props.children || 'Upload'}</div>
    </div>
  );
};
export default UploadImg;
