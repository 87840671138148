import json2csv from 'json2csv';
import downloadFile from './downloadFile';

export default (data, fields, fieldNames) => {
  const csvData = json2csv({
    data,
    fields,
    fieldNames,
  });

  const csvBlob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvData], {
    type: 'text/csv',
  });
  const filename = window.URL.createObjectURL(csvBlob) + '.csv';
  downloadFile(csvBlob, filename);
};
