import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  NobilityNameEnum,
  NobilitiesPathMap,
  NobilitiesSort,
} from './constants';

const NobilitiesWrapper = styled.div`
  padding-bottom: 3rem;
`;

const NobilitiesBlock = styled.div`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const NobilityName = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
`;

const NobilitySeats = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > .ant-avatar {
    margin: 0.25rem;
    flex-shrink: 0;
  }
`;

class Nobilities extends React.PureComponent {
  static propTypes = {
    family: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderNobilitySeat = (user, key) => {
    const isEmpty = _.isEmpty(user);
    const src = _.get(user, 'thumbUrl');
    const size = 64;
    return isEmpty ? (
      <Avatar
        key={key}
        size={size}
      >
        <span>空</span>
      </Avatar>
    ) : (
      <Avatar
        key={key}
        src={src}
        size={size}
        icon={<UserOutlined />}
      />
    );
  };

  renderNobilitySeats = (qty, nobility) => {
    const { family } = this.props;
    const seats = _.fill(Array(qty), null);
    const prop = NobilitiesPathMap[nobility];
    const dataset = _.get(family, `nobilities.${prop}`);
    const users = dataset || [];
    return seats.map((value, index) => {
      const user = _.get(users, index);
      return this.renderNobilitySeat(user, index);
    });
  };

  renderNobilitiesBlocks = () => {
    const { family } = this.props;
    const qtyList = _.get(family, 'familyLevel.nobilityQty', []);
    const size = _.size(qtyList);
    return size > 0 && _.map(NobilitiesSort, (nobility) => {
      const qty = qtyList[nobility];
      return (
        <NobilitiesBlock key={nobility}>
          <NobilityName>
            <span>&lt;&lt;&lt;&nbsp;</span>
            <span>{NobilityNameEnum[nobility]}</span>
            <span>&nbsp;&gt;&gt;&gt;</span>
          </NobilityName>
          <NobilitySeats>
            {this.renderNobilitySeats(qty, nobility)}
          </NobilitySeats>
        </NobilitiesBlock>
      );
    });
  };

  render() {
    return (
      <NobilitiesWrapper>
        {this.renderNobilitiesBlocks()}
      </NobilitiesWrapper>
    );
  }
}

export default Nobilities;
