import { handleActions, createAction } from 'redux-actions';
import { pipe } from 'ramda';
import { pluck, switchMap, map, tap, mapTo } from 'rxjs/operators';
import { message } from 'antd';
import { ofType, catchRequestError } from '../utils/extendOperators';
import {
  getLiveMasterInfoAPI,
  getCurrencyListAPI,
  sendLiveMasterAPI,
} from '../apis';
import { catchError } from 'rxjs/operators';

/**
 * Action Types
 */
const GET_LIVE_MASTER_INFO = 'GET_LIVE_MASTER_INFO';
const GET_LIVE_MASTER_INFO_SUCCESS = 'GET_LIVE_MASTER_INFO_SUCCESS';
const GET_LIVE_MASTER_INFO_FAILRUE = 'GET_LIVE_MASTER_INFO_FAILRUE';

const SEND_LIVE_MASTER_INFO = 'SEND_LIVE_MASTER_INFO';
const SEND_LIVE_MASTER_INFO_SUCCESS = 'SEND_LIVE_MASTER_INFO_SUCCESS';
const SEND_LIVE_MASTER_INFO_FAILURE = 'SEND_LIVE_MASTER_INFO_FAILURE';

const GET_CURRENCY_LIST = 'GET_CURRENCY_LIST';
const GET_CURRENCY_LIST_SUCCESS = 'GET_CURRENCY_LIST_SUCCESS';
const GET_CURRENCY_LIST_FAILURE = 'GET_CURRENCY_LIST_FAILURE';

/**
 * Action Creator
 */
export const getLiveMasterInfo = createAction(GET_LIVE_MASTER_INFO);
const getLiveMasterInfoSuccess = createAction(GET_LIVE_MASTER_INFO_SUCCESS);
const getLiveMasterInfoFailrue = createAction(GET_LIVE_MASTER_INFO_FAILRUE);

export const sendLiveMasterInfo = createAction(SEND_LIVE_MASTER_INFO);
const sendLiveMasterInfoSuccess = createAction(SEND_LIVE_MASTER_INFO_SUCCESS);
const sendLiveMasterInfoFailure = createAction(SEND_LIVE_MASTER_INFO_FAILURE);

export const getCurrencyList = createAction(GET_CURRENCY_LIST);
const getCurrencyListSuccess = createAction(GET_CURRENCY_LIST_SUCCESS);
const getCurrencyListFailure = createAction(GET_CURRENCY_LIST_FAILURE);

/**
 * Epics
 */
export const getLiveMasterInfoEpic = pipe(
  ofType(GET_LIVE_MASTER_INFO),
  pluck('payload'),
  switchMap(payload =>
    getLiveMasterInfoAPI(payload).pipe(
      map(getLiveMasterInfoSuccess),
      catchRequestError(getLiveMasterInfoFailrue)
    )
  )
);

export const sendLiveMasterInfoEpic = pipe(
  ofType(SEND_LIVE_MASTER_INFO),
  pluck('payload'),
  switchMap(payload =>
    sendLiveMasterAPI(payload.id, payload).pipe(
      tap(() => {
        message.success('修改成功');
      }),
      map(sendLiveMasterInfoSuccess),
      catchRequestError(sendLiveMasterInfoFailure)
    )
  )
);

export const showSubmitErrorEpic = pipe(
  ofType(SEND_LIVE_MASTER_INFO_FAILURE),
  pluck('payload'),
  tap(payload => alert(JSON.stringify(payload))),
  mapTo({ type: 'DO_NOTHING' })
);

export const getCurrencyListEpic = pipe(
  ofType(GET_CURRENCY_LIST),
  pluck('payload'),
  switchMap(payload =>
    getCurrencyListAPI().pipe(
      map(getCurrencyListSuccess),
      catchError(getCurrencyListFailure)
    )
  )
);

/**
 * Reducer
 */
const initialState = {
  formData: {
    currencyList: [],
  },
  formValue: {},
};

export default handleActions(
  {
    [GET_LIVE_MASTER_INFO_SUCCESS]: (state, action) => ({
      ...state,
      formValue: action.payload,
    }),
    [GET_CURRENCY_LIST_SUCCESS]: (state, action) => ({
      ...state,
      formData: {
        ...state.formData,
        ...action.payload,
      },
    }),
  },
  initialState
);
