import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Input, Button, Card } from 'antd';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import * as actions from '../actions';
import brandName from '../constants/brandName';

const FormItem = Form.Item;

const mapStateToProps = state => ({
  auth: state.auth,
  isLoading: state.auth.isLoading,
  isLoginFailed: state.auth.isLoginFailed,
  isInitCookieAuth: state.views.isInitCookieAuth,
  isAuthenticated: state.auth.isAuthenticated
});

const LoginBG = styled.div`
  height: 100%;
  background-color: rgb(64, 64, 64);
  padding-top: 20vh;
`;

const StyledCard = styled(Card).attrs({
  title: `${brandName} 直播後台登入`,
  bordered: false
})`
  max-width: 500px;
  margin: 0 auto;

  .ant-card-head-title {
    font-size: 20px;
    text-align: center;
  }
`;

export default WrappedComponent => {
  @connect(mapStateToProps, { login: actions.login })
  @Form.create()
  class CheckAuth extends Component {
    static propTypes = {
      auth: PropTypes.object.isRequired,
      login: PropTypes.func.isRequired,
      isLoading: PropTypes.bool.isRequired,
      isLoginFailed: PropTypes.bool.isRequired
    };

    constructor(props) {
      super(props);
      this.state = {
        isAuthenticated: props.auth.isAuthenticated
      };
    }

    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.login({
            account: values.userName,
            password: values.password
          });
        }
      });
    };

    renderLogin = () => {
      const { form, isLoading, isLoginFailed } = this.props;
      const { getFieldDecorator } = form;

      return (
        <LoginBG>
          <StyledCard>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator('userName', {
                  rules: [{ required: true, message: '帳號為必填欄位' }]
                })(
                  <Input
                    prefix={<LegacyIcon type="user" style={{ fontSize: 13 }} />}
                    placeholder="請輸入帳號"
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: '密碼為必填欄位' }]
                })(
                  <Input
                    prefix={<LegacyIcon type="lock" style={{ fontSize: 13 }} />}
                    type="password"
                    placeholder="請輸入密碼"
                  />
                )}
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={isLoading}
                >
                  登入
                </Button>
                {isLoginFailed && (
                  <span style={{ color: 'red', paddingLeft: '8px' }}>
                    帳號或密碼錯誤！
                  </span>
                )}
              </FormItem>
            </Form>
          </StyledCard>
        </LoginBG>
      );
    };

    render() {
      const {
        auth: { isAuthenticated }
      } = this.props;
      return isAuthenticated ? <WrappedComponent /> : this.renderLogin();
    }
  }

  return CheckAuth;
};
