import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { message, Button, Table, Modal } from 'antd';
import {
  getRevenueWithdrawListAPI,
  withdrawAgencyRevenueAPI,
} from '../../../apis';
import WithdrawFilters from './WithdrawFilters';
import WithdrawInfo from './WithdrawInfo';

const { Column } = Table;
const { confirm } = Modal;

const StatusEnum = Object.freeze({
  0: '未結算',
  1: '已結算',
});

const RevenueWithdrawFilters = styled(WithdrawFilters)`
  margin-bottom: 1rem;
`;

const RevenueWithdrawInfo =styled(WithdrawInfo)`
  margin-bottom: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    & + button {
      margin-left: 0.5rem;
    }
  }
`;

class RevenueWithdraw extends React.Component {
  constructor(props) {
    super(props);

    const now = new Date();

    this.WithdrawFiltersRef = React.createRef();

    this.state = {
      isFetchingList: false,
      currentMonth: now.getMonth() + 1,
      revenueWithdraws: [],
    };
  }

  processRevenueWithdrawList = (data) => {
    const { WithdrawFilters } = this;
    const { currentMonth } = this.state;
    const revenueWithdraws = [];
    const year = WithdrawFilters.getFieldValue('year');
    for (let i = currentMonth; i > 0; i--) {
      const month = `${year}-${_.padStart(i, 2, '0')}`;
      const row = _.find(data, { month });
      if (row) {
        revenueWithdraws.push({ ...row, status: 1 });
        continue;
      }
      revenueWithdraws.push({
        month,
        status: 0,
        operatorNickname: '-',
      });
    }
    this.setState({ revenueWithdraws });
  }

  fetchRevenueWithdrawList = () => {
    this.setState({ isFetchingList: true });

    const { WithdrawFilters } = this;
    const payload = WithdrawFilters.getFormData();
    const obserable = getRevenueWithdrawListAPI(payload);
    obserable.subscribe((response) => {
      const { data } = response;
      this.processRevenueWithdrawList(data);
      this.setState({ isFetchingList: false });
    });
  }

  withdrawAgencyRevenue = (payload) => {
    const obserable = withdrawAgencyRevenueAPI(payload);
    obserable.subscribe((response) => {
      const { Status } = response;
      if (Status === 'Ok') {
        message.success((
          <React.Fragment>
            <span>業績結算已排程</span>
            <div>請於五分鐘後至指定信箱中收取報表</div>
          </React.Fragment>
        ));
        this.fetchRevenueWithdrawList();
      } else {
        message.error('結算失敗');
      }
    });
  }

  onWithdrawClick = (targetMonth) => {
    const { WithdrawFilters, WithdrawInfo } = this;
    const agency = WithdrawFilters.getFormAgency();
    const iPromise = WithdrawInfo.validateFields();
    
    iPromise.then((values) => {
      confirm({
        title: '業績結算',
        content: (
          <div>
            <div>{`確定結算 ${agency.agencyName}/${targetMonth} 業績嗎？`}</div>
            <div>同月份僅能結算一次。</div>
          </div>
        ),
        onOk: () => {
          const { email } = values;
          this.withdrawAgencyRevenue({
            email,
            targetMonth,
            agencyIds: [`${agency.agencyId}`],
          });
        },
      });
    });
    iPromise.catch(() => {
      message.error('請確認Email格式');
    });
  }

  renderStatus = (rowData) => {
    const { status } = rowData;
    return StatusEnum[status];
  }

  renderActions = (rowData) => {
    const { status, month } = rowData;
    return (
      <Actions>
        <Button
          type="warn"
          disabled={status}
          onClick={() => {
            this.onWithdrawClick(month);
          }}
        >
          <span>結算 & 匯出</span>
        </Button>
      </Actions>
    );
  }

  renderAchievementTable = () => {
    const { isFetchingList, revenueWithdraws } = this.state;
    return (
      <Table
        rowKey="month"
        dataSource={revenueWithdraws}
        loading={isFetchingList}
        pagination={false}
      >
        <Column title="月份" dataIndex="month" key="month" />
        <Column title="帳務狀況" key="status" render={this.renderStatus} />
        <Column title="操作者" dataIndex="operatorNickname" key="operatorNickname" />
        <Column title="功能" width="8rem" key="actions" render={this.renderActions} />
      </Table>
    );
  }

  render() {
    return (
      <main>
        <RevenueWithdrawFilters
          ref={(form) => this.WithdrawFilters = form}
          onSubmit={this.fetchRevenueWithdrawList}
        />
        <RevenueWithdrawInfo
          ref={(form) => this.WithdrawInfo = form}
        />
        {this.renderAchievementTable()}
      </main>
    );
  }
}

export default RevenueWithdraw;
