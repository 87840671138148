import { createAction, handleActions, combineActions } from 'redux-actions';
import { message } from 'antd';
import {
  switchMap,
  mergeMap,
  map,
  withLatestFrom,
  pluck
} from 'rxjs/operators';
import {
  ofType,
  catchRequestError,
  getPayloadByType
} from '../utils/extendOperators';
import {
  getGiftListAPI,
  setGiftAPI,
  deleteGiftAPI,
  addGiftAPI,
  getGiftRandomAPI,
  addGiftRandomAPI,
  setGiftRandomAPI
} from '../apis';
import { createRequestTypes } from '../actions/Types';
import produce from 'immer';
export const defaultQuery = {
  typeFilter: null,
  giftCategoryId: '',
  statusFilter: 'all',
  orderBy: 'point_desc',
  item: 20,
  page: 1
};
/**
 * Action Types
 */
const GET_GIFT_LIST = createRequestTypes('GET_GIFT_LIST');
const ADD_GIFT = createRequestTypes('ADD_GIFT');
const SET_CURRENT_GIFT = 'SET_CURRENT_GIFT';
const CLOSE_GIFT_MODAL = 'CLOSE_GIFT_MODAL';
const SET_GIFT = createRequestTypes('SET_GIFT');
const DELETE_GIFT = createRequestTypes('DELETE_GIFT');
const CHANGE_STATUS = 'CHANGE_STATUS';
const CHANGE_CATEGORY_ID = 'CHANGE_CATEGORY_ID';
const UPDATE_QUERY = 'UPDATE_QUERY';
const GET_GIFT_RANDOM = createRequestTypes('GET_GIFT_RANDOM');
const ADD_GIFT_RANDOM = createRequestTypes('ADD_GIFT_RANDOM');
const SET_GIFT_RANDOM = createRequestTypes('SET_GIFT_RANDOM');
/**
 * Action Creator
 */
export const getGiftList = ({
  typeFilter,
  giftCategoryId,
  statusFilter,
  orderBy,
  item,
  page
} = defaultQuery) =>
  createAction(GET_GIFT_LIST.REQUEST)({
    typeFilter,
    giftCategoryId,
    statusFilter,
    orderBy,
    item,
    page
  });
export const getGiftListSuccess = createAction(GET_GIFT_LIST.SUCCESS);
export const getGiftListFailure = createAction(GET_GIFT_LIST.FAILURE);
export const addGift = createAction(ADD_GIFT.REQUEST, data => data);
export const addGiftSuccess = createAction(ADD_GIFT.SUCCESS);
export const addGiftFailure = createAction(ADD_GIFT.FAILURE);
export const setGift = (id, data) =>
  createAction(SET_GIFT.REQUEST)({
    id,
    data
  });
export const setGiftSuccess = createAction(SET_GIFT.SUCCESS);
export const setGiftFailure = createAction(SET_GIFT.FAILURE);
export const deleteGift = createAction(DELETE_GIFT.REQUEST);
export const deleteGiftSuccess = createAction(DELETE_GIFT.SUCCESS);
export const deleteGiftFailure = createAction(DELETE_GIFT.FAILURE);
export const setCurrentGift = (info, isCreateMode) =>
  createAction(SET_CURRENT_GIFT)({ info, isCreateMode });
export const closeGiftModal = createAction(CLOSE_GIFT_MODAL);
export const updateQuery = createAction(UPDATE_QUERY);
export const changeStatus = createAction(CHANGE_STATUS);
export const changeCategoryId = createAction(CHANGE_CATEGORY_ID);
// 隨機禮物
export const getGiftRandom = createAction(
  GET_GIFT_RANDOM.REQUEST,
  (payload, callback) => ({
    payload,
    callback
  })
);
export const getGiftRandomSuccess = createAction(GET_GIFT_RANDOM.SUCCESS);
export const getGiftRandomFailure = createAction(GET_GIFT_RANDOM.FAILURE);
export const addGiftRandom = createAction(
  ADD_GIFT_RANDOM.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback
  })
);
export const addGiftRandomSuccess = createAction(ADD_GIFT_RANDOM.SUCCESS);
export const addGiftRandomFailure = createAction(ADD_GIFT_RANDOM.FAILURE);
export const setGiftRandom = createAction(
  SET_GIFT_RANDOM.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback
  })
);
export const setGiftRandomSuccess = createAction(SET_GIFT_RANDOM.SUCCESS);
export const setGiftRandomFailure = createAction(SET_GIFT_RANDOM.FAILURE);
/**
 * Epics
 */
export const getGiftListEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_GIFT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getGiftListAPI(payload).pipe(
        map(getGiftListSuccess),
        catchRequestError(getGiftListFailure)
      )
    )
  );

export const addGiftEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_GIFT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      addGiftAPI(payload).pipe(
        withLatestFrom(state$),
        mergeMap(([res, { giftList }]) => [
          addGiftSuccess(res),
          getGiftList({ ...giftList })
        ]),
        catchRequestError(err => {
          message.error('發生錯誤');
          return addGiftFailure(err);
        })
      )
    )
  );

export const setGiftEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_GIFT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      setGiftAPI(payload.id, payload.data).pipe(
        withLatestFrom(state$),
        mergeMap(([res, { giftList }]) => {
          return [setGiftSuccess(res), getGiftList({ ...giftList })];
        }),
        catchRequestError(err => {
          message.error('發生錯誤');
          return setGiftFailure(err);
        })
      )
    )
  );

export const deleteGiftEpic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_GIFT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      deleteGiftAPI(payload).pipe(
        withLatestFrom(state$),
        mergeMap(([res, { giftList }]) => {
          return [deleteGiftSuccess(res), getGiftList({ ...giftList })];
        }),
        catchRequestError(err => {
          message.error('發生錯誤');
          return deleteGiftFailure(err);
        })
      )
    )
  );

export const changeStatusEpic = (action$, state$) =>
  action$.pipe(
    getPayloadByType(CHANGE_STATUS),
    withLatestFrom(state$),
    map(([status, state]) => {
      const {
        giftList: { categoryId }
      } = state;
      return getGiftList(status, categoryId);
    })
  );

export const changeCategoryIdEpic = (action$, state$) =>
  action$.pipe(
    getPayloadByType(CHANGE_CATEGORY_ID),
    withLatestFrom(state$),
    map(([categoryId, state]) => {
      const {
        giftList: { status }
      } = state;
      return getGiftList(status, categoryId);
    })
  );

//隨機禮物
export const getGiftRandomEpic = action$ =>
  action$.pipe(
    ofType(GET_GIFT_RANDOM.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callback }) =>
      getGiftRandomAPI(payload).pipe(
        map(res => {
          if (typeof callback === 'function') {
            callback();
          }
          return getGiftRandomSuccess(res);
        }),
        catchRequestError(getGiftRandomFailure)
      )
    )
  );

export const addGiftRandomtEpic = action$ =>
  action$.pipe(
    ofType(ADD_GIFT_RANDOM.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      addGiftRandomAPI(payload).pipe(
        mergeMap(res => {
          const actions = [addGiftRandomSuccess(res)];
          if (typeof callback === 'function') {
            callback();
          }
          if (callbackAction) actions.push(callbackAction);
          return actions;
        }),
        catchRequestError(addGiftRandomFailure)
      )
    )
  );

export const setEGiftRandomtEpic = action$ =>
  action$.pipe(
    ofType(SET_GIFT_RANDOM.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      setGiftRandomAPI(payload).pipe(
        mergeMap(res => {
          const actions = [setGiftRandomSuccess(res)];
          if (typeof callback === 'function') {
            callback();
          }
          if (callbackAction) actions.push(callbackAction);
          return actions;
        }),
        catchRequestError(setGiftRandomFailure)
      )
    )
  );
/**
 * Reducer
 */
const initalState = {
  data: [],
  loading: false,
  currentGift: {},
  currentGiftRandom: null,
  isModalVisible: false,
  isCreateMode: false,
  typeFilter: null,
  statusFilter: 'all',
  giftCategoryId: '',
  orderBy: 'point_desc',
  page: 1,
  item: 20,
  totalCount: 0
};

export default handleActions(
  {
    [GET_GIFT_LIST.REQUEST]: produce((draft, { payload }) => {
      return { ...draft, ...payload, loading: true };
    }),
    [GET_GIFT_LIST.SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      totalCount: action.payload.totalCount,
      loading: false
    }),
    [GET_GIFT_LIST.FAILURE]: (state, action) => ({
      ...state,
      data: [],
      totalCount: 0,
      loading: false
    }),
    [combineActions(
      ADD_GIFT.REQUEST,
      SET_GIFT.REQUEST,
      DELETE_GIFT.REQUEST,
      GET_GIFT_RANDOM.REQUEST,
      ADD_GIFT_RANDOM.REQUEST,
      SET_GIFT_RANDOM.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [combineActions(
      ADD_GIFT.SUCCESS,
      ADD_GIFT.FAILURE,
      SET_GIFT.SUCCESS,
      SET_GIFT.FAILURE,
      DELETE_GIFT.SUCCESS,
      DELETE_GIFT.FAILURE,
      SET_GIFT_RANDOM.FAILURE,
      ADD_GIFT_RANDOM.SUCCESS,
      ADD_GIFT_RANDOM.FAILURE
    )]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [GET_GIFT_RANDOM.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentGiftRandom = payload.data;
    }),
    [GET_GIFT_RANDOM.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.currentGiftRandom = null;
    }),
    [SET_GIFT_RANDOM.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentGiftRandom = null;
    }),
    [SET_CURRENT_GIFT]: (state, action) => ({
      ...state,
      currentGift: action.payload.info,
      isModalVisible: true,
      isCreateMode: action.payload.isCreateMode
    }),
    [CLOSE_GIFT_MODAL]: state => ({
      ...state,
      isModalVisible: false,
      currentGift: {}
    }),
    [CHANGE_STATUS]: (state, action) => ({
      ...state,
      status: action.payload
    }),
    [CHANGE_CATEGORY_ID]: (state, action) => ({
      ...state,
      categoryId: action.payload
    }),
    [UPDATE_QUERY]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  initalState
);
