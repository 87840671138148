import React, { Component } from 'react';
import styled from 'styled-components';
import fakeData from '../../fake/liveProfitShareDocApplicationSetting';
import { Table, Button } from 'antd';
import LiveProfitIdAvatarModal from './components/LiveProfitIdAvatarModal';

const TableContainer = styled.div`
  .table-id-img {
    max-width: 150px;
  }
`;

const TableToolbar = styled.div`
  padding: 8px 64px 16px 8px;

  .title {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 160px;
  }
`;

class LiveProfitShareDocApplicationSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,

    };
  }

  handleNewDocBtnClick = () => {
    this.setState({
      isModalVisible: true,
    });
  }

  render() {
    const tableColumns = [{
      title: '身分證',
      dataIndex: 'avatarUrl',
      render: url => <img className="table-id-img" src={url} alt="personal-id-avatar" />,
    }, {
      title: '說明',
      dataIndex: 'captionText',
    }];
    return (
      <div>
        <h1>主播 > 申請直播分潤文件設定</h1>
        <TableToolbar>
          <span className="title">已上傳文件</span>
          <Button
            onClick={this.handleNewDocBtnClick}
            type="primary"
            className="new-doc-btn"
          >
            新增文件
          </Button>
        </TableToolbar>
        <TableContainer>
          <Table
            columns={tableColumns}
            dataSource={fakeData}
            rowKey="_id"
          />
        </TableContainer>
        <LiveProfitIdAvatarModal
          isModalVisible={this.state.isModalVisible}
          onClose={() => this.setState({ isModalVisible: false })}
        />
      </div>
    );
  }
}

export default LiveProfitShareDocApplicationSetting;
