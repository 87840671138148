import React from 'react';
import styled from 'styled-components';
import {
  message, Button, Descriptions,
  Modal, Tooltip,
} from 'antd';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { history } from '../../store';
import { ACCOUNT_CREATION_MANAGEMENT } from '../../constants/routes';

const IdUnit = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  word-break: break-word;

  span {
    flex-grow: 1;
  }

  .ant-btn {
    padding: 0 0.5rem;
    flex-shrink: 0;
    font-size: 1.2rem;
  }
`;

const EditButton = styled(Button)`
  padding: 0;
  font-size: 0.9rem;
`;

export default (data) => {
  const onCopyIDButtonClick = () => {
    const id = data.id;
    const iPromise = navigator.clipboard.writeText(id);
    iPromise.then(() => {
      message.success('UUID 複製成功');
    });
    iPromise.catch(() => {
      message.error('UUID 複製失敗');
    });
  };

  const onGotoEditButtonClick = () => {
    modal.destroy();
    history.push(`/user-management/personal/${data.id}`, {
      from: ACCOUNT_CREATION_MANAGEMENT,
    });
  };

  const modal = Modal.success({
    width: 600,
    title: '帳號建立完成',
    okText: '關閉',
    content: (
      <React.Fragment>
        <Descriptions column={1} style={{ margin: '12px 0' }} bordered>
          <Descriptions.Item label="LoginId">
            <span>{data.loginId}</span>
          </Descriptions.Item>
          <Descriptions.Item label="UUID">
            <IdUnit>
              <span>{data.id}</span>
              <Tooltip placement="top" title={'複製'}>
                <Button
                  type="link"
                  onClick={onCopyIDButtonClick}
                >
                  <CopyOutlined />
                </Button>
              </Tooltip>
            </IdUnit>
          </Descriptions.Item>
        </Descriptions>
        <EditButton
          type="link"
          onClick={onGotoEditButtonClick}
        >
          <EditOutlined />
          <span>前往編輯資料</span>
        </EditButton>
      </React.Fragment>
    ),
  });
};
