import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import options from './options';

const Wrapper = styled.div`
  width: 100%;
  height: 31rem;
  display: flex;
  border: solid 1px #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
`;

const Spinner = styled(Spin)`
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class LiveMasterNetworkSpeedChart extends React.Component {
  static propTypes = {
    dataset: PropTypes.array,
    visible: PropTypes.bool,
  }

  static defaultProps = {
    dataset: [],
    visible: false,
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderLoader = () => {
    return (
      <Spinner
        indicator={
          <LoadingOutlined style={{ fontSize: 20 }} spin />
        }
      />
    );
  }

  render() {
    const { visible, dataset, style } = this.props;
    return visible && (
      <Wrapper style={style}>
        <Chart
          width="100%"
          height="inherit"
          chartType="LineChart"
          loader={this.renderLoader()}
          data={dataset}
          options={options}
        />
      </Wrapper>
    );
  }
}

export default LiveMasterNetworkSpeedChart;
