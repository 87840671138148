import React, { Component } from 'react';
import { Button, Table, Popconfirm } from 'antd';

import LightBox from '../../components/LightBox';

class AvatarTable extends Component {
  static defaultProps = {
    avatars: [],
    deleteAvatar: v => console.log(v)
  };

  generateTableColumn() {
    const { deleteAvatar } = this.props;

    return [
      {
        title: '頭銜名稱',
        dataIndex: 'avatarName'
      },
      {
        title: '點數',
        dataIndex: 'point'
      },
      {
        title: '頭銜活動類別',
        dataIndex: 'eventName',
        render: eventName =>
          eventName ? <p>{eventName}</p> : <p>活動過期或不存在</p>
      },
      {
        title: '訊息前 icon (有字)',
        dataIndex: 'imageAvatarFull',
        render: photo => <LightBox src={photo} />
      },
      {
        title: '訊息前 icon (無字)',
        dataIndex: 'imageAvatarShort',
        render: photo => <LightBox src={photo} />
      },
      {
        title: '進場特效',
        dataIndex: 'imageDebutBasic',
        render: photo => <LightBox src={photo} />
      },
      {
        title: '進場特效翻轉版',
        dataIndex: 'imageDebutTrans',
        render: photo => <LightBox src={photo} />
      },
      {
        title: '上方頭像特殊頭匡',
        dataIndex: 'imagePhotoFrame',
        render: photo => <LightBox src={photo} />
      },
      {
        title: '頭銜活動圖示',
        dataIndex: 'imageBanner',
        render: photo => <LightBox src={photo} />
      },
      {
        title: '移除',
        key: 'delete',
        dataIndex: 'id',
        render: id => (
          <Popconfirm
            title="是否確定移除"
            onConfirm={() => deleteAvatar(id)}
            placement="right"
          >
            <Button>移除</Button>
          </Popconfirm>
        )
      }
    ];
  }

  render() {
    const { avatars } = this.props;

    return (
      <div>
        {avatars.length > 0 && (
          <div>
            <Table
              rowKey="id"
              pagination={false}
              columns={this.generateTableColumn()}
              dataSource={avatars}
            />
          </div>
        )}
      </div>
    );
  }
}

export default AvatarTable;
