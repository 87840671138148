import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import { groupBy } from 'ramda';
import { switchMap, map, pluck, mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ofType, catchRequestError } from '../utils/extendOperators';

import {
  getAssignedAchievementListAPI,
  addAssignedAchievementAPI,
  setAssignedAchievementAPI,
  deleteAssignedAchievementAPI
} from '../apis';
import { notification } from 'antd';

/**
 * Action Types
 */
const SHOW_MODAL = 'ASSIGNEDACHIEVEMENTS.SHOW_MODAL';

const GET_ASSIGNED_ACHIEVEMENT_LIST = 'GET_ASSIGNED_ACHIEVEMENT_LIST';
const GET_ASSIGNED_ACHIEVEMENT_LIST_SUCCESS =
  'GET_ASSIGNED_ACHIEVEMENT_LIST_SUCCESS';
const GET_ASSIGNED_ACHIEVEMENT_LIST_FAILURE =
  'GET_ASSIGNED_ACHIEVEMENT_LIST_FAILURE';

const ADD_ASSIGNED_ACHIEVEMENT = 'ADD_ASSIGNED_ACHIEVEMENT';
const ADD_ASSIGNED_ACHIEVEMENT_SUCCESS = 'ADD_ASSIGNED_ACHIEVEMENT_SUCCESS';
const ADD_ASSIGNED_ACHIEVEMENT_FAILURE = 'ADD_ASSIGNED_ACHIEVEMENT_FAILURE';

const MODIFY_ASSIGNED_ACHIEVEMENT = 'MODIFY_ASSIGNED_ACHIEVEMENT';
const MODIFY_ASSIGNED_ACHIEVEMENT_SUCCESS =
  'MODIFY_ASSIGNED_ACHIEVEMENT_SUCCESS';
const MODIFY_ASSIGNED_ACHIEVEMENT_FAILURE =
  'MODIFY_ASSIGNED_ACHIEVEMENT_FAILURE';

const DELETE_ASSIGNED_ACHIEVEMENT = 'DELETE_ASSIGNED_ACHIEVEMENT';
const DELETE_ASSIGNED_ACHIEVEMENT_SUCCESS =
  'DELETE_ASSIGNED_ACHIEVEMENT_SUCCESS';
const DELETE_ASSIGNED_ACHIEVEMENT_FAILURE =
  'DELETE_ASSIGNED_ACHIEVEMENT_FAILURE';

/**
 * Action Creators
 */
export const showModal = createAction(SHOW_MODAL);
export const getAssignedAchievementList = createAction(
  GET_ASSIGNED_ACHIEVEMENT_LIST
);
export const getAssignedAchievementListSuccess = createAction(
  GET_ASSIGNED_ACHIEVEMENT_LIST_SUCCESS
);
export const getAssignedAchievementListFailure = createAction(
  GET_ASSIGNED_ACHIEVEMENT_LIST_FAILURE
);

export const addAssignedAchievement = createAction(ADD_ASSIGNED_ACHIEVEMENT);
export const addAssignedAchievementSuccess = createAction(
  ADD_ASSIGNED_ACHIEVEMENT_SUCCESS
);
export const addAssignedAchievementFailure = createAction(
  ADD_ASSIGNED_ACHIEVEMENT_FAILURE
);

export const modifyAssignedAchievement = createAction(
  MODIFY_ASSIGNED_ACHIEVEMENT
);
export const modifyAssignedAchievementSuccess = createAction(
  MODIFY_ASSIGNED_ACHIEVEMENT_SUCCESS
);
export const modifyAssignedAchievementFailure = createAction(
  MODIFY_ASSIGNED_ACHIEVEMENT_FAILURE
);

export const deleteAssignedAchievement = createAction(
  DELETE_ASSIGNED_ACHIEVEMENT,
  (ids = []) => ids
);
export const deleteAssignedAchievementSuccess = createAction(
  DELETE_ASSIGNED_ACHIEVEMENT_SUCCESS
);
export const deleteAssignedAchievementFailure = createAction(
  DELETE_ASSIGNED_ACHIEVEMENT_FAILURE
);

export const getAssignedAchievementListEpic = action$ =>
  action$.pipe(
    ofType(GET_ASSIGNED_ACHIEVEMENT_LIST),
    pluck('payload'),
    switchMap(() =>
      getAssignedAchievementListAPI().pipe(
        map(getAssignedAchievementListSuccess),
        catchRequestError(getAssignedAchievementListFailure)
      )
    )
  );

export const addAssignedAchievementEpic = action$ => {
  return action$.pipe(
    ofType(ADD_ASSIGNED_ACHIEVEMENT),
    pluck('payload'),
    map(payload => {
      return payload.user.map(id => {
        return { ...payload, user: id };
      });
    }),
    mergeMap(users => {
      return forkJoin(
        users.map(user =>
          addAssignedAchievementAPI(user).pipe(
            catchRequestError(e => {
              return {
                Status: 'error',
                id: user.loginId,
                message: e.response.Message || e.message
              };
            })
          )
        )
      );
    }),
    mergeMap(e => {
      const errors = groupBy(v => v.Status)(e);
      if (errors['error'] && errors['error'].length > 0) {
        notification.error({
          message: 'Add Fail.',
          description: `${errors['error'].map(e => e.id).join('\n')}`
        });
        return [addAssignedAchievementFailure()];
      } else {
        return [addAssignedAchievementSuccess(e), getAssignedAchievementList()];
      }
    })
  );
};

export const modifyAssignedAchievementEpic = action$ => {
  return action$.pipe(
    ofType(MODIFY_ASSIGNED_ACHIEVEMENT),
    pluck('payload'),
    switchMap(payload =>
      setAssignedAchievementAPI(payload).pipe(
        mergeMap(e => [
          modifyAssignedAchievementSuccess(e),
          getAssignedAchievementList()
        ]),
        catchRequestError(modifyAssignedAchievementFailure)
      )
    )
  );
};

export const delectAssignedAchievementEpic = action$ =>
  action$.pipe(
    ofType(DELETE_ASSIGNED_ACHIEVEMENT),
    pluck('payload'),
    mergeMap(achiIds => {
      return forkJoin(
        achiIds.map(id =>
          deleteAssignedAchievementAPI(id).pipe(
            catchRequestError(e => {
              return {
                Status: 'error',
                id: id,
                message: e.response.Message || e.message
              };
            })
          )
        )
      );
    }),
    mergeMap(e => {
      const errors = groupBy(v => v.Status)(e);
      if (errors['error'] && errors['error'].length > 0) {
        notification.error({
          message: 'Delete Fail',
          description: `ID ${errors['error'].map(e => e.id).join(',')}`
        });
        return [deleteAssignedAchievementFailure()];
      } else {
        return [
          deleteAssignedAchievementSuccess(e),
          getAssignedAchievementList()
        ];
      }
    })
  );

export const getVisibleAssignedAchievements = (list = [], filter = '') => {
  const reg = RegExp(filter);
  return list.filter(achv => {
    return (
      reg.test(achv.achvName) ||
      reg.test(achv.loginId) ||
      reg.test(achv.userNickName)
    );
  });
};
/**
 * Reducer
 */
const initialState = {
  loading: false,
  isShowModal: false,
  assignedAchievementList: []
};

export default handleActions(
  {
    [combineActions(
      GET_ASSIGNED_ACHIEVEMENT_LIST,
      ADD_ASSIGNED_ACHIEVEMENT,
      DELETE_ASSIGNED_ACHIEVEMENT,
      MODIFY_ASSIGNED_ACHIEVEMENT
    )]: produce(draft => {
      draft.loading = true;
    }),
    [GET_ASSIGNED_ACHIEVEMENT_LIST_SUCCESS]: produce((draft, { payload }) => {
      draft.assignedAchievementList = payload.data.sort(function(a, b) {
        return b.id - a.id;
      });
      draft.loading = false;
    }),
    [combineActions(
      ADD_ASSIGNED_ACHIEVEMENT_SUCCESS,
      MODIFY_ASSIGNED_ACHIEVEMENT_SUCCESS
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [combineActions(
      GET_ASSIGNED_ACHIEVEMENT_LIST_FAILURE,
      ADD_ASSIGNED_ACHIEVEMENT_FAILURE,
      DELETE_ASSIGNED_ACHIEVEMENT_SUCCESS,
      MODIFY_ASSIGNED_ACHIEVEMENT_FAILURE,
      DELETE_ASSIGNED_ACHIEVEMENT_FAILURE
    )]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
