import React, { Component } from 'react';
import { countBy } from 'ramda';
import {
  Button,
  Input,
  Select,
  Modal,
  Popconfirm,
  message,
  InputNumber
} from 'antd';
import { Form } from '@ant-design/compatible';
import UploadImage from '../../components/UploadImage';

const FormItem = Form.Item;
const Option = Select.Option;

class AvatarForm extends Component {
  static defaultProps = {
    loading: false,
    isShowModal: false,
    avatars: [],
    events: [],
    showModal: v => console.log(v),
    addAvatar: v => console.log(v)
  };

  generateFormItem() {
    const { events } = this.props;

    return [
      {
        key: 'avatarName',
        label: '頭銜名稱',
        options: {
          rules: [{ required: true, message: '請輸入頭銜名稱' }]
        },
        render: () => <Input size="large" />
      },
      {
        key: 'point',
        label: '點數',
        options: {
          rules: [{ required: true, message: '請輸入點數' }]
        },
        render: () => <InputNumber size="large" min={1} />
      },
      {
        key: 'eventId',
        label: '頭銜活動類別',
        options: {
          rules: [{ required: true, message: '請選擇頭銜活動類別' }]
        },
        render: () => (
          <Select>
            {events.filter(el => el.type === 3).map(el => (
              <Option key={el} value={el.id + ''}>
                {el.eventName}
              </Option>
            ))}
          </Select>
        )
      },
      {
        key: 'imageAvatarFullHash',
        label: '訊息前 icon (有字)',
        options: {
          rules: [{ required: true, message: '請上傳訊息前 icon (有字)' }]
        },
        render: () => <UploadImage uploadUrl="/image/create/avatarImage" />
      },
      {
        key: 'imageAvatarShortHash',
        label: '訊息前 icon (無字)',
        options: {
          rules: [{ required: true, message: '請上傳訊息前 icon (無字)' }]
        },
        render: () => <UploadImage uploadUrl="/image/create/avatarImage" />
      },
      {
        key: 'imageDebutBasicHash',
        label: '進場特效',
        options: {
          rules: [{ required: true, message: '請上傳進場特效' }]
        },
        render: () => <UploadImage uploadUrl="/image/create/avatarImage" />
      },
      {
        key: 'imageDebutTransHash',
        label: '進場特效翻轉版',
        options: {
          rules: [{ required: true, message: '請上傳進場特效翻轉版' }]
        },
        render: () => <UploadImage uploadUrl="/image/create/avatarImage" />
      },
      {
        key: 'imagePhotoFrameHash',
        label: '上方頭像特殊頭匡',
        options: {
          rules: [{ required: true, message: '請上傳上方頭像特殊頭匡' }]
        },
        render: () => <UploadImage uploadUrl="/image/create/avatarImage" />
      },
      {
        key: 'imageBannerHash',
        label: '頭銜活動圖示',
        options: {
          rules: [{ required: true, message: '請上傳頭銜活動圖示' }]
        },
        render: () => <UploadImage uploadUrl="/image/create/avatarImage" />
      }
    ];
  }

  handleSubmit = e => {
    const { avatars, addAvatar } = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const eventCount = countBy(el => el.eventId, avatars);
      if (eventCount[values.eventId] >= 5) {
        message.error('一組頭銜分類最多只能有 5 個頭銜');
      } else if (!err) {
        addAvatar(values);
      }
    });
  };

  render() {
    const { loading, isShowModal, showModal } = this.props;
    const { getFieldDecorator, getFieldsError } = this.props.form;

    const hasErrors = fieldsError =>
      Object.keys(fieldsError).some(field => fieldsError[field]);

    return (
      <div>
        {isShowModal ? (
          <Modal
            visible={true}
            footer={null}
            closable={false}
            onCancel={() => showModal(false)}
          >
            <Form>
              {this.generateFormItem().map(item => (
                <FormItem key={item.key} label={item.label}>
                  {getFieldDecorator(item.key, item.options)(item.render())}
                </FormItem>
              ))}
            </Form>
            <Popconfirm
              title="是否確定新增"
              onConfirm={this.handleSubmit}
              placement="bottomLeft"
            >
              <Button loading={loading} disabled={hasErrors(getFieldsError())}>
                新增
              </Button>
            </Popconfirm>
            <Button onClick={() => showModal(false)}>取消</Button>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Form.create()(AvatarForm);
