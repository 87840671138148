import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipe } from 'ramda';
import { Button } from 'antd';

import AvatarTable from './AvatarTable';
import AvatarForm from './AvatarForm';

import {
  getAvatar,
  addAvatar,
  deleteAvatar,
  showModal
} from '../../reducers/avatar';
import { getEventCategory } from '../../reducers/eventCategory';

class EventCategoriesManagement extends Component {
  componentDidMount() {
    this.props.getAvatar();
    this.props.getEventCategory();
  }

  render() {
    const {
      loading,
      isShowModal,
      showModal,
      addAvatar,
      deleteAvatar,
      avatars,
      events
    } = this.props;

    return (
      <div>
        <h1>內容管理>頭銜管理</h1>
        <Button onClick={() => showModal(true)} disabled={loading}>
          新增頭銜
        </Button>

        <AvatarTable avatars={avatars} deleteAvatar={deleteAvatar} />

        <AvatarForm
          loading={loading}
          isShowModal={isShowModal}
          avatars={avatars}
          events={events}
          showModal={showModal}
          addAvatar={addAvatar}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.avatar.loading,
  isShowModal: state.avatar.isShowModal,
  avatars: state.avatar.avatars,
  events: state.eventCategory.events
});

const mapDispatchToProps = {
  getAvatar,
  addAvatar,
  deleteAvatar,
  showModal,
  getEventCategory
};

export default pipe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EventCategoriesManagement);
