import {
  assoc, complement, equals,
  head, isEmpty, map,
  toPairs, zipObj,
} from 'ramda';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import styled from 'styled-components';
import { Button, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {
  getAppConfig,
  setAppConfig,
  showModal,
} from '../../reducers/appConfigManagement';
import AppConfigData from './AppConfigData';
import { PageContainer, PageTitle } from '../../components/styled/page';
import AppConfigFormModal from './AppConfigFormModal';

const { Column } = Table;

const BreakWordContent = styled.p`
  margin: 0;
  word-break: break-all;
`;

const getTableData = (data, appName) =>
  map(app => assoc('value', data[app.key], app), appName);

const getSubmitData = compose(
  zipObj(['name', 'value']),
  head,
  toPairs,
);

class AppConfigManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      editData: undefined,
      editKey: undefined,
      editName: undefined,
      editType: undefined,
    };
  }

  componentDidMount() {
    this.props.getAppConfig();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (
      isEmpty(this.state.dataSource) ||
      (complement(isEmpty)(data) && complement(equals)(prevProps.data, data))
    ) {
      this.setState({
        dataSource: getTableData(data, AppConfigData),
      });
    }
  }

  onFormSubmit = (values) => {
    const { setAppConfig } = this.props;
    setAppConfig(getSubmitData(values));
  };

  onFormCancel = () => {
    this.props.showModal(false);
  };

  rednerBreakWordContent = (content) => {
    return (
      <BreakWordContent>{content}</BreakWordContent>
    );
  }

  renderActions = (rowData) => {
    const { name, key, type, value } = rowData;
    return (
      <Button
        shape="circle"
        onClick={() => {
          this.setState({
            editName: name,
            editKey: key,
            editType: type,
            editData: { [key]: value },
          }, this.props.showModal);
        }}
      >
        <EditOutlined />
      </Button>
    );
  }

  render() {
    const { isShowModal, loading } = this.props;
    const { dataSource, editName, editKey, editType, editData } = this.state;
    return (
      <PageContainer>
        <PageTitle>{'首頁管理 > AppConfig'}</PageTitle>
        <Table
          rowKey="key"
          loading={loading}
          dataSource={dataSource}
          pagination={false}
        >
          <Column
            title="控制項目"
            dataIndex="name"
          />
          <Column
            title="欄位 ID"
            dataIndex="key"
          />
          <Column
            title="設定值"
            dataIndex="value"
            render={this.rednerBreakWordContent}
          />
          <Column
            title="操作"
            render={this.renderActions}
          />
        </Table>
        <AppConfigFormModal
          visible={isShowModal}
          editName={editName}
          editKey={editKey}
          editType={editType}
          editData={editData}
          onCancel={this.onFormCancel}
          onOk={this.onFormSubmit}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => state.appConfigManagement;

const mapDispatchToProps = {
  showModal,
  getAppConfig,
  setAppConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppConfigManagement);
