import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { message, Avatar, Descriptions } from 'antd';
import { setSupportAPI } from '../../../apis';
import avatarStylesheet from '../../../constants/avatarStylesheet';
import { userLevel } from '../../../constants/roles';
import SupprtMedia from './Media';
import EnquiryContext from '../EnquiryContext';
import EnquiryModal from '../../../components/EnquiryModal';
import brandName from '../../../constants/brandName';

const { Item: DescriptionsItem } = Descriptions;
const avatarStyle = avatarStylesheet.oasis;

const UserWarrper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  .ant-avatar {
    margin-right: 0.75rem;
    flex-shrink: 0;
    user-select: none;
  }
`;

const UserInfo = styled.div`
  flex-grow: 1;
  overflow: hidden;

  p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledSupprtInfo = styled.div`
  table {
    table-layout: fixed !important;

    th {
      width: 8rem;
    }
  }

  .device-info {
    p {
      margin: 0;

      & + p {
        margin-top: 0.5rem;
      }
    }
  }
`;

const SupportStatus = styled.div`
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
`;

const EmptyField = styled.span`
  color: #bfbfbf;
`;

class SupportInfo extends React.Component {
  static contextType = EnquiryContext;

  static propTypes = {
    dataset: PropTypes.object.isRequired,
    onUpdateSupport: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.EnquiryModalRef = React.createRef();

    this.state = {};
  }

  transformUserLevel = level => {
    if (_.isEmpty(level)) return;

    const { levelId, levelNum } = level;
    const text = userLevel[levelId];
    return `${text} ${levelNum}`;
  };

  showEditModal = () => {
    const { dataset } = this.props;
    const { EnquiryModalRef } = this;
    const modal = EnquiryModalRef.current;
    modal.show(dataset);
  };

  updateSupport = values => {
    const { onUpdateSupport } = this.props;
    const observable = setSupportAPI(values);
    observable.subscribe(
      () => {
        message.success('狀態修改成功');
        if (onUpdateSupport) onUpdateSupport();
      },
      () => message.error('狀態修改失敗')
    );
  };

  renderCarouselMedia = files => {
    const isEmpty = _.isEmpty(files);
    return isEmpty ? (
      <EmptyField>無圖片/影片資料</EmptyField>
    ) : (
      <SupprtMedia files={files} />
    );
  };

  renderUserCard = () => {
    const { dataset } = this.props;
    const { client } = this.context;
    const nickname = _.get(dataset, 'user.nickname', '');
    const loginId = _.get(dataset, 'user.loginId');
    const profilePicture = _.get(dataset, 'user.profilePicture');
    const userLevel = _.get(client, 'userLevel');

    const avatarName = nickname.substring(0, 1);
    const displayLevel = this.transformUserLevel(userLevel);

    return (
      <UserWarrper>
        <Avatar size={80} src={profilePicture} style={avatarStyle}>
          {avatarName}
        </Avatar>
        <UserInfo>
          <p>暱稱：{nickname}</p>
          <p>
            {brandName} ID：{loginId}
          </p>
          <p>等級：{displayLevel}</p>
        </UserInfo>
      </UserWarrper>
    );
  };

  renderCategory = () => {
    const { dataset } = this.props;
    const { supportCategories } = this.context;
    const categoryID = _.get(dataset, 'category');
    const category = _.find(supportCategories, { id: categoryID });
    return _.get(category, 'label', '');
  };

  renderStatus = () => {
    const { dataset } = this.props;
    return (
      <SupportStatus onClick={this.showEditModal}>
        <span>{dataset.status}</span>
      </SupportStatus>
    );
  };

  render() {
    const { dataset } = this.props;
    return (
      <StyledSupprtInfo>
        {this.renderUserCard()}
        <Descriptions size="small" column={1} bordered>
          <DescriptionsItem label="分類">
            {this.renderCategory()}
          </DescriptionsItem>
          <DescriptionsItem label="標題">{dataset.title}</DescriptionsItem>
          <DescriptionsItem label="狀態">
            {this.renderStatus()}
          </DescriptionsItem>
          <DescriptionsItem label="詳細描述">
            {dataset.content}
          </DescriptionsItem>
          <DescriptionsItem className="device-info" label="手機裝置資訊">
            <p>
              手機作業系統：{dataset.platform} {dataset.OSVersion}
            </p>
            <p>手機型號：{dataset.deviceMarketingName}</p>
            <p>APP 版本號：{dataset.AppVersion}</p>
          </DescriptionsItem>
          <DescriptionsItem label="照片/影片">
            {this.renderCarouselMedia(dataset.files)}
          </DescriptionsItem>
        </Descriptions>
        <EnquiryModal
          ref={this.EnquiryModalRef}
          onConfirm={this.updateSupport}
        />
      </StyledSupprtInfo>
    );
  }
}

export default SupportInfo;
