import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as actions from './actions';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, Alert, Button } from 'antd';
import { AppHeader } from './components';
import {
  Home,
  SideMenu,
  NotFound,
  DAU,
  MAU,
  LiveRoom,
  VoiceChatTypeManagement,
  VoiceChatList,
  VoiceChatRoom,
  OffList,
  ReportedLive,
  ReportedPosts,
  GiftList,
  ManagerCompanyBankAccountSettings,
  AnchorManagement,
  TotalShareRate,
  BankAccountSettings,
  CommentManagement,
  LiveMasterAccountManagement,
  UserManagement,
  LiveApplicationReview,
  LiveProfitShareDocApplicationSetting,
  RegularSettlement,
  ManualSettlement,
  SystemAnnouncementPush,
  AnchorApplication,
  RegistrationStaffGrowthReport,
  PerformanceGrowthReport,
  LiveViewerStatistics,
  PaymentToolStatistics,
  AgencyManagement,
  FamilyDetail,
  FamilyManagement,
  CheckAuth,
  PhotoManagement,
  GiftReport,
  AdCollection,
  ExportUserData,
  AccountSheetExport,
  PointRatioManagement,
  EventCategoriesManagement,
  UserConsumeSheetExport,
  LiveMasterHoursSheetExport,
  LiveMasterNetworkSpeedSheetExport,
  PurchaseOrderList,
  GiftBoxManagement,
  AnimationEffectManagement,
  RewardManagement,
  ResetAuth,
  EnquiryAssistance,
  EnquiryManagement,
  MessageManagement,
  MessageRoomList,
  RemittanceManagement,
  RemittanceProductManagement,
  OverlayAdManagement,
  TabManagement,
  TagManagement,
  TagGroupManagement,
  SpotlightManagement,
  SeriesManagement,
  FlashNewsManagement,
  VideoManagement,
  PastEventManagement,
  AppConfigManagement,
  AppRankingManagement,
  AccountCreationManagement,
  AccountDeletionRequest,
  BlocklistManagement,
  VoiceChatRoomReport,
  VoiceChatUserManagement,
  EffectManagement,
  DispatchManagement,
  ForbiddenManagement,
  PrivateVoiceChatSpotlightManagement,
  DispatchGiftManagement,
  BottomTabbarManagement,
  ContractManagement,
  AgencyMasterManagement,
  FloatingSlotManagement,
  SigninEventManagement,
  QueryRankingList
} from './containers';

import {
  LiveMasterPersonal,
  DailyStatistics,
  DismantlingStatistics,
  FansGiftRanking
} from './containers/LiveMasterAccountManagement/containers';

import MessageDialog from './containers/MessageRoomList/MessageDialog';
import EventBanner from './containers/AdManagement/EventBanner';
import AdBanner from './containers/AdManagement/AdBanner';
import Personal from './containers/UserManagement/Personal';

import {
  USER_MANAGEMENT,
  PERSONAL,
  EXPORT_USER_DATA,
  ACCOUNT_SHEET_EXPORT,
  POINT_RATIO_MANAGEMENT,
  EVENT_CATEGORIES_MANAGEMENT,
  USER_CONSUME_SHEET_EXPORT,
  LIVE_MASTER_HOURS_SHEET_EXPORT,
  PURCHASE_ORDER_LIST,
  LIVE_ROOM,
  LIVE_MASTER_NETWORK_SPEED_SHEET_EXPORT,
  INTERACTIVE_GIFTBOX_MANAGEMENT,
  ANIMATION_EFFECT_MANAGEMENT,
  REWARD_MANAGEMENT,
  RESET_AUTH,
  ENQUIRY_ASSISTANCE,
  ENQUIRY_MANAGEMENT,
  GIFT_LIST,
  OVERLAY_AD_MANAGEMENT,
  TAB_MANAGEMENT,
  TAG_MANAGEMENT,
  MESSAGE_MANAGEMENT,
  MESSAGE_ROOM_LIST,
  DIALOG,
  FORBIDDEN_MANAGEMENT,
  VOICE_CHAT_TYPE_MANAGEMENT,
  VOICE_CHAT_LIST,
  VOICE_CHAT_ROOM,
  REMITTANCE_MANAGEMENT,
  REMITTANCE_PRODUCT_MANAGEMENT,
  SPOTLIGHT_MANAGEMENT,
  FLASH_NEWS_MANAGEMENT,
  SERIES_MANAGEMENT,
  VIDEO_MANAGEMENT,
  APP_CONFIG_MANAGEMENT,
  TAG_GROUP_MANAGEMENT,
  APP_RANKING_MANAGEMENT,
  ACCOUNT_CREATION_MANAGEMENT,
  ACCOUNT_DELETION_REQUEST,
  BLOCKLIST_MANAGEMENT,
  VOICE_CHAT_ROOM_REPORT,
  VOICE_CHAT_KICKOUT_USER,
  EFFECT_MANAGEMENT,
  DISPATCH_MANAGEMENT,
  CONTRACT_MANAGEMENT,
  AGENCY_MANAGEMENT,
  FAMILY_MANAGEMENT,
  FAMILY_DETAIL,
  PRIVATEVOICECHAT_SPOTLIGHT_MANAGEMENT,
  DISPATCH_GIFT_MANAGEMENT,
  BOTTOM_TABBAR_MANAGEMENT,
  FLOATING_SLOT_MANAGEMENT,
  SIGNIN_EVENT_MANAGEMENT,
  QUERY_RANKING_LIST
} from './constants/routes';

import { history } from './store';
import { PAST_EVENT_MANAGEMENT } from './constants/routes';
import { isNotNil, isNotEmpty } from './utils/mixin';
import { find, pathOr, pipe, test, toPairs } from 'ramda';
const hasRight = (accountRightObj, path) =>
  pipe(
    toPairs,
    find(value => test(new RegExp('^' + path, 'g'), value[0])),
    pathOr(false, [1])
  )(accountRightObj);
const { Sider, Content } = Layout;

const Wrapper = styled(Layout)`
  width: 100%;
  height: 100%;
`;

const StyledSider = styled(Sider)`
  min-width: 14rem !important;
  max-width: 14rem !important;
  height: 100%;
  position: fixed;
  left: 0;
  background-color: white;
  overflow: auto;
`;

const StyledRightLayout = styled(Layout)`
  height: 100%;
  padding-left: 14rem;
`;
const StyledContent = styled(Content)`
  padding-left: 16px;
  overflow-y: auto;
`;

const mapStateToProps = state => ({
  isServerError: state.views.isServerError,
  hasUserInfo: state.auth.hasUserInfo,
  loginId: state.auth.loginId,
  accountRightObj: state.auth.accountRightObj,
  router: state.router
});

const mapDispatchToProps = {
  logout: actions.logout
};

@CheckAuth
@connect(mapStateToProps, mapDispatchToProps)
class App extends Component {
  static propTypes = {
    isServerError: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    hasUserInfo: PropTypes.bool.isRequired
  };

  renderContent = accountRightObj => {
    const subPath = this.props.router.location.pathname.split('/')[1];
    var isBlock = false;
    if (
      isNotNil(accountRightObj) &&
      isNotEmpty(accountRightObj) &&
      isNotEmpty(subPath)
    ) {
      const result = hasRight(accountRightObj, subPath);
      isBlock = !result;
    }

    return (
      <StyledContent>
        <Switch>
          {isBlock ? <Route component={NotFound} /> : null}
          <Route
            path="/redirect/:target*"
            render={props => <Redirect to={`/${props.match.params.target}`} />}
          />
          <Route exact path="/" component={Home} />
          <Route path="/gift-report" component={GiftReport} />
          <Route path="/dau" component={DAU} />
          <Route path="/mau" component={MAU} />
          <Route path="/photo-management" component={PhotoManagement} />
          <Route path={`${LIVE_ROOM}/:id`} component={LiveRoom} />
          <Route
            path={VOICE_CHAT_TYPE_MANAGEMENT}
            component={VoiceChatTypeManagement}
          />
          <Route path={VOICE_CHAT_LIST} component={VoiceChatList} />
          <Route path={`${VOICE_CHAT_ROOM}/:id`} component={VoiceChatRoom} />
          <Route path="/off-list" component={OffList} />
          <Route path="/reported-live" component={ReportedLive} />
          <Route path="/reported-posts" component={ReportedPosts} />
          <Route
            path="/manager-company-bank-account-settings"
            component={ManagerCompanyBankAccountSettings}
          />
          <Route exact path={GIFT_LIST}>
            <Redirect to={`${GIFT_LIST}/liveRoom`} />
          </Route>
          <Route path={`${GIFT_LIST}/:type`} component={GiftList} />
          <Route
            path="/anchor-management/personal/:id"
            component={LiveMasterPersonal}
          />
          <Route
            path="/anchor-management/dismantling-statistics/:id"
            component={DismantlingStatistics}
          />
          <Route
            path="/anchor-management/daily-statistics-information/:id"
            component={DailyStatistics}
          />
          <Route
            path="/anchor-management/fans-gift-ranking/:id"
            component={FansGiftRanking}
          />
          <Route path="/anchor-management" component={AnchorManagement} />
          <Route path="/total-share-rate" component={TotalShareRate} />
          <Route
            path="/bank-account-settings"
            component={BankAccountSettings}
          />
          <Route path="/fans-gift-ranking/:id" component={FansGiftRanking} />
          <Route path="/comment-management" component={CommentManagement} />
          <Route path="/personal/:id" component={LiveMasterPersonal} />
          <Route
            path="/dismantling-statistics/:id"
            component={DismantlingStatistics}
          />
          <Route
            path="/live-master-account-management/personal/:id"
            component={LiveMasterPersonal}
          />
          <Route
            path="/live-master-account-management/daily-statistics-information/:id"
            component={DailyStatistics}
          />
          <Route
            path="/live-master-account-management/dismantling-statistics/:id"
            component={DismantlingStatistics}
          />
          <Route
            path="/live-master-account-management/fans-gift-ranking/:id"
            component={FansGiftRanking}
          />
          <Route
            path="/live-master-account-management"
            component={LiveMasterAccountManagement}
          />
          <Route
            path={`${USER_MANAGEMENT}${PERSONAL}/:id`}
            component={Personal}
          />
          <Route path="/user-management" component={UserManagement} />
          <Route
            path="/live-application-review"
            component={LiveApplicationReview}
          />
          <Route
            path="/live-profit-share-doc-application-setting"
            component={LiveProfitShareDocApplicationSetting}
          />
          <Route path="/statistics-info/:id" component={DailyStatistics} />
          <Route path="/regular-settlement" component={RegularSettlement} />
          <Route path="/manual-settlement" component={ManualSettlement} />
          <Route
            path="/system-announcement-push"
            component={SystemAnnouncementPush}
          />
          <Route path="/ad-management/:zone/:type?" component={AdCollection} />
          <Route path={SPOTLIGHT_MANAGEMENT} component={SpotlightManagement} />
          <Route path="/event-banner-management" component={EventBanner} />
          <Route path="/ad-banner-management" component={AdBanner} />
          <Route path={OVERLAY_AD_MANAGEMENT} component={OverlayAdManagement} />
          <Route path="/anchor-application" component={AnchorApplication} />
          <Route
            path="/registration-staff-growth-report"
            component={RegistrationStaffGrowthReport}
          />
          <Route
            path="/performance-growth-report"
            component={PerformanceGrowthReport}
          />
          <Route
            path="/live-viewer-statistics"
            component={LiveViewerStatistics}
          />
          <Route
            path="/payment-tool-statistics"
            component={PaymentToolStatistics}
          />
          <Route
            path={`${AGENCY_MANAGEMENT}/:id`}
            component={AgencyMasterManagement}
          />
          <Route
            path={FAMILY_MANAGEMENT}
            component={FamilyManagement}
          />
          <Route
            path={`${FAMILY_DETAIL}/:id`}
            component={FamilyDetail}
          />
          <Route path={AGENCY_MANAGEMENT} component={AgencyManagement} />
          <Route path={EXPORT_USER_DATA} component={ExportUserData} />
          <Route path={ACCOUNT_SHEET_EXPORT} component={AccountSheetExport} />
          <Route
            path={POINT_RATIO_MANAGEMENT}
            component={PointRatioManagement}
          />
          <Route
            path={EVENT_CATEGORIES_MANAGEMENT}
            component={EventCategoriesManagement}
          />
          <Route
            path={USER_CONSUME_SHEET_EXPORT}
            component={UserConsumeSheetExport}
          />
          <Route
            path={LIVE_MASTER_HOURS_SHEET_EXPORT}
            component={LiveMasterHoursSheetExport}
          />
          <Route
            path={LIVE_MASTER_NETWORK_SPEED_SHEET_EXPORT}
            component={LiveMasterNetworkSpeedSheetExport}
          />
          <Route path={PURCHASE_ORDER_LIST} component={PurchaseOrderList} />
          <Route
            path={INTERACTIVE_GIFTBOX_MANAGEMENT}
            component={GiftBoxManagement}
          />
          <Route
            path={ANIMATION_EFFECT_MANAGEMENT}
            component={AnimationEffectManagement}
          />
          <Route path={REWARD_MANAGEMENT} component={RewardManagement} />
          <Route path={RESET_AUTH} component={ResetAuth} />
          <Route path={ENQUIRY_MANAGEMENT} component={EnquiryManagement} />
          <Route
            path={`${ENQUIRY_ASSISTANCE}/:id`}
            component={EnquiryAssistance}
          />
          <Route path={MESSAGE_MANAGEMENT} component={MessageManagement} />
          <Route path={MESSAGE_ROOM_LIST} exact component={MessageRoomList} />
          <Route path={`${DIALOG}/:id`} component={MessageDialog} />
          <Route
            path={REMITTANCE_MANAGEMENT}
            component={RemittanceManagement}
          />
          <Route
            path={REMITTANCE_PRODUCT_MANAGEMENT}
            component={RemittanceProductManagement}
          />
          <Route path={TAB_MANAGEMENT} component={TabManagement} />
          <Route path={TAG_MANAGEMENT} component={TagManagement} />
          <Route path={TAG_GROUP_MANAGEMENT} component={TagGroupManagement} />
          <Route path={SERIES_MANAGEMENT} component={SeriesManagement} />
          <Route path={FLASH_NEWS_MANAGEMENT} component={FlashNewsManagement} />
          <Route path={VIDEO_MANAGEMENT} component={VideoManagement} />
          <Route path={APP_CONFIG_MANAGEMENT} component={AppConfigManagement} />
          <Route
            path={APP_RANKING_MANAGEMENT}
            component={AppRankingManagement}
          />
          <Route path={FORBIDDEN_MANAGEMENT} component={ForbiddenManagement} />
          <Route path={PAST_EVENT_MANAGEMENT} component={PastEventManagement} />
          <Route
            path={ACCOUNT_CREATION_MANAGEMENT}
            component={AccountCreationManagement}
          />
          <Route
            path={ACCOUNT_DELETION_REQUEST}
            component={AccountDeletionRequest}
          />
          <Route path={BLOCKLIST_MANAGEMENT} component={BlocklistManagement} />
          <Route
            path={VOICE_CHAT_ROOM_REPORT}
            component={VoiceChatRoomReport}
          />
          <Route
            path={VOICE_CHAT_KICKOUT_USER}
            component={VoiceChatUserManagement}
          />
          <Route path={EFFECT_MANAGEMENT} component={EffectManagement} />
          <Route path={DISPATCH_MANAGEMENT} component={DispatchManagement} />
          <Route path={CONTRACT_MANAGEMENT} component={ContractManagement} />
          <Route
            path={PRIVATEVOICECHAT_SPOTLIGHT_MANAGEMENT}
            component={PrivateVoiceChatSpotlightManagement}
          />
          <Route
            path={DISPATCH_GIFT_MANAGEMENT}
            component={DispatchGiftManagement}
          />
          <Route
            path={BOTTOM_TABBAR_MANAGEMENT}
            component={BottomTabbarManagement}
          />
          <Route
            path={FLOATING_SLOT_MANAGEMENT}
            component={FloatingSlotManagement}
          />
          <Route
            path={SIGNIN_EVENT_MANAGEMENT}
            component={SigninEventManagement}
          />
          <Route path={QUERY_RANKING_LIST} component={QueryRankingList} />
          <Route component={NotFound} />
        </Switch>
      </StyledContent>
    );
  };

  render() {
    const { isServerError, logout, hasUserInfo, accountRightObj } = this.props;

    return (
      <ConnectedRouter history={history}>
        <Wrapper>
          <StyledSider>
            <SideMenu />
          </StyledSider>
          <StyledRightLayout>
            {isServerError && (
              <Alert
                banner
                type="error"
                message={
                  <div>
                    登入過期或伺服器錯誤，請
                    <Button
                      type="link"
                      onClick={() => logout()}
                      style={{ padding: 0, height: 'auto' }}
                    >
                      重新登入
                    </Button>
                    。
                  </div>
                }
              />
            )}
            <AppHeader />
            {hasUserInfo && isNotNil(accountRightObj)
              ? this.renderContent(accountRightObj)
              : null}
          </StyledRightLayout>
        </Wrapper>
      </ConnectedRouter>
    );
  }
}

export default App;
