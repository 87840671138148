import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Space,
  Spin
} from 'antd';
import { drop } from 'ramda';
import React, { useEffect } from 'react';
import FileUpload from '../../components/FileUpload';
import { type } from '../../constants/contract';
import { useHasErrors } from '../../hooks/useHasErrors';
import { isReallyEmpty } from '../../utils/mixin';
const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;
const availableDaysOption = [15, 30, 90, 365];
const contractType = drop(1, type);
const ContractFormModal = ({
  onOk,
  onCancel,
  editData,
  visible,
  loading,
  isCreateMode
}) => {
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();

  useEffect(() => {
    if (visible && !isReallyEmpty(editData) && !isCreateMode) {
      form.setFieldsValue(editData);
    }
  }, [form, editData, isCreateMode, visible]);

  const onFinish = values => {
    onOk(values);
  };

  const onAfterClose = () => {
    form.resetFields();
    setHasError(false);
  };

  return (
    <Modal
      width={550}
      title={`${isCreateMode ? '新增' : '編輯'} 分潤方案`}
      visible={visible}
      onCancel={onCancel}
      afterClose={onAfterClose}
      footer={
        <Space>
          <Popconfirm
            key="confirm"
            title={`是否確定${isCreateMode ? '新增' : '儲存'} `}
            placement="topLeft"
            onConfirm={() => form.submit()}
          >
            <Button
              key={'add'}
              loading={loading}
              type="primary"
              disabled={hasErrors}
            >
              確定
            </Button>
          </Popconfirm>
          <Button key="cancle" onClick={onCancel}>
            取消
          </Button>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onFieldsChange={validateStatus}>
          <FormItem
            label="名稱"
            name="name"
            rules={[
              {
                required: true,
                message: '請輸入名稱'
              }
            ]}
          >
            <Input placeholder="請輸入分潤方案名稱" />
          </FormItem>
          <FormItem
            label="簡述"
            name="description"
            rules={[
              {
                required: true,
                message: '請輸入方案簡述'
              }
            ]}
          >
            <TextArea placeholder="請輸入分潤方案簡述" />
          </FormItem>
          <FormItem
            labelCol={{ span: 24 }}
            label="合約類型"
            name="type"
            rules={[
              {
                required: true,
                message: '請輸入名稱'
              }
            ]}
          >
            <Radio.Group disabled={!isCreateMode} style={{ display: 'flex' }}>
              {contractType.map(item => (
                <Radio key={item.id} value={item.id}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </FormItem>
          <FormItem
            label="合約有效期間"
            name="availableDays"
            rules={[
              {
                required: true,
                message: '請選擇天數'
              }
            ]}
          >
            <Select
              disabled={!isCreateMode}
              style={{ width: 120 }}
              placeholder="請選擇天數"
            >
              {availableDaysOption.map(value => (
                <Option value={value} key={value}>
                  {value} 天
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="合約檔案" name="fileUrl">
            <FileUpload uploadUrl="/cdn/contract/file" />
          </FormItem>
          {!isCreateMode && (
            <FormItem key="id" hidden={true} name="id">
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default ContractFormModal;
