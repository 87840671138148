import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Select, DatePicker, Button } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { getRegistrationStaffList } from '../reducers/registrationStaffGrowth';
import { dateOptions } from '../constants/moments';
import downloadCSV from '../utils/downloadCSV';

const { RangePicker } = DatePicker;
const Option = Select.Option;

const Tools = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

class RegistrationStaffGrowthReport extends Component {
  componentDidMount() {
    this.props.getRegistrationStaffList(dateOptions.week);
  }

  onChange = value => {
    const { getRegistrationStaffList, startDate, endDate } = this.props;
    if (value !== 'customize') {
      getRegistrationStaffList(+value);
    } else {
      getRegistrationStaffList(startDate, endDate, true);
    }
  };

  onDatePickChange = date => {
    this.props.getRegistrationStaffList(
      date[0].valueOf(),
      date[1].valueOf(),
      true
    );
  };

  downloadCSVFromJson = () => {
    const { dataSource } = this.props;
    downloadCSV(dataSource, ['dateStr', 'counts'], ['時間', '會員數量']);
  };

  render() {
    const { dataSource, startDate, endDate, isCustomize } = this.props;
    return (
      <div>
        <h1>{'經營管理 > 註冊會員成長報表'}</h1>
        <Tools>
          <Select
            defaultValue={dateOptions.week}
            value={isCustomize ? 'customize' : startDate}
            style={{ width: '8rem' }}
            onChange={this.onChange}>
            <Option value={dateOptions.week}>過去一週</Option>
            <Option value={dateOptions.month}>過去一個月</Option>
            <Option value={dateOptions.quart}>過去一季</Option>
            <Option value={dateOptions.half}>過去六個月</Option>
            <Option value={dateOptions.year}>過去一年</Option>
            <Option value="customize">自訂</Option>
          </Select>
          {isCustomize ? (
            <RangePicker
              style={{ width: 400, margin: '0 10px' }}
              format="YYYY/MM/DD HH:mm:ss"
              showTime={true}
              value={[moment(+startDate), moment(+endDate)]}
              onChange={this.onDatePickChange}
              allowClear={false}
            />
          ) : null}
          <Button type="primary" onClick={this.downloadCSVFromJson}>
            匯出報表
          </Button>
        </Tools>
        {dataSource.length && (
          <BarChart
            width={1000}
            height={500}
            data={dataSource}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="dateStr" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Bar name="會員數量" dataKey="counts" fill="#82ca9d" />
          </BarChart>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    dataSource: state.registrationStaffGrowth.data,
    startDate: String(state.registrationStaffGrowth.startDate, 10),
    endDate: String(state.registrationStaffGrowth.endDate, 10),
    isCustomize: state.registrationStaffGrowth.isCustomize
  }),
  {
    getRegistrationStaffList
  }
)(RegistrationStaffGrowthReport);
