import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ArrowUpOutlined, GiftOutlined } from '@ant-design/icons';
import styled from 'styled-components';

class ChatActions extends React.Component {

  static propTypes = {
    onSendMessage: PropTypes.func,
    onGiftBtnClick: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
    };
  }

  onMessageInput = (event) => {
    const value = event.target.value;
    const message = _.trim(value);
    this.setState({ message });
  }

  onSendMessage = (event) => {
    event.preventDefault();

    const { inputRef } = this;
    const inputEl = inputRef.current;
    const message = _.trim(inputEl.value);

    if (message.length <= 0) return;

    const { onSendMessage } = this.props;
    onSendMessage(message);
    inputEl.value = null;
  }

  onGiftBtnClick = () => {
    const { onGiftBtnClick } = this.props;
    onGiftBtnClick();
  }

  render() {
    const { inputRef } = this;
    const { className } = this.props;

    return (
      <form
        className={className}
        onSubmit={this.onSendMessage}
      >
        <input
          ref={inputRef}
          type="text"
          placeholder="輸入訊息"
        />
        <Button
          className="send-message ant-btn-icon-only"
          type="primary"
          htmlType="submit"
        >
          <ArrowUpOutlined />
        </Button>
        <Button
          className="send-gift ant-btn-icon-only"
          type="danger"
          onClick={this.onGiftBtnClick}
        >
          <GiftOutlined />
        </Button>
      </form>
    );
  }
}

const StyledChatActions = styled(ChatActions)`
  display: flex;
  padding: 0.5rem;

  input {
    padding: 0 1rem;
    flex-grow: 1;
    border: 0;
    border-radius: 1rem;
    background: #F0F0F0;
    line-height: 2.5;
    outline: 0;
    box-shadow: none !important;

    &::placeholder {
      color: #AAA;
    }
  }

  button {
    margin-left: 0.5rem;
    flex-shrink: 0;
  }

  .send-message {
    border-radius: 50%;
  }
`;

export default StyledChatActions;
