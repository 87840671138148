import {
  ExclamationCircleOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tooltip
} from 'antd';
import { indexBy, prop } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableContainer from '../../components/TableContainer';
import { status, type } from '../../constants/contract';
import { AGENCY_MANAGEMENT } from '../../constants/routes';
import {
  addContract,
  deleteContract,
  getContract,
  getContractList,
  setContract,
  showModal
} from '../../reducers/contractManagement';
import ContractFormModal from './ContractFormModal';

const { Search } = Input;
const Option = Select.Option;
const FormItem = Form.Item;

const typeIndexById = indexBy(prop('id'), type);
const columns = [
  { title: '方案名稱', dataIndex: 'name' },
  {
    title: '方案概述',
    dataIndex: 'description'
  },
  {
    title: '合約類型',
    dataIndex: 'type',
    render: value => typeIndexById[value]?.label
  },
  {
    title: '合約有效期間',
    dataIndex: 'availableDays',
    width: 118,
    render: value => `${value} 天`
  },
  {
    title: '合約檔案',
    dataIndex: 'fileUrl',
    width: 90,
    render: value =>
      value && (
        <a href={value} target="_blank" rel="noreferrer">
          <PaperClipOutlined style={{ fontSize: '18px', padding: '4px' }} />
        </a>
      )
  }
];
const mapState = ({ contractManagement }) => ({
  ...contractManagement
});
const mapDispatch = {
  getContractList,
  addContract,
  getContract,
  setContract,
  deleteContract,
  showModal
};

class ContractManagement extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      keyword: '',
      statusFilter: status[1].id,
      contractTypeFilter: type[0].id,
      page: 1,
      isCreateMode: false,
      selectedRowKeys: []
    };
  }

  componentDidMount() {
    this.getContractList();
  }

  /**
   * 處理 Table 勾選
   */
  onSelectRowChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    });
  };

  /**
   * 點選分頁
   */
  onPageChange = page => {
    this.getContractList(this.state.filterText, page);
  };

  /**
   * 新增
   */
  onAddClick = () => {
    this.setState({ isCreateMode: true });
    this.props.showModal();
  };

  /**
   * 編輯
   */
  onEditClick = () => {
    const { getContract, showModal } = this.props;
    const { selectedRowKeys } = this.state;
    this.setState({
      isCreateMode: false
    });
    getContract(selectedRowKeys[0]);
    showModal();
  };

  /**
   * 刪除
   */
  onDelectClick = () => {
    const { deleteContract } = this.props;
    const { selectedRowKeys } = this.state;
    const { keyword, statusFilter, contractTypeFilter, page } = this.state;
    Modal.confirm({
      title: '確定要封存該分潤方案？',
      icon: <ExclamationCircleOutlined />,
      content: '封存方案後，你將且無法編輯且經紀公司將無法使用該分潤方案',
      onOk: () => {
        deleteContract(
          selectedRowKeys,
          getContractList({
            keyword,
            contractStatusFilter: statusFilter,
            contractTypeFilter,
            page
          })
        );
        this.setState({ selectedRowKeys: [] });
      }
    });
  };

  /**
   * 取得分潤方案列表
   */
  getContractList = (keyword = '', page = 1) => {
    const { statusFilter, contractTypeFilter } = this.state;
    this.setState({ page });
    this.props.getContractList({
      keyword,
      contractStatusFilter: statusFilter,
      contractTypeFilter,
      page
    });
  };

  /**
   * 是否去經紀公司
   */
  gotoAgency = () => {
    Modal.confirm({
      title: '是否要新增經紀公司資訊？',
      content: '至經紀公司管理頁面，新增及管理經紀公司資料，並檢視直播主資訊',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        this.props.history.push(AGENCY_MANAGEMENT);
      }
    });
  };

  /**
   * 送出表單
   */
  onFormSubmit = values => {
    const { addContract, setContract } = this.props;
    const { isCreateMode, keyword, page, statusFilter, contractTypeFilter } =
      this.state;
    if (isCreateMode) {
      addContract(
        values,
        getContractList({
          keyword: '',
          statusFilter: status[1].id,
          contractTypeFilter,
          page: 1
        }),
        this.gotoAgency
      );
      this.setState({
        keyword: '',
        statusFilter: status[1].id,
        contractTypeFilter: type[0].id,
        page: 1
      });
    } else {
      setContract(
        values,
        getContractList({
          keyword,
          contractStatusFilter: statusFilter,
          contractTypeFilter,
          page
        })
      );
      this.setState({ selectedRowKeys: [] });
    }
  };

  /**
   * 關閉表單
   */
  onFormCancel = () => {
    this.props.showModal(false);
  };

  render() {
    const { loading, isShowModal, data, totalCount, currentContract } =
      this.props;
    const {
      selectedRowKeys,
      isCreateMode,
      page,
      keyword,
      statusFilter,
      contractTypeFilter
    } = this.state;

    return (
      <>
        <h1>{'經營管理 > 分潤方案管理'}</h1>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Space wrap>
            <FormItem style={{ marginBottom: '8px' }}>
              <Search
                allowClear={true}
                value={keyword}
                style={{ maxWidth: '320px' }}
                placeholder="請輸入分潤方案名稱"
                onChange={event =>
                  this.setState({ keyword: event.target.value })
                }
                onSearch={value => this.getContractList(value, 1)}
                enterButton
              />
            </FormItem>
            <FormItem label="方案狀態" style={{ marginBottom: '8px' }}>
              <Select
                value={statusFilter}
                onChange={value =>
                  this.setState({ statusFilter: value }, () =>
                    this.getContractList(keyword, 1)
                  )
                }
                style={{ minWidth: 120 }}
              >
                {status.map(status => (
                  <Option key={status.id} title={status.label}>
                    {status.label}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem label="合約類型" style={{ marginBottom: '8px' }}>
              <Select
                value={contractTypeFilter}
                onChange={value =>
                  this.setState({ contractTypeFilter: value }, () => {
                    this.getContractList(keyword, 1);
                  })
                }
                style={{ minWidth: 128 }}
              >
                {type.map(value => (
                  <Option key={value.id} title={value.label}>
                    {value.label}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Space>
        </div>
        <Space style={{ marginBottom: '8px' }}>
          <Button onClick={this.onAddClick}>新增</Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            onClick={this.onEditClick}
          >
            編輯
          </Button>
          <Button
            onClick={this.onDelectClick}
            type="danger"
            disabled={selectedRowKeys.length === 0}
          >
            封存
          </Button>
        </Space>
        <TableContainer>
          <Table
            rowKey="contractId"
            scroll={{ x: true }}
            loading={loading}
            rowSelection={{
              selectedRowKeys,
              renderCell(checked, record, index, node) {
                if (record?.contractStatus === status[2].id) {
                  return <Tooltip title="方案已被封存">{node}</Tooltip>;
                }
                return node;
              },
              getCheckboxProps: record => {
                return {
                  disabled: record?.contractStatus === status[2].id
                };
              },
              onChange: this.onSelectRowChange
            }}
            pagination={{
              pageSize: 20,
              total: totalCount,
              current: page,
              showSizeChanger: false,
              onChange: this.onPageChange
            }}
            columns={columns}
            dataSource={data}
          ></Table>
        </TableContainer>

        <ContractFormModal
          isCreateMode={isCreateMode}
          visible={isShowModal}
          loading={loading}
          editData={isCreateMode ? null : currentContract}
          onOk={this.onFormSubmit}
          onCancel={this.onFormCancel}
        />
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ContractManagement);
