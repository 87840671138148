import styled from 'styled-components';

const TableContainer = styled.div`
  padding-right: 16px;
  .ant-table-wrapper {
    border-radius: 4px;
  }
  .ant-pagination {
    margin-right: 16px;
  }
`;
export default TableContainer;
