import { message } from 'antd';
import produce from 'immer';
import { groupBy, is, pathOr } from 'ramda';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import {
  addSigninEventAPI,
  deleteSigninEventAPI,
  getContinuityEventListAPI,
  getSigninEventAPI,
  getSigninEventListAPI,
  setSigninEventAPI
} from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';
export const defaultQuery = { page: 1, item: 20 };
/**Type */
const GET_CONTINUITY_EVENT_LIST = createRequestTypes(
  'GET_CONTINUITY_EVENT_LIST'
);
const GET_SIGNIN_EVENT_LIST = createRequestTypes('GET_SIGNIN_EVENT_LIST');
const ADD_SIGNIN_EVENT = createRequestTypes('ADD_SIGNIN_EVENT');
const GET_SIGNIN_EVENT = createRequestTypes('GET_SIGNIN_EVENT');
const SET_SIGNIN_EVENT = createRequestTypes('SET_SIGNIN_EVENT');
const DELETE_SIGNIN_EVENT = createRequestTypes('DELETE_SIGNIN_EVENT');
const SHOW_SIGNIN_EVENT_MODAL = 'SHOW_SIGNIN_EVENT_MODAL';

/**
 * Action Creator
 */
export const getSigninEventList = createAction(
  GET_SIGNIN_EVENT_LIST.REQUEST,
  (payload = {}) => ({ ...defaultQuery, ...payload })
);
export const getSigninEventListSuccess = createAction(
  GET_SIGNIN_EVENT_LIST.SUCCESS
);
export const getSigninEventListFailure = createAction(
  GET_SIGNIN_EVENT_LIST.FAILURE
);
export const showModal = createAction(
  SHOW_SIGNIN_EVENT_MODAL,
  (isShowModal = true) => isShowModal
);
export const addSigninEvent = createAction(
  ADD_SIGNIN_EVENT.REQUEST,
  (payload, callbackAction, callback) => ({
    payload,
    callbackAction,
    callback
  })
);
export const addSigninEventSuccess = createAction(ADD_SIGNIN_EVENT.SUCCESS);
export const addSigninEventFailure = createAction(ADD_SIGNIN_EVENT.FAILURE);
export const getSigninEvent = createAction(GET_SIGNIN_EVENT.REQUEST);
export const getSigninEventSuccess = createAction(GET_SIGNIN_EVENT.SUCCESS);
export const getSigninEventFailure = createAction(GET_SIGNIN_EVENT.FAILURE);
export const setSigninEvent = createAction(
  SET_SIGNIN_EVENT.REQUEST,
  (payload, callbackAction) => ({
    payload,
    callbackAction
  })
);
export const setSigninEventSuccess = createAction(SET_SIGNIN_EVENT.SUCCESS);
export const setSigninEventFailure = createAction(SET_SIGNIN_EVENT.FAILURE);
export const deleteSigninEvent = createAction(
  DELETE_SIGNIN_EVENT.REQUEST,
  (ids, callbackAction) => ({ ids, callbackAction })
);
export const deleteSigninEventSuccess = createAction(
  DELETE_SIGNIN_EVENT.SUCCESS
);
export const deleteSigninEventFailure = createAction(
  DELETE_SIGNIN_EVENT.FAILURE
);
export const getContinuityEventList = createAction(
  GET_CONTINUITY_EVENT_LIST.REQUEST
);
export const getContinuityEventListSuccess = createAction(
  GET_CONTINUITY_EVENT_LIST.SUCCESS
);
export const getContinuityEventListFailure = createAction(
  GET_CONTINUITY_EVENT_LIST.FAILURE
);
/**
 * Epics
 */
export const getSigninEventListEpic = action$ =>
  action$.pipe(
    ofType(GET_SIGNIN_EVENT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getSigninEventListAPI(payload).pipe(
        map(getSigninEventListSuccess),
        catchRequestError(getSigninEventListFailure)
      )
    )
  );

export const addSigninEventEpic = action$ =>
  action$.pipe(
    ofType(ADD_SIGNIN_EVENT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction, callback }) =>
      addSigninEventAPI(payload).pipe(
        mergeMap(res => {
          if (is(Function, callback)) {
            callback();
          }
          return callbackAction
            ? [addSigninEventSuccess(res), callbackAction]
            : [addSigninEventSuccess(res)];
        }),
        catchRequestError(addSigninEventFailure)
      )
    )
  );

export const getSigninEventEpic = action$ =>
  action$.pipe(
    ofType(GET_SIGNIN_EVENT.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getSigninEventAPI(payload).pipe(
        map(getSigninEventSuccess),
        catchRequestError(getSigninEventFailure)
      )
    )
  );

export const setSignInMissionEpic = action$ =>
  action$.pipe(
    ofType(SET_SIGNIN_EVENT.REQUEST),
    pluck('payload'),
    switchMap(({ payload, callbackAction }) =>
      setSigninEventAPI(payload).pipe(
        mergeMap(res =>
          callbackAction
            ? [setSigninEventSuccess(res), callbackAction]
            : [setSigninEventSuccess(res)]
        ),
        catchRequestError(setSigninEventFailure)
      )
    )
  );

export const deleteSigninEventEpic = action$ =>
  action$.pipe(
    ofType(DELETE_SIGNIN_EVENT.REQUEST),
    pluck('payload'),
    switchMap(({ ids, callbackAction }) => {
      return forkJoin(
        ids.map(id =>
          deleteSigninEventAPI(id).pipe(
            catchError(error => {
              return of({
                Status: 'Error',
                Message: pathOr(error.message, ['response', 'Message'], error),
                id
              });
            })
          )
        )
      ).pipe(
        mergeMap(res => {
          const errors = groupBy(v => v.Status)(res);
          const actions = [];

          if (errors.Error?.length > 0) {
            message.error(
              `刪除失敗: ${errors.Error.map(
                e => `id:${e.id} message: ${e.Message}`
              ).join(', ')}`
            );
            actions.push(deleteSigninEventFailure(res));
          } else {
            actions.push(deleteSigninEventSuccess(res));
          }
          if (callbackAction) actions.push(callbackAction);
          return actions;
        })
      );
    })
  );

export const getContinuityEventListEpic = action$ =>
  action$.pipe(
    ofType(GET_CONTINUITY_EVENT_LIST.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getContinuityEventListAPI(payload).pipe(
        map(getContinuityEventListSuccess),
        catchRequestError(getContinuityEventListFailure)
      )
    )
  );
/**
 * Reducer
 */
const initialState = {
  data: [],
  continuityEventList: [],
  currentSigninEvent: null,
  loading: false,
  isShowModal: false,
  totalCount: 0
};

export default handleActions(
  {
    [combineActions(
      GET_SIGNIN_EVENT_LIST.REQUEST,
      GET_CONTINUITY_EVENT_LIST.REQUEST,
      ADD_SIGNIN_EVENT.REQUEST,
      SET_SIGNIN_EVENT.REQUEST,
      DELETE_SIGNIN_EVENT.REQUEST
    )]: produce((draft, { payload }) => {
      draft.loading = true;
    }),
    [GET_SIGNIN_EVENT.REQUEST]: produce(draft => {
      draft.loading = true;
      draft.currentSigninEvent = null;
    }),
    [GET_SIGNIN_EVENT.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.currentSigninEvent = payload.data;
    }),
    [GET_SIGNIN_EVENT.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.currentSigninEvent = null;
      draft.isShowModal = false;
    }),
    [combineActions(ADD_SIGNIN_EVENT.SUCCESS, SET_SIGNIN_EVENT.SUCCESS)]:
      produce((draft, { payload }) => {
        draft.loading = false;
        draft.isShowModal = false;
      }),
    [GET_SIGNIN_EVENT_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload.data;
      draft.totalCount = payload.totalCount;
    }),
    [GET_SIGNIN_EVENT_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.data = [];
      draft.totalCount = 0;
    }),
    [GET_CONTINUITY_EVENT_LIST.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.continuityEventList = payload.data;
    }),
    [GET_CONTINUITY_EVENT_LIST.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.continuityEventList = [];
    }),
    [combineActions(
      ADD_SIGNIN_EVENT.FAILURE,
      SET_SIGNIN_EVENT.FAILURE,
      DELETE_SIGNIN_EVENT.SUCCESS,
      DELETE_SIGNIN_EVENT.FAILURE
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SHOW_SIGNIN_EVENT_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
