import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Form, Input, Select, Spin } from 'antd';
import { addAccount } from '../../reducers/accountCreationManagement';
import showInfo from './showInfo';
import { PageTitle } from '../../components/styled/page';

const Option = Select.Option;

const Wrapper = styled.section`
  margin-right: 1rem;
  padding-bottom: 1rem;
`;

const Container = styled.div`
  width: 25rem;
  margin: 2rem auto 0;
  padding: 16px;
  background: #fff;
  border-radius: 4px;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    width: 5.5rem;
  }
`;

const ROLE_LIST = Object.freeze([
  { id: '7', name: '經營管理' },
  { id: '1', name: '系統管理員' },
  { id: '8', name: '內容管理' },
  { id: '6', name: '帳務管理' },
  { id: '9', name: '經紀公司' },
  { id: '18', name: '家族長' },
  { id: '5', name: '一般用戶' },
  { id: '10', name: '場控' },
  { id: '11', name: '經紀公司管理' },
  { id: '12', name: '設計' },
  { id: '13', name: '企劃' },
  // { id: '4', name: '直播主' },
  // { id: '14', name: '聲聊主' },
  // { id: '15', name: '私聊主' },
  // { id: '17', name: 'OBS 直播主' }
]);

class ChatBotManagement extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.state = {
      confirmDirty: false
    };
  }

  compareToFirstPassword = (rule, value) => {
    const form = this.formRef.current;
    if (value && value !== form.getFieldValue('password')) {
      const error = new Error('密碼輸入不一致！');
      return Promise.reject(error);
    } else {
      return Promise.resolve();
    }
  };

  onPasswordBlur = () => {
    const form = this.formRef.current;
    const value = form.getFieldValue('password');
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm']);
    }
  };

  onConfirmBlur = e => {
    const { value } = e.target;
    this.setState({
      confirmDirty: this.state.confirmDirty || !!value
    });
  };

  onSubmit = values => {
    const form = this.formRef.current;
    const { addAccount } = this.props;
    addAccount(values, userData => {
      form.resetFields();
      showInfo(userData);
    });
  };

  renderRoleSelector = () => (
    <Select>
      {ROLE_LIST.map(role => (
        <Option key={role.id} value={role.id}>
          <span>{role.name}</span>
        </Option>
      ))}
    </Select>
  );

  render() {
    const { loading } = this.props;
    return (
      <Wrapper>
        <PageTitle>{'帳號管理 > 新創帳號'}</PageTitle>
        <Container>
          <Spin spinning={loading}>
            <Form
              ref={this.formRef}
              style={{ maxWidth: '480px' }}
              onFinish={this.onSubmit}
              autoComplete="off"
            >
              <FormItem name="publicSexInfo" initialValue={false} hidden>
                <Input type="hidden" />
              </FormItem>
              <FormItem
                label="帳號"
                name="loginId"
                rules={[{ required: true, message: '請輸入帳號!' }]}
              >
                <Input autoComplete="false" />
              </FormItem>
              <FormItem
                label="密碼"
                name="password"
                rules={[{ required: true, message: '請輸入密碼！' }]}
              >
                <Input.Password
                  autoComplete="new-password"
                  onBlur={this.onPasswordBlur}
                />
              </FormItem>
              <FormItem
                label="確認密碼"
                name="confirm"
                rules={[
                  { required: true, message: '請再次確認密碼！' },
                  { validator: this.compareToFirstPassword }
                ]}
              >
                <Input.Password onBlur={this.onConfirmBlur} />
              </FormItem>
              <FormItem
                label="選擇角色"
                name="role"
                rules={[{ required: true, message: '請選擇角色！' }]}
              >
                {this.renderRoleSelector()}
              </FormItem>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ width: '100%' }}
              >
                <span>建立</span>
              </Button>
            </Form>
          </Spin>
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  const { accountCreationManagement } = state;
  return {
    loading: accountCreationManagement.loading,
    data: accountCreationManagement.data
  };
};

const mapDispatchToProps = {
  addAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatBotManagement);
