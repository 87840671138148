import _ from 'lodash';
import { forkJoin } from 'rxjs';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { Tabs } from 'antd';
import ClickOutside from 'react-click-outside';
import GiftCategory from './GiftCategory';
import { getGiftListAPI, getGiftCategoryListAPI } from '../../../../apis';

const { TabPane } = Tabs;

class GiftPanel extends React.Component {

  static propTypes = {
    onSendGift: PropTypes.func,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
      gifts: {},
      categories: [],
      visible: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const observable = forkJoin({
      groupsRes: this.giftObservable(),
      categoriesRes: this.giftCategoriesObservable(),
    });
    observable.subscribe(res => {
      const { groupsRes, categoriesRes } = res;
      const gifts = _.groupBy(groupsRes.data, 'categoryId');
      const categories = categoriesRes.data;
      this.setState({ gifts, categories });
    });
  }

  giftObservable = () => {
    const observable = getGiftListAPI({
      typeFilter: 1,
      statusFilter: 'on',
      item: 999,
    });
    return observable;
  }

  giftCategoriesObservable = () => {
    const observable = getGiftCategoryListAPI({
      typeFilter: 1,
      statusFilter: 'on',
      item: 999,
    });
    return observable;
  }

  show = () => {
    this.setState({ visible: true });
  }

  hide = () => {
    this.setState({ visible: false });
  }

  onGiftSeleted = (id) => {
    const { onSendGift } = this.props;
    onSendGift(id);
    this.hide();
  }

  renderGiftCategory = (category) => {
    const { gifts } = this.state;
    return (
      <GiftCategory
        gifts={gifts[category.id]}
        onGiftSeleted={this.onGiftSeleted}
      ></GiftCategory>
    );
  }

  render() {
    const { className } = this.props;
    const { categories, visible } = this.state;
    const wrapperClass = classNames(className, 'gift-pannel');
    return visible ? (
      <ClickOutside onClickOutside={this.hide}>
        <div className={wrapperClass}>
          <Tabs size="small" centered>
            {categories.map(category => (
              <TabPane tab={category.categoryName} key={category.id}>
                {this.renderGiftCategory(category)}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </ClickOutside>
    ) : null;
  }
}

const StyledGiftPanel = styled(GiftPanel)`
  height: 20rem;
  padding: 0.5rem;
  background-color: #FAFAFA;
  outline: none;

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content {
    width: 100%;
    position: absolute;
    top: 2.25rem;
    bottom: 0;
  }
`;

export default StyledGiftPanel;
