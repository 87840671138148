import { message } from 'antd';
import Cookies from 'js-cookie';
import {
  always,
  compose,
  cond,
  equals,
  has,
  identity,
  map as Rmap,
  mapObjIndexed,
  pipe,
  remove,
  T
} from 'ramda';
import { createAction, handleActions } from 'redux-actions';
import {
  delay,
  map,
  mapTo,
  mergeMap,
  pluck,
  retryWhen,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import * as actions from '../actions';
import * as Types from '../actions/Types';
import { createRequestTypes } from '../actions/Types';
import {
  changePasswordAPI,
  getAccountRightListAPI,
  getPersonInfoAPI,
  login,
  setHeader
} from '../apis';
import { specialAccountList } from '../constants/roles';
import { catchRequestError, ofType } from '../utils/extendOperators';

const csvJSON = csv => {
  var lines = csv.split('\n');

  var result = [];

  var headers = lines[0].split(',');

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(',');

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return result;
};

const formatCSVData = compose(
  Rmap(
    mapObjIndexed(
      cond([
        [equals('TRUE'), always(true)],
        [equals('FALSE'), always(false)],
        [T, identity]
      ])
    )
  ),
  remove(0, 2),
  csvJSON
);
/**
 * Action Types
 */

const GET_PERSONAL_INFO = 'GET_PERSONAL_INFO';
const GET_PERSONAL_INFO_SUCCESS = 'GET_PERSONAL_INFO_SUCCESS';
const GET_PERSONAL_INFO_FAILURE = 'GET_PERSONAL_INFO_FAILURE';

const CHANGE_PASSWORD = createRequestTypes('CHANGE_PASSWORD');
const GET_ACCOUNT_RIGHT_LIST = createRequestTypes('GET_ACCOUNT_RIGHT_LIST');

/**
 * Action Creators
 */
export const loginInit = createAction(Types.AUTH_INIT.REQUEST);
export const changePassword = createAction(CHANGE_PASSWORD.REQUEST);

const loginInitSuccess = createAction(Types.AUTH_INIT.SUCCESS);
const loginInitFailure = createAction(Types.AUTH_INIT.FAILURE);
const loginSuccess = createAction(Types.AUTH_LOGIN.SUCCESS);
const loginFailure = createAction(Types.AUTH_LOGIN.FAILURE);

export const getLiveMasterPersonalInfo = createAction(GET_PERSONAL_INFO);
const getLiveMasterPersonalInfoSuccess = createAction(
  GET_PERSONAL_INFO_SUCCESS
);
const getLiveMasterPersonalInfoFailure = createAction(
  GET_PERSONAL_INFO_FAILURE
);

export const getAccountRightList = createAction(GET_ACCOUNT_RIGHT_LIST.REQUEST);
export const getAccountRightListSuccess = createAction(
  GET_ACCOUNT_RIGHT_LIST.SUCCESS
);
export const getAccountRightListFailure = createAction(
  GET_ACCOUNT_RIGHT_LIST.FAILURE
);

/**
 *
 */
function checkSpecialAccount(account, roleName) {
  return has(account, specialAccountList)
    ? specialAccountList[account]
    : roleName;
}

/**
 * Epic
 */
export const loginEpic = pipe(
  ofType(Types.AUTH_LOGIN.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    login(payload).pipe(
      tap(({ data: { token, nonce } }) => {
        setHeader(token, nonce);
        Cookies.set('_trueloveToken', token);
        Cookies.set('_trueloveNonce', nonce);
      }),
      mergeMap(res => {
        return [
          loginSuccess(res),
          getLiveMasterPersonalInfo(),
          getAccountRightList()
        ];
      }),
      catchRequestError(loginFailure)
    )
  )
);

export const getAccountRightListEpic = action$ =>
  action$.pipe(
    ofType(GET_ACCOUNT_RIGHT_LIST.REQUEST),
    pluck('payload'),
    switchMap(() =>
      getAccountRightListAPI().pipe(
        pluck('response'),
        map(getAccountRightListSuccess),
        retryWhen(errors => errors.pipe(delay(150), take(5))),
        catchRequestError(getAccountRightListFailure)
      )
    )
  );

export const getLiveMasterPersonalInfoEpic = pipe(
  ofType(GET_PERSONAL_INFO),
  mergeMap(action =>
    getPersonInfoAPI().pipe(
      mergeMap(res => {
        const { roles, loginId } = res.data;
        roles[0].name = checkSpecialAccount(loginId, roles[0].name);
        return [getLiveMasterPersonalInfoSuccess(res), getAccountRightList()];
      }),
      catchRequestError(getLiveMasterPersonalInfoFailure)
    )
  )
);

export const logoutEpic = pipe(
  ofType(Types.AUTH_LOGOUT.REQUEST),
  tap(() => {
    Cookies.remove('_trueloveToken');
    Cookies.remove('_trueloveNonce');
    setHeader();
  }),
  mapTo({ type: Types.AUTH_LOGOUT.SUCCESS })
);

export const loginInitEpic = pipe(
  ofType(Types.AUTH_INIT.REQUEST),
  map(() => {
    let { _trueloveToken: token, _trueloveNonce: nonce } = Cookies.get();

    if (!token || !nonce) {
      throw new Error();
    }

    return { token, nonce };
  }),
  tap(({ token, nonce }) => setHeader(token, nonce)),
  mergeMap(payload => {
    return [loginInitSuccess(payload), getLiveMasterPersonalInfo()];
  }),
  catchRequestError(loginInitFailure)
);

export const changePasswordEpic = pipe(
  ofType(CHANGE_PASSWORD.REQUEST),
  pluck('payload'),
  switchMap(payload =>
    changePasswordAPI(payload).pipe(
      map(actions.logout),
      catchRequestError(
        pipe(error => {
          message.success('修改密碼失敗');
          return error;
        }, createAction(CHANGE_PASSWORD.FAILURE))
      )
    )
  )
);

/**
 * Reducer
 */

const initialState = {
  loginId: '',
  token: '',
  nonce: '',
  isAuthenticated: false,
  isLoginFailed: false,
  isLoading: false,
  hasUserInfo: false,
  roles: [],
  accountRightObj: null
};

export default handleActions(
  {
    [Types.AUTH_LOGIN.REQUEST]: (state, action) => ({
      ...state,
      isLoading: true
    }),
    [Types.AUTH_LOGIN.SUCCESS]: (state, action) => {
      return {
        ...state,
        token: action.payload.data.token,
        nonce: action.payload.data.nonce,
        isAuthenticated: !!action.payload.data.token,
        isLoginFailed: false
      };
    },
    [Types.AUTH_LOGIN.FAILURE]: (state, action) => ({
      ...state,
      isLoading: false,
      isLoginFailed: true
    }),
    [Types.AUTH_INIT.SUCCESS]: (state, action) => {
      return {
        ...state,
        token: action.payload.token,
        nonce: action.payload.nonce,
        isAuthenticated: true
      };
    },
    [Types.AUTH_LOGOUT.SUCCESS]: (state, action) => ({
      ...initialState
    }),
    [GET_PERSONAL_INFO_SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload.data,
      hasUserInfo: true
    }),
    [GET_ACCOUNT_RIGHT_LIST.REQUEST]: (state, action) => ({
      ...state,
      accountRightObj: null
    }),
    [GET_ACCOUNT_RIGHT_LIST.SUCCESS]: (state, { payload }) => {
      const csvData = formatCSVData(payload);
      const loginId = state.loginId;
      const accountRightObj =
        csvData.find(account => account.loginId === loginId) || {};
      return {
        ...state,
        accountRightObj: accountRightObj
      };
    },
    [GET_ACCOUNT_RIGHT_LIST.FAILURE]: (state, { payload }) => {
      return {
        ...state,
        accountRightObj: null
      };
    }
  },
  initialState
);
