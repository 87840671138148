import { Descriptions, Modal } from 'antd';
import { find, propEq } from 'ramda';
import { renderPreviewer } from '../../components/UploadImg';
import React, { useEffect, useState } from 'react';
import { isNotEmpty } from '../../utils/mixin';

const PreviewGiftModal = ({ giftList, effectData, visible, onCancel }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (visible && isNotEmpty(giftList) && effectData) {
      setData(find(propEq('id', effectData.giftV2Id), giftList));
    }
  }, [visible, giftList, effectData]);

  const onAfterClose = () => {
    setData(null);
  };

  return (
    <Modal
      visible={visible}
      title="預覽"
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
      afterClose={onAfterClose}
    >
      {data && (
        <Descriptions column={1} size="small" bordered>
          <Descriptions.Item label="圖片">
            {renderPreviewer(data.animationUrl)}
          </Descriptions.Item>
          <Descriptions.Item label="縮圖">
            {renderPreviewer(data.thumbUrl)}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Modal>
  );
};
export default PreviewGiftModal;
