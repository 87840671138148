import { Button, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import moment from 'moment';
import { equals, evolve, isEmpty, keys, map, omit, reject } from 'ramda';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseOrderListApi } from '../apis';
import LiveMasterSelect from '../components/LiveMasterSelect';
import { payRoles, payType, payTypeForEsgame } from '../constants/roles';
import { getPurchaseOrder } from '../reducers/purchaseOrderList';
import downloadCSV from '../utils/downloadCSV';
import { isReallyEmpty } from '../utils/mixin';
import { PageContainer, PageTitle } from '../components/styled/page';
import { useHasErrors } from '../hooks/useHasErrors';
const FormItem = Form.Item;
const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;
const removeEmpty = reject(equals(''));
const getSubmitData = payload => {
  if (payload.dates && payload.dates.length > 0) {
    payload.startAt = Number(payload.dates[0].format('X'));
    payload.endAt = Number(payload.dates[1].format('X'));
  }
  payload.status = payload.status !== '' ? Number(payload.status) : '';
  return { ...omit(['dates'], removeEmpty(payload)) };
};
const getCSV = data => {
  downloadCSV(
    map(
      evolve({
        createAt: date => moment.unix(date).format('YYYY/MM/DD HH:mm:ss'),
        issuedAt: date =>
          date ? moment.unix(date).format('YYYY/MM/DD HH:mm:ss') : '無紀錄',
        status: code => (code === 1 ? '成功' : `失敗，失敗代碼 ${code}`)
      }),
      data.purchaseOrderList
    ),
    [
      'createAt',
      'issuedAt',
      'orderId',
      'creditCardCode',
      'purchaseType',
      'paymentType',
      'price',
      'points',
      'status',
      'loginId',
      'nickname',
      'invoiceNumber'
    ],
    [
      '交易開始',
      '交易完成',
      '交易訂單編號',
      '信用卡末四碼',
      '交易方式',
      '付款方式',
      '交易金額',
      '交易點數',
      '交易狀態',
      'Login ID',
      '儲值暱稱',
      '發票號碼'
    ]
  );
};
const formItemLayout = {
  labelCol: {
    md: { span: 24 },
    lg: { span: 5 }
  },
  wrapperCol: {
    md: { span: 24 },
    lg: { span: 19 }
  }
};
const columns = [
  {
    title: '交易開始',
    dataIndex: 'createAt',
    render: createAt => (
      <span>{moment.unix(createAt).format('YYYY-MM-DD HH:mm:ss')}</span>
    )
  },
  {
    title: '交易完成',
    dataIndex: 'issuedAt',
    render: issuedAt => (
      <span>
        {issuedAt
          ? moment.unix(issuedAt).format('YYYY-MM-DD HH:mm:ss')
          : '無紀錄'}
      </span>
    )
  },
  {
    title: '交易訂單編號',
    dataIndex: 'orderId'
  },
  {
    title: '交易方式',
    dataIndex: 'purchaseType'
  },
  {
    title: '付款方式',
    dataIndex: 'paymentType'
  },
  {
    title: '交易金額',
    dataIndex: 'price'
  },
  {
    title: '交易點數',
    dataIndex: 'points'
  },
  {
    title: '交易狀態',
    dataIndex: 'status',
    render: status => (
      <span>{status === 1 ? '成功' : `失敗，失敗代碼 ${status}`}</span>
    )
  },
  {
    title: '信用卡末四碼',
    dataIndex: 'creditCardCode'
  },
  {
    title: 'Login ID',
    dataIndex: 'loginId'
  },
  {
    title: '儲值暱稱',
    dataIndex: 'nickname'
  },
  {
    title: '發票號碼',
    dataIndex: 'invoiceNumber'
  }
];

function getPayOption(payRoles) {
  return map(
    item => (
      <Option key={item} value={item}>
        {payRoles[item]}
      </Option>
    ),
    keys(payRoles)
  );
}

const getPaymentTypeOption = purchaseType => {
  const types = purchaseType !== 'esgame' ? payType : payTypeForEsgame;
  return map(
    item => (
      <Option key={item} value={item}>
        {types[item]}
      </Option>
    ),
    keys(types)
  );
};

const PurchaseOrderList = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { resetFields, getFieldValue } = form;
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const { loading, order, page, item, totalCount } = useSelector(
    state => state.purchaseOrderList
  );
  const [isChanged, setIsChanged] = useState(false);

  const onControlsChange = () => {
    setIsChanged(true);
  };

  const onResetClick = () => {
    setIsChanged(true);
    setHasError(false);
    form.resetFields();
  };

  const onDownloadClick = () => {
    const values = form.getFieldsValue(true);
    const payload = { ...getSubmitData(values), page: 1, item: totalCount };
    getPurchaseOrderListApi(payload).subscribe(getCSV);
  };

  const onSubmit = e => {
    getPurchaseOrderByPage(1);
    setIsChanged(false);
  };

  const getPurchaseOrderByPage = page => {
    const values = form.getFieldsValue(true);
    const payload = { ...getSubmitData(values), page, item };
    dispatch(getPurchaseOrder(payload));
  };

  return (
    <PageContainer>
      <PageTitle>{'帳務管理 > 交易資料查詢'}</PageTitle>
      <div style={{ width: '100%', maxWidth: 1600, margin: '0 auto' }}>
        <Form
          form={form}
          onFieldsChange={validateStatus}
          onFinish={onSubmit}
          {...formItemLayout}
        >
          <Row gutter={24}>
            <Col span={24} md={{ span: 12 }}>
              <FormItem name="orderId" label="交易訂單編號">
                <Input
                  allowClear={true}
                  onChange={({ currentTarget }) => {
                    onControlsChange();
                    //reset 日期欄位的狀態
                    if (
                      isReallyEmpty(currentTarget.value) &&
                      isReallyEmpty(getFieldValue('dates'))
                    ) {
                      resetFields(['dates']);
                    }
                  }}
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <FormItem name="userId" label="Login ID">
                <LiveMasterSelect
                  style={{ width: '100%' }}
                  typeFilter="login_id"
                  statusFilter={null}
                  roleFilter={null}
                  onSelectChange={onControlsChange}
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <FormItem name="purchaseType" label="交易方式" initialValue="">
                <Select
                  placeholder="請選擇交易方式"
                  onChange={onControlsChange}
                >
                  <Option key="all" value="">
                    全部
                  </Option>
                  {getPayOption(payRoles)}
                </Select>
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <FormItem
                    name="paymentType"
                    label="付款方式"
                    initialValue=""
                    shouldUpdate
                  >
                    <Select
                      placeholder="請選擇付款方式"
                      onChange={onControlsChange}
                    >
                      <Option key="all" value="">
                        全部
                      </Option>
                      {getPaymentTypeOption(getFieldValue('purchaseType'))}
                    </Select>
                  </FormItem>
                )}
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <FormItem name="status" label="交易狀態" initialValue="">
                <Select
                  placeholder="請選擇交易狀態"
                  onChange={onControlsChange}
                >
                  <Option value="">全部</Option>
                  <Option value="0">未完成</Option>
                  <Option value="1">已完成</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <FormItem noStyle shouldUpdate>
                {() => (
                  <FormItem
                    name="dates"
                    label="交易日期"
                    shouldUpdate
                    rules={
                      isReallyEmpty(getFieldValue('orderId'))
                        ? null
                        : [{ required: true, message: '請輸入交易日期' }]
                    }
                  >
                    <RangePicker
                      allowClear={true}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{ width: '100%' }}
                      onChange={onControlsChange}
                      showTime={{
                        defaultValue: [
                          moment('00:00:00', 'HH:mm:ss'),
                          moment('23:59:59', 'HH:mm:ss')
                        ]
                      }}
                    />
                  </FormItem>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              md={{ span: 12, offset: 12 }}
              style={{ textAlign: 'right' }}
            >
              <FormItem
                wrapperCol={{
                  md: { span: 24, offset: 0 },
                  lg: { span: 16, offset: 8 }
                }}
              >
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  disabled={hasErrors}
                >
                  查詢
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={onResetClick}>
                  清除
                </Button>
                <Button
                  style={{ marginLeft: 8 }}
                  disabled={isEmpty(order) || isChanged}
                  onClick={onDownloadClick}
                >
                  匯出報表
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          disabled: isChanged,
          showSizeChanger: false,
          onChange: page => getPurchaseOrderByPage(page),
          current: page,
          pageSize: item,
          total: totalCount
        }}
        dataSource={order}
        loading={loading}
      />
    </PageContainer>
  );
};

export default PurchaseOrderList;
