import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Table, Select, Form, InputNumber } from 'antd';
import ImageUpload from './ImageUpload';

export default class RandomLiveNPC extends PureComponent {
  _validateImage = (rule, value, callback) => {
    if (!value) {
      return callback('此欄位必填');
    }
    callback();
  };

  handleChange = path => changedValue => {
    const { setFields, getFieldError } = this.props;
    if (setFields && changedValue && getFieldError(path)) {
      setFields({
        [path]: {
          error: undefined,
        },
      });
    }
  };

  _createColumns = () => {
    const { formFieldDecorator, giftList, isEdit } = this.props;

    return [
      {
        title: '正常動畫',
        dataIndex: ['animationSetting', 'map', '0', 'url'],
        width: 80,
        render: (value, record, i) => {
          return (
            <FormItem className="mb0">
              {formFieldDecorator(`npcs[${i}].animationSetting.map[0].url`, {
                validateTrigger: ['onChange'],
                rules: [{ validator: this._validateImage }],
              })(
                <ImageUpload
                  onChange={this.handleChange(
                    `npcs[${i}].animationSetting.map[0].url`
                  )}
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: '爆擊1 動畫',
        dataIndex: ['animationSetting', 'map', '1', 'url'],
        width: 80,
        render: (value, record, i) => {
          return (
            <FormItem className="mb0">
              {formFieldDecorator(`npcs[${i}].animationSetting.map[1].url`, {
                validateTrigger: ['onChange'],
                rules: [{ validator: this._validateImage }],
              })(
                <ImageUpload
                  onChange={this.handleChange(
                    `npcs[${i}].animationSetting.map[1].url`
                  )}
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: '爆擊2 動畫',
        dataIndex: ['animationSetting', 'map', '2', 'url'],
        width: 80,
        render: (value, record, i) => {
          return (
            <FormItem className="mb0">
              {formFieldDecorator(`npcs[${i}].animationSetting.map[2].url`, {
                validateTrigger: ['onChange'],
                rules: [{ validator: this._validateImage }],
              })(
                <ImageUpload
                  onChange={this.handleChange(
                    `npcs[${i}].animationSetting.map[2].url`
                  )}
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: '過關全版動畫',
        dataIndex: ['animationSetting', 'map', '3', 'url'],
        width: 90,
        render: (value, record, i) => {
          return (
            <FormItem className="mb0">
              {formFieldDecorator(`npcs[${i}].animationSetting.map[3].url`, {
                validateTrigger: ['onChange'],
                rules: [{ validator: this._validateImage }],
              })(
                <ImageUpload
                  onChange={this.handleChange(
                    `npcs[${i}].animationSetting.map[3].url`
                  )}
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: '攻擊/餵養道具',
        dataIndex: 'giftIds',
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].giftIds`, {
              validateTrigger: ['onBlur'],
              rules: [{ required: true, message: '此欄位必填' }],
            })(
              <Select
                mode="multiple"
                size="large"
                className="w100"
                placeholder="計分禮物"
                allowClear
                disabled={isEdit}
              >
                {giftList.map(x => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name} / {x.point}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
        ),
      },
      {
        title: '過關點數',
        dataIndex: 'npcSpacings',
        width: 80,
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].npcSpacings[0].points`, {
              validateTrigger: ['onBlur'],
              rules: [
                { required: true, whitespace: true, message: '此欄位必填' },
              ],
            })(
              <InputNumber
                size="large"
                className="w100"
                placeholder="過關點數"
                disabled={isEdit}
                min={0}
              />
            )}
          </FormItem>
        ),
      },
      {
        title: '主播回饋點數',
        dataIndex: 'bonus',
        width: 90,
        render: (value, record, i) => (
          <FormItem>
            {formFieldDecorator(`npcs[${i}].bonus`, {
              validateTrigger: ['onBlur'],
              rules: [
                { required: true, whitespace: true, message: '此欄位必填' },
              ],
            })(
              <InputNumber
                size="large"
                className="w100"
                min={0}
                placeholder="主播回饋點數"
                disabled={isEdit}
              />
            )}
          </FormItem>
        ),
      },
    ];
  };

  render() {
    const { npcs } = this.props;

    const columns = this._createColumns();

    return (
      <Table
        rowKey="id"
        className="mt12"
        columns={columns}
        dataSource={npcs}
        pagination={false}
        size="middle"
      />
    );
  }
}

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-top: 5px;
  }

  .ant-input-number,
  .ant-select-selection {
    margin-top: 10px;
  }
`;
