import moment from 'moment';
import { pipe } from 'ramda';
import { switchMap, map } from 'rxjs/operators';
import { createAction, handleActions } from 'redux-actions';
import { ofType, catchRequestError } from '../utils/extendOperators';
import { loadRegistrationStaffList } from '../apis';

/**
 * Action Types
 */
const GET_REGISTRATION_STAFF_LIST = 'GET_REGISTRATION_STAFF_LIST';
const GET_REGISTRATION_STAFF_LIST_SUCCESS =
  'GET_REGISTRATION_STAFF_LIST_SUCCESS';
const GET_REGISTRATION_STAFF_LIST_FAILURE =
  'GET_REGISTRATION_STAFF_LIST_FAILURE';

/**
 * Action Creators
 */
export const getRegistrationStaffList = (
  startDate,
  endDate = moment()
    .endOf('day')
    .valueOf(),
  isCustomize = false
) =>
  createAction(GET_REGISTRATION_STAFF_LIST)({
    startDate,
    offsetInMillis: 28800000,
    endDate,
    isCustomize
  });

const getRegistrationStaffListSuccess = createAction(
  GET_REGISTRATION_STAFF_LIST_SUCCESS
);
const getRegistrationStaffListFailure = createAction(
  GET_REGISTRATION_STAFF_LIST_FAILURE
);

export const getRegistrationStaffListEpic = pipe(
  ofType(GET_REGISTRATION_STAFF_LIST),
  map(action => action.payload),
  switchMap(payload =>
    loadRegistrationStaffList(payload).pipe(
      map(getRegistrationStaffListSuccess),
      catchRequestError(getRegistrationStaffListFailure)
    )
  )
);

const initialState = {
  data: [],
  startDate: undefined,
  endDate: undefined,
  isCustomize: false
};

export default handleActions(
  {
    [GET_REGISTRATION_STAFF_LIST]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [GET_REGISTRATION_STAFF_LIST_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data.map(item => ({ ...item }))
    })
  },
  initialState
);
