import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import { isEmpty, groupBy } from 'ramda';
import { switchMap, map, pluck, tap, mergeMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { ofType, catchRequestError } from '../utils/extendOperators';
import {
  getRewardListAPI,
  addRewardAPI,
  setRewardAPI,
  deleteRewardAPI,
  getAnimationEffectListAPI,
  searchAPI,
} from '../apis';
import { notification } from 'antd';

/**
 * Action Types
 */
const SHOW_MODAL = 'REWARD.SHOW_MODAL';

const GET_REWARD_LIST = 'GET_REWARD_LIST';
const GET_REWARD_LIST_SUCCESS = 'GET_REWARD_LIST_SUCCESS';
const GET_REWARD_LIST_FAILURE = 'GET_REWARD_LIST_FAILURE';

const ADD_REWARD = 'ADD_REWARD';
const ADD_REWARD_SUCCESS = 'ADD_REWARD_SUCCESS';

const SET_CURRENT_REWARD = 'SET_CURRENT_REWARD';

const MODIFY_REWARD = 'MODIFY_REWARD';
const MODIFY_REWARD_SUCCESS = 'MODIFY_REWARD_SUCCESS';
const MODIFY_REWARD_FAILURE = 'MODIFY_REWARD_FAILURE';

const DELETE_REWARD = 'DELETE_REWARD';
const DELETE_REWARD_SUCCESS = 'DELETE_REWARD_SUCCESS';

/**
 * Action Creators
 */
export const showModal = createAction(SHOW_MODAL);
export const getRewardList = createAction(GET_REWARD_LIST);
export const getRewardListSuccess = createAction(GET_REWARD_LIST_SUCCESS);
export const getRewardListFailure = createAction(GET_REWARD_LIST_FAILURE);

export const addReward = createAction(ADD_REWARD);
export const addRewardSuccess = createAction(ADD_REWARD_SUCCESS);

export const setCurrentReward = createAction(SET_CURRENT_REWARD);

export const modifyReward = createAction(MODIFY_REWARD);
export const modifyRewardSuccess = createAction(MODIFY_REWARD_SUCCESS);
export const modifyRewardFailure = createAction(MODIFY_REWARD_FAILURE);

export const deleteReward = createAction(DELETE_REWARD, (ids = []) => ids);
export const deleteRewardSuccess = createAction(DELETE_REWARD_SUCCESS);

export const getRewardListEpic = (action$) =>
  action$.pipe(
    ofType(GET_REWARD_LIST),
    pluck('payload'),
    switchMap(() => {
      return combineLatest(
        getRewardListAPI(),
        getAnimationEffectListAPI(),
        searchAPI({
          keyword: '',
          typeFilter: 'nick_name',
          roleFilter: 'ROLE_MASTER',
          page: 1,
          item: 9999,
        })
      ).pipe(
        map(getRewardListSuccess),
        catchRequestError((e) => {
          const response = e.response ? e.response.Message : '';
          notification.error({
            message: 'Get List Fail.',
            description: `${e.message} - ${response}`,
          });
          return getRewardListFailure(e);
        })
      );
    })
  );

export const addRewardEpic = (action$) => {
  return action$.pipe(
    ofType(ADD_REWARD),
    pluck('payload'),
    map((payload) => {
      return payload.liveMaster.map((id) => {
        return { ...payload, liveMaster: id };
      });
    }),
    switchMap((payload) => {
      return combineLatest(
        ...payload.map((master) =>
          addRewardAPI(master).pipe(
            catchRequestError((e) => {
              return {
                Status: 'error',
                id: master.liveMaster,
                message: e.message,
              };
            })
          )
        )
      ).pipe(
        tap((e) => {
          const errors = groupBy((v) => v.Status)(e);
          if (errors['error'] && errors['error'].length > 0) {
            notification.error({
              message: 'Add Fail.',
              description: `${errors['error'].map((e) => e.id).join('\n')}`,
            });
          }
        }),
        mergeMap((e) => [addRewardSuccess(e), getRewardList()])
      );
    })
  );
};

export const modifyRewardEpic = (action$) => {
  return action$.pipe(
    ofType(MODIFY_REWARD),
    pluck('payload'),
    switchMap((payload) =>
      setRewardAPI(payload).pipe(
        mergeMap((e) => [modifyRewardSuccess(e), getRewardList()]),
        catchRequestError(modifyRewardFailure)
      )
    )
  );
};

export const delectRewardEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_REWARD),
    pluck('payload'),
    switchMap((rewardIds) => {
      return combineLatest(
        ...rewardIds.map((id) =>
          deleteRewardAPI(id).pipe(
            catchRequestError((e) => {
              return { Status: 'error', id: id, message: e.message };
            })
          )
        )
      ).pipe(
        tap((e) => {
          const errors = groupBy((v) => v.Status)(e);
          if (errors['error'] && errors['error'].length > 0) {
            notification.error({
              message: 'Delete Fail.',
              description: `ID ${errors['error'].map((e) => e.id).join(',')}`,
            });
          }
        }),
        mergeMap((e) => [deleteRewardSuccess(e), getRewardList()])
      );
    })
  );

/**
 * Reducer
 */
const initialState = {
  loading: false,
  isShowModal: false,
  liveMasterList: [],
  effectList: [],
  rewardList: [],
  currentReward: {},
};

export default handleActions(
  {
    [combineActions(
      GET_REWARD_LIST,
      ADD_REWARD,
      DELETE_REWARD,
      MODIFY_REWARD
    )]: produce((draft) => {
      draft.loading = true;
    }),
    [GET_REWARD_LIST_SUCCESS]: produce((draft, { payload }) => {
      const effectsByID = {};
      const liveMasterByID = {};
      draft.loading = false;
      draft.effectList = Object.values(payload[1].data).flat();
      draft.effectList.forEach((effect) => {
        Object.assign(effectsByID, { [effect.id]: effect });
      });

      draft.liveMasterList = payload[2].data;
      draft.liveMasterList.forEach((master) => {
        Object.assign(liveMasterByID, { [master.id]: master });
      });

      // Join List
      draft.rewardList = payload[0].data.map((reward) => {
        return {
          ...reward,
          sfxType: effectsByID[reward.sfxId]
            ? effectsByID[reward.sfxId].type
            : '',
          liveMasterLoginId: liveMasterByID[reward.liveMasterId]
            ? liveMasterByID[reward.liveMasterId].loginId
            : '',
        };
      });
    }),
    [SET_CURRENT_REWARD]: produce((draft, { payload }) => {
      draft.currentReward = isEmpty(payload)
        ? {}
        : { ...payload, sfx: payload.sfxId, liveMaster: payload.liveMasterId };
    }),
    [combineActions(ADD_REWARD_SUCCESS, MODIFY_REWARD_SUCCESS)]: produce(
      (draft) => {
        draft.loading = false;
        draft.isShowModal = false;
      }
    ),
    [combineActions(
      DELETE_REWARD_SUCCESS,
      GET_REWARD_LIST_FAILURE,
      MODIFY_REWARD_FAILURE
    )]: produce((draft, { payload }) => {
      draft.loading = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    }),
  },
  initialState
);
