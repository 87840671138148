import _ from 'lodash';
import objectHash from 'object-hash';
import React from 'react';
import PropTypes from 'prop-types';
import { Table, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import columns from './columns';

class MemberList extends React.Component {
  static propTypes = {
    dataset: PropTypes.array,
    pagination: PropTypes.object,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.columns = columns(this);

    this.state = {
    };
  }

  renderRanking = (text, record, index) => {
    const { pagination } = this.props;
    const { current, pageSize } = pagination;
    return (current - 1) * pageSize + index + 1;
  };

  renderAvatar = (rowData) => {
    const source = _.get(rowData, 'thumbUrl');
    return (
      <Avatar
        src={source}
        size={64}
      >
        <UserOutlined />
      </Avatar>
    );
  };

  render() {
    const { dataset, pagination } = this.props;
    return (
      <Table
        rowKey={(record) => objectHash(record)}
        columns={this.columns}
        dataSource={dataset}
        pagination={pagination}
      />
    );
  }
}

export default MemberList;
