import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Message from '../Message';

const StyledPeriod = styled.div`
  margin: 1rem 0 0.5rem;
  text-align: center;

  span {
    padding: 0 0.5rem;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.15);
    color: #FAFAFA;
    border-radius: 1rem;
    line-height: 1.75;
    font-size: 0.9rem;
  }
`;

const MessageWrapper = styled.div`
  margin: 0.5rem 1rem;
`;

class MessagePeriod extends React.Component {

  static propTypes = {
    day: PropTypes.string.isRequired,
    dataset: PropTypes.array.isRequired,
  }

  static defaultProps = {
    dataset: [],
  }

  constructor(props) {
    super(props);

    this.state = {
      messages: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { dataset: prevDataset } = prevProps;
    const { dataset } = this.props;
    if (!_.isEqual(prevDataset, dataset)) this.init();
  }

  componentWillUnmount() {
  }

  init = () => {
    const { dataset } = this.props;
    const sorted = _.sortBy(dataset, 'createAt');
    this.setState({ messages: sorted });
  }

  renderPeriodTitle = () => {
    const { day } = this.props;
    const now = Moment();
    const yesterday = Moment().subtract(1, 'days');
    const createAt = Moment.unix(day);
    let text;
    if (createAt.isSame(now, 'day')) {
      text = '今天';
    } else if (createAt.isSame(yesterday, 'day')) {
      text = '昨天';
    } else if (createAt.isSame(now, 'year')) {
      text = createAt.format('MM/DD');
    } else {
      text = createAt.format('YYYY/MM/DD');
    }
    return text;
  }

  renderMessage = (message) => {
    const { $hash } = message;
    return (
      <MessageWrapper key={$hash}>
        <Message message={message}></Message>
      </MessageWrapper>
    );
  }

  render() {
    const { messages } = this.state;
    return (
      <React.Fragment>
        <StyledPeriod>
          <span>{this.renderPeriodTitle()}</span>
        </StyledPeriod>
        {_.map(messages, (message) => this.renderMessage(message))}
      </React.Fragment>
    );
  }
}

export default MessagePeriod;
