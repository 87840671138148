import * as Types from './Types';
import { createAction } from 'redux-actions';

export const showServerError = createAction(Types.AUTH_SERVER_ERROR);
export const login = createAction(Types.AUTH_LOGIN.REQUEST);
export const logout = createAction(Types.AUTH_LOGOUT.REQUEST);
export const loadDauReports = createAction(Types.LOAD_DAU_REPORTS.REQUEST);
export const loadMauReports = createAction(Types.LOAD_MAU_REPORTS.REQUEST);
export const loadLiveMasterList = createAction(
  Types.LOAD_LIVE_MASTER_LIST.REQUEST
);
export const loadUserExportData = createAction(
  Types.LOAD_USER_EXPORT_DATA.REQUEST
);
