import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Upload, Modal, message } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { isNil } from 'ramda';
import uuid from '../../utils/uuid';

export default class ImageUpload extends PureComponent {
  static defaultProps = {
    uploadUrl: '/image/create/npcAnimation'
  };

  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: props.value ? [{ uid: uuid(), url: props.value }] : []
    };
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps;
    const { value } = this.props;
    if (prevValue !== value) {
      this.setFileList(value);
    }
  }

  setFileList = (value) => {
    let fileList = [];
    if (!isNil(value)) {
      if (typeof value === 'string') {
        fileList = [{ uid: uuid(), url: value }];
      } else if (typeof value === 'object') {
        fileList = [{ ...value }];
      }
    }
    this.setState({ fileList });
  }

  _onPreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  _customRequest = (info) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      const file = {
        uid: uuid(),
        name: info.file.name,
        url: fileReader.result,
        status: 'done',
        file: info.file
      };

      this.setState(
        state => ({ fileList: [...state.fileList, file] }),
        () => this.triggerChange(this.state.fileList[0])
      );
    });
    fileReader.readAsDataURL(info.file);
  };

  _onRemove = () => {
    this.triggerChange(null);
    // this.setState({ fileList: [] });
  };

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) onChange(changedValue);
  };

  handleCancel = () => {
    this.setState({ previewVisible: false });
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;

    return (
      <div>
        <StyledUpload
          accept=".gif, .apng, .jpg, .jpeg, .png"
          listType="picture-card"
          fileList={fileList}
          multiple={false}
          onPreview={this._onPreview}
          onRemove={this._onRemove}
          beforeUpload={beforeUpload}
          customRequest={this._customRequest}
          showUploadList={{ showDownloadIcon: false }}
        >
          {fileList.length <= 0 && uploadButton}
        </StyledUpload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

const StyledUpload = styled(Upload)`
  .ant-upload-animate-inline-leave {
    display: none;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 64px;
    height: 64px;
  }

  .ant-upload-select-picture-card i {
    position: relative;
    top: 0px;
    font-size: 18px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 0px;
    font-size: 12px;
    color: #666;
  }
`;

const uploadButton = (
  <div>
    <LegacyIcon type="plus" />
    <div className="ant-upload-text">Upload</div>
  </div>
);

function beforeUpload(file) {
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error('檔案太大, 請勿超過10MB!');
  }
  return isLt2M;
}
