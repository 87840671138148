import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Menu } from 'antd';
import { AD_MANAGEMENT } from '../../constants/routes';
import AdManagement from './AdManagement';
import SpotlightManagement from '../SpotlightManagement';
import { PageContainer, PageTitle } from '../../components/styled/page';

const Tab = Menu.Item;

const Tabs = styled(Menu)`
  margin: 0 -1rem 1rem;
`;

const AdCollection = props => {
  const { zone, type } = props.match.params;

  function onMenuClick(e) {
    props.history.push(`${AD_MANAGEMENT}/${e.key}`);
  }

  return (
    <PageContainer>
      <PageTitle>{'活動管理 > 首頁廣告管理'}</PageTitle>
      <Tabs
        mode="horizontal"
        selectedKeys={[`${zone}${type ? `/${type}` : ''}`]}
        onClick={onMenuClick}
      >
        <Tab key="spotlight">Spotlight</Tab>
        <Tab key="event/0">Event</Tab>
        <Tab key="hot/0">AD</Tab>
      </Tabs>
      <Switch>
        <Route
          path={`${AD_MANAGEMENT}/spotlight`}
          render={() => <SpotlightManagement {...props} showHeadline={false} />}
        />
        <Route
          path={[`${AD_MANAGEMENT}/:zone/:type?`]}
          render={() => <AdManagement zone={zone} type={type} />}
        />
      </Switch>
    </PageContainer>
  );
};

export default AdCollection;
