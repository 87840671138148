import { Descriptions, Modal, Spin } from 'antd';
import {
  filter,
  keys,
  compose,
  pick,
  includes,
  map,
  prop,
  toPairs,
  fromPairs,
  flatten,
  __
} from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { debutLevel, sfxSubTypeList } from '../../constants/effect';
import { findById, isReallyEmpty } from '../../utils/mixin';

const Container = styled.div`
  min-height: 48px;
  .ant-descriptions-item-content img {
    max-width: 100%;
  }
`;

const isVideo = url => /\.(mp4)$/i.test(url);
const renderPreviewer = value => {
  if (isReallyEmpty(value)) {
    return false;
  }

  if (isVideo(value)) {
    return (
      <video
        controls
        muted={true}
        autoPlay={true}
        loop={true}
        style={{ maxWidth: '100%' }}
        src={value}
      />
    );
  } else {
    return <img alt="preview" style={{ maxWidth: '100%' }} src={value} />;
  }
};
const getAcmpColumnsWithItems = (acmpIds, effect) => {
  const keyMapToUrl = {
    badgeId: ['badgeUrl'],
    badgeSimpleId: ['badgeSimpleUrl'],
    debutId: ['debutUrl', 'debutPreviewUrl', 'debutDuration'],
    frameId: ['frameUrl'],
    sfxId: ['sfxThumbnailUrl', 'sfxPreviewUrl'],
    bubbleId: ['bubbleUrl', 'bubblePreviewUrl']
  };
  const pickKeys = keys(keyMapToUrl);
  const acmpItemList = compose(toPairs, pick(pickKeys))(effect);
  const keysAfterFilter = compose(
    keys,
    fromPairs,
    filter(value => includes(value[1], acmpIds))
  )(acmpItemList);

  const flattenResult = compose(
    flatten,
    map(prop(__, keyMapToUrl))
  )(keysAfterFilter);
  return flattenResult;
};
export const TypesConfig = {
  SFX: {
    name: '濾鏡特效',
    columns: ['name', 'type', 'subType']
  },
  EVENT: {
    name: '獎勵特效',
    columns: ['name', 'type']
  },
  BUBBLE: {
    name: '留言框特效',
    columns: ['name', 'type']
  }
};

const tableColumns = [
  {
    title: '名稱',
    dataIndex: 'name'
  },
  {
    title: '類型',
    dataIndex: 'type',
    render: value => {
      return TypesConfig[value] ? TypesConfig[value].name : value;
    }
  },
  {
    title: '分類',
    dataIndex: 'subType',
    render: value => findById(value, sfxSubTypeList)?.label
  },
  {
    title: '徽章',
    dataIndex: 'badgeSimpleUrl',
    render: photo => (photo ? <img alt="preview" src={photo} /> : '無此特效')
  },
  {
    title: '頭銜',
    dataIndex: 'badgeUrl',
    render: photo => (photo ? <img alt="preview" src={photo} /> : '無此特效')
  },
  {
    title: '進場特效',
    dataIndex: 'debutUrl',
    render: photo =>
      photo
        ? renderPreviewer(photo, { autoPlay: true, loop: true })
        : '無此特效'
  },
  {
    title: '進場特效縮圖',
    dataIndex: 'debutPreviewUrl',
    render: photo => (photo ? <img alt="preview" src={photo} /> : '無此特效')
  },
  {
    title: '進場特效等級',
    dataIndex: 'debutDuration',
    render: value => debutLevel[value] || value
  },
  {
    title: '頭像框',
    dataIndex: 'frameUrl',
    render: photo => (photo ? <img alt="preview" src={photo} /> : '無此特效')
  },
  {
    title: '縮圖',
    dataIndex: 'sfxThumbnailUrl',
    render: photo => (photo ? <img alt="preview" src={photo} /> : '無此特效')
  },
  {
    title: '預覽效果',
    dataIndex: 'sfxPreviewUrl',
    render: photo => (photo ? <img alt="preview" src={photo} /> : '無此特效')
  },
  {
    title: '留言框',
    dataIndex: 'bubbleUrl',
    render: photo => (photo ? <img alt="preview" src={photo} /> : '無此特效')
  },
  {
    title: '留言框-縮圖',
    dataIndex: 'bubblePreviewUrl',
    render: photo => (photo ? <img alt="preview" src={photo} /> : '無此特效')
  }
];

const getColumns = (type, acmpItemColumn) => {
  if (TypesConfig[type]) {
    const columns = TypesConfig[type].columns.concat(acmpItemColumn);
    return filter(col => columns.includes(col.dataIndex), tableColumns);
  }
  return [];
};

const PreviewEffectModal = ({
  visible,
  effectData,
  dispatchData,
  onCancel
}) => {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (effectData && dispatchData) {
      setColumns(
        getColumns(
          effectData?.type,
          getAcmpColumnsWithItems(dispatchData.acmpItemIds, effectData)
        )
      );
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [effectData, dispatchData]);

  return (
    <Modal
      visible={visible}
      width={800}
      title="預覽"
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      <Container>
        <Spin spinning={loading}>
          <Descriptions column={1} size="small" bordered>
            {effectData &&
              columns.map(col => (
                <Descriptions.Item label={col.title} key={col.dataIndex}>
                  {col.render
                    ? col.render(effectData[col.dataIndex])
                    : effectData[col.dataIndex]}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Spin>
      </Container>
    </Modal>
  );
};
export default PreviewEffectModal;
