import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

const PreviewImage = styled.img`
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
`;

class PreviewModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      source: null,
      visible: false,
    };
  }

  show = (source) => {
    this.setState({ source, visible: true });
  }

  hide = () => {
    this.setState({ visible: false });
  }

  render() {
    const { visible, source } = this.state;

    return (
      <Modal
        visible={visible}
        destroyOnClose={true}
        footer={null}
        width={'80%'}
        onCancel={this.hide}
        bodyStyle={{
          padding: '8px',
          minHeight: '56px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <PreviewImage src={source} />
      </Modal>
    );
  }
}

export default PreviewModal;
