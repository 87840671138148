import React, { Component } from 'react';
import styled from 'styled-components';
import { Select, Input, Button } from 'antd';
import {
  Form,
  Icon as LegacyIcon,
} from '@ant-design/compatible';
import { off as fakeData } from '../fake/liveStream';
import { LiveCard } from '../components';

const Option = Select.Option;
const FormItem = Form.Item;
const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  min-width: 850px;

  > .row {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }

  > .align-right {
    margin-left: auto;
  }
`;

const LiveCardCollection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .card-image {
    cursor: default;

    &:hover {
      opacity: inherit;
    }
  }
`;

class OffList extends Component {
  handleSubmit = e => {
    e.preventDefault();
    console.log(`你搜尋了 ${e.target[0].value}`);
  };

  handleOnline = account => () => {
    window.alert(`你讓 ${account} 恢復上架成功`);
  };

  render() {
    return (
      <div>
        <StyledHeader>
          <div className="row">
            <h1>直播管理 > 已下架列表</h1>
          </div>
          <div className="row">
            <h3 className="title">顯示：</h3>
            <Select defaultValue="off" style={{ width: 120 }}>
              <Option value="live">直播中</Option>
              <Option value="off">已下架</Option>
              <Option value="reported">被檢舉直播</Option>
            </Select>
          </div>
          <div className="row align-right">
            <Form layout="inline" onSubmit={this.handleSubmit}>
              <FormItem>
                <Input placeholder="搜尋" style={{ width: 200 }} />
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  搜尋
                </Button>
              </FormItem>
            </Form>
          </div>
        </StyledHeader>
        <LiveCardCollection>
          {fakeData.map(d => (
            <LiveCard key={d.index} imgUrl={d.picture}>
              <h3>{d.account}</h3>
              <Button type="default" onClick={this.handleOnline(d.account)}>
                <LegacyIcon type="pushpin" /> 恢復上架
              </Button>
            </LiveCard>
          ))}
        </LiveCardCollection>
      </div>
    );
  }
}

export default OffList;
