import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Button, Card } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';

const StyledModal = styled(Modal)`
  .modal-content {
    display: flex;
  }

  .name-field {
    margin-bottom: 16px;

    span {
      margin-right: 8px;
    }
  }

  .right-content {
    flex: 1;
    padding-left: 8px;
  }
`;

class PhotoModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    showDeleteModal: false,
  };

  render() {
    const {
      visible,
      title,
      currentPhotoContent,
      onClose,
      deletePhoto,
    } = this.props;
    const { showDeleteModal } = this.state;

    return (
      <StyledModal
        title={title}
        width={600}
        visible={visible}
        onCancel={onClose}
        footer={null}>
        <div className="modal-content">
          <div className="left-content">
            <Card style={{ width: 300 }}>
              <img
                src={
                  currentPhotoContent.photoPath ||
                  'http://via.placeholder.com/400x400'
                }
                alt=""
                width="100%"
              />
            </Card>
          </div>
          <div className="right-content">
            {currentPhotoContent.owner && currentPhotoContent.owner.nickname ? (
              <div className="name-field">
                <h3>暱稱</h3>
                {currentPhotoContent.owner &&
                  currentPhotoContent.owner.nickname}
              </div>
            ) : null}
            {currentPhotoContent.content ? (
              <div className="name-field">
                <h3>照片描述</h3>
                {currentPhotoContent.content}
              </div>
            ) : null}
            <Button
              type="danger"
              onClick={() => {
                this.setState({ showDeleteModal: true });
              }}>
              <LegacyIcon type="delete" />刪除照片
            </Button>
          </div>
        </div>
        <Modal
          title="確定刪除照片？"
          visible={showDeleteModal}
          onOk={() => {
            this.setState({ showDeleteModal: false });
            deletePhoto();
          }}
          onCancel={() => this.setState({ showDeleteModal: false })}
          okText="確認"
          cancelText="取消">
          <p style={{ color: 'red' }}>注意！刪除後將無法復原</p>
        </Modal>
      </StyledModal>
    );
  }
}

export default PhotoModal;
