export default function columns(comp) {
  return Object.freeze([{
    key: 'thumb',
    width: '6rem',
    render: comp.renderThumb,
  }, {
    key: 'info',
    title: '家族資訊',
    width: '20%',
    render: comp.renderInfo,
  }, {
    key: 'admin',
    title: '家族長',
    width: '14%',
    dataIndex: ['adminUser', 'name'],
  }, {
    key: 'description',
    title: '家族簡介',
    width: '23%',
    dataIndex: 'desc',
  }, {
    key: 'bulletin',
    title: '家族佈告欄',
    width: '23%',
    render: comp.renderBulletin,
  }, {
    key: 'actions',
    width: '5rem',
    render: comp.renderActions,
  }]);
};
