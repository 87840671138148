import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import moment from 'moment';
import {
  evolve,
  filter,
  identity,
  isNil,
  map,
  pipe,
  propEq,
  reject,
  times,
  useWith as ramdaUseWith,
  when
} from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadImg from '../../components/UploadImg';
import { useHasErrors } from '../../hooks/useHasErrors';
import { getEffectList } from '../../reducers/effectManagement';
import { isNotNil, isReallyEmpty } from '../../utils/mixin';

const FormItem = Form.Item;
const Option = Select.Option;
const Text = Typography.Text;
const DefaultURL = {
  BACKGROUND:
    'https://d3eq1e23ftm9f0.cloudfront.net/signin/background/9d05f75cceb911edb4c8d7bca655947d.png',
  GIFT: 'https://d3eq1e23ftm9f0.cloudfront.net/signin/gift/b786c192ceb911edb4c8d7bca655947d.png'
};
const filterByDay = ramdaUseWith(filter, [propEq('day'), reject(isNil)]);
const defaultPickerValue = [
  moment('08:00:00', 'HH:mm:ss'),
  moment('07:59:59', 'HH:mm:ss').add(1, 'day')
];

const getSubmitData = evolve({
  startTime: e => moment(e).unix(),
  endTime: e => moment(e).unix()
});
const checkDays = ({ getFieldValue, validateFields }) => ({
  validator(_, value) {
    const specialGift = getFieldValue('specialGift');
    const result = filterByDay(value, specialGift);
    if (result && result?.length >= 2) {
      return Promise.reject(new Error('天數重複'));
    }
    return Promise.resolve();
  }
});
const mapDataToForm = when(
  isNotNil,
  pipe(
    evolve({
      startTime: e => moment.unix(e),
      endTime: e => moment.unix(e)
    })
  )
);
const SigninEventFormModal = ({
  isCreateMode,
  editData,
  loading,
  onOk,
  onCancel,
  open
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [hasErrors, setHasError, validateStatus] = useHasErrors();
  const [formDisabled, setFormDisabled] = useState(false);
  const { data: effectData, loading: effectLoading } = useSelector(
    state => state.effectManagement
  );

  useEffect(() => {
    dispatch(
      getEffectList({
        filter: 'GIFT',
        sfxFilter: 'TEMPORARY',
        page: 1,
        item: 999
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (open) {
      if (!isReallyEmpty(editData) && !isCreateMode) {
        setFormDisabled(moment() > moment.unix(editData.startTime));
        form.setFieldsValue(mapDataToForm(editData));
      }
    }
  }, [form, editData, open, isCreateMode]);

  const onSubmit = () => form.submit();

  const onFinish = values => {
    onOk(getSubmitData(values));
  };

  const onAfterClose = () => {
    form.resetFields();
    form.setFieldsValue({ specialGift: [] });
    setFormDisabled(false);
    setHasError(false);
  };

  const validateFieldsDay = () => {
    const totalSpecialGift = form.getFieldValue('specialGift')?.length;
    const validatePaths = pipe(
      times(identity),
      map(index => ['specialGift', index, 'day'])
    )(totalSpecialGift);
    form.validateFields(validatePaths);
  };

  const setDefaultBackgroundUrl = () => {
    form.setFieldsValue({
      backgroundUrl: DefaultURL.BACKGROUND
    });
    form.validateFields(['backgroundUrl']);
  };

  const setDefaultGiftUrl = () => {
    form.setFieldsValue({
      giftThumbUrl: DefaultURL.GIFT
    });
    form.validateFields(['giftThumbUrl']);
  };

  return (
    <Modal
      title={`${
        isCreateMode ? '新增' : formDisabled ? '預覽' : '編輯'
      }活動簽到任務`}
      onOk={onSubmit}
      onCancel={onCancel}
      open={open}
      afterClose={onAfterClose}
      okButtonProps={{ disabled: hasErrors || formDisabled }}
    >
      <Spin spinning={loading}>
        <Form
          disabled={formDisabled}
          form={form}
          onFinish={onFinish}
          onFieldsChange={validateStatus}
        >
          <FormItem
            name="name"
            label="名稱"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: '請輸入名稱' }]}
          >
            <Input placeholder="請輸入名稱" />
          </FormItem>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="startTime"
                label="開始日期"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: '請輸入開始日期' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  showToday={false}
                  defaultPickerValue={defaultPickerValue[0]}
                  disabledDate={current => {
                    const endTime = form.getFieldValue('endTime')?.clone();
                    if (endTime) {
                      return current > endTime.subtract(1, 'day').endOf('day');
                    } else {
                      return false;
                    }
                  }}
                  onChange={value => {
                    //強制在調整一次時間
                    value?.hours(8).minutes(0).seconds(0).milliseconds(0);
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="endTime"
                label="結束日期"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: '請輸入結束日期' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  showToday={false}
                  defaultPickerValue={defaultPickerValue[1]}
                  disabledDate={current => {
                    const formStartTime = form
                      .getFieldValue('startTime')
                      ?.clone();
                    if (formStartTime) {
                      return current < formStartTime.endOf('day');
                    } else {
                      return false;
                    }
                  }}
                  onChange={value => {
                    //強制在調整一次時間
                    value?.hours(7).minutes(59).seconds(59).milliseconds(59);
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="backgroundUrl"
                initialValue={DefaultURL.BACKGROUND}
                label={
                  <>
                    <span>上傳背景</span>
                    <Button
                      type="link"
                      size="small"
                      onClick={setDefaultBackgroundUrl}
                    >
                      套用預設圖
                    </Button>
                  </>
                }
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: '請輸入上傳背景' }]}
              >
                <UploadImg
                  accept="image/*"
                  uploadUrl="/cdn/signin/background"
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="giftThumbUrl"
                initialValue={DefaultURL.GIFT}
                label={
                  <>
                    <span>上傳指定禮物 Icon</span>
                    <Button
                      type="link"
                      size="small"
                      onClick={setDefaultGiftUrl}
                    >
                      套用預設圖
                    </Button>
                  </>
                }
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: '請輸入指定禮物 Icon' }]}
              >
                <UploadImg accept="image/*" uploadUrl="/cdn/signin/gift" />
              </FormItem>
            </Col>
          </Row>
          <Divider orientation="left" orientationMargin="0">
            任務獎勵設定
          </Divider>
          <Row gutter={8}>
            <Col span={18}>
              <FormItem
                name={['defaultGift', 'accomplishmentId']}
                rules={[{ required: true, message: '請選擇背包禮物' }]}
                labelCol={{ span: 24 }}
                label={
                  <>
                    預設禮物
                    <Text type="secondary">（每天登入固定獲得的任務獎勵）</Text>
                  </>
                }
              >
                <Select
                  placeholder="請選擇背包禮物"
                  loading={effectLoading}
                  showSearch
                  filterOption={(input, { name }) =>
                    name && name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {effectData.map(item => {
                    return (
                      <Option key={item.id} value={item.id} name={item.name}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                labelCol={{ span: 24 }}
                name={['defaultGift', 'count']}
                rules={[{ required: true, message: '請輸入數量' }]}
                initialValue={1}
                label="數量"
              >
                <InputNumber
                  placeholder="發送數量"
                  title="數量"
                  min={1}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
          </Row>
          <p label="指定禮物">
            指定禮物
            <Text type="secondary">
              （指定天數所設定的禮物，會取代預設禮物）
            </Text>
          </p>
          <Form.List name="specialGift" initialValue={[]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                <div style={{ minHeight: '102px', marginTop: '32px' }}>
                  {fields.length > 0 ? (
                    fields.map(field => (
                      <Space
                        direction="horizontal"
                        key={field.key}
                        align="baseline"
                        style={{ justifyContent: 'start', width: '100%' }}
                      >
                        登入第
                        <FormItem
                          {...field}
                          key={field.key + 'day'}
                          name={[field.name, 'day']}
                          style={{ width: 'auto', flexDirection: 'row' }}
                          rules={[
                            { required: true, message: '請輸入天數' },
                            checkDays
                          ]}
                        >
                          <InputNumber
                            placeholder="天數"
                            min={1}
                            max={28}
                            style={{ width: '70px' }}
                            onChange={validateFieldsDay}
                          />
                        </FormItem>
                        天
                        <FormItem
                          {...field}
                          key={field.key + 'accomplishmentId'}
                          name={[field.name, 'accomplishmentId']}
                          rules={[
                            {
                              required: true,
                              message: '請選擇背包禮物'
                            }
                          ]}
                        >
                          <Select
                            style={{ width: '200px' }}
                            placeholder="請選擇背包禮物"
                            loading={effectLoading}
                            showSearch
                            filterOption={(input, { name }) =>
                              name &&
                              name.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                          >
                            {effectData.map(item => {
                              return (
                                <Option
                                  key={item.id}
                                  value={item.id}
                                  name={item.name}
                                >
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                        <FormItem
                          {...field}
                          key={field.key + 'count'}
                          name={[field.name, 'count']}
                          initialValue={1}
                          rules={[
                            {
                              required: true,
                              message: '請輸入數量'
                            }
                          ]}
                        >
                          <InputNumber
                            placeholder="數量"
                            title="數量"
                            min={1}
                            style={{ width: '76px' }}
                          />
                        </FormItem>
                        <Button
                          title="刪除"
                          disabled={formDisabled}
                          type="text"
                          shape="circle"
                          icon={<MinusCircleOutlined />}
                          size="small"
                          onClick={() => {
                            remove(field.name);
                            requestAnimationFrame(validateFieldsDay);
                          }}
                        />
                      </Space>
                    ))
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="無指定禮物"
                    />
                  )}
                </div>
                <Form.ErrorList errors={errors} />
                <FormItem>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    新增
                  </Button>
                </FormItem>
              </>
            )}
          </Form.List>
          {!isCreateMode && (
            <FormItem name="id" noStyle hidden>
              <Input key="id" type="hidden" />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
export default SigninEventFormModal;
