import styled from 'styled-components';

export const PageTitle = styled.h1`
`;

export const PageContainer = styled.div`
  min-width: 850px;
  margin-right: 1rem;
  padding-bottom: 1rem;
`;
