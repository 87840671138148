import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Input, Pagination } from 'antd';
import {
  getPhotoList,
  setCurrentPhotoContent,
  closePhotoModal,
  removePhotoList,
} from '../../reducers/photoManagement';
import { LiveCard, PhotoModal } from '../../components';
import { PageContainer, PageTitle } from '../../components/styled/page';

const LiveCardCollection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const PhotoPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

class PhotoManagement extends React.Component {
  state = {
    keyword: '',
  };

  componentDidMount() {
    this.props.getPhotoList();
  }

  changeKeyword = e => {
    this.setState({ keyword: e.target.value });
  };

  handleImageClickWithInfo = info => () => {
    const { setCurrentPhotoContent } = this.props;
    setCurrentPhotoContent(info);
  };

  searchKeyword = () => {
    const { getPhotoList, currentPage, pageSize } = this.props;
    const { keyword } = this.state;
    getPhotoList(keyword, currentPage, pageSize);
  };

  changePage = pagination => {
    const { getPhotoList, pageSize } = this.props;
    const { keyword } = this.state;
    getPhotoList(keyword, pagination - 1, pageSize);
  };

  deletePhoto = () => {
    const {
      removePhotoList,
      currentPage,
      pageSize,
      currentPhotoContent: { id: photoId },
    } = this.props;
    const { keyword } = this.state;
    removePhotoList(photoId, keyword, currentPage, pageSize);
  };

  render() {
    const {
      photoData,
      currentPage,
      totalCount,
      currentPhotoContent,
      isModalVisible,
      closePhotoModal,
    } = this.props;
    const { keyword } = this.state;

    return (
      <PageContainer>
        <PageTitle>{'帳號管理 > 照片管理'}</PageTitle>
        <Input
          style={{ width: 200, marginRight: 10, marginBottom: 20 }}
          placeholder="請輸入主播名稱"
          value={keyword}
          onChange={this.changeKeyword}
        />
        <Button type="primary" onClick={this.searchKeyword}>
          <span>搜尋</span>
        </Button>
        <LiveCardCollection>
          {photoData.map(d => (
            <LiveCard
              imgUrl={d.photoPath}
              key={d.id}
              onImageClick={this.handleImageClickWithInfo(d)}>
              <h3>{d.owner.nickname}</h3>
            </LiveCard>
          ))}
        </LiveCardCollection>
        {totalCount > 0 && (
          <PhotoPagination
            defaultCurrent={1}
            current={currentPage + 1}
            total={totalCount}
            onChange={this.changePage}
          />
        )}
        <PhotoModal
          visible={isModalVisible}
          onClose={closePhotoModal}
          currentPhotoContent={currentPhotoContent}
          deletePhoto={this.deletePhoto}
        />
      </PageContainer>
    );
  }
}

export default connect(
  state => ({
    photoData: state.photoManagement.data,
    currentPage: state.photoManagement.currentPage,
    pageSize: state.photoManagement.pageSize,
    totalCount: state.photoManagement.totalCount,
    isModalVisible: state.photoManagement.isModalVisible,
    currentPhotoContent: state.photoManagement.currentPhotoContent,
  }),
  {
    getPhotoList,
    setCurrentPhotoContent,
    closePhotoModal,
    removePhotoList,
  }
)(PhotoManagement);
