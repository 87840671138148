import produce from 'immer';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { map, pluck, switchMap } from 'rxjs/operators';
import { createRequestTypes } from '../actions/Types';
import { getLiveGiftGiverRankingAPI, getLiveMasterRankingAPI } from '../apis';
import { catchRequestError, ofType } from '../utils/extendOperators';

/**Type */
const GET_LIVEMASTER_RANKING = createRequestTypes('GET_LIVEMASTER_RANKING');
const GET_GIFTGIVER_RANKING = createRequestTypes('GET_GIFTGIVER_RANKING');
const defaultQuery = { item: 20, page: 1 };
/**
 * Action Creator
 */
export const getLiveMasterRanking = createAction(
  GET_LIVEMASTER_RANKING.REQUEST,
  payload => ({ ...defaultQuery, ...payload })
);
export const getLiveMasterRankingSuccess = createAction(
  GET_LIVEMASTER_RANKING.SUCCESS
);
export const getLiveMasterRankingFailure = createAction(
  GET_LIVEMASTER_RANKING.FAILURE
);
export const getGiftGiverRanking = createAction(
  GET_GIFTGIVER_RANKING.REQUEST,
  payload => ({ ...defaultQuery, ...payload })
);
export const getGiftGiverRankingSuccess = createAction(
  GET_GIFTGIVER_RANKING.SUCCESS
);
export const getGiftGiverRankingFailure = createAction(
  GET_GIFTGIVER_RANKING.FAILURE
);

/**
 * Epics
 */
export const getLiveMasterRankingEpic = action$ =>
  action$.pipe(
    ofType(GET_LIVEMASTER_RANKING.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getLiveMasterRankingAPI(payload).pipe(
        map(getLiveMasterRankingSuccess),
        catchRequestError(getLiveMasterRankingFailure)
      )
    )
  );

export const getGiftGiverRankingEpic = action$ =>
  action$.pipe(
    ofType(GET_GIFTGIVER_RANKING.REQUEST),
    pluck('payload'),
    switchMap(payload =>
      getLiveGiftGiverRankingAPI(payload).pipe(
        map(getGiftGiverRankingSuccess),
        catchRequestError(getGiftGiverRankingFailure)
      )
    )
  );

/**
 * Reducer
 */
const initialState = {
  loading: false,
  liveMasterRanking: [],
  giftGiverRanking: []
};

export default handleActions(
  {
    [combineActions(
      GET_LIVEMASTER_RANKING.REQUEST,
      GET_GIFTGIVER_RANKING.REQUEST
    )]: produce(draft => {
      draft.loading = true;
    }),

    [GET_LIVEMASTER_RANKING.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.liveMasterRanking = payload.data;
    }),
    [GET_GIFTGIVER_RANKING.SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.giftGiverRanking = payload.data;
    }),
    [GET_LIVEMASTER_RANKING.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.liveMasterRanking = [];
    }),
    [GET_GIFTGIVER_RANKING.FAILURE]: produce(draft => {
      draft.loading = false;
      draft.giftGiverRanking = [];
    })
  },
  initialState
);
