import { Button, Popconfirm, Select, Space } from 'antd';
import {
  always,
  compose,
  cond,
  converge,
  dissoc,
  equals,
  identity,
  ifElse,
  is,
  isNil,
  mergeRight,
  pipe,
  prop,
  T,
  zipObj
} from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer, PageTitle } from '../../components/styled/page';
import TableContainer from '../../components/TableContainer';
import { sfxTypeList, typeList } from '../../constants/effect';
import {
  addEffect,
  deleteEffect,
  getEffect,
  getEffectList,
  resetEffect,
  setEffect,
  showModal
} from '../../reducers/effectManagement';
import { omitWhen } from '../../utils/mixin';
import BubbleTable from './BubbleTable';
import DispatchTable from './DispatchTable';
import EffectFormModal from './EffectFormModal';
import GeneralTable from './GeneralTable';
const { Option } = Select;
const typeOptions = [{ id: 'ALL', label: '全部' }, ...typeList];
const omitNullImg = omitWhen(isNil, [
  'badgeUrl',
  'badgeSimpleUrl',
  'debutUrl',
  'frameUrl',
  'sfxPreviewUrl',
  'sfxThumbnailUrl'
]);
const getSubmitData = compose(
  dissoc('sfxDate'),
  converge(mergeRight, [
    identity,
    pipe(
      prop('sfxDate'),
      cond([
        [is(Array), zipObj(['sfxStartDate', 'sfxEndDate'])],
        [equals('forever'), always({ sfxStartDate: null, sfxEndDate: null })],
        [T, always({})]
      ])
    )
  ]),
  ifElse(
    compose(is(Object), prop('debutUrl')),
    value => ({
      ...value,
      debutUrl: value?.debutUrl?.videoUrl
    }),
    identity
  )
);

const EffectManagement = () => {
  const dispatch = useDispatch();
  const { loading, data, totalCount, currentEffect, isShowModal } = useSelector(
    state => state.effectManagement
  );
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [type, setType] = useState(typeOptions[0].id);
  const [sfxType, setSfxType] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getEffectList({ filter: typeOptions[0].id, sfxFilter: null, page: 1 })
    );
  }, [dispatch]);

  /**
   * 處理 Table 勾選
   */
  const onSelectRowChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  /**
   * 點選分頁
   */
  const onPageChange = page => {
    setSelectedRowKeys([]);
    setPage(page);
    dispatch(getEffectList({ filter: type, sfxFilter: sfxType, page }));
  };

  /**
   * 新增
   */
  const onAddClick = () => {
    setIsCreateMode(true);
    dispatch(showModal());
  };

  /**
   * 編輯
   */
  const onEditClick = () => {
    setIsCreateMode(false);
    dispatch(getEffect(selectedRowKeys[0]));
    dispatch(showModal());
  };

  /**
   * 刪除
   */
  const onDelectClick = () => {
    dispatch(
      deleteEffect(
        selectedRowKeys,
        getEffectList({ filter: type, sfxFilter: sfxType, page: 1 })
      )
    );
    setSelectedRowKeys([]);
    setPage(1);
  };

  /**
   * 選擇類別
   */
  const onTypeChange = type => {
    const sfx = type === 'SFX' ? sfxTypeList[0].id : null;
    setType(type);
    setSfxType(sfx);
    setPage(1);
    setSelectedRowKeys([]);
    dispatch(getEffectList({ filter: type, sfxFilter: sfx, page: 1 }));
  };

  /**
   * 選擇濾鏡類別
   */
  const onSfxTypeChange = sfx => {
    setSfxType(sfx);
    setPage(1);
    setSelectedRowKeys([]);
    dispatch(getEffectList({ filter: type, sfxFilter: sfx, page: 1 }));
  };

  /**
   * 送出表單
   */
  const onSubmit = values => {
    if (isCreateMode) {
      dispatch(
        addEffect(
          omitNullImg(getSubmitData(values)),
          getEffectList({
            filter: typeOptions[0].id,
            sfxFilter: sfxTypeList[0].id,
            page: 1
          })
        )
      );
      setType(typeOptions[0].id);
      setSfxType(sfxTypeList[0].id);
      setPage(1);
    } else {
      dispatch(
        setEffect(
          getSubmitData(values),
          getEffectList({
            filter: type,
            sfxFilter: sfxType,
            page
          })
        )
      );
    }
    setSelectedRowKeys([]);
    resetEffect();
  };

  /**
   * 關閉表單
   */
  const onModalCancel = () => {
    dispatch(showModal(false));
    dispatch(resetEffect());
  };

  const renderTable = () => {
    if (type === 'SFX') {
      return (
        <GeneralTable
          loading={loading}
          data={data}
          selectedRowKeys={selectedRowKeys}
          pageSize={20}
          currentPage={page}
          total={totalCount}
          onRowSelectionChange={onSelectRowChange}
          onPageChange={onPageChange}
        />
      );
    } else if (type === 'BUBBLE') {
      return (
        <BubbleTable
          loading={loading}
          data={data}
          selectedRowKeys={selectedRowKeys}
          pageSize={20}
          currentPage={page}
          total={totalCount}
          onRowSelectionChange={onSelectRowChange}
          onPageChange={onPageChange}
        />
      );
    } else {
      return (
        <DispatchTable
          loading={loading}
          data={data}
          selectedRowKeys={selectedRowKeys}
          pageSize={20}
          currentPage={page}
          total={totalCount}
          onRowSelectionChange={onSelectRowChange}
          onPageChange={onPageChange}
        />
      );
    }
  };

  return (
    <PageContainer>
      <PageTitle>{'活動管理 > 特效管理'}</PageTitle>
      <div>
        <Space
          style={{
            marginBottom: 16
          }}
        >
          <Button onClick={onAddClick}>新增</Button>
          <Button disabled={selectedRowKeys.length !== 1} onClick={onEditClick}>
            編輯
          </Button>
          <Popconfirm
            disabled={selectedRowKeys.length === 0}
            key={'cancel'}
            title="是否確定刪除?"
            placement="right"
            onConfirm={onDelectClick}
          >
            <Button
              type="danger"
              disabled={selectedRowKeys.length === 0}
              style={{ marginRight: '24px' }}
            >
              刪除
            </Button>
          </Popconfirm>
          類型：
          <Select style={{ width: 120 }} value={type} onChange={onTypeChange}>
            {typeOptions.map(item => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
          濾鏡類別：
          <Select
            style={{ width: 120 }}
            value={sfxType}
            onChange={onSfxTypeChange}
            disabled={type !== 'SFX'}
          >
            {sfxTypeList.map(item => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Space>
        <TableContainer>{renderTable()}</TableContainer>
      </div>
      <EffectFormModal
        isCreateMode={isCreateMode}
        open={isShowModal}
        loading={loading}
        editData={currentEffect}
        onOk={onSubmit}
        onCancel={onModalCancel}
      />
    </PageContainer>
  );
};

export default EffectManagement;
