import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Tag } from 'antd';
import {
  FireOutlined,
  EditOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons';

const StatusSettings = new Map([
  [0, { color: 'red', text: '停用' }],
  [1, { color: 'green', text: '啟用' }]
]);

const StyledVoiceChatCard = styled.div`
  display: flex;
  background-color: #fafafa;
  border-radius: 0.5rem;
  color: #424242;
  overflow: hidden;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 5px 10px rgba(33, 33, 33, 0.15);

    .voice-chat-pointer {
      background-color: #1890ff;
    }
  }

  & + & {
    margin-top: 1rem;
  }

  .voice-chat-pointer {
    width: 0.25rem;
    align-self: stretch;
    background-color: #ddd;
  }

  .voice-chat-body {
    padding: 0.75rem;
    display: flex;
    flex-grow: 1;
  }

  .voice-chat-avatar {
    width: 6rem;
    flex-grow: 0;
    flex-shrink: 0;

    & > div {
      width: 100%;
      padding-top: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .voice-chat-user-info {
    padding: 0 1rem;
    flex-basis: 30%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: felx-start;

    p {
      margin: 0;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  .voice-chat-room-info {
    padding: 0 1rem;
    flex-basis: 30%;
    flex-grow: 2;
    display: flex;
    align-items: center;
    justify-content: felx-start;
    font-size: 1.1rem;

    .sub-info {
      margin-top: 0.5rem;
    }
  }

  .sub-info {
    display: flex;
    align-items: center;
    line-height: 1.5;
    font-size: 1rem;
    color: #888;

    .ant-tag {
      font-size: 0.9em;
    }
  }

  .voice-chat-actions {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-left: 0.3rem solid #f0f2f5;

    .ant-btn {
      width: 5rem;
      height: 5rem;
      background: none;
      border: 0;
      font-size: 1.6rem;
      color: #666;
      box-shadow: none;
      outline: none;

      .anticon {
        font-size: inherit;
      }

      &:hover {
        color: #1890ff;
      }

      &:disabled {
        color: #BDBDBD;
      }

      &::after {
        display: none;
      }
    }
  }
`;

class VoiceChatCard extends React.Component {

  static propTypes = {
    dataset: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      isActived: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { dataset: propDataset } = props;
    const newState = {};
    if (propDataset) {
      const status = _.get(propDataset, 'roomStatus');
      newState.isActived = status === 1;
    }
    return newState;
  }

  onEditBtnClick = () => {
    const { dataset, onEdit } = this.props;
    if (onEdit) onEdit(dataset);
  }

  onEnterBtnClick = () => {
    const { dataset, onEnter } = this.props;
    if (onEnter) onEnter(dataset);
  }

  renderAvatar = () => {
    const { dataset } = this.props;
    const image = _.get(dataset, 'profilePicture');
    const style = { backgroundImage: `url(${image})` };
    return image ? <div style={style}></div> : null;
  };

  renderUserInfo = () => {
    const { dataset } = this.props;
    const nickname = _.get(dataset, 'nickname');
    return (
      <div>
        <p>{nickname}</p>
      </div>
    );
  };

  renderRoomInfo = () => {
    const { dataset } = this.props;
    const { isActived } = this.state;
    const title = _.get(dataset, 'roomTitle');
    const status = _.get(dataset, 'roomStatus');
    const hot = _.get(dataset, 'hot');
    return (
      <div>
        <p>{title}</p>
        <div className="sub-info">
          {this.renderRoomStatus(status)}
          {isActived && this.renderRoomHot(hot)}
        </div>
      </div>
    );
  };

  renderRoomHot = (hot) => {
    return (
      <div>
        <FireOutlined />
        <span>{hot}</span>
      </div>
    );
  };

  renderRoomStatus = (status) => {
    const settings = StatusSettings.get(status);
    const { color, text } = settings;
    return settings && (
      <Tag color={color}>{text}</Tag>
    );
  };

  renderActions = () => {
    const { isActived } = this.state;
    return (
      <React.Fragment>
        <Button
          icon={<EditOutlined />}
          onClick={this.onEditBtnClick}
        />
        <Button
          icon={<PlaySquareOutlined />}
          disabled={!isActived}
          onClick={this.onEnterBtnClick}
        />
      </React.Fragment>
    );
  };

  render() {
    return (
      <StyledVoiceChatCard>
        <div className="voice-chat-pointer"></div>
        <div className="voice-chat-body">
          <div className="voice-chat-avatar">
            {this.renderAvatar()}
          </div>
          <div className="voice-chat-user-info">
            {this.renderUserInfo()}
          </div>
          <div className="voice-chat-room-info">
            {this.renderRoomInfo()}
          </div>
        </div>
        <div className="voice-chat-actions">
          {this.renderActions()}
        </div>
      </StyledVoiceChatCard>
    );
  }
}

export default VoiceChatCard;
