import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Badge, Button, Image, Popconfirm, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer, PageTitle } from '../../components/styled/page';
import {
  addBottomTabBar,
  deleteBottomTabBar,
  getBottomTabBar,
  getBottomTabBarList,
  setBottomTabBar,
  showModal
} from '../../reducers/bottomTabbarManagement';
import BottomTabBarModal from './BottomTabBarModal';
const BottomTabbarManagement = props => {
  const dispatch = useDispatch();
  const { data, loading, currentTab, isShowModal } = useSelector(
    state => state.bottomTabbarManagement
  );
  const [isCreateMode, setIsCreateMode] = useState(true);
  useEffect(() => {
    dispatch(getBottomTabBarList());
    return () => {
      setIsCreateMode(true);
    };
  }, [dispatch]);

  const onAddClick = () => {
    setIsCreateMode(true);
    dispatch(showModal(true));
  };

  const onModalCancel = () => {
    setIsCreateMode(true);
    dispatch(showModal(false));
  };

  const onEditClick = id => {
    setIsCreateMode(false);
    dispatch(getBottomTabBar(id));
    dispatch(showModal(true));
  };

  const onDeleteClick = id => {
    dispatch(deleteBottomTabBar(id, getBottomTabBarList()));
  };

  const onSubmit = values => {
    if (isCreateMode) {
      dispatch(addBottomTabBar(values, getBottomTabBarList()));
    } else {
      dispatch(setBottomTabBar(values, getBottomTabBarList()));
    }
  };

  const columns = [
    {
      dataIndex: null,
      align: 'right',
      width: 50,
      render: (text, record, index) =>
        index < 4 ? <Badge status="success" /> : null
    },
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: '名稱',
      dataIndex: ['i18nContents', 'names', 'zh_TW']
    },
    {
      title: 'Icon (Normal)',
      dataIndex: 'iconUrl',
      render: value => <Image width={48} src={value} />
    },
    {
      title: 'Icon (Activated)',
      dataIndex: 'activatedIconUrl',
      render: value => <Image width={48} src={value} />
    },
    {
      width: 64,
      dataIndex: null,
      key: 'actions',
      render: (__, record) => (
        <Space size="middle">
          <Button
            title="編輯"
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => onEditClick(record.id)}
          />
          <Popconfirm
            title="是否確定刪除?"
            placement="topLeft"
            onConfirm={() => onDeleteClick(record.id)}
          >
            <Button
              title="刪除"
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      )
    }
  ];
  return (
    <PageContainer>
      <PageTitle>{'首頁管理 > 底部頁籤'}</PageTitle>
      <div
        style={{
          marginBottom: 16
        }}
      >
        <Button onClick={onAddClick} type="primary">
          新增
        </Button>
      </div>
      <Table
        title={() => (
          <div>
            <InfoCircleOutlined style={{ marginRight: '8px' }} />
            注意：APP 僅顯示前 4 項
          </div>
        )}
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={data}
      />
      <BottomTabBarModal
        isCreateMode={isCreateMode}
        editData={currentTab}
        visible={isShowModal}
        loading={loading}
        onCancel={onModalCancel}
        onOk={onSubmit}
      />
    </PageContainer>
  );
};
export default BottomTabbarManagement;
