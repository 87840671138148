import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createLogger } from 'redux-logger';
import { createBrowserHistory as createHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';
import rootEpic from '../reducers/rootEpic';

const history = createHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = routerMiddleware(history);
const logger = createLogger({
  collapsed: true
});
const epicMiddleware = createEpicMiddleware();
const configureStore = () => {
  const store = createStore(
    reducers(history),
    composeEnhancer(applyMiddleware(middleware, epicMiddleware, logger))
  );
  epicMiddleware.run(rootEpic);
  return store;
};

export default configureStore();
export {
  history,
};
