import { handleActions, createAction, combineActions } from 'redux-actions';
import produce from 'immer';
import { switchMap, map, pluck, mergeMap, catchError } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { empty } from 'rxjs';
import { ofType, catchRequestError } from '../utils/extendOperators';
import {
  getAnimationEffectListAPI,
  addAnimationEffectAPI,
  setAnimationEffectAPI,
  deleteAnimationEffectAPI
} from '../apis';
import { notification } from 'antd';

const SHOW_MODAL = 'ANIMATION_EFFECT.SHOW_MODAL';

const GET_ANIMATION_EFFECT_LIST = 'GET_ANIMATION_EFFECT_LIST';
const GET_ANIMATION_EFFECT_LIST_SUCCESS = 'GET_ANIMATION_EFFECT_LIST_SUCCESS';
const GET_ANIMATION_EFFECT_LIST_FAILURE = 'GET_ANIMATION_EFFECT_LIST_FAILURE';

const ADD_ANIMATION_EFFECT = 'ADD_ANIMATION_EFFECT';
const ADD_ANIMATION_EFFECT_SUCCESS = 'ADD_ANIMATION_EFFECT_SUCCESS';
const ADD_ANIMATION_EFFECT_FAILURE = 'ADD_ANIMATION_EFFECT_FAILURE';

const SET_CURRENT_ANIMATION_EFFECT = 'SET_CURRENT_ANIMATION_EFFECT';

const MODIFY_ANIMATION_EFFECT = 'MODIFY_ANIMATION_EFFECT';
const MODIFY_ANIMATION_EFFECT_SUCCESS = 'MODIFY_ANIMATION_EFFECT_SUCCESS';
const MODIFY_ANIMATION_EFFECT_FAILURE = 'MODIFY_ANIMATION_EFFECT_FAILURE';

const DELETE_ANIMATION_EFFECT = 'DELETE_ANIMATION_EFFECT';
const DELETE_ANIMATION_EFFECT_SUCCESS = 'DELETE_ANIMATION_EFFECT_SUCCESS';
const DELETE_ANIMATION_EFFECT_FAILURE = 'DELETE_ANIMATION_EFFECT_FAILURE';

/**
 * Creator
 */
export const showModal = createAction(SHOW_MODAL);
export const getAnimationEffectList = createAction(GET_ANIMATION_EFFECT_LIST);
export const getAnimationEffectListSuccess = createAction(
  GET_ANIMATION_EFFECT_LIST_SUCCESS
);
export const getAnimationEffectListFailure = createAction(
  GET_ANIMATION_EFFECT_LIST_FAILURE
);

export const addAnimationEffect = createAction(ADD_ANIMATION_EFFECT);
export const addAnimationEffectSuccess = createAction(
  ADD_ANIMATION_EFFECT_SUCCESS
);
export const addAnimationEffectFailure = createAction(
  ADD_ANIMATION_EFFECT_FAILURE
);

export const setCurrentAnimationEffect = createAction(
  SET_CURRENT_ANIMATION_EFFECT
);

export const modifyAnimationEffect = createAction(MODIFY_ANIMATION_EFFECT);
export const modifyAnimationEffectSuccess = createAction(
  MODIFY_ANIMATION_EFFECT_SUCCESS
);
export const modifyAnimationEffectFailure = createAction(
  MODIFY_ANIMATION_EFFECT_FAILURE
);

export const deleteAnimationEffect = createAction(
  DELETE_ANIMATION_EFFECT,
  (ids = []) => ids
);
export const deleteAnimationEffectSuccess = createAction(
  DELETE_ANIMATION_EFFECT_SUCCESS
);
export const deleteAnimationEffectFailure = createAction(
  DELETE_ANIMATION_EFFECT_FAILURE
);

export const getAnimationEffectListEpic = action$ =>
  action$.pipe(
    ofType(GET_ANIMATION_EFFECT_LIST),
    pluck('payload'),
    switchMap(() =>
      getAnimationEffectListAPI().pipe(
        map(getAnimationEffectListSuccess),
        catchRequestError(getAnimationEffectListFailure)
      )
    )
  );

export const addAnimationEffectEpic = action$ => {
  return action$.pipe(
    ofType(ADD_ANIMATION_EFFECT),
    pluck('payload'),
    switchMap(payload =>
      addAnimationEffectAPI(payload).pipe(
        mergeMap(e => [addAnimationEffectSuccess(e), getAnimationEffectList()]),
        catchRequestError(addAnimationEffectFailure)
      )
    )
  );
};

export const modifyAnimationEffectEpic = action$ => {
  return action$.pipe(
    ofType(MODIFY_ANIMATION_EFFECT),
    pluck('payload'),
    switchMap(payload =>
      setAnimationEffectAPI(payload).pipe(
        mergeMap(e => [
          modifyAnimationEffectSuccess(e),
          getAnimationEffectList()
        ]),
        catchRequestError(modifyAnimationEffectFailure)
      )
    )
  );
};

export const delectAnimationEffectEpic = action$ =>
  action$.pipe(
    ofType(DELETE_ANIMATION_EFFECT),
    pluck('payload'),
    mergeMap(e => {
      return forkJoin(
        e.map(id => {
          return deleteAnimationEffectAPI(id).pipe(
            catchError(err => {
              const response = err.response ? err.response.Message : '';
              notification.error({
                message: `Delete Fail. id: ${id}`,
                description: `${err.message} - ${response}`
              });
              return [empty(), deleteAnimationEffectFailure()];
            })
          );
        })
      );
    }),
    mergeMap(() => {
      return [deleteAnimationEffectSuccess(), getAnimationEffectList()];
    })
  );
const initialState = {
  loading: false,
  isShowModal: false,
  effectList: [],
  currentEffect: {}
};

export default handleActions(
  {
    [combineActions(
      GET_ANIMATION_EFFECT_LIST,
      ADD_ANIMATION_EFFECT,
      DELETE_ANIMATION_EFFECT,
      MODIFY_ANIMATION_EFFECT
    )]: produce(draft => {
      draft.loading = true;
    }),
    [GET_ANIMATION_EFFECT_LIST_SUCCESS]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.effectList = Object.values(payload.data).flat();
    }),
    [combineActions(
      ADD_ANIMATION_EFFECT_SUCCESS,
      MODIFY_ANIMATION_EFFECT_SUCCESS
    )]: produce(draft => {
      draft.loading = false;
      draft.isShowModal = false;
    }),
    [SET_CURRENT_ANIMATION_EFFECT]: produce((draft, { payload }) => {
      draft.currentEffect = payload;
    }),
    [combineActions(
      DELETE_ANIMATION_EFFECT_SUCCESS,
      GET_ANIMATION_EFFECT_LIST_FAILURE,
      MODIFY_ANIMATION_EFFECT_FAILURE,
      ADD_ANIMATION_EFFECT_FAILURE,
      DELETE_ANIMATION_EFFECT_FAILURE
    )]: produce(draft => {
      draft.loading = false;
    }),
    [SHOW_MODAL]: produce((draft, { payload }) => {
      draft.isShowModal = payload;
    })
  },
  initialState
);
