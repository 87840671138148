import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Select, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import InputI18 from '../../components/InputI18';

const { Option } = Select;

const StyledTabRow = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  user-select: none;
  cursor: move;

  &.sortable-ghost {
    opacity: 0.5;
  }

  &.sortable-drag {
    opacity: 0.5;
  }

  .tab-row-index {
    position: relative;
    width: 3rem;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
  }

  .tab-row-field {
    padding: 0.5rem;
    flex-grow: 1;
    text-align: center;
  }

  .tab-row-tools {
    button {
      border: 0;
      background: none;
      box-shadow: none;
      outline: 0;

      i {
        font-size: 1rem;
      }

      &::after {
        display: none;
      }

      &.delete {
        &:hover,
        &:focus {
          color: #ff4d4f;
        }
      }
    }
  }
`;

class TabRow extends React.Component {
  static propTypes = {
    dataset: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    rowData: PropTypes.object.isRequired,
    onDelete: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      isTagGroup: false
    };
  }

  componentDidMount() {
    const { rowData } = this.props;
    const { template } = rowData;

    this.setState({
      isTagGroup: template === 'TAG_GROUP'
    });
  }

  componentWillUnmount() {}

  updateField = (field, value) => {
    const { rowData } = this.props;
    rowData[field] = value;
  };

  renderNameField = () => {
    const { rowData } = this.props;
    const { names } = rowData.i18nContents;
    return (
      <div className="tab-row-field">
        <InputI18
          defaultValue={names}
          onChange={value => {
            this.updateField('i18nContents', { names: value });
            // 目前 API name 這個欄位是必填，所以還是要帶入值
            this.updateField('name', value?.zh_TW);
          }}
        />
      </div>
    );
  };

  renderGroupField = () => {
    const { dataset, rowData } = this.props;
    const { isTagGroup } = this.state;
    const { tagGroups } = dataset;
    const { groupId: defGroup } = rowData;

    return (
      <Select
        style={{ width: '10rem' }}
        className="tab-row-field tab-row-group"
        defaultValue={defGroup}
        disabled={!isTagGroup}
        onChange={value => this.updateField('groupId', value)}
      >
        {tagGroups.map(group => (
          <Option key={group.id} value={group.id}>
            <span>{group.label}</span>
          </Option>
        ))}
      </Select>
    );
  };

  renderTemplateField = () => {
    const { dataset, rowData } = this.props;
    const { isTagGroup } = this.state;
    const { templates } = dataset;
    const { template: defTemplate } = rowData;

    return (
      <Select
        style={{ width: '10rem' }}
        className="tab-row-field tab-row-template"
        defaultValue={defTemplate}
        disabled={!isTagGroup}
        onChange={value => this.updateField('template', value)}
      >
        {templates.map(template => (
          <Option
            key={template.value}
            value={template.value}
            disabled={template.label !== 'TAG_GROUP'}
          >
            <span>{template.label}</span>
          </Option>
        ))}
      </Select>
    );
  };

  render() {
    const { index, onDelete } = this.props;
    const { isTagGroup } = this.state;

    return (
      <StyledTabRow>
        <Tooltip placement="top" title="排序">
          <div className="tab-row-index">#{index + 1}</div>
        </Tooltip>
        <Tooltip placement="top" title="顯示名稱" zIndex={999}>
          {this.renderNameField()}
        </Tooltip>
        <Tooltip placement="top" title="撈取標籤群組">
          {this.renderGroupField()}
        </Tooltip>
        <Tooltip placement="top" title="頁面版型">
          {this.renderTemplateField()}
        </Tooltip>
        <div className="tab-row-tools">
          <Tooltip placement="top" title="刪除">
            <Button
              className="delete"
              disabled={!isTagGroup}
              onClick={() => onDelete(index)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </div>
      </StyledTabRow>
    );
  }
}

export default TabRow;
