import { evolve, pick } from 'ramda';
import React from 'react';
import { Button, Modal } from 'antd';
import RemittanceProductForm from './RemittanceProductForm';
const processFormData = evolve({ status: value => Number(value) });
const actions = {
  create: {
    title: '新增商品',
    handler: 'createProduct'
  },
  edit: {
    title: '編輯商品',
    handler: 'updateProduct'
  }
};

class RemittanceProductModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      action: null,
      visible: false
    };
  }

  show = (action, data) => {
    if (!actions[action]) {
      console.error(
        `RemittanceProductModal: Could not support action "${action}"`
      );
      return;
    }

    this.setState({ action, visible: true });
    requestAnimationFrame(() => {
      const { form } = this;
      form.reset();
      if (data) form.setFormData(data);
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  createProduct = formData => {
    this.props.addProductInfo(processFormData(formData));
  };

  updateProduct = formData => {
    const payload = pick(['id', 'remark', 'status'], formData);
    this.props.setProductInfo(processFormData(payload));
  };

  onConfirm = () => {
    const { form } = this;
    const { action } = this.state;

    form.validateFields((errors, values) => {
      if (errors) return;

      const handler = actions[action].handler;
      this[handler](values);
      this.hide();
    });
  };

  renderFooter = () => {
    return (
      <React.Fragment>
        <Button type="primary" onClick={this.onConfirm}>
          確定
        </Button>
        <Button onClick={this.hide}>取消</Button>
      </React.Fragment>
    );
  };

  render() {
    const { action, visible } = this.state;
    const meta = actions[action];
    const title = meta && meta.title;

    return (
      <Modal
        className="remittance-product-modal"
        title={title}
        visible={visible}
        footer={this.renderFooter()}
        onCancel={this.hide}
        centered
        maskClosable
      >
        <RemittanceProductForm
          wrappedComponentRef={form => (this.form = form)}
          mode={action}
        ></RemittanceProductForm>
      </Modal>
    );
  }
}

export default RemittanceProductModal;
