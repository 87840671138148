import {
  compose,
  map,
  zipObj,
  isNil,
  isEmpty,
  toPairs,
  either,
  merge,
  curry,
  reject,
  pick,
  omit,
  path,
  identity,
  concat,
  join,
  tail,
  split,
  fromPairs,
  find,
  propEq,
  complement,
  always,
  ifElse
} from 'ramda';
import { useWith as ramdaUseWith } from 'ramda';
export const toOptionList = compose(map(zipObj(['value', 'label'])), toPairs);
export const isReallyEmpty = either(isNil, isEmpty);
export const omitWhen = curry((fn, ks, obj) =>
  merge(omit(ks, obj), reject(fn, pick(ks, obj)))
);
export const createQs = ifElse(
  isReallyEmpty,
  always(''),
  compose(
    concat('?'),
    join('&'),
    map(join('=')),
    toPairs,
    reject(isReallyEmpty)
  )
);
export const parseQs = compose(fromPairs, map(split('=')), split('&'), tail);
export const hasValueInPath = ramdaUseWith(
  (path, obj) => !isNil(path(obj)),
  [path, identity]
);
export const findById = ramdaUseWith(find, [propEq('id'), identity]);
export const isNotNil = complement(isNil);
export const isNotEmpty = complement(isEmpty);
