class Observer {
  constructor() {
    this.events = new Map();
  }

  on(type, callback) {
    const { events } = this;
    if (!events.has(type)) events.set(type, []);
    events.get(type).push(callback);
    return this;
  }

  off(type) {
    this.events.delete(type);
    return this;
  }

  dispatch(type, ...args) {
    const { events } = this;
    if (events.has(type)) {
      const event = events.get(type);
      event.forEach((callback) => callback.call(null, ...args));
      return true;
    }
    return false;
  }

  clear() {
    this.events.clear();
  }
}

export default Observer;
