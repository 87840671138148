import { pickBy, includes } from 'ramda';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Select } from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;

const ROLE_LIST = Object.freeze([
  { id: '7', name: '經營管理' },
  { id: '1', name: '系統管理員' },
  { id: '8', name: '內容管理' },
  { id: '6', name: '帳務管理' },
  { id: '9', name: '經紀公司' },
  { id: '18', name: '家族長' },
  { id: '5', name: '一般用戶' },
  { id: '10', name: '場控' },
  { id: '11', name: '經紀公司管理' },
  { id: '12', name: '設計' },
  { id: '13', name: '企劃' },
  // { id: '4', name: '直播主' },
  // { id: '14', name: '聲聊主' },
  // { id: '15', name: '私聊主' },
  // { id: '17', name: 'OBS 直播主' }
]);

const rolesForAgency = ['4', '5', '10'];

const pickRolesByIds = (targets, roles) =>
  Object.values(pickBy(({ id }) => targets.includes(id), roles));
  
class ChangeRoleModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  isAgency = () => {
    const { roles } = this.props;
    return roles.includes('ROLE_AGENCY_MANAGER');
  };

  getInitailRole = () => {
    const { currentRoleType } = this.props;
    const isAgency = this.isAgency();
    const inRolesForAgency = includes(currentRoleType, rolesForAgency);
    return !isAgency || inRolesForAgency ? currentRoleType : '';
  };

  onSubmit = (values) => {
    const {
      currentSelectId,
      onSubmit,
      onClose,
    } = this.props;
    onSubmit({
      ids: [currentSelectId],
      role: values.roleType,
    });
    onClose();
  };

  renderRoleSelector = () => {
    const list = this.isAgency() ?
      pickRolesByIds(rolesForAgency, ROLE_LIST) :
      ROLE_LIST;
    return (
      <Select placeholder="請選擇權限">
        {list.map((role) => (
          <Option key={role.id} value={role.id}>
            <span>{role.name}</span>
          </Option>
        ))}
      </Select>
    );
  };

  render() {
    const { visible, onClose } = this.props;
    return (
      <Modal
        title="權限變更設定"
        visible={visible}
        onCancel={onClose}
        footer={null}
      >
        <Form onFinish={this.onSubmit}>
          <FormItem
            label="權限變更"
            name="roleType"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            initialValue={this.getInitailRole()}
            rules={[
              { required: true, message: '請選擇權限' },
            ]}
          >
            {this.renderRoleSelector()}
          </FormItem>
          <FormItem wrapperCol={{ span: 8, offset: 4 }}>
            <Button type="primary" htmlType="submit">
              <span>確定</span>
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default ChangeRoleModal;
